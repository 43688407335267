import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { InternshipsService } from 'app/shared/dashboard/internships/internships.service';
import { forkJoin as observableForkJoin } from 'rxjs';
import { IFilterInternships, InternshipsFiltersService } from '../internships-filter.service';

@Component({
  selector: 'prf-internship-group-filter',
  templateUrl: './internship-group-filter.component.html',
  styleUrls: ['./internship-group-filter.component.scss'],
})
export class InternshipGroupFilterComponent implements OnInit, OnDestroy {
  groups: any;
  filters: IFilterInternships;
  selectedInternships: any = [];
  subscription: any;
  searchInternship: string = '';
  selectedGroup: any = [];
  isOpen: boolean = false;
  @ViewChild('groupFilter') groupFilter: ElementRef;
  @ViewChild('groupDrop') groupDrop: ElementRef;
  constructor(private internshipsService: InternshipsService, private filtersService: InternshipsFiltersService) {}

  ngOnInit() {
    this.subscription = this.filtersService.getInternshipsFilters().subscribe(data => {
      this.filters = data;
      this.selectedInternships = data.Internships ? data.Internships : [];
    });
    observableForkJoin([this.filtersService.getTalentGroupCourses(), this.filtersService.getCourseGroups()]).subscribe(
      ([talentGroupCourse, courseGroups]) => {
        talentGroupCourse.map(({ talent: name, groups }) => ({ name, groups }));

        this.groups = courseGroups.map(({ name }) => ({ name, courses: [] }));
        talentGroupCourse.forEach(({ groups = [] }) => {
          groups.forEach(({ group, courses = [] }) => {
            let courseGroup = this.groups.find(({ name }) => name === group);

            if (!courseGroup) {
              courseGroup = {
                name: group,
                courses: [],
              };
              this.groups.push(courseGroup);
            }

            courses.forEach(course => {
              if (!courseGroup.courses.find(name => name === course)) {
                courseGroup.courses.push(course);
              }
            });
          });
        });
        this.groups = this.groups.filter(({ courses }) => courses.length);
        this.groups.forEach(group => {
          group.courses.sort();
          group.courses = group.courses.map(name => ({ name }));
        });
      },
    );
  }

  checkFilterHeader() {
    if (this.filters.Internships && this.filters.Internships.length > 0) {
      if (this.filters.Internships.length > 1) {
        let courseTitles = ['направление', 'направления', 'направлений'];
        let n = this.filters.Internships.length;
        let title = this.filtersService.getTitleByNumber(n, courseTitles);
        return n + ' ' + title;
      } else {
        let course = this.selectedInternships.filter(el => el === this.filters.Internships[0]);
        return course[0];
      }
    } else {
      return 'Направление';
    }
  }

  setInternshipFilter(internship: any) {
    if (!this.filters.Internships) {
      this.filters.Internships = [internship.name];
    } else {
      let index = this.selectedInternships.indexOf(internship.name);
      index !== -1 ? this.filters.Internships.splice(index, 1) : this.filters.Internships.push(internship.name);
    }
    this.filtersService.setInternshipsFilter(this.filters);
    this.checkGroupName();
  }

  deselectInternships() {
    this.filters.Internships = null;
    this.selectedGroup = [];
    this.filtersService.setInternshipsFilter(this.filters);
  }

  setGroupFilter(group: any) {
    if (!this.filters.Internships) {
      this.selectedGroup = [];
    }
    let courses = group.courses.map(el => el.name);
    if (this.selectedGroup && this.selectedGroup.length > 0) {
      let index = this.selectedGroup.indexOf(group.name);
      if (index > -1) {
        this.selectedGroup.splice(index, 1);
        this.filters.Internships = this.filters.Internships.filter(el => courses.indexOf(el) === -1);
      } else {
        this.selectedGroup.push(group.name);
        this.filters.Internships = this.filters.Internships.concat(courses);
      }
    } else {
      this.selectedGroup = [group.name];
      this.filters.Internships = courses;
    }
    this.checkGroupName();
    this.filtersService.setInternshipsFilter(this.filters);
  }

  checkGroupName() {
    this.selectedGroup = [];
    if (this.filters.Internships && this.filters.Internships.length > 0) {
      this.groups.forEach(group => {
        group.courses.forEach(course => {
          if (this.filters.Internships.includes(course.name)) {
            if (this.selectedGroup && this.selectedGroup.length > 0) {
              this.selectedGroup.push(group.name);
            } else {
              this.selectedGroup = [group.name];
            }
          }
        });
      });
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  closeOther(dropdown: any, icon: any) {
    dropdown.classList.toggle('w--open');
    let elements = this.groupFilter.nativeElement.querySelectorAll('.event-dd-second.w-dropdown-list.w--open');
    elements.forEach(el => (el !== dropdown ? el.classList.remove('w--open') : false));
    icon.classList.toggle('rotateUp');
    let icons = this.groupFilter.nativeElement.querySelectorAll('.event-category-dd-icon.w-icon-dropdown-toggle.rotateUp');
    icons.forEach(el => (el !== icon ? el.classList.remove('rotateUp') : false));
  }

  @HostListener('document:click', ['$event.target'])
  checkClick(target) {
    if (this.groupFilter && !this.groupFilter?.nativeElement.contains(target)) {
      this.isOpen = false;
    }
  }
}
