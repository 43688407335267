export enum ApiProfilesUrls {
  SharedResult = '/api/v1.0/profiles/sharedresults',
  GetSharedResult = '/api/v1.0/profiles/sharedresults/getbyuser',
  UpdateUserProfile = '/api/v1.0/profiles/userprofile/update',
  UploadAvatar = '/api/v1.0/profiles/userprofile/uploadavatar',
  BindChild = '/api/v1.0/profiles/userprofile/bindchild',
  GetPortfolio = '/api/v1.0/profiles/portfolio/getportfolio',
  DeletePortfolio = '/api/v1.0/profiles/portfolio/deleteportfoliofile',
  AddPortfolioFile = '/api/v1.0/profiles/portfolio/addexistingportfoliofile',
  CreateAfterTaskPupil = '/api/v1.0/profiles/questionary/createaftertaskpupilcommunicationbyuserid',
  ClassesFormatsForProfessions = '/api/v1.0/profiles/classesformatsforprofessions',
  ClassesFormatsForProfessionByUserId = '/api/v1.0/profiles/classesformatsforprofessions/getbyuserid',
}
