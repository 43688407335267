import { inject, Injectable } from '@angular/core';
import { StorageKeys } from '@profilum-logic-services/web-storage/web-storage.collections';
import { WebStorageService } from '@profilum-logic-services/web-storage/web-storage.service';
import { IBubbleBoxLine, IPipeSlice } from '../../../interfaces/prf-tests.interface';

@Injectable()
export class BubbleBoxService {
  private desktopLines: IBubbleBoxLine[] = [];
  private tabletLines: IBubbleBoxLine[] = [];
  private webStorageService: WebStorageService = inject(WebStorageService);

  public setDesktopLine(line: IPipeSlice[], elementsLength: number): void {
    const storedLines: IBubbleBoxLine[] | null = this.webStorageService.get(StorageKeys.BubbleBoxDesktopLines);

    if (!storedLines || this.desktopLines.find((elem: IBubbleBoxLine) => elem.elements === elementsLength)) {
      return;
    }

    const newLine: IBubbleBoxLine = { elements: elementsLength, line: line };

    if (storedLines) {
      storedLines.push(newLine);
      this.webStorageService.set(StorageKeys.BubbleBoxDesktopLines, JSON.stringify(storedLines));
    } else {
      this.webStorageService.set(StorageKeys.BubbleBoxDesktopLines, JSON.stringify([newLine]));
    }

    this.desktopLines.push(newLine);
  }

  public getDesktopLine(elementsLength: number): IPipeSlice[] {
    const storedLines: IBubbleBoxLine[] | null = this.webStorageService.get(StorageKeys.BubbleBoxDesktopLines);

    if (storedLines) {
      this.desktopLines = storedLines;
    } else {
      this.webStorageService.set(StorageKeys.BubbleBoxDesktopLines, JSON.stringify([]));
    }

    return this.desktopLines.find((elem: IBubbleBoxLine) => elem.elements === elementsLength)?.line;
  }

  public setTabletLine(line: IPipeSlice[], elementsLength: number): void {
    const storedLines: IBubbleBoxLine[] | null = this.webStorageService.get(StorageKeys.BubbleBoxTabletLines);

    if (!storedLines || this.tabletLines.find((elem: IBubbleBoxLine) => elem.elements === elementsLength)) {
      return;
    }

    const newLine: IBubbleBoxLine = { elements: elementsLength, line: line };

    if (storedLines) {
      storedLines.push(newLine);
      this.webStorageService.set(StorageKeys.BubbleBoxTabletLines, JSON.stringify(storedLines));
    } else {
      this.webStorageService.set(StorageKeys.BubbleBoxTabletLines, JSON.stringify([newLine]));
    }

    this.tabletLines.push(newLine);
  }

  public getTabletLine(elementsLength: number): IPipeSlice[] {
    const storedLines: IBubbleBoxLine[] | null = this.webStorageService.get(StorageKeys.BubbleBoxTabletLines);

    if (storedLines) {
      this.tabletLines = storedLines;
    } else {
      this.webStorageService.set(StorageKeys.BubbleBoxTabletLines, JSON.stringify([]));
    }

    return this.tabletLines.find((elem: IBubbleBoxLine) => elem.elements === elementsLength)?.line;
  }
}
