<div class="filter-div cat" #dateFilter>
  <div class="professions-dropdown w-dropdown" [style.z-index]="dateOpen ? 1001 : 0">
    <div
      class="professions-dropdown-toggle w-dropdown-toggle"
      (click)="dateOpen = !dateOpen"
      [class.active]="filters.publishedDate && filters.publishedDate.length > 0"
    >
      <div class="professions-dd-icon-2 w-icon-dropdown-toggle"></div>
      <div>{{ checkFilterHeader() }}</div>
    </div>
    <nav class="dd-prof-filter-list w-dropdown-list" [class.w--open]="dateOpen">
      <div class="dd-prof-filter-div _2">
        <div class="filter-list-row">
          <ng-container *ngIf="filters.publishedDate && filters.publishedDate.length > 0; else notSelected">
            <a class="clear-categories show" (click)="deselectDate()">{{ 'SHARED.CLEAR_BUTTON' | translate }}</a>
          </ng-container>
        </div>
        <div class="scroll-block">
          <div class="prof-categories-links">
            <a
              class="prof-categories-link w-inline-block"
              *ngFor="let type of types"
              [class.w--current]="selectedTime.indexOf(type.value) !== -1"
            >
              <div class="category-text-link" (click)="setTypeFilter(type)">
                {{ type.viewValue }}
              </div>
            </a>
          </div>
        </div>
      </div>
    </nav>
  </div>
</div>

<ng-template #notSelected>
  <div class="filter-list-heading">{{ 'SHARED.ALL_DATES' | translate }}</div>
  <a class="clear-categories">{{ 'SHARED.CLEAR_BUTTON' | translate }}</a>
</ng-template>
