<div class="dialog-wrapper">
  <div class="dialog-close" (click)="dialogRef.close()"></div>
  <div *ngIf="isParent" class="interests-compare__top-text">
    {{ 'TEST_AND_RESULTS.PROGRESS_BAR_PARENT' | translate }} "{{ data.title }}" {{ data.compareTitle }}
    {{ 'TEST_AND_RESULTS.PROGRESS_BAR_PARENT2' | translate }}
  </div>
  <div *ngIf="!isParent" class="interests-compare__top-text">
    {{ 'TEST_AND_RESULTS.PROGRESS_BAR_CHILD' | translate }} "{{ data.title }}" {{ data.compareTitle
    }}{{ 'TEST_AND_RESULTS.PROGRESS_BAR_CHILD2' | translate }}
  </div>

  <div class="interests-compare__item" style="margin-top: 26px">
    <div class="interests-compare__name">{{ 'TEST_AND_RESULTS.FINAL_RESULTS' | translate }}</div>
    <div class="interests-compare__line interests-compare__line--final" [style.width]="data.totalEstimate + '%'">
      <div class="interests-compare__num">{{ data.totalEstimate }}%</div>
      <div class="interests-compare__text">{{ data.title }}</div>
    </div>
  </div>

  <div *ngIf="isParent" class="interests-compare__item">
    <div class="interests-compare__name">{{ 'TEST_AND_RESULTS.YOU_RESULTS' | translate }}</div>
    <div class="interests-compare__line interests-compare__line--parents" [style.width]="data.parentEstimate + '%'">
      <div class="interests-compare__num">{{ data.parentEstimate }}%</div>
    </div>
  </div>

  <div *ngIf="!isParent" class="interests-compare__item">
    <div class="interests-compare__name">{{ 'TEST_AND_RESULTS.YOU_RESULTS' | translate }}</div>
    <div class="interests-compare__line interests-compare__line--parents" [style.width]="data.childEstimate + '%'">
      <div class="interests-compare__num">{{ data.childEstimate }}%</div>
    </div>
  </div>

  <div *ngIf="isParent" class="interests-compare__item">
    <div class="interests-compare__name">{{ 'TEST_AND_RESULTS.CHILD_RESULT' | translate }}</div>
    <div class="interests-compare__line interests-compare__line--child" [style.width]="data.childEstimate + '%'">
      <div class="interests-compare__num">{{ data.childEstimate }}%</div>
    </div>
  </div>

  <div *ngIf="!isParent" class="interests-compare__item">
    <div class="interests-compare__name">{{ 'TEST_AND_RESULTS.CHILD_RESULT' | translate }}</div>
    <div class="interests-compare__line interests-compare__line--child" [style.width]="data.parentEstimate + '%'">
      <div class="interests-compare__num">{{ data.parentEstimate }}%</div>
    </div>
  </div>
</div>
