<prf-add-center></prf-add-center>
<div>
  <h2>{{ 'SHARED.LISTS_OF_CENTERS' | translate }}</h2>
  <button (click)="updataStatistics()">{{ 'SHARED.UPDATE_STATISTIC' | translate }}</button>
  {{ 'SHARED.METHOD_IS_LONG' | translate }}
  <table *ngIf="allInstitutionsStatistic">
    <tr>
      <td>
        <b>Всего центров: {{ allInstitutionsStatistic.length }}</b>
      </td>
      <td>
        <b>{{ regPupilsCounter }}</b>
      </td>
      <td>
        <b>{{ testedPupilsCounter }}</b>
      </td>
      <td></td>
    </tr>
    <tr>
      <td><b>Название</b></td>
      <td><b>Учеников</b></td>
      <td><b>Прошло тест</b></td>
    </tr>
    <tr *ngFor="let institution of allInstitutionsStatistic">
      <td>{{ institution.institutionName }}</td>
      <td>{{ institution.registeredPupilsCount }}</td>
      <td>{{ institution.completedTestsCount }}</td>
      <td>
        <!-- <button mat-button routerLink="{{institution.url}}">Ссылка курса</button> -->
        <prf-edit-center [currentInstitution]="institution" (institutionIdChanged)="institutionUpdate($event)"></prf-edit-center>
        <prf-delete-center [institution]="institution" (institutionDeleted)="removeInstitution($event)"></prf-delete-center>
      </td>
    </tr>
  </table>
</div>
