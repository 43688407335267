import { Component } from '@angular/core';
import { BaseSliderComponent } from 'app/pages/catalog/profession-page/base-slider/base-slider.component';

@Component({
  selector: 'prf-person-slider',
  templateUrl: './person-slider.component.html',
  styleUrls: ['./person-slider.component.scss'],
})
export class PersonSliderComponent extends BaseSliderComponent {
  public overlayOn: boolean = false;
  public dialogItem: any;

  constructor() {
    super();
  }

  OnInit() {
    super.ngOnInit();
  }

  public updateFrame() {
    if (this.collection) {
      return super.updateFrame().map(item => {
        let newItem = { ...item };
        newItem.description = item.description.slice(0, 256).trim() + '...';
        return newItem;
      });
    } else {
      return [];
    }
  }

  public prepareImgLink(url) {
    return document.location.origin + url;
  }

  public openDialog(item) {
    this.dialogItem = item;
    this.overlayOn = true;
  }

  public closeDialog() {
    this.dialogItem = null;
    this.overlayOn = false;
  }

  public getFullDescription(name) {
    return (this.collection.filter(item => item.name === name)[0] || {}).description || '';
  }
}
