import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { B2gSaasService, IUserInfo } from '../../api-services';
import { StorageKeys, WebStorageService } from '../../logic-services';
import { UsersRestrictionService } from '../user-restriction';
import { Router } from '@angular/router';
import { IUserProfileInfo } from '../../core';

@Injectable({
  providedIn: 'root',
})
export class UserDataHandlerService {
  private _userData: BehaviorSubject<IUserInfo>;
  private readonly usersRestrictionService: UsersRestrictionService = inject(UsersRestrictionService);

  constructor(
    private b2gSaasService: B2gSaasService,
    private webStorageService: WebStorageService,
    private router: Router,
  ) {
    this._userData = new BehaviorSubject<IUserInfo>(this.getUserInfo());
  }

  public logout(): void {
    this.webStorageService.clear(StorageKeys.UserData);
    this._userData.next(null);
  }

  public updateUserInfo(user: IUserInfo): void {
    const userInfo = this.getUserInfo();
    const newUserInfo = userInfo ? { ...userInfo, ...user } : user;

    this.webStorageService.set(StorageKeys.UserData, newUserInfo);
    this._userData.next(newUserInfo);
  }

  public isUserRestricted(): boolean {
    return this.usersRestrictionService.checkUserAccess(this._userData.getValue().regionId);
  }

  public getUserInfo(): IUserInfo {
    const userData = this.webStorageService.get(StorageKeys.UserData);

    if (userData) {
      return userData as IUserInfo;
    }

    const userProfileData = this.webStorageService.get(StorageKeys.UserProfileInfo) ?? ({} as IUserProfileInfo);

    return userProfileData as IUserInfo;
  }

  public getUserProfileInfo(): IUserProfileInfo {
    return this.webStorageService.get(StorageKeys.UserProfileInfo) ?? ({} as IUserProfileInfo);
  }

  public getUserData(): Observable<IUserInfo> {
    return this._userData.pipe(map((user: IUserInfo | null) => user ?? ({} as IUserInfo)));
  }

  public navigateToLogin() {
    this.router.navigate(['/login']);
  }

  public fetchUserInfo(): void {
    this.sendApiRequest().subscribe();
  }

  private sendApiRequest(): Observable<IUserInfo> {
    return this.b2gSaasService.getUserInfo().pipe(
      take(1),
      tap((response: IUserInfo) => {
        this._userData.next(response);
        this.webStorageService.set(StorageKeys.UserData, response);
      }),
    );
  }
}
