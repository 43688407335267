<form novalidate #f="ngForm" (ngSubmit)="onSubmit(f)">
  <div class="form-control">
    <mat-form-field>
      <input
        type="email"
        maxlength="70"
        matInput
        placeholder="Введите новый Email"
        [(ngModel)]="_email.newEmail"
        [formControl]="newEmailFormControl"
      />
      <mat-error *ngIf="newEmailFormControl.hasError('required')">
        {{ 'SHARED.FORM.EMAIL' | translate }}
        <strong>{{ 'SHARED.REQUIRED_FIELD' | translate }}</strong>
      </mat-error>
      <mat-error *ngIf="newEmailFormControl.hasError('email') && !newEmailFormControl.hasError('required')">
        {{ 'SHARED.PLEASE_ENTER_CUR_EMAIL' | translate }}
      </mat-error>
    </mat-form-field>
  </div>
  <button type="submit" mat-raised-button>{{ 'SHARED.CHANGE_EMAIL' | translate }}</button>
</form>
