import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MunicipalitiesService } from '../municipalities.service';

@Component({
  selector: 'prf-delete-municipality',
  templateUrl: './delete-municipality.component.html',
  styleUrls: ['./delete-municipality.component.scss'],
})
export class DeleteMunicipalityComponent implements OnInit {
  @Input() municipalityId: string;
  @Output() municipalityDeleted = new EventEmitter<string>();

  public dialogOpen: boolean = false;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private municipalitiesService: MunicipalitiesService, private utilsService: UtilsService) {}

  ngOnInit() {}

  showDeleteDialog(): void {
    this.dialogOpen = !this.dialogOpen;
  }

  delete() {
    this.municipalitiesService
      .deleteMunicipality(this.municipalityId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        r => {
          this.utilsService.openSnackBar('👌 Муниципалитет удален', 'success');
          return setTimeout(_ => {
            this.municipalityDeleted.emit(this.municipalityId);
            this.showDeleteDialog();
          }, 300);
        },
        err => {
          return this.utilsService.openSnackBar('👎 Произошла ошибка, попробуйте позже', 'error');
        },
      );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }
}
