import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'prf-internship-recommendations',
  templateUrl: './internship-recommendations.component.html',
  styleUrls: ['./internship-recommendations.component.scss'],
})
export class InternshipRecommendationsComponent implements OnInit {
  @Input() set testResults(val) {
    this._testResults = val;
  }
  get testResults() {
    return this._testResults;
  }
  private _testResults;

  titleName: string = 'стажировок';
  constructor() {}

  ngOnInit() {}
}
