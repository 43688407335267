import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { InternshipsService } from 'app/shared/dashboard/internships/internships.service';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { IFilterInternships, InternshipsFiltersService } from './internships-filter.service';

export const AGE_DATA = [
  {
    label: '0+',
    value: 'ZeroPlus',
  },
  {
    label: '4 - 6',
    value: 'FourSix',
  },
  {
    label: '7 - 10',
    value: 'SevenTen',
  },
  {
    label: '11 - 13',
    value: 'ElevenThirteen',
  },
  {
    label: '14 - 16',
    value: 'FourteenSixteen',
  },
  {
    label: '16+',
    value: 'SixteenPlus',
  },
];

@Component({
  selector: 'prf-internship-filter',
  templateUrl: './internship-filter.component.html',
  styleUrls: ['./internship-filter.component.scss'],
})
export class InternshipFilterComponent implements OnInit, OnDestroy {
  talents = [];
  groups = [];
  subscription: any;
  ageRangeData: any = AGE_DATA;
  filters: IFilterInternships;
  priceHeader: string;
  disabledMaxPrice: boolean = false;
  isPriceFree: boolean = false;
  ageFilterHeader: string = '';

  @ViewChild('ageFilter') ageFilter: ElementRef;
  @ViewChild('ageDrop') ageDrop: ElementRef;
  @ViewChild('priceFilter') priceFilter: ElementRef;
  @ViewChild('priceDrop') priceDrop: ElementRef;

  constructor(
    private internshipsService: InternshipsService,
    private filtersService: InternshipsFiltersService,
    private translateService: TranslateService,
  ) {
    this.getTranslation('PLAYER.ANKETA.TELL_ABOUT.AGE')
      .pipe(take(1))
      .subscribe(translation => (this.ageFilterHeader = translation));
  }

  ngOnInit() {
    this.subscription = this.filtersService.getInternshipsFilters().subscribe(data => {
      this.filters = data;
    });
  }

  setFilter(obj: any = {}) {
    for (let key in obj) {
      if (obj) {
        this.filters[key] = obj[key];
      }
    }
    this.filtersService.setInternshipsFilter(this.filters);
  }

  checkFilters() {
    switch (true) {
      case this.filters.Audience && this.filters.Audience.length > 0:
        return true;
      case this.filters.CourseGroups && this.filters.CourseGroups.length > 0:
        return true;
      case this.filters.classesFormat && this.filters.classesFormat.length > 0:
        return true;
      default:
        return false;
    }
  }

  checkAgeHeader() {
    if (this.filters.Audience && this.filters.Audience.length > 0) {
      let age = this.ageRangeData.filter(el => el.value === this.filters.Audience);
      return age[0].label;
    } else {
      return this.ageFilterHeader;
    }
  }

  getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }

  numberOnly(event): boolean {
    let charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  clearAllFilters() {
    this.filtersService.setInternshipsFilter({});
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @HostListener('document:click', ['$event.target'])
  checkClick(target) {
    if (this.priceFilter && !this.priceFilter?.nativeElement.contains(target)) {
      this.priceDrop.nativeElement.classList.remove('w--open');
    }

    if (this.ageFilter && !this.ageFilter?.nativeElement.contains(target)) {
      this.ageDrop.nativeElement.classList.remove('w--open');
      this.ageFilter.nativeElement.classList.remove('w--open');
    }
  }
}
