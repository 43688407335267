import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BubbleViewWithImgComponent } from './bubble-view-with-img/bubble-view-with-img.component';
import { IAnswerInfo } from '@profilum-api-services/api-slides/api-slides.interface';
import { TestBoxHelperComponent } from '@profilum-components/prf-tests/test-helper/test-box-helper.component';

@Component({
  selector: 'prf-bubble-box-with-img',
  standalone: true,
  imports: [CommonModule, BubbleViewWithImgComponent],
  templateUrl: './bubble-box-with-img.component.html',
  styleUrls: ['./bubble-box-with-img.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BubbleBoxWithImgComponent extends TestBoxHelperComponent implements OnInit {
  private elementsLength: number;
  private isLastTwoItems: boolean;
  private isLastOneItem: boolean;

  public ngOnInit(): void {
    this.elementsLength = this.sectionInfo.elements.length;
    this.isLastTwoItems = this.elementsLength % 5 === 2;
    this.isLastOneItem = ((this.elementsLength - 1) % 5) % 3 === 0;
    this.setupRequiredFields();
  }

  public isLastItem(index: number): boolean {
    return this.isLastOneItem && index === this.elementsLength - 1;
  }

  public isLastRightOfTwoItems(index: number): boolean {
    return this.isLastTwoItems && index === this.elementsLength - 1;
  }

  public isLastLeftOfTwoItems(index: number): boolean {
    return this.isLastTwoItems && index === this.elementsLength - 2;
  }

  public trackByFn(index: number, answer: IAnswerInfo): string {
    return answer.answerId;
  }
}
