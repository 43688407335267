import { of as observableOf, Observable, throwError, TimeoutError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpService } from 'app/core/services/http.service';
import { AdminAccessLevel } from 'app/shared/enums/admins.enums';
import { IAllInstitutionsStatistic } from 'app/shared/interfaces/iallinstitutionsstatistic';
import { IAllSchoolsStatistic } from 'app/shared/interfaces/iallschoolsstatistic';
import { ICity } from 'app/shared/interfaces/icity';
import { map, catchError, pluck } from 'rxjs/operators';
import { InstitutionClass } from '../../../shared/classes/institution.class';
import { ICompany } from '../../../shared/interfaces/icompany.interface';
import { INews } from '../../../shared/interfaces/inews.interface';
import { ISchool } from '../../../shared/interfaces/ischool.interface';
import { HttpErrorResponse } from '@angular/common/http';

import { IProfessionFilter } from 'app/shared/interfaces/iprofessionfilter.interface';
import { IFindUsersFilter } from 'app/shared/interfaces/ifindusersfilter.interface';
import { IProfession } from 'app/shared/interfaces/iprofession.interface';
import { IAddUserInterface } from '../../../shared/interfaces/iregistration.interface';

@Injectable()
export class AdminPanelService {
  defaultTerritoryId: string = '00000000-0000-0000-0000-000000000000';

  constructor(private http: HttpService) {}

  // обработка ошибок
  handleError(err: any): Observable<any> {
    if (err instanceof TimeoutError) {
      console.error(`Frontend returned timeout error: ${err['error']}`);
      return throwError(err['error']);
    }
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
      const errorText = err.error ? (err.error.comment ? err.error.comment : err.error) : null;
    }
    throw throwError(err);
  }

  getCatalogSchools(): Observable<any> {
    return this.http.get('/api/v1.0/catalog/schools').pipe(
      map(r => r.schools),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  setSchool(dataSchool): Observable<any> {
    return this.http.post('/api/v1.0/catalog/schools', dataSchool).pipe(
      map(r => r.id),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  changeUserInfo(dataUser): Observable<any> {
    return this.http.post('/b2c/v1.0/admins/changeuserinfo', dataUser).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  totalDeleteUser(userId): Observable<any> {
    return this.http.delete('/b2c/v1.0/admins/deleteuser?userId=' + userId).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  createUserByRole(dataUser: IAddUserInterface): Observable<any> {
    return this.http.post('/b2c/v1.0/saas/adduser', dataUser).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  createAdminUser(dataUser): Observable<any> {
    return this.http.post('/b2c/v1.0/admins/addadmin', dataUser).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  findUsersByFilters(filters: IFindUsersFilter): Observable<any> {
    const credentials = {
      filters: filters,
    };
    return this.http.post('/b2c/v1.0/admins/findusers', credentials).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getSchoolAdmins(userRegionId: string, userMunicipalityId: string): Observable<any> {
    const data = {
      regionId: userRegionId,
      municipalityId: userMunicipalityId,
    };
    return this.http.get('/b2c/v1.0/admins/getschooladmins', data).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getDirectors(userRegionId: string, userMunicipalityId: string): Observable<any> {
    const data = {
      regionId: userRegionId,
      municipalityId: userMunicipalityId,
    };
    return this.http.get('/b2c/v1.0/admins/getdirectors', data).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getAdminDOUsers(userRegionId: string, userMunicipalityId: string): Observable<any> {
    const data = {
      regionId: userRegionId,
      municipalityId: userMunicipalityId,
    };
    return this.http.get('/b2c/v1.0/admins/getadmindos', data).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getAdminUsers(userRegionId: string, userMunicipalityId: string): Observable<any> {
    const params = [];
    let url = '/b2c/v1.0/admins/getregionadmins';
    userRegionId ? params.push(`RegionId=${userRegionId}`) : null;
    userMunicipalityId ? params.push(`MunicipalityId=${userMunicipalityId}`) : null;
    if (params.length) {
      url += encodeURI('?' + params.join('&'));
    }
    return this.http.get(url).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getEmployerUsers(): Observable<any> {
    return this.http.get('/b2c/v1.0/admins/getemployerusers').pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getTutorUsers(): Observable<any> {
    return this.http.get('/b2c/v1.0/admins/gettutors').pipe(
      map(r => r.tutors),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  createProfessions(profession: IProfession): Observable<any> {
    const credentials = {
      profession: profession,
    };
    return this.http.post('/api/v1.0/catalog/professions/mongo/create', credentials).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getProfessionByHrid(hrid: string): Observable<any> {
    return this.http.get(`/api/v1.0/catalog/professions/mongo/onebyhrid?hrid=${hrid}`).pipe(
      map(r => r.profession),
      catchError((err: HttpErrorResponse) => {
        if (err.status == 404) {
          return observableOf(null);
        }
        return this.handleError(err);
      }),
    );
  }
  getProfessionsByFilters(filters: IProfessionFilter): Observable<any> {
    return this.http.post('/api/v1.0/catalog/professions/mongo/search/filters', { filters: filters }).pipe(
      map(r => r.professions),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }
  getElasticProfessionsByFilters(filters: IProfessionFilter): Observable<any> {
    filters.isVisible = true;

    return this.http.post('/api/v1.0/catalog/professions/elasticsearch/search/filters', filters).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getAllProfessionsByRegion(regionId): Observable<any> {
    const filters: IProfessionFilter = {};
    Object.assign(filters, { regionId: regionId });
    const credentials = Object.assign({}, { filters });
    return this.http.post('/api/v1.0/catalog/professions/mongo/search/filters', credentials).pipe(
      map(r => r.professions),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }
  getAllElasticProfessionsByRegion(regionId): Observable<any> {
    const filters: IProfessionFilter = {};

    Object.assign(filters, { regionId: regionId, isVisible: true });

    return this.http.post('/api/v1.0/catalog/professions/elasticsearch/search/filters', filters).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getProfessionCount(filters) {
    return this.http.post('/api/v1.0/catalog/professions/mongo/count/filters', filters).pipe(
      map(r => r.count),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }
  getElasticProfessionCount(filters) {
    return this.http.post('/api/v1.0/catalog/professions/elasticsearch/count/filters', filters).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getAllProfessionsByMunicipality(municipalityId): Observable<any> {
    const filters: IProfessionFilter = {};
    Object.assign(filters, { municipalityId: municipalityId });
    const credentials = Object.assign({}, { filters });

    return this.http.post('/api/v1.0/catalog/professions/mongo/search/filters', credentials).pipe(
      map(r => r.professions),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  public getProfessionsQuery(query, regionId?): Observable<any> {
    const credentials = {
      query: query,
    };
    regionId ? Object.assign(credentials, { regionId: regionId }) : null;
    return this.http.post('/api/v1.0/catalog/professions/mongo/search/query', credentials).pipe(
      map(r => r.professions),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }
  public getElasticProfessionsQuery(query, regionId?): Observable<any> {
    return this.http.post('/api/v1.0/catalog/professions/search/query', { query, regionId }).pipe(
      map(response => response.professions),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getProfessionsByIds(ids: string[]): Observable<any> {
    const filters: IProfessionFilter = {};
    ids && ids.length ? Object.assign(filters, { ids: ids }) : null;
    const credentials = Object.assign({}, { filters });

    return this.http.post('/api/v1.0/catalog/professions/mongo/search/query', credentials).pipe(
      map(r => r.professions),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  changeProfessions(value: IProfession): Observable<any> {
    const credentials = {
      id: value.id,
      profession: value,
    };
    return this.http.put('/api/v1.0/catalog/professions/mongo/update', credentials).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getNews(from, to, groupId): Observable<any> {
    const urlParams = '?' + 'From=' + from + '&To=' + to + '&groupId=' + groupId;
    return this.http.get('/b2c/v1.0/saas/getevents' + urlParams).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  createNews(value: INews): Observable<any> {
    return this.http.post('/b2c/v1.0/saas/createevent', value).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  uploadImage(image: File, id: string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append(image[0].name, image[0]);
    return this.http.postImage('/b2c/v1.0/utils/upload/' + id, formData).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getGroups(): Observable<any> {
    return this.http.get('/api/v1.0/catalog/fielddos/all').pipe(
      map(r => r.fieldDOs),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getFields(): Observable<any> {
    return this.http.get('/api/v1.0/catalog/fields/all').pipe(
      map(r => r.fields),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getCourses(): Observable<any> {
    return this.http.get('/api/v1.0/catalog/courses/all').pipe(
      map(r => r.courses),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getProfilClasses(): Observable<any> {
    return this.http.get('/api/v1.0/catalog/search/profilclasses').pipe(
      map(r => r.result),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getInstitutionsAll(): Observable<InstitutionClass[]> {
    return this.http.get('/api/v1.0/catalog/institutions/all').pipe(
      map(r => r.institutions),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getInstitution(institutionId): Observable<any> {
    return this.http.get('/api/v1.0/catalog/institutions/one?id=' + institutionId).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  createInstitutions(institution: InstitutionClass): Observable<any> {
    return this.http.post('/api/v1.0/catalog/institutions', institution).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  updateInstitutions(institution: InstitutionClass): Observable<any> {
    return this.http.put('/api/v1.0/catalog/institutions?institutionId=' + institution.id, institution).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  deleteInstitutions(institutionId): Observable<any> {
    return this.http.delete('/api/v1.0/catalog/institutions?institutionId=' + institutionId).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  removeProfession(professionID): Observable<any> {
    return this.http.delete('/api/v1.0/catalog/professions/mongo/delete?professionId=' + professionID).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getAllSchoolsStatistic(
    regionId?: string,
    municipalityId?: string,
    city?: string,
    date?: Date,
    from?: number,
    size?: number,
    year?: number,
  ): Observable<any> {
    let urlParams = '';
    if (regionId) {
      urlParams += '?regionId=' + regionId;
    }
    if (municipalityId) {
      urlParams += urlParams ? '&municipalityId=' + municipalityId : '?municipalityId=' + municipalityId;
    }
    if (city) {
      urlParams += urlParams ? '&city=' + city : '?city=' + city;
    }
    if (date) {
      urlParams += urlParams ? '&date=' + date : '?date=' + date;
    }
    if (size) {
      urlParams += urlParams ? '&size=' + size : '?size=' + size;
    }
    if (from) {
      urlParams += urlParams ? '&from=' + from : '?from=' + from;
    }
    if (year != null && year >= 0) {
      urlParams += urlParams ? '&year=' + year : '?year=' + year;
    }

    return this.http.post('/b2c/v1.0/admins/getallschoolsstatisticbyfilters' + urlParams).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  updateAllSchoolsStatistic(regionId: string, yearToUpdate?: number): Observable<any> {
    const data = {
      regionId: regionId,
      reportYear: yearToUpdate,
    };
    return this.http.post('/b2c/v1.0/admins/updateallschoolsstatistic', data).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getAllInstitutionsStatistic(): Observable<IAllInstitutionsStatistic[]> {
    return this.http.get('/b2c/v1.0/admins/getallinstitutionsstatistic').pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  updateAllInstitutionsStatistic(): Observable<any> {
    return this.http.post('/b2c/v1.0/admins/updateallinstitutionsstatistic').pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getSchoolByID(schoolID): Observable<any> {
    return this.http.get('/api/v1.0/catalog/schools/onebyid?schoolId=' + schoolID).pipe(
      map(r => r.school),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  updateSchool(schoolClass: ISchool): Observable<any> {
    return this.http.put('/api/v1.0/catalog/schools/update?schoolId=' + schoolClass.id, schoolClass).pipe(
      map(r => r.school),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  deleteSchool(schoolID): Observable<any> {
    return this.http.delete('/api/v1.0/catalog/schools?id=' + schoolID).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getAllCities(): Observable<ICity[]> {
    return this.http.get('/api/v1.0/catalog/cities/all').pipe(
      map(r => r.cities),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getRegionCities(regionId: string): Observable<ICity[]> {
    return this.http.get('/api/v1.0/catalog/cities/getbyregion?regionId=' + regionId).pipe(
      map(r => r.cities),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getCity(cityId): Observable<any> {
    return this.http.get('/api/v1.0/catalog/cities/one?id=' + cityId).pipe(
      map(r => r.city),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  updateCity(city): Observable<any> {
    return this.http.put('/api/v1.0/catalog/cities?cityId=' + city.id, city).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  deleteCity(cityId): Observable<any> {
    return this.http.delete('/api/v1.0/catalog/cities?cityId=' + cityId).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  createCity(city): Observable<any> {
    return this.http.post('/api/v1.0/catalog/cities', city).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getRegionTestInfo(): Observable<any> {
    return this.http.get('/api/v1.0/player/regiontestinfo/list').pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  // getAdminAccessLevel(userRegionId: string, userMunicipalityId: string) {
  //   let userRole = localStorage.getItem('userRole');
  //   if (userRole == 'admin') {
  //     userRegionId = localStorage.getItem('regionId');
  //     userMunicipalityId = localStorage.getItem('municipalityId');
  //     if (userRegionId != null && userMunicipalityId != null) {
  //       if (
  //         userRegionId == this.defaultTerritoryId &&
  //         userMunicipalityId == this.defaultTerritoryId
  //       ) {
  //         return AdminAccessLevel.GLOBAL;
  //       }
  //       if (
  //         userRegionId != this.defaultTerritoryId &&
  //         userMunicipalityId == this.defaultTerritoryId
  //       ) {
  //         return AdminAccessLevel.REGION;
  //       }
  //       if (
  //         userRegionId != this.defaultTerritoryId &&
  //         userMunicipalityId != this.defaultTerritoryId
  //       ) {
  //         return AdminAccessLevel.MUNICIPALITY;
  //       }
  //     }
  //   }
  // }

  getAllCompanies(): Observable<ICompany[]> {
    return this.http.get('/api/v1.0/companies/companies/all').pipe(map(r => r.companies));
  }

  createRegisterOpen(credentials): Observable<any> {
    return this.http.post('/b2c/v1.0/saas/registeropen', credentials).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  downloadCatalogExcel(regionId, dataType): Observable<any> {
    return this.http.getFile(`/b2c/v1.0/admins/getcatalogreportdata?regionId=${regionId}&dataType=${dataType}`).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  resetPasswordToRegCode(email: string): Observable<any> {
    return this.http.post('/api/v1.0/auth/account/resetpasswordtocode', email).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }
}
