import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'prf-control-panel',
  templateUrl: './control-panel.component.html',
  styleUrls: ['./control-panel.component.scss'],
})
export class ControlPanelComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
