export interface IAllInstitutionsStatistic {
  institutionId: string;
  registeredPupilsCount: number;
  completedTestsCount: number;
  institutionName: string;
}

export interface IFindUsersFilter {
  role: string;
  lastName: string;
  firstName: string;
  email: string;
  code: string;
  regionId: string;
  municipalityId: string;
  city: string;
  schoolId: string;
}

export interface ICompany {
  id: string;
  createdDate: string;
  name: string;
  description: string;
  logoUrl: string;
  vacancies: string[];
  internships: string[];
  url?: string;
}

export interface IInstitution {
  id?: string; // Идентификатор конкретной программы
  createdDate?: string; // Дата создания
  publishedDate?: string; // Дата публикации
  isPublished?: boolean;
  hrid?: string; // Внешний идентификатор (human-readable id)
  name?: string; // Название
  description?: string; // Описание
  address?: string;
  existenceTime?: string;
  additionalInformation?: string;
  mainImageId?: string; // Идентификатор главного фотографии к программе
  mainImagePath?: string; // Идентификатор главного фотографии к программе
  audience?: string[]; // Возрастная аудитория конкретной программы
  url?: string; // Ссылка на описание конкретной программы
  email?: string;
  groupId?: string;
  groupName?: string;
  category?: string;
  billingInformation?: {
    inn: string;
    ogrn: string;
    kpp: string;
  };
  scheduleId?: string; // Идентификатор расписания занятий по программе
  galleryIds?: string[]; // Галерея картинок программы
  galleryPaths?: string[];
  regionId?: string;
  municipalityId?: string;
}

export class Company {
  id: string;
  createdDate?: string;
  updatedDate: string;
  hrid: string;
  name: string;
  logoUrl: string;
  regionId: string;
  municipalityId: string;
  cityId: string;
  address: string;
  responsiblePerson: string;
  email: string;
  phone: string;
  fieldIds?: string[];
  fieldNames: string[];
  professions: string[];
  companyDescription: string;
  employeesCount: 0;
  websiteLink: string;
  vkontakteLink: string;
  facebookLink: string;
  instagramLink: string;
  okLink: string;
  youtubeLink: string;
  yandexZenLink: string;
  news: string[];
  vacancies?: string[];
  internships?: string[];
}

export interface IUpdateRegistrationCode {
  userId?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  gender?: string;
  schoolId?: string;
  phoneNumber?: string;
  role?: string;
}

export interface IRegionCourses {
  regionId: string;
  courseId: string;
  fifthClass: boolean;
  sixthClass: boolean;
  seventhClass: boolean;
  eighthClass: boolean;
  ninthClass: boolean;
  tenthClass: boolean;
  eleventhClass: boolean;
}

export interface ILinkedCourseRegion {
  IRegionCurseStatus;
  linkedCourseRegion: IRegionCourses[];
}
