import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'currencyConvert' })
export class CurrencyConvertPipe implements PipeTransform {
  constructor(private _translate: TranslateService) {}

  transform(value: string): number {
    if (this._translate.currentLang != 'ru') {
      return Math.round(+value / 16);
    }
    return +value;
  }
}
