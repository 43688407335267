export const enum SlideType {
  Bubbles = 'Bubbles',
  Cards = 'Cards',
  Scale = 'Scale',
  Rectangles = 'Rectangles',
}

export enum AnswerType {
  Image = 'Image',
  Open = 'Open',
  Regular = 'Regular',
}

export const RectanglesQuestion: Set<string> = new Set<string>([
  'a0410e33-b919-49b9-df20-0f24810f198e',
  'db15f57a-c454-ceda-363f-437d5dea89c5',
  'c56a4d91-ce69-d055-8a75-21470ddc2abb',
  'dd80d550-bf45-e40b-0bb4-58b7fc33d772',
  'a7c34e12-0e31-44ad-aa25-0c4a30b67973',
  '8a5214c9-881a-1eb0-6019-2024370c0850',
  '48dc6b53-5e70-209b-38b9-c5eeb3bc77bd',
  'defb718f-aa06-07e1-4a80-8971709dee0d',
  '54aa24f2-436f-4ce9-8c1d-1522703992ce',
  'c65c7c38-3662-4e81-9bbb-fb4b04cd2214',
  '39d18039-ac4f-445c-af07-dd53ae0bf57a',
  'a8355db6-1331-418c-a40b-f65084f9c193',
  'abbda951-6157-4c9c-80aa-7234ff9f27e5',
  '1f34c7eb-9606-f501-d31e-c06d7988ecd7',
  'c522658a-eb2a-93d1-8293-4c5ca91ff580',
  'fe1cadcf-be58-1226-4d2e-068d27936e1d',
  '54aa24f2-436f-4ce9-8c1d-1522703992ce',
  'f74c81b5-7612-338e-f0b4-5d9a38e7cc2b',
]);

export enum ETestTypesSteps {
  DEFAULT_STANDART = 37,
  DEFAULT_360 = 17,
  PROFESSIONPRESTIGE = 7,
  MOTIVATION_TEST = 6,
  VALUES_TEST = 15,
  PROMO_TEST = 12,
  CAREER_TYPES = 10,
}
