<ng-template [ngIf]="sectionInfo">
  <form [formGroup]="form" class="prf-open-question">
    <div formArrayName="formData" class="prf-open-question-form">
      <div class="prf-textarea-container" *ngFor="let answer of sectionInfo.elements; let i = index; trackBy: trackByFn">
        <div class="prf-group" [formGroupName]="i">
          <textarea
            class="prf-textarea"
            [class.error]="isRequired && !isGoForwardAllowed() && form.touched"
            type="text"
            formControlName="question"
            [placeholder]="'PRF_TESTS.OPEN_QUESTION.PLACEHOLDER' | translate"
            name="question"
            [minlength]="minLength"
            [maxLength]="maxLength"
            [rows]="rows"
            (blur)="changeAnswer($event)"
          ></textarea>
        </div>
      </div>
    </div>
  </form>
</ng-template>
