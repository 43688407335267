import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'prf-page-500',
  templateUrl: './page-500.component.html',
  styleUrls: ['./page-500.component.scss'],
})
export class Page500Component implements OnInit {
  constructor(private meta: Meta) {
    this.meta.updateTag({ name: 'og:title', content: '500' });
  }

  ngOnInit() {}
}
