<button mat-button (click)="toggleEditDialog()">{{ 'SHARED.EDIT_BUTTON' | translate }}</button>

<div class="editors-modal" *ngIf="dialogOpen" [ngClass]="dialogOpen ? 'active-modal' : ''">
  <div class="editors-modal-wrapper">
    <div class="editors-modal-body">
      <div class="editors-modal-dialog">
        <div class="editors-modal-dialog-body">
          <p (click)="dialogOpen = !dialogOpen">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 35 35">
              <g fill-rule="evenodd" stroke-linecap="square">
                <path d="M9.192 9.192l18.385 18.385M27.577 9.192L9.192 27.577"></path>
              </g>
            </svg>
          </p>
          <form novalidate #f="ngForm" (ngSubmit)="onSubmit(f); f.reset()" *ngIf="loaded">
            <mat-form-field class="full-width">
              <input matInput id="name" [(ngModel)]="institutionInfo.name" name="name" #classname="ngModel" placeholder="Название" />
            </mat-form-field>

            <mat-form-field class="full-width">
              <input matInput id="hrid" [(ngModel)]="institutionInfo.hrid" name="hrid" #classhrid="ngModel" placeholder="hrid" />
            </mat-form-field>

            <mat-form-field class="full-width">
              <input matInput id="email" [(ngModel)]="institutionInfo.email" name="email" #classemail="ngModel" placeholder="Email" />
            </mat-form-field>

            <mat-form-field class="full-width">
              <input matInput id="address" [(ngModel)]="institutionInfo.address" name="address" #classhrid="ngModel" placeholder="Адрес" />
            </mat-form-field>

            <mat-form-field class="full-width">
              <input
                matInput
                id="description"
                [(ngModel)]="institutionInfo.description"
                name="description"
                #classdescription="ngModel"
                placeholder="Описание"
              />
            </mat-form-field>

            <mat-form-field class="full-width">
              <input matInput id="url" [(ngModel)]="institutionInfo.url" name="url" #classurl="ngModel" placeholder="Ссылка на сайт" />
            </mat-form-field>

            <mat-form-field class="full-width">
              <mat-select placeholder="Категория" #category [(ngModel)]="institutionInfo.category" name="category">
                <mat-option *ngFor="let category of institutionCategories" [value]="category.value">
                  {{ category.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="full-width">
              <input
                matInput
                id="additionalInformation"
                [(ngModel)]="institutionInfo.additionalInformation"
                name="additionalInformation"
                #classadditionalInformation="ngModel"
                placeholder="Дополнительная информация"
              />
            </mat-form-field>

            <button type="submit" mat-raised-button>{{ 'SHARED.SAVE_CHANGES' | translate }}</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
