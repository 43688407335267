import { IMetroStationInterface } from '../../../../../../../shared/interfaces/imetrostation.interface';

export class MetroColors {
  public getStationByLine(station: IMetroStationInterface): string {
    const row = METRO_LINES.find(dct_station => dct_station.line === station.line);

    if (row) {
      return row.color;
    } else {
      return 'grey';
    }
  }
}

export const METRO_LINES = [
  { line: 'Сокольническая', color: 'red' },
  { line: 'Замоскворецкая', color: 'green' },
  { line: 'Арбатско-Покровская', color: 'blue' },
  { line: 'Филевская', color: 'light-blue' },
  { line: 'Кольцевая', color: 'brown' },
  { line: 'Калужско-Рижская', color: 'orange' },
  { line: 'Таганско-Краснопресненская', color: 'purple' },
  { line: 'Калининская', color: 'yellow' },
  { line: 'Серпуховско-Тимирязевская', color: 'grey' },
  { line: 'Люблинско-Дмитровская', color: 'lime' },
  { line: 'Каховская', color: 'tail' },
  { line: 'Бутовская', color: 'blue-grey' },
  { line: 'Московское центральное кольцо', color: 'white-red' },
  { line: 'Московская монорельсовая транспортная система', color: 'purple-light' },
  { line: 'Большая кольцевая линия', color: 'tail' },
];
export const METRO_COLORS = [
  { name: 'Авиамоторная', line: 'Калининская', color: 'yellow' },
  { name: 'Автозаводская', line: 'Замоскворецкая', color: 'green' },
  { name: 'Автозаводская', line: 'Московское центральное кольцо', color: 'white-red' },
  { name: 'Академическая', line: 'Калужско-Рижская', color: 'orange' },
  { name: 'Александровский сад', line: 'Филевская', color: 'light-blue' },
  { name: 'Алексеевская', line: 'Калужско-Рижская', color: 'orange' },
  { name: 'Алма-Атинская', line: 'Замоскворецкая', color: 'green' },
  { name: 'Алтуфьево', line: 'Серпуховско-Тимирязевская', color: 'grey' },
  { name: 'Андроновка', line: 'Московское центральное кольцо', color: 'white-red' },
  { name: 'Аннино', line: 'Серпуховско-Тимирязевская', color: 'grey' },
  { name: 'Арбатская', line: 'Филевская', color: 'light-blue' },
  { name: 'Арбатская', line: 'Арбатско-Покровская', color: 'blue' },
  { name: 'Аэропорт', line: 'Замоскворецкая', color: 'green' },
  { name: 'Бабушкинская', line: 'Калужско-Рижская', color: 'orange' },
  { name: 'Багратионовская', line: 'Филевская', color: 'light-blue' },
  { name: 'Балтийская', line: 'Московское центральное кольцо', color: 'white-red' },
  { name: 'Баррикадная', line: 'Таганско-Краснопресненская', color: 'purple' },
  { name: 'Бауманская', line: 'Арбатско-Покровская', color: 'blue' },
  { name: 'Беговая', line: 'Таганско-Краснопресненская', color: 'purple' },
  { name: 'Белокаменная', line: 'Московское центральное кольцо', color: 'white-red' },
  { name: 'Белорусская', line: 'Кольцевая', color: 'brown' },
  { name: 'Белорусская', line: 'Замоскворецкая', color: 'green' },
  { name: 'Беляево', line: 'Калужско-Рижская', color: 'orange' },
  { name: 'Бибирево', line: 'Серпуховско-Тимирязевская', color: 'grey' },
  { name: 'Библиотека имени Ленина', line: 'Сокольническая', color: 'red' },
  { name: 'Битцевский парк', line: 'Бутовская', color: 'blue-grey' },
  { name: 'Борисово', line: 'Люблинско-Дмитровская', color: 'lime' },
  { name: 'Боровицкая', line: 'Серпуховско-Тимирязевская', color: 'grey' },
  { name: 'Боровское шоссе', line: 'Калининская', color: 'yellow' },
  { name: 'Ботанический сад', line: 'Московское центральное кольцо', color: 'white-red' },
  { name: 'Ботанический сад', line: 'Калужско-Рижская', color: 'orange' },
  { name: 'Братиславская', line: 'Люблинско-Дмитровская', color: 'lime' },
  { name: 'Бульвар Дмитрия Донского', line: 'Серпуховско-Тимирязевская', color: 'grey' },
  { name: 'Бульвар Рокоссовского', line: 'Сокольническая', color: 'red' },
  { name: 'Бульвар Рокоссовского', line: 'Московское центральное кольцо', color: 'white-red' },
  { name: 'Бульвар адмирала Ушакова', line: 'Бутовская', color: 'blue-grey' },
  { name: 'Бунинская Аллея', line: 'Бутовская', color: 'blue-grey' },
  { name: 'Бутырская', line: 'Люблинско-Дмитровская', color: 'lime' },
  { name: 'ВДНХ', line: 'Калужско-Рижская', color: 'orange' },
  { name: 'Варшавская', line: 'Каховская', color: 'tail' },
  { name: 'Верхние Котлы', line: 'Московское центральное кольцо', color: 'white-red' },
  { name: 'Верхние Лихоборы', line: 'Люблинско-Дмитровская', color: 'lime' },
  { name: 'Владыкино', line: 'Московское центральное кольцо', color: 'white-red' },
  { name: 'Владыкино', line: 'Серпуховско-Тимирязевская', color: 'grey' },
  { name: 'Водный стадион', line: 'Замоскворецкая', color: 'green' },
  { name: 'Войковская', line: 'Замоскворецкая', color: 'green' },
  { name: 'Волгоградский проспект', line: 'Таганско-Краснопресненская', color: 'purple' },
  { name: 'Волжская', line: 'Люблинско-Дмитровская', color: 'lime' },
  { name: 'Волоколамская', line: 'Арбатско-Покровская', color: 'blue' },
  { name: 'Воробьевы горы', line: 'Сокольническая', color: 'red' },
  { name: 'Выставочная', line: 'Филевская', color: 'light-blue' },
  {
    name: 'Выставочный центр',
    line: 'Московская монорельсовая транспортная система',
    color: 'purple-light',
  },
  { name: 'Выхино', line: 'Таганско-Краснопресненская', color: 'purple' },
  { name: 'Говорово', line: 'Калининская', color: 'yellow' },
  { name: 'Деловой центр', line: 'Московское центральное кольцо', color: 'white-red' },
  { name: 'Деловой центр', line: 'Большая кольцевая линия', color: 'tail' },
  { name: 'Динамо', line: 'Замоскворецкая', color: 'green' },
  { name: 'Дмитровская', line: 'Серпуховско-Тимирязевская', color: 'grey' },
  { name: 'Добрынинская', line: 'Кольцевая', color: 'brown' },
  { name: 'Домодедовская', line: 'Замоскворецкая', color: 'green' },
  { name: 'Достоевская', line: 'Люблинско-Дмитровская', color: 'lime' },
  { name: 'Дубровка', line: 'Московское центральное кольцо', color: 'white-red' },
  { name: 'Дубровка', line: 'Люблинско-Дмитровская', color: 'lime' },
  { name: 'Жулебино', line: 'Таганско-Краснопресненская', color: 'purple' },
  { name: 'ЗИЛ', line: 'Московское центральное кольцо', color: 'white-red' },
  { name: 'Зорге', line: 'Московское центральное кольцо', color: 'white-red' },
  { name: 'Зябликово', line: 'Люблинско-Дмитровская', color: 'lime' },
  { name: 'Измайлово', line: 'Московское центральное кольцо', color: 'white-red' },
  { name: 'Измайловская', line: 'Арбатско-Покровская', color: 'blue' },
  { name: 'Калужская', line: 'Калужско-Рижская', color: 'orange' },
  { name: 'Кантемировская', line: 'Замоскворецкая', color: 'green' },
  { name: 'Каховская', line: 'Каховская', color: 'tail' },
  { name: 'Каширская', line: 'Замоскворецкая', color: 'green' },
  { name: 'Каширская', line: 'Каховская', color: 'tail' },
  { name: 'Киевская', line: 'Филевская', color: 'light-blue' },
  { name: 'Киевская', line: 'Арбатско-Покровская', color: 'blue' },
  { name: 'Киевская', line: 'Кольцевая', color: 'brown' },
  { name: 'Китай-город', line: 'Таганско-Краснопресненская', color: 'purple' },
  { name: 'Китай-город', line: 'Калужско-Рижская', color: 'orange' },
  { name: 'Кожуховская', line: 'Люблинско-Дмитровская', color: 'lime' },
  { name: 'Коломенская', line: 'Замоскворецкая', color: 'green' },
  { name: 'Комсомольская', line: 'Сокольническая', color: 'red' },
  { name: 'Комсомольская', line: 'Кольцевая', color: 'brown' },
  { name: 'Коньково', line: 'Калужско-Рижская', color: 'orange' },
  { name: 'Коптево', line: 'Московское центральное кольцо', color: 'white-red' },
  { name: 'Котельники', line: 'Таганско-Краснопресненская', color: 'purple' },
  { name: 'Красногвардейская', line: 'Замоскворецкая', color: 'green' },
  { name: 'Краснопресненская', line: 'Кольцевая', color: 'brown' },
  { name: 'Красносельская', line: 'Сокольническая', color: 'red' },
  { name: 'Красные ворота', line: 'Сокольническая', color: 'red' },
  { name: 'Крестьянская застава', line: 'Люблинско-Дмитровская', color: 'lime' },
  { name: 'Кропоткинская', line: 'Сокольническая', color: 'red' },
  { name: 'Крылатское', line: 'Арбатско-Покровская', color: 'blue' },
  { name: 'Крымская', line: 'Московское центральное кольцо', color: 'white-red' },
  { name: 'Кузнецкий мост', line: 'Таганско-Краснопресненская', color: 'purple' },
  { name: 'Кузьминки', line: 'Таганско-Краснопресненская', color: 'purple' },
  { name: 'Кунцевская', line: 'Арбатско-Покровская', color: 'blue' },
  { name: 'Кунцевская', line: 'Филевская', color: 'light-blue' },
  { name: 'Курская', line: 'Арбатско-Покровская', color: 'blue' },
  { name: 'Курская', line: 'Кольцевая', color: 'brown' },
  { name: 'Кутузовская', line: 'Филевская', color: 'light-blue' },
  { name: 'Кутузовская', line: 'Московское центральное кольцо', color: 'white-red' },
  { name: 'Ленинский проспект', line: 'Калужско-Рижская', color: 'orange' },
  { name: 'Лермонтовский проспект', line: 'Таганско-Краснопресненская', color: 'purple' },
  { name: 'Лесопарковая', line: 'Бутовская', color: 'blue-grey' },
  { name: 'Лихоборы', line: 'Московское центральное кольцо', color: 'white-red' },
  { name: 'Локомотив', line: 'Московское центральное кольцо', color: 'white-red' },
  { name: 'Ломоносовский проспект', line: 'Калининская', color: 'yellow' },
  { name: 'Лубянка', line: 'Сокольническая', color: 'red' },
  { name: 'Лужники', line: 'Московское центральное кольцо', color: 'white-red' },
  { name: 'Люблино', line: 'Люблинско-Дмитровская', color: 'lime' },
  { name: 'Марксистская', line: 'Калининская', color: 'yellow' },
  { name: 'Марьина роща', line: 'Люблинско-Дмитровская', color: 'lime' },
  { name: 'Марьино', line: 'Люблинско-Дмитровская', color: 'lime' },
  { name: 'Маяковская', line: 'Замоскворецкая', color: 'green' },
  { name: 'Медведково', line: 'Калужско-Рижская', color: 'orange' },
  { name: 'Международная', line: 'Филевская', color: 'light-blue' },
  { name: 'Менделеевская', line: 'Серпуховско-Тимирязевская', color: 'grey' },
  { name: 'Минская', line: 'Калининская', color: 'yellow' },
  { name: 'Митино', line: 'Арбатско-Покровская', color: 'blue' },
  { name: 'Мичуринский проспект', line: 'Калининская', color: 'yellow' },
  { name: 'Молодежная', line: 'Арбатско-Покровская', color: 'blue' },
  { name: 'Мякинино', line: 'Арбатско-Покровская', color: 'blue' },
  { name: 'Нагатинская', line: 'Серпуховско-Тимирязевская', color: 'grey' },
  { name: 'Нагорная', line: 'Серпуховско-Тимирязевская', color: 'grey' },
  { name: 'Нахимовский Проспект', line: 'Серпуховско-Тимирязевская', color: 'grey' },
  { name: 'Нижегородская', line: 'Московское центральное кольцо', color: 'white-red' },
  { name: 'Новогиреево', line: 'Калининская', color: 'yellow' },
  { name: 'Новокосино', line: 'Калининская', color: 'yellow' },
  { name: 'Новокузнецкая', line: 'Замоскворецкая', color: 'green' },
  { name: 'Новопеределкино', line: 'Калининская', color: 'yellow' },
  { name: 'Новослободская', line: 'Кольцевая', color: 'brown' },
  { name: 'Новохохловская', line: 'Московское центральное кольцо', color: 'white-red' },
  { name: 'Новоясеневская', line: 'Калужско-Рижская', color: 'orange' },
  { name: 'Новые Черёмушки', line: 'Калужско-Рижская', color: 'orange' },
  { name: 'Озёрная', line: 'Калининская', color: 'yellow' },
  { name: 'Окружная', line: 'Московское центральное кольцо', color: 'white-red' },
  { name: 'Окружная', line: 'Люблинско-Дмитровская', color: 'lime' },
  { name: 'Октябрьская', line: 'Кольцевая', color: 'brown' },
  { name: 'Октябрьская', line: 'Калужско-Рижская', color: 'orange' },
  { name: 'Октябрьское поле', line: 'Таганско-Краснопресненская', color: 'purple' },
  { name: 'Орехово', line: 'Замоскворецкая', color: 'green' },
  { name: 'Отрадное', line: 'Серпуховско-Тимирязевская', color: 'grey' },
  { name: 'Охотный ряд', line: 'Сокольническая', color: 'red' },
  { name: 'Павелецкая', line: 'Кольцевая', color: 'brown' },
  { name: 'Павелецкая', line: 'Замоскворецкая', color: 'green' },
  { name: 'Панфиловская', line: 'Московское центральное кольцо', color: 'white-red' },
  { name: 'Парк Культуры', line: 'Кольцевая', color: 'brown' },
  { name: 'Парк Победы', line: 'Калининская', color: 'yellow' },
  { name: 'Парк Победы', line: 'Арбатско-Покровская', color: 'blue' },
  { name: 'Парк культуры', line: 'Сокольническая', color: 'red' },
  { name: 'Партизанская', line: 'Арбатско-Покровская', color: 'blue' },
  { name: 'Первомайская', line: 'Арбатско-Покровская', color: 'blue' },
  { name: 'Перово', line: 'Калининская', color: 'yellow' },
  { name: 'Петровский парк', line: 'Большая кольцевая линия', color: 'tail' },
  { name: 'Петровский парк', line: 'Калининская', color: 'yellow' },
  { name: 'Петровско-Разумовская', line: 'Люблинско-Дмитровская', color: 'lime' },
  { name: 'Петровско-Разумовская', line: 'Серпуховско-Тимирязевская', color: 'grey' },
  { name: 'Печатники', line: 'Люблинско-Дмитровская', color: 'lime' },
  { name: 'Пионерская', line: 'Филевская', color: 'light-blue' },
  { name: 'Планерная', line: 'Таганско-Краснопресненская', color: 'purple' },
  { name: 'Площадь Гагарина', line: 'Московское центральное кольцо', color: 'white-red' },
  { name: 'Площадь Ильича', line: 'Калининская', color: 'yellow' },
  { name: 'Площадь Революции', line: 'Арбатско-Покровская', color: 'blue' },
  { name: 'Полежаевская', line: 'Таганско-Краснопресненская', color: 'purple' },
  { name: 'Полянка', line: 'Серпуховско-Тимирязевская', color: 'grey' },
  { name: 'Пражская', line: 'Серпуховско-Тимирязевская', color: 'grey' },
  { name: 'Преображенская площадь', line: 'Сокольническая', color: 'red' },
  { name: 'Пролетарская', line: 'Таганско-Краснопресненская', color: 'purple' },
  { name: 'Проспект Вернадского', line: 'Сокольническая', color: 'red' },
  { name: 'Проспект Мира', line: 'Калужско-Рижская', color: 'orange' },
  { name: 'Проспект Мира', line: 'Кольцевая', color: 'brown' },
  { name: 'Профсоюзная', line: 'Калужско-Рижская', color: 'orange' },
  { name: 'Пушкинская', line: 'Таганско-Краснопресненская', color: 'purple' },
  { name: 'Пятницкое шоссе', line: 'Арбатско-Покровская', color: 'blue' },
  { name: 'Раменки', line: 'Калининская', color: 'yellow' },
  { name: 'Рассказовка', line: 'Калининская', color: 'yellow' },
  { name: 'Речной вокзал', line: 'Замоскворецкая', color: 'green' },
  { name: 'Рижская', line: 'Калужско-Рижская', color: 'orange' },
  { name: 'Римская', line: 'Люблинско-Дмитровская', color: 'lime' },
  { name: 'Ростокино', line: 'Московское центральное кольцо', color: 'white-red' },
  { name: 'Румянцево', line: 'Сокольническая', color: 'red' },
  { name: 'Рязанский проспект', line: 'Таганско-Краснопресненская', color: 'purple' },
  { name: 'Савёловская', line: 'Серпуховско-Тимирязевская', color: 'grey' },
  { name: 'Савёловская', line: 'Калининская', color: 'yellow' },
  { name: 'Савёловская', line: 'Большая кольцевая линия', color: 'tail' },
  { name: 'Саларьево', line: 'Сокольническая', color: 'red' },
  { name: 'Свиблово', line: 'Калужско-Рижская', color: 'orange' },
  { name: 'Севастопольская', line: 'Серпуховско-Тимирязевская', color: 'grey' },
  { name: 'Селигерская', line: 'Люблинско-Дмитровская', color: 'lime' },
  { name: 'Семеновская', line: 'Арбатско-Покровская', color: 'blue' },
  { name: 'Серпуховская', line: 'Серпуховско-Тимирязевская', color: 'grey' },
  { name: 'Славянский бульвар', line: 'Арбатско-Покровская', color: 'blue' },
  { name: 'Смоленская', line: 'Филевская', color: 'light-blue' },
  { name: 'Смоленская', line: 'Арбатско-Покровская', color: 'blue' },
  { name: 'Сокол', line: 'Замоскворецкая', color: 'green' },
  { name: 'Соколиная Гора', line: 'Московское центральное кольцо', color: 'white-red' },
  { name: 'Сокольники', line: 'Сокольническая', color: 'red' },
  { name: 'Солнцево', line: 'Калининская', color: 'yellow' },
  { name: 'Спартак', line: 'Таганско-Краснопресненская', color: 'purple' },
  { name: 'Спортивная', line: 'Сокольническая', color: 'red' },
  { name: 'Сретенский бульвар', line: 'Люблинско-Дмитровская', color: 'lime' },
  { name: 'Стрешнево', line: 'Московское центральное кольцо', color: 'white-red' },
  { name: 'Строгино', line: 'Арбатско-Покровская', color: 'blue' },
  { name: 'Студенческая', line: 'Филевская', color: 'light-blue' },
  { name: 'Сухаревская', line: 'Калужско-Рижская', color: 'orange' },
  { name: 'Сходненская', line: 'Таганско-Краснопресненская', color: 'purple' },
  { name: 'Таганская', line: 'Таганско-Краснопресненская', color: 'purple' },
  { name: 'Таганская', line: 'Кольцевая', color: 'brown' },
  { name: 'Тверская', line: 'Замоскворецкая', color: 'green' },
  { name: 'Театральная', line: 'Замоскворецкая', color: 'green' },
  { name: 'Текстильщики', line: 'Таганско-Краснопресненская', color: 'purple' },
  { name: 'Телецентр', line: 'Московская монорельсовая транспортная система', color: 'purple-light' },
  { name: 'Теплый стан', line: 'Калужско-Рижская', color: 'orange' },
  { name: 'Технопарк', line: 'Замоскворецкая', color: 'green' },
  { name: 'Тимирязевская', line: 'Серпуховско-Тимирязевская', color: 'grey' },
  {
    name: 'Тимирязевская',
    line: 'Московская монорельсовая транспортная система',
    color: 'purple-light',
  },
  { name: 'Третьяковская', line: 'Калининская', color: 'yellow' },
  { name: 'Третьяковская', line: 'Калужско-Рижская', color: 'orange' },
  { name: 'Тропарёво', line: 'Сокольническая', color: 'red' },
  { name: 'Трубная', line: 'Люблинско-Дмитровская', color: 'lime' },
  { name: 'Тульская', line: 'Серпуховско-Тимирязевская', color: 'grey' },
  { name: 'Тургеневская', line: 'Калужско-Рижская', color: 'orange' },
  { name: 'Тушинская', line: 'Таганско-Краснопресненская', color: 'purple' },
  { name: 'Угрешская', line: 'Московское центральное кольцо', color: 'white-red' },
  { name: 'Улица 1905 года', line: 'Таганско-Краснопресненская', color: 'purple' },
  {
    name: 'Улица Академика Королёва',
    line: 'Московская монорельсовая транспортная система',
    color: 'purple-light',
  },
  { name: 'Улица Горчакова', line: 'Бутовская', color: 'blue-grey' },
  {
    name: 'Улица Милашенкова',
    line: 'Московская монорельсовая транспортная система',
    color: 'purple-light',
  },
  {
    name: 'Улица Сергея Эйзенштейна',
    line: 'Московская монорельсовая транспортная система',
    color: 'purple-light',
  },
  { name: 'Улица Скобелевская', line: 'Бутовская', color: 'blue-grey' },
  { name: 'Улица Старокачаловская', line: 'Бутовская', color: 'blue-grey' },
  { name: 'Улица академика Янгеля', line: 'Серпуховско-Тимирязевская', color: 'grey' },
  { name: 'Университет', line: 'Сокольническая', color: 'red' },
  { name: 'Филевский парк', line: 'Филевская', color: 'light-blue' },
  { name: 'Фили', line: 'Филевская', color: 'light-blue' },
  { name: 'Фонвизинская', line: 'Люблинско-Дмитровская', color: 'lime' },
  { name: 'Фрунзенская', line: 'Сокольническая', color: 'red' },
  { name: 'Ховрино', line: 'Замоскворецкая', color: 'green' },
  { name: 'Хорошёво ', line: 'Московское центральное кольцо', color: 'white-red' },
  { name: 'Хорошёвская', line: 'Калининская', color: 'yellow' },
  { name: 'Хорошёвская', line: 'Большая кольцевая линия', color: 'tail' },
  { name: 'ЦСКА', line: 'Калининская', color: 'yellow' },
  { name: 'ЦСКА', line: 'Большая кольцевая линия', color: 'tail' },
  { name: 'Царицыно', line: 'Замоскворецкая', color: 'green' },
  { name: 'Цветной бульвар', line: 'Серпуховско-Тимирязевская', color: 'grey' },
  { name: 'Черкизовская', line: 'Сокольническая', color: 'red' },
  { name: 'Чертановская', line: 'Серпуховско-Тимирязевская', color: 'grey' },
  { name: 'Чеховская', line: 'Серпуховско-Тимирязевская', color: 'grey' },
  { name: 'Чистые пруды', line: 'Сокольническая', color: 'red' },
  { name: 'Чкаловская', line: 'Люблинско-Дмитровская', color: 'lime' },
  { name: 'Шаболовская', line: 'Калужско-Рижская', color: 'orange' },
  { name: 'Шелепиха', line: 'Московское центральное кольцо', color: 'white-red' },
  { name: 'Шелепиха ', line: 'Калининская', color: 'yellow' },
  { name: 'Шелепиха ', line: 'Большая кольцевая линия', color: 'tail' },
  { name: 'Шипиловская', line: 'Люблинско-Дмитровская', color: 'lime' },
  { name: 'Шоссе Энтузиастов', line: 'Московское центральное кольцо', color: 'white-red' },
  { name: 'Шоссе Энтузиастов', line: 'Калининская', color: 'yellow' },
  { name: 'Щелковская', line: 'Арбатско-Покровская', color: 'blue' },
  { name: 'Щукинская', line: 'Таганско-Краснопресненская', color: 'purple' },
  { name: 'Электрозаводская', line: 'Арбатско-Покровская', color: 'blue' },
  { name: 'Юго-Западная', line: 'Сокольническая', color: 'red' },
  { name: 'Южная', line: 'Серпуховско-Тимирязевская', color: 'grey' },
  { name: 'Ясенево', line: 'Калужско-Рижская', color: 'orange' },
];
