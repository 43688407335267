import { Injectable } from '@angular/core';
import { IOverlay } from './overlay-busy.interfaces';

@Injectable({
  providedIn: 'root',
})
export class OverlayBusyService {
  public overlay: IOverlay;

  public register(overlay: IOverlay): void {
    this.overlay = overlay;
  }

  public unregister(): void {
    this.overlay = undefined;
  }

  public show(text?: string): void {
    if (this.overlay) {
      this.overlay.show(text);
    }
  }

  public hide(): void {
    if (this.overlay) {
      this.overlay.hide();
    }
  }
}
