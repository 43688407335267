import { Component, Input } from '@angular/core';
import { IProfession } from 'app/shared/interfaces/iprofession.interface';

@Component({
  selector: 'prf-detailed-profession-card',
  templateUrl: './detailed-profession-card.component.html',
  styleUrls: ['./detailed-profession-card.component.scss'],
})
export class DetailedProfessionCardComponent {
  @Input() profession: IProfession;
}
