<div
  class="spider-chart {{ positioningType }}"
  [style.width.px]="SPIDER_CHART_CONFIG.defaultSize"
  [style.height.px]="SPIDER_CHART_CONFIG.defaultSize"
>
  <ng-container #content />
</div>

<ng-template #simpleResultsTemplate>
  <prf-spider-chart-layout [layoutConfig]="{ width: spiderSVGWidth, height: spiderSVGHeight, figureType }">
    <svg prf-spider-chart-polygon [theme]="theme" [polygonPoints]="polygonPoints"></svg>
  </prf-spider-chart-layout>
  <prf-spider-chart-value-block
    *ngFor="let spiderChartUnitData of data[0]; index as i"
    [spiderChartUnitData]="spiderChartUnitData"
    [index]="i"
    [theme]="theme"
    [stylePosition]="resultBlocksPosition[i]"
  />
</ng-template>

<ng-template #additionalResultsTemplate>
  <prf-spider-chart-layout [layoutConfig]="{ width: spiderSVGWidth, height: spiderSVGHeight, figureType }">
    <svg prf-spider-chart-polygon [theme]="theme" [polygonPoints]="additionalPolygonPoints"></svg>
  </prf-spider-chart-layout>
  <prf-spider-chart-value-block
    *ngFor="let spiderChartUnitData of data[1]; index as i"
    [spiderChartUnitData]="spiderChartUnitData"
    [index]="i"
    [theme]="theme"
    [stylePosition]="resultBlocksPosition[i]"
  />
</ng-template>

<ng-template #doubleResultsTemplate>
  <prf-spider-chart-layout [layoutConfig]="{ width: spiderSVGWidth, height: spiderSVGHeight, figureType }">
    <svg prf-spider-chart-polygon [theme]="SpiderChartThemeEnum.GREEN" [polygonPoints]="polygonPoints"></svg>
    <svg prf-spider-chart-polygon [theme]="SpiderChartThemeEnum.BLUE" [polygonPoints]="additionalPolygonPoints"></svg>
  </prf-spider-chart-layout>
  <prf-spider-chart-value-block
    *ngFor="let spiderChartUnitData of data[0]; index as i"
    [spiderChartUnitData]="spiderChartUnitData"
    [index]="i"
    [theme]="SpiderChartThemeEnum.GREEN"
    [additionalTheme]="SpiderChartThemeEnum.BLUE"
    [additionalChartUnitValue]="data[1][i]?.value"
    [stylePosition]="resultBlocksPosition[i]"
  />
  <ng-container *ngIf="SPIDER_CHART_CONFIG.differentPoints.enabled">
    <prf-spider-chart-different-block
      *ngFor="let differentIconStylePosition of differentIconsPosition"
      [differentIconStylePosition]="differentIconStylePosition"
    />
  </ng-container>
</ng-template>
