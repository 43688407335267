import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TestViewHelperComponent } from '@profilum-components/prf-tests/test-helper/test-view-helper.component';

@Component({
  selector: 'prf-bubble-view',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './bubble-view.component.html',
  styleUrls: ['./bubble-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BubbleViewComponent extends TestViewHelperComponent implements OnInit {
  public ngOnInit(): void {
    this.init();
    this.selectAnswerFromStorage();
  }
}
