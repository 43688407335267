import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { AdminPanelService } from '../../admin-panel.service';

@Component({
  selector: 'prf-delete-center',
  templateUrl: './delete-center.component.html',
  styleUrls: ['./delete-center.component.scss'],
})
export class DeleteCenterComponent implements OnInit {
  @Input() institution;
  @Output() institutionDeleted: any = new EventEmitter();
  dialogOpen: boolean = false;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private adminPanelService: AdminPanelService, private utilsService: UtilsService) {}

  ngOnInit() {}

  openDialog() {
    this.dialogOpen = !this.dialogOpen;
  }

  delete() {
    this.adminPanelService
      .deleteInstitutions(this.institution.institutionId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(response => {
        this.utilsService.openSnackBar('👌 Центр удален', 'success');
        this.institutionDeleted.emit(this.institution);
        return setTimeout(_ => {
          this.openDialog();
        }, 1000);
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }
}
