import { inject, Injectable } from '@angular/core';
import { HttpService } from '../http';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ApiPlayerUrls } from './api-player.collections';
import {
  IGetAllScreeningTestsResponse,
  IGetInformationAboutScreeningTestResponse,
  IGetTestsByScreenTestIdAndVariantIdResponse,
  ITestInfo,
} from './api-player.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiPlayerService {
  private readonly httpService: HttpService = inject(HttpService);

  public getTestSlidesCount(screenTestingId: string): Observable<any> {
    return this.httpService.get(ApiPlayerUrls.GetTestSlidesCount, { screenTestingId }).pipe(take(1));
  }

  public createSession(userData: any): Observable<any> {
    const postData: any = {
      userId: userData.userId,
      screeningTestId: userData.testId,
      variantId: userData.variantId,
      data: userData.personalInfo,
      referralUserId: userData.referralUserId ? userData.referralUserId : null,
    };
    // Для получения сессии родителем необходимо добавить параметры
    if (userData.isParent) {
      postData.data.ScreeningTestType = 'Screening360';
    }
    return this.httpService.post(ApiPlayerUrls.Sessions, postData).pipe(take(1));
  }

  public getSession(sessionId: string): Observable<any> {
    return this.httpService.get(ApiPlayerUrls.Sessions, { sessionId }).pipe(take(1));
  }

  public getSessionsList(userId: string): Observable<any> {
    return this.httpService.get(ApiPlayerUrls.GetSessionsList, { userId }).pipe(take(1));
  }

  public getLastSessionId(userId: string, screeningTestId: string): Observable<any> {
    return this.httpService.get(ApiPlayerUrls.GetLastSessionId, { userId, screeningTestId }).pipe(take(1));
  }

  public getTestsList(): Observable<any> {
    return this.httpService.get(ApiPlayerUrls.GetTestsList).pipe(map((response: any) => response.json()));
  }

  public getNextSlide(sessionId: string, isAnonymous: boolean = false): Observable<any> {
    const url: string = isAnonymous ? ApiPlayerUrls.GetNextSlideAnonymous : ApiPlayerUrls.GetNextSlide;
    return this.httpService.get(url, { sessionId }).pipe(take(1));
  }

  public getCurrentSlide(sessionId: string): Observable<any> {
    return this.httpService.get(ApiPlayerUrls.GetCurrentSlide, { sessionId }).pipe(take(1));
  }

  public getPreviousSlide(sessionId: string): Observable<any> {
    return this.httpService.get(ApiPlayerUrls.GetPreviousSlide, { sessionId }).pipe(take(1));
  }

  public regionTestInfo(regionId: string): Observable<ITestInfo> {
    return this.httpService.get<ITestInfo>(ApiPlayerUrls.RegionTestInfo, { regionId }).pipe(take(1));
  }

  public regionTestInfoList(): Observable<ITestInfo> {
    return this.httpService.get<ITestInfo>(ApiPlayerUrls.RegionTestInfoList).pipe(take(1));
  }

  public getTestSlidesCountBySessionId(sessionId: string): Observable<number> {
    return this.httpService.get<number>(ApiPlayerUrls.GetTestSlidesCountBySessionId, { sessionId }).pipe(take(1));
  }

  public getTestsByScreenTestIdAndVariantId(
    screeningTestId: string,
    variantId: string,
  ): Observable<IGetTestsByScreenTestIdAndVariantIdResponse> {
    return this.httpService
      .get<IGetTestsByScreenTestIdAndVariantIdResponse>(ApiPlayerUrls.GetTestsByScreenTestIdAndVariantId, { screeningTestId, variantId })
      .pipe(take(1));
  }

  public getAllScreeningTests(): Observable<IGetAllScreeningTestsResponse[]> {
    return this.httpService.get<IGetAllScreeningTestsResponse[]>(ApiPlayerUrls.GetTestsList).pipe(take(1));
  }

  public getInformationAboutScreeningTest(screeningTestId: string): Observable<IGetInformationAboutScreeningTestResponse> {
    return this.httpService
      .get<IGetInformationAboutScreeningTestResponse>(ApiPlayerUrls.GetInformationAboutScreeningTest, { screeningTestId })
      .pipe(take(1));
  }
}
