import { StorageKeys, Storages } from '../../logic-services';
import { Helper } from '../common-helper';
import { IStorageHelper } from './storage-helper.interface';

export class StorageHelper implements IStorageHelper {
  private _storage: Storage | null;

  constructor(storage: Storages) {
    this.storage = storage;
  }

  public set storage(storage: Storages) {
    switch (storage) {
      case Storages.Local:
        this._storage = window.localStorage;
        break;
      case Storages.Session:
        this._storage = window.sessionStorage;
        break;
      case Storages.Cookie:
        this._storage = null;
        break;
      default:
        this._storage = null;
        break;
    }
  }

  private get webStorage(): Storage {
    return this._storage;
  }

  public get<T>(key: StorageKeys): T | null {
    const dataFromWebStorage: string | RegExpMatchArray = this.webStorage
      ? this.webStorage.getItem(key)
      : document.cookie.match(`(^|;) ?${key}=([^;]*)(;|$)`);

    try {
      return this.webStorage ? <T>JSON.parse(dataFromWebStorage as string) : <T>JSON.parse(dataFromWebStorage[2]);
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return this.webStorage ? <any>dataFromWebStorage ?? null : dataFromWebStorage ? dataFromWebStorage[2] : null;
    }
  }

  public set<T>(key: StorageKeys, value: T, cookieOptions?: { path: string }): void {
    if (!Helper.isDefined(value)) {
      return;
    }

    try {
      if (this.webStorage) {
        this.webStorage.setItem(key, Helper.isString(value) ? <any>value : JSON.stringify(value));
        return;
      }

      const cookieValues: string[] = [`${key}=${Helper.isString(value) ? <string>value : JSON.stringify(value)}`];

      for (const propName in cookieOptions) {
        if (cookieOptions.hasOwnProperty(propName)) {
          cookieValues.push(`; ${propName}=${(cookieOptions as Record<string, string>)[propName]}`);
        }
      }

      document.cookie = cookieValues.join('');
      return;
    } catch (e) {
      console.error(e);
    }
  }

  public clear(key: StorageKeys): void {
    try {
      if (this.webStorage) {
        this.webStorage.removeItem(key);
        return;
      }

      document.cookie = `${key}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
      return;
    } catch (e) {
      console.error(e);
    }
  }

  public clearAll(): void {
    try {
      if (this.webStorage) {
        this.webStorage.clear();
        return;
      }

      const cookies: string[] = document.cookie?.split(';') ?? [];

      cookies.forEach((cookie: string) => {
        const eqPos: number = cookie.indexOf('=');
        const key: string = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

        document.cookie = key + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
      });
      return;
    } catch (e) {
      console.error(e);
    }
  }
}
