export enum LocationEnum {
  BASE = 'base',
  MOSOBL = 'mosreg',
  KAZAZKHSTAN = 'kz',
  KRUZHKI = 'kruzhki',
  YANAO = 'yanao',
  BASHKORTOSTAN = 'bashkortostan',
  MURMANSK = 'murmansk',
  PSKOV = 'pskov',
  EUROCHEM = 'eurochem',
}

export interface IAppSettings {
  professionViewConfiguration: {
    showSalary: boolean;
  };
  regionId?: string;
  regionLanguages?: {
    isAccessible: boolean;
    defaultLanguage: string;
    regionLanguage: string;
  };
  docsPath?: {
    docsPathDefault: string;
    docsPathRegion: string;
  };
  b2c?: {
    regionId: string;
    municipalityId: string;
    cityId: string;
    schoolId: string;
    classId: string;
  };
  banner?: {
    lastSessionBeforeDate: string;
  };
  promotestSchool: {
    schoolId: string;
    schoolClassLetter: string;
    schoolClassNumber: string;
  };
  moscowRegion?: {
    moscowRegionId: string;
    moscowMunicipalityid: string;
  };
  vorobioviGori: {
    regionId: string;
    municipalityId: string;
    schoolId: string;
    schoolClassLetter: string;
    schoolClassNumber: string;
  };
  mosruSchoolId?: string;
  profilumLogoUrl?: string;
  maximumChildAge?: number;
  fromDirectorToParentsLetter: {
    recommendedSchool: string[];
    recommendedMunicipality: string[];
    recommendedOnline: string[];
    recommendedProfessions: string[];
  };
  copyLink?: string;
  copyLinkLogin?: string;
  copyLinkRegister?: string;
  openRegistrationPupil?: boolean;
  openRegistrationParent?: boolean;
  registrationParentWithChild?: boolean;
  defaultTimeout?: number;
  campTestId?: string;
  bvbLkStudentUrl?: string;
  bvbLkProfessionsRecommendationUrl?: string;
  bvbLkErrorTimeoutUrl?: string;
  prosvetRedirectUrl?: string;
  anketaForOrganisations?: string;
  newPupilFlow?: boolean;
  yandexRedirectUrl?: string;
  feature?: {
    selectClassDropdown: boolean;
    enableHttpErrorLogger: boolean;
    enableJavascriptErrorLogger: boolean;
    programsSpoVo?: {
      regionsToShow?: string[];
    };
  };
  demoBvbTinderUrl?: string;
  demoTinderUserId?: string[];
  yandexMetricCounterNumber?: number;
  accessEditNumber?: boolean;
  regionIdsToRedirectOnRegionalDashboard?: { id: string; url: string }[];
  b2cRedirectUrl?: string;
  mesRedirectUrl?: string;
  regionsWhereToShowNewParentAgreement?: string[];
  pilotUzbekistanRegionsId?: string[];
  peopleChangeCountryBannerLocationShown?: string[];
  parentsMeetingBannerShown?: string[];
  bashkortostanClosedLesson?: {
    regionId?: string;
    pupilLessonNumber?: number;
    teacherLessonNumber?: number;
  };
  archivedRegions?: string[];
  disableLessonsInTeacherLK?: boolean;
  disableLessonsInTeacherLKInRegions?: string[];
  disableTalentDiagnosticInPupilLK?: boolean;
  disableTalentDiagnosticInParentLK?: boolean;
}
