export const enum ApiCoursesMaterialsUrls {
  // lessons
  GetAllCourseMaterials = '/api/v1.0/lessons/coursesmaterials/all',
  GetOneCourseMaterial = '/api/v1.0/lessons/coursesmaterials/one',
  CourseMaterials = '/api/v1.0/lessons/coursesmaterials',
  CreateLesson = '/api/v1.0/lessons/coursesmaterials/addlesson',
  UpdateLesson = '/api/v1.0/lessons/coursesmaterials/updatelesson',
  DeleteLesson = '/api/v1.0/lessons/coursesmaterials/deletelesson',
  CopyCourseMaterialWithLessons = '/api/v1.0/lessons/coursesmaterials/copycoursematerialwithlessons',
  GetByCourseMaterialId = '/api/v1.0/lessons/lessons/getbycoursematerialid',
  GetLesson = '/api/v1.0/lessons/lessons/one',
  AllSetPassed = '/api/v1.0/lessons/lessons/allsetpassed',
  GetCourseById = '/api/v1.0/lessons/lessons/getbycoursematerialid',
  GetProfessionTaskActivities = '/api/v1.0/lessons/taskactivity/getprofessionstasksactivities',
  AddProfessionTaskActivities = '/api/v1.0/lessons/taskactivity/addprofessiontotaskactivity',
  GetPassed = '/api/v1.0/lessons/lessonactivity/getpassed',
  SetPassed = '/api/v1.0/lessons/lessonactivity/setpassed',
  GetCourseByClass = '/api/v1.0/lessons/courseactivity/getcoursebyclass',
  AssignCourseForClass = '/api/v1.0/lessons/courseactivity/assigncourseforclass',
  UpdateCourseForClass = '/api/v1.0/lessons/courseactivity/updatecourseforclass',

  // courses
  GetAllCourses = '/api/v1.0/catalog/courses/all',

  // tasks
  GetTaskById = '/api/v1.0/catalog/tasks/onebyid',
  SearchTasksByComplexity = '/api/v1.0/catalog/tasks/search/complexity',
}
