export enum ETrajectoryTypes {
  MASTER_CLASS = 1,
  EXCURSION = 2,
  SHORT_COURSE = 4,
  LONG_COURSE = 8,
  FESTIVAL = 16,
  ACTION = 32,
  MEETING = 64,
  COMPETITION = 128,
  PROFPROBA = 256,
  OPEN_DAY = 512,
  CATALOG_TASK = 1024,
}
