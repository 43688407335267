import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subject } from 'rxjs';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { StorageKeys, UserDataHandlerService, WebStorageService } from '@profilum-library';

@Component({
  selector: 'prf-navigation',
  standalone: true,
  imports: [CommonModule, RouterLink, TranslateModule],
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent extends UnsubscribeComponent {
  @Input() public buttonEvent: Subject<boolean>;
  @Input() public disableForward: boolean;
  @Input() public disableBack: boolean;
  @Input() public showAgreements: boolean;
  @Input() public isFinish: boolean = false;
  @Input() public showStartDiagnosticButton: boolean = false;
  @Input() public isFirstStep: boolean = false;

  public userRole: string = '';

  constructor(private userDataHandlerService: UserDataHandlerService, private webStorageService: WebStorageService) {
    super();

    this.userRole = this.userDataHandlerService.getUserInfo()?.role;

    if (!this.userRole) {
      this.userRole = this.webStorageService.get(StorageKeys.UserRole);
    }
  }

  public back(): void {
    if (this.disableBack) {
      return;
    }
    this.buttonEvent.next(false);
  }

  public forward(): void {
    if (this.disableForward) {
      return;
    }
    this.buttonEvent.next(true);
  }

  public getPath(): string {
    return `/${this.userRole}`;
  }
}
