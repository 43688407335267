import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { delay, retryWhen, scan, tap } from 'rxjs/operators';

const TIME_REPEAT: number = 1;

@Injectable()
export class ServerErrorsInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retryWhen(err =>
        err.pipe(
          scan((retryCount, err) => {
            if (retryCount > TIME_REPEAT) throw err;
            else return retryCount + 1;
          }, 0),
          delay(2000),
          tap(err => console.warn('warning! repeat request ==>', err)),
        ),
      ),
    );
  }
}
