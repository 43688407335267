<div class="filter-div cat" #priceFilter>
  <div class="professions-dropdown w-dropdown" [style.z-index]="filterOpen ? 1000 : 0">
    <div class="professions-dropdown-toggle w-dropdown-toggle" [class.active]="selectedFilter" (click)="filterOpen = !filterOpen">
      <div class="professions-dd-icon-2 w-icon-dropdown-toggle"></div>
      <div>{{ checkFilterHeader() }}</div>
    </div>
    <nav class="dd-prof-filter-list w-dropdown-list" [class.w--open]="filterOpen">
      <div class="dd-prof-filter-div _2">
        <div class="filter-list-row">
          <ng-container *ngIf="selectedFilter">
            <a class="clear-categories show" (click)="clearPrice()">{{ 'SHARED.CLEAR_BUTTON' | translate }}</a>
          </ng-container>
        </div>
        <div class="scroll-block">
          <div class="prof-categories-links">
            <a
              class="prof-categories-link w-inline-block"
              *ngFor="let filter of pricesFilters"
              [class.w--current]="selectedFilter === filter"
              (click)="setPriceFilter(filter)"
            >
              <div class="category-text-link">{{ filter.viewValue }}</div>
            </a>
          </div>
        </div>
      </div>
    </nav>
  </div>
</div>
