import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { SlidesState } from '@profilum-components/prf-tests/slides-store/slides.state';
import { Helper } from '@profilum-helpers/common-helper/helper';

declare global {
  interface Window {
    ym: (
      counterNumber: number,
      label: string,
      params: Record<string, any> | string,
      options?: Record<string, any> | string,
      callback?: () => void,
      ctx?: Record<string, any>,
    ) => void;
    ymCounterExist: () => void;
    ymCounterInitialized: () => void;
    ymData: () => void;
    ymHelper: () => void;
    testErrorLoggerService: () => void;
  }
}

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forRoot([SlidesState]),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: !Helper.isEnvironmentDev() }),
  ],
})
export class ProfilumComponentsModule {}
