import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { LandingB2cModule } from './b2c/landing-b2c.module';
import { LandingBaseModule } from './base/landing-base.module';
import { landingRoutes } from './landing-routing.config';
import { LandingComponent } from './landing/landing.component';
import { ProsvetAuthModule } from './prosvet-auth/prosvet-auth.module';
import { SeamlessEntryModule } from './seamlessentry/seamlessentry.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(landingRoutes),
    LandingBaseModule,
    LandingB2cModule,
    ProsvetAuthModule,
    SharedModule,
    SeamlessEntryModule,
  ],
  declarations: [LandingComponent],
})
export class LandingModule {}
