import { inject, Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { ApiProfilesUrls } from './api-profiles.collections';
import { IStatusAnswer } from '../b2g-saas';
import {
  ICreateClassesFormatsForProfessionsRequest,
  ICreateClassesFormatsForProfessionsResponse,
  IDeleteClassesFormatsForProfessionsResponse,
  IGetClassesFormatsForProfessionByUserIdResponse,
  IGetClassesFormatsForProfessionsResponse,
  IUpdateClassesFormatsForProfessionsRequest,
  IUpdateClassesFormatsForProfessionsResponse,
} from './api-profiles.interface';
import { HttpService } from '../http';

@Injectable({
  providedIn: 'root',
})
export class ApiProfilesService {
  private readonly httpService: HttpService = inject(HttpService);

  public setSharedResult(sessionId: string, userId: string, isChild: boolean, status: string): Observable<any> {
    return this.httpService.post(ApiProfilesUrls.SharedResult, { userId, sessionId, isChild, status }).pipe(take(1));
  }

  public updateSharedResult(sharedResultId: string, userId: string, sessionId: string): Observable<any> {
    return this.httpService.put(ApiProfilesUrls.SharedResult, { sharedResultId, userId, sessionId }).pipe(take(1));
  }

  public updateUserProfile(user: any): Observable<any> {
    return this.httpService.put(ApiProfilesUrls.UpdateUserProfile, user).pipe(take(1));
  }

  public getSharedResults(userId?: string): Observable<any> {
    return this.httpService.get(ApiProfilesUrls.GetSharedResult, { userId }).pipe(map((response: any) => response.sharedResults));
  }

  public changeUserAvatar(image: FileList): Observable<any> {
    const formData: FormData = new FormData();
    formData.append(image[0].name, image[0]);
    return this.httpService.postImage(ApiProfilesUrls.UploadAvatar, formData).pipe(take(1));
  }

  bindChild(parentUserId: string, childUserId: string): Observable<any> {
    return this.httpService.get(ApiProfilesUrls.BindChild, { parentUserId, childUserId }).pipe(take(1));
  }

  public careerLiteracySurvey(taskId: string, lessonId: string, userId: string, answer: string): Observable<IStatusAnswer> {
    return this.httpService.post(ApiProfilesUrls.CreateAfterTaskPupil, { taskId, lessonId, userId, answer }).pipe(take(1));
  }

  public getPortfolio(userId: string): Observable<any> {
    return this.httpService.get(ApiProfilesUrls.GetPortfolio, { userId }).pipe(take(1));
  }

  public addExistingPortfolioFile(portfolioFile): Observable<any> {
    return this.httpService.postFile(ApiProfilesUrls.AddPortfolioFile, portfolioFile);
  }

  public deletePortfolioFile(portfolioFileId: string): Observable<any> {
    return this.httpService.delete(ApiProfilesUrls.DeletePortfolio, { portfolioFileId }).pipe(take(1));
  }

  public addPortfolioFile(portfolioId: string, name: string, fileType: string, file: File): Observable<any> {
    const url = '/api/v1.0/profiles/portfolio/addportfoliofile';
    const params: Params = {
      portfolioId,
      name,
      fileType,
    };

    const formData: FormData = new FormData();
    formData.append(file.name, file);

    return this.httpService.postImage(url, formData, params).pipe(take(1));
  }

  public getClassesFormatsForProfessions(userId: string, professionId: string): Observable<IGetClassesFormatsForProfessionsResponse> {
    return this.httpService
      .get<IGetClassesFormatsForProfessionsResponse>(ApiProfilesUrls.ClassesFormatsForProfessions, {
        userId,
        professionId,
      })
      .pipe(take(1));
  }

  public createClassesFormatsForProfessions(
    data: ICreateClassesFormatsForProfessionsRequest,
  ): Observable<ICreateClassesFormatsForProfessionsResponse> {
    return this.httpService
      .post<ICreateClassesFormatsForProfessionsResponse>(ApiProfilesUrls.ClassesFormatsForProfessions, data)
      .pipe(take(1));
  }

  public updateClassesFormatsForProfessions(
    data: IUpdateClassesFormatsForProfessionsRequest,
  ): Observable<IUpdateClassesFormatsForProfessionsResponse> {
    return this.httpService
      .put<IUpdateClassesFormatsForProfessionsResponse>(ApiProfilesUrls.ClassesFormatsForProfessions, data)
      .pipe(take(1));
  }

  public deleteClassesFormatsForProfessions(userId: string, professionId: string): Observable<IDeleteClassesFormatsForProfessionsResponse> {
    return this.httpService
      .delete<IDeleteClassesFormatsForProfessionsResponse>(ApiProfilesUrls.ClassesFormatsForProfessions, {
        userId,
        professionId,
      })
      .pipe(take(1));
  }

  public getClassesFormatsForProfessionByUserId(userId: string): Observable<IGetClassesFormatsForProfessionByUserIdResponse> {
    return this.httpService
      .get<IGetClassesFormatsForProfessionByUserIdResponse>(ApiProfilesUrls.ClassesFormatsForProfessionByUserId, {
        userId,
      })
      .pipe(take(1));
  }
}
