import { Component, OnDestroy } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'prf-page-news',
  templateUrl: './page-news.component.html',
  styleUrls: ['./page-news.component.scss'],
})
export class PageNewsComponent implements OnDestroy {
  public userRole: string = '';
  public newsId: string = '';

  constructor(private meta: Meta, private activatedRoute: ActivatedRoute) {
    this.meta.updateTag({ name: 'og:title', content: 'Страница новостей' });
    this.userRole = localStorage.getItem('userRole');
    this.activatedRoute.params.subscribe((params: Params) => {
      this.newsId = params['id'];
    });
  }

  ngOnDestroy() {}
}
