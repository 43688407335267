<div class="page-switcher-container">
  <div class="page-switcher">
    <a
      *ngFor="let item of valuesArray"
      class="page-switcher__item"
      (click)="selectMenu(item)"
      [class.active]="!isDisabled && selectedMenu === item"
      [class.no-active]="isDisabled"
      >{{ item.name }}</a
    >
  </div>
</div>
