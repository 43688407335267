import { Component, ElementRef, HostListener, OnInit, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { SharedService } from 'app/shared/shared.service';
import { FavoritesService } from 'app/pages/catalog/favorites/favorites.service';
import { AppSettingsService } from 'app/shared/services/appsettings.service';
import { Observable, Subject } from 'rxjs';
import { ProjectVariables } from '../../../core/project.variables';
import { ResultsService } from '../../results/results.service';
import { PupilPanelService } from './pupil-panel.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EDiagnStateEnum, IPupilDiagnosticInterface } from 'app/shared/interfaces/idiagnostic.interface';
import { switchMap, tap } from 'rxjs/operators';
import { TESTS_VARIANTS_IDS } from 'app/shared/global-constants/tests-variants.data';
import { IUserInfo, UserDataHandlerService } from '@profilum-library';

export const DiagnosticsList: IPupilDiagnosticInterface[] = [
  {
    name: 'Таланты',
    description: 'Узнай о своих интересах и способностях',
    screeningTestId: TESTS_VARIANTS_IDS.passportOfTalents,
    diagnosticState: EDiagnStateEnum.TEST_OPENED,
    route: '/test-na-professiyu',
    results: '/results',
  },
  {
    name: 'Мотивация',
    description: 'Выясни, что тебя заряжает',
    screeningTestId: TESTS_VARIANTS_IDS.motivationtest,
    diagnosticState: EDiagnStateEnum.NOT_OPENED,
    route: '/motivation-test',
    results: '/motivation-test-results',
  },
  {
    name: 'Карьерные типы',
    description: 'Посмотри, какой путь тебе ближе',
    screeningTestId: TESTS_VARIANTS_IDS.careerTypes,
    diagnosticState: EDiagnStateEnum.NOT_OPENED,
    route: '/career-test',
    results: '/career-test-results',
  },
  {
    name: 'Ценности',
    description: 'Найди, что важно именно для тебя',
    screeningTestId: TESTS_VARIANTS_IDS.valuesTest,
    diagnosticState: EDiagnStateEnum.NOT_OPENED,
    route: '/values-test',
    results: '/values-test-results',
  },
];

export const DiagnosticsHints = {
  awaitMeeting: 'Дождись встречи с экспертом — он откроет полные результаты',

  resultsAvailable: 'Диагностика пройдена, тебе доступны полные результаты',
};

export const DashboardStatuses = {
  studing: {
    title: 'Диагностика — это как поговорить',
    text: 'Только коротко и по делу. Так мы делаем наши встречи интереснее: больше времени уделяем обсуждению, меньше — расспросам.',
  },
  action: {
    title: 'Тьютор открыл тебе новую диагностику',
    text: 'Давай обсудим ещё одну интересную тему. </br>Жми на синюю кнопку и вперёд! :)',
  },
};

@Component({
  selector: 'prf-pupil-panel',
  templateUrl: './pupil-panel.component.html',
  styleUrls: ['./pupil-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PupilPanelComponent implements OnInit {
  userData: IUserInfo;
  lastTestResults: any = [];
  goalsProfessions: any = [];
  isWelcome: boolean = false;
  stepTwo: boolean = false;
  dataFetched: boolean = false;
  lastSessionId: string = '';
  sessionStatus: string = '';
  public selectLanguage: boolean = false;
  sessionId: string = null;

  public diagnosticsIds = TESTS_VARIANTS_IDS;

  dashboardStatus: { title: string; text: string } = DashboardStatuses.studing;

  screeningTestsIdsList: string[] = [];

  sessionList: any[] = [];
  public motivationLastSessionId: string;
  public valuesLastSessionId: string;
  public careerSessionId: string;

  selectedDiagnostic: IPupilDiagnosticInterface;

  isOnboarding: boolean = false;
  title: string = 'Главная';

  dashboardStatuses = DashboardStatuses;
  public diagnosticsHints = DiagnosticsHints;

  screeningTestsAccesses: any[] = [];

  public window = window;

  isLoading: boolean = false;

  public isMobile: boolean = false;

  diagnosticsFullList: IPupilDiagnosticInterface[] = DiagnosticsList;
  diagnosticsList: IPupilDiagnosticInterface[] = [];

  @ViewChildren('popup') popup: QueryList<ElementRef>;

  private ngUnsubscribe$ = new Subject<any>();

  constructor(
    private meta: Meta,
    private pupilPanelService: PupilPanelService,
    private resultsService: ResultsService,
    private sharedService: SharedService,
    private favoritesService: FavoritesService,
    private projectVariables: ProjectVariables,
    private appSettingsService: AppSettingsService,
    private route: ActivatedRoute,
    private userDataHandlerService: UserDataHandlerService,
    private router: Router,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'Личный кабинет ученика' });
    // если выполняется совпадение regionId из appsettings с выбранным регионом пользователя и для данного региона разрешена смена языка (isAccessible)
    this.selectLanguage =
      AppSettingsService.settings.regionLanguages &&
      AppSettingsService.settings.regionLanguages.isAccessible &&
      AppSettingsService.settings.regionId == localStorage.getItem('regionId');

    DiagnosticsList.forEach(diagn => {
      if (diagn.screeningTestId) {
        this.screeningTestsIdsList.push(diagn.screeningTestId);
      }
    });
    this.isOnboarding = this.router.getCurrentNavigation()?.extras.state?.needOnboarding;

    this.isMobile = window.innerWidth <= 450;
  }

  ngOnInit() {
    this.isLoading = true;
    this.userDataHandlerService.getUserData().subscribe(value => {
      this.userData = value;
      if (this.userData) {
        this.getData();
      }
    });
    this.lastSessionId = localStorage.getItem('testSessionId');
    this.sessionStatus = localStorage.getItem('sessionStatus');
  }

  getData() {
    this.getTestAccesses()
      .pipe(
        switchMap(() =>
          this.getSessionStatus(this.userData.userId).pipe(
            tap(() => {
              // проверка, что результаты открыты
              this.screeningTestsAccesses.forEach(testElement => {
                const index = this.diagnosticsList.indexOf(
                  this.diagnosticsList.find(el => el.screeningTestId === testElement.screeningTestId),
                );
                if (testElement.isTestResult && this.diagnosticsList[index].diagnosticState === EDiagnStateEnum.IS_TESTED) {
                  this.diagnosticsList[index].diagnosticState = EDiagnStateEnum.RESULTS_OPENED;
                }
              });
              if (
                this.diagnosticsList.some(diagn => {
                  return diagn.diagnosticState === EDiagnStateEnum.TEST_OPENED;
                })
              ) {
                this.dashboardStatus = DashboardStatuses.action;
              }
            }),
          ),
        ),
      )
      .subscribe(() => (this.isLoading = false));
  }

  getTestAccesses(): Observable<any> {
    return this.pupilPanelService.getUserTestsAccesses().pipe(
      tap((responce: any) => {
        if (responce.status === 'Success') {
          this.screeningTestsAccesses = responce.screeningTestsAccesses;
          responce.screeningTestsAccesses.forEach(testElement => {
            const index = this.diagnosticsFullList.indexOf(
              this.diagnosticsFullList.find(diag => diag.screeningTestId === testElement.screeningTestId),
            );

            //проверка, что тест открыт
            if (index > -1) {
              if (testElement.isTestPassing || testElement.isTestResult) {
                const diagnosticToPush = this.diagnosticsFullList[index];
                Object.assign(diagnosticToPush, {
                  createdDate: this.screeningTestsAccesses.find(el => el.screeningTestId === diagnosticToPush.screeningTestId)?.createdDate,
                });
                this.diagnosticsList.push(diagnosticToPush);

                if (
                  testElement.isTestPassing &&
                  this.diagnosticsList[this.diagnosticsList.length - 1].diagnosticState === EDiagnStateEnum.NOT_OPENED
                ) {
                  this.diagnosticsList[this.diagnosticsList.length - 1].diagnosticState = EDiagnStateEnum.TEST_OPENED;
                }
              }
            }

            this.diagnosticsList.sort((a, b) => (Date.parse(a.createdDate) < Date.parse(b.createdDate) ? 1 : -1));
          });
        }
      }),
    );
  }

  private getSessionStatus(pupilId): Observable<any> {
    return this.pupilPanelService.getSessionList(pupilId).pipe(
      tap((pupilSessions: any[]) => {
        this.sessionList = pupilSessions;

        this.motivationLastSessionId = pupilSessions
          .filter(session => session.screeningTestId === this.diagnosticsIds.motivationtest && session.completed)
          .sort((a, b) => (Date.parse(a.createdDate) < Date.parse(b.createdDate) ? 1 : -1))[0]?.sessionId;

        this.valuesLastSessionId = pupilSessions
          .filter(session => session.screeningTestId === this.diagnosticsIds.valuesTest && session.completed)
          .sort((a, b) => (Date.parse(a.createdDate) < Date.parse(b.createdDate) ? 1 : -1))[0]?.sessionId;

        // проверка, что тест пройден
        this.screeningTestsIdsList.forEach(testId => {
          const lastSessionCompleted = this.sessionList
            .filter(session => session.screeningTestId === testId && session.completed)
            .sort((a, b) => (Date.parse(a.createdDate) < Date.parse(b.createdDate) ? 1 : -1))[0];

          if (lastSessionCompleted) {
            const index = this.diagnosticsList.findIndex(diagnotic => diagnotic.screeningTestId === testId);

            if (index > -1) {
              this.diagnosticsList[index].diagnosticState = EDiagnStateEnum.IS_TESTED;
            }
          } else {
            //ищем незавершенную сессию
            const lastSessionIncompleted = this.sessionList
              .filter(session => session.screeningTestId === testId && session.completed === false)
              .sort((a, b) => (Date.parse(a.createdDate) < Date.parse(b.createdDate) ? 1 : -1))[0];
            if (lastSessionIncompleted) {
              const index = this.diagnosticsList.indexOf(this.diagnosticsFullList.find(diag => diag.screeningTestId === testId));
            }
          }
        });
      }),
    );
  }

  openResultsPage(diagnostic?: IPupilDiagnosticInterface) {
    if (diagnostic) {
      if (diagnostic.diagnosticState >= EDiagnStateEnum.IS_TESTED) {
        this.selectedDiagnostic = this.diagnosticsList.find(diag => diag.screeningTestId === diagnostic.screeningTestId);
        //selectedDiagnostic нужна для отображения результатов
        if (this.selectedDiagnostic) {
          // TODO добавить case для career test
          switch (this.selectedDiagnostic.screeningTestId) {
            case TESTS_VARIANTS_IDS.valuesTest:
              this.router.navigate([this.selectedDiagnostic.results + '/' + this.valuesLastSessionId]);
              break;
            case TESTS_VARIANTS_IDS.motivationtest:
              this.router.navigate([this.selectedDiagnostic.results + '/' + this.motivationLastSessionId]);
              break;
            default:
              this.router.navigate([this.selectedDiagnostic.results]);
          }
        }
      }
    }
  }

  routingTest(diagnostic: IPupilDiagnosticInterface) {
    if (diagnostic) {
      const a = origin + diagnostic.route;
      this.router.navigate([diagnostic.route]);
    }
  }

  finishOnboarding() {
    this.isOnboarding = false;
    this.router.navigate(['/test-na-professiyu']);
  }

  showHintMobile(statusContainer, popup) {
    if (popup.style.display != 'block') {
      popup.style.display = 'block';
    } else {
      popup.style.display = 'none';
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target?.innerWidth;
    this.isMobile = event.target.innerWidth <= 450;
  }

  @HostListener('window:click', ['$event.target'])
  onClick(target) {
    const popups = this.popup;
    const statusBars = Array.from(document.getElementsByClassName('status-container'));
    if (this.isMobile && !statusBars.some(el => el.contains(target))) {
      popups.forEach(popup => {
        popup.nativeElement.setAttribute('style', 'display: none');
      });
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
