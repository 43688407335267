import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';

import { SPIDER_CHART_CONFIG } from '../spider-chart-custom.config';
import { ISpiderChartConfig } from '../spider-chart-custom.model';

@Component({
  selector: 'prf-spider-chart-different-block',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  templateUrl: './spider-chart-different-block.component.html',
  styleUrls: ['./spider-chart-different-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpiderChartDifferentBlockComponent {
  protected readonly SPIDER_CHART_CONFIG: ISpiderChartConfig = SPIDER_CHART_CONFIG;

  @Input({ required: true }) public differentIconStylePosition: string;
}
