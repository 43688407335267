import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'prf-codes-children',
  templateUrl: './codes-children.component.html',
  styleUrls: ['./codes-children.component.scss'],
})
export class CodesChildrenComponent implements OnInit {
  @Input() children: Array<any> = [];
  public showToolTipe: boolean = false;

  constructor() {}

  ngOnInit() {}
}
