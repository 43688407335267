import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
export interface ISwitcherItem {
  name: string;
  value: string;
}

@Component({
  selector: 'prf-page-switcher',
  templateUrl: './page-switcher.component.html',
  styleUrls: ['./page-switcher.component.scss'],
})
export class PageSwitcherComponent implements OnInit {
  @Input() valuesArray: ISwitcherItem[];
  @Input() isDisabled: boolean = false;
  @Output() selectedMenuEmitter = new EventEmitter();
  public selectedMenu: ISwitcherItem;
  ngOnInit(): void {
    this.selectedMenu = this.valuesArray[0];
  }

  public selectMenu(menuItem: ISwitcherItem) {
    this.selectedMenu = menuItem;
    this.selectedMenuEmitter.emit(this.selectedMenu);
  }
}
