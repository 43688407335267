import { inject, Injectable } from '@angular/core';
import { HttpService } from '../http';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ApiFavoritesUrls, ProductTypes } from './api-favorites.collections';

@Injectable({
  providedIn: 'root',
})
export class ApiFavoritesService {
  private readonly httpService: HttpService = inject(HttpService);

  public getFavorites(userId: string, productType?: ProductTypes | string): Observable<any> {
    return this.httpService.get(ApiFavoritesUrls.GetFavorites, { userId, productType }).pipe(map((response: any) => response.favorites));
  }

  public addToFavorites(productId: string, productType: string, userId: string): Observable<any> {
    return this.httpService.post(ApiFavoritesUrls.AddFavorites, { productId, productType, userId }).pipe(take(1));
  }

  public deleteFromFavorites(favoriteId: string): Observable<any> {
    return this.httpService.delete(ApiFavoritesUrls.DeleteFavorites, { favoriteId }).pipe(take(1));
  }
}
