<form novalidate #f="ngForm" (ngSubmit)="onSubmit(f)">
  <div class="settings-photo">
    <div class="avatar">
      <img *ngIf="_user.imagePath != null" [src]="_user?.imagePath" (error)="updateUrl($event)" />
      <span (click)="uploadAvatar()">АЯ</span>
      <input type="file" hidden #fileInput (change)="selectFile($event)" />
    </div>
  </div>
  <button type="submit" mat-raised-button>Сохранить изображение</button>
</form>
