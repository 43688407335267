import { Routes } from '@angular/router';
import { MainGuard } from '../core/guards/main.guard';
import { LoginB2cComponent } from './b2c/login-b2c/login-b2c.component';

export const landingRoutes: Routes = [
  {
    path: '',
    component: LoginB2cComponent,
    canActivate: [MainGuard],
    data: { hideHeader: true, userLogout: false },
  },
  {
    path: 'b2c',
    loadChildren: () => import('./b2c/landing-b2c.module').then(m => m.LandingB2cModule),
  },
];
