import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { InternshipService } from '../../../../shared/dashboard/internships/internship.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserProfileService } from 'app/pages/catalog/user-profile-page/user-profile.service';
import { InternshipsService } from 'app/shared/dashboard/internships/internships.service';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { InternshipDetailsService } from './internship-details.service';

import { AGE_DATA, COMPLEXITYS, COURSES_TYPES } from 'app/shared/global-constants/constants';
import { IApplyInternship } from 'app/shared/interfaces/iapplyinternship.interface';
import { ISchool } from 'app/shared/interfaces/ischool.interface';
import { OverlayBusyService } from 'app/shared/overlay-busy/overlay-busy.service';
import * as moment from 'moment';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { SharedService } from 'app/shared/shared.service';
import { Observable, of, Subject } from 'rxjs';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { FavoritesService } from 'app/pages/catalog/favorites/favorites.service';
import { UserDataHandlerService } from '@profilum-library';

//todo internships favorites не включены в основной favorites, пока не делал для них логику!
@Component({
  selector: 'prf-internship-details',
  templateUrl: './internship-details.component.html',
  styleUrls: ['./internship-details.component.scss'],
})
export class InternshipDetailsComponent implements OnInit {
  internship: any;
  types: any = COURSES_TYPES;

  ageData: any = AGE_DATA;
  isFavorite: boolean = false;
  otherInternships: any = [];
  similarInternships: any = [];
  favoritesInternships: any;
  internshipEnrolls: any;
  userId: string;
  startDate: string;

  hrid: string;
  title: string;
  mapHeader: string;
  userRole: string;
  adminLevel: string = '';
  color: string;
  createDate: string;
  complexity: string = '';

  isEnrolled: boolean = false;
  dataFetched: boolean = false;
  popUpConfirming: boolean = false;
  addtoArchive: boolean = false;
  mapsLoaded: boolean = false;

  moment: any;
  testCompleted: boolean = false;
  applyInternship: IApplyInternship = {
    userId: '',
    internship: '',
    regionId: '',
    municnipalityId: '',
    schoolId: '',
    schoolClass: '',
  };
  school: ISchool;
  userClass: any;

  private ngUnsubscribe$ = new Subject<any>();

  constructor(
    private meta: Meta,
    private internshipDetailsService: InternshipDetailsService,
    private profileService: UserProfileService,
    private internshipService: InternshipService,
    private route: ActivatedRoute,
    private internshipsService: InternshipsService,
    private utilsService: UtilsService,
    private overlayService: OverlayBusyService,
    private sharedService: SharedService,
    private favoritesService: FavoritesService,
    private userDataHandlerService: UserDataHandlerService,
    private router: Router,
  ) {
    const userInfo = this.userDataHandlerService.getUserInfo();

    this.meta.updateTag({ name: 'og:title', content: 'Стажировка' });
    this.userRole = userInfo.role;
    if (this.userRole && this.userRole.includes('admin')) {
      this.adminLevel = localStorage.getItem('adminLevel');
    }
    this.userId = userInfo.userId;
    this.moment = moment;
    this.moment.locale('ru');
  }

  ngOnInit() {
    this.overlayService.show();
    this.setTitles();
    const sessionStatus = localStorage.getItem('sessionStatus');
    this.testCompleted = sessionStatus === 'Success';
    this.route.params
      .pipe(
        switchMap(params => {
          this.hrid = params['hrid'];
          window.scrollTo(0, 0);
          return this.loadCurrentInternship();
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  loadCurrentInternship(): Observable<any> {
    return this.internshipService.getInternshipByHRID(this.hrid).pipe(
      switchMap(data => {
        this.internship = data;
        const complexity = COMPLEXITYS.find(c => c.value === this.internship.complexity);
        this.complexity = complexity ? complexity.viewValue : '';
        this.startDate = this.moment(this.internship.startDate).format('D MMMM YYYY');

        let classEnrollObservable$: Observable<any> = of(null);
        if (this.userRole === 'pupil') {
          classEnrollObservable$ = this.loadFavorites().pipe(
            switchMap(r =>
              this.internshipDetailsService.getClassEnroll(this.userId, this.internship.id).pipe(
                tap(classEnroll => {
                  this.isEnrolled = classEnroll && classEnroll.isDeleted === false;
                }),
              ),
            ),
          );
        }

        return forkJoin([this.loadOtherCourses(), classEnrollObservable$]).pipe(
          tap(r => {
            this.dataFetched = true;
            this.overlayService.hide();
          }),
        );
      }),
    );
  }

  loadFavorites(): Observable<any> {
    // TODO данного объекта нет в избранном
    // внести правки, если internships будут добавлены
    return this.internshipDetailsService.getFavoriteInternships().pipe(
      tap(r => {
        this.favoritesInternships = r;
        this.internshipDetailsService.addFavoriteInternships(this.favoritesInternships);
        if (this.favoritesInternships) {
          const productIds = this.favoritesInternships.filter(el => el.productId === this.internship.id);
          this.isFavorite = productIds.length > 0;
        }
      }),
    );
  }

  // async loadSchoolClass() {
  //   this.school = await this.sharedService.getSchool();
  //   const schcl = await this.profileService.getSchoolClass();
  //   this.userClass = schcl[0];
  // }

  addToGoals() {
    //this.loadSchoolClass();
    forkJoin([this.sharedService.getSchool(), this.profileService.getSchoolClass()])
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(([school, schcl]) => {
        this.school = school;
        this.userClass = schcl[0];
        this.applyInternship = {
          userId: this.userId,
          internship: this.internship.name,
          regionId: this.internship.regionId,
          municnipalityId: this.internship.municipalityId,
          schoolId: localStorage.getItem('schoolId'),
          schoolClass: this.userClass.className,
        };
        this.internshipDetailsService.sendAplyForInternship(this.applyInternship);
        this.goToInternship();
      });
  }

  // removeGoal() {
  // if (this.currentFavoriteIds) {
  //   this.currentFavoriteIds.forEach(favoriteId => this.goalsService.deleteGoal(favoriteId));
  //   setTimeout(() => this.router.navigate(['/' + this.userRole]), 1000);
  // }
  // }

  archive(internship) {
    internship.isArchived = true;
    this.internshipsService
      .changeInternshipsDO(internship.id, internship)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(r => {
        this.utilsService.openSnackBar('👌 Стажировка добавлена в архив', 'success');
        return setTimeout(_ => {
          this.router.navigate(['/admin/internships']);
        }, 2000);
      });
  }

  loadOtherCourses(): Observable<any> {
    if (this.internship.institutionId && this.internship.institutionId !== '00000000-0000-0000-0000-000000000000') {
      return forkJoin([
        this.internshipService.getOtherInternships(this.internship.institutionId),
        this.internshipService.getSimilarInternship(this.internship.internships),
      ]).pipe(
        tap(([otherInternships, similarInternships]) => {
          this.otherInternships = otherInternships.filter(d => d.id !== this.internship.id);
          this.similarInternships = similarInternships.filter(d => d.id !== this.internship.id);
        }),
      );
    } else {
      return of(null);
    }
  }

  addToFavorite() {
    this.internshipDetailsService
      .addToFavorites(this.internship.id, this.userId)
      .pipe(switchMap(r => this.loadFavorites()))
      .subscribe();
  }

  removeFavorite() {
    const filteredFavorites = this.favoritesInternships.filter(el => el.productId === this.internship.id);
    const internshipFavoriteIds = filteredFavorites.map(el => el.id);
    if (internshipFavoriteIds) {
      internshipFavoriteIds.forEach(favoriteId => this.internshipDetailsService.deleteInternshipFromFavorites(favoriteId));
      this.isFavorite = false;
    }
  }

  goToInternship() {
    if (!this.isEnrolled) {
      this.internshipDetailsService
        .addClassEnroll(this.internship.id)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(r => (this.isEnrolled = true));
    }
  }

  cancelClassEnroll() {
    if (this.isEnrolled) {
      this.internshipDetailsService
        .removeClassEnroll(this.internship.id, this.userId)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(r => (this.isEnrolled = false));
    }
  }

  getAges() {
    // let agesArray = [];
    // this.course.audience.forEach(age => agesArray.push(this.ageData.find(el => el.value === age).label));
    // let string = agesArray;
    // if (agesArray.length > 1) {
    //   let startSympols = agesArray[0].slice(0, agesArray[0].indexOf('-'));
    //   string = startSympols + agesArray[agesArray.length - 1].slice(agesArray[agesArray.length - 1].indexOf('-'))
    // }
    // return string
    if (this.internship.maxAgeAudience != 100 && this.internship.minAgeAudience != 0) {
      return 'от ' + this.internship.minAgeAudience + ' до ' + this.internship.maxAgeAudience + ' лет';
    } else if (this.internship.maxAgeAudience == 100) {
      return this.internship.minAgeAudience + '+ лет';
    } else if (this.internship.minAgeAudience == 0) {
      return 'до ' + this.internship.maxAgeAudience + ' лет';
    }
  }

  setTitles() {
    const path = this.router.routerState.snapshot.url;
    if (path.includes('/internships/')) {
      this.title = 'Стажировки';
      this.mapHeader = 'Стажировки на карте';
    }
  }

  backButtonUrl() {
    switch (this.userRole) {
      case 'admin':
        return '/admin/internships';
      case 'schooladmin':
        return '/schooladmin/internships';
      default:
        return '/internships';
    }
  }

  deleteInternship(internshipID) {
    this.internshipsService
      .removeCurrentInternship(internshipID)
      .pipe(take(1))
      .subscribe(r => {
        this.popUpConfirming = !this.popUpConfirming;
        return this.router.navigate(['/admin/internships']);
      });
  }

  checkMenuRole() {
    switch (this.userRole) {
      case 'pupil':
      case 'parent':
        return true;
      default:
        return false;
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
