export enum ApiSchoolsUrls {
  // Schools
  Schools = '/api/v1.0/catalog/schools',
  GetSchoolById = '/api/v1.0/catalog/schools/onebyid',
  SchoolsUpdate = '/api/v1.0/catalog/schools/update',

  //Classes
  GetFilteredClassesElastic = '/api/v1.0/catalog/classes/elasticsearch/search/filters',
  OneById = '/api/v1.0/catalog/classes/mongo/onebyid',
  ClassesCountStatistics = '/api/v1.0/catalog/classes/mongo/classescountstatistic',
  ClassesCountStatisticsElastic = '/api/v1.0/catalog/classes/elasticsearch/classescountstatistic',
  ReloadClasses = '/api/v1.0/catalog/classes/reloadclasseselasticindex',
  GetClassesCountElastic = '/api/v1.0/catalog/classes/elasticsearch/count/filters',
  GetCourseByHrid = '/api/v1.0/catalog/classes/mongo/onebyhrid',
  CreateClassesDO = '/api/v1.0/catalog/classes/mongo/create',
  ChangeClassesDO = '/api/v1.0/catalog/classes/mongo/update',
  RemoveCurrentClass = '/api/v1.0/catalog/classes/mongo/delete',
  CheckHrid = '/api/v1.0/catalog/classes/mongo/checkhrid',
  GetClassesCount = '/api/v1.0/catalog/classes/mongo/count/filters',
  GetFilteredClasses = '/api/v1.0/catalog/classes/mongo/search/filters',
}
