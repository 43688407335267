export interface IChatBotMessage {
  text: string;
  icon?: string;
  type?: string;
  image?: string;
  position?: string;
}

export interface IChatBotButton {
  type: string;
  name: string;
  visible?: string;
  messages?: IChatBotMessage[];
}

export interface IChatBotSection {
  type: string;
  messages: IChatBotMessage[];
  buttons: IChatBotButton[];
}

export interface IChatBotData {
  id: string;
  regionId: string;
  name: string;
  sections: IChatBotSection[];
}

export interface IResponseChatBots {
  comment: string;
  senderService: string;
  status: string;
}

export interface IResponseGetChatBotById extends IResponseChatBots {
  regionChatBot: IChatBotData | null;
}

export interface IResponseGetChatBots extends IResponseChatBots {
  regionChatBots: IChatBotData[] | null;
}
