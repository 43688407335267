export const enum Storages {
  Local,
  Session,
  Cookie,
  UserStorage,
}

export const enum StorageKeys {
  CurrentClassInfo = 'currentClassInfo',
  SelectedMenuItem = 'selectedMenuItem',
  SelectedLanguage = 'selectedLanguage',
  UserRole = 'userRole',
  RegionId = 'regionId',
  MunicipalityId = 'municipalityId',
  TestSessionId = 'testSessionId',
  TestType = 'testType',
  Tag = 'tag',
  SessionStatus = 'sessionStatus',
  ImagePath = 'imagePath',
  FirstName = 'firstName',
  UserId = 'userId',
  Email = 'email',
  SlidesNumber = 'slidesNumber',
  IsPilot = 'isPilot',
  IsVisuallyImpairedVersion = 'isVisuallyImpairedVersion',
  IsDemoUserBvb = 'isDemoUserBvb',
  TeacherId = 'teacherId',
  SchoolClassId = 'schoolClassId',
  AssignedCourseExists = 'assignedCourseExists',
  CoursesIds = 'coursesIds',
  LastName = 'lastName',
  SchoolId = 'schoolId',
  UserGender = 'userGender',
  AdminLevel = 'adminLevel',
  SchoolIdParam = 'schoolIdParam',
  CompanyId = 'companyId',
  Position = 'position',
  ClassIdParam = 'classIdParam',
  LastRoute = 'lastRoute',
  SkipCaching = 'skipCaching',
  IsAuthorized = 'isAuthorized',
  UserAccess = 'userAccess',
  Issuer = 'issuer',
  IsFirstSession = 'isFirstSession',
  RegionIdParam = 'regionIdParam',
  CityIdParam = 'cityIdParam',
  FirstNameChild = 'firstNameChild',
  LastNameChild = 'lastNameChild',
  BirthdayChild = 'birthdayChild',
  PhoneChild = 'phoneChild',
  GenderChild = 'genderChild',
  AddChildPopupShouldBeOpened = 'addChildPopupShouldBeOpened',
  WelcomeStep = 'welcomeStep',
  ProviderName = 'providerName',
  City = 'city',
  DefaultEdFilters = 'defaultEdFilters',
  RefferalUserId = 'RefferalUserId',
  RefferalUserGender = 'RefferalUserGender',
  LastSession = 'lastSession',
  CourseId = 'courseId',
  LessonId = 'lessonId',
  TaskId = 'taskId',
  CityId = 'cityId',
  ShowCreatedClassPopupInfo = 'showCreatedClassPopupInfo',
  TeacherStatus = 'teacherStatus',
  ClassCourseChapterInfo = 'classCourseChapterInfo',
  ActiveLessons = 'activeLessons',
  ClassNumber = 'classNumber',
  ClassLetter = 'classLetter',
  CourseMaterialId = 'courseMaterialId',
  TestLang = 'testLang',
  SharedSession = 'sharedSession',
  Anketa = 'anketa',
  Test = 'test',
  RedirectTestType = 'redirectTestType',
  IsInvitationCopied = 'isInvitationCopied',
  IsRedirectAfterRegistrationOnKruzhki = 'isRedirectAfterRegistrationOnKruzhki',
  Course = 'course',
  ClassesArray = 'classesArray',
  Players = 'players',
  ResultsType = 'resultsType',
  LastSessionId = 'lastSessionId',
  TestSessionIdCapabilities = 'testSessionIdCapabilities',
  TestSessionIdInterests = 'testSessionIdInterests',
  TestSessionIdICareerLiteracy = 'testSessionIdCareerLiteracy',
  LastSessionIdCapabilities = 'lastSessionIdCapabilities',
  LastSessionIdInterests = 'lastSessionIdInterests',
  LastSessionIdCareerLiteracy = 'lastSessionIdCareerLiteracy',
  TeacherHasNoClasses = 'teacherHasNoAClasses',
  CurrentChild = 'currentChild',
  UserInfo = 'userInfo',
  Onbording = 'onbording',
  FirstVisitLessons = 'firstVisitLessons',
  OldPassedPupilsForTeacher = 'oldPassedPupilsForTeacher',
  BubbleBoxDesktopLines = 'bubbleBoxDesktopLines',
  BubbleBoxTabletLines = 'bubbleBoxTabletLines',
  ClassesFormatsForProfessionForDemo = 'classesFormatsForProfessionForDemo',
  DiscoverProfessionDemoFields = 'discoverProfessionDemoFields',
  DiscoverProfessionDemoReferer = 'discoverProfessionDemoReferer',
  DiscoverProfessionDemoProjectLabel = 'discoverProfessionDemoProjectLabel',
  IsRASVersion = 'isRASVersion',
  ScreeningTestIdForPlayer = 'screeningTestIdForPlayer',
  PageInfo = 'pageInfo',
  UploadedOriginalUserPhoto = 'uploadedOriginalUserPhoto',
  CanGoForward = 'canGoForward',
  NoElementsToSelect = 'noElementsToSelect',
  FontSizeLabel = 'fontSizeLabel',
  OnbordingSpoVo = 'onbordingSpoVo',
  TryingOnStageShowedSteps = 'tryingOnStageShowedSteps',
  TeacherWelcomePopup = 'teacherWelcomePopup',
  IdOnboardingNotification = 'idOnboardingNotification',
  FittingProfessionIds = 'fittingProfessionIds',
  FittingShowedNotification = 'fittingShowedNotification',
  ApplyProfessionFilters = 'applyProfessionFilters',
  ApplyProfessionSort = 'applyProfessionSort',
  FilteredProfessionCategory = 'filteredProfessionCategory',
  PromoTag = 'promoTag',
  PromoTestingChildId = 'promoTestingChildId',
  TryOnLessonId = 'tryOnLessonId',
  FilteredProfessionCategoryId = 'filteredProfessionCategoryId',
  ProfessionOptions = 'professionOptions',
  InterestsDiagnosticLessonData = 'interestsDiagnosticLessonData',
  AptitudeDiagnosticLessonData = 'aptitudeDiagnosticLessonData',
  ProfessionsTryOn = 'professionsTryOn',
  ProfessionTryingOnData = 'professionTryingOnData',
  PassedTeacherTryingOnLesson = 'passedTeacherTryingOnLesson',
  ShowedTryingOnBanner = 'showTryingOnBanner',
  RangingOnboarding = 'rangingOnboarding',
  VariantTestIdForPlayer = 'variantTestIdForPlayer',
  CurrentLessonNumber = 'currentLessonNumber',
  TestMufPupilName = 'testMufPupilName',
  TestMyFuturePupilName = 'TestMyFuturePupilName',
  TinderProfessionsByIndustry = 'tinderProfessionsByIndustry',
  TinderFavouritesCounter = 'tinderFavouritesCounter',
  TinderProfessionsCounter = 'tinderProfessionsCounter',
  TinderState = 'tinderState',
  TinderClosedContourState = 'tinderClosedContourState',
  TinderOnboardingSteps = 'tinderOnboardingSteps',
  TinderCompleteResultsState = 'tinderCompleteResultsState',
  OnlyForward = 'onlyForward',
  TestRSAPupilName = 'testRSAPupilName',
  Step = 'step',
  IsFirstVisitBvbDiscoverProfession = 'isFirstVisitBvbDiscoverProfession',
  DiscoverProfessionDemoUserState = 'discoverProfessionDemoUserState',
  ShowSuspensionMessage = 'showSuspensionMessage'
}

export const IncludedToUserStorageKeys: Set<StorageKeys> = new Set<StorageKeys>([
  StorageKeys.UserId,
  StorageKeys.OldPassedPupilsForTeacher,
  StorageKeys.ClassCourseChapterInfo,
  StorageKeys.ActiveLessons,
  StorageKeys.ProfessionTryingOnData,
  StorageKeys.TryingOnStageShowedSteps,
  StorageKeys.ShowedTryingOnBanner,
  StorageKeys.TryOnLessonId,
  StorageKeys.TinderState,
]);

export const IncludedToRamStorageKeys: Set<StorageKeys> = new Set<StorageKeys>([StorageKeys.UserRole]);
