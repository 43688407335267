export interface IFilterInternships {
  TalentsName?: string[];
  classesFormat?: string[];
  CourseGroups?: string[];
  Internships?: string[];
  Audience?: string;
  PublishedDate?: any;
  Courses?: string[];
  institutionIds?: string[];
  regionId?: string;
  municipalityId?: string;
  from?: number;
  size?: number;
  isArchived?: boolean;
}

export interface IInternship {
  id?: string; // Идентификатор стажировки
  name?: string; // Название
  hrid?: string; // Внешний идентификатор (human-readable id)
  description?: string; // Описание
  shortDescription?: string; // Краткое описание
  audience?: string[]; // Возрастная аудитория конкретной программы
  complexity?: string; // Сложность программы
  deliveryMode?: string; // Способ проведения
  scheduleId?: string; // Идентификатор расписания занятий по программе
  url?: string; // Ссылка на описание конкретной программы
  mainImageId?: string; // Идентификатор главного фотографии к программе
  mainImagePath?: string; // Путь главного фотографии к программе
  galleryIds?: string[]; // Галерея картинок программы
  galleryPaths?: string[];
  orderNumber?: number;
  isInstitutionApproved?: boolean;
  courseIds?: string[];
  courses?: string[];
  courseGroupIds?: string[];
  courseGroups?: string[];
  talents?: string[];
  talentIds?: string[];
  productGroups?: string[];
  address?: string;
  institutionId?: string;
  institution?: string;
  isArchived?: boolean;
  startDate?: any; // дата начала мероприятия
  isRegular?: boolean; // регулярное мероприятие или нет
  minAgeAudience?: number; // минимальный возраст
  maxAgeAudience?: number; // максимальный возраст
  regionId?: string; // Регион
  municipalityId?: string; // Муниципалитет
  duration?: number; // Длительность (в днях)
  createdDate?: string; // Дата создания
  publishedDate?: string; // Дата публикации
}
