import { Injectable } from '@angular/core';
import { UserStorageService } from '../user-storage';
import { RamStorageService } from '../ram-storage';
import { StorageHelper } from '../../helpers';
import { IncludedToRamStorageKeys, StorageKeys, Storages } from './web-storage.collections';

@Injectable({
  providedIn: 'root',
})
export class WebStorageService extends StorageHelper {
  constructor(private userStorageService: UserStorageService, private ramStorageService: RamStorageService) {
    super(Storages.Local);
  }

  public set<T>(key: StorageKeys, value: T): void {
    super.set(key, value);
    this.userStorageService.set(key, value);

    if (IncludedToRamStorageKeys.has(key)) {
      this.ramStorageService.set(key, value);
    }
  }

  public get<T>(key: StorageKeys): T | null {
    return super.get(key);
  }
}
