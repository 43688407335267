<div *ngIf="dataLoaded">
  <div class="spider-chart">
    <div class="spider-chart__text" *ngIf="false">
      <div class="spider-chart__name">{{ 'TEST_AND_RESULTS.SKILS.HEADER' | translate }}</div>
    </div>
    <prf-radar-chart [data]="skillsData" [isParent]="false"></prf-radar-chart>
  </div>
  <prf-interest-progress-bar [data]="interestsData" [isParent]="false"></prf-interest-progress-bar>

  <div class="talants">
    <div class="talants__name">{{ 'TEST_AND_RESULTS.SKILS.TALANTS' | translate }}</div>
    <div class="talants__list">
      <div class="talants__item">
        <div class="talants__pic">
          <img *ngIf="talentsData[1]?.imageUrl?.length" [src]="talentsData[1].imageUrl" alt="" />
        </div>
        <div *ngIf="talentsData[1]?.name?.length" class="talants__item-name">
          {{ talentsData[1].name }}
        </div>
        <div *ngIf="talentsData[1]?.description?.length" class="talants__item-text">
          {{ talentsData[1].description }}
        </div>
      </div>
      <div class="talants__item">
        <div class="talants__pic">
          <img *ngIf="talentsData[0]?.imageUrl?.length" [src]="talentsData[0].imageUrl" alt="" />
        </div>
        <div *ngIf="talentsData[0]?.name?.length" class="talants__item-name">
          {{ talentsData[0].name }}
        </div>
        <div *ngIf="talentsData[0]?.description?.length" class="talants__item-text">
          {{ talentsData[0].description }}
        </div>
      </div>
      <div class="talants__item">
        <div class="talants__pic">
          <img *ngIf="talentsData[2]?.imageUrl?.length" [src]="talentsData[2].imageUrl" alt="" />
        </div>
        <div *ngIf="talentsData[2]?.name?.length" class="talants__item-name">
          {{ talentsData[2].name }}
        </div>
        <div *ngIf="talentsData[2]?.description?.length" class="talants__item-text">
          {{ talentsData[2].description }}
        </div>
      </div>
    </div>
  </div>
</div>
