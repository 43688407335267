import { Injectable } from '@angular/core';
import { Observable, throwError, TimeoutError } from 'rxjs';

@Injectable()
export class ErrorHandlerService {
  public check(err: any, responseName: string = ''): Observable<any> {
    if (err instanceof TimeoutError) {
      console.error(`%c${responseName} %c - Frontend returned timeout error: ${err['error']}`, 'color:yellow', 'color:red');
      return throwError(err['error']);
    }
    if (err.error instanceof Error) {
      console.error(`%c${responseName} %c - An error occurred:`, 'color:yellow', 'color:red', err.error.message);
    } else {
      console.error(`%c${responseName} %c - Backend returned code ${err.status}, body was: ${err.error}`, 'color:yellow', 'color:red');
    }
    throw throwError(err);
  }
}
