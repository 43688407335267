<div class="event-page-section create" *ngIf="course">
  <div class="edit-event-row">
    <div class="edit-event-column">
      <h1 class="event-h1 edit">{{ title }}</h1>
      <div class="event-status-block-2">
        <div class="event-status-text-2">
          {{ 'SHARED.CREATED_DUE_DATE' | translate : { createdDate: createdDate } }}
        </div>
        <div class="event-status-text-2">Ред {{ publishedDate }}</div>
      </div>
    </div>
    <div class="edit-event-column _2">
      <a [routerLink]="['/admin/copy-course', course.hrid]" *ngIf="!isCopy" class="edit-event-button w-inline-block">
        <div class="edit-event-button-row">
          <div class="edit-icon-div">
            <img src="/assets/images/icons/files-copy-interface-symbol.svg" class="icon-initial-state" />
          </div>
          <div>{{ 'SHARED.CREATE_COPY' | translate }}</div>
        </div>
      </a>
      <a (click)="addtoArchive = !addtoArchive" class="edit-event-button w-inline-block">
        <div class="edit-event-button-row">
          <div class="edit-icon-div">
            <img src="/assets/images/icons/archive-black-box_1.svg" class="icon-initial-state" />
          </div>
          <div>Добавить в архив</div>
        </div>
      </a>
      <a class="delete-event w-inline-block" (click)="popUpConfirming = !popUpConfirming"></a>
    </div>
  </div>
  <div class="create-event-form w-form">
    <form [formGroup]="form">
      <div class="create-form-row _2">
        <div class="create-form-column _1">
          <label class="create-form-label">Формат*</label>
        </div>
        <div class="create-form-column" #ddFormat>
          <div class="professions-dropdown w-dropdown">
            <div class="professions-dropdown-toggle w-dropdown-toggle" (click)="typesFormats.classList.toggle('w--open')">
              <div class="professions-dd-icon-2 w-icon-dropdown-toggle"></div>
              <div>{{ checkFormatsHeader() }}</div>
            </div>
            <nav class="dd-prof-filter-list w-dropdown-list" #typesFormats>
              <div class="dd-prof-filter-div">
                <div class="scroll-block">
                  <div class="prof-categories-links">
                    <a class="prof-categories-link w-inline-block" *ngFor="let type of classesFormats">
                      <div class="category-text-link" (click)="setFormat(type); typesFormats.classList.remove('w--open')">
                        {{ type.viewValue }}
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div class="event-image uploaded-photo" [style.background-image]="'url(' + f.mainImagePath.value + ')'">
        <a class="white-button change-event-photo w-button" (click)="upload.click()">Изменить</a>
        <input type="file" (change)="uploadLogoCourse($event)" #upload hidden />
      </div>
      <div class="event-image-text">Рекомендуем загружать качественные фото. Его увидят тысячи учеников и родителей.</div>
      <h2 class="event-h2">Основная информация</h2>
      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="name">Название*</label>
        </div>
        <div class="create-form-column">
          <input
            type="text"
            class="create-event-field w-input"
            maxlength="256"
            name="name"
            data-name="name"
            [class.error-field]="submitted && f.name.errors"
            placeholder="Название"
            id="name"
            formControlName="name"
          />
          <ng-container *ngIf="submitted && f.name.errors">
            <mat-error *ngIf="f.name.errors.required">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
          </ng-container>
        </div>
      </div>
      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="hrid">HRID курса</label>
        </div>
        <div class="create-form-column">
          <input
            type="text"
            class="create-event-field w-input"
            [class.error-field]="(submitted && f.hrid.errors) || (submitted && !checkhrid)"
            maxlength="256"
            name="hrid"
            placeholder="HRID курса"
            id="hrid"
            formControlName="hrid"
          />
          <ng-container *ngIf="submitted && f.hrid.errors">
            <mat-error *ngIf="f.hrid.errors.required">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
          </ng-container>
          <ng-container *ngIf="submitted && !checkhrid">
            <mat-error *ngIf="!checkhrid">
              {{ 'SHARED.HRID_IS_BUSY' | translate }}
            </mat-error>
          </ng-container>
        </div>
      </div>

      <div class="create-form-row">
        <div class="create-form-column _1">
          <label for="region" class="create-form-label">{{ 'SHARED.REGION' | translate }}*</label>
          <div class="div-space"></div>
        </div>
        <mat-form-field class="create-form-column">
          <mat-select
            placeholder="{{ 'SHARED.REGION' | translate }}"
            class="create-event-field w-select"
            [class.error-field]="submitted && f.region.errors"
            formControlName="region"
            name="region"
            id="region"
            [compareWith]="compareItems"
          >
            <mat-option (click)="selectRegion(region)" [value]="region" *ngFor="let region of allRegions$ | async">{{
              region.name
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="create-form-row" *ngIf="f.region.value.id && f.region.value.id != defaultRegion">
        <div class="create-form-column _1">
          <label for="municipality" class="create-form-label">Муниципалитет</label>
          <div class="div-space"></div>
        </div>
        <mat-form-field class="create-form-column">
          <mat-select
            placeholder="Муниципалитет"
            class="create-event-field w-select"
            [class.error-field]="submitted && f.municipality.errors"
            formControlName="municipality"
            name="municipality"
            id="municipality"
            [compareWith]="compareItems"
          >
            <mat-option [value]="municipality" (click)="clearMunicipality()">Не выбран</mat-option>
            <mat-option [value]="municipality" *ngFor="let municipality of municipalitiesByRegion$ | async">{{
              municipality.name
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="institution">Организатор*</label>
        </div>
        <div class="create-form-column">
          <input
            type="text"
            class="create-event-field w-input"
            maxlength="256"
            name="institution"
            [class.error-field]="submitted && f.institution.errors"
            data-name="institution"
            placeholder="Организатор"
            id="institution"
            formControlName="institution"
          />
          <ng-container *ngIf="submitted && f.institution.errors">
            <mat-error *ngIf="f.institution.errors.required">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
          </ng-container>
        </div>
      </div>
      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="shortDescription">Краткое описание*</label>
          <div class="info-div add-event">
            <div class="info-icon" (mouseover)="showInfoPopup = true" (mouseout)="showInfoPopup = false"></div>
            <div *ngIf="showInfoPopup" class="info-popup">
              <p>Краткое описание имеет ограничение на 5000 символов</p>
              <img src="/assets/images/icons/info-triangle.png" width="12" class="info-triangle" />
            </div>
          </div>
        </div>
        <div class="create-form-column">
          <textarea
            id="shortDescription"
            name="shortDescription"
            placeholder="Краткое описание"
            [class.error-field]="submitted && f.shortDescription.errors"
            maxlength="5000"
            formControlName="shortDescription"
            class="create-event-field w-input"
          >
          </textarea>
          <ng-container *ngIf="submitted && f.shortDescription.errors">
            <mat-error *ngIf="f.shortDescription.errors.required">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
          </ng-container>
        </div>
      </div>
      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="description">Описание*</label>
        </div>
        <div class="create-form-column">
          <quill-editor id="description" height="250px" name="description" placeholder="Описание" formControlName="description">
          </quill-editor>
        </div>
      </div>
      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label">Тематика*</label>
        </div>
        <prf-select-themes [existingThemes]="course.courses" (dataSelectThemes)="dataThemes($event)"> </prf-select-themes>
      </div>
      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label">Талант*</label>
        </div>
        <prf-select-talents [existingTalents]="course.talents" (dataSelectTalents)="dataTalents($event)"> </prf-select-talents>
      </div>
      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label">Сложность*</label>
        </div>
        <div class="create-form-column" #ddComplexity>
          <div class="professions-dropdown w-dropdown">
            <div class="professions-dropdown-toggle w-dropdown-toggle" (click)="typesComplexitys.classList.toggle('w--open')">
              <div class="professions-dd-icon-2 w-icon-dropdown-toggle"></div>
              <div>{{ checkComplexityHeader() }}</div>
            </div>
            <nav class="dd-prof-filter-list w-dropdown-list" #typesComplexitys>
              <div class="dd-prof-filter-div">
                <div class="scroll-block">
                  <div class="prof-categories-links">
                    <a class="prof-categories-link w-inline-block" *ngFor="let type of complexitys">
                      <div class="category-text-link" (click)="setComplexity(type); typesComplexitys.classList.remove('w--open')">
                        {{ type.viewValue }}
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label">Рекомендуемый возраст*</label>
          <div class="info-div add-event">
            <div class="info-icon" (mouseover)="showInfoPopup = true" (mouseout)="showInfoPopup = false"></div>
            <!-- <div class="info-popup" *ngIf="showInfoPopup">
              <p>Этот код можно использовать для входа в личный кабинет до смены пароля</p>
              <img src="/assets/images/icons/info-triangle.png" width="12" class="info-triangle add-event">
            </div> -->
          </div>
        </div>
        <div class="create-form-column">
          <div class="create-event-row">
            <div class="create-event-row">
              <label class="create-form-label" for="minAgeAudience">от</label>
              <input
                class="create-event-field time w-input"
                type="text"
                [class.error-field]="submitted && f.minAgeAudience.errors"
                maxlength="256"
                name="minAgeAudience"
                data-name="minAgeAudience"
                id="minAgeAudience"
                formControlName="minAgeAudience"
              />
            </div>
            <div class="create-event-row">
              <label class="create-form-label" for="maxAgeAudience">до</label>
              <input
                class="create-event-field time w-input"
                type="text"
                [class.error-field]="submitted && f.maxAgeAudience.errors"
                maxlength="256"
                name="maxAgeAudience"
                data-name="maxAgeAudience"
                id="maxAgeAudience"
                formControlName="maxAgeAudience"
              />
            </div>
          </div>
          <div class="create-event-text">Выберите диапазон</div>
        </div>
      </div>
      <div class="create-form-row _2">
        <div class="create-form-column _1">
          <label class="create-form-label">Формат курса*</label>
        </div>
        <div class="create-form-column" #ddFormatCourse>
          <div class="professions-dropdown w-dropdown">
            <div class="professions-dropdown-toggle w-dropdown-toggle" (click)="formatCourse.classList.toggle('w--open')">
              <div class="professions-dd-icon-2 w-icon-dropdown-toggle"></div>
              <div>{{ checkDeliveryHeader() }}</div>
            </div>
            <nav class="dd-prof-filter-list w-dropdown-list" #formatCourse>
              <div class="dd-prof-filter-div">
                <div class="scroll-block">
                  <div class="prof-categories-links">
                    <a class="prof-categories-link w-inline-block" *ngFor="let format of delivery">
                      <div class="category-text-link" (click)="setDelivery(format); formatCourse.classList.remove('w--open')">
                        {{ format.viewValue }}
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div class="create-form-row">
        <div class="create-event-row date-raw">
          <div class="create-form-column _1">
            <label class="create-form-label" for="startDate">Дата проведения</label>
          </div>

          <mat-form-field>
            <mat-label>Choose a date</mat-label>
            <input
              matInput
              [matDatepicker]="picker"
              formControlName="startDate"
              class="create-event-field date w-input"
              id="startDate"
              [class.error-field]="submitted && f.startDate.errors"
            />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="create-form-column">
          <div class="create-event-row">
            <label class="create-form-label" for="startTime">Время начала</label>
            <input
              class="create-event-field time w-input"
              type="text"
              [class.error-field]="submitted && f.startTime.errors"
              [textMask]="{ mask: timeMask }"
              maxlength="256"
              name="startTime"
              data-name="startTime"
              id="startTime"
              formControlName="startTime"
            />
          </div>
        </div>
      </div>
      <div class="create-form-row _2">
        <div class="create-form-column _1">
          <label class="create-form-label" for="unitPrice">Стоимость*</label>
          <div class="info-div add-event">
            <div class="info-icon" (mouseover)="showInfoPopup = true" (mouseout)="showInfoPopup = false"></div>
            <!-- <div class="info-popup" *ngIf="showInfoPopup">
              <p>Этот код можно использовать для входа в личный кабинет до смены пароля</p>
              <img src="/assets/images/icons/info-triangle.png" width="12" class="info-triangle">
            </div> -->
          </div>
        </div>
        <div class="create-form-column">
          <div class="cost-row">
            <input
              type="number"
              [class.error-field]="submitted && f.unitPrice.errors"
              class="create-event-field cost w-input"
              maxlength="256"
              name="unitPrice"
              data-name="unitPrice"
              id="unitPrice"
              formControlName="unitPrice"
            />
            <div class="cost-symbol">{{ 'COMMON.MONEY_SYMBOL' | translateByDefault }}</div>
          </div>
          <div class="create-event-text">Если бесплатно поставьте 0</div>
          <ng-container *ngIf="submitted && f.unitPrice.errors">
            <mat-error *ngIf="f.unitPrice.errors.required">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
          </ng-container>
        </div>
      </div>
      <div class="create-form-row _2">
        <div class="create-form-column _1">
          <label class="create-form-label">Тип промокода</label>
        </div>
        <div class="create-form-column">
          <div class="create-form-column" #ddPromoType>
            <div class="professions-dropdown w-dropdown">
              <div class="professions-dropdown-toggle w-dropdown-toggle w-dd-width" (click)="promoTypes.classList.toggle('w--open')">
                <div class="professions-dd-icon-2 w-icon-dropdown-toggle"></div>
                <div>{{ checkPromocodeTypesHeader() }}</div>
              </div>
              <nav class="dd-prof-filter-list w-dropdown-list" #promoTypes>
                <div class="dd-prof-filter-div">
                  <div class="filter-list-row">
                    <ng-container *ngIf="selectedPromoType; else notSelected">
                      <a class="clear-categories show" (click)="deselectPromoType()">{{ 'SHARED.CLEAR_BUTTON' | translate }}</a>
                    </ng-container>
                  </div>
                  <div class="scroll-block">
                    <div class="prof-categories-links">
                      <a class="prof-categories-link w-inline-block" *ngFor="let type of promocodeTypes">
                        <div class="category-text-link" (click)="setPromoType(type); promoTypes.classList.remove('w--open')">
                          {{ type.viewValue }}
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div class="create-form-row _2" *ngIf="selectedPromoType != null">
        <div class="create-form-column _1">
          <label class="create-form-label" for="promocode">Промокод*</label>
        </div>
        <div class="create-form-column">
          <div class="cost-row">
            <input
              type="text"
              class="create-event-field w-input"
              [class.error-field]="submitted && f.promocode.errors"
              maxlength="256"
              name="hrid"
              placeholder="Промокод"
              id="promocode"
              formControlName="promocode"
            />
          </div>
        </div>
      </div>
      <div class="create-form-row _2" *ngIf="selectedPromoType != null">
        <div class="create-form-column _1">
          <label class="create-form-label" for="unitDiscount">Размер скидки*</label>
        </div>
        <div class="create-form-column">
          <div class="cost-row">
            <input
              type="number"
              [class.error-field]="submitted && f.unitDiscount.errors"
              class="create-event-field cost w-input"
              maxlength="256"
              name="unitDiscount"
              data-name="unitDiscount"
              id="unitDiscount"
              formControlName="unitDiscount"
            />
            <div *ngIf="!selectedPromoType.value" class="cost-symbol">
              {{ 'COMMON.MONEY_SYMBOL' | translateByDefault }}
            </div>
            <div *ngIf="selectedPromoType.value" class="cost-symbol">
              {{ 'COMMON.PERCENT_SYMBOL' | translateByDefault }}
            </div>
          </div>
          <ng-container *ngIf="submitted && f.unitDiscount.errors">
            <mat-error *ngIf="f.unitDiscount.errors.required">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
          </ng-container>
        </div>
      </div>
      <h2 class="event-h2">Дополнительная информация</h2>
      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="address">Адрес проведения</label>
        </div>
        <div class="create-form-column">
          <input
            type="text"
            class="create-event-field w-input"
            maxlength="256"
            name="address"
            data-name="address"
            id="address"
            formControlName="address"
            placeholder="Адрес проведения"
          />
        </div>
      </div>
      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label">Ближайшее метро</label>
        </div>
        <prf-select-metro [existingMetroStations]="course.metroStations" (dataSelectMetro)="dataMetro($event)"> </prf-select-metro>
      </div>
      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="url">Ссылка</label>
        </div>
        <div class="create-form-column">
          <input
            type="text"
            class="create-event-field w-input"
            maxlength="256"
            name="url"
            data-name="url"
            id="url"
            formControlName="url"
            placeholder="Ссылка"
          />
        </div>
      </div>
      <div class="edit-event-buttons-row">
        <input type="submit" (click)="submit()" value="{{ 'SHARED.SAVE_CHANGES' | translate }}" class="button green-btn w-button" />
        <a class="white-button edit-event w-button" routerLink="/admin/courses">
          {{ 'SHARED.EXIT_WITHOUT_SAVE' | translate }}
        </a>
      </div>
    </form>
    <div class="w-form-done">
      <div>Thank you! Your submission has been received!</div>
    </div>
    <div class="w-form-fail">
      <div>Oops! Something went wrong while submitting the form.</div>
    </div>
  </div>
</div>

<ng-container *ngIf="popUpConfirming">
  <div class="perform-task-lb lb show">
    <div class="lb-bg"></div>
    <div class="lb-block task-lb">
      <a class="close-lb w-inline-block" (click)="popUpConfirming = !popUpConfirming">
        <img src="/assets/images/icons/close-lb.svg" />
      </a>
      <div class="tasl-lb-row">
        <div class="task-lb-column _2">
          <h4 class="task-lb-heading-result">Удаление курса</h4>
          <div class="task-result-text">Вы уверены, что хотите удалить курс?</div>
          <a class="button attach-file-btn w-button" (click)="deleteCourse(course.id)">{{ 'BUTTONS.DELETE' | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="addtoArchive">
  <div class="perform-task-lb lb show">
    <div class="lb-bg"></div>
    <div class="lb-block task-lb">
      <a class="close-lb w-inline-block" (click)="addtoArchive = !addtoArchive">
        <img src="/assets/images/icons/close-lb.svg" />
      </a>
      <div class="tasl-lb-row">
        <div class="task-lb-column _2">
          <h4 class="task-lb-heading-result">Добавление в архив</h4>
          <div class="task-result-text">Вы уверены, что хотите добавить курс в архив?</div>
          <a class="button attach-file-btn w-button" (click)="archive(course)">{{ 'SHARED.ADD' | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</ng-container>
