import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { InternshipDetailsService } from 'app/pages/catalog/internship-page/internship-details/internship-details.service';
import { COURSES_TYPES } from 'app/shared/dashboard/courses/courses-catalog/courses-filter/courses-types-filter/courses-types-filter.component';
import * as moment from 'moment';
import { InternshipsService } from '../../../../internships.service';

@Component({
  selector: 'prf-internship-card',
  templateUrl: './internship-card.component.html',
  styleUrls: ['./internship-card.component.scss'],
})
export class InternshipCardComponent implements OnInit, OnDestroy {
  @Input() internship: any;
  @Input() viewValue: any;
  @Input() image: any;
  @ViewChild('heart') heart: ElementRef;
  @ViewChild('heartActive') heartActive: ElementRef;
  types = COURSES_TYPES;
  currentType: any;
  isFavorite: boolean = false;
  isCourse: boolean;
  subscription: any;
  favoritesInternships: any;
  userId: string;
  userRole: string;
  accessAllow: boolean = false;
  popUpConfirming: boolean = false;
  startDate: string;
  moment: any;

  constructor(
    private internshipDetailsService: InternshipDetailsService,
    private internshipsService: InternshipsService,
    private router: Router,
  ) {
    this.moment = moment;
    this.moment.locale('ru');
  }

  ngOnInit() {
    this.userId = localStorage.getItem('userId');
    this.userRole = localStorage.getItem('userRole');
    this.accessAllow = this.userRole === 'admin';
    this.subscription = this.internshipDetailsService.getFavoriteInternships().subscribe(data => {
      this.favoritesInternships = data;
      if (this.favoritesInternships) {
        let productIds = this.favoritesInternships.filter(el => el.productId === this.internship.id);
        this.isFavorite = productIds.length > 0;
      }
    });
    this.startDate = this.moment(this.internship.startDate).format('D MMMM YYYY');
  }

  private getTypeCourse(value: string) {
    let type = this.types.filter(el => el.value === value);
    return type[0];
  }

  deleteInternship(internshipID) {
    this.internshipsService.removeCurrentInternship(internshipID);
    this.popUpConfirming = !this.popUpConfirming;
    return this.ngOnInit();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
