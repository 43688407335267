<div class="login-section">
  <div class="login-container">
    <a routerLink="/" class="login-logo w-inline-block">
      <img class="login-logo-img" src="/assets/images/dashboard/profilum-logo.svg" alt="Profilum logo" />
    </a>

    <div class="tabs login w-tabs">
      <!-- <div class="tabs-menu w-tab-menu">
        <a [routerLink]="['/mo-login']" class="tab-link login w-inline-block w-tab-link">
          <div>{{ 'SHARED.ENTRANCE' | translate }}</div>
          <img src="/assets/images/icons/angle.png" width="20" class="angle" />
        </a>
        <a
          [routerLink]="['/mo-registration']"
          class="tab-link login w-inline-block w-tab-link w--current"
        >
          <div>{{ 'SHARED.REGISTRATION' | translate }}</div>
          <img src="/assets/images/icons/angle2.png" width="20" class="angle _2" />
        </a>
      </div> -->

      <div class="tab-pane login">
        <div class="user-data-form-block w-form">
          <div class="login-form-row reg-form">
            <div class="login-form-column">
              <a routerLink="/mo-registration" class="reg-form-back-button">{{ 'BUTTONS.PREVIOUS' | translate }}</a>
            </div>
            <div class="login-field-column reg-form" *ngIf="registrationPupil">
              <img src="/assets/images/dashboard/reg-student.svg" class="registration-link-image reg-form" />
              <div class="registration-link-text reg-form">Ученик</div>
            </div>
            <div class="login-field-column reg-form" *ngIf="registrationOther">
              <img src="/assets/images/dashboard/reg-other.svg" class="registration-link-image reg-form" />
              <div class="registration-link-text reg-form">
                {{ 'CODE_REGISTRATION_KZ.ORGANIZATION' | translate }}
              </div>
            </div>
            <div *ngIf="registrationPupil">
              <a *ngIf="tglRegistration" (click)="toggleRegistration()">{{ 'SHARED.REGISTRATION' | translate }}</a>
              <a *ngIf="!tglRegistration" (click)="toggleRegistration()">{{ 'CODE_REGISTRATION_KZ.HAVE_CODE' | translate }}</a>
            </div>
          </div>
          <form [formGroup]="form" *ngIf="accessFormRole" autocomplete="off">
            <div class="login-form-row">
              <label for="email" class="login-label">{{ 'LOGIN_KZ.EMAIL' | translate }}</label>
              <div class="login-field-column">
                <input
                  type="email"
                  autocomplete="off"
                  class="login-field w-input"
                  [class.error-field]="(submitted && f.email.errors) || (submitted && duplicateUserName)"
                  maxlength="70"
                  name="email"
                  data-name="email"
                  id="email"
                  formControlName="email"
                  (focusout)="checkFormatEmail($event)"
                />
                <ng-container>
                  <mat-error *ngIf="!checkEmail">
                    {{ 'SHARED.FORM.CHECK_EMAIL' | translate }}
                  </mat-error>
                  <mat-error *ngIf="submitted && duplicateUserName">
                    {{ 'CODE_REGISTRATION_KZ.EMAIL_EXIST' | translate }}
                  </mat-error>
                </ng-container>
              </div>
            </div>
            <div class="login-form-row">
              <label for="password" class="login-label">{{ 'CODE_REGISTRATION_KZ.ACCESS_CODE' | translate }}</label>
              <div class="login-field-column">
                <input
                  *ngIf="isMaskedPassword"
                  type="password"
                  autocomplete="new-password"
                  class="login-field w-input short-right"
                  [class.error-field]="(submitted && f.password.errors) || passFailed"
                  maxlength="25"
                  name="password"
                  data-name="password"
                  id="password"
                  formControlName="password"
                />
                <input
                  *ngIf="!isMaskedPassword"
                  type="text"
                  autocomplete="new-password"
                  class="login-field w-input short-right"
                  [class.error-field]="(submitted && f.password.errors) || passFailed || codeIsUsed"
                  maxlength="25"
                  name="password"
                  data-name="password"
                  id="passwordUnMask"
                  formControlName="password"
                />
                <div class="under-field-text" *ngIf="registrationPupil">
                  {{ 'CODE_REGISTRATION_KZ.PARENT_GIVE' | translate }}
                </div>
                <div class="under-field-text" *ngIf="registrationOther">
                  {{ 'CODE_REGISTRATION_KZ.SERVICE_ADMINISTRATION_GIVE' | translate }}
                </div>
                <div [ngClass]="{ eye: !isMaskedPassword, eye__closed: isMaskedPassword }" (click)="toggleMask()"></div>
                <ng-container *ngIf="submitted && f.password.errors">
                  <mat-error *ngIf="f.password.errors.required">
                    {{ 'SHARED.FORM.FILL_INPUT' | translate }}
                  </mat-error>
                </ng-container>
                <mat-error *ngIf="submitted && passFailed">
                  {{ 'CODE_REGISTRATION_KZ.CHECK_CODE' | translate }}
                </mat-error>
                <mat-error *ngIf="submitted && codeIsUsed"> Данный код уже использован</mat-error>
              </div>
            </div>

            <div class="terms" *ngIf="!registrationPupil">
              <div class="checkbox-terms-grn" [class.checked-terms-grn]="personalTerms" (click)="personalTerms = !personalTerms"></div>
              <span class="terms-text"
                >{{ 'SHARED.AGREEMENT.ACCEPT_TEXT' | translate }}
                <a href="{{ getDocsRoute() + '/Agreement.pdf' }}" target="_blank">{{ 'SHARED.AGREEMENT.USER_CONFIRMATION' | translate }}</a>
              </span>
            </div>
            <div class="w-center-content">
              <a
                (click)="animateLogin()"
                [ngClass]="{ disabled: !isAccessAllowed }"
                [class.btn-waiting]="buttonWaiting"
                [class.btn-activated]="buttonActivated"
                [class.btn-activate]="!buttonActivate"
                class="button-animate btn-activate"
                id="btnActivation"
              >
                <span class="btn-icon"></span>
                <span class="btn-text" data-wait="Подождите" [attr.data-after]="'SHARED.REGISTER' | translate">{{
                  'SHARED.REGISTER' | translate
                }}</span>
              </a>
            </div>
          </form>

          <!-- OPEN FORM REGISTRATION PUPIL -->
          <prf-registration-pupil-prosvet *ngIf="registrationPupil && !tglRegistration"></prf-registration-pupil-prosvet>
        </div>
      </div>
    </div>
  </div>
</div>
