import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IAnswerInfo } from '@profilum-api-services/api-slides/api-slides.interface';
import { TestBoxHelperComponent } from '@profilum-components/prf-tests/test-helper/test-box-helper.component';
import { Helper } from '@profilum-helpers/common-helper/helper';
import { RangeViewComponent } from './range-view/range-view.component';


@Component({
  selector: 'prf-range-box',
  standalone: true,
  imports: [CommonModule, RangeViewComponent],
  templateUrl: './range-box.component.html',
  styleUrls: ['./range-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RangeBoxComponent extends TestBoxHelperComponent implements OnInit {
  @Output() public updateValue: EventEmitter<IAnswerInfo> = new EventEmitter<IAnswerInfo>();

  public ngOnInit(): void {
    this.sectionInfo.elements.forEach((answer: IAnswerInfo) => {
      answer.answerId = answer.answerId ? answer.answerId : answer.marks[0].answerId;
    });
  }

  public updateAnswer(updatedValue: IAnswerInfo): void {
    this.updateValue.emit({
      questionSectionId: updatedValue.questionSectionId,
      answerId: updatedValue.answerId,
    });

    if (this.isRequired) {
      const uncheckedAnswer: IAnswerInfo = this.sectionInfo.elements.find((answer: IAnswerInfo) => {
        return !Helper.isString(answer.selectedAnswerId, true);
      });

      if (!uncheckedAnswer) {
        this.selectedCount$.next(this.sectionInfo.elements.length);
      }
    }
  }

  public trackByFn(index: number, answer: IAnswerInfo): string {
    return answer.answerId;
  }
}
