import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from 'app/core/services/http.service';
import { ISpeciality } from 'app/shared/interfaces/ispeciality.interface';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SpecialtiesService {
  constructor(private http: HttpService) {}

  // обработка ошибок
  handleError(err: any): Observable<any> {
    if (err instanceof TimeoutError) {
      console.error(`Frontend returned timeout error: ${err['error']}`);
      return throwError(err['error']);
    }
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
      let errorText = err.error ? (err.error.comment ? err.error.comment : err.error) : null;
    }
    throw throwError(err);
  }

  getAllSpecialties(): Observable<any> {
    return this.http.get('/api/v1.0/catalog/specialities/all').pipe(
      map(res => res.specialities),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getSpecialtyById(id): Observable<ISpeciality> {
    return this.http.get('/api/v1.0/catalog/specialities/onebyid?id=' + id).pipe(
      map(res => res.speciality),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  deleteSpecialty(id): Observable<any> {
    return this.http.delete('/api/v1.0/catalog/specialities?specialityId=', id).pipe(
      map(res => res),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  addSpecialty(speciality) {
    let requestBody = {
      speciality: speciality,
    };
    return this.http.post('/api/v1.0/catalog/specialities', requestBody).pipe(
      map(res => res),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  updateSpecialty(speciality) {
    let requestBody = {
      speciality: speciality,
    };
    return this.http.put('/api/v1.0/catalog/specialities', requestBody).pipe(
      map(res => res),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }
}
