import { Pipe, PipeTransform } from '@angular/core';

export class DataSchool {
  value: string = '';
  courses: any;
}

@Pipe({
  name: 'filterSchools',
})
export class FilterSchoolsPipe implements PipeTransform {
  transform(items: DataSchool[], filter: string): any[] {
    if (!filter || filter == '' || filter.length < 2) {
      return items;
    }
    return items.filter(item => item.value.toLowerCase().indexOf(filter.toLowerCase()) > -1);
  }
}
