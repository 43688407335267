export enum ApiLocationsUrls {
  // municipalities
  GetMunicipalityByRegion = '/api/v1.0/catalog/municipalities/getbyregion',
  Municipality = '/api/v1.0/catalog/municipalities/one',

  //regions
  GetListOfAllRegions = '/api/v1.0/catalog/regions/all',
  Regions = '/api/v1.0/catalog/regions',
  GetRegion = '/api/v1.0/catalog/regions/one',
  RegionFiles = 'api/v1.0/catalog/regions/{id}/files',

  //cities
  GetCity = '/api/v1.0/catalog/cities/one',
  GetAllCities = '/api/v1.0/catalog/cities/all',
  GetCitiesByRegion = '/api/v1.0/catalog/cities/getbyregion',
  UpdateCity = '/api/v1.0/catalog/cities?cityId=',
  Cities = '/api/v1.0/catalog/cities',
  GetCitiesByMunicipality = '/api/v1.0/catalog/cities/getbymunicipality',
}
