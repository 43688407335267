import { StorageKeys, Storages } from '../../logic-services';

export interface IStorageHelper {
  storage: Storages;

  get<T>(key: StorageKeys): T | null;

  set<T>(key: StorageKeys, value: T, cookieOptions?: { path: string }): void;

  clear(key: StorageKeys): void;

  clearAll(): void;
}
