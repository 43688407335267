import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CoursesListService } from 'app/pages/catalog/courses-page-without-filters/courses-list.service';
import { IFilterClasses } from 'app/shared/interfaces/ifilterclasses.interface';
import { Observable, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { CoursesFiltersService } from './courses-filters.service';

export const AGE_DATA = [
  {
    label: '0+',
    value: 'ZeroPlus',
  },
  {
    label: '4 - 6',
    value: 'FourSix',
  },
  {
    label: '7 - 10',
    value: 'SevenTen',
  },
  {
    label: '11 - 13',
    value: 'ElevenThirteen',
  },
  {
    label: '14 - 16',
    value: 'FourteenSixteen',
  },
  {
    label: '16+',
    value: 'SixteenPlus',
  },
];

@Component({
  selector: 'prf-courses-filter',
  templateUrl: './courses-filter.component.html',
  styleUrls: ['./courses-filter.component.scss'],
})
export class CoursesFilterComponent implements OnInit, OnDestroy {
  talents = [];
  groups = [];
  metro = [];
  maxPrice: any;
  ageRangeData: any = AGE_DATA;
  filters: IFilterClasses;
  priceHeader: string;
  disabledMaxPrice: boolean = false;
  isPriceFree: boolean = false;
  ageHeader: string = '';

  private ngUnsubscribe$: Subject<any> = new Subject();

  @ViewChild('ageFilter') ageFilter: ElementRef;
  @ViewChild('ageDrop') ageDrop: ElementRef;
  @ViewChild('priceFilter') priceFilter: ElementRef;
  @ViewChild('priceDrop') priceDrop: ElementRef;

  constructor(
    private coursesListService: CoursesListService,
    private filtersService: CoursesFiltersService,
    private translateService: TranslateService,
  ) {
    this.getTranslation('PLAYER.ANKETA.TELL_ABOUT.AGE')
      .pipe(take(1))
      .subscribe(translation => (this.ageHeader = translation));
  }

  ngOnInit() {
    this.filtersService
      .getCoursesFilters()
      .pipe(
        switchMap(data => {
          this.filters = data;
          return this.checkPriceHeader(this.filters.maxPrice).pipe(tap(r => (this.priceHeader = r)));
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  setFilter(obj: any = {}) {
    for (let key in obj) {
      if (obj) {
        this.filters[key] = obj[key];
      }
    }
    this.filtersService.setCoursesFilter(this.filters);
  }

  checkFilters() {
    switch (true) {
      case this.filters.metroStationNames && this.filters.metroStationNames.length > 0:
        return true;
      case this.filters.audience && this.filters.audience.length > 0:
        return true;
      case this.filters.courseGroups && this.filters.courseGroups.length > 0:
        return true;
      case this.filters.courses && this.filters.courses.length > 0:
        return true;
      case this.filters.classesFormat && this.filters.classesFormat.length > 0:
        return true;
      case this.filters.maxPrice >= 0:
        return true;
      case this.filters.institutionId && this.filters.institutionId.length > 0:
        return true;
      default:
        return false;
    }
  }

  checkPriceHeader(price): Observable<any> {
    if (price || price === 0) {
      if (price > 0) {
        return this.getTranslation('SHARED.BEFORE').pipe(map(translation => translation + ' ' + this.filters.maxPrice));
      } else {
        return this.getTranslation('SHARED.ONLY_FREE');
      }
    } else {
      return this.getTranslation('SHARED.PRICE');
    }
  }

  checkAgeHeader() {
    if (this.filters.audience && this.filters.audience.length > 0) {
      let age = this.ageRangeData.filter(el => el.value === this.filters.audience);
      return age[0].label;
    } else {
      return this.ageHeader;
    }
  }

  updatePriceByValue() {
    if (!this.isPriceFree && this.maxPrice > 100) {
      this.setMaxPrice();
    }
  }

  updatePriceByCheckbox(checkbox: any) {
    this.isPriceFree = checkbox.classList.contains('checked');
    this.disabledMaxPrice = this.isPriceFree;
    this.setMaxPrice();
  }

  numberOnly(event): boolean {
    let charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  clearAllFilters() {
    this.filtersService.setCoursesFilter({});
    this.disabledMaxPrice = false;
    this.maxPrice = null;
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }

  @HostListener('document:click', ['$event.target'])
  checkClick(target) {
    if (this.priceFilter && !this.priceFilter?.nativeElement.contains(target)) {
      this.priceDrop.nativeElement.classList.remove('w--open');
    }

    if (this.ageFilter && !this.ageFilter.nativeElement.contains(target)) {
      this.ageDrop.nativeElement.classList.remove('w--open');
      this.ageFilter.nativeElement.classList.remove('w--open');
    }
  }

  private setMaxPrice() {
    if (this.isPriceFree) {
      this.setFilter({ maxPrice: 0 });
    } else {
      this.setFilter({ maxPrice: this.maxPrice });
    }
  }

  getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }
}
