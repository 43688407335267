import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'prf-agile-bubble-box-container',
  templateUrl: './agile-bubble-box-container.component.html',
  styleUrls: ['./agile-bubble-box-container.component.scss'],
})
export class AgileBubbleBoxContainerComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
