import { phonePattern } from './common.collections';
import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IAnswerInfo } from '../../api-services';
import { AnswerType } from '../../collections';

export abstract class Helper {
  public static isDefined<T>(data: T): boolean {
    return typeof data !== 'undefined';
  }

  public static isString<T>(data: T, isNotEmpty: boolean = false): boolean {
    return isNotEmpty ? typeof data === 'string' && !!data : typeof data === 'string';
  }

  public static isBoolean<T>(data: T): boolean {
    return typeof data === 'boolean';
  }

  public static isFunction<T>(data: T): boolean {
    return typeof data === 'function';
  }

  public static isObject(data: any, isNotEmpty: boolean = false): boolean {
    const isObject: boolean = Object.prototype.toString.call(data) === '[object Object]';

    if (isNotEmpty && isObject) {
      let notEmpty: boolean = false;

      for (const prop in data) {
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty(prop)) {
          notEmpty = true;
          break;
        }
      }

      return isObject && notEmpty;
    }

    return isObject;
  }

  public static openOnlineConsultantWidget(event: Event): void {
    event?.preventDefault();
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    (document.querySelector('.b24-widget-button-block') as HTMLElement | null)?.click();
  }

  public static isArray<T>(data: T, isNotEmpty: boolean = false): boolean {
    return isNotEmpty ? Array.isArray(data) && data.length > 0 : Array.isArray(data);
  }

  public static isNumber<T>(data: T): boolean {
    return typeof data === 'number';
  }

  public static isNumeric(data: number): boolean {
    return Helper.isNumber(data) && !isNaN(data) && isFinite(data);
  }

  public static scrollToTop(): void {
    window?.scrollTo(0, 0);
  }

  public static parseStringToBoolean(value: string): boolean {
    return value?.toLowerCase() === 'true';
  }

  public static printPage(): void {
    window.print();
  }

  public static sorting(value1: string | number, value2: string | number, order: 'desc' | 'asc' = 'asc'): number {
    if (Helper.isString(value1) && Helper.isString(value2)) {
      const a: string = (value1 as string).toUpperCase();
      const b: string = (value2 as string).toUpperCase();

      if (order === 'asc') {
        return a === b ? 0 : a > b ? 1 : -1;
      }

      return a === b ? 0 : a < b ? 1 : -1;
    }

    if (typeof value1 === 'number' && typeof value2 === 'number') {
      return order === 'asc' ? value1 - value2 : value2 - value1;
    }

    return 1;
  }

  /**
   * Метод для склонения числительных
   * @param {number} number - Число
   * @param {string[]} words - Массив слов, например: ['вопрос', 'вопроса', 'вопросов']
   * @return {string} - Например: 1 - вопрос, 2 - вопроса, 11 - вопросов
   */
  public static declOfNum(number: number, words: string[]): string {
    return words[number % 100 > 4 && number % 100 < 20 ? 2 : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? Math.abs(number) % 10 : 5]];
  }

  public static calculateAspectRatio(width: number, height: number): string {
    const gcd: number = Helper.gcd(width, height);

    return `${width / gcd}:${height / gcd}`;
  }

  public static gcd(a: number, b: number): number {
    if (b > a) {
      const tempA: number = a;

      a = b;
      b = tempA;
    }

    while (b !== 0) {
      const m: number = a % b;

      a = b;
      b = m;
    }

    return a;
  }

  public static parseSchoolClassName(schoolClassName: string): {
    classLetter: string;
    classNumber: number | null;
  } {
    if (!Helper.isString(schoolClassName, true)) {
      throw new Error('schoolClassName is not defined or not a string');
    }

    const trimmedSchoolClassName: string = schoolClassName.trim();
    const regexpToGetNumber: RegExp = /[+-]?([0-9]*[.])?[0-9]+/;
    const classNumberStr: RegExpMatchArray | null = trimmedSchoolClassName.match(regexpToGetNumber);
    const classLetterStr: string = trimmedSchoolClassName.replace(regexpToGetNumber, '');

    return {
      classLetter: classLetterStr,
      classNumber: Helper.isArray(classNumberStr, true) ? parseFloat((classNumberStr as string[])[0]) : null,
    };
  }

  public static isValidEmail(inputString: string, allowedDomains: string[] | null = null): boolean {
    const trimmedInputString: string = inputString?.trim();

    if (!Helper.isString(trimmedInputString, true) || trimmedInputString.length > 254) {
      return false;
    }

    const regexp: RegExp =
      /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
    const isValid: boolean = regexp.test(trimmedInputString);

    if (!isValid) {
      return false;
    }

    const emailParts: string[] = trimmedInputString.split('@');

    if (emailParts[0].length > 64) {
      return false;
    }

    const domainParts: string[] = emailParts[1].split('.');

    if (domainParts.length <= 1 || domainParts.some((domainPart: string) => domainPart.length > 63)) {
      return false;
    }

    const lastDomainPart: string = domainParts[domainParts.length - 1];

    if (lastDomainPart.length < 2) {
      return false;
    }

    return allowedDomains ? allowedDomains.some((domain: string) => domainParts[domainParts.length - 1] === domain) : true;
  }

  public static isValidPhoneNumber(inputString: string): boolean {
    try {
      const isValidPhoneNumber: boolean = /^(\+7|7|8)?[\s-]?\(?[679][0-9]{2}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/.test(
        inputString,
      );

      if (!isValidPhoneNumber) {
        return false;
      }
      const phoneNumberData: { phoneNumber: string; phoneNumberWithSeparators: string } | null = Helper.parsePhoneNumber(inputString);

      if (!phoneNumberData?.phoneNumber) {
        return false;
      }

      return /^[+]7[0-9]{10}$/.test(phoneNumberData.phoneNumber);
    } catch (e) {
      return false;
    }
  }

  public static parsePhoneNumber(
    phoneNumber: string,
    separator: string = ' ',
  ): { phoneNumber: string; phoneNumberWithSeparators: string } | null {
    const trimmedStr: string = phoneNumber?.trim();

    if (!Helper.isString(trimmedStr)) {
      throw new Error('phoneNumber is not a string');
    }

    const onlyNumbersStr: string = trimmedStr.replace(/\D/g, '');

    if (!onlyNumbersStr) {
      return null;
    }

    const splitNumbers: string[] = onlyNumbersStr.split('');
    const indexOfSevenNumber: number = trimmedStr.indexOf('7');
    const hasZoneCode: boolean = indexOfSevenNumber !== -1 && trimmedStr[indexOfSevenNumber - 1] === '+';
    let numbers: number[] = [];

    splitNumbers.forEach((char: string, index: number) => {
      const number: number = parseInt(char, 10);

      if (Helper.isNumber(number) && Number.isInteger(number)) {
        let validNumber: number = number;

        if (index === 0) {
          switch (true) {
            case number === 8:
              validNumber = 7;
              break;
            case !hasZoneCode:
              numbers.unshift(7);
              validNumber = number;
              break;
            default:
              validNumber = number;
              break;
          }
        }

        numbers.push(validNumber);
      }
    });

    if (numbers.length < 11) {
      throw new Error('phoneNumber should contain 10 or more numbers!');
    }

    numbers = numbers.slice(0, 11);

    const numbersWithSeparators: (string | number)[] = [];
    const countOfNumbersToInsertSeparator: number[] = [1, 3, 3, 2, 2];
    let countOfNumbers: number = 0;

    numbers.forEach((number: number) => {
      if (countOfNumbersToInsertSeparator[0] === countOfNumbers) {
        countOfNumbersToInsertSeparator.shift();
        numbersWithSeparators.push(separator);
        countOfNumbers = 0;
      }

      numbersWithSeparators.push(number);
      countOfNumbers += 1;
    });

    return {
      phoneNumber: `+${numbers.join('')}`,
      phoneNumberWithSeparators: `+${numbersWithSeparators.join('')}`,
    };
  }

  public static disablePageScrolling(): void {
    const body: HTMLBodyElement | null = document.querySelector('body');

    body?.classList.add('body_no-scroll');
  }

  public static enablePageScrolling(): void {
    const body: HTMLBodyElement | null = document.querySelector('body');

    body?.classList.remove('body_no-scroll');
  }

  public static getRandomData<T>(arr: T[]): T {
    if (!Helper.isArray(arr)) {
      throw new Error('arr parameter should be an array');
    }

    const random: number = Math.floor(Math.random() * arr.length);

    return arr[random];
  }

  public static getRandomNumber(min: number, max: number): number {
    if (!Helper.isNumber(min) || !Helper.isNumber(max)) {
      throw new Error('min and max should be number');
    }

    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  public static getRandomUniqueIndexes(interval: number, count: number): number[] {
    if (!Helper.isNumber(interval) || !Helper.isNumber(count)) {
      throw new Error('interval and count should be number');
    }

    const parsedCount: number = interval <= count ? interval : count;
    const randomIndexes: number[] = [];

    const getRandom = (): void => {
      const random: number = Math.floor(Math.random() * interval);

      if (randomIndexes.indexOf(random) >= 0) {
        getRandom();
        return;
      }

      randomIndexes.push(random);
    };

    for (let i: number = 0; i < parsedCount; i++) {
      getRandom();
    }

    return randomIndexes;
  }

  public static phoneValidation(phone: string): string {
    if (!Helper.isString(phone)) {
      throw new Error('phone is not a string');
    }

    switch (phone[0]) {
      case '8': {
        phone = `7${phone.slice(1, 11)}`;
        break;
      }
      case '7': {
        break;
      }
      default: {
        phone = `7${phone}`;
        break;
      }
    }

    return phone;
  }

  public static convertPhoneNumber(phone: string, pattern: string = phonePattern): string {
    let i: number = 0;

    if (Helper.isString(phone, true)) {
      return pattern?.replace(/#/g, () => {
        return phone[i++];
      });
    }

    return phone;
  }

  public static setMaskPhoneNumber(phone: string): string {
    return `+${phone.slice(0, 1)} ${phone.slice(1, 4)} ${phone.slice(4, 7)} ${phone.slice(7, 9)} ${phone.slice(9, 11)}`;
  }

  public static parseToNumber(data: string | number): number {
    try {
      const regExp: RegExp = /[-]{0,1}[\d]*[.]{0,1}[\d]+/;
      const numbers: RegExpMatchArray | null = (data as string).match(regExp);

      return Helper.isNumber(data) ? parseFloat(data as string) : numbers?.length ? parseFloat(numbers[0]) : NaN;
    } catch (e) {
      return NaN;
    }
  }

  public static sortArraysAsDiamondByLength<Type>(arr: Type[][]): Type[][] {
    const copyArr: Type[][] = arr.slice();

    copyArr.sort((a: Type[], b: Type[]) => a.length - b.length);

    const result: Type[][] = [];

    for (let i: number = copyArr.length - 1; i >= 0; i--) {
      if (i % 2 === 0) {
        result.push(copyArr[i]);
      } else {
        result.unshift(copyArr[i]);
      }
    }

    return result;
  }

  public static getRandomInteger(min: number, max: number): number {
    const rand: number = min + Math.random() * (max + 1 - min);

    return Math.floor(rand);
  }

  public static sliceArrayIntoChunks<Type>(elements: Type[], chunkSize: number): Type[][] {
    const res: Type[][] = [];

    for (let i: number = 0; i < elements.length; i += chunkSize) {
      const chunk: Type[] = elements.slice(i, i + chunkSize);
      res.push(chunk);
    }

    return res;
  }

  public static checkResizeAnyHTMLElements(callback: ResizeObserverCallback): ResizeObserver {
    return new ResizeObserver(callback);
  }

  public static parseJwtToken<T>(token: string): T | null {
    if (!Helper.isString(token, true)) {
      return null;
    }

    try {
      const base64Url: string = token.split('.')[1];
      const base64: string = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload: string = decodeURIComponent(
        window
          .atob(base64)
          .split('')
          .map((c: string) => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(''),
      );

      return <T>JSON.parse(jsonPayload);
    } catch (e) {
      console.error(e);

      return null;
    }
  }

  public static isAbsoluteUrl(url: string): boolean {
    const regExp: RegExp = /^https?:\/\//i;

    return regExp.test(url);
  }

  public static getImageSrc(data: IAnswerInfo): string {
    if (data.answerType === AnswerType.Image) {
      return data.backgroundImageUrl ? data.backgroundImageUrl : './assets/images/answers/' + data.text;
    }

    return '';
  }

  public static getTranslations(keys: string[]): Observable<Record<string, string>> {
    return <Observable<Record<string, string>>>inject(TranslateService).get(keys);
  }

  public static isEnvironmentDev(): boolean {
    return window.location.hostname.includes('dev') || window.location.hostname.includes('localhost');
  }
}
