<div class="overlay" *ngIf="showMessage">
  <div class="alert-message">
    <div class="show-panel">
      {{ alertMessage }}
    </div>
    <div class="buttons">
      <a routerLink="/" class="toHome button">{{ 'SHARED.ON_MAIN' | translate }}</a>
      <a routerLink="/login" class="toLogin button">{{ 'SHARED.LOGIN' | translate }}</a>
    </div>
  </div>
</div>
