export const enum ApiFieldsUrls {
  GetAllFields = '/api/v1.0/catalog/fields/all',
  GetGroups = '/api/v1.0/catalog/fielddos/all',
  GetOneField = '/api/v1.0/catalog/fields/one',
  Fields = '/api/v1.0/catalog/fields',
  ReturnsFieldStructuredDescriptionByID = '/api/v1.0/catalog/fields/{id}/structured',
  GetFieldTypes = '/api/v1.0/catalog/fields/getfieldtypes',
}

export const enum FieldTypes {
  Default,
  DepCult,
}
