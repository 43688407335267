import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AddFirstProfessionModalService {
  public show$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public url: string = '';

  public close() {
    this.show$.next(false);
  }

  public open(url: string) {
    this.url = url;
    this.show$.next(true);
  }
}
