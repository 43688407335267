import { inject, Injectable, NgZone } from '@angular/core';
import { Observable, Subscriber, Subscription } from 'rxjs';
import $, { Cash } from 'cash-dom';
import { IObject } from '@profilum-api-services/http/http.interface';

@Injectable({
  providedIn: 'root',
})
export class NgZoneService {
  private readonly ngZone: NgZone = inject(NgZone);

  public static checkIsInZone(): void {
    console.log(NgZone.isInAngularZone());
  }

  public subscribeToObsOutsideZone<T>(): any {
    return (source: Observable<T>) => {
      return new Observable((obs: Subscriber<T>) => {
        let subscriber: Subscription;

        this.ngZone.runOutsideAngular(() => {
          subscriber = source.subscribe(obs);
        });

        return subscriber;
      });
    };
  }

  public runOutsideZone(callback: () => Record<string, any> | void): void {
    this.ngZone.runOutsideAngular(callback);
  }

  public addEventListenerOutsideZone(
    event: string,
    callback: (e: Event, param?: any) => IObject | void,
    element: HTMLElement | Cash = null,
  ): void {
    this.ngZone.runOutsideAngular(() => {
      if (element) {
        const $element: Cash = element instanceof $ ? (element as Cash) : $(element);

        $element.on(event, callback);
        return;
      }

      $(window).on(event, callback);
    });
  }
}
