<div class="event-page-section create">
  <h1 class="event-h1">{{ 'SHARED.ADDING' | translate }}</h1>
  <div class="create-event-form w-form">
    <form [formGroup]="form">
      <h2 class="event-h2">Основная информация</h2>

      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="name">Название*</label>
        </div>
        <div class="create-form-column">
          <input
            type="text"
            class="create-event-field w-input"
            maxlength="256"
            name="name"
            data-name="name"
            [class.error-field]="submitted && f.name.errors"
            placeholder="Название"
            id="name"
            formControlName="name"
          />
          <ng-container *ngIf="submitted && f.name.errors">
            <mat-error *ngIf="f.name.errors.required">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
          </ng-container>
        </div>
      </div>

      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="name">Специальности</label>
        </div>
        <div class="create-form-column">
          <mat-select formControlName="universitySpecialities" multiple>
            <mat-option *ngFor="let spec of specialities" [value]="spec">{{ spec.name }}</mat-option>
          </mat-select>
        </div>
      </div>

      <input (click)="submit()" type="submit" value="Добавить ВУЗ" class="button green-btn w-button" />
    </form>
    <div class="w-form-done">
      <div>Thank you! Your submission has been received!</div>
    </div>
    <div class="w-form-fail">
      <div>Oops! Something went wrong while submitting the form.</div>
    </div>
  </div>
</div>
