<div class="admin-header-event-page" *ngIf="specialty">
  <div class="admin-header-col">
    <div class="event-status-text ev-page" *ngIf="specialty.createdDate">
      {{ 'SHARED.CREATED_DUE_DATE' | translate : { createdDate: createdDate } }}
    </div>
  </div>
  <div class="edit-event-column event-page-edit">
    <div class="edit-event-col-2">
      <a class="delete-event w-inline-block" (click)="popUpConfirming = !popUpConfirming"></a>
    </div>
    <div class="edit-event-col">
      <a [routerLink]="['/admin/edit-specialty', specialty.id]" class="button edit-event-page-btn w-button">{{
        'SHARED.EDIT_BUTTON' | translate
      }}</a>
    </div>
  </div>
</div>

<div class="event-page-section" *ngIf="specialty">
  <div class="event-page-header event-gradient-bg-2 purple">
    <div
      class="event-image-bg"
      [ngStyle]="{
        'background-image': specialty.mainImagePath ? 'url(' + specialty.mainImagePath + ')' : ''
      }"
    ></div>
    <div class="event-header-column"></div>
    <div class="event-header-column _2">
      <div class="event-page-h1-div">
        <h1 class="event-page-h1">{{ specialty.name }}</h1>
      </div>
    </div>
  </div>
  <div class="event-page-content">
    <div class="event-page-description-row">
      <div class="event-page-description-column">
        <h4 class="event-page-h4">{{ 'COURCES.PAGE.DESCRIPTION' | translate }}</h4>

        <p class="event-page-description-text" innerHtml="{{ specialty.description }}"></p>
      </div>

      <div *ngIf="specialty.govCode" class="event-page-descr-column">
        <h4 class="event-page-h4">govCode</h4>
        <p class="event-page-description-text">{{ specialty.govCode }}</p>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="popUpConfirming">
  <div class="perform-task-lb lb show">
    <div class="lb-bg"></div>
    <div class="lb-block task-lb">
      <a class="close-lb w-inline-block" (click)="popUpConfirming = !popUpConfirming">
        <img src="/assets/images/icons/close-lb.svg" />
      </a>
      <div class="tasl-lb-row">
        <div class="task-lb-column _2">
          <h4 class="task-lb-heading-result">Удаление ВУЗа</h4>
          <div class="task-result-text">Вы уверены, что хотите удалить ВУЗ?</div>
          <a class="button attach-file-btn w-button" (click)="deleteSpecialty(specialty.id)">{{ 'BUTTONS.DELETE' | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</ng-container>
