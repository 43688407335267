import { Component, Input, OnInit } from '@angular/core';
import { IUniversity } from 'app/shared/interfaces/iuniversity.interface';
import { UniversitiesService } from '../../universities.service';
import { Router } from '@angular/router';
import { Subject, throwError } from 'rxjs';
import * as moment from 'moment';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { catchError, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'prf-university-card',
  templateUrl: './university-card.component.html',
  styleUrls: ['./university-card.component.scss'],
})
export class UniversityCardComponent implements OnInit {
  @Input() university: IUniversity;

  userId: string;
  userRole: string;
  popUpConfirming: boolean = false;
  moment: any;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(private universitiesService: UniversitiesService, private utilsService: UtilsService, private router: Router) {
    this.moment = moment;
    this.moment.locale('ru');
  }

  ngOnInit() {
    this.userId = localStorage.getItem('userId');
    this.userRole = localStorage.getItem('userRole');
  }

  deleteUniversity(id) {
    this.universitiesService
      .deleteUniversity(id)
      .pipe(
        catchError(err => {
          this.popUpConfirming = !this.popUpConfirming;
          this.utilsService.openSnackBar('👎 Ошибка на сервере, попробуйте позже', 'error');
          return throwError(err);
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(delResponse => {
        if (delResponse.status == 'Success') {
          this.utilsService.openSnackBar(`👌 ВУЗ удален`, 'success');
        }
        this.popUpConfirming = !this.popUpConfirming;
        return setTimeout(_ => {
          this.router.navigate(['/admin/universities']);
        }, 1000);
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
