import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { IAllInstitutionsStatistic } from 'app/shared/interfaces/iallinstitutionsstatistic';
import { Observable, of, Subject, timer } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { InstitutionClass } from '../../../../../shared/classes/institution.class';
import { AdminPanelService } from '../../admin-panel.service';

@Component({
  selector: 'prf-edit-center',
  templateUrl: './edit-center.component.html',
  styleUrls: ['./edit-center.component.scss'],
})
export class EditCenterComponent implements OnInit {
  @Input() currentInstitution: IAllInstitutionsStatistic;
  @Output() institutionIdChanged = new EventEmitter();
  institutionInfo: InstitutionClass;

  selectedOptions = [];
  selectedFiles: File;
  imagePath: string;
  dialogOpen: boolean = false;
  audiences = [
    { value: 'ZeroPlus', viewValue: '0+' },
    { value: 'FourSix', viewValue: '4-6' },
    { value: 'SevenTen', viewValue: '7-10' },
    { value: 'ElevenThirteen', viewValue: '11-13' },
    { value: 'FourteenSixteen', viewValue: '14-16' },
    { value: 'SixteenPlus', viewValue: '16+' },
  ];
  institutionCategories = [
    { value: 'Commercial', viewValue: 'Коммерческое' },
    { value: 'State', viewValue: 'Государственное' },
  ];
  loaded: boolean = false;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(private adminPanelService: AdminPanelService, private utilsService: UtilsService) {}

  ngOnInit() {}

  toggleEditDialog() {
    this.dialogOpen = !this.dialogOpen;

    this.edit().pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
  }

  edit(): Observable<any> {
    return this.adminPanelService.getInstitution(this.currentInstitution.institutionId).pipe(
      tap(data => {
        this.institutionInfo = data;
        if (this.institutionInfo) {
          this.loaded = true;
        }
      }),
    );
  }

  //todo check after merge!
  onSubmit(valid: boolean) {
    if (valid) {
      this.adminPanelService
        .updateInstitutions(this.institutionInfo)
        .pipe(
          switchMap(response => {
            let currentObs$: Observable<any>;
            if (response) {
              this.utilsService.openSnackBar('👌 Центр обновлен', 'success');
              return currentObs$.pipe(
                switchMap(_ =>
                  timer(300).pipe(
                    switchMap(_ => {
                      return this.edit().pipe(
                        tap(_ => {
                          this.institutionIdChanged.emit(this.institutionInfo.id); // отправляем id измененного центра в centers.component
                        }),
                      );
                    }),
                  ),
                ),
              );
            } else {
              currentObs$ = of(null);
            }
            return currentObs$;
          }),
          takeUntil(this.ngUnsubscribe$),
        )
        .subscribe();
    }
  }

  removeSelectedRows() {
    this.selectedOptions.forEach(item => {
      let indexGalleryPaths: number = this.institutionInfo.galleryPaths.findIndex(d => d === item);
      if (indexGalleryPaths > -1) {
        this.institutionInfo.galleryPaths.splice(indexGalleryPaths, 1);
      }
    });
    this.selectedOptions = [];
  }

  selectFile(event) {
    if (event.target.files.item(0).type.match('image.*')) {
      this.selectedFiles = event.target.files;
    } else {
      alert('invalid format!');
    }
  }

  // async uploadMainImagePath() {
  //   this.institutionInfo.mainImagePath = await this.adminPanelService.uploadImage(
  //     this.selectedFiles,
  //     this.institutionInfo.id
  //   );
  // }

  // async uploadGalleryPaths() {
  //   let mainImagePaths = await this.adminPanelService.uploadImage(
  //     this.selectedFiles,
  //     this.institutionInfo.id
  //   );
  //   this.institutionInfo.galleryPaths.push(mainImagePaths);
  // }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
