import { inject, Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { Observable } from 'rxjs';
import {
  IGetCurrentSlideResponse,
  IMoveToNextSlideAnonymousResponse,
  IMoveToNextSlideResponse,
  IMoveToPreviousSlideResponse,
} from './api-slides.interface';
import { ApiSlidesUrls } from './api-slides.collections';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiSlidesService {
  private readonly httpService: HttpService = inject(HttpService);

  public moveToNextSlide(sessionId: string): Observable<IMoveToNextSlideResponse> {
    return this.httpService.get<IMoveToNextSlideResponse>(ApiSlidesUrls.MoveToNextSlide, { sessionId }).pipe(take(1));
  }

  public moveToNextSlideAnonymous(sessionId: string): Observable<IMoveToNextSlideAnonymousResponse> {
    return this.httpService.get<IMoveToNextSlideAnonymousResponse>(ApiSlidesUrls.MoveToNextSlideAnonymous, { sessionId }).pipe(take(1));
  }

  public moveToPreviousSlide(sessionId: string): Observable<IMoveToPreviousSlideResponse> {
    return this.httpService.get<IMoveToPreviousSlideResponse>(ApiSlidesUrls.MoveToPreviousSlide, { sessionId }).pipe(take(1));
  }

  public getCurrentSlide(sessionId: string): Observable<IGetCurrentSlideResponse> {
    return this.httpService.get<IGetCurrentSlideResponse>(ApiSlidesUrls.GetCurrentSlide, { sessionId }).pipe(take(1));
  }
}
