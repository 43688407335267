import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

declare let ymaps: any;

@Component({
  selector: 'prf-yandex-maps',
  templateUrl: './yandex-maps.component.html',
  styleUrls: ['./yandex-maps.component.scss'],
})
export class YandexMapsComponent implements OnInit {
  public yaMap;

  @Input() location;
  @Output() loaded = new EventEmitter<boolean>();

  @ViewChild('container', { static: true }) container: ElementRef;

  constructor() {}

  ngOnInit() {
    if (ymaps) {
      ymaps.ready().then(() => {
        ymaps
          .geocode(this.location, {
            results: 1,
          })
          .then(res => {
            let geoObject = res.geoObjects.get(0);
            let coords = geoObject.geometry.getCoordinates();
            this.yaMap = new ymaps.Map(this.container.nativeElement, {
              center: [coords[0], coords[1]],
              zoom: 16,
              controls: ['zoomControl'],
            });
            this.yaMap.behaviors.disable('scrollZoom');
            this.yaMap.geoObjects.add(new ymaps.Placemark([coords[0], coords[1]]));

            this.loaded.emit(true);
          });
      });
    }
  }
}
