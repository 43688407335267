<div class="filter-div cat" #ddTalents>
  <div class="professions-dropdown w-dropdown">
    <div
      class="professions-dropdown-toggle w-dropdown-toggle"
      (click)="talentsDrop.classList.toggle('w--open')"
      [class.active]="selectedTalents.length > 0"
    >
      <div class="professions-dd-icon-2 w-icon-dropdown-toggle"></div>
      <div>{{ checkFilterHeader() }}</div>
    </div>
    <nav class="dd-prof-filter-list w-dropdown-list" #talentsDrop>
      <div class="dd-prof-filter-div _2 metro-filter-div">
        <div class="filter-list-row">
          <ng-container *ngIf="selectedTalents.length > 0; else notSelected">
            <div class="filter-list-heading">{{ selectedTalents.length }} {{ 'SHARED.SELECTED' | translate }}</div>
            <a class="clear-categories show" (click)="deselectTalents()">{{ 'SHARED.CLEAR_BUTTON' | translate }}</a>
          </ng-container>
        </div>

        <div class="form-block w-form">
          <form id="metro-form" name="email-form" data-name="Email Form">
            <div class="search-block">
              <input
                type="text"
                class="search-categories w-input"
                maxlength="256"
                name="Search-2"
                data-name="Search 2"
                placeholder="{{ 'SHARED.SEARCH' | translate }}"
                id="Search-2"
                autocomplete="off"
                [(ngModel)]="searchTalents"
              />
              <a class="search-clear w-inline-block" (click)="searchTalents = ''" [class.show]="searchTalents && searchTalents.length > 0">
              </a>
            </div>
          </form>
          <div class="w-form-done">
            <div>Thank you! Your submission has been received!</div>
          </div>
          <div class="w-form-fail">
            <div>Oops! Something went wrong while submitting the form.</div>
          </div>
        </div>
        <div class="scroll-block">
          <div class="prof-categories-links">
            <a
              *ngFor="let talent of talents"
              (click)="selectTalent(talent.name)"
              class="event-categories-link w-inline-block"
              [class.w--current]="selectedTalents.indexOf(talent.name) !== -1"
            >
              <!--<div class="metro-color filter-metro"></div>-->
              <div class="category-text-link">{{ talent.name }}</div>
            </a>
          </div>
        </div>
      </div>
    </nav>
  </div>
</div>

<ng-template #notSelected>
  <div class="filter-list-heading">{{ 'SHARED.ALL_TALENTS' | translate }}</div>
  <a class="clear-categories">{{ 'SHARED.CLEAR_BUTTON' | translate }}</a>
</ng-template>
