import { inject, Injectable } from '@angular/core';
import { HttpService } from '../http';
import { forkJoin, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ApiAdminsUrls } from './api-admins.collections';
import {
  Company,
  IAllInstitutionsStatistic,
  ICompany,
  IFindUsersFilter,
  ILinkedCourseRegion,
  IRegionCourses,
  IUpdateRegistrationCode,
} from './api-admins.interface';
import { IClass, ISchool, ISchoolClass } from '../api-schools';
import { IStatusAnswer } from '../b2g-saas';

@Injectable({
  providedIn: 'root',
})
export class ApiAdminsService {
  private readonly httpService: HttpService = inject(HttpService);

  public getAllSchoolsStatistic(
    regionId?: string,
    municipalityId?: string,
    city?: string,
    date?: Date,
    from?: number,
    size?: number,
    year?: number,
  ): Observable<any> {
    return this.httpService
      .post(ApiAdminsUrls.GetAllSchoolsStatistic, { regionId, municipalityId, city, date, from, size, year })
      .pipe(take(1));
  }

  public updateAllSchoolsStatistic(regionId: string, reportYear?: number): Observable<any> {
    return this.httpService.post(ApiAdminsUrls.UpdateAllSchoolsStatistic, { regionId, reportYear }).pipe(take(1));
  }

  public getAllInstitutionsStatistic(): Observable<IAllInstitutionsStatistic[]> {
    return this.httpService.get<IAllInstitutionsStatistic[]>(ApiAdminsUrls.GetAllInstitutionsStatistic).pipe(take(1));
  }

  public updateAllInstitutionsStatistic(): Observable<any> {
    return this.httpService.post(ApiAdminsUrls.UpdateAllInstitutionsStatistic).pipe(take(1));
  }

  public changeUserInfo(dataUser): Observable<any> {
    return this.httpService.post(ApiAdminsUrls.ChangeUserInfo, dataUser).pipe(take(1));
  }

  public totalDeleteUser(userId): Observable<any> {
    return this.httpService.delete(ApiAdminsUrls.DeleteUser, { userId }).pipe(take(1));
  }

  public createAdminUser(dataUser): Observable<any> {
    return this.httpService.post(ApiAdminsUrls.AddAdmin, dataUser).pipe(take(1));
  }

  public findUsersByFilters(filters: IFindUsersFilter): Observable<any> {
    return this.httpService.post(ApiAdminsUrls.FindUsers, { filters }).pipe(take(1));
  }

  public getSchoolAdmins(regionId: string, municipalityId: string): Observable<any> {
    return this.httpService.get(ApiAdminsUrls.GetSchoolAdmins, { regionId, municipalityId }).pipe(take(1));
  }

  public getDirectors(regionId: string, municipalityId: string): Observable<any> {
    return this.httpService.get(ApiAdminsUrls.GetDirectors, { regionId, municipalityId }).pipe(take(1));
  }

  public getAdminDOUsers(regionId: string, municipalityId: string): Observable<any> {
    return this.httpService.get(ApiAdminsUrls.GetAdminDos, { regionId, municipalityId }).pipe(take(1));
  }

  public getAdminUsers(regionId: string, municipalityId: string): Observable<any> {
    return this.httpService.get(ApiAdminsUrls.GetRegionAdmins, { regionId, municipalityId }).pipe(take(1));
  }

  public getEmployerUsers(): Observable<any> {
    return this.httpService.get(ApiAdminsUrls.GetEmployerUsers).pipe(take(1));
  }

  public getTutorUsers(): Observable<any> {
    return this.httpService.get(ApiAdminsUrls.GetTutors).pipe(map((r: any) => r.tutors));
  }

  public downloadCatalogExcel(regionId: string, dataType: string): Observable<any> {
    return this.httpService.get(ApiAdminsUrls.GetCatalogReportData, { regionId, dataType }).pipe(take(1));
  }

  public getAllCompanies(): Observable<ICompany[]> {
    return this.httpService.get(ApiAdminsUrls.GetAllCompanies).pipe(map((response: any) => response.companies));
  }

  public getCompany(id: string): Observable<any> {
    return this.httpService.get(ApiAdminsUrls.GetCompanyById, { id }).pipe(take(1));
  }

  public updateCompany(company: Company): Observable<any> {
    return this.httpService.put(ApiAdminsUrls.GetCompanies, { company: company }).pipe(take(1));
  }

  public UpdateAllSchoolClassExtended(): Observable<any> {
    return this.httpService.post(ApiAdminsUrls.UpdateAllSchoolClassExtended).pipe(take(1));
  }

  public updateSchoolStatistic(schoolId): Observable<any> {
    return this.httpService.post(ApiAdminsUrls.UpdateExtendedPupils, { schoolId }).pipe(take(1));
  }

  public updateAllSchoolsAnalytics(regionId: string, dateFrom: Date, dateTo: Date): Observable<any> {
    return this.httpService.post(ApiAdminsUrls.CalculateAllSchoolReport, { regionId, dateFrom, dateTo }).pipe(take(1));
  }

  public updateSchoolAnalytics(schoolId: string, dateFrom: Date, dateTo: Date): Observable<any> {
    return this.httpService.post(ApiAdminsUrls.CalculateSchoolReport, { schoolId, dateFrom, dateTo }).pipe(take(1));
  }

  public calculatePartialRegioniReport(regionId: string) {
    return this.httpService.post(ApiAdminsUrls.CalculateRegionReport, { regionId, dateFrom: '', dateTo: '' }).pipe(take(1));
  }

  public calculatePartialMunicipalityiReport(regionId, municipalityId) {
    return this.httpService
      .post(ApiAdminsUrls.CalculateMunicipalityReport, { regionId, municipalityId, dateFrom: '', dateTo: '' })
      .pipe(map((response: any) => response.id));
  }

  public getInstitution(): Observable<any> {
    return this.httpService.get(ApiAdminsUrls.GetInstitution).pipe(take(1));
  }

  public createAdminDOClass(classesDto: IClass): Observable<any> {
    return this.httpService.post(ApiAdminsUrls.CreateAdminDOClass, classesDto).pipe(take(1));
  }

  public replaceAdminDOClass(classesDto: IClass): Observable<any> {
    return this.httpService.put(ApiAdminsUrls.ReplaceAdminDOClass, classesDto).pipe(take(1));
  }

  public removeAdminDOClass(classID: string): Observable<any> {
    return this.httpService.delete(ApiAdminsUrls.RemoveAdminDOClass, { classID }).pipe(take(1));
  }

  public getSchool(): Observable<ISchool> {
    return this.httpService.get(ApiAdminsUrls.SchoolAdminsGetSchool).pipe(take(1));
  }

  public addNewSchoolClass(schoolClassData: ISchoolClass): Observable<any> {
    return this.httpService.post(ApiAdminsUrls.SchoolAdminsAddSchoolClass, schoolClassData).pipe(take(1));
  }

  public removeSchoolClass(schoolClassId: any): Observable<any> {
    return this.httpService.delete(ApiAdminsUrls.SchoolAdminsDeleteSchoolClass, { schoolClassId }).pipe(take(1));
  }

  public updateSchoolClass(schoolClass: ISchoolClass): Observable<any> {
    return this.httpService.put(ApiAdminsUrls.SchoolAdminsUpdateSchoolClass, schoolClass).pipe(take(1));
  }

  public updateRegistrationCode(pupilToUpdate: IUpdateRegistrationCode): Observable<IStatusAnswer> {
    return this.httpService.post(ApiAdminsUrls.UpdateRegistrationCode, pupilToUpdate).pipe(take(1));
  }

  public removePupil(userId: any): Observable<any> {
    return this.httpService.delete(ApiAdminsUrls.RemoveFromSchoolClass, { userId }).pipe(take(1));
  }

  public removeTeacher(userId: string): Observable<any> {
    return this.httpService.delete(ApiAdminsUrls.DeleteTeacher, { userId }).pipe(take(1));
  }

  public removeUnregisteredPupil(userId: string): Observable<any> {
    return this.httpService.delete(ApiAdminsUrls.DeleteRegistrationCode, { userId }).pipe(take(1));
  }

  public resetPasswordToCode(email: string): Observable<any> {
    return this.httpService.get(ApiAdminsUrls.ResetPassword, { email }).pipe(take(1));
  }

  public createClassDO(classesDto: IClass): Observable<any> {
    return this.httpService.post(ApiAdminsUrls.CreateClass, classesDto).pipe(take(1));
  }

  public replaceClassDO(classesDto: IClass): Observable<any> {
    return this.httpService.put(ApiAdminsUrls.ReplaceClass, classesDto).pipe(take(1));
  }

  public deleteClassDO(classId: any): Observable<any> {
    return this.httpService.delete(ApiAdminsUrls.DeleteClass, { classId }).pipe(take(1));
  }

  public getRegionCoursesByRegionId(regionId: string): Observable<IRegionCourses[]> {
    return this.httpService
      .get<IRegionCourses[]>(ApiAdminsUrls.GetRegionCourseByRegionId, { regionId })
      .pipe(map((r: any) => r?.regionCourses ?? []));
  }

  public getTeachers(schoolId: string): Observable<any> {
    return this.httpService.get(ApiAdminsUrls.GetTeachers, { schoolId }).pipe(take(1));
  }

  public getCompletedTestsCount(schoolId: string): Observable<any> {
    return this.httpService.get(ApiAdminsUrls.GetCompletedTestsCount, { schoolId }).pipe(take(1));
  }

  public getSchoolClassesExtended(schoolId): Observable<any> {
    return this.httpService.get(ApiAdminsUrls.GetSchoolClassesWithCounts, { schoolId }).pipe(take(1));
  }

  private requestGetTeachers(schoolId: string): Observable<any> {
    return this.httpService.get(ApiAdminsUrls.GetTeacher, { schoolId }).pipe(take(1));
  }

  public refreshSchoolData(schoolId: string): Observable<any> {
    return forkJoin([
      this.requestGetTeachers(schoolId),
      this.getSchoolStatistic(schoolId),
      this.getSchoolClassesExtended(schoolId),
      this.getCompletedTestsCount(schoolId),
    ]);
  }

  public getSchoolStatistic(schoolId: any): Observable<any> {
    return this.httpService.get(ApiAdminsUrls.GetSchoolStatistic, { schoolId }).pipe(take(1));
  }

  public getDataUnregistered(schoolClassId: string): Observable<any> {
    return this.httpService.get(ApiAdminsUrls.UnloadInactivePupil, { schoolClassId }).pipe(take(1));
  }

  public transferPupil(email: string | null, phone: string | null, currentClass: string, destinationClass: string): Observable<any> {
    const data = {
      pupilEmail: email,
      currentClassName: currentClass,
      destinationClassName: destinationClass,
      pupilPhoneNumber: phone,
    };
    return this.httpService.post(ApiAdminsUrls.TransferPupil, data).pipe(take(1));
  }

  public bindTeacherToClass(teacherId: string, schoolClassId: string): Observable<any> {
    return this.httpService.get(ApiAdminsUrls.BindTeacherToClass, { teacherId, schoolClassId }).pipe(take(1));
  }

  public getRegionCourse(regionId: string, courseId: string): Observable<ILinkedCourseRegion> {
    return this.httpService.get<ILinkedCourseRegion>(ApiAdminsUrls.GetRegionCourse, { regionId, courseId }).pipe(take(1));
  }

  public getUserTrajectories(professionId: string): Observable<any> {
    return this.httpService.get(ApiAdminsUrls.GetUserTrajectories, { professionId }).pipe(take(1));
  }
}
