import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

@Component({
  selector: 'prf-compare-modal',
  templateUrl: './compare-modal.component.html',
  styleUrls: ['./compare-modal.component.scss'],
})
export class CompareModalComponent implements OnInit {
  public data;
  public isParent;

  constructor(public dialogRef: MatDialogRef<CompareModalComponent>, private translate: TranslateService) {}

  ngOnInit() {
    let tr = this.translate
      .getTranslation(this.translate.currentLang)
      .pipe(take(1))
      .subscribe(langObject => {
        if (this.isParent) {
          this.data.compareTitle =
            this.data.parentEstimate > this.data.childEstimate ? langObject.TEST_AND_RESULTS.LESS : langObject.TEST_AND_RESULTS.MORE;
        } else {
          this.data.compareTitle =
            this.data.parentEstimate < this.data.childEstimate ? langObject.TEST_AND_RESULTS.LESS : langObject.TEST_AND_RESULTS.MORE;
        }
      });
  }
}
