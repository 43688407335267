<a class="banner-container" [routerLink]="['/test-na-professiyu']">
  <div class="banner-content">
    <div class="banner-text">
      <span> За год твои интересы могли поменяться </span>
      <br />
      <span> Пройди тест еще раз </span>
    </div>
    <div class="banner-button">Пройти тест</div>
  </div>
</a>
