<div class="interests-progress-bar">
  <div class="interests-progress-bar__name">
    {{ 'TEST_AND_RESULTS.SKILS.INTERESTS' | translate }}
  </div>

  <div class="interests-progress-bar__content">
    <div class="interests-progress-bar__left-info">0 — {{ 'TEST_AND_RESULTS.NOT_INTERESTED' | translate }}</div>
    <div class="interests-progress-bar__centr-info">50</div>
    <div class="interests-progress-bar__right-info">100 — {{ 'TEST_AND_RESULTS.INTERESTED' | translate }}</div>
    <div class="interests-progress-bar__item" *ngFor="let skillData of data" (click)="openDialog(skillData)">
      <span *ngIf="skillData.canCompare" [ngClass]="{ 'hover-cursor': true }" class="interests-progress-bar__item-info"></span>
      <div
        *ngIf="!skillData.childEstimate"
        [ngClass]="{ 'interests-progress-bar__line_low': skillData.parentEstimate < 10 }"
        class="interests-progress-bar__line interests-progress-bar__line1"
        [style.width]="(skillData.parentEstimate < 10 ? 10 : skillData.parentEstimate) + '%'"
      ></div>
      <div
        *ngIf="skillData.childEstimate"
        class="interests-progress-bar__line"
        [ngClass]="{
          'hover-cursor': !skillData.isEqual,
          'interests-progress-bar__line3': !skillData.isEqual,
          'interests-progress-bar__line1': skillData.isEqual
        }"
        [style.width]="(skillData.parentEstimate < 10 ? 10 : skillData.parentEstimate) + '%'"
      ></div>
      <div
        *ngIf="skillData.childEstimate"
        class="interests-progress-bar__line"
        [ngClass]="{
          'hover-cursor': !skillData.isEqual,
          'interests-progress-bar__line2': !skillData.isEqual,
          'interests-progress-bar__line1': skillData.isEqual
        }"
        [style.width]="skillData.childEstimate + '%'"
      ></div>
      <div class="interests-progress-bar__num" [ngClass]="{ 'hover-cursor': skillData.canCompare }">
        {{ skillData.parentEstimate }}
      </div>
      <div class="interests-progress-bar__description" [ngClass]="{ 'hover-cursor': skillData.canCompare }">
        {{ skillData.title }}
      </div>
    </div>
  </div>
</div>
