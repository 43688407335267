<div class="login-section">
  <div class="login-container">
    <a routerLink="/" class="login-logo w-inline-block">
      <img class="login-logo-img" src="/assets/images/dashboard/profilum-logo.svg" alt="Profilum logo" />
    </a>
    <div class="tabs login w-tabs">
      <!-- <div class="tabs-menu w-tab-menu">
        <a
          [routerLink]="['/prosvetlogin']"
          [routerLinkActive]="['w--current']"
          class="tab-link login w-inline-block w-tab-link"
        >
          <div>{{ 'SHARED.ENTRANCE' | translate }}</div>
          <img src="/assets/images/icons/angle.png" width="20" class="angle" />
        </a>
        <a
          [routerLink]="['/prosvetregistration']"
          [routerLinkActive]="['w--current']"
          class="tab-link login w-inline-block w-tab-link"
        >
          <div>{{ 'SHARED.REGISTRATION' | translate }}</div>
          <img src="/assets/images/icons/angle2.png" width="20" class="angle _2" />
        </a>
      </div> -->

      <div class="w-tab-pane w--tab-active">
        <div class="tab-pane login">
          <div class="registration-row">
            <div class="registration-column">
              <a (click)="registrationProsvetUser()" class="registration-link w-inline-block">
                <img src="/assets/images/dashboard/reg-parent.svg" class="registration-link-image" />
                <div class="registration-link-text">Родитель</div>
              </a>
            </div>
            <div class="registration-column">
              <a (click)="registrationProsvetUser()" class="registration-link w-inline-block">
                <img src="/assets/images/dashboard/reg-student.svg" class="registration-link-image" />
                <div class="registration-link-text">Ученик</div>
              </a>
            </div>
            <div class="registration-column">
              <!--<a [routerLink]="['/registration-other']" class="registration-link w-inline-block">-->
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSeE_16yOAjFTvaFtgKkv730MQz0-fHwqQTS8RFFxusCUh203g/viewform"
                class="registration-link w-inline-block"
              >
                <img src="/assets/images/dashboard/reg-other.svg" class="registration-link-image" />
                <div class="registration-link-text">
                  {{ 'CODE_REGISTRATION_KZ.ORGANIZATION' | translate }}
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
