import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  CoursesFiltersService,
} from 'app/shared/dashboard/courses/courses-catalog/courses-filter/courses-filters.service';
import { ClassesFormatTypes } from 'app/shared/enums/courses-types.enum';
import { IFilterClasses } from 'app/shared/interfaces/ifilterclasses.interface';
import { OverlayBusyService } from 'app/shared/overlay-busy/overlay-busy.service';
import { Observable, of, Subject } from 'rxjs';
import { catchError, mergeMap, switchMap, takeUntil, tap, timeout } from 'rxjs/operators';
import { CoursesService } from '../../../courses.service';

const PAGE_SIZE: number = 6;
const TRIGGER_SCROLL_BOTTOM: number = 350;
const PAGE_LOAD: number = 12;

@Component({
  selector: 'prf-elastic-events-courses',
  templateUrl: './elastic-events-courses.component.html',
  styleUrls: ['./elastic-events-courses.component.scss'],
})
export class ElasticEventsCoursesComponent implements OnInit {
  @Input() isCourses: boolean;

  @Input() viewValue;
  @Output() countCoursesEmitter = new EventEmitter<any>();
  coursesCount: number;
  fromTo: any = { currentPage: 0, pageSize: PAGE_LOAD };
  filters: IFilterClasses;
  courses: Array<any> = [];
  titleName: string;
  currentCourses: Array<any> = [];
  cardsToShow: number = PAGE_SIZE;
  userRole: string;
  url: string;
  public searchCourses: any[];
  //нужно, чтобы загрузить курсы после очистки строки поиска
  private loadCoursesAfterSearchClear: boolean = false;

  loadStopper: boolean = false;
  isLoading: boolean = false;
  requestError: boolean = false;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private filtersService: CoursesFiltersService,
    private overlayService: OverlayBusyService,
    private coursesService: CoursesService,
    private router: Router,
  ) {
    this.userRole = localStorage.getItem('userRole');
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });
  }

  ngOnInit() {
    this.overlayService.show();
    this.filtersService.setCoursesFilter({});

    this.filtersService
      .getCoursesFilters()
      .pipe(
        mergeMap(filters => {
          //check and reject null/undefined values
          let filtersVerified: IFilterClasses = {};
          Object.keys(filters).forEach(key => {
            filters[key] != null && filters[key] != undefined ? (filtersVerified[key] = filters[key]) : null;
          });
          this.filters = filtersVerified;

          // Блок для избегания ошибок с повторными запросами и фильтрами
          this.loadStopper = false;
          this.fromTo.currentPage = 0;
          this.currentCourses = [];
          return this.loadCourses(this.filters);
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(() => this.overlayService.hide());
  }

  loadCourses(filtersObject): Observable<any> {
    let path = this.router.routerState.snapshot.url;
    let filters = Object.assign({}, filtersObject);

    if (this.filters && !this.filters.classesFormat) {
      if (path === '/events') {
        this.titleName = 'мероприятий';
        filters.classesFormat = [
          ClassesFormatTypes.MasterClass,
          ClassesFormatTypes.Excursion,
          ClassesFormatTypes.Action,
          ClassesFormatTypes.Festival,
          ClassesFormatTypes.Meeting,
          ClassesFormatTypes.Competition,
          ClassesFormatTypes.Profproba,
          ClassesFormatTypes.OpenDay,
        ];
      }
      if (path === '/courses') {
        this.titleName = 'курсов';
        filters.classesFormat = [ClassesFormatTypes.ShortCourse, ClassesFormatTypes.LongCourse];
      }
    }
    this.filtersService.setRegionMunicipalityFilters(filters);

    if (!this.isLoading && !this.loadStopper) {
      this.isLoading = true;
      return this.coursesService.getClassesElasticCount(filters).pipe(
        timeout(10000),
        switchMap(data => {
          this.countCoursesEmitter.emit(data);

          if (!this.loadStopper) {
            return this.coursesService.getElasticFilteredClasses(filters, this.fromTo).pipe(
              timeout(10000),
              tap(data => {
                let courses = data;
                this.loadStopper = !!(courses.length == 0 || courses.length < PAGE_LOAD);

                if (this.url === '/admin/courses/mine-courses') {
                  courses = courses.filter(d => d.institutionId === '00000000-0000-0000-0000-000000000000');
                }
                if (courses) {
                  for (let item in courses) {
                    if (courses) {
                      this.currentCourses.push(courses[item]);
                    }
                  }
                }
                this.requestError = false;
                this.isLoading = false;
              }),
              catchError(err => {
                this.isLoading = false;
                this.requestError = true;
                return of(null);
              }),
            );
          } else {
            return of(null);
          }
        }),
        catchError(err => {
          this.isLoading = false;
          this.requestError = true;
          return of(null);
        }),
        takeUntil(this.ngUnsubscribe$),
      );
    } else {
      return of(null);
    }
  }

  @HostListener('window:scroll', [])
  onScroll(): void {
    // условие на то, что это не список из поиска
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      this.loadMore();
      this.cardsToShow += PAGE_SIZE;
    }
  }

  public loadMore() {
    this.fromTo.currentPage += PAGE_LOAD;
    this.loadCourses(this.filters).pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
