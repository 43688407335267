<div class="filter-div cat" #institutionsFilter>
  <div class="professions-dropdown w-dropdown" [style.z-index]="filterOpen ? 1000 : 0">
    <div class="professions-dropdown-toggle w-dropdown-toggle" (click)="filterOpen = !filterOpen" [class.active]="filters.institutionId">
      <div class="professions-dd-icon-2 w-icon-dropdown-toggle"></div>
      <div>{{ checkFilterHeader() }}</div>
    </div>
    <nav class="dd-prof-filter-list w-dropdown-list" [class.w--open]="filterOpen">
      <div class="dd-prof-filter-div _2">
        <!-- <div class="filter-list-row">
          <ng-container *ngIf="filters.InstitutionId">
            <a class="clear-categories show" (click)="deselectInstitution()">{{
              'SHARED.CLEAR_BUTTON' | translate
            }}</a>
          </ng-container>
        </div> -->
        <div class="scroll-block">
          <div class="prof-categories-links">
            <a
              class="prof-categories-link w-inline-block"
              *ngFor="let institution of institutionsAll"
              [class.w--current]="selectedInstitution === institution"
              (click)="setInstitutionFilter(institution)"
            >
              <div class="category-text-link">{{ institution.name }}</div>
            </a>
          </div>
        </div>
      </div>
    </nav>
  </div>
</div>
