import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginProsvetComponent } from './login-prosvet/login-prosvet.component';
import { CodeRegistrationProsvetComponent } from './registration-prosvet/code-registration/code-registration-prosvet.component';
import { OpenRegistrationProsvetComponent } from './registration-prosvet/open-registration/open-registration-prosvet.component';
import { RegistrationProsvetComponent } from './registration-prosvet/registration-prosvet.component';
import { RegistrationPupilProsvetComponent } from './registration-prosvet/registration-pupil/registration-pupil-prosvet.component';

const routes: Routes = [
  { path: 'prosvetlogin', component: LoginProsvetComponent, pathMatch: 'full' },
  {
    path: 'prosvetregistration',
    component: RegistrationProsvetComponent,
    pathMatch: 'full',
  },
  {
    path: 'prosvetregistration-pupil',
    component: RegistrationPupilProsvetComponent,
    pathMatch: 'full',
  },
  {
    path: 'prosvetregistration-parent',
    component: OpenRegistrationProsvetComponent,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProsvetAuthRoutingModule {}
