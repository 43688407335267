import { Injectable } from '@angular/core';

@Injectable()
export class CalculationService {
  public calculateRealPrice(course: any): number {
    const discount: number = +course.unitDiscount;
    const price: number = +course.unitPrice;
    if (course.isPercent || course.isPercent === null) {
      return discount ? price - price * discount * 0.01 : price;
    } else {
      return discount ? price - discount : price;
    }
  }
}
