<form novalidate #f="ngForm" (ngSubmit)="onSubmit(f)">
  <div class="form-control">
    <mat-form-field>
      <input
        type="tel"
        matInput
        placeholder="{{ 'SHARED.FORM.PHONE' | translate }}"
        [(ngModel)]="_user.phoneNumber"
        [formControl]="phoneNumberFormControl"
      />
      <mat-error *ngIf="phoneNumberFormControl.hasError('required')">
        Телеон <strong>{{ 'SHARED.REQUIRED_FIELD' | translate }}</strong>
      </mat-error>
    </mat-form-field>
  </div>
  <button type="submit" mat-raised-button>Изменить телефон</button>
</form>
