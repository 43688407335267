import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { OverlayBusyService } from 'app/shared/overlay-busy/overlay-busy.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import {
  CoursesFiltersService,
} from 'app/shared/dashboard/courses/courses-catalog/courses-filter/courses-filters.service';
import { ISpeciality } from 'app/shared/interfaces/ispeciality.interface';
import { SpecialtiesService } from '../specialties.service';

@Component({
  selector: 'prf-all-specialties',
  templateUrl: './all-specialties.component.html',
  styleUrls: ['./all-specialties.component.scss'],
})
export class AllSpecialtiesComponent implements OnInit {
  currentSpecialties: ISpeciality[] = [];
  currentSpecialty: ISpeciality;
  specialtiesCount: string;

  dataFetched: boolean = false;
  viewValue: boolean = false;
  titleName: string;
  userRole: string;
  url: string;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private specialtiesService: SpecialtiesService,
    private overlayService: OverlayBusyService,
    private filtersService: CoursesFiltersService,
    private router: Router,
    private meta: Meta,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'Все специальности' });

    this.userRole = localStorage.getItem('userRole');
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });
  }

  ngOnInit() {
    this.overlayService.show();

    this.loadSpecialties()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => this.overlayService.hide());
  }

  loadSpecialties(): Observable<any> {
    return this.specialtiesService.getAllSpecialties().pipe(
      tap(response => {
        let specialties = response;

        if (specialties) {
          for (let item in specialties) {
            this.currentSpecialties.push(specialties[item]);
          }
          this.dataFetched = true;
        }
      }),
    );
  }

  // счетчик из фильтров и при ngOnInit
  public countSpecialties(specialtiesLength: number) {
    let specialtiesTitles = ['специальность', 'специальности', 'специальностей'];
    let title = this.filtersService.getTitleByNumber(specialtiesLength, specialtiesTitles);
    return (this.specialtiesCount = specialtiesLength + ' ' + title);
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
