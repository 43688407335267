import { Component, HostListener, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { mergeMap, takeUntil, tap } from 'rxjs/operators';
import { IInstitution } from '../../../../../../shared/interfaces/IInstitution.interface';
import { OverlayBusyService } from '../../../../../../shared/overlay-busy/overlay-busy.service';
import { CoursesListService } from '../../../../courses-page-without-filters/courses-list.service';
import {
  CoursesFiltersService,
} from '../../../../../../shared/dashboard/courses/courses-catalog/courses-filter/courses-filters.service';
import { CoursesService } from '../../../courses.service';
import { Observable, of, Subject } from 'rxjs';
import { ClassesFormatTypes } from 'app/shared/enums/courses-types.enum';
import { IFilterClasses } from 'app/shared/interfaces/ifilterclasses.interface';

export const PAGE_SIZE: number = 6;
const TRIGGER_SCROLL_BOTTOM: number = 350;
const PAGE_LOAD: number = 12;

@Component({
  selector: 'prf-events-courses-archive',
  templateUrl: './events-courses-archive.component.html',
  styleUrls: ['./events-courses-archive.component.scss'],
})
export class EventsCoursesArchiveComponent implements OnInit {
  @Input() isCourses: boolean;
  @Input() viewValue;
  @Input() searches;
  fromTo: any = { currentPage: 0, pageSize: PAGE_LOAD };
  filters: IFilterClasses;
  courses: Array<any> = [];
  dataFetched: boolean = false;
  titleName: string;
  currentCourses: Array<any> = [];
  cardsToShow: number = PAGE_SIZE;
  userRole: string;
  url: string;
  currentInstitution: IInstitution;
  loadStopper: boolean = false;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private coursesListService: CoursesListService,
    private filtersService: CoursesFiltersService,
    private overlayService: OverlayBusyService,
    private coursesService: CoursesService,
    private router: Router,
  ) {
    this.userRole = localStorage.getItem('userRole');
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });
  }

  ngOnInit() {
    this.overlayService.show();

    this.filtersService.setCoursesFilter({});
    this.filtersService
      .getCoursesFilters()
      .pipe(
        mergeMap(data => {
          this.filters = data;
          // Блок для избежания ошибок с повторными запросами и фильтрами
          this.currentCourses = [];
          this.loadStopper = false;
          this.fromTo.currentPage = 0;

          return this.loadCourses();
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(() => this.overlayService.hide());
  }

  loadCourses(): Observable<any> {
    let classesArchivedObservable$: Observable<any>;

    let path = this.router.routerState.snapshot.url;
    let filters = Object.assign({}, this.filters);
    if (this.filters && !this.filters.classesFormat) {
      if (path === '/events') {
        this.titleName = 'мероприятий';
        filters.classesFormat = [
          ClassesFormatTypes.MasterClass,
          ClassesFormatTypes.Excursion,
          ClassesFormatTypes.Action,
          ClassesFormatTypes.Festival,
          ClassesFormatTypes.Meeting,
          ClassesFormatTypes.Competition,
          ClassesFormatTypes.Profproba,
          ClassesFormatTypes.OpenDay,
        ];
      }
      if (path === '/courses') {
        this.titleName = 'курсов';
        filters.classesFormat = [ClassesFormatTypes.ShortCourse, ClassesFormatTypes.LongCourse];
      }
    }
    this.filtersService.setRegionMunicipalityFilters(filters);
    if (!this.loadStopper) {
      classesArchivedObservable$ = this.filtersService.getFilteredClassesArchived(filters, this.fromTo).pipe(
        tap(data => {
          let courses = data;
          this.loadStopper = !!(courses.length == 0 || courses.length < PAGE_LOAD);

          if (this.url === '/admin/courses/mine-courses') {
            courses = courses.filter(d => d.institutionId === '00000000-0000-0000-0000-000000000000');
          }
          if (courses) {
            for (let item in courses) {
              if (courses) {
                this.currentCourses.push(courses[item]);
              }
            }
          }
          this.dataFetched = true;
        }),
      );
    } else {
      classesArchivedObservable$ = of(null);
    }
    return classesArchivedObservable$;
  }

  @HostListener('window:scroll', [])
  onScroll(): void {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      this.loadMore();
      this.cardsToShow += PAGE_SIZE;
    }
  }

  public loadMore() {
    this.fromTo.currentPage += PAGE_LOAD;
    this.loadCourses().pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
