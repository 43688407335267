import { inject, Injectable } from '@angular/core';
import { HttpService } from '../http';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ApiInstitutionsUrls } from './api-institutions.collections';
import { InstitutionClass } from './api-institutions.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiInstitutionsService {
  private readonly httpService: HttpService = inject(HttpService);

  public getInstitutionsAll(): Observable<InstitutionClass[]> {
    return this.httpService.get(ApiInstitutionsUrls.GetAllInstitutions).pipe(map((response: any) => response.institutions));
  }

  public getInstitution(id: string): Observable<any> {
    return this.httpService.get(ApiInstitutionsUrls.GetInstitution, { id }).pipe(take(1));
  }

  public createInstitutions(institution: InstitutionClass): Observable<any> {
    return this.httpService.post(ApiInstitutionsUrls.Institutions, institution).pipe(take(1));
  }

  public updateInstitutions(institution: InstitutionClass): Observable<any> {
    return this.httpService.put(ApiInstitutionsUrls.Institutions, { institutionId: institution.id, institution }).pipe(take(1));
  }

  public deleteInstitutions(institutionId: string): Observable<any> {
    return this.httpService.delete(ApiInstitutionsUrls.Institutions, { institutionId }).pipe(take(1));
  }
}
