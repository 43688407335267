<div class="profession__where-to-work">
  <div class="slider profession__where-to-work--list">
    <button *ngIf="canScrollLeft()" type="button" (click)="scrollLeft()" class="prev" title="{{ getBackLabel() | translate }}">
      &nbsp;
    </button>
    <ul>
      <li class="profession__where-to-work--item" *ngFor="let item of frame">
        <div class="profession__where-to-work--pic" *ngIf="item.imageId">
          <img src="/content/images/companies/{{ item.imageId }}" height="62" />
        </div>
        <div class="profession__where-to-work--link" (click)="processClick(item.url)">
          {{ item.name }}
        </div>
      </li>
    </ul>
    <button *ngIf="canScrollRight()" type="button" class="next" (click)="scrollRight()" title="{{ getForwardLabel() | translate }}">
      &nbsp;
    </button>
  </div>
</div>
