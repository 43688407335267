import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'prf-internship-search',
  templateUrl: './internship-search.component.html',
  styleUrls: ['./internship-search.component.scss'],
})
export class InternshipSearchComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
