import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'prf-mine-internships',
  templateUrl: './mine-internships.component.html',
  styleUrls: ['./mine-internships.component.scss'],
})
export class MineInternshipsComponent implements OnInit {
  public searches: any = [];
  public courses: any = [];
  public internshipsCount: string;
  public viewValue: boolean = false;

  constructor(private meta: Meta) {
    this.meta.updateTag({ name: 'og:title', content: 'Мои стажировки' });
  }

  ngOnInit() {}

  dataSearch(data) {
    this.searches = data;
  }

  ngOnDestroy() {}
}
