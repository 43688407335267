import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';

import * as moment from 'moment';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';
import { UserInfoClass } from '../../../../shared/classes/userInfo.class';
import { REG_EXP } from '../../../../shared/global-constants/reg-exp';
import { SettingsService } from '../../../../shared/dashboard/settings/settings.service';

import { UserProfileService } from '../user-profile.service';
import { Observable, of, Subject } from 'rxjs';
import { throwError } from 'rxjs/internal/observable/throwError';
import { SharedService } from 'app/shared/shared.service';

@Component({
  selector: 'prf-edit-user-profile',
  templateUrl: './edit-user-profile.component.html',
  styleUrls: ['./edit-user-profile.component.scss'],
})
export class EditUserProfileComponent implements OnInit {
  @Input() user: UserInfoClass;
  @Input() userRole: any;
  @Input() animIndexToChild: any;
  @Output() editProfile = new EventEmitter();
  @Output() indexFromChild = new EventEmitter();

  form: UntypedFormGroup;
  subscription: any;
  phoneRegExp: RegExp = REG_EXP.phoneRegExp;
  emailRegExp: RegExp = REG_EXP.emailRegExp;
  phoneMask: Array<any> = ['+', '7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];
  submitted: boolean = false;
  duplicateUserName: boolean = false;

  public readonly dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public readonly dateRegExp: RegExp = REG_EXP.dateRegExp;

  public date: string;
  public moment: any = moment;
  public isMale: boolean = false;
  public emailSubmitted: boolean = false;
  public userBirthday: string = '';
  public checkEmail: boolean = true;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private profileService: UserProfileService,
    private fb: UntypedFormBuilder,
    private settingsService: SettingsService,
    private router: Router,
    private utilsService: UtilsService,
    private translateService: TranslateService,
    private sharedService: SharedService,
  ) {
    this.moment = moment;
    this.moment.locale('ru');
  }

  ngOnInit() {
    this.form = this.fb.group({
      lastName: new UntypedFormControl(this.user.lastName, [Validators.required]),
      firstName: new UntypedFormControl(this.user.firstName, [Validators.required]),
      //  middleName: new FormControl(this.user.middleName, [Validators.required]),
      phone: new UntypedFormControl(this.user.phoneNumber.slice(-10), [Validators.pattern(this.phoneRegExp)]),
      email: new UntypedFormControl(this.user.email, [Validators.required, Validators.pattern(this.emailRegExp)]),
      date: new UntypedFormControl(moment.utc(this.user.birthday, 'YYYY-MM-DD').format('DD/MM/YYYY'), [
        Validators.pattern(this.dateRegExp),
      ]),
      gender: new UntypedFormControl(this.user.gender, [Validators.required]),
    });
    this.userBirthday = moment.utc(this.user.birthday, 'YYYY-MM-DD').format('DD/MM/YYYY');
  }

  cancelEdit() {
    this.editProfile.emit(false);
    this.duplicateUserName = false;
  }

  get f() {
    return this.form.controls;
  }

  submitChanges() {
    // выключаем валидацию, если пользователь не менял дату
    if (this.form.controls['date'].pristine == true) {
      this.form.controls.date.setValidators([Validators.nullValidator]);
      this.form.controls.date.updateValueAndValidity();
    }
    this.emailSubmitted = false;
    this.submitted = true;
    if (this.form.valid) {
      let emailUpdateObservable$: Observable<any>;
      if (this.form.value.email !== this.user.email) {
        emailUpdateObservable$ = this.profileService.changeEmail(this.form.value.email).pipe(
          switchMap(response => {
            if (response.status == 'Failed' && response.comment == 'Invalid email') {
              this.duplicateUserName = true;
              this.emailSubmitted = false;
              return of(null);
            } else if (response.status == 'Success') {
              this.emailSubmitted = true;
              this.user.email = this.form.value.email;
              this.utilsService.openSnackBar('👌 Изменения успешно сохранены', 'success');
              return this.sharedService.requestUserInfo();
            }
          }),
          catchError(err => {
            this.emailSubmitted = false;
            return this.getTranslation('SHARED.SOMETHING_WENT_WRONG').pipe(
              switchMap(translation => {
                console.error(translation);
                return throwError(err);
              }),
            );
          }),
        );
      } else {
        emailUpdateObservable$ = of(null);
      }

      emailUpdateObservable$
        .pipe(
          switchMap(r => {
            let currentObs$: Observable<any>;
            if (
              this.form.value.phone !== this.user.phoneNumber ||
              this.form.value.date !== this.userBirthday ||
              this.form.value.lastName !== this.user.lastName ||
              this.form.value.firstName !== this.user.firstName ||
              //   this.form.value.middleName !== this.user.middleName ||
              this.form.value.gender !== this.user.gender
            ) {
              this.user.phoneNumber = this.form.value.phone?.replace(/\D/g, '');
              this.user.birthday = moment.utc(this.form.value.date, 'DD/MM/YYYY'); // записываем дату в user в формате Moment
              this.user.lastName = this.form.value.lastName;
              this.user.firstName = this.form.value.firstName;
              //  this.user.middleName = this.form.value.middleName;
              this.user.gender = this.form.value.gender;

              currentObs$ = this.profileService.updateUserProfile(this.user).pipe(
                tap(data => {
                  this.profileService.setUserProfile(this.user);
                  this.editProfile.emit(false);
                  this.userBirthday = moment.utc(this.user.birthday, 'YYYY-MM-DD').format('DD/MM/YYYY');
                  this.utilsService.openSnackBar('👌 Изменения успешно сохранены', 'success');
                }),
                catchError(err => {
                  this.emailSubmitted = false;
                  return this.getTranslation('SHARED.SOMETHING_WENT_WRONG').pipe(
                    switchMap(translation => {
                      console.error(translation);
                      return throwError(err);
                    }),
                  );
                }),
              );
            } else {
              currentObs$ = of(null);
            }
            this.emailSubmitted = true;

            return currentObs$;
          }),
          takeUntil(this.ngUnsubscribe$),
        )
        .subscribe();
    } else {
      this.emailSubmitted = false;
    }
    this.changeIndex(this.animIndexToChild, this.emailSubmitted);
  }

  public changeIndex(index: number, toChange: boolean) {
    if (toChange == true) {
      if (index == 0 || index == 1) {
        index = 2;
        this.indexFromChild.emit(index);
      } else {
        index = 1;
        this.indexFromChild.emit(index);
        // восстанавливаем валидацию даты
        this.form.controls.date.setValidators([Validators.required, Validators.pattern(this.dateRegExp)]);
      }
      return index;
    } else {
      return;
    }
  }

  public checkFormatEmail(event): boolean {
    if (event) {
      this.checkEmail = this.emailRegExp.test(this.form.value.email);
      if (this.duplicateUserName == true) {
        this.duplicateUserName = !this.duplicateUserName;
      }
      return;
    }
  }

  getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
