import { Injectable } from '@angular/core';
import { HttpService } from 'app/core/services/http.service';

import { Stands } from 'app/shared/enums/stands.enums';
import { IFilterClasses } from 'app/shared/interfaces/ifilterclasses.interface';
import { map } from 'rxjs/operators';

export interface ILazyController {
  currentPage: number;
  pageSize: number;
  hasData: boolean;
}

@Injectable()
export class CoursesListService {
  constructor(private http: HttpService) {}

  public getTalentGroupCourse() {
    return this.http.get('/api/v1.0/catalog/search/talentgroupcourse').pipe(map(response => response.result));
  }

  public getClassesCountStatistic(filters: IFilterClasses) {
    const credentials = {
      filters: filters,
    };
    credentials.filters.stand = Stands.Talent;
    return this.http
      .post('/api/v1.0/catalog/classes/mongo/classescountstatistic', credentials)
      .pipe(map(response => response.classesCountStatistic));
  }
  public getElasticClassesCountStatistic(filters: IFilterClasses) {
    const credentials = {
      filters: filters,
    };
    credentials.filters.stand = Stands.Talent;
    return this.http
      .post('/api/v1.0/catalog/classes/elasticsearch/classescountstatistic', credentials)
      .pipe(map(response => response.classesCountStatistic));
  }

  public getCourseGroups() {
    return this.http.get('/api/v1.0/catalog/fielddos/all').pipe(
      map(response => {
        const data = response.fieldDOs;
        data.sort(sortList);
        return data;
      }),
    );
  }

  // данные приходят несортированные и с дубликатами
  // TODO добавить цвет в модель метро
  public getMetro() {
    return this.http.get('/api/v1.0/catalog/search/metrostations').pipe(
      map(response => {
        const data = response.result;

        data.sort(sortList);
        return uniqSorted(data);
      }),
    );
  }

  public getTalents() {
    return this.http.get('/api/v1.0/catalog/search/talents').pipe(
      map(response => {
        const data = response.result;
        data.sort(sortList);
        return uniqSorted(data);
      }),
    );
  }

  public getMaxPrice() {
    return this.http.get('/api/v1.0/catalog/search/maxprice').pipe(map(response => response.result));
  }
}

function sortList(left, right) {
  if (left.name > right.name) {
    return 1;
  }
  if (left.name < right.name) {
    return -1;
  }
  return 0;
}

function uniqSorted(list) {
  const newList = [list[0]];

  list.forEach(({ name, line }) => {
    if (newList[newList.length - 1].name !== name) {
      newList.push({ name, line });
    }
  });

  return newList;
}
