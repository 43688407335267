import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { SessionStatusEnum } from '../../enums';
import { B2gSaasService } from '../../api-services';

@Injectable()
export class StartMincifryTestGuard {
  /* для решения проблемы пересоздания сессий,
  когда пользователь повторно нажимает на кнопку "пройти диагностику" на странице /mincifry-test */

  constructor(private b2gSaasService: B2gSaasService, private router: Router) {}

  public async canActivate(): Promise<boolean> {
    try {
      const { sessionId, status } = await this.getLastSession();

      switch (status) {
        case SessionStatusEnum.Success:
          await this.router.navigate(['/mincifry-test-results', sessionId]);
          return false;
        case SessionStatusEnum.Uncompleted:
          await this.router.navigate(['/mincifry-test', sessionId]);
          return false;
        default:
          return true;
      }
    } catch (e) {
      return true;
    }
  }

  private getLastSession(): Promise<{ sessionId: string; status: string }> {
    const userId = window.localStorage.getItem('userId');

    if (userId) {
      return this.b2gSaasService
        .getLastSession(userId)
        .pipe(
          map(({ sessionId, status }) => {
            return { sessionId, status };
          }),
        )
        .toPromise();
    }

    return Promise.reject(null);
  }
}
