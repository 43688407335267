import { inject, Injectable } from '@angular/core';
import { HttpService } from '../http';
import { forkJoin, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { IExtendedPupil } from '../api-users';
import { UserDataHandlerService } from '../../utils-services';
import { ApiTemporaryUrls } from './api-temporary.collections';
import { ISchoolClassPersentage } from './api-temporary.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiTemporaryService {
  private readonly httpService: HttpService = inject(HttpService);
  private readonly userDataHandlerService: UserDataHandlerService = inject(UserDataHandlerService);

  public userControlDomain(): string {
    const userRole = this.userDataHandlerService.getUserInfo().role;
    switch (userRole) {
      case 'admin':
        return 'admins';
      case 'adminDO':
        // check the role!
        return 'adminsdo';
      case 'schooladmin':
        return 'schooladmins';
      case 'director':
        return 'directors';
      case 'teacher':
        return 'teachers';
      case 'parent':
        return 'parents';
      case 'pupil':
        return 'pupils';
      case 'employer':
        return '';
      default:
        break;
    }
  }

  getSchoolClass(schoolClassId: string): Observable<any> {
    return this.httpService.get(`/saas/v1.0/${this.userControlDomain()}/getschoolclass`, { schoolClassId }).pipe(take(1));
  }

  getTeacher(schoolClassId: string, schoolId: string): Observable<any> {
    let url = '';
    const apiControlName: string = this.userControlDomain();
    switch (apiControlName) {
      case 'admins':
        url = ApiTemporaryUrls.AdminsGetTeacher;
        break;
      case 'schooladmins':
        url = ApiTemporaryUrls.SchoolAdminsGetTeacher;
        break;
      default:
        url = ApiTemporaryUrls.GetTeacher;
        break;
    }
    return this.httpService.get(url, { schoolClassId, schoolId }).pipe(take(1));
  }

  public getExtendedPupils(schoolClassId: string): Observable<IExtendedPupil[]> {
    return this.httpService.get<any[]>(`/saas/v1.0/${this.userControlDomain()}/getextendedpupils`, { schoolClassId }).pipe(take(1));
  }

  public getPupils(schoolClassId: string): Observable<any[]> {
    return this.httpService.get<any[]>(`/saas/v1.0/${this.userControlDomain()}/getpupilsbyclass`, { schoolClassId }).pipe(take(1));
  }

  public refreshSchoolClass(schoolClassId: string, schoolId: string): Observable<any> {
    return forkJoin([this.getTeacher(schoolClassId, schoolId), this.getExtendedPupils(schoolClassId), this.getPupils(schoolClassId)]);
  }

  public getSchoolClassPage(schoolClassId: string): Observable<any> {
    return this.httpService
      .get(`/saas/v1.0/${this.userControlDomain()}/getschoolclasspage`, { schoolClassId })
      .pipe(filter(r => r !== null));
  }

  public getSchoolClassPercentsPage(schoolClassId: string): Observable<any> {
    return this.httpService.get(`/saas/v1.0/${this.userControlDomain()}/getschoolclasspercentspage`, { schoolClassId }).pipe(take(1));
  }

  public getSchoolClassPercentsPageForCG(schoolClassId: string): Observable<ISchoolClassPersentage> {
    return this.httpService.get(`/saas/v1.0/${this.userControlDomain()}/getschoolclasspercentspageforkg`, { schoolClassId }).pipe(take(1));
  }

  public getSchoolClassStatistic(schoolClassId: string): Observable<any> {
    return this.httpService.get(`/saas/v1.0/${this.userControlDomain()}/getschoolclassstatistic?schoolClassId=${schoolClassId}`).pipe(
      filter(r => r !== null),
      take(1),
    );
  }

  getClassTestingSessions(schoolClassId: string): Observable<any> {
    return this.httpService.get(`/saas/v1.0/${this.userControlDomain()}/getlastsessionsbyclass?schoolClassId=${schoolClassId}`).pipe(
      filter(r => r !== null),
      take(1),
    );
  }
}
