import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import {
  CoursesFiltersService,
} from 'app/shared/dashboard/courses/courses-catalog/courses-filter/courses-filters.service';
import { IFilterClasses } from 'app/shared/interfaces/ifilterclasses.interface';
import { Subject, throwError } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';

import { CoursesService } from '../../courses.service';

@Component({
  selector: 'prf-active-courses',
  templateUrl: './active-courses.component.html',
  styleUrls: ['./active-courses.component.scss'],
})
export class ActiveCoursesComponent implements OnInit {
  public courses: any = [];
  public classesCount: string;
  public classesSearchCount: string;
  public viewValue: boolean = false;
  filters: IFilterClasses;

  private ngUnsubscribe$: Subject<any> = new Subject();
  private userRole: String = localStorage.getItem('userRole');

  constructor(private meta: Meta, private filtersService: CoursesFiltersService, private coursesService: CoursesService) {}

  ngOnInit() {
    this.filtersService.getCoursesFilters().pipe(
      tap(filters => {
        this.filters = filters;
        this.findCoursesCountByRegion();
      }),
      takeUntil(this.ngUnsubscribe$),
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }

  showTerritoryFilter() {
    return this.userRole === 'schooladmin' ? false : true;
  }

  findCoursesCountByRegion() {
    let filters = Object.assign({}, this.filters);
    let getCount$ = this.coursesService.getClassesElasticCount(filters);
    this.filtersService.setRegionMunicipalityFilters(filters);

    if (this.userRole === 'admin') {
      getCount$ = this.coursesService.getClassesCount(filters);
    }

    return getCount$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(
      count => {
        return this.countCourses(null, count.count);
      },
      catchError(err => throwError(err)),
    );
  }

  // счетчик из фильтров и при ngOnInit
  public countCourses(coursesLength: number, searchLength: number = 0) {
    let complexLength = coursesLength + searchLength;
    let courseTitles = ['курс', 'курса', 'курсов'];
    let title = this.filtersService.getTitleByNumber(complexLength, courseTitles);
    return (this.classesCount = complexLength + ' ' + title);
  }
}
