import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { IUniversity } from 'app/shared/interfaces/iuniversity.interface';
import { OverlayBusyService } from 'app/shared/overlay-busy/overlay-busy.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { UniversitiesService } from '../universities.service';
import {
  CoursesFiltersService,
} from 'app/shared/dashboard/courses/courses-catalog/courses-filter/courses-filters.service';

const PAGE_SIZE: number = 6;
const TRIGGER_SCROLL_BOTTOM: number = 350;
const PAGE_LOAD: number = 12;

@Component({
  selector: 'prf-all-universities',
  templateUrl: './all-universities.component.html',
  styleUrls: ['./all-universities.component.scss'],
})
export class AllUniversitiesComponent implements OnInit {
  currentUniversities: IUniversity[] = [];
  currentUniversity: IUniversity;
  universitiesCount: string;

  dataFetched: boolean = false;
  viewValue: boolean = false;
  titleName: string;
  userRole: string;
  url: string;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private universitiesService: UniversitiesService,
    private overlayService: OverlayBusyService,
    private filtersService: CoursesFiltersService,
    private meta: Meta,
    private router: Router,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'Все ВУЗы' });

    this.userRole = localStorage.getItem('userRole');
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });
  }

  ngOnInit() {
    this.overlayService.show();

    this.loadUniversities()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => this.overlayService.hide());
  }

  loadUniversities(): Observable<any> {
    return this.universitiesService.getAllUniversities().pipe(
      tap(response => {
        let universities = response;

        if (universities) {
          for (let item in universities) {
            this.currentUniversities.push(universities[item]);
          }
          this.dataFetched = true;
        }
      }),
    );
  }

  // счетчик из фильтров и при ngOnInit
  public countUniversities(universitiesLength: number) {
    let universitiesTitles = ['ВУЗ', 'ВУЗа', 'ВУЗов'];
    let title = this.filtersService.getTitleByNumber(universitiesLength, universitiesTitles);
    return (this.universitiesCount = universitiesLength + ' ' + title);
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
