import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SPIDER_CHART_CONFIG } from '../spider-chart-custom.config';
import { ISpiderChartConfig } from '../spider-chart-custom.model';
import { SpiderChartService } from '../spider-chart.service';
import { IPathData, ISpiderChartLayoutConfig } from './spider-chart-layout.model';

@Component({
  selector: 'prf-spider-chart-layout',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './spider-chart-layout.component.html',
  styleUrls: ['./spider-chart-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpiderChartLayoutComponent implements OnInit {
  public pathData: IPathData;

  private spiderChartService: SpiderChartService = inject(SpiderChartService);

  protected readonly SPIDER_CHART_CONFIG: ISpiderChartConfig = SPIDER_CHART_CONFIG;
  protected readonly Array: ArrayConstructor = Array;

  @Input({ required: true }) public layoutConfig: ISpiderChartLayoutConfig;

  public ngOnInit(): void {
    this.pathData = this.spiderChartService.getLayoutConfig(this.layoutConfig);
  }
}
