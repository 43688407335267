import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { ISpeciality } from 'app/shared/interfaces/ispeciality.interface';
import { IUniversity } from 'app/shared/interfaces/iuniversity.interface';
import { OverlayBusyService } from 'app/shared/overlay-busy/overlay-busy.service';
import { forkJoin, Subject, throwError } from 'rxjs';
import { catchError, take, takeUntil, tap } from 'rxjs/operators';
import { UniversitiesService } from '../universities.service';

@Component({
  selector: 'prf-edit-university',
  templateUrl: './edit-university.component.html',
  styleUrls: ['./edit-university.component.scss'],
})
export class EditUniversityComponent implements OnInit {
  university: IUniversity;
  universityId: string;

  specialities: ISpeciality[];

  public submitted: boolean = false;

  public userRole: string;
  popUpConfirming: boolean = false;

  public form: UntypedFormGroup;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private meta: Meta,
    private fb: UntypedFormBuilder,
    private router: Router,
    public route: ActivatedRoute,
    private utilsService: UtilsService,
    private overlayService: OverlayBusyService,
    private translateService: TranslateService,
    private universitiesService: UniversitiesService,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'Редактирование ВУЗа' });
    this.userRole = localStorage.getItem('userRole');
  }

  ngOnInit() {
    this.overlayService.show();
    this.route.params.pipe(take(1)).subscribe(params => {
      this.universityId = params['id'];
    });
    forkJoin([this.universitiesService.getUniversityById(this.universityId), this.universitiesService.getSpecialities()])
      .pipe(
        tap(([university, specialities]) => {
          this.university = university;
          this.specialities = specialities;
          this.form = this.fb.group({
            id: new UntypedFormControl(this.university.id, []),
            name: new UntypedFormControl(this.university.name, [Validators.required]),
            universitySpecialities: new UntypedFormControl(university.universitySpecialities, []),
          });
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(
        r => this.overlayService.hide(),
        err => {
          this.overlayService.hide();
        },
      );
  }

  get f() {
    return this.form.controls;
  }

  submit() {
    this.submitted = true;

    if (this.form.valid) {
      let universityObject = {
        name: this.form.get('name').value,
        universitySpecialities: this.form.get('universitySpecialities').value,
      };

      this.universitiesService
        .updateUniversity(universityObject)
        .pipe(
          tap(r => {
            this.utilsService.openSnackBar('👌 ВУЗ обновлён, вы будете перенаправлены на страницу данного ВУЗа', 'success');
            return setTimeout(_ => {
              this.router.navigate(['/admin/universities/' + this.form.get('id').value]);
            }, 2000);
          }),
        )
        .subscribe();
    } else {
      this.utilsService.openSnackBar('👎 Произошла ошибка, проверьте правильность данных', 'error');
    }
  }

  deleteUniversity(id) {
    this.universitiesService
      .deleteUniversity(id)
      .pipe(
        catchError(err => {
          this.popUpConfirming = !this.popUpConfirming;
          this.utilsService.openSnackBar('👎 Ошибка на сервере, попробуйте позже', 'error');
          return throwError(err);
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(delResponse => {
        if (delResponse.status == 'Success') {
          this.utilsService.openSnackBar(`👌 ВУЗ удален`, 'success');
        }
        this.popUpConfirming = !this.popUpConfirming;
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
