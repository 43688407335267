<div class="prf-navigation">
  <ng-template [ngIf]="showStartDiagnosticButton" [ngIfElse]="steps">
    <a class="prf-navigation-button prf-navigation-testing-button" [class.disabled]="disableForward" (click)="forward()">
      Пройти диагностику
    </a>
  </ng-template>

  <ng-template #steps>
    <ng-template [ngIf]="isFirstStep" [ngIfElse]="notFirst">
      <a class="prf-navigation-button prf-navigation-testing-button prf-navigation-button-back" [routerLink]="getPath()">
        {{ 'BUTTONS.PREVIOUS' | translate }}
      </a>

      <a
        class="prf-navigation-button prf-navigation-testing-button prf-navigation-button-next"
        [class.disabled]="disableForward"
        (click)="forward()"
      >
        <ng-template [ngIf]="isFinish" [ngIfElse]="next"> {{ 'BUTTONS.OK' | translate }}</ng-template>
        <ng-template #next>{{ 'BUTTONS.NEXT' | translate }}</ng-template>
      </a>
    </ng-template>
    <ng-template #notFirst>
      <a
        class="prf-navigation-button prf-navigation-testing-button prf-navigation-button-back"
        [class.disabled]="disableBack"
        (click)="back()"
      >
        {{ 'BUTTONS.PREVIOUS' | translate }}
      </a>

      <a
        class="prf-navigation-button prf-navigation-testing-button prf-navigation-button-next"
        [class.disabled]="disableForward"
        (click)="forward()"
      >
        <ng-template [ngIf]="isFinish" [ngIfElse]="next"> {{ 'BUTTONS.OK' | translate }}</ng-template>
        <ng-template #next>{{ 'BUTTONS.NEXT' | translate }}</ng-template>
      </a>
    </ng-template>
  </ng-template>
</div>
