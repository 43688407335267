export const enum B2gReportsUrls {
  GetGeneratedRegionReportByEmail = '/saas/v1.0/reports/getgeneratedregionreportbyemail',
  GetRegionStatisticExcel = '/saas/v1.0/reports/getregionstatisticexcel',
  UpdatePupilUserInfo = '/saas/v1.0/reports/updatepupilusersinfoforregion',
  UpdateRegionStatisticExcel = '/saas/v1.0/reports/updateregionstatisticexcel',
  GetPupilUserInfoExcel = '/saas/v1.0/reports/getpupilusersinfoforregionexcel',
  GenerateReportProfessionsFromField = '/saas/v1.0/reports/generatereportprofessionsfromfield',
  GetReportProfessionsFromField = '/saas/v1.0/reports/getreportprofessionsfromfield',
}

export const enum EiType {
  Unknown,
  School,
  EducationCenter,
}
