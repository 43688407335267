export enum EAnketaStep {
  AboutTest = 1,
}

export enum ETestType {
  Default = 1,
  Test360 = 2,
  Specialist = 3,
  MotivationTest = 4,
  ValuesTest = 5,
  CareerTypes = 6,
}

export enum EUserStatus {
  Specialist = 1,
  Student,
  Schoolar,
}
