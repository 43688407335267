import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'prf-child-info',
  templateUrl: './child-info.component.html',
  styleUrls: ['./child-info.component.scss'],
})
export class ChildInfoComponent {
  @Input() form: UntypedFormGroup;

  public ageRange = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];
  public childAge: number = this.ageRange[0];
  public isMaleGender: boolean = true;

  public onAgeChanged(event): void {
    this.childAge = event.value;
  }

  public changeGender() {
    if (setTimeout) {
      setTimeout(() => {
        this.isMaleGender = !this.isMaleGender;
      }, 300);
    } else {
      this.isMaleGender = !this.isMaleGender;
    }
  }
}
