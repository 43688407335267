import { Injectable } from '@angular/core';
import { IApplyInternship } from 'app/shared/interfaces/iapplyinternship.interface';
import { BehaviorSubject, Observable, throwError, TimeoutError } from 'rxjs';
import { HttpService } from '../../../../core/services/http.service';
import { catchError, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class InternshipDetailsService {
  private currentInternship = new BehaviorSubject(null);
  private favoritesInternship = new BehaviorSubject(null);
  private favoritesInternshipsChange = new BehaviorSubject(null);

  constructor(private http: HttpService) {}

  // обработка ошибок
  handleError(err: any): Observable<any> {
    if (err instanceof TimeoutError) {
      console.error(`Frontend returned timeout error: ${err['error']}`);
      return throwError(err['error']);
    }
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
      let errorText = err.error ? (err.error.comment ? err.error.comment : err.error) : null;
    }
    throw throwError(err);
  }

  addToFavorites(productId, userId): Observable<any> {
    let params = {
      productId: productId,
      productType: 'Internship',
      userId: userId,
    };
    return this.http.post('/api/v1.0/profiles/favorites/addtofavorites', params).pipe(
      map(r => {
        this.favoritesInternshipsChange.next(null);
        return r;
      }),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  deleteInternshipFromFavorites(productId: string): Observable<any> {
    return this.http.delete('/api/v1.0/profiles/favorites/delete?favoriteId=' + productId).pipe(
      map(r => {
        this.favoritesInternshipsChange.next(null);
        return r;
      }),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  addClassEnroll(internshipId: string): Observable<any> {
    return this.http.post('/b2c/v1.0/pupils/addclassenroll?classId=' + internshipId).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  removeClassEnroll(internshipId: string, userId: string, status: number = 2): Observable<any> {
    let credentials = {
      internshipId: internshipId,
      userId: userId,
      status: status,
      isDeleted: true,
    };
    return this.http.put('/b2c/v1.0/saas/updateclassenroll', credentials).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getClassEnrolls(): Observable<any> {
    return this.http.get('/b2c/v1.0/saas/getclassenrolls').pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getClassEnroll(userId: string, classId: string): Observable<any> {
    const params = `?userId=${userId}&classId=${classId}`;
    return this.http.get('/b2c/v1.0/pupils/getclassenroll' + params).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  sendAplyForInternship(applyToInternship: IApplyInternship): Observable<any> {
    const credentials = {
      userId: applyToInternship.userId,
      internship: applyToInternship.internship,
      regionId: applyToInternship.regionId,
      municipalityId: applyToInternship.municnipalityId,
      schoolId: applyToInternship.schoolId,
      schoolClass: applyToInternship.schoolClass,
    };
    return this.http.post('/b2c/v1.0/pupils/sendapplyinternship', credentials).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  addInternship(internship: any) {
    this.currentInternship.next(internship);
  }

  getCurrentInternship(): Observable<any> {
    return this.currentInternship.asObservable();
  }

  addFavoriteInternships(internships: any) {
    this.favoritesInternship.next(internships);
  }

  getFavoriteInternships(): Observable<any> {
    return this.favoritesInternship.asObservable();
  }

  getFavoritesInternshipsChange(): Observable<any> {
    return this.favoritesInternshipsChange.asObservable();
  }
}
