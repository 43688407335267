import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CourseService } from 'app/pages/catalog/courses-page-without-filters/course.service';
import * as moment from 'moment';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import {
  CourseDetailsService,
} from '../../../../../courses-page-without-filters/course-details/course-details.service';
import { CoursesService } from '../../../../courses.service';
import { COURSES_TYPES } from '../../add-course/add-course.component';
import { MetroColors } from '../../add-course/select-metro/metro-colors';
import { forkJoin as observableForkJoin } from 'rxjs/internal/observable/forkJoin';
import { SchoolAdminPanelService } from 'app/pages/control-panel/schooladmin/schooladmin-panel.service';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { FavoritesService } from 'app/pages/catalog/favorites/favorites.service';

@Component({
  selector: 'prf-event-course-card',
  templateUrl: './event-course-card.component.html',
  styleUrls: ['./event-course-card.component.scss'],
})
export class EventCourseCardComponent implements OnInit, OnDestroy {
  @Input() course: any;
  @Input() viewValue: any;
  @Input() image: any;
  @ViewChild('heart') heart: ElementRef;
  @ViewChild('heartActive') heartActive: ElementRef;
  types = COURSES_TYPES;
  currentType: any;
  isFavorite: boolean = false;
  isCourse: boolean;
  subscription: any;
  favoritesCourses: any;
  userId: string;
  userRole: string;
  accessAllow: boolean = false;
  metroColors: MetroColors = new MetroColors();
  popUpConfirming: boolean = false;
  startDate: string;
  moment: any;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private courseDetailsService: CourseDetailsService,
    private coursesService: CoursesService,
    private courseService: CourseService,
    private schoolAdminPanelService: SchoolAdminPanelService,
    private utilsService: UtilsService,
    private favoritesService: FavoritesService,
    private router: Router,
  ) {
    this.moment = moment;
    this.moment.locale('ru');
  }

  ngOnInit() {
    this.isCourse = this.course.classesFormat === 'ShortCourse' || this.course.classesFormat === 'LongCourse';
    this.currentType = this.getTypeCourse(this.course.classesFormat);
    this.userId = localStorage.getItem('userId');
    this.userRole = localStorage.getItem('userRole');
    this.accessAllow = this.userRole === 'admin';
    this.favoritesService
      .getFavoriteCourses()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(data => {
        this.favoritesCourses = data;
        if (this.favoritesCourses) {
          let productIds = this.favoritesCourses.filter(el => el.productId === this.course.id);
          this.isFavorite = productIds.length > 0;
        }
      });
    let startTime = this.moment(this.course.startDate).format('HH:mm');
    if (startTime == '00:00') {
      this.startDate = this.moment(this.course.startDate).format('D MMMM YYYY');
    } else {
      this.startDate = this.moment(this.course.startDate).format('D MMMM YYYY в HH:mm');
    }
  }

  calculateRealPrice() {
    return this.courseDetailsService.calculateRealPrice(this.course);
  }

  private getTypeCourse(value: string) {
    let type = this.types.filter(el => el.value === value);
    return type[0];
  }

  checkAction(event: any) {
    this.isCourse ? this.router.navigate(['/courses', this.course.hrid]) : this.router.navigate(['/events', this.course.hrid]);
  }

  deleteCourse(courseID) {
    let observables: Observable<any>[] = [];
    //mongo
    if (this.userRole == 'schooladmin') {
      observables.push(this.schoolAdminPanelService.deleteClassDO(courseID));
    } else {
      //admin
      observables.push(this.coursesService.removeCurrentClass(courseID));
    }
    //elastic
    observables.push(this.courseService.removeCurrentClass(courseID));
    observableForkJoin(observables)
      .pipe(
        catchError(err => {
          this.popUpConfirming = !this.popUpConfirming;
          this.utilsService.openSnackBar('👎 Ошибка на сервере, попробуйте позже', 'error');
          return throwError(err);
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(([delMongo, delElastic]) => {
        if (delMongo.status == 'Success') {
          this.utilsService.openSnackBar(`👌 Курс удален`, 'success');
        }
        this.popUpConfirming = !this.popUpConfirming;
        this.ngOnInit();
      });
  }

  public cutText(text: string, maxLen: number): string {
    if (text && text.length > maxLen) {
      text = text.substring(0, maxLen - 2) + '..';
    }
    return text;
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
