import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'app/shared/modules/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { PagesModule } from 'app/pages/pages.module';
import { SharedModule } from 'app/shared/shared.module';
import { SeamlessEntryRoutingModule } from './seamlessentry-routing.module';
import { SeamlessentryComponent } from './seamlessentry.component';
import { SeamlessentryService } from './seamlessentry.service';

@NgModule({
  declarations: [SeamlessentryComponent],
  imports: [CommonModule, MaterialModule, ReactiveFormsModule, PagesModule, SharedModule, SeamlessEntryRoutingModule],
  providers: [SeamlessentryService],
})
export class SeamlessEntryModule {}
