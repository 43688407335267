export const enum ApiProgramsUrls {
  GetProgramsSpoVpoBySpecialityCodes = '/api/v1.0/catalog/programsspovpo/getprogramsspovpobyspecialitycodes',

}

export const RegionIdsForNewPrograms =
  ["31057d28-722a-4103-b253-882a72081770",
    "18ab5c91-7d94-4033-84f0-7e3ff02bb1d5",
    "cae554a8-5341-4645-93c6-cdb7e09e2f69",
    "cf8208ee-e114-44ea-80db-bf146c1202bf"
    ]
