<prf-add-municipality [regions]="regions" (municipalityAdded)="municipalityAdded($event)">{{
  'SHARED.ADD_MUNICIPALITY' | translate
}}</prf-add-municipality>
<div>
  <h2>Список муниципалитетов</h2>
  <div class="utils-bar">
    <div class="chooseRegion">
      <div>
        <label for="region" class="login-label">Выберите регион:</label>
        <div class="div-space"></div>
      </div>
      <div class="dropdown-inline cat" #regionFilter>
        <div class="dropdown-inline-container w-dropdown">
          <div class="dd-input-field" #regionField (click)="regionDrop.classList.toggle('w--open'); regionField.classList.toggle('opened')">
            <div class="login-field w-input" [class.active]="selectedRegionName">
              {{ selectedRegionName }}
            </div>
          </div>
          <nav class="dd-prof-filter-list w-dropdown-list" #regionDrop>
            <div class="dd-prof-filter-div">
              <div class="filter-list-row">
                <div class="filter-list-heading">{{ 'SHARED.REGION' | translate }}</div>
              </div>
              <div class="prof-categories-links">
                <a
                  class="prof-categories-link w-inline-block"
                  *ngFor="let region of regions"
                  (click)="setRegionAsync(region); regionDrop.classList.remove('w--open'); regionField.classList.remove('opened')"
                >
                  <div class="category-text-link">{{ region.name }}</div>
                </a>
              </div>
              <!-- <div class="filter-list-reset" (click)="resetData(); regionDrop.classList.remove('w--open'); regionField.classList.remove('opened')">Весь список</div> -->
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>

  <table>
    <tr>
      <td>
        <b
          >Всего муниципалитетов: <span *ngIf="municipalitiesView">{{ municipalitiesView.length }}</span></b
        >
      </td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td><b>Название</b></td>
      <td>Hrid</td>
      <td>{{ 'SHARED.REGION' | translate }}</td>
      <td></td>
    </tr>
    <tr *ngFor="let municipalityView of municipalitiesView">
      <td>{{ municipalityView.name }}</td>
      <td>{{ municipalityView.hrid }}</td>
      <td>{{ municipalityView.regionName }}</td>
      <td>
        <prf-edit-municipality
          [municipalityView]="municipalityView"
          [regions]="regions"
          (municipalityChanged)="municipalityUpdate($event)"
        ></prf-edit-municipality>
        <prf-delete-municipality
          [municipalityId]="municipalityView.id"
          (municipalityDeleted)="municipalityDeleted($event)"
        ></prf-delete-municipality>
      </td>
    </tr>
  </table>
</div>
