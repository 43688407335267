import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'salary',
})
export class SalaryPipe implements PipeTransform {
  transform(money: number): any {
    let strMoney = money + '';
    return strMoney.replace(/(\d)(?=(\d{3})+$)/g, '$1 ');
  }
}
