import { IHttpStatus } from '../http';

export interface IGetValueResponse extends IHttpStatus {
  value: string;
}

export interface IGetAllDataResponse extends IHttpStatus {
  userStorage: IUserStorage;
}

export interface IUpdateValueRequest {
  userId: string;
  key: string;
  value: string;
}

export interface IUpdateValueResponse extends IHttpStatus {}

export interface IDeleteValueRequest {
  userId: string;
  key: string;
}

export interface IDeleteValueResponse extends IHttpStatus {}

export interface IDeleteAllDataRequest {
  userId: string;
}

export interface IDeleteAllDataResponse extends IHttpStatus {}

export interface IUserStorage {
  userId: string;
  storage: Record<string, string>;
}
