import { Component, HostListener, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';
import {
  ProfessionFilter,
} from '../../../../../shared/dashboard/professions/profession-filter/profession-filter.component';
import { ProfessionSortingEnum } from './profession-order/profession-order.component';
import { ProfessionsService } from '../../../../../shared/dashboard/professions/professions.service';
import { Subject } from 'rxjs';
import { ISortedItem } from 'app/shared/elements/dropdown-sorting/dropdown-sorting.component';
import { IProfession } from 'app/shared/interfaces/iprofession.interface';

import { EmptyGuid } from '@profilum-library';

const PAGE_SIZE: number = 15;
const TRIGGER_SCROLL_BOTTOM: number = 350;

@Component({
  selector: 'prf-profession-catalog',
  templateUrl: './profession-catalog.component.html',
  styleUrls: ['./profession-catalog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProfessionCatalogComponent implements OnInit {
  @Input() showRecommended: boolean = true;
  @Input() tabName: string;

  public categoryList: Array<ProfessionFilter> = [];
  public professionList: Array<IProfession> = [];
  public professionListFiltered: Array<IProfession> = [];
  public currentGroupElementsCount: number = PAGE_SIZE; // количество загруженных профессий в текущей группе
  public pageNumber: number = 0; // номер загруженной страницы
  public loadedAll: boolean = false;
  public loadedCategory: boolean = false;
  public loading: boolean = false;
  public DemandRatingAsc: boolean = false;
  public userRole: string = '';

  public professionListFilteredView: Array<IProfession> = [];
  private countElement: number = 0;

  public isSearchList: boolean = false;

  private filters: any = {};
  private order: ISortedItem;

  private demandRatingsByDesc = ['High', 'Medium', 'Low'];
  private demandRatingsByAsc = ['Low', 'Medium', 'High'];

  private maxDemandRating = 'High';
  private minDemandRating = 'Low';

  private nameSort: boolean = true;

  public defaultRegion: string = '00000000-0000-0000-0000-000000000000';
  public defaultMunicipality: string = '00000000-0000-0000-0000-000000000000';

  private ngUnsubscribe$ = new Subject<any>();

  constructor(private professionsService: ProfessionsService) {
    this.userRole = localStorage.getItem('userRole');
  }

  ngOnInit() {
    this.getCategoryList();
    this.setDefaultFilters();
    this.getProfessionCatalog();

    this.professionsService
      .getSortingProfession()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((sorting: ISortedItem) => {
        this.order = sorting;
        return this.getProfessionsByOrder(this.order);
      });
  }

  public loadMore() {
    this.loadedAll = this.countElement > this.professionListFiltered.length;
    if (this.loadedAll) {
      return;
    }
    for (let i = this.countElement; i < PAGE_SIZE + this.countElement; i++) {
      if (i < this.professionListFiltered.length) {
        this.professionListFilteredView.push(this.professionListFiltered[i]);
      }
    }
    this.countElement = this.countElement + PAGE_SIZE;
    return;
  }

  public sortProfessions() {}

  setDefaultFilters() {
    let regionIdValue: string;
    switch (this.userRole) {
      case 'tutor':
        regionIdValue = '00000000-0000-0000-0000-000000000000';
        Object.assign(this.filters, { RegionId: regionIdValue });
        break;
      default:
        const isPilot = localStorage.getItem('isPilot') == 'true' ? true : false;
        const regionId = localStorage.getItem('regionId');
        regionIdValue = regionId && regionId != 'null' && !isPilot ? regionId : '00000000-0000-0000-0000-000000000000';
        Object.assign(this.filters, { RegionId: regionIdValue });

        if (!isPilot) {
          const municipalityId = localStorage.getItem('municipalityId');
          const municipalityIdValue = municipalityId && municipalityId != 'null' ? municipalityId : null;
          Object.assign(this.filters, { MunicipalityId: municipalityIdValue });
        }
    }
  }

  // данные из поиска
  dataSearch(data: string) {
    if (data && data.length) {
      this.isSearchList = true;

      this.professionsService
        .getProfessionsQuery(data, localStorage.getItem('regionId') || EmptyGuid)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(professions => {
          if (this.userRole === 'tutor') {
            this.professionList = professions.filter(profession => profession.regionId === this.defaultRegion);
          } else {
            this.professionList = professions.filter(profession => profession.regionId === localStorage.getItem('regionId'));
          }
          this.getProfessionsByOrder(this.order);
        });
    } else {
      this.isSearchList = false;
      this.getProfessionCatalog(0, true);
    }
    return;
  }

  public getProfessionCatalog(selectedProfessionsCount?: number, reset?: boolean) {
    this.loading = true;
    this.loadedAll = false;
    if (reset) {
      this.professionList = [];
      this.professionListFiltered = [];
      this.professionListFilteredView = [];
      this.countElement = 0;
    }

    this.professionsService
      .acquireProfessions(this.filters)
      .pipe(take(1))
      .subscribe(professions => {
        professions.forEach(p => this.professionList.push(p));

        this.getProfessionsByOrder(this.order);
        this.loading = false;
        return;
      });
  }

  public getProfessionsByCategory(categoryItem: ProfessionFilter) {
    this.loadedAll = false;
    this.pageNumber = 1;
    this.currentGroupElementsCount = PAGE_SIZE;

    // нужно установить начальное значение после смены категории
    // this.filters.demandRating = this.DemandRatingAsc ? this.minDemandRating : this.maxDemandRating;

    if (categoryItem && categoryItem.name) {
      this.filters.Fields = categoryItem.name;
    } else {
      delete this.filters.Fields;
    }

    this.getProfessionCatalog(PAGE_SIZE, true);
  }

  public getProfessionsByOrder(order: ISortedItem) {
    const professionList = [...this.professionList];

    if (!order) {
      this.professionListFiltered = professionList;
    } else {
      switch (order?.key) {
        case ProfessionSortingEnum.NAME_DESC:
          const cyrillicNamesDesc: any[] = professionList
            .filter(prof => /[А-Яа-я]/.test(prof.name[0]))
            .sort((a, b) => a.name.localeCompare(b.name));
          const latinNamesDesc: any[] = professionList
            .filter(prof => /[A-Za-z]/.test(prof.name[0]))
            .sort((a, b) => a.name.localeCompare(b.name));
          const otherNamesDesc: any[] = professionList
            .filter(prof => /[^А-Яа-яA-Za-z]/.test(prof.name[0]))
            .sort((a, b) => a.name.localeCompare(b.name));
          this.professionListFiltered = [...cyrillicNamesDesc, ...latinNamesDesc, ...otherNamesDesc];
          break;
        case ProfessionSortingEnum.NAME_ASC:
          const cyrillicNamesAsc: any[] = professionList
            .filter(prof => /[А-Яа-я]/.test(prof.name[0]))
            .sort((a, b) => b.name.localeCompare(a.name));
          const latinNamesAsc: any[] = professionList
            .filter(prof => /[A-Za-z]/.test(prof.name[0]))
            .sort((a, b) => b.name.localeCompare(a.name));
          const otherNamesAsc: any[] = professionList
            .filter(prof => /[^А-Яа-яA-Za-z]/.test(prof.name[0]))
            .sort((a, b) => b.name.localeCompare(a.name));
          this.professionListFiltered = [...cyrillicNamesAsc, ...latinNamesAsc, ...otherNamesAsc];
          break;
        case ProfessionSortingEnum.RELEVANCE_DESC:
          this.professionListFiltered = professionList.sort((a, b) => {
            return b.demandRating - a.demandRating;
          });
          break;
        case ProfessionSortingEnum.RELEVANCE_ASC:
          this.professionListFiltered = professionList.sort((a, b) => {
            return a.demandRating - b.demandRating;
          });
          break;
        case ProfessionSortingEnum.SALARIES_DESC:
          this.professionListFiltered = professionList.sort((a, b) => {
            return b.averageSalary - a.averageSalary;
          });
          break;
        case ProfessionSortingEnum.SALARIES_ASC:
          this.professionListFiltered = professionList.sort((a, b) => {
            return a.averageSalary - b.averageSalary;
          });
          break;
        default:
          break;
      }
    }

    this.professionListFilteredView = [];
    this.countElement = 0;
    this.loadMore();
  }

  private getCategoryList() {
    this.professionsService
      .getFields()
      .pipe(take(1))
      .subscribe(fields => {
        fields.forEach(field => {
          this.categoryList.push({
            name: field.name,
            count: 0,
            selected: false,
          });
        });

        this.loadedCategory = true;
      });
  }

  private switchDemandRating(responseProfessionsLength: number, orderedDemandRatings: string[]) {
    const lastDemandRatingIndex = orderedDemandRatings.length - 1; // индекс последнего элемента
    if (this.filters.demandRating === orderedDemandRatings[lastDemandRatingIndex]) {
      this.loadedAll = true;
    } else {
      const nextDemandRating = this.getNextItem(orderedDemandRatings, this.filters.demandRating, lastDemandRatingIndex);
      if (nextDemandRating !== null) {
        this.getNextDemandProfessions(responseProfessionsLength, nextDemandRating);
      }
    }
  }

  // получить значение следующего элементаю Если элемент не найден, то вернуть null
  private getNextItem(orderedItems: string[], currentValue: string, lastItemIndex: number): string {
    let nextItem = null;
    for (let i = 0; i < lastItemIndex; i++) {
      if (currentValue === orderedItems[i]) {
        nextItem = orderedItems[i + 1];
        break;
      }
    }
    return nextItem;
  }

  private getNextDemandProfessions(responseProfessionsLength: number, nextDemandRating: string) {
    this.filters.demandRating = nextDemandRating;
    this.currentGroupElementsCount = 0; // обнуляем счетчик элементов при переходе к другому уровню востребованности

    // const loadedProfessionsCounter = this.professionList.length + responseProfessionsLength; // то, что было в массиве + количество элементов из последнего запроса
    const loadedProfessionsCounter = this.professionList.length + responseProfessionsLength; // то, что было в массиве + количество элементов из последнего запроса

    const requiredProfessionsCount = PAGE_SIZE * this.pageNumber; // необходимое количество профессий для заполнения всех текущих страниц
    if (loadedProfessionsCounter < requiredProfessionsCount) {
      this.currentGroupElementsCount = PAGE_SIZE - responseProfessionsLength; // подсчитываем количество элементов, необходимых для полного заполнения страницы
      this.getProfessionCatalog(this.currentGroupElementsCount);
    }
  }

  @HostListener('window:scroll', [])
  onScroll(): void {
    if (this.tabName == 'catalog' && window.innerHeight + window.scrollY >= document.body.offsetHeight - TRIGGER_SCROLL_BOTTOM) {
      if (!this.loading && !this.loadedAll && !this.isSearchList) {
        this.loadMore();
      }
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
