export enum ApiFavoritesUrls {
  GetFavorites = '/api/v1.0/profiles/favorites/getfavorites',
  AddFavorites = '/api/v1.0/profiles/favorites/addtofavorites',
  DeleteFavorites = '/api/v1.0/profiles/favorites/delete',
}

export enum ProductTypes {
  Consultation = 1,
  Class = 2,
  Profession = 3,
}
