<div class="dashboard-page" [formGroup]="form">
  <section formGroupName="regionReport">
    <div class="section-title">Обновить статистику на дашборде по региону</div>
    <div class="dropdown-container">
      <prf-dropdown-with-search
        formControlName="region"
        [label]="'SHARED.REGIONS' | translate"
        [options]="listedRegions"
      ></prf-dropdown-with-search>
    </div>
    <a
      class="button-animate btn-activate"
      [class.btn-waiting]="form.get('regionReport').get('region').status == 'DISABLED'"
      [class.disabled]="!form.get('regionReport').get('region').value"
      (click)="generateRegionReport()"
      ><span class="btn-icon"></span> <span class="btn-text" data-wait="Подождите" data-after="Сгенерировано">Сгенерировать</span></a
    >
    <div *ngIf="generatedRegionReportId" class="report-info">Сгенерированный отчет (id): {{ generatedRegionReportId }}</div>
  </section>
  <section formGroupName="municipalityReport">
    <div class="section-title">Обновить статистику на дашборде по муниципалитету</div>
    <div class="dropdown-container">
      <prf-dropdown-with-search
        formControlName="region"
        [label]="'SHARED.REGIONS' | translate"
        [options]="listedRegions"
      ></prf-dropdown-with-search>
    </div>
    <div class="dropdown-container">
      <prf-dropdown-with-search
        formControlName="municipality"
        [label]="'SHARED.MUNICIPALITIES' | translate"
        [options]="listedMunicipalities"
      ></prf-dropdown-with-search>
    </div>
    <a
      class="button-animate btn-activate"
      [class.btn-waiting]="form.get('municipalityReport').get('municipality').status == 'DISABLED'"
      [class.disabled]="!form.get('municipalityReport').get('municipality').value"
      (click)="generateMunicipalityReport()"
      ><span class="btn-icon"></span><span class="btn-text" data-wait="Подождите" data-after="Сгенерировано">Сгенерировать</span></a
    >
    <div *ngIf="generatedMunicipalityReportId" class="report-info">Сгенерированный отчет (id): {{ generatedMunicipalityReportId }}</div>
  </section>
</div>
