import { inject, Injectable } from '@angular/core';
import { HttpService } from '../http';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
  IAddUserRecommendationRequest,
  ICompletedTestsCount,
  ICreateParentSessionData,
  IDataSession,
  IDirectorPupilsObject,
  IExtendedPupil,
  IGetSchoolClassResponse,
  IGetUserIdsWithRecommendationRequest,
  ITeacherNameInterface,
} from './api-users.interface';
import { ApiUsersUrls } from './api-users.collections';
import { IClass, ISchool, ISchoolClass } from '../api-schools';
import { IStatusAnswer } from '../b2g-saas';
import { IUpdateRegistrationCode } from '../api-admins';

@Injectable({
  providedIn: 'root',
})
export class ApiUsersService {
  private readonly httpService: HttpService = inject(HttpService);

  // teachers

  public getTeacherNameBySchoolClassIdTeachers(schoolClassId: string): Observable<ITeacherNameInterface> {
    return this.httpService.get(ApiUsersUrls.GetTeacherName, { schoolClassId }).pipe(take(1));
  }

  public getSchoolClassesTeachers(): Observable<any> {
    return this.httpService.get(ApiUsersUrls.GetSchoolClassesTeachers).pipe(
      take(1),
      map((response: any) => response.schoolClasses),
    );
  }

  public getCompletedTestsCountTeachers(schoolId: string): Observable<ICompletedTestsCount[]> {
    return this.httpService.get<ICompletedTestsCount[]>(ApiUsersUrls.GetCompletesTestsCount, { schoolId }).pipe(take(1));
  }

  public getSchoolClassTeachers(schoolClassId: string): Observable<ISchoolClass> {
    return this.httpService.get<ISchoolClass>(ApiUsersUrls.GetSchoolClassTeachers, { schoolClassId }).pipe(take(1));
  }

  public getExtendedPupilsTeachers(schoolClassId?: string): Observable<any> {
    return this.httpService.get(ApiUsersUrls.GetExtendedPupilsTeachers, { schoolClassId }).pipe(take(1));
  }

  public getPupilsTeachers(schoolClassId: any): Observable<IExtendedPupil[]> {
    return this.httpService.get<IExtendedPupil[]>(ApiUsersUrls.GetPupilsByClassTeachers, { schoolClassId }).pipe(take(1));
  }
  public getPupilsByTeacher(): Observable<any> {
    return this.httpService.get(ApiUsersUrls.GetPupilsByTeacher).pipe(take(1));
  }

  public getSchoolTeachers(): Observable<any> {
    return this.httpService.get(ApiUsersUrls.GetSchoolTeachers).pipe(take(1));
  }

  public removePupilTeachers(userId: any): Observable<any> {
    return this.httpService.delete(ApiUsersUrls.RemoveFromSchoolClass, { userId }).pipe(take(1));
  }

  public removeUnregisteredPupilTeachers(userId: string): Observable<any> {
    return this.httpService.delete(ApiUsersUrls.DeleteRegistrationCode, { userId }).pipe(take(1));
  }

  public getDataUnregisteredTeachers(schoolClassId: string): Observable<any> {
    return this.httpService.get(ApiUsersUrls.UnloadInactivePupil, { schoolClassId }).pipe(take(1));
  }

  public addUserRecommendationTeachers(
    userId: string,
    productId: string,
    recommendationType: string,
    addresserRole: string,
  ): Observable<any> {
    return this.httpService
      .post(ApiUsersUrls.AddUserRecommendationTeachers, { userId, productId, recommendationType, addresserRole })
      .pipe(take(1));
  }

  public getUserIdsWithRecommendationTeachers(productId: string, recommendationType: string): Observable<any> {
    return this.httpService.get(ApiUsersUrls.GetUserWithRecommendation, { productId, recommendationType }).pipe(take(1));
  }

  public updateRegistrationCodeTeachers(pupilToUpdate: IUpdateRegistrationCode): Observable<IStatusAnswer> {
    return this.httpService.post(ApiUsersUrls.UpdateRegistrationCodeTeachers, pupilToUpdate).pipe(take(1));
  }

  public getPupilsByTutorTeachers() {
    return this.httpService.get(ApiUsersUrls.GetPupilsByTutor).pipe(map((r: any) => r.pupils));
  }

  // directors

  public getSchoolDirectors(): Observable<ISchool> {
    return this.httpService.get(ApiUsersUrls.GetSchoolDirectors).pipe(take(1));
  }

  public getSchoolClassDirectors(schoolClassId: string) {
    return this.httpService.get(ApiUsersUrls.GetSchoolClassDirectors, { schoolClassId }).pipe(take(1));
  }

  public getSchoolClassesDirectors(): Observable<any> {
    return this.httpService.get(ApiUsersUrls.GetSchoolClassesDirectors).pipe(take(1));
  }

  public getTeacherDirectors(schoolClassId: string): Observable<any> {
    return this.httpService.get(ApiUsersUrls.GetTeacher, { schoolClassId }).pipe(take(1));
  }

  public getExtendedPupilsDirectors(schoolClassId: string): Observable<IExtendedPupil[]> {
    return this.httpService.get<IExtendedPupil[]>(ApiUsersUrls.GetExtendedPupilsDirectors, { schoolClassId }).pipe(take(1));
  }

  public getPupilsDirectors(schoolClassId: string): Observable<any[]> {
    return this.httpService.get<any[]>(ApiUsersUrls.GetPupilsByClassDirectors, { schoolClassId }).pipe(take(1));
  }

  public getSchoolStatisticDirectors(schoolId: string): Observable<any> {
    return this.httpService.get(ApiUsersUrls.GetSchoolStatistic, { schoolId }).pipe(take(1));
  }

  public getDistributionDirectors(dataObject): Observable<IDirectorPupilsObject> {
    return this.httpService.post<IDirectorPupilsObject>(ApiUsersUrls.GetPupilsByFilter, dataObject).pipe(take(1));
  }

  public sendResultsMessagesDirectors(): Observable<any> {
    return this.httpService.post(ApiUsersUrls.SendResultsToParents).pipe(take(1));
  }

  public getSchoolClassesExtendedDirectors(schoolId): Observable<any> {
    return this.httpService.get(ApiUsersUrls.GetSchoolClassesWithCounts, { schoolId }).pipe(take(1));
  }

  public getTeachersDirectors(schoolId: string): Observable<any> {
    return this.httpService.get(ApiUsersUrls.GetTeachers, { schoolId }).pipe(take(1));
  }

  public getCompletedTestsCountDirectors(schoolId: string): Observable<any> {
    return this.httpService.get(ApiUsersUrls.GetCompletedTestsCountByClass, { schoolId }).pipe(take(1));
  }

  public getPupilsByResultsFiltersExcelDirectors(requestOptions: any): Observable<any> {
    return this.httpService.postFile(ApiUsersUrls.GetPupilsDistributionExcel, requestOptions).pipe(take(1));
  }

  createClassDODirectors(classesDto: IClass): Observable<any> {
    return this.httpService.post(ApiUsersUrls.CreateClass, classesDto).pipe(take(1));
  }

  replaceClassDODirectors(classesDto: IClass): Observable<any> {
    return this.httpService.put(ApiUsersUrls.ReplaceClass, classesDto).pipe(take(1));
  }

  deleteClassDODirectors(classId: any): Observable<any> {
    return this.httpService.delete(ApiUsersUrls.DeleteClass, { classId }).pipe(take(1));
  }

  // parents

  public createSessionParents(requestData: any): Observable<ICreateParentSessionData> {
    return this.httpService.post<ICreateParentSessionData>(ApiUsersUrls.CreateParentSession, requestData).pipe(take(1));
  }

  public addUserRecommendationParents(params: IAddUserRecommendationRequest): Observable<any> {
    return this.httpService.post<any>(ApiUsersUrls.AddUserRecommendationParents, params).pipe(take(1));
  }

  public getUserIdsWithRecommendationParents(params: IGetUserIdsWithRecommendationRequest): Observable<any> {
    return this.httpService.get<any>(ApiUsersUrls.GetUserIdsWithRecommendation, params).pipe(take(1));
  }

  public getUserInfoByCodeParents(registrationCode: string): Observable<any> {
    return this.httpService.get(ApiUsersUrls.GetUserInfoByCode, { registrationCode }).pipe(take(1));
  }

  public updateRegistrationCodeParents(pupilToUpdate: IUpdateRegistrationCode): Observable<string> {
    return this.httpService.post<string>(ApiUsersUrls.UpdateRegistrationCodeParents, pupilToUpdate).pipe(take(1));
  }

  // pupils

  public createSessionPupils(postData: any): Observable<any> {
    return this.httpService.post(ApiUsersUrls.CreateSessionPupils, postData).pipe(take(1));
  }

  public getSchoolClassPupils(): Observable<IGetSchoolClassResponse> {
    return this.httpService.get<IGetSchoolClassResponse>(ApiUsersUrls.GetSchoolClassPupils).pipe(take(1));
  }

  //TODO нужно удалить и оставить только createSession
  public createTestSessionPupils(userData: IDataSession): Observable<any> {
    return this.httpService.post(ApiUsersUrls.CreateSessionPupils, userData).pipe(take(1));
  }

  public softSchoolClassChangePupils(userId: string, schoolId: string, classNumber: number, classLetter: string): Observable<any> {
    return this.httpService.post(ApiUsersUrls.SoftSchoolClassChangePupils, { userId, schoolId, classNumber, classLetter }).pipe(take(1));
  }

  public addClassEnrollPupils(classId): Observable<any> {
    return this.httpService.post(ApiUsersUrls.AddClassEnrollPupils, classId ).pipe(take(1));
  }

  public removeClassEnrollPupils(classId: string, userId: string, status: number = 2): Observable<any> {
    return this.httpService.put(ApiUsersUrls.UpdateClassEnrollPupils, { classId, userId, status, isDeleted: true }).pipe(take(1));
  }

  public getClassEnrollPupils(userId: string, classId: string): Observable<any> {
    return this.httpService.get(ApiUsersUrls.GetClassEnrollPupils, { userId, classId }).pipe(take(1));
  }

  public getRecommendationProgramPupils(): Observable<any> {
    return this.httpService.get(ApiUsersUrls.GetRecommendationProgramPupils, { countSpecialities: 10 }).pipe(take(1));
  }

  public sendApplyForInternshipPupils(
    userId: string,
    internship: string,
    regionId: string,
    municipalityId: string,
    schoolId: string,
    schoolClass: string,
  ): Observable<any> {
    return this.httpService
      .post(ApiUsersUrls.SendApplyInternshipPupils, { userId, internship, regionId, municipalityId, schoolId, schoolClass })
      .pipe(take(1));
  }

  public getCurrentStepPupils(): Observable<any> {
    return this.httpService.post(ApiUsersUrls.GetCurrentStepPupils).pipe(take(1));
  }

  public updateCurrentStepPupils(currentStep: string): Observable<any> {
    // TODO в POST методе параметр уходит в url???
    return this.httpService.post(ApiUsersUrls.GetCurrentStepPupils + '?currentStep=' + currentStep).pipe(take(1));
  }

  public updateCurrentStep2Pupils(currentStep: string): Observable<any> {
    // TODO в POST методе параметр уходит в url??? + 2 разных url которые кажется делают одно и  то же
    return this.httpService.post('/saas/v1.0/pupils/updatecurrentstep?currentStep=' + currentStep).pipe(take(1));
  }

  public addUserTrajectoryPupils(userId: string, productId: string, trajectoryType: string, professionId: string): Observable<any> {
    return this.httpService.post(ApiUsersUrls.AddUserTrajectoryPupils, { userId, productId, professionId, trajectoryType }).pipe(take(1));
  }
}
