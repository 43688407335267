import { IHttpStatus } from '../http/http.interface';

export interface IGeneratedRegionReportByEmailRequest {
  regionId: string;
  userId: string;
  role: string;
  dateFrom: string;
  dateTo: string;
  isMongoSessions?: boolean;
  isAllVariants?: boolean;
  maxCount?: number;
  variantId?: string;
  municipalityId?: string;
  language?: string;
}

export interface IGeneratedRegionReportByEmailResponse extends IHttpStatus {
  requestReportId: string;
}

export interface IChoosesProfessionsReport {
  id: string;
  createDate: string;
  schoolClassId: string;
  data: string;
  usersChoosesProfessionsCount?: number;
}
