<ng-template [ngIf]="screenSections">
  <div *ngFor="let screenSection of screenSections; trackBy: trackBySectionId" class="prf-rectangle-view-with-sections">
    <div (click)="toggleSection(screenSection)" class="prf-rectangle-view-with-sections-item prf-rectangle-view-with-sections-item-theme">
      <p>{{ screenSection.name }}</p>
    </div>
    <div [class.hidden]="!screenSection.isOpened" class="prf-rectangle-view-with-sections-section">
      <prf-rectangle-view
        *ngFor="let answer of screenSection.fields; trackBy: trackByAnswerId"
        [answerInfo]="answer"
        [sectionInfo]="sectionInfo"
        (selectedAnswer)="updateSelectedAnswer($event)"
        class="prf-rectangle-view-with-sections-section-item"
      >
      </prf-rectangle-view>
    </div>
  </div>
</ng-template>
