import { Component, EventEmitter, Output } from '@angular/core';
import { ISortedItem } from 'app/shared/elements/dropdown-sorting/dropdown-sorting.component';

// export class ProfessionOrder {
//   key: string;
//   name: string;
// }

export enum ProfessionSortingEnum {
  NAME_DESC = 'name_desc',
  NAME_ASC = 'name_asc',
  RELEVANCE_DESC = 'relevance_desc',
  RELEVANCE_ASC = 'relevance_asc',
  SALARIES_DESC = 'salaries_desc',
  SALARIES_ASC = 'salaries_asc',
}

@Component({
  selector: 'prf-profession-order',
  templateUrl: './profession-order.component.html',
  styleUrls: ['./profession-order.component.scss'],
})
export class ProfessionOrderComponent {
  @Output() selectedOrder = new EventEmitter<ISortedItem>();

  public nameList: Array<ISortedItem> = [
    { key: ProfessionSortingEnum.NAME_DESC, name: 'По названию А-Я, A-Z' },
    { key: ProfessionSortingEnum.NAME_ASC, name: 'По названию Я-А, Z-A' },
  ];
  public relevanceList: Array<ISortedItem> = [
    { key: ProfessionSortingEnum.RELEVANCE_DESC, name: 'По востребованности (больше - меньше)' },
    { key: ProfessionSortingEnum.RELEVANCE_ASC, name: 'По востребованности (меньше - больше)' },
  ];
  public salariesList: Array<ISortedItem> = [
    { key: ProfessionSortingEnum.SALARIES_DESC, name: 'По зарплате (больше - меньше)' },
    { key: ProfessionSortingEnum.SALARIES_ASC, name: 'По зарплате (меньше - больше)' },
  ];
}
