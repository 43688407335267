import { IStatusAnswer } from '../b2g-saas';
import { IFavoriteProfessionInterface } from '../api-favorites';
import { DistributionTypes } from '../../enums';

export interface ITeacherNameInterface extends IStatusAnswer {
  lastName?: string;
  firstName?: string;
  middleName?: string;
}

export interface ICompletedTestsCount {
  schoolClassId: string;
  count: number;
}

export interface IExtendedPupil {
  favoriteProfessions?: IFavoriteProfessionInterface[];
  lastSessionId?: string;
  lastTestDate?: string;
  lastSessionCompleted?: boolean;
  lastParentSessionId?: string;
  lastParentSessionCompleted?: boolean;
  pupilId: number;
  firstName: string;
  lastName: string;
  middleName?: string;
  userId: string;
  userHrid: number;
  isActivated: boolean;
  isTested: boolean;
  registrationCode: string;
  schoolClassId: string;
  imagePath?: string;
  email: string;
  phoneNumber?: string;
  gender: string;
  birthday: string;
  registeredByInstitutionId?: string;
  isDeleted?: boolean;
  createdDate?: string;
  passwordUpdatedDate?: Date;
}

export interface PupilsByResultsObject {
  schoolId?: string;
  talent?: string;
  profilClass?: string;
  level?: string;
  direction?: string;
  wantedDO?: string;
  gender?: string;
  class?: string;
}
export interface IDirectorPupilsObject {
  comment: string;
  pupilsInfo: IDirectorPupil[];
  senderService: string;
  status: string;
}

export interface PupilsDistributionObject {
  schoolId?: string;
  distributionType?: DistributionTypes;
  gender?: string;
  class?: string;
}

export interface IDirectorPupil {
  favoriteProfessions: IFavoriteProfessionInterface[];
  lastSessionId: string;
  lastSessionCompleted: boolean;
  lastParentSessionId: string;
  lastParentSessionCompleted: boolean;
  userId: string;
  fullName: string;
  class: string;
}

export interface ICreateParentSessionData {
  sessionId: string;
  status: string;
  comment: string;
  senderService: string;
}

export interface IAddUserRecommendationRequest {
  UserId: string;
  ProductId: string;
  RecommendationType: string;
  AddresserRole: string;
}

export interface IGetUserIdsWithRecommendationRequest {
  productId: string;
  recommendationType: string;
}

export interface IGetSchoolClassResponse {
  schoolClasses: any;
  id?: any;
  teacherId?: string;
  letter: string;
  number: string;
  schoolId?: string;
  className?: string;
  pupilsCount?: number;
  completedTestsCount?: number;
  totalPupilsCount?: number;
  parentRegistrationLink?: string;
  shortLink?: string;
  isTestedPupils?: number;
}

export interface IDataSession {
  userId?: string;
  referralUserId?: string;
  screeningTestId: string;
  language?: string;
  data?: any;
  variandId?: string;
}
