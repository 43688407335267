<div *ngIf="sectionInfo" class="prf-image-box-container">
  <div class="prf-image-box">
      <prf-rectangle-image-view
        *ngFor="let answer of sectionInfo.elements; trackBy: trackByFn"
        [answerInfo]="answer"
        [sectionInfo]="sectionInfo"
        (selectedAnswer)="updateSelectedAnswer($event)"
        [updateSelections]="updateSelectionsSubject"
        class="prf-image-box-item"
        [class.prf-image-box-item--more2]="sectionInfo.elements.length > 2"
        [class.prf-image-box-item--more7]="sectionInfo.elements.length > 7"
      ></prf-rectangle-image-view>
  </div>
</div>
