import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { IStorageHelper, StorageHelper } from '../../helpers';
import { StorageKeys, Storages, UtilsService } from '../../logic-services';
import { HttpService } from '../../api-services';
import { EUserTags } from '../../enums';

export interface IBvbLoginData {
  role: string;
  userId: string;
  tag: string;
  isUnRegistered: boolean;
  succeeded: boolean;
  isLockedOut: boolean;
  isNotAllowed: boolean;
  requiresTwoFactor: boolean;
}

@Injectable()
export class BvbGuard {
  private loginFailMessage: string = 'Ошибка аутентификации';
  private storageHelper: IStorageHelper = new StorageHelper(Storages.Local);

  constructor(private router: Router, private httpService: HttpService, private utilsService: UtilsService) {}

  public async canActivate(): Promise<boolean> {
    try {
      const tagFromStorage: string = this.storageHelper.get(StorageKeys.Tag);
      const { succeeded }: IBvbLoginData = await this.login().toPromise();
      const tagIsCorrectAndLoginSucceeded: boolean = tagFromStorage === EUserTags[EUserTags.Bvb].toString() || succeeded;

      if (!tagIsCorrectAndLoginSucceeded) {
        throw new Error('Некорректный тег или ошибка логина!');
      }

      return tagIsCorrectAndLoginSucceeded;
    } catch (e) {
      this.utilsService.openSnackBar(this.loginFailMessage, 'error');
      this.router.navigate(['']);

      return false;
    }
  }

  public login(): Observable<IBvbLoginData | null> {
    const userId: string = this.storageHelper.get(StorageKeys.UserId);
    const email: string = this.storageHelper.get(StorageKeys.Email);

    if (userId && email) {
      return this.httpService.post<IBvbLoginData>('/bffproject/v1.0/bvb/login', { userId, email });
    }

    throw new Error('userId и/или email не найдены');
  }
}
