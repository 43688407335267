import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'app/shared/shared.service';
import { UserInfoClass } from 'app/shared/classes/userInfo.class';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { FilterByNamePipe } from 'app/shared/pipes/filter-by-name.pipe';
import { FilterSchoolsPipe } from 'app/shared/pipes/filter-schools.pipe';
import { AppSettingsService } from 'app/shared/services/appsettings.service';
import * as moment from 'moment';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { AdminPanelService } from '../../admin/admin-panel.service';
import { RegionsService } from '../../admin/regions/regions.service';
import { ChildrenAddService } from '../../parent/children-add/children-add.service';
import { Observable, Subject } from 'rxjs';
import { SchoolClassesClass } from 'app/shared/classes/school-classes.class';
import { ICity } from 'app/shared/interfaces/icity';
import { REG_EXP } from 'app/shared/global-constants/reg-exp';
import { RegistrationService } from 'app/landing/b2c/registration-b2c/registration.service';
import { SCHOOL_NUMBERS } from 'app/shared/global-constants/school-numbers';

@Component({
  selector: 'prf-update-user-info',
  templateUrl: './update-user-info.component.html',
  styleUrls: ['./update-user-info.component.scss'],
})
export class UpdateUserInfoComponent implements OnInit {
  public userData: UserInfoClass;
  public personalTerms: boolean = true;
  dataFetched: boolean = false;
  role = 'pupil';

  tag: string;
  public form: UntypedFormGroup;
  public submitted: boolean;
  public readonly dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public readonly emailRegExp: RegExp = REG_EXP.emailRegExp;
  public readonly dateRegExp: RegExp = REG_EXP.dateRegExp;
  public readonly testOneLetter: RegExp = REG_EXP.testOneLetter;
  public readonly testOneDigit: RegExp = REG_EXP.testOneDigit;
  public readonly testSixCharter: RegExp = REG_EXP.testSixCharter;
  public readonly testWhiteSpace: RegExp = REG_EXP.testWhiteSpace;
  public readonly testRusLetters: RegExp = REG_EXP.testRusLetters;
  public readonly phoneRegExp: RegExp = REG_EXP.phoneRegExp;

  public regions = [];
  public cities: ICity[] = [];
  private _citiesView;
  public classes: SchoolClassesClass[] = [];
  public classesFetched: boolean = false;
  public schools: any[] = [];
  private _schoolView: any[] = [];
  public numbersClass: any = [];
  public lettersClass: any = [];

  public moment: any = moment;
  public buttonWaiting: boolean = false;
  public buttonActivated: boolean = false;
  public buttonActivate: boolean = false;
  public duplicateUserName: boolean = false;
  public checkEmail: boolean = true;
  public checkBirthday: boolean = false;
  public isClasses: boolean = false;

  public isNotEnoughYears: boolean = false; // признак для определения разрешенного возраста
  private minAge: number = 14; // минимальный разрешенный возраст для регистрации ученика
  public SCHOOL_LETTERS: { value: string }[] = [];
  public defaultRegion: string = null;
  protected ngUnsubscribe$: Subject<any> = new Subject<any>();
  constructor(
    private registrationService: RegistrationService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private childrenAddService: ChildrenAddService,
    private filterSchoolsPipe: FilterSchoolsPipe,
    private filterByNamePipe: FilterByNamePipe,
    private translateService: TranslateService,
    private adminPanelService: AdminPanelService,
    private appSettingsService: AppSettingsService,
    private sharedService: SharedService,
    private regionsService: RegionsService,
    private utilsService: UtilsService
  ) {
    this.moment = moment;
    this.moment.locale('ru');
  }

  ngOnInit() {
    this.defaultRegion = AppSettingsService.settings.regionId;
    // await super.ngOnInit();

    this.getData().pipe(
      switchMap(_ => {
        return this.getUserData().pipe(
          tap(_ => {
            this.setUserDataValues();
            this.dataFetched = true;
          }),
        );
      }),
    );
  }

  getData(): Observable<any> {
    return this.getRegions().pipe(switchMap(_ => this.getCities().pipe(switchMap(_ => this.getSchools()))));
  }

  public updateUserData() {
    this.submitted = true;
    if (this.form.valid) {
      let school = this.schools.find(school => school.id === this.userData.schoolId);
      let city = this.cities.find(city => city.name === this.userData.city);
      let schoolId = school ? school.id : this.userData.schoolId;

      let classLetter, classNumber;

      if (this.f.schoolClass.value == null) {
        classNumber = this.f.schoolClassNumber.value.name;
        classLetter = this.f.schoolClassLetter.value.name;
      } else {
        let classLetterIndex = this.f.schoolClass.value && this.f.schoolClass.value.name.match(/[a-zA-Zа-яА-Я]/).index;
        classLetter = classLetterIndex && this.f.schoolClass.value.name[classLetterIndex];
        classNumber = this.f.schoolClass.value && parseInt(this.f.schoolClass.value.name);
      }

      this.registrationService
        .softSchoolClassChange(this.userData.userId, schoolId, classNumber, classLetter)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(response => {
          if (response.status == 'Success') {
            this.utilsService.openSnackBar('👌 Изменения успешно сохранены', 'success');
            this.router.navigateByUrl('/pupil');
            return;
          } else {
            this.utilsService.openSnackBar('👎 Ошибка на сервере, попробуйте позже', 'error');
            this.failWaiting();
          }
        });
    } else {
      this.failWaiting();
    }
  }

  animateLogin() {
    this.buttonActivate = true;
    this.buttonWaiting = true;
    this.updateUserData();
  }

  private getUserData(): Observable<any> {
    return this.sharedService.getUserInfoData().pipe(
      tap((ui: UserInfoClass) => {
        this.userData = ui;
      }),
    );
  }

  private setUserDataValues(): void {
    // check the  equation!
    const isValidDate = this.userData.birthday && this.userData.birthday !== moment('0001-01-01T00:00:00'); // в таком формте приходит отсутствие даты

    //  userData.birthday = moment(userData.birthday, 'DD/MM/YYYY', true).format('YYYY-MM-DD');

    const birthday = isValidDate ? moment(this.userData.birthday).format('DD/MM/YYYY') : null;

    let region = this.regions.find(region => region.id === this.userData.regionId);
    let school = this.schools.find(school => school.id === this.userData.schoolId);
    let city = this.cities.find(city => city.name === this.userData.city);

    // запрос класов по школе
    if (school && school.id) {
      this.getSchoolClassBySchool(school.id)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(r => {
          this.f.schoolClass.reset();
          this.f.schoolClassNumber.reset();
          this.f.schoolClassLetter.reset();
        });
    }

    this.form = this.fb.group({
      lastName: new UntypedFormControl({ value: this.userData.lastName, disabled: true }, [Validators.required]),
      firstName: new UntypedFormControl({ value: this.userData.firstName, disabled: true }, [Validators.required]),
      middleName: new UntypedFormControl({ value: this.userData.middleName, disabled: true }),
      date: new UntypedFormControl({ value: isValidDate ? birthday : null, disabled: isValidDate }, [
        Validators.required,
        Validators.pattern(this.dateRegExp),
      ]),
      email: new UntypedFormControl({ value: this.userData.email, disabled: this.userData.email ? true : false }, [
        Validators.required,
        Validators.pattern(this.emailRegExp),
      ]),
      role: new UntypedFormControl('pupil', [Validators.required]),
      region: new UntypedFormControl({ value: region ? { name: region.name, data: region } : null, disabled: region ? true : false }, [
        Validators.required,
      ]),
      city: new UntypedFormControl({
        value: city ? { name: city.name, data: city } : null,
        disabled: city ? true : false,
      }),
      school: new UntypedFormControl(
        {
          value: school ? { name: school.viewValue, data: school } : null,
          disabled: school ? true : false,
        },
        [Validators.required],
      ),
      schoolClass: new UntypedFormControl(null),
      schoolClassNumber: new UntypedFormControl(null),
      schoolClassLetter: new UntypedFormControl(null),
      gender: new UntypedFormControl(
        {
          value: this.userData.gender && ['M', 'F'].indexOf(this.userData.gender) > -1 ? this.userData.gender : null,
          disabled: this.userData.gender && ['M', 'F'].indexOf(this.userData.gender) > -1 ? true : false,
        },
        [Validators.required],
      ),
    });

    //нужно, чтобы подтянулись города по муниципалитету
    this.f.region.value && !this.f.city.value ? this.onRegionChange() : null;

    // нужно, чтобы подтянулись школы по городу
    this.f.city.value && !this.f.school.value ? this.onCityChange() : null;

    // вызываем проверку возраста
    this.checkFormatDate(this.f.date.value);
  }

  onRegionChange() {
    this.f.city.reset();
    this.f.city.disabled ? this.f.city.enable() : null;
    this.onCityChange();
    if (this.f.region.value && this.f.region.value.data.id) {
      this.citiesView = this.f.region.value.data.id;
    }
  }
  onCityChange() {
    this.f.school.reset();
    this.f.school.disabled ? this.f.school.enable() : null;
    this.onSchoolChange();
    if (this.f.city.value && this.f.city.value.data.name) {
      this.schoolView = this.f.city.value.data.name;
    }
  }
  onSchoolChange() {
    if (this.f.school.value && this.f.school.value.data.id) {
      this.getSchoolClassBySchool(this.f.school.value.data.id)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(r => {
          this.f.schoolClass.reset();
          this.f.schoolClassNumber.reset();
          this.f.schoolClassNumber.reset();
        });
    }
  }

  get isAccessAllowed() {
    return this.form.valid && this.isClassSelected && !this.isNotEnoughYears;
  }

  getDocsRoute(): string {
    return AppSettingsService.settings.docsPath.docsPathRegion;
  }

  getSchoolClassBySchool(id): Observable<any> {
    return this.registrationService.getSchoolClassesBySchool(id).pipe(
      tap((classes: SchoolClassesClass[]) => {
        this.classes = classes;
        this.classes && this.classes.length ? (this.isClasses = true) : null;
        this.classesFetched = true;
      }),
    );
  }

  getRegions(): Observable<any> {
    return this.regionsService.getAllRegions().pipe(
      tap(regions => {
        this.regions = regions.filter(region => region.hrid != 'defaultRegion');
      }),
    );
  }

  getCities(): Observable<any> {
    return this.adminPanelService.getAllCities().pipe(tap(cities => (this.cities = cities)));
  }

  getSchools(): Observable<any> {
    return this.childrenAddService.getCatalogSchools().pipe(
      tap((schools: any[]) => {
        this.schools = [...schools].map(school => ({
          city: school.city,
          value: school.number,
          viewValue: school.number,
          id: school.id,
        }));
      }),
    );
  }

  get f() {
    return this.form.controls;
  }

  get regionsView() {
    return [...this.regions].map(region => ({ name: region.name, data: region }));
  }

  set citiesView(regionId) {
    this._citiesView = regionId
      ? [...this.cities].filter(city => city.regionId === regionId).map(city => ({ name: city.name, data: city }))
      : [];
  }
  get citiesView() {
    return this._citiesView;
  }

  set schoolView(cityName) {
    this._schoolView = cityName
      ? [...this.schools].filter(school => school.city === cityName).map(school => ({ name: school.viewValue, data: school }))
      : [];
  }
  get schoolView() {
    return this._schoolView;
  }

  get schoolClassView() {
    return [...this.classes].map(schoolClass => ({
      name: schoolClass.number + schoolClass.letter,
      data: schoolClass,
    }));
  }
  get schoolClassNumber() {
    return [...SCHOOL_NUMBERS].map(number => ({ name: number.value, data: number }));
  }
  get schoolClassLetter() {
    return [...this.appSettingsService.getByDefaultLocale('SCHOOL_LETTERS')].map(letter => ({
      name: letter.value,
      data: letter,
    }));
  }
  get isClassSelected(): boolean {
    return (this.f.schoolClassLetter.value && this.f.schoolClassNumber.value) || this.f.schoolClass.value;
  }
  get currentClassLetter() {
    return this.f.schoolClassLetter.value ? this.f.schoolClassLetter.value.name : this.f.schoolClass.value.data.letter;
  }
  get currentClassNumber() {
    return this.f.schoolClassNumber.value ? this.f.schoolClassNumber.value.name : this.f.schoolClass.value.data.number;
  }

  public checkBirthdayDate(isCorrectDate: boolean) {
    let date = moment();
    let userDate = moment(this.f.date.value, 'DD/MM/YYYY', true); // порядок определен форматом даты на фронте
    let diff = moment.duration(date.diff(userDate)).asYears();
    this.isNotEnoughYears = diff < this.minAge;
  }
  public removeWaiting() {
    this.buttonWaiting = false;
    this.buttonActivated = true;
  }
  public failWaiting() {
    this.buttonWaiting = false;
    this.buttonActivate = false;
  }

  public checkFormatEmail(event): void {
    if (event) {
      this.checkEmail = this.emailRegExp.test(this.f.email.value);
    }
  }
  public checkFormatDate(event): void {
    if (event) {
      this.checkBirthdayDate(true);
      this.checkBirthday = true;
    }
  }
  public tooggleSelectClassType() {
    this.isClasses = !this.isClasses;
    this.f.schoolClass.reset();
    this.f.schoolClassNumber.reset();
    this.f.schoolClassLetter.reset();
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
