import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  signal,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { filter, retry, switchMap, take, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import {
  IPlayerOptions,
  IPreparedAnswer,
  ISectionInfo,
  ISlideImage,
  IUserAnswerLocalCache,
} from '@profilum-interfaces/prf-tests.interface';
import {
  IAnswerInfo,
  IGetCurrentSlideResponse,
  IMoveToNextSlideResponse,
  ISlideBubble,
  ISlideRectangle,
  ISlideSection,
} from '@profilum-api-services/api-slides/api-slides.interface';
import { ApiSlidesService } from '@profilum-api-services/api-slides/api-slides.service';
import { ApiTestsService } from '@profilum-api-services/api-tests/api-tests.service';
import { ApiUserAnswersService } from '@profilum-api-services/api-user-answers/api-user-answers.service';
import { ApiUtilsService } from '@profilum-api-services/api-utils/api-utils.service';
import {
  AnswerType,
  ETestTypesSteps,
  RectanglesQuestion,
  SlideType,
} from '@profilum-collections/prf-section.collections';
import { QuestionTypes } from '@profilum-collections/prf-tests.collections';
import {
  LoadDynamicComponentsComponent,
} from '@profilum-components/load-dynamic-components/load-dynamic-components.component';
import {
  IDynamicComponentInputData,
} from '@profilum-components/load-dynamic-components/load-dynamic-components.interface';
import {
  BubbleBoxWithImgComponent,
} from '@profilum-components/prf-tests/bubble-boxes/bubble-box-with-img/bubble-box-with-img.component';
import { BubbleBoxComponent } from '@profilum-components/prf-tests/bubble-boxes/bubble-box/bubble-box.component';
import {
  OpenQuestionComponent,
} from '@profilum-components/prf-tests/open-questions/open-question/open-question.component';
import { RangeBoxComponent } from '@profilum-components/prf-tests/range-boxes/range-box/range-box.component';
import {
  GroupedRectangleViewBoxComponent,
} from '@profilum-components/prf-tests/rectangle-boxes/grouped-rectangle-view-box/grouped-rectangle-view-box.component';
import {
  RectangleBoxComponent,
} from '@profilum-components/prf-tests/rectangle-boxes/rectangle-box/rectangle-box.component';
import {
  RectangleImageBoxComponent,
} from '@profilum-components/prf-tests/rectangle-boxes/rectangle-image-box/rectangle-image-box.component';
import {
  FetchCurrentSlide,
  FetchNextSlide,
  FetchPreviousSlide,
  UpdateSlide,
} from '@profilum-components/prf-tests/slides-store/slides.actions';
import { SlidesStateModel } from '@profilum-components/prf-tests/slides-store/slides.model';
import { SlidesState } from '@profilum-components/prf-tests/slides-store/slides.state';
import { NavigationComponent } from '@profilum-components/prf-tests/test-navigations/navigation/navigation.component';
import { AnchorDirective } from '@profilum-directives/anchor/anchor.directive';
import { Helper } from '@profilum-helpers/common-helper/helper';
import { NgZoneService } from '@profilum-logic-services/ng-zone-service/ng-zone-service.service';
import { OverlayBusyService } from '@profilum-logic-services/overlay-busy/overlay-busy.service';
import { RamStorageService } from '@profilum-logic-services/ram-storage/ram-storage.service';
import { StorageKeys } from '@profilum-logic-services/web-storage/web-storage.collections';
import { WebStorageService } from '@profilum-logic-services/web-storage/web-storage.service';
import { YandexMetricsService } from '@profilum-logic-services/yandex-metrics/yandex-metrics.service';
import { YmItems } from '@profilum-logic-services/yandex-metrics/yandex-metrics.collections';

@Component({
  selector: 'prf-section',
  standalone: true,
  imports: [CommonModule, AnchorDirective, NavigationComponent, TranslateModule],
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionComponent extends LoadDynamicComponentsComponent implements OnInit, OnDestroy {
  @Input() public options: IPlayerOptions;
  @Input() public isAnonymous: boolean = false;
  @Input() public isPreview: boolean = false;
  @Input() public screeningTestName: string = '';
  @Input() public enableYM: boolean = false;
  @Input() public storageKeyForCachingSessionId?: StorageKeys;
  public readonly navigation: Subject<boolean> = new Subject<boolean>();
  public sectionInfo: WritableSignal<ISectionInfo> = signal(null);
  public slideImage: WritableSignal<ISlideImage> = signal(null);
  public slidesCount: number = ETestTypesSteps.DEFAULT_STANDART;
  public slideNum: number;
  public isOpenQuestion: boolean = false;
  public isScaleQuestion: boolean = false;
  @Output() public allSlidesCount: EventEmitter<number> = new EventEmitter<number>();
  @Output() public currentSlideNumber: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild(AnchorDirective, { static: true }) protected adHost!: AnchorDirective;
  private sessionId: string;
  private questionId: string;
  private slideType: string;
  private currentSection: number = 0;
  private slideSections: ISectionInfo[] = [];
  private resultStorage: IAnswerInfo[];
  private isUIBlocked: boolean;
  private isGoForwardBlocked: boolean;
  private isGoBackBlocked: boolean;
  private goForward: boolean = true;
  private store: Store = inject(Store);
  protected slideData: IGetCurrentSlideResponse;
  protected ngZoneService: NgZoneService = inject(NgZoneService);
  protected changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);
  protected rangeBoxComponent: typeof RangeBoxComponent = RangeBoxComponent;
  protected rectangleBoxComponent: typeof RectangleBoxComponent = RectangleBoxComponent;
  protected rectangleBoxSingleComponent: typeof RectangleBoxComponent = RectangleBoxComponent;
  protected bubbleBoxComponent: typeof BubbleBoxComponent = BubbleBoxComponent;
  protected groupedRectangleViewBoxComponent: typeof GroupedRectangleViewBoxComponent = GroupedRectangleViewBoxComponent;
  protected openQuestionComponent: typeof OpenQuestionComponent = OpenQuestionComponent;
  protected rectangleImageBoxComponent: typeof RectangleImageBoxComponent = RectangleImageBoxComponent;
  protected bubbleBoxWithImageComponent: typeof BubbleBoxWithImgComponent = BubbleBoxWithImgComponent;
  protected ramStorageService: RamStorageService = inject(RamStorageService);
  protected apiUtilsService: ApiUtilsService = inject(ApiUtilsService);
  protected yandexMetricsService: YandexMetricsService = inject(YandexMetricsService);

  constructor(
    private activatedRoute: ActivatedRoute,
    private apiTestsService: ApiTestsService,
    private apiSlidesService: ApiSlidesService,
    private webStorageService: WebStorageService,
    private overlayBusyService: OverlayBusyService,
    private apiUserAnswersService: ApiUserAnswersService,
    private router: Router,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.initSection();

    this.store
      .select(SlidesState.getCurrentSlide)
      .pipe(filter((currentSlide: IMoveToNextSlideResponse) => !!currentSlide))
      .subscribe((currentSlide: IGetCurrentSlideResponse) => {
        this.setSlideData(currentSlide, this.goForward);
      });

    this.store
      .select(SlidesState.getError)
      .pipe(filter((error: any) => !!error))
      .subscribe((error: any) => {
        console.error(error);
      });

    this.navigation.pipe(takeUntil(this.unsubscribe)).subscribe((goForward: boolean) => {
      this.goForward = goForward;

      if (this.enableYM && goForward) {
        this.yandexMetricsService.hit(YmItems.None, window.location.href, {
          params: {
            event: { prflm_diagnostics: { next: [`${this.screeningTestName} step: "${this.slideNum}"`] } },
          },
        });
      }

      this.processSlides(goForward);
    });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.ramStorageService.clear(StorageKeys.CanGoForward);
  }

  public canGoBack(): boolean {
    return this.slideData && (this.slideNum > 1 || this.currentSection > 0);
  }

  public isFinish(): boolean {
    return this.slideNum === this.slidesCount;
  }

  public isDisabledGoBack(): boolean {
    return this.isGoBackBlocked;
  }

  public isDisabledGoForward(): boolean {
    return this.isGoForwardBlocked;
  }

  private initSection(): void {
    this.viewContainerRef = this.adHost.viewContainerRef;
    this.activatedRoute.params
      .pipe(
        switchMap((params: Params) => {
          this.sessionId = params['ssid'];
          this.storageKeyForCachingSessionId && this.webStorageService.set(this.storageKeyForCachingSessionId, this.sessionId);

          return this.apiTestsService.getTestSlidesCountBySessionId(this.sessionId);
        }),
        switchMap((slidesCount: number) => {
          this.slidesCount = slidesCount;
          this.allSlidesCount.emit(this.slidesCount);

          return this.store.dispatch(new FetchCurrentSlide({ sessionId: this.sessionId, isAnonymous: this.isAnonymous }));
        }),
        take(1),
        takeUntil(this.unsubscribe),
      )
      .subscribe();
  }

  private getLocalCache(): IUserAnswerLocalCache | null {
    try {
      const cache: Record<string, IUserAnswerLocalCache> = JSON.parse(this.webStorageService.get(StorageKeys.Test)) || {};
      const cacheBySessionId: IUserAnswerLocalCache | undefined = cache[this.sessionId];

      return cacheBySessionId ?? null;
    } catch (e) {
      return null;
    }
  }

  private processSlides(goForward: boolean = true): void {
    this.questionId = this.slideData.slide.questionId;
    this.slideType = this.slideData.slide.slideView.viewType;

    const cache: IUserAnswerLocalCache | null = this.getLocalCache();
    const answers: IAnswerInfo[] = this.slideData.slide.userAnswers || cache[this.slideData.slide.questionId] || [];

    switch (this.slideType) {
      case SlideType.Bubbles: {
        this.processBubblesSlides(answers, goForward);
        break;
      }
      case SlideType.Scale: {
        this.processScaleSlides(answers, goForward);
        break;
      }
      case SlideType.Rectangles: {
        this.processRectanglesSlides(answers, goForward);
        break;
      }
      default:
        break;
    }

    this.sectionInfo.set(this.getCurrentSlideSection());
    this.slideImage.set(this.setSlideImage());
    Helper.scrollToTop();
  }

  private addSlideSection(section: ISectionInfo): void {
    if (!this.slideSections.length) {
      this.currentSection = -1;
    }

    this.slideSections.push(section);
  }

  private hasSlideSections(): boolean {
    return this.slideSections.length > 0;
  }

  private getPreviousSlideSection(isNewSlide: boolean = false): ISectionInfo {
    if (isNewSlide) {
      this.currentSection = 0;

      return this.slideSections[0];
    }

    if (this.hasSlideSections() && this.currentSection > 0) {
      this.currentSection--;

      return this.slideSections[this.currentSection];
    }

    return null;
  }

  private getCurrentSlideSection(): ISectionInfo {
    if (this.hasSlideSections() && this.currentSection > -1) {
      return this.slideSections[this.currentSection];
    }

    return null;
  }

  private getNextSlideSection(): ISectionInfo {
    if (this.hasSlideSections() && this.currentSection < this.slideSections.length - 1) {
      this.currentSection++;

      return this.slideSections[this.currentSection];
    }

    return null;
  }

  private saveLocalCache(cache: IUserAnswerLocalCache): void {
    const session: Record<string, IUserAnswerLocalCache> = {
      [this.sessionId]: cache,
    };

    this.webStorageService.set(StorageKeys.Test, session);
  }

  private saveLocalChanges(): void {
    const cache: IUserAnswerLocalCache = this.getLocalCache() ?? ({} as IUserAnswerLocalCache);
    const section: ISectionInfo = this.getCurrentSlideSection();

    if (!section) {
      return;
    }

    this.resultStorage = section.elements;
    cache[this.questionId] = this.prepareAnswers(this.resultStorage);
    this.saveLocalCache(cache);
  }

  private prepareAnswers(answers: IAnswerInfo[]): IPreparedAnswer[] {
    switch (this.slideType) {
      case SlideType.Scale:
        return this.setScaleSlideAnswers(answers);
      default:
        return this.setBubblesOrRectanglesSlideAnswers(answers);
    }
  }

  private processNewBubblesSlide(answers: IAnswerInfo[]): void {
    const sections: ISlideSection[] = this.slideData.slide.slideView.bubbleView.sections;
    const slideBubbles: ISlideBubble[] = this.slideData.slide.slideView.bubbleView.bubbles;

    slideBubbles.forEach((bubble: ISlideBubble) => {
      const answer: IAnswerInfo = answers.find((userAnswer: IAnswerInfo) => userAnswer.answerId === bubble.answerId);

      if (answer) {
        const section: ISlideSection = sections.find((section: ISlideSection) => section.sectionId === answer.questionSectionId);

        Object.assign(bubble, {
          sectionType: section && section.sectionType,
          sectionId: answer.questionSectionId,
        });
      }
    });

    sections.forEach((section: ISlideSection) => {
      this.addSlideSection({
        sectionId: section.sectionId,
        sectionType: section.sectionType,
        title: section.name,
        description: section.description,
        elements: slideBubbles,
        results: this.resultStorage,
        isRequired: section.isRequired,
        minAnswers: section.minAnswers,
        maxAnswers: section.maxAnswers,
      });
    });
  }

  private processNewRectanglesSlide(answers: IAnswerInfo[]): void {
    const sections: ISlideSection[] = this.slideData.slide.slideView.rectangleView.sections;
    const slideRectangles: ISlideRectangle[] = this.slideData.slide.slideView.rectangleView.rectangles;

    slideRectangles.forEach((rectangle: ISlideRectangle) => {
      const answer: IAnswerInfo = answers.find((userAnswer: IAnswerInfo) => userAnswer.answerId === rectangle.answerId);

      if (answer) {
        const section: ISlideSection = sections.find((section: ISlideSection) => section.sectionId === answer.questionSectionId);

        Object.assign(rectangle, {
          sectionType: section && section.sectionType,
          sectionId: answer.questionSectionId,
        });
      }
    });

    sections.forEach((section: ISlideSection) => {
      this.addSlideSection({
        sectionId: section.sectionId,
        sectionType: section.sectionType,
        title: section.name,
        description: section.description,
        elements: slideRectangles,
        results: this.resultStorage,
        isRequired: section.isRequired,
        minAnswers: section.minAnswers,
        maxAnswers: section.maxAnswers,
      });
    });
  }

  private processNewScaleSlide(answers: IAnswerInfo[]): void {
    const rulers: IAnswerInfo[] = this.slideData.slide.slideView.scaleView.rulers;
    const filteredRulers: IAnswerInfo[] = rulers.filter((element: IAnswerInfo, index: number) => {
      return index === rulers.findIndex((ruler: IAnswerInfo) => ruler.questionSectionId === element.questionSectionId);
    });

    filteredRulers.forEach((ruler: IAnswerInfo) => {
      const answer: IAnswerInfo = answers.find((a: IAnswerInfo) => a.questionSectionId === ruler.questionSectionId);

      if (answer) {
        Object.assign(ruler, {
          answerId: answer.answerId,
        });
      }
    });

    const addSlideData: ISectionInfo = {
      sectionId: filteredRulers[0].questionSectionId,
      sectionType: 'Scale',
      title: this.slideData.slide.slideView.scaleView.title,
      description: this.slideData.slide.slideView.scaleView.description,
      elements: filteredRulers,
      results: this.resultStorage,
      isRequired: this.slideData.slide.slideView.scaleView.isRequired,
    };

    this.slideData.slide.slideView.scaleView.imageUrl
      ? Object.assign(addSlideData, {
          imageUrl: this.slideData.slide.slideView.scaleView.imageUrl,
        })
      : null;

    this.addSlideSection(addSlideData);
  }

  private processBubblesSlides = (answers: IAnswerInfo[], goForward: boolean): void => {
    if (RectanglesQuestion.has(this.slideData.slide.questionId)) {
      this.slideData.slide.slideView.rectangleView = {
        ...this.slideData.slide.slideView.bubbleView,
        rectangles: this.slideData.slide.slideView.bubbleView.bubbles,
      };

      this.processRectanglesSlides(answers, goForward);

      return;
    }

    let isNewSlide: boolean = false;

    if (!this.hasSlideSections()) {
      isNewSlide = true;

      this.processNewBubblesSlide(answers);
    }

    this.saveLocalChanges();

    const nextSection: ISectionInfo = goForward ? this.getNextSlideSection() : this.getPreviousSlideSection(isNewSlide);
    const answerType: AnswerType = this.slideData.slide.slideView.bubbleView.bubbles[0].answerType as AnswerType;
    let component: typeof BubbleBoxComponent | typeof BubbleBoxWithImgComponent | typeof OpenQuestionComponent;

    switch (answerType) {
      case AnswerType.Image:
        component = this.bubbleBoxWithImageComponent;
        break;
      case AnswerType.Open:
        this.isOpenQuestion = true;
        component = this.openQuestionComponent;
        break;
      default:
        component = this.bubbleBoxComponent;
        break;
    }

    if (nextSection) {
      this.setSlideNumber(isNewSlide, goForward);
      nextSection.questionType = this.slideData.slide.questionType;
      const inputData: IDynamicComponentInputData[] = [
        { field: 'sectionInfo', data: nextSection },
        { field: 'isRequired', data: nextSection.isRequired },
        { field: 'maxSelections', data: nextSection.maxAnswers },
        { field: 'minSelections', data: nextSection.minAnswers },
      ];

      if (this.options?.openQuestionRows) {
        inputData.push({ field: 'rows', data: this.options.openQuestionRows });
      }

      this.loadDynamicComponent(component, inputData);
    } else {
      this.disableUI();
      this.saveResults(goForward);
    }
  };

  private processRectanglesSlides = (answers: IAnswerInfo[], goForward: boolean): void => {
    let isNewSlide: boolean = false;

    if (!this.hasSlideSections()) {
      isNewSlide = true;
      this.processNewRectanglesSlide(answers);
    }

    this.saveLocalChanges();

    const orderTypes: Record<number, number> = {};
    const nextSection: ISectionInfo = goForward ? this.getNextSlideSection() : this.getPreviousSlideSection(isNewSlide);

    this.slideData.slide.slideView.rectangleView.rectangles.forEach((rectangle: ISlideRectangle) => {
      const orderNumber: number = orderTypes[rectangle.orderNumber] ?? 0;

      orderTypes[rectangle.orderNumber] = orderNumber + 1;
    });

    const isGrouped: boolean = Object.values(orderTypes).some((orderType: number) => orderType > 1);
    const isSingle: boolean = this.slideData.slide.questionType === QuestionTypes.Single;
    const answerType: AnswerType = this.slideData.slide.slideView.rectangleView.rectangles[0].answerType as AnswerType;
    let component:
      | typeof RectangleBoxComponent
      | typeof GroupedRectangleViewBoxComponent
      | typeof RectangleImageBoxComponent
      | typeof OpenQuestionComponent;

    switch (true) {
      case answerType === AnswerType.Image:
        component = this.rectangleImageBoxComponent;
        break;
      case answerType === AnswerType.Open:
        this.isOpenQuestion = true;
        component = this.openQuestionComponent;
        break;
      case isGrouped:
        component = this.groupedRectangleViewBoxComponent;
        break;
      case isSingle:
        component = this.rectangleBoxSingleComponent;
        break;
      default:
        component = this.rectangleBoxComponent;
        break;
    }

    if (nextSection) {
      this.setSlideNumber(isNewSlide, goForward);
      nextSection.questionType = this.slideData.slide.questionType;
      this.loadDynamicComponent(component, [
        { field: 'sectionInfo', data: nextSection },
        { field: 'isRequired', data: nextSection.isRequired },
        { field: 'maxSelections', data: nextSection.maxAnswers },
        { field: 'minSelections', data: nextSection.minAnswers },
      ]);
    } else {
      this.disableUI();
      this.saveResults(goForward);
    }
  };

  private processScaleSlides = (answers: IAnswerInfo[], goForward: boolean): void => {
    let isNewSlide: boolean = false;

    this.isScaleQuestion = true;

    if (!this.hasSlideSections()) {
      isNewSlide = true;
      this.processNewScaleSlide(answers);
    }

    this.saveLocalChanges();

    const nextSection: ISectionInfo = goForward ? this.getNextSlideSection() : this.getPreviousSlideSection(isNewSlide);

    if (nextSection) {
      this.setSlideNumber(isNewSlide, goForward);
      nextSection.questionType = this.slideData.slide.questionType;
      this.loadDynamicComponent(this.rangeBoxComponent, [
        { field: 'sectionInfo', data: nextSection },
        { field: 'isRequired', data: nextSection.isRequired },
      ]);
    } else {
      this.disableUI();
      this.saveResults(goForward);
    }
  };

  private saveResults(goForward: boolean): void {
    if (goForward) {
      const results: IPreparedAnswer[] = this.prepareAnswers(this.resultStorage);

      if (JSON.stringify(this.slideData.slide.userAnswers) !== JSON.stringify(results)) {
        this.store.dispatch(new UpdateSlide({ slideId: this.slideData.slide.id, userAnswers: results }));
        this.apiUserAnswersService
          .saveUserAnswers({
            sessionId: this.sessionId,
            userAnswers: results,
          })
          .pipe(retry({ count: 3, delay: 2000 }), take(1))
          .subscribe();
      }
    }

    this.acquireSlide(goForward);
  }

  private acquireSlide(goForward: boolean): void {
    this.loadSlideData(goForward);
  }

  private disableUI(): void {
    this.isUIBlocked = true;
    this.overlayBusyService.show();
  }

  private enableUI(): void {
    this.isUIBlocked = false;
    this.overlayBusyService.hide();
  }

  private clearSlideSections(): void {
    this.slideSections = [];
    this.currentSection = -1;
  }

  private addNewSlideData(goForward: boolean): void {
    this.clearSlideSections();
    this.resultStorage = null;
    this.processSlides(goForward);
    this.enableUI();
    this.changeDetectorRef.detectChanges();
  }

  private loadPreviousSlideData(): Observable<SlidesStateModel> {
    return this.store.dispatch(new FetchPreviousSlide({ sessionId: this.sessionId }));
  }

  private loadNextSlideData(): Observable<SlidesStateModel> {
    return this.store.dispatch(new FetchNextSlide({ sessionId: this.sessionId, isAnonymous: this.isPreview || this.isAnonymous }));
  }

  private loadSlideData(goForward: boolean): void {
    if (!goForward && this.slideData.slide.orderNumber > 1) {
      this.loadPreviousSlideData().pipe(take(1)).subscribe();
    } else {
      this.loadNextSlideData().pipe(take(1)).subscribe();
    }
  }

  private setSlideNumber(isNewSlide: boolean, goForward: boolean): void {
    if (!isNewSlide) {
      this.slideNum = goForward ? (this.slideNum += 1) : (this.slideNum -= 1);
      this.currentSlideNumber.emit(this.slideNum);
    }
  }

  private setScaleSlideAnswers(answers: IAnswerInfo[]): IPreparedAnswer[] {
    return answers
      .filter((answer: IAnswerInfo) => !!(answer.selectedAnswerId ?? answer.answerId))
      .map((answer: IAnswerInfo) => {
        return {
          questionId: this.questionId,
          answerId: answer.selectedAnswerId ?? answer.answerId,
          questionSectionId: answer.questionSectionId,
          answerType: answer.answerType,
          data: answer.text,
        };
      });
  }

  private setBubblesOrRectanglesSlideAnswers(answerInfo: IAnswerInfo[]): IPreparedAnswer[] {
    return answerInfo
      .filter((value: IAnswerInfo) => !!value.sectionId)
      .map((element: IAnswerInfo) => {
        return {
          questionId: this.questionId,
          answerId: element.answerId,
          questionSectionId: element.sectionId,
          answerType: element.answerType,
          data: element.text,
        };
      });
  }

  private setSlideImage(): ISlideImage | null {
    let imageUrl: string | null = null;

    switch (this.slideType) {
      case SlideType.Bubbles: {
        imageUrl = this.slideData.slide.slideView.bubbleView.imageUrl;
        break;
      }
      case SlideType.Scale: {
        imageUrl = this.slideData.slide.slideView.scaleView.imageUrl;
        break;
      }
      case SlideType.Rectangles: {
        imageUrl = this.slideData.slide.slideView.rectangleView.imageUrl;
        break;
      }
      default:
        break;
    }

    if (Helper.isString(imageUrl, true)) {
      return {
        src: imageUrl,
        alt: this.sectionInfo().description?.slice(0, 100) ?? '',
      };
    } else {
      return null;
    }
  }

  private setSlideData(currentSlide: IGetCurrentSlideResponse, goForward: boolean): void {
    if (currentSlide.passedLast) {
      if (this.isPreview) {
        this.apiUtilsService.deleteTestSessions({ sessionIds: [this.sessionId] }).subscribe();
        this.router.navigate(['/']).then(() => {
          this.enableUI();
        });
      } else {
        if (this.enableYM) {
          this.yandexMetricsService.hit(YmItems.None, window.location.href, {
            params: {
              event: { prflm_diagnostics: { finish: [this.screeningTestName] } },
            },
          });
        }
        this.router.navigate(['/results-switcher', this.sessionId]).then(() => {
          this.storageKeyForCachingSessionId && this.webStorageService.set(StorageKeys.SessionStatus, 'Completed');
          this.enableUI();
        });
      }

      return;
    }

    this.slideData = currentSlide;
    this.slideNum = this.slideData.slide.orderNumber;
    this.currentSlideNumber.emit(this.slideNum);
    this.addNewSlideData(goForward);
    this.enableUI();
  }
}
