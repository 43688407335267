export const phonePattern: string = '+# (###) ###-##-##';

export const EmptyGuid: string = '00000000-0000-0000-0000-000000000000';

export enum UserRoles {
  admin = 'admin',
  adminDO = 'adminDO',
  schooladmin = 'schooladmin',
  director = 'director',
  teacher = 'teacher',
  employer = 'employer',
  tutor = 'tutor',
  pupil = 'pupil',
  parent = 'parent',
}

export const enum Projects {
  Admin,
  B2c,
  B2g,
  Projects,
  TestEditor,
}
