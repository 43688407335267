import { Pipe, PipeTransform } from '@angular/core';
import { ProfessionFilter } from './profession-filter.component';

@Pipe({
  name: 'professionFilter',
})
export class ProfessionFilterPipe implements PipeTransform {
  transform(items: Array<ProfessionFilter>, filter: string): any[] {
    if (!filter || filter == '' || filter.length < 3) {
      return items;
    }

    return items.filter(item => item.name.toLowerCase().indexOf(filter.toLowerCase()) > -1);
  }
}
