import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from 'app/core/services/http.service';
import { IProsvetUserData } from 'app/shared/interfaces/iprosvet.userdata.interface';
import { ISchoolPortalUserData } from 'app/shared/interfaces/ischoolportal.userdata.interface';
import { Observable, of, throwError, TimeoutError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class LoginProsvetService {
  constructor(private http: HttpService) {}

  // обработка ошибок
  handleError(err: any): Observable<any> {
    if (err instanceof TimeoutError) {
      console.error(`Frontend returned timeout error: ${err['error']}`);
      return throwError(err['error']);
    }
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
      let errorText = err.error ? (err.error.comment ? err.error.comment : err.error) : null;
    }
    throw throwError(err);
  }

  public getProsvetAuthRoute(): Observable<any> {
    return this.http.get('/b2c/v1.0/prosveshcheniye/getauthurl').pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getProsvetUserData(code): Observable<IProsvetUserData> {
    const credentials = {
      authCode: code,
    };

    return this.http.post('/b2c/v1.0/prosveshcheniye/getuserdata', credentials).pipe(map(r => r));
  }

  //изменить метод
  public loginProsvetUser(userData) {
    const SPCredentials = {
      email: userData.email,
      userId: userData.userId,
    };
    return this.http.post('/b2c/v1.0/prosveshcheniye/login', SPCredentials).pipe(map(r => r));
  }

  public login(login: string, pass: string, rememberMe?: boolean): Observable<any> {
    localStorage.clear();
    let credentials = {
      userName: login,
      password: pass,
      rememberMe: true,
    };
    return this.http.post('/b2c/v1.0/saas/login', credentials).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  public tokenLogin(loginToken: string): Observable<any> {
    localStorage.clear();
    return this.http.post('/b2c/v1.0/saas/token_login', loginToken).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }
}
