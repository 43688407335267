import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../shared/modules/material.module';
import { FilterByNamePipe } from '../../shared/pipes/filter-by-name.pipe';
import { SharedModule } from '../../shared/shared.module';
import { PagesModule } from '../../pages/pages.module';
import { LandingBaseComponent } from './landing-base/landing-base.component';
import { SwiperDirective } from '@profilum-directives/swiper/swiper.directive';

@NgModule({
  imports: [MaterialModule, ReactiveFormsModule, PagesModule, SharedModule, SwiperDirective],
  declarations: [LandingBaseComponent],
  exports: [LandingBaseComponent],
  providers: [FilterByNamePipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LandingBaseModule {}
