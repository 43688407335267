import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'app/shared/shared.service';

import { REG_EXP } from 'app/shared/global-constants/reg-exp';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'prf-registration-prosvet',
  templateUrl: './registration-prosvet.component.html',
  styleUrls: ['./registration-prosvet.component.scss'],
})
export class RegistrationProsvetComponent implements OnInit {
  public isMaskedPassword: boolean = true;
  public password: string = null;
  public email: string = null;
  public enterByCode: boolean = false;
  private emailPattern: RegExp = REG_EXP.emailRegExp;
  public registrationFailed: boolean = false;
  public passFailed: boolean = false;
  public loginFailed: boolean = false;

  constructor(
    private meta: Meta,
    private router: Router,
    private sharedService: SharedService,
    private translateService: TranslateService,
  ) {
    this.getTranslation('SHARED.REGISTRATION')
      .pipe(take(1))
      .subscribe(translation => this.meta.updateTag({ name: 'og:title', content: translation }));
  }

  ngOnInit() {}

  public toggleMask() {
    this.isMaskedPassword = !this.isMaskedPassword;
  }

  registrationProsvetUser() {
    this.sharedService
      .getProsvetRoute()
      .pipe(take(1))
      .subscribe(schoolPortalRoute => {
        schoolPortalRoute ? (location.href = schoolPortalRoute.url) : null;
      });
  }

  public isAccessAllowed() {
    return this.password && this.isValid(this.email);
  }

  public isValid(email) {
    return this.emailPattern.test(email);
  }

  public enterCode() {
    this.enterByCode = !this.enterByCode;
  }

  getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }
}
