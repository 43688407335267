import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';
import { EventsPageComponent } from './events-page.component';

import { EventsRoutingModule } from './events-routing.module';

@NgModule({
  declarations: [EventsPageComponent],
  imports: [CommonModule, EventsRoutingModule, SharedModule],
})
export class EventsModule {}
