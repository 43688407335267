import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor(private snackBar: MatSnackBar) {}

  public openSnackBar(message: string, kindof: string, time?: number): void {
    const cfg: MatSnackBarConfig = new MatSnackBarConfig();
    cfg.horizontalPosition = 'right';
    cfg.verticalPosition = 'top';
    cfg.duration = time ? time : 9000;

    cfg.panelClass = kindof;
    this.snackBar.open(message, null, cfg);
  }

  public closeSnackBar(): void {
    this.snackBar.dismiss();
  }
}
