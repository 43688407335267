import { IHttpStatus } from '../http';

export interface IGetClassesFormatsForProfessionsResponse extends IHttpStatus {
  classesFormatsForProfession: IClassesFormatsForProfessions;
}

export interface ICreateClassesFormatsForProfessionsRequest {
  classesFormatsForProfession: IClassesFormatsForProfessions;
}

export interface ICreateClassesFormatsForProfessionsResponse extends IHttpStatus {}

export interface IUpdateClassesFormatsForProfessionsRequest {
  classesFormatsForProfession: IClassesFormatsForProfessions;
}

export interface IUpdateClassesFormatsForProfessionsResponse extends IHttpStatus {}

export interface IDeleteClassesFormatsForProfessionsResponse extends IHttpStatus {}

export interface IGetClassesFormatsForProfessionByUserIdResponse extends IHttpStatus {
  classesFormatsForProfessions: IClassesFormatsForProfessions[];
}

export interface IClassesFormatsForProfessions {
  userId: string;
  professionId: string;
  classFormats: string[];
}

export interface ISharedResultsResponse {
  id: string;
  userId: string;
  sessionId: string;
  refferalUserId: string;
  refferalSessionId: string;
  isChild: boolean;
}
