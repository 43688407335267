import { Component } from '@angular/core';
import { BaseSliderComponent } from 'app/pages/catalog/profession-page/base-slider/base-slider.component';

@Component({
  selector: 'prf-job-slider',
  templateUrl: './job-slider.component.html',
  styleUrls: ['./job-slider.component.scss'],
})
export class JobSliderComponent extends BaseSliderComponent {
  constructor() {
    super();
  }

  OnInit() {
    super.ngOnInit();
  }
}
