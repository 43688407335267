<ng-template [ngIf]="sectionInfo">
  <div class="prf-range-box">
    <prf-range-view
      *ngFor="let answer of sectionInfo.elements; let i = index; trackBy: trackByFn"
      [answerInfo]="answer"
      [sectionInfo]="sectionInfo"
      (updateValue)="updateAnswer($event)"
      class="prf-range-box-item"
    ></prf-range-view>
  </div>
</ng-template>
