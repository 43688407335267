import { Injectable } from '@angular/core';
import { IncludedToRamStorageKeys, StorageKeys, Storages } from './web-storage.collections';
import { StorageHelper } from '@profilum-helpers/storage-helper/storage-helper';
import { RamStorageService } from '@profilum-logic-services/ram-storage/ram-storage.service';
import { UserStorageService } from '@profilum-logic-services/user-storage/user-storage.service';

@Injectable({
  providedIn: 'root',
})
export class WebStorageService extends StorageHelper {
  constructor(private userStorageService: UserStorageService, private ramStorageService: RamStorageService) {
    super(Storages.Local);
  }

  public override set<T>(key: StorageKeys, value: T): void {
    super.set(key, value);
    this.userStorageService.set(key, value);

    if (IncludedToRamStorageKeys.has(key)) {
      this.ramStorageService.set(key, value);
    }
  }

  public override get<T>(key: StorageKeys): T | null {
    return super.get(key);
  }
}
