import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { timer } from 'rxjs';
import { debounce, takeUntil } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { BubbleViewComponent } from './bubble-view/bubble-view.component';
import { IAnswerInfo } from '@profilum-api-services/api-slides/api-slides.interface';
import { TestBoxHelperComponent } from '@profilum-components/prf-tests/test-helper/test-box-helper.component';
import { Helper } from '@profilum-helpers/common-helper/helper';
import { IPipeSlice } from '@profilum-interfaces/prf-tests.interface';
import { BreakpointLabels } from '@profilum-logic-services/breakpoints/breakpoints.collections';
import { IWindowSize } from '@profilum-logic-services/breakpoints/breakpoints.interface';
import { BreakpointsService } from '@profilum-logic-services/breakpoints/breakpoints.service';
import { BubbleBoxService } from '@profilum-logic-services/prf-tests/bubble-box/bubble-box.service';

@Component({
  standalone: true,
  selector: 'prf-bubble-box',
  templateUrl: './bubble-box.component.html',
  styleUrls: ['./bubble-box.component.scss'],
  imports: [CommonModule, BubbleViewComponent],
  providers: [BubbleBoxService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BubbleBoxComponent extends TestBoxHelperComponent implements OnInit, OnDestroy {
  public linesCounter: IPipeSlice[] = [];
  private breakPointsService: BreakpointsService = inject(BreakpointsService);
  private bubbleBoxService: BubbleBoxService = inject(BubbleBoxService);

  public ngOnInit(): void {
    this.initComponent();
    this.setupRequiredFields();
  }

  public trackByLineFn(index: number, item: IPipeSlice): number {
    return item.start;
  }

  public trackByFn(index: number, answer: IAnswerInfo): string {
    return answer.answerId;
  }

  private initComponent(): void {
    this.breakPointsService.getWindowSize
      .pipe(
        debounce(() => timer(300)),
        takeUntil(this.unsubscribe),
      )
      .subscribe((e: IWindowSize) => {
        let elements: IAnswerInfo[][];
        switch (true) {
          case BreakpointsService.isMobile(e.currentBreakpoint):
            elements = Helper.sliceArrayIntoChunks(this.sectionInfo.elements, 2);
            this.createLinesForTemplate(elements);
            break;
          case BreakpointsService.isTablet(e.currentBreakpoint):
            if (this.bubbleBoxService.getTabletLine(this.sectionInfo.elements.length)) {
              this.linesCounter = this.bubbleBoxService.getTabletLine(this.sectionInfo.elements.length);
              this.changeDetectorRef.detectChanges();
            } else {
              elements = Helper.sortArraysAsDiamondByLength(this.sliceArrayIntoRandomLengthArrays(this.sectionInfo.elements, true));
              this.createLinesForTemplate(elements, BreakpointLabels.Tablet);
            }
            break;
          default:
            if (this.bubbleBoxService.getDesktopLine(this.sectionInfo.elements.length)) {
              this.linesCounter = this.bubbleBoxService.getDesktopLine(this.sectionInfo.elements.length);
              this.changeDetectorRef.detectChanges();
            } else {
              elements = Helper.sortArraysAsDiamondByLength(this.sliceArrayIntoRandomLengthArrays(this.sectionInfo.elements));
              this.createLinesForTemplate(elements, BreakpointLabels.Desktop);
            }
            break;
        }
      });
  }

  private sliceArrayIntoRandomLengthArrays(elements: IAnswerInfo[], isTablet: boolean = false): IAnswerInfo[][] {
    const elementsClone: IAnswerInfo[] = window.structuredClone(elements);
    const elementsByLine: IAnswerInfo[][] = [];

    do {
      const itemCounts: number = isTablet ? Helper.getRandomInteger(2, 4) : Helper.getRandomInteger(1, 5);
      const line: IAnswerInfo[] = elementsClone.splice(0, itemCounts);
      elementsByLine.push(line);
    } while (elementsClone.length);

    return elementsByLine;
  }

  private createLinesForTemplate(elements: IAnswerInfo[][], type?: BreakpointLabels): void {
    this.linesCounter = [];
    this.linesCounter.push({ start: 0, end: elements[0].length });

    for (let i: number = 1; i < elements.length; i++) {
      const start: number = this.linesCounter[i - 1].start + elements[i - 1].length;
      const end: number = start + elements[i].length;

      this.linesCounter.push({ start: start, end: end });
    }

    if (type === BreakpointLabels.Desktop) {
      this.bubbleBoxService.setDesktopLine(this.linesCounter, this.sectionInfo.elements.length);
    } else if (type === BreakpointLabels.Tablet) {
      this.bubbleBoxService.setTabletLine(this.linesCounter, this.sectionInfo.elements.length);
    }

    this.changeDetectorRef.detectChanges();
  }
}
