//promotest is for parents
export enum ETestTypes {
  DEFAULT_STANDART,
  DEFAULT_360,
  PROFESSIONPRESTIGE,
  MOTIVATION_TEST,
  VALUES_TEST,
  PROMO_TEST,
  CAREER_TYPES,
}

//todo кол-во вопросов в тесте может меняться, возможно, нужен метод для запроса..
export enum ETestTypesSteps {
  DEFAULT_STANDART = 37,
  DEFAULT_360 = 17,
  PROFESSIONPRESTIGE = 7,
  MOTIVATION_TEST = 6,
  VALUES_TEST = 15,
  PROMO_TEST = 12,
  CAREER_TYPES = 10,
}
