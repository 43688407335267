import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BreakpointLabels, Breakpoints } from './breakpoints.collections';
import { IWindowSize } from './breakpoints.interface';
import { NgZoneService } from '@profilum-logic-services/ng-zone-service/ng-zone-service.service';

@Injectable({
  providedIn: 'root',
})
export class BreakpointsService {
  private readonly _isMobile: BehaviorSubject<boolean>;
  private readonly _isTablet: BehaviorSubject<boolean>;
  private readonly _isDesktop: BehaviorSubject<boolean>;
  private readonly _breakpoint: BehaviorSubject<string>;
  private readonly _breakpoints: Map<string, { min: number; max: number }>;
  private _resizer: BehaviorSubject<IWindowSize>;
  private _currentBreakpoint: string;

  public static isMobile(breakpoint: string): boolean {
    return breakpoint === BreakpointLabels.Mobile;
  }

  public static isTablet(breakpoint: string): boolean {
    return breakpoint === BreakpointLabels.Tablet;
  }

  public static isDesktop(breakpoint: string): boolean {
    return breakpoint === BreakpointLabels.Desktop;
  }

  constructor(private ngZoneService: NgZoneService) {
    this._isMobile = new BehaviorSubject<boolean>(false);
    this._isTablet = new BehaviorSubject<boolean>(false);
    this._isDesktop = new BehaviorSubject<boolean>(false);
    this._breakpoint = new BehaviorSubject<string>('');
    this._breakpoints = new Map<string, { min: number; max: number }>();
    this.initBreakpoints();
    this.setBreakpoints();
  }

  public get isMobile(): Observable<boolean> {
    return this._isMobile.asObservable();
  }

  public get isTablet(): Observable<boolean> {
    return this._isTablet.asObservable();
  }

  public get isDesktop(): Observable<boolean> {
    return this._isDesktop.asObservable();
  }

  public get getWindowSize(): Observable<IWindowSize> {
    return this._resizer.pipe(this.ngZoneService.subscribeToObsOutsideZone());
  }

  public get getCurrentBreakpoint(): Observable<string> {
    return this._breakpoint.asObservable();
  }

  private getActiveBreakpoints(width: number): string {
    let b: string = null;

    for (const breakpoint of this._breakpoints) {
      const { min, max }: { min: number; max: number } = breakpoint[1];

      if (width >= min && width <= max) {
        b = breakpoint[0];
        break;
      }
    }

    return b;
  }

  private initBreakpoints(): void {
    for (const breakpoint of Breakpoints) {
      let min: number = -Infinity;
      let max: number = Infinity;

      if (breakpoint[1].min) {
        min = parseInt(breakpoint[1].min, 10);
      }

      if (breakpoint[1].max) {
        max = parseInt(breakpoint[1].max, 10);
      }

      this._breakpoints.set(breakpoint[0], { min, max });
    }
  }

  private setBreakpoints(): void {
    this.ngZoneService.runOutsideZone(() => {
      this._resizer = new BehaviorSubject<IWindowSize>({
        height: window.innerHeight,
        width: window.innerWidth,
        currentBreakpoint: this.getActiveBreakpoints(window.innerWidth),
      });

      window.addEventListener('orientationchange', this.calculateWindowSize);
      window.addEventListener('resize', this.calculateWindowSize);

      this.getWindowSize
        .pipe(filter((data: IWindowSize) => data.currentBreakpoint !== this._currentBreakpoint))
        .subscribe((res: IWindowSize) => {
          this._isMobile.next(BreakpointsService.isMobile(res.currentBreakpoint));
          this._isTablet.next(BreakpointsService.isTablet(res.currentBreakpoint));
          this._isDesktop.next(BreakpointsService.isDesktop(res.currentBreakpoint));
          this._breakpoint.next(res.currentBreakpoint);
          this._currentBreakpoint = res.currentBreakpoint;
        });
    });
  }

  private calculateWindowSize = (): void => {
    const width: number = window.innerWidth;
    const height: number = window.innerHeight;

    this._resizer.next({
      height,
      width,
      currentBreakpoint: this.getActiveBreakpoints(width),
    });
  };
}
