import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

import { PupilPanelService } from 'app/pages/control-panel/pupil/pupil-panel.service';
import { ResultsService } from 'app/pages/results/results.service';
import { ETestTypesSteps } from 'app/shared/enums/testtypes.enum';
import { PlayerService } from 'app/pages/player/player.service';
import { ISwitcherItem } from 'app/shared/dashboard/page-switcher/page-switcher.component';
import { Gender } from 'app/shared/enums/gender.enums';
import { SharedService } from 'app/shared/shared.service';
import { AppSettingsService } from 'app/shared/services/appsettings.service';
import { OverlayBusyService } from 'app/shared/overlay-busy/overlay-busy.service';
import { IHistory } from 'app/shared/interfaces/ihistory.interface';
import { ITestInfo } from 'app/shared/interfaces/itestinfo.interface';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { IUserInfo, UserDataHandlerService } from '@profilum-library';

@Component({
  selector: 'prf-recommendations',
  templateUrl: './recommendations.component.html',
  styleUrls: ['./recommendations.component.scss'],
})
export class RecommendationsComponent extends UnsubscribeComponent implements OnInit {
  public loaded: boolean = false;
  public userRole: string = '';
  public userData: IUserInfo;
  public selectedChild: any;
  public childrenRegister: Array<any> = [];
  public testSessions: any[] = [];
  public testRecommends: any = [];
  public lastSessionCompleted: IHistory;
  public isLastSessionLoaded: boolean = false;
  public currentTabName: string = 'all';
  public testSessionId: string = '';
  public sessionStatus: string = '';
  public testResults: any;
  public currentSlide: number = 1;
  public countSlide: number = ETestTypesSteps.DEFAULT_STANDART;
  public userProfile: any;

  public pupilRecommendationsMenu: ISwitcherItem[] = [
    { name: 'Профессии', value: 'professions' },
    // {name: 'Профильные классы', value: 'profileClasses'},
  ];
  public selectedTab: string = this.pupilRecommendationsMenu[0].value;

  constructor(
    private pupilPanelService: PupilPanelService,
    private resultsService: ResultsService,
    private sharedService: SharedService,
    private playerService: PlayerService,
    private appSettingsService: AppSettingsService,
    private overlayService: OverlayBusyService,
    private userDataHandlerService: UserDataHandlerService,
    private router: Router,
  ) {
    super();
    this.userData = this.userDataHandlerService.getUserInfo();
    this.userRole = this.userData.role;
    this.testSessionId = localStorage.getItem('testSessionId');
    this.sessionStatus = localStorage.getItem('sessionStatus');
  }

  public ngOnInit(): void {
    this.overlayService.show();
    this.loadProfile();
    if (this.userRole === 'pupil') {
      this.getSlide().pipe(takeUntil(this.unsubscribe)).subscribe();
    }

    this.getRecommendations()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.overlayService.hide());
  }

  public getRecommendations(): Observable<any> {
    let lastSessionCompleted$: Observable<string> = of(null);

    if (this.userRole === 'pupil') {
      lastSessionCompleted$ = of(this.testSessionId);
    } else {
      lastSessionCompleted$ = this.sharedService.getUserInfoData().pipe(
        switchMap(data => {
          this.childrenRegister = data.children.filter(el => el.id != '00000000-0000-0000-0000-000000000000');
          // todo реализовать возможность переключаться между детьми
          // логика применима если только один зарегистированный ребенок, передаем первого из массива
          this.selectedChild = this.childrenRegister[0];
          return this.getLastSessionCompleted(this.selectedChild.userId);
        }),
      );
    }

    return lastSessionCompleted$.pipe(
      switchMap(lastSessionCompleted => {
        if (lastSessionCompleted && this.sessionStatus === 'Success') {
          const sessionIds = [lastSessionCompleted];

          const getResultsObject = {
            regionId: this.userData.regionId,
            municipalityId: this.userData.municipalityId,
            sessionIds: sessionIds,
            role: this.userRole,
          };

          return this.resultsService.getResultPages(getResultsObject).pipe(
            tap(results => {
              if (results.results.length > 0) {
                this.testResults = results.results;
              }
            }),
          );
        } else {
          return of(null);
        }
      }),
    );
  }

  public getLastSessionCompleted(childId): Observable<any> {
    return this.pupilPanelService.getTestingSessions(childId).pipe(
      switchMap(sessions => {
        if (sessions && sessions.length > 0) {
          return this.sharedService.getRegionTestInfo(this.userData.regionId).pipe(
            switchMap((testInfo: ITestInfo) => {
              this.testSessions = sessions
                .filter(el => el.screeningTestId === testInfo.screeningTestId)
                .sort((a, b) => (Date.parse(a.createdDate) < Date.parse(b.createdDate) ? 1 : -1));

              this.lastSessionCompleted = this.testSessions.filter(session => session.completed)[0];
              this.sessionStatus = this.lastSessionCompleted ? 'Success' : 'Uncompleted test';
              this.isLastSessionLoaded = true;
              return of(this.lastSessionCompleted?.sessionId);
            }),
          );
        } else {
          this.sessionStatus = 'Uncompleted test';
          this.isLastSessionLoaded = true;
          return of(null);
        }
      }),
    );
  }

  public loadProfile(): void {
    this.sharedService
      .getUserInfoData()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(data => {
        this.userProfile = data;
      });
  }

  private getSlide(): Observable<any> {
    if (this.sessionStatus === 'Uncompleted test') {
      return this.playerService.getCurrentSlide(this.testSessionId).pipe(
        tap(slide => {
          if (slide && slide.slide && slide.slide.orderNumber) {
            this.currentSlide = slide.slide.orderNumber;
          }
        }),
      );
    } else {
      return of(null);
    }
  }

  public selectTab(event): void {
    this.selectedTab = event?.value;
  }

  public navigateStartTest(): void {
    this.router.navigate(['/test-na-professiyu']);
  }

  public navigateContinueTest(): void {
    this.router.navigate(['/test-na-professiyu/' + this.testSessionId]);
  }

  public isMale(gender: string): boolean {
    return gender === Gender.Male;
  }
}
