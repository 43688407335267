import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'app/shared/shared.module';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ChangePasswordService } from './change-password/change-password.service';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { ConfirmEmailService } from './confirm-email/confirm-email.service';
import {
  RestorePasswordKazahstanComponent,
} from './restore-password/restore-password-kazahstan/restore-password-kazahstan.component';
import { RestorePasswordComponent } from './restore-password/restore-password.component';
import { RestorePasswordService } from './restore-password/restore-password.service';
import { SendLinkComponent } from './send-link/send-link.component';
import { TranslateModule } from '@ngx-translate/core';
import {
  ChangePasswordKazahstanComponent,
} from './change-password/change-password-kazahstan/change-password-kazahstan.component';

@NgModule({
  imports: [BrowserModule, RouterModule, SharedModule, TranslateModule],
  declarations: [
    ChangePasswordComponent,
    RestorePasswordComponent,
    ConfirmEmailComponent,
    SendLinkComponent,
    RestorePasswordKazahstanComponent,
    ChangePasswordKazahstanComponent,
  ],
  providers: [ChangePasswordService, ConfirmEmailService, RestorePasswordService],
  bootstrap: [],
})
export class SettingsModule {}
