<div class="content-loader-filter">
  <content-loader [height]="45" [width]="900">
    <svg:rect x="10" y="10" rx="20" ry="20" width="180" height="30" />
    <svg:rect x="200" y="10" rx="20" ry="20" width="360" height="30" />
  </content-loader>
</div>

<div class="profession-loader-container">
  <div class="profession-loader">
    <content-loader [height]="325" [width]="288">
      <svg:circle cx="26" cy="273" r="8" />
      <svg:rect x="37" y="203" rx="5" ry="5" width="220" height="10" />
      <svg:circle cx="25" cy="250" r="8" />
      <svg:rect x="38" y="225" rx="5" ry="5" width="220" height="10" />
      <svg:circle cx="26" cy="209" r="8" />
      <svg:rect x="38" y="247" rx="5" ry="5" width="220" height="10" />
      <svg:circle cx="25" cy="229" r="8" />
      <svg:rect x="38" y="267" rx="5" ry="5" width="220" height="10" />
      <svg:rect x="15" y="150" rx="0" ry="0" width="246" height="33" />
      <svg:circle cx="135" cy="77" r="55" />
    </content-loader>
  </div>

  <div class="profession-loader">
    <content-loader [height]="325" [width]="288">
      <svg:circle cx="26" cy="273" r="8" />
      <svg:rect x="37" y="203" rx="5" ry="5" width="220" height="10" />
      <svg:circle cx="25" cy="250" r="8" />
      <svg:rect x="38" y="225" rx="5" ry="5" width="220" height="10" />
      <svg:circle cx="26" cy="209" r="8" />
      <svg:rect x="38" y="247" rx="5" ry="5" width="220" height="10" />
      <svg:circle cx="25" cy="229" r="8" />
      <svg:rect x="38" y="267" rx="5" ry="5" width="220" height="10" />
      <svg:rect x="15" y="150" rx="0" ry="0" width="246" height="33" />
      <svg:circle cx="135" cy="77" r="55" />
    </content-loader>
  </div>

  <div class="profession-loader">
    <content-loader [height]="325" [width]="288">
      <svg:circle cx="26" cy="273" r="8" />
      <svg:rect x="37" y="203" rx="5" ry="5" width="220" height="10" />
      <svg:circle cx="25" cy="250" r="8" />
      <svg:rect x="38" y="225" rx="5" ry="5" width="220" height="10" />
      <svg:circle cx="26" cy="209" r="8" />
      <svg:rect x="38" y="247" rx="5" ry="5" width="220" height="10" />
      <svg:circle cx="25" cy="229" r="8" />
      <svg:rect x="38" y="267" rx="5" ry="5" width="220" height="10" />
      <svg:rect x="15" y="150" rx="0" ry="0" width="246" height="33" />
      <svg:circle cx="135" cy="77" r="55" />
    </content-loader>
  </div>
</div>
