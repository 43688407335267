export interface ISpiderChartConfig {
  defaultSize: number;
  layout: {
    type: SpiderChartFiguresEnum;
    peaks: number;
    repeatCounts: number;
    repeatFigureStep: number;
    heightModifier: number;
    widthModifier: number;
    strokeWidth: string;
    strokeWidthRepeatedHex: string;
    stroke: string;
    fill: string;
    fillOpacity: number;
  };
  line: {
    stroke: string;
    strokeOpacity: number;
    strokeWidth: string;
  };
  polygon: {
    strokeWidth: string;
    fillOpacity: number;
  };
  legendValue: {
    offset: Record<SpiderChartPositioningTypeEnum, number>;
    transformString: Record<SpiderChartPositioningTypeEnum, Record<number, string>>;
    onlyColoredDots: SpiderChartThemeEnum[];
  };
  differentPoints: {
    enabled: boolean;
    differencePercent: number;
    imgSrc: string;
    width: number;
    height: number;
  };
  animation: {
    enabled: boolean;
  };
}

export interface ISpiderChartUnit {
  axis: string;
  description: string;
  value: number;
  color?: string;
}

export enum SpiderChartFiguresEnum {
  HEXAGON,
}

export enum SpiderChartThemeEnum {
  GREEN = 'green',
  BLUE = 'blue',
  YELLOW = 'yellow',
  PUPIL_CAPABILITIES = 'pupil-capabilities',
  CLASS_RESULTS = 'class-results',
}

export enum SpiderChartPositioningTypeEnum {
  DEFAULT = 'default',
  PUPIL_CAPABILITIES = 'pupil-capabilities',
  CLASS_RESULTS = 'class-results',
}
