import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from 'app/shared/shared.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UtilsService } from '../backend-services/utils.service';

@Component({
  selector: 'prf-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(private sharedService: SharedService, private router: Router, private utilsService: UtilsService) {}

  ngOnInit() {
    this.sharedService
      .logOut()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(r => {
        this.utilsService.openSnackBar('👌 Вы вышли из системы', 'success');
        // setTimeout(_ => {
        //   location.reload();
        // }, 500);
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
