import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError, TimeoutError } from 'rxjs';
import { HttpService } from '../../../core/services/http.service';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class UserProfileService {
  private userProfile = new BehaviorSubject<any>(null);

  constructor(private http: HttpService, private utilsService: UtilsService) {}

  // обработка ошибок
  handleError(err: any): Observable<any> {
    if (err instanceof TimeoutError) {
      console.error(`Frontend returned timeout error: ${err['error']}`);
      return throwError(err['error']);
    }
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
      let errorText = err.error ? (err.error.comment ? err.error.comment : err.error) : null;
    }
    throw throwError(err);
  }

  // получить класс ученика
  getSchoolClass(): Observable<any> {
    return this.http.get('/b2c/v1.0/pupils/getschoolclass').pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  updateUserProfile(user: any): Observable<any> {
    return this.http.put('/api/v1.0/profiles/userprofile/update', user).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  changeEmail(email: string): Observable<any> {
    return this.http.post('/b2c/v1.0/saas/changeemail', { newEmail: email }).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  changeUserAvatar(image: File): Observable<any> {
    let formData: FormData = new FormData();
    formData.append(image[0].name, image[0]);
    return this.http.postImage('/api/v1.0/profiles/userprofile/uploadavatar', formData).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  // async getUserFavorites(userId): Promise<any> {
  //   try {
  //     let response = await this.http
  //       .get('/api/v1.0/profiles/favorites/getfavorites?userId=' + userId)
  //       .toPromise();
  //     return response;
  //   } catch (e) {
  //     throwError(e);
  //     this.utilsService.openSnackBar('👎 Ошибка на сервере, попробуйте позже', 'error');
  //     return null;
  //   }
  // }
  // getUserFavorites(userId): Observable<any> {
  //   return this.http.get('/api/v1.0/profiles/favorites/getfavorites?userId=' + userId).pipe(
  //     map(r => r),
  //     catchError((err: HttpErrorResponse) => {
  //       return this.handleError(err);
  //     })
  //   );
  // }

  // getUserFavorite(favoriteId): Observable<any> {
  //   return this.http.get('/api/v1.0/catalog/classes/mongo/onebyid?id=' + favoriteId).pipe(
  //     map(r => r.class),
  //     catchError((err: HttpErrorResponse) => {
  //       return this.handleError(err);
  //     })
  //   );
  // }

  public getSharedResults(userId?: string): Observable<any> {
    const params = userId ? '?userId=' + userId : '';
    return this.http.get('/api/v1.0/profiles/sharedresults/getbyuser' + params).pipe(
      map(r => r.sharedResults),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  setUserProfile(user: any) {
    this.userProfile.next(user);
  }

  getUserProfile(): Observable<any> {
    return this.userProfile.asObservable();
  }
}
