import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { COURSES_FORMAT_DATA } from 'app/shared/global-constants/constants';
import { IFilterClasses } from 'app/shared/interfaces/ifilterclasses.interface';
import { CoursesFiltersService } from '../courses-filters.service';

@Component({
  selector: 'prf-courses-format-filter',
  templateUrl: './courses-format-filter.component.html',
  styleUrls: ['./courses-format-filter.component.scss'],
})
export class CoursesFormatFilterComponent implements OnInit {
  subscription: any;
  filters: IFilterClasses;
  formats = COURSES_FORMAT_DATA;
  selectedFormats: any = [];
  classes: any = [];
  formatOpen: boolean = false;
  selectFormat: string;
  @ViewChild('formatFilter') formatFilter: ElementRef;

  constructor(private filtersService: CoursesFiltersService, private translateService: TranslateService) {}

  ngOnInit() {
    this.subscription = this.filtersService.getCoursesFilters().subscribe(data => {
      this.filters = data;
      this.selectedFormats = data.format ? data.format : [];
    });
  }

  setFormatsFilter(format: any) {
    if (!this.filters.format) {
      this.filters.format = format.value;
    } else {
      this.filters.format = format.value;
    }
    this.filtersService.setCoursesFilter(this.filters);
    this.formatOpen = false;
  }

  setFormat(obj) {
    if (obj) {
      this.selectedFormats = obj;
    }
  }

  deselectFormat() {
    this.filters.format = null;
    this.filtersService.setCoursesFilter(this.filters);
  }

  checkFilterHeader() {
    if (this.filters.format) {
      let format = this.formats.filter(el => el.value === this.selectedFormats);
      return format[0].viewValue;
    } else {
      return 'Формат';
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  @HostListener('document:click', ['$event.target'])
  checkClick(target) {
    if (this.formatFilter && !this.formatFilter?.nativeElement.contains(target)) {
      this.formatOpen = false;
    }
  }
}
