import { Component, Input } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IAnketaObject } from 'app/pages/player/anketa/anketa.interface';

@Component({
  selector: 'prf-select-test-type-int',
  templateUrl: './select-test-type-int.component.html',
  styleUrls: ['./select-test-type-int.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: SelectTestTypeIntComponent, multi: true },
    { provide: NG_VALIDATORS, useExisting: SelectTestTypeIntComponent, multi: true },
  ],
})
export class SelectTestTypeIntComponent implements ControlValueAccessor {
  @Input()
  anketa: IAnketaObject;
  public selected: number;

  private onChangeCallback = (_: any) => {};
  private onTouchedCallback = () => {};

  public select(value) {
    this.selected = value;
    this.onChangeCallback(value);
  }

  public writeValue(value) {
    this.selected = value || 0;
  }

  public registerOnChange(fn) {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn) {
    this.onTouchedCallback = fn;
  }

  public validate(control: AbstractControl): { [validator: string]: string } {
    const value = control.value;

    if (value === 1 || value === 2 || value === 3) {
      return null;
    }
    return { selectTestType: '' };
  }
}
