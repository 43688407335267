import { IHttpStatus } from '../http';
import { FieldTypes } from './api-fields.collections';

export interface IGetAllFieldsResponse extends IHttpStatus {
  fields: IField[];
}

export interface IGetOneFieldResponse extends IHttpStatus {
  field: IField;
}

export interface IFieldStructuredDescriptionResponse {
  name: string;
  structuredDescription: IStructuredDescription;
}

export interface IField {
  id: string;
  name: string;
  description: string;
  icon: string;
  type?: FieldTypes;
}

export interface IStructuredDescription {
  imageUrl: string;
  backgroundImageUrl: string;
  description: string;
  outlook: string;
  relationToOthers: string;
  averageSalary: number;
  laborMarketOverview: string;
  newProfessions: INewProfessions[];
  futureProfessions: IFutureProfessions[];
  requiredSkills: IRequiredSkills[];
  references: string[];
  additionalProp1: string;
  additionalProp2: string;
  additionalProp3: string;
}

export interface INewProfessions {
  name: string;
  description: string;
}

export interface IFutureProfessions {
  name: string;
  description: string;
}

export interface IRequiredSkills {
  name: string;
  description: string;
}

export interface IFieldCreateResponse extends IFieldResponseStatus {
  id: string;
}

export interface IFieldResponseStatus {
  status: string;
  comment: string;
  senderService: string;
}
