import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IProfession } from '../../api-services';
import { ISortedItem } from './professions.interface';

@Injectable({
  providedIn: 'root',
})
export class ProfessionsService {
  private colorMap = {
    'Административная работа': 1,
    'Продажи и торговое дело': 1,
    Управление: 1,
    'Экономика, бизнес и финансы': 1,
    'Инженерия, архитектура и проектирование': 6,
    'Естественные науки': 3,
    'Медицина и здравоохранение': 3,
    'Сельское и лесное хозяйство, рыболовство': 3,
    'Образование и педагогика, управление знаниями': 7,
    'Реклама, PR, развлечения и СМИ': 7,
    'Социальные и гуманитарные науки': 7,
    Юриспруденция: 7,
    'Производство и технологии': 2,
    'Строительные и технические работы, добыча': 2,
    'Транспорт и перевозки': 2,
    'Установка, обслуживание и ремонт': 2,
    'Информационные технологии и математика': 5,
    'Безопасность и обеспечение порядка': 4,
    'Военное дело': 4,
    Спорт: 4,
    'Искусство, творчество, дизайн': 8,
    'Сервис, питание и услуги': 1,
  };
  private currentProfession = new BehaviorSubject<IProfession>(null);
  private sortingProfession$ = new BehaviorSubject<ISortedItem>(null);

  public getColorSchemeNumber(fieldName) {
    return this.colorMap[fieldName] || 1;
  }

  public setCurrentProfession(job: IProfession) {
    this.currentProfession.next(job);
  }

  public getCurrentProfession(): Observable<IProfession> {
    return this.currentProfession.asObservable();
  }

  public setSortingProfession(name) {
    this.sortingProfession$.next(name);
  }

  public getSortingProfession(): Observable<ISortedItem> {
    return this.sortingProfession$.asObservable();
  }
}
