import { IHttpStatus } from '../http';
import { EiType } from '../b2g-reports';

export interface ICheckReferralParams {
  consent?: boolean;
}

export interface ICheckReferralResponse extends IHttpStatus {
  isValid: boolean;
}

export interface ICodeSendParams {
  phone: string;
}

export interface ICodeSendResponse extends IHttpStatus {}

export interface ICodeVerifyParams {
  code: string;
}

export interface ICodeVerifyResponse extends IHttpStatus {}

export interface IInviteResponse extends IHttpStatus {
  invitationLink: string;
}

export interface IRegisterBody {
  firstName: string;
  lastName: string;
  phone: string;
  password: string;
  city: string;
  eiType: EiType;
  eiName: string;
  grade: number;
  letter: string;
}

export interface IRegisterResponse extends IHttpStatus {
  userId: string;
}

export interface ICitiesResponse extends IHttpStatus {
  cities: string[];
}

export interface IEisResponse extends IHttpStatus {
  eis: string[];
}
