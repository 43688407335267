import { inject, Injectable } from '@angular/core';
import { HttpService } from '../http';
import {
  IAssigningCourseModel,
  ICopyCourseMaterialWithLessonsRequest,
  ICopyCourseMaterialWithLessonsResponse,
  ICourseMaterial,
  ICourseMaterialExt,
  ICreateCourseMaterialRequest,
  ICreateCourseMaterialResponse,
  ICreateLessonRequest,
  ICreateLessonResponse,
  IDeleteCourseMaterialResponse,
  IDeleteLessonRequest,
  IDeleteLessonResponse,
  IGetAllCourseMaterialsResponse,
  IGetCourseByClassResponse,
  IGetOneCourseMaterialResponse,
  ILesson,
  ILessonActivity,
  IUpdateCourseMaterialRequest,
  IUpdateCourseMaterialResponse,
  IUpdateLessonRequest,
  IUpdateLessonResponse,
  ProfessionsTasksActivities,
} from './api-courses-materials.interface';
import { ApiCoursesMaterialsUrls } from './api-courses-materials.collections';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiCoursesMaterialsService {
  private readonly httpService: HttpService = inject(HttpService);

  public getAllCourseMaterials(): Observable<IGetAllCourseMaterialsResponse> {
    return this.httpService.get<IGetAllCourseMaterialsResponse>(ApiCoursesMaterialsUrls.GetAllCourseMaterials).pipe(take(1));
  }

  public getOneCourseMaterial(id: string): Observable<ICourseMaterial | ICourseMaterialExt> {
    return this.httpService
      .get<IGetOneCourseMaterialResponse>(ApiCoursesMaterialsUrls.GetOneCourseMaterial, {
        id,
      })
      .pipe(map(response => response.courseMaterial));
  }

  public deleteCourseMaterial(id: string): Observable<IDeleteCourseMaterialResponse> {
    return this.httpService
      .delete<IDeleteCourseMaterialResponse>(ApiCoursesMaterialsUrls.CourseMaterials, {
        id,
      })
      .pipe(take(1));
  }

  public createCourseMaterial(courseMaterial: ICreateCourseMaterialRequest): Observable<ICreateCourseMaterialResponse> {
    return this.httpService.post<ICreateCourseMaterialResponse>(ApiCoursesMaterialsUrls.CourseMaterials, courseMaterial).pipe(take(1));
  }

  public updateCourseMaterial(courseMaterial: IUpdateCourseMaterialRequest): Observable<IUpdateCourseMaterialResponse> {
    return this.httpService.put<IUpdateCourseMaterialResponse>(ApiCoursesMaterialsUrls.CourseMaterials, courseMaterial).pipe(take(1));
  }

  public createLesson(lessonMaterial: ICreateLessonRequest): Observable<ICreateLessonResponse> {
    return this.httpService.post<ICreateLessonResponse>(ApiCoursesMaterialsUrls.CreateLesson, lessonMaterial).pipe(take(1));
  }

  public updateLesson(lessonMaterial: IUpdateLessonRequest): Observable<IUpdateLessonResponse> {
    return this.httpService.put<ICreateLessonResponse>(ApiCoursesMaterialsUrls.UpdateLesson, lessonMaterial).pipe(take(1));
  }

  public deleteLesson(lessonData: IDeleteLessonRequest): Observable<IDeleteLessonResponse> {
    return this.httpService.delete<IDeleteLessonResponse>(ApiCoursesMaterialsUrls.DeleteLesson, null, lessonData).pipe(take(1));
  }

  public copyCourseMaterialWithLessons(params: ICopyCourseMaterialWithLessonsRequest): Observable<ICopyCourseMaterialWithLessonsResponse> {
    return this.httpService
      .post<ICopyCourseMaterialWithLessonsResponse>(ApiCoursesMaterialsUrls.CopyCourseMaterialWithLessons, null, params)
      .pipe(take(1));
  }

  public getCourseByClass(schoolClassId: string): Observable<IGetCourseByClassResponse> {
    return this.httpService.get<IGetCourseByClassResponse>(ApiCoursesMaterialsUrls.GetCourseByClass, { schoolClassId }).pipe(take(1));
  }

  public getAllCourses(): Observable<any> {
    return this.httpService.get(ApiCoursesMaterialsUrls.GetAllCourses).pipe(map((r: any) => r.courses));
  }

  public getByCourseMaterialId(courseId): Observable<ILesson[]> {
    return this.httpService.get(ApiCoursesMaterialsUrls.GetCourseById, courseId).pipe(map((response: any) => response?.lessons ?? []));
  }

  public getLessonsById(id: string): Observable<ILesson> {
    return this.httpService.get(ApiCoursesMaterialsUrls.GetLesson, { id }).pipe(map((response: any) => response?.lesson ?? null));
  }

  public getPassed(schoolClassId: string, courseId: string): Observable<ILessonActivity[]> {
    return this.httpService
      .get(ApiCoursesMaterialsUrls.GetPassed, { courseId, schoolClassId })
      .pipe(map((response: any) => response?.lessonActivity));
  }

  public getProfessionsTasksActivities(userId: string, lessonId: string) {
    return this.httpService
      .get(ApiCoursesMaterialsUrls.GetProfessionTaskActivities, { userId, lessonId })
      .pipe(map((response: ProfessionsTasksActivities) => response.chooseProfessionsFromFieldTaskActivity));
  }

  public addProfessionToTaskActivity(courseId: string, lessonId: string, taskId: string, userId: string, processedProfessions: any) {
    return this.httpService.post(ApiCoursesMaterialsUrls.AddProfessionTaskActivities, {
      courseId,
      lessonId,
      taskId,
      userId,
      processedProfessions,
    });
  }

  public setPassed(userId: string, courseId: string, lessonId: string, schoolClassId: string, taskId?: string, isPassed?: boolean) {
    return this.httpService.put(ApiCoursesMaterialsUrls.SetPassed, {
      lessonActivity: [{ userId, courseId, lessonId, schoolClassId, taskId, isPassed }],
    });
  }

  public setMultipleTaskPassedTemp(lessonActivity: ILessonActivity[]) {
    return this.httpService.put(ApiCoursesMaterialsUrls.SetPassed, { lessonActivity });
  }

  public assignCourseForClass(courseAssigningData: IAssigningCourseModel): Observable<ILessonActivity> {
    return this.httpService.post(ApiCoursesMaterialsUrls.AssignCourseForClass, courseAssigningData);
  }

  public updateCourseForClass({ courseActivity: { courseId, schoolClassId } }: IAssigningCourseModel): Observable<ILessonActivity> {
    return this.httpService.post(ApiCoursesMaterialsUrls.UpdateCourseForClass, { courseId, schoolClassId });
  }

  // TODO нужен запрос который принимает несколько courseId и по ним возвращает необходимые lessons без всяких combineLatest, так же убрать сортировку
  public getLessonsByCourseIds(courseIds: string[]): Observable<ILesson[]> {
    return combineLatest(
      courseIds.map((courseMaterialId: string) => {
        return this.httpService
          .get(ApiCoursesMaterialsUrls.GetByCourseMaterialId, { courseMaterialId })
          .pipe(map((response: any) => response?.lessons ?? []));
      }),
    ).pipe(
      map((lessons: ILesson[][]) => {
        // @ts-ignore
        return lessons.flat().sort((lesson1, lesson2) => lesson1.orderNumber - lesson2.orderNumber);
      }),
    );
  }

  // TODO нужен запрос который принимает несколько courseId и по ним возвращает необходимые lessons без всяких combineLatest
  public getPassedLessonActivity(courseIds: string[], schoolClassId: string): Observable<ILessonActivity[]> {
    return combineLatest(
      courseIds.map((courseId: string) => {
        return this.httpService
          .get(ApiCoursesMaterialsUrls.GetPassed, { courseId, schoolClassId })
          .pipe(map((response: any) => response?.lessonActivity ?? []));
      }),
    ).pipe(
      // TODO бек должен возвращать плоский а не вложенный массив
      map((lessonActivities: ILessonActivity[][]) => {
        // @ts-ignore
        return lessonActivities.flat();
      }),
    );
  }

  public getStaticTaskById(id: string) {
    return this.httpService.get(ApiCoursesMaterialsUrls.GetTaskById, { id }).pipe(map((response: any) => response.task));
  }

  public getStaticTaskByComplexity(complexity: string): Observable<any> {
    return this.httpService
      .post(ApiCoursesMaterialsUrls.SearchTasksByComplexity, { complexity })
      .pipe(map((response: any) => response.tasks));
  }
}
