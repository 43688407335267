<div
  *ngIf="answerInfo"
  class="prf-bubble-view prf-bubble-view-theme"
  [class.select]="isSelectedCurrently()"
  [class.selected]="isSelectedBefore()"
  [class.positive]="isPositive()"
  [class.negative]="!isPositive()"
  (click)="selectAnswer()"
>
  <img
    draggable="false"
    class="prf-bubble-img"
    [ngSrc]="src"
    [alt]="'PRF_TESTS.BUBBLE_BOX_WITH_IMAGE.IMAGE_ALT_TEXT' | translate"
    width="200"
    height="200"
  />
</div>
