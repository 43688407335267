import { Component, ElementRef, HostBinding, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from 'app/shared/shared.service';
import {
  COURSES_TYPES,
} from 'app/shared/dashboard/courses/courses-catalog/courses-filter/courses-types-filter/courses-types-filter.component';
import { FavoritesService } from 'app/pages/catalog/favorites/favorites.service';

import { IInternship } from 'app/shared/interfaces/iinternships.interface';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { InternshipDetailsService } from '../internship-details/internship-details.service';
import { FavoritesTypes } from 'app/shared/enums/favorites.enums';

const DESCRIPTION_LENGTH: number = 70;

@Component({
  selector: 'prf-internship-card-new',
  templateUrl: './internship-card-new.component.html',
  styleUrls: ['./internship-card-new.component.scss'],
})
export class InternshipCardNewComponent {
  @Input() internship: IInternship;
  types = COURSES_TYPES;
  currentType: any = { color: '' };
  isFavorite: boolean = false;
  favoritesInternships: any;
  userId: string;
  moment: any = moment;
  isToggleFavorite: boolean = false;
  startDate: string;

  public userRole: string = '';

  @HostBinding('class.events-column') hostClass: boolean = true;
  @ViewChild('heart') heart: ElementRef;
  @ViewChild('heartActive') heartActive: ElementRef;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private internshipDetailsService: InternshipDetailsService,
    private router: Router,
    private sharedService: SharedService,
    private favoritesService: FavoritesService,
  ) {
    this.moment.locale('ru');
  }

  ngOnInit() {
    this.userRole = localStorage.getItem('userRole');
    this.userId = localStorage.getItem('userId');
    this.internshipDetailsService
      .getFavoriteInternships()
      .pipe(
        tap(data => {
          this.favoritesInternships = data;
          if (this.favoritesInternships) {
            let productIds = this.favoritesInternships.filter(el => el.productId === this.internship.id);
            this.isFavorite = productIds.length > 0;
          }
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
    this.startDate = this.moment(this.internship.startDate).format('D MMMM YYYY');
  }

  public toggleFavorite() {
    this.isToggleFavorite = true;

    if (this.isFavorite) {
      this.removeFavorite();
      this.isFavorite = false;
    } else {
      this.addToFavorite();
      this.isFavorite = true;
    }
  }

  public cutText(text: string, maxLen: number): string {
    if (text && text.length > maxLen) {
      text = text.substring(0, maxLen - 2) + '..';
    }
    return text;
  }
  //todo: check if internships and Class objects match each other..
  loadFavorites(): Observable<any> {
    return this.favoritesService.getUserFavorites(this.userId, FavoritesTypes.Class).pipe(
      tap(r => {
        this.favoritesInternships = r;
        this.internshipDetailsService.addFavoriteInternships(this.favoritesInternships);
      }),
    );
  }

  addToFavorite() {
    this.internshipDetailsService
      .addToFavorites(this.internship.id, this.userId)
      .pipe(
        switchMap(r => this.loadFavorites()),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  removeFavorite() {
    if (this.favoritesInternships) {
      let filteredFavorites = this.favoritesInternships.filter(el => el.productId === this.internship.id);
      let internshipFavoriteIds = filteredFavorites.map(el => el.id);
      if (internshipFavoriteIds) {
        internshipFavoriteIds.forEach(favoriteId =>
          this.internshipDetailsService.deleteInternshipFromFavorites(favoriteId).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(),
        );
        this.isFavorite = false;
      }
    }
  }

  checkAction(event: any) {
    if (!this.isToggleFavorite) {
      switch (true) {
        case this.heart && this.heart.nativeElement.contains(event.target):
          this.addToFavorite();
          break;
        case this.heartActive && this.heartActive.nativeElement.contains(event.target):
          this.removeFavorite();
          break;
        default:
          this.router.navigate(['/internships', this.internship.hrid]);
          break;
      }
    } else {
      this.favoritesInternships = false;
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
