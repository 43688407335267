import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ApiTryingOnUrls } from './api-trying-on.collections';
import {
  IGetProfessionsForFilteringResponse,
  IGetResultsResponse,
  IGetTryingOnQueryParams,
  ISetTryingOnAnswersRequest,
  ISetTryingOnAnswersResponse,
} from './api-trying-on.interface';
import { HttpService } from '../http';

@Injectable({
  providedIn: 'root',
})
export class ApiTryingOnService {
  constructor(private httpService: HttpService) {}

  public getProfessionsForFiltering(queryParams: IGetTryingOnQueryParams): Observable<IGetProfessionsForFilteringResponse> {
    return this.httpService.get<IGetProfessionsForFilteringResponse>(ApiTryingOnUrls.ProfessionsForFiltering, queryParams).pipe(take(1));
  }

  public getResults(queryParams: IGetTryingOnQueryParams): Observable<IGetResultsResponse> {
    return this.httpService.get<IGetResultsResponse>(ApiTryingOnUrls.GetResults, queryParams).pipe(take(1));
  }

  public setTryingOnAnswers(body: ISetTryingOnAnswersRequest): Observable<ISetTryingOnAnswersResponse> {
    return this.httpService.post<ISetTryingOnAnswersResponse>(ApiTryingOnUrls.SetTryingOnAnswers, body).pipe(take(1));
  }
}
