import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';
import { NewsDetailComponent } from './news-detail/news-detail.component';
import { NewsLentaComponent } from './news-lenta/news-lenta.component';

import { NewsRoutingModule } from './news-routing.module';
import { PageNewsComponent } from './page-news.component';

@NgModule({
  declarations: [PageNewsComponent, NewsLentaComponent, NewsDetailComponent],
  imports: [CommonModule, NewsRoutingModule, SharedModule],
})
export class NewsModule {}
