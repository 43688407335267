import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'prf-schooladmin-welcome',
  templateUrl: './schooladmin-welcome.component.html',
  styleUrls: ['./schooladmin-welcome.component.scss'],
})
export class SchooladminWelcomeComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
