import {
  IGetCurrentSlideResponse,
  IMoveToNextSlideResponse,
  IMoveToPreviousSlideResponse,
} from '@profilum-api-services/api-slides/api-slides.interface';
import { IPreparedAnswer } from '@profilum-interfaces/prf-tests.interface';


export class FetchNextSlide {
  public static readonly type: string = '[PRF_TESTS_SLIDES] Fetch Next Slide';

  constructor(public payload: { sessionId: string; isAnonymous: boolean }) {}
}

export class SetNextSlide {
  public static readonly type: string = '[PRF_TESTS_SLIDES] Set Next Slide';

  constructor(public payload: { slide: IMoveToNextSlideResponse }) {}
}

export class SetNextSlideFailed {
  public static readonly type: string = '[PRF_TESTS_SLIDES] Set Next Slide Failed';

  constructor(public payload: { error: any }) {}
}

export class FetchPreviousSlide {
  public static readonly type: string = '[PRF_TESTS_SLIDES] Fetch Previous Slide';

  constructor(public payload: { sessionId: string }) {}
}

export class SetPreviousSlide {
  public static readonly type: string = '[PRF_TESTS_SLIDES] Set Previous Slide';

  constructor(public payload: { slide: IMoveToPreviousSlideResponse }) {}
}

export class SetPreviousSlideFailed {
  public static readonly type: string = '[PRF_TESTS_SLIDES] Set Previous Slide Failed';

  constructor(public payload: { error: any }) {}
}

export class FetchCurrentSlide {
  public static readonly type: string = '[PRF_TESTS_SLIDES] Fetch Current Slide';

  constructor(public payload: { sessionId: string, isAnonymous: boolean }) {}
}

export class SetCurrentSlide {
  public static readonly type: string = '[PRF_TESTS_SLIDES] Set Current Slide';

  constructor(public payload: { slide: IGetCurrentSlideResponse }) {}
}

export class SetCurrentSlideFailed {
  public static readonly type: string = '[PRF_TESTS_SLIDES] Set Current Slide Failed';

  constructor(public payload: { error: any }) {}
}

export class DeleteAll {
  public static readonly type: string = '[PRF_TESTS_SLIDES] Delete All';
}

export class UpdateSlide {
  public static readonly type: string = '[PRF_TESTS_SLIDES] Update Slide';

  constructor(public payload: { slideId: string; userAnswers: IPreparedAnswer[] }) {}
}
