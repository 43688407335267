import { inject, Injectable } from '@angular/core';
import { HttpService } from '../http';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  IField,
  IFieldCreateResponse,
  IFieldStructuredDescriptionResponse,
  IGetAllFieldsResponse,
  IGetOneFieldResponse,
} from './api-fields.interface';
import { ApiFieldsUrls, FieldTypes } from './api-fields.collections';

@Injectable({
  providedIn: 'root',
})
export class ApiFieldsService {
  private readonly httpService: HttpService = inject(HttpService);

  public getAllFields(fieldType: FieldTypes = FieldTypes.Default): Observable<IGetAllFieldsResponse> {
    return this.httpService.get<IGetAllFieldsResponse>(ApiFieldsUrls.GetAllFields, { fieldType }).pipe(take(1));
  }

  public getGroups(): Observable<any> {
    return this.httpService.get(ApiFieldsUrls.GetGroups).pipe(take(1));
  }

  public getFieldStructuredDescriptionByID(id: string): Observable<IFieldStructuredDescriptionResponse> {
    const url: string = ApiFieldsUrls.ReturnsFieldStructuredDescriptionByID.replace('{id}', id);

    return this.httpService.get<IFieldStructuredDescriptionResponse>(url).pipe(take(1));
  }

  public getOneField(fieldId: string): Observable<IGetOneFieldResponse> {
    return this.httpService.get<IGetOneFieldResponse>(ApiFieldsUrls.GetOneField, { fieldId }).pipe(take(1));
  }

  public getFieldTypes(): Observable<any> {
    return this.httpService.get(ApiFieldsUrls.GetFieldTypes).pipe(take(1));
  }

  public createField(field: IField): Observable<IFieldCreateResponse> {
    return this.httpService.post<IFieldCreateResponse>(ApiFieldsUrls.Fields, { field }).pipe(take(1));
  }
}
