import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'prf-all-internships',
  templateUrl: './all-internships.component.html',
  styleUrls: ['./all-internships.component.scss'],
})
export class AllInternshipsComponent implements OnInit {
  public ActiveArchive: number = 1;

  constructor(private meta: Meta) {
    this.meta.updateTag({ name: 'og:title', content: 'Все стажировки' });
  }

  ngOnInit() {}
}
