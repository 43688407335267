<section class="pieChart-container">
  <h1>Таланты</h1>
  <canvas
    baseChart
    [datasets]="pieChartData"
    [options]="pieChartOptions"
    [labels]="pieChartLabels"
    [legend]="pieChartLegend"
    [type]="pieChartType"
  >
  </canvas>
</section>
