import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy',
})
export class OrderByPipe implements PipeTransform {
  transform(array: Array<any>, args: string) {
    if (array && array.length > 0 && args) {
      let orderByValue = args;
      let byVal = 1;

      if (orderByValue[0] == '!') {
        byVal = -1;
        orderByValue = orderByValue.substring(1);
      }

      array.sort((a: any, b: any) => {
        if (a[orderByValue] < b[orderByValue]) {
          return -byVal;
        } else if (a[orderByValue] > b[orderByValue]) {
          return byVal;
        } else {
          return 0;
        }
      });

      return array;
    }

    return array;
  }
}
