import { UserActionKind, UserActionResult } from './api-auth.collections';
import { IHttpStatus } from '../http';

export interface ICookieInterface {
  status: string;
  comment: string;
}

export interface IMosRuUserData {
  userId: string;
  email?: string;
  role?: string;
  status?: string;
  comment?: string;
}

export interface ICheckActivationCode {
  isActive: boolean;
  status?: string;
  comment?: string;
  senderService?: string;
}

export interface ISchoolPortalUserData {
  userId: string;
  externalAppUserId?: string;
  email?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  role?: string;
  schoolId?: string;
  municipalityId?: string;
  regionId?: string;
  birthday?: string;
  gender?: string;
  city?: string;
  tag?: string;
  status?: string;
  comment?: string;
  needToUpdate?: boolean;
}

export interface IUserMurmanskInterface {
  status: string;
  comment: string;
  senderService: string;
  userId: string;
  externalAppUserId: string;
  email: string;
  firstName: string;
  middleName: string;
  lastName: string;
  role: string;
  birthday: string;
  gender: string;
  regionId: string;
  municipalityId: string;
  city: string;
  schoolId: string;
  tag: string;
  needToUpdate: true;
}

export interface IUserMurmanskLoginData {
  role: string;
  userId: string;
  tag: string;
  isUnRegistered: boolean;
  succeeded: boolean;
  isLockedOut: boolean;
  isNotAllowed: boolean;
  requiresTwoFactor: boolean;
}

export interface IProsvetUserData {
  userId: string;
  externalAppUserId?: string;
  email?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  role?: string;
  schoolId?: string;
  municipalityId?: string;
  regionId?: string;
  birthday?: string;
  gender?: string;
  city?: string;
  tag?: string;
  status?: string;
  comment?: string;
  needToUpdate?: boolean;
}

export type IData = Record<string, any>;

export interface IUserAction extends IData {
  /**
   * string($date-time) - время события с часовым поясом (ISO 8601)
   */
  timestamp: string;

  /**
   * Profilum.Services.Events.Dtos.UserActionKindstringEnum
   */
  kind: UserActionKind;

  /**
   * Profilum.Services.Events.Dtos.UserActionResultstringEnum
   */
  result: UserActionResult;

  /**
   * string($uuid) - уникальный UUID события, сгенерированный по алгоритму версии 4 (RFC 4122)
   */
  id?: string;

  /**
   * string($uuid) - UUID пользователя, если применимо к типу события
   * nullable: true
   */
  userId?: string;

  /**
   * string - IP-адрес клиента
   * nullable: true
   * example: 127.0.0.1
   * Если не задан, то значение автоматически извлечется на стороне сервера
   */
  clientIPAddress?: string;

  /**
   * string - ИД сессии браузера (не сессия теста)
   * nullable: true
   */
  sessionId?: string;

  /**
   * string - Длительность события, если событие протяженное по времени, в формате (d.)hh:mm:ss(.ffffff) - в скобках необязательные части
   * nullable: true
   * example: 00:00:00
   */
  duration?: string;

  /**
   * string - Страница, на которой произошло событие
   * nullable: true
   */
  ReferrerPage?: string;
}

export interface IBvbRegisterData {
  userId: string;
  userHrid: number;
  password: string;
  email: string;
  status: string;
  comment: string;
  senderService: string;
}

export interface IJwtRegisterData {
  userId: string;
  userHrid: number;
  password: string;
  email: string;
  tag: string;
  status: string;
  comment: string;
  senderService: string;
}

export interface IJwtLoginData {
  isLockedOut: boolean;
  isNotAllowed: boolean;
  isUnRegistered: boolean;
  requiresTwoFactor: boolean;
  role: string;
  succeeded: boolean;
  tag: string;
  userId: string;
}

export interface IIsEmailFreeResponse extends IHttpStatus {
  isFree: false;
}
