import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UniversitiesService } from './universities.service';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { UniversitiesComponent } from './universities.component';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'app/shared/modules/material.module';
import { SharedModule } from 'app/shared/shared.module';
import { AllUniversitiesComponent } from './all-universities/all-universities.component';
import { EditUniversityComponent } from './edit-university/edit-university.component';
import { AddUniversityComponent } from './add-university/add-university.component';
import { UniversityCardComponent } from './all-universities/university-card/university-card.component';
import { UniversityDetailsComponent } from './all-universities/university-details/university-details.component';

@NgModule({
  declarations: [
    UniversitiesComponent,
    AllUniversitiesComponent,
    EditUniversityComponent,
    AddUniversityComponent,
    UniversityCardComponent,
    UniversityDetailsComponent,
  ],
  imports: [CommonModule, BrowserModule, RouterModule, TranslateModule, MaterialModule, SharedModule],
  providers: [UniversitiesService],
})
export class UniversitiesModule {}
