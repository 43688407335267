import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { AdminPanelService } from 'app/pages/control-panel/admin/admin-panel.service';
import {
  CoursesFiltersService,
} from 'app/shared/dashboard/courses/courses-catalog/courses-filter/courses-filters.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { saveAs } from 'file-saver';
import { IFilterClasses } from 'app/shared/interfaces/ifilterclasses.interface';

@Component({
  selector: 'prf-all-courses',
  templateUrl: './all-courses.component.html',
  styleUrls: ['./all-courses.component.scss'],
})
export class AllCoursesComponent implements OnInit {
  public ActiveArchive: number = 1;
  filters: IFilterClasses;
  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(private meta: Meta, private filtersService: CoursesFiltersService, private adminPanelService: AdminPanelService) {
    this.meta.updateTag({ name: 'og:title', content: 'Все курсы' });
  }

  ngOnInit() {
    this.filtersService.getCoursesFilters().subscribe(data => {
      this.filters = data;
    });
  }

  downloadClassesCatalog() {
    this.adminPanelService
      .downloadCatalogExcel(this.filters.regionId, 'Classes')
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(response => {
        const blob = new Blob([response.body], { type: response.headers.get('content-type') });
        var fileName = this.parseFilenameFromContentDisposition(response.headers.get('content-disposition'));
        const file = new File([blob], fileName, { type: response.headers.get('content-type') });
        saveAs(file);
      });
  }

  private parseFilenameFromContentDisposition(contentDisposition) {
    if (!contentDisposition) return null;
    let matches = /filename="(.*?)"/g.exec(contentDisposition);
    return matches && matches.length > 1 ? matches[1] : 'untitled';
  }

  canDownloadCatalog() {
    let userRole = localStorage.getItem('userRole');
    return userRole === 'admin' ? true : false;
  }
}
