export function Path(commands) {
  this.commands = commands;
}

Path.prototype.toString = function () {
  return this.commands.join('');
};

Path.M = function (point): void {
  this.point = point;
};

Path.M.prototype.toString = function (): string {
  return 'M' + this.point.x + ' ' + this.point.y;
};

Path.L = function (to): void {
  this.to = to;
};

Path.L.prototype.toString = function (): string {
  return 'L' + this.to.x + ' ' + this.to.y;
};

Path.Q = function (control, to): void {
  this.control = control;
  this.to = to;
};

Path.Q.prototype.toString = function (): string {
  return 'Q' + this.control.x + ' ' + this.control.y + ' ' + this.to.x + ' ' + this.to.y;
};

Path.Z = function (): void {};

Path.Z.prototype.toString = function (): string {
  return 'Z';
};

export function Vector(x, y): void {
  this.x = x;
  this.y = y;
}

Vector.fromAngle = function (angle, magnitude) {
  if (typeof magnitude !== 'number') {
    magnitude = 1;
  }
  return new Vector(magnitude * Math.cos(angle), magnitude * Math.sin(angle));
};
