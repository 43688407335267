import { YmEventName, YmEventType, YmItems } from '../yandex-metrics';
import { IData, UserActionKind, UserActionResult } from '../../api-services';

export const UaParamsByYmItem: Map<YmItems, IData> = new Map<YmItems, IData>([
  [
    // Переход в карточку из списка профессий раздела профессий
    YmItems.S_Professions_ProfessionsCard,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      EventDescription: 'Переход в карточку из списка профессий раздела профессий',
      GoalsYandexMetrica: YmItems.S_Professions_ProfessionsCard,
      EventName: YmEventName.ViewProfessionPage,
    },
  ],
  [
    // Переход в карточку из списка избранного учеником:
    YmItems.S_Favorites_ProfessionsCard,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      EventDescription: 'Переход в карточку из списка избранных профессий',
      GoalsYandexMetrica: YmItems.S_Favorites_ProfessionsCard,
    },
  ],
  [
    // Переход на страницу профессии
    YmItems.S_ProfessionsPage,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      EventDescription: 'Просмотр страницы каталога профессий',
      GoalsYandexMetrica: YmItems.S_ProfessionsPage,
    },
  ],
  [
    // Нажатие кнопки понравилось в Тиндере профессий:
    YmItems.S_lessons_Exercise_Like,
    {
      kind: UserActionKind.SetLike,
      result: UserActionResult.Success,
      EventName: YmEventName.LikingProfessionTinder,
      GoalsYandexMetrica: YmItems.S_lessons_Exercise_Like,
    },
  ],
  [
    // Нажатие кнопки не понравилось в Тиндере профессий:
    YmItems.S_lessons_Exercise_NotLike,
    {
      kind: UserActionKind.UnsetLike,
      result: UserActionResult.Success,
      EventName: YmEventName.LikingProfessionTinder,
      GoalsYandexMetrica: YmItems.S_lessons_Exercise_NotLike,
    },
  ],
  [
    // Нажатие кнопки вернуться в Тиндере профессий:
    YmItems.S_lessons_Exercise_Back,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      EventName: YmEventName.BackButtonProfessionTinder,
      GoalsYandexMetrica: YmItems.S_lessons_Exercise_Back,
      EventDescription: 'Нажатие кнопки "назад" в Тиндере профессий',
    },
  ],
  [
    // Отдельно завершения задания как цели:
    YmItems.S_lessons_CompleteExercise,
    {
      kind: UserActionKind.CompleteTask,
      result: UserActionResult.Success,
      EventName: YmEventName.CompleteExercise,
      GoalsYandexMetrica: YmItems.S_lessons_CompleteExercise,
      EventDescription: 'Завершение задания как цели',
    },
  ],
  [
    // Нажатие Лайк при выполнении задания
    YmItems.S_lessons_Likes,
    {
      kind: UserActionKind.SetLike,
      result: UserActionResult.Success,
      GoalsYandexMetrica: YmItems.S_lessons_Likes,
      EventName: YmEventName.LikingProfession,
      EventDescription: 'Нажатие Лайк',
    },
  ],
  [
    // Нажатие Лайк в раздели профессии в списке профессий
    YmItems.S_Professions_Likes,
    {
      kind: UserActionKind.SetLike,
      result: UserActionResult.Success,
      GoalsYandexMetrica: YmItems.S_Professions_Likes,
      EventName: YmEventName.LikingProfession,
      EventDescription: 'Нажатие Лайк',
    },
  ],
  [
    // Нажатие Лайк в карточке профессий
    YmItems.S_ProfessionsCard_Likes,
    {
      kind: UserActionKind.SetLike,
      result: UserActionResult.Success,
      GoalsYandexMetrica: YmItems.S_ProfessionsCard_Likes,
      EventName: YmEventName.LikingProfession,
      EventDescription: 'Нажатие Лайк',
    },
  ],
  [
    // Нажатие Лайк в разделе избранное в профиле ученика
    YmItems.S_Favorites_Likes,
    {
      kind: UserActionKind.SetLike,
      result: UserActionResult.Success,
      GoalsYandexMetrica: YmItems.S_Favorites_Likes,
      EventName: YmEventName.LikingProfession,
      EventDescription: 'Нажатие Лайк',
    },
  ],
  [
    // Переход по категориям  отобранных профессий в подразделе “Выбор” раздела “Результат”
    YmItems.T_Results_Industries,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      EventDescription: 'Переход к категориям отобранных профессий',
      GoalsYandexMetrica: 'T_Results_Industries',
    },
  ],
  [
    // Раскрытие списков выборов учеников в подразделе “Выборы” раздела “Результаты”.
    YmItems.T_Results_AllSelections,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      EventDescription: 'Раскрытие списков выборов учеников',
      GoalsYandexMetrica: 'T_Results_AllSelections',
    },
  ],
  [
    // Кнопка перехода к профессиям, отфильтрованным по интересам ученика
    YmItems.S_Interests_ViewProfessions,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      GoalsYandexMetrica: YmItems.S_Interests_ViewProfessions,
      EventName: YmEventName.TransitionToProfessionsFiltered,
      EventDescription: 'Переход к отфильтрованным профессиям по интересам',
      Filter: 'Interests',
    },
  ],
  [
    // Кнопка перехода к профессиям, отфильтрованным по способностям ученика
    YmItems.S_Aptitudes_ViewProfessions,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      GoalsYandexMetrica: YmItems.S_Aptitudes_ViewProfessions,
      EventName: YmEventName.TransitionToProfessionsFiltered,
      EventDescription: 'Переход к отфильтрованным профессиям по способностям',
      Filter: 'Aptitudes',
    },
  ],
  [
    // Переход на вкладку "Избранное" в профиле ученика
    YmItems.S_Profile_Favorites,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      GoalsYandexMetrica: YmItems.S_Profile_Favorites,
      EventName: YmEventName.TransitionToProfileItem,
      EventDescription: "Переход к вкладке 'Избранное' в профиле пользователя.",
    },
  ],
  [
    // Переход на вкладку "Интересы" в профиле ученика
    YmItems.S_Profile_Interests,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      GoalsYandexMetrica: YmItems.S_Profile_Interests,
      EventName: YmEventName.TransitionToProfileItem,
      EventDescription: "Переход к вкладке 'Интересы' в профиле пользователя.",
    },
  ],
  [
    // Переход на вкладку "Способности" в профиле ученика
    YmItems.S_Profile_Aptitude,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      GoalsYandexMetrica: YmItems.S_Profile_Aptitude,
      EventName: YmEventName.TransitionToProfileItem,
      EventDescription: "Переход к вкладке 'Способности' в профиле пользователя.",
    },
  ],
  [
    // Переход в раздел 'Задания' ученика
    YmItems.S_lessons,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      GoalsYandexMetrica: YmItems.S_lessons,
      EventName: YmEventName.TransitionToMenuItem,
      EventDescription: "Переход в раздел 'Задания' ученика",
    },
  ],
  [
    // Переход в раздел 'Профиль' ученика
    YmItems.S_profile,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      GoalsYandexMetrica: YmItems.S_profile,
      EventName: YmEventName.TransitionToMenuItem,
      EventDescription: "Переход в раздел 'Профиль' ученика",
    },
  ],
  [
    // Переход в раздел 'Профессии' ученика
    YmItems.S_Professions,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      GoalsYandexMetrica: YmItems.S_Professions,
      EventName: YmEventName.TransitionToMenuItem,
      EventDescription: "Переход в раздел 'Профессии' ученика",
    },
  ],
  [
    // Ученик. Переход на страницу регистрации.
    YmItems.Pu_Reg_Hellow_OpenPage,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      GoalsYandexMetrica: YmItems.Pu_Reg_Hellow_OpenPage,
      EventName: YmEventName.StudentsRegistrationPageTransition,
      EventDescription: 'Ученик. Переход на страницу регистрации',
    },
  ],
  [
    // Ученик. Нажатие кнопки "Войти" в окне "Приветствия нового ученика"
    YmItems.Pu_Reg_Hellow_ClickEnterButton,
    {
      kind: UserActionKind.Register,
      result: UserActionResult.Success,
      GoalsYandexMetrica: YmItems.Pu_Reg_Hellow_ClickEnterButton,
      EventName: YmEventName.StudentsRegistrationEnterButtonClick,
      EventDescription: 'Ученик. Нажатие кнопки "Войти" в окне "Приветствия нового ученика"',
    },
  ],
  [
    // Ученик. Нажатие кнопки "Продолжить" в окне  "Придумай пароль"
    YmItems.Pu_Reg_CreatePas_ClickEnterButton,
    {
      kind: UserActionKind.ChangePassword,
      result: UserActionResult.Success,
      GoalsYandexMetrica: YmItems.Pu_Reg_CreatePas_ClickEnterButton,
      EventName: YmEventName.StudentsRegistrationContinueButtonClick,
      EventDescription: 'Ученик. Регистрация. Нажатие кнопки "Продолжить" в окне  "Придумай пароль"',
    },
  ],
  [
    // Ученик. Нажатие кнопки "Начнем!" в окне  "Начала введения"
    YmItems.Pu_Reg_Introduction_ClickEnterButton,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      GoalsYandexMetrica: YmItems.Pu_Reg_Introduction_ClickEnterButton,
      EventName: YmEventName.StudentsRegistrationStartButtonClick,
      EventDescription: 'Ученик. Регистрация. Нажатие кнопки "Начнем!" в окне  "Начала введения"',
    },
  ],
  [
    // Ученик. Нажатие кнопки "Данные не верны" в окне "Приветствия нового ученика"
    YmItems.Pu_Reg_Hellow_DataNotCorrect,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      GoalsYandexMetrica: YmItems.Pu_Reg_Hellow_DataNotCorrect,
      EventName: YmEventName.StudentsRegistrationIncorrectDataButtonClick,
      EventDescription: 'Ученик. Нажатие кнопки "Данные не верны" в окне "Приветствия нового ученика"',
    },
  ],
  [
    // Переход на страницу 'Выборы' через меню навигации
    YmItems.T_Results_Selections,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      GoalsYandexMetrica: YmItems.T_Results_Selections,
      EventName: YmEventName.TransitionToSelections,
      EventDescription: 'Переход на страницу "Выборы" через меню навигации',
    },
  ],
  [
    // Переход на страницу 'Журнал' через меню навигации
    YmItems.T_Results_Register,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      GoalsYandexMetrica: YmItems.T_Results_Register,
      EventName: YmEventName.TransitionToSelections,
      EventDescription: 'Переход на страницу "Журнал" через меню навигации',
    },
  ],
  [
    // Переход на страницу 'Диагноста' через меню навигации
    YmItems.T_Results_Diagnostics,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      GoalsYandexMetrica: YmItems.T_Results_Diagnostics,
      EventName: YmEventName.TransitionToSelections,
      EventDescription: 'Переход на страницу "Диагноста" через меню навигации',
    },
  ],
  [
    // Переход в карточку профессии из раздела “Профессии”
    YmItems.T_Professions_ProfessionCard,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      GoalsYandexMetrica: YmItems.T_Professions_ProfessionCard,
      EventDescription: 'Считаем число переходов в карточку профессии из раздела Профессии',
    },
  ],
  [
    // Выдача заданий связанных с прошедшим занятием ученикам
    YmItems.T_Lessons_AccessToLesson,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      GoalsYandexMetrica: YmItems.T_Lessons_AccessToLesson,
      EventDescription: 'Выдача заданий, связанных с прошедшим занятием',
      EventName: YmEventName.AssigningTasks,
    },
  ],
  [
    // Открытие конспектов урока
    YmItems.T_Lessons_Open,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      GoalsYandexMetrica: YmItems.T_Lessons_Open,
      EventDescription: 'Открытие конспектов урока',
      EventName: YmEventName.OpeningLesson,
    },
  ],
  [
    // Скачивание материалов урока
    YmItems.T_Lessons_Download,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      GoalsYandexMetrica: YmItems.T_Lessons_Download,
      EventDescription: 'Скачивание материалов урока',
      EventName: YmEventName.DownloadingLesson,
    },
  ],
  [
    // Копия приглашения для родителей на регистрацию для их детей в пустой класс.
    YmItems.T_Class_InvitationInEmptyClass,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      GoalsYandexMetrica: YmItems.T_Class_InvitationInEmptyClass,
      EventName: YmEventName.CopyingInvitation,
      EventDescription: 'Копирование приглашения на панели "Приглашение для родителей"',
    },
  ],
  [
    // Копировать приглашение родителям для учеников
    YmItems.T_Class_invitationInNotEmptyClass,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      GoalsYandexMetrica: YmItems.T_Class_invitationInNotEmptyClass,
      EventName: YmEventName.CopyingInvitation,
      EventDescription: 'Копирование приглашения на панели "Кого-то не хватает"',
    },
  ],
  [
    // Нажатие кнопки “Редактировать” профиле
    YmItems.T_Profile_EditButton,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      EventDescription: 'Нажатие кнопки “Редактировать” в профиле',
      GoalsYandexMetrica: YmItems.T_Profile_EditButton,
    },
  ],
  [
    // Нажатие кнопки “Сохранить” профиле
    YmItems.T_Profile_SaveChanges,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      EventDescription: 'Нажатие кнопки сохранить изменения в профиле',
      GoalsYandexMetrica: YmItems.T_Profile_SaveChanges,
    },
  ],
  [
    // Учитель. Регистрация. Нажатие кнопки "Войти" в окне "Приветствия нового учителя"
    YmItems.T_Reg_Hello_ClickEnterButton,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      EventDescription: 'Учитель. Регистрация. Нажатие кнопки "Войти" в окне "Приветствия нового учителя"',
      GoalsYandexMetrica: YmItems.T_Reg_Hello_ClickEnterButton,
      EventName: YmEventName.TeacherRegistrationEnterButtonClick,
    },
  ],
  [
    // Учитель. Регистрация. Нажатие кнопки "Подтвердить" в окне "Подтверждения номера телефона".
    YmItems.T_Reg_Confirm_ClickConfirmButton,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      EventDescription: 'Учитель. Регистрация. Нажатие кнопки "Подтвердить" в окне "Подтверждения номера телефона".',
      GoalsYandexMetrica: YmItems.T_Reg_Confirm_ClickConfirmButton,
      EventName: YmEventName.TeacherRegistrationConfirmButtonClick,
    },
  ],
  [
    // Учитель. Регистрация. Успешная проверка валидации пароля в окне "Придумай пароль".
    YmItems.T_Reg_CreatePas_PasswordValidation,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      EventDescription: 'Учитель. Регистрация. Успешная проверка валидации пароля в окне "Придумай пароль"',
      GoalsYandexMetrica: YmItems.T_Reg_CreatePas_PasswordValidation,
      EventName: YmEventName.TeacherRegistrationPasswordValidation,
    },
  ],
  [
    // Учитель. Нажатие кнопки. "Это не я или данные не верны".
    YmItems.T_Reg_Hellow_NotMeButton,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      EventDescription: 'Нажатие кнопки. "Это не я или данные не верны".',
      GoalsYandexMetrica: YmItems.T_Reg_Hellow_NotMeButton,
      EventName: YmEventName.TeacherRegistrationNotMeButtonClick,
    },
  ],
  [
    // Учитель. Регистрация. Нажатие кнопки "Видимый пароль".
    YmItems.T_Reg_CreatePas_PasswordVisibility,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      EventDescription: 'Учитель. Регистрация. Нажатие кнопки "Видимый пароль".',
      GoalsYandexMetrica: YmItems.T_Reg_CreatePas_PasswordVisibility,
      EventName: YmEventName.TeacherRegistrationPasswordVisibilityButtonClick,
    },
  ],
  [
    // Учитель. Регистрация. Нажатие кнопки "Указать другой номер"
    YmItems.T_Reg_Confirm_AnotherNumber,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      EventDescription: 'Учитель. Регистрация. Нажатие кнопки "Указать другой номер"',
      GoalsYandexMetrica: YmItems.T_Reg_Confirm_AnotherNumber,
      EventName: YmEventName.TeacherRegistrationAnotherNumberButtonClick,
    },
  ],
  [
    // Переход в раздел 'Занятия'
    YmItems.T_Lessons,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      EventDescription: "Переход в раздел 'Занятия'",
      GoalsYandexMetrica: YmItems.T_Lessons,
      EventName: YmEventName.TransitionToLessons,
    },
  ],
  [
    // Переход в раздел 'Ученики'
    YmItems.T_Class,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      EventDescription: "Переход в раздел 'Ученики'",
      GoalsYandexMetrica: YmItems.T_Class,
      EventName: YmEventName.TransitionToStudents,
    },
  ],
  [
    // Переход в раздел 'Результаты'
    YmItems.T_Results,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      EventDescription: "Переход в раздел 'Результаты'",
      GoalsYandexMetrica: YmItems.T_Results,
      EventName: YmEventName.TransitionToResults,
    },
  ],
  [
    // Переход в раздел 'Профессии'
    YmItems.T_Professions,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      EventDescription: "Переход в раздел 'Профессии'",
      GoalsYandexMetrica: YmItems.T_Professions,
      EventName: YmEventName.TransitionToProfessions,
    },
  ],
  [
    // Переходы между классами
    YmItems.T_SelectClass,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      EventDescription: 'Переход между классами',
      GoalsYandexMetrica: YmItems.T_SelectClass,
      EventName: YmEventName.ClassTransition,
    },
  ],
  [
    // Родитель. Регистрация. Переход на страницу регистрации.
    YmItems.Pa_Reg_Hello_OpenPage,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      EventDescription: 'Родитель. Регистрация. Переход на страницу регистрации.',
      GoalsYandexMetrica: YmItems.Pa_Reg_Hello_OpenPage,
      EventName: YmEventName.ParentRegistrationPageTransition,
    },
  ],
  [
    // Родитель. Нажатие кнопки "Продолжить" в окне "Приветствия нового родителя".
    YmItems.Pa_Reg_Hello_ClickEnterButton,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      EventDescription: 'Родитель. Регистрация. Нажатие кнопки "Продолжить" в окне "Приветствия нового родителя"',
      GoalsYandexMetrica: YmItems.Pa_Reg_Hello_ClickEnterButton,
      EventName: YmEventName.ParentRegistrationHelloContinueButtonClick,
    },
  ],
  [
    // Родитель. Регистрация. Нажатие кнопки "Продолжить" в окне "Введите данные родителя".
    YmItems.Pa_Reg_ParentDetails_ClickEnterButton,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      EventDescription: 'Родитель. Регистрация. Нажатие кнопки "Продолжить" в окне "Введите данные родителя"',
      GoalsYandexMetrica: YmItems.Pa_Reg_ParentDetails_ClickEnterButton,
      EventName: YmEventName.ParentRegistrationParentDetailsContinueButtonClick,
    },
  ],
  [
    // Родитель. Регистрация. Нажатие кнопки "Продолжить" в окне "Введите данные подростка".
    YmItems.Pa_Reg_PupilData_ClickEnterButton,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      EventDescription: 'Родитель. Регистрация. Нажатие кнопки "Продолжить" в окне "Введите данные подростка".',
      GoalsYandexMetrica: YmItems.Pa_Reg_PupilData_ClickEnterButton,
      EventName: YmEventName.ParentRegistrationEnterYourTeensDetails,
    },
  ],
  [
    // Родитель. Регистрация. Нажатие кнопки "Продолжить" в окне "Проверьте данные"
    YmItems.Pa_Reg_CheckData_ClickEnterButton,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      EventDescription: 'Родитель. Регистрация. Нажатие кнопки "Продолжить" в окне "Проверьте данные"',
      GoalsYandexMetrica: YmItems.Pa_Reg_CheckData_ClickEnterButton,
      EventName: YmEventName.ParentRegistrationCheckTheData,
    },
  ],
  [
    // Родитель. Регистрация. Нажатие галочки "Получать уведомления".
    YmItems.Pa_Reg_ParentData_NewsletterConsent,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      EventDescription: 'Родитель. Регистрация. Нажатие галочки "Получать уведомления"',
      GoalsYandexMetrica: YmItems.Pa_Reg_ParentData_NewsletterConsent,
      EventName: YmEventName.ParentRegistrationNewsletterConsentCheckboxClick,
    },
  ],
  [
    // Родитель. Регистрация. Нажатие кнопки "Данные не верны" Страница приветствия.
    YmItems.Pa_Reg_Hello_DataIsNotCorrect,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      EventDescription: 'Родитель. Регистрация. Нажатие кнопки "Данные не верны" Страница приветствия.',
      GoalsYandexMetrica: YmItems.Pa_Reg_Hello_DataIsNotCorrect,
      EventName: YmEventName.ParentRegistrationHello,
    },
  ],
  [
    // Родитель. Регистрация. Нажатие кнопки "Редактировать" данные.
    YmItems.Pa_Reg_DataCheck_Edit,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      EventDescription: 'Родитель. Регистрация. Нажатие кнопки "Редактировать" данные.',
      GoalsYandexMetrica: YmItems.Pa_Reg_DataCheck_Edit,
      EventName: YmEventName.ParentRegistrationEditButtonClick,
    },
  ],
  [
    // Всплытие оповещения о наличии функционала СПО ВО
    YmItems.S_Profile_SPOVO_Open,
    {
      kind: UserActionKind.NotificationView,
      result: UserActionResult.Success,
      EventDescription: 'Всплытие оповещения о наличии функционала СПО ВО.',
      EventName: YmEventName.SpoVo,
      GoalsYandexMetrica: YmItems.S_Profile_SPOVO_Open,
    },
  ],
  [
    // Клик на кнопку “Пойдем посмотрим! СПО ВО
    YmItems.S_Profile_SPOVO_Lets_See,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      EventDescription: 'Клик на кнопку “Пойдем посмотрим! СПО ВО',
      EventName: YmEventName.SpoVo,
      GoalsYandexMetrica: YmItems.S_Profile_SPOVO_Lets_See,
    },
  ],
  [
    // Клик на кнопку “Посмотреть”, во всплывающем окне. СПО ВО
    YmItems.S_Profile_SPOVO_To_See,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      EventDescription: 'Клик на кнопку “Посмотреть”, во всплывающем окне. СПО ВО',
      EventName: YmEventName.SpoVo,
      GoalsYandexMetrica: YmItems.S_Profile_SPOVO_To_See,
    },
  ],
  [
    // Клик на пункт рекомендованной программы обучения. СПО ВО
    YmItems.S_Profile_SPOVO_Item,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      EventDescription: 'Клик на пункт рекомендованной программы обучения. СПО ВО',
      EventName: YmEventName.SpoVo,
      GoalsYandexMetrica: YmItems.S_Profile_SPOVO_Item,
    },
  ],
  [
    // Нажатие кнопок фильтров: ВСЕ, ВО, СПО.
    YmItems.S_Profile_SPOVO_Filter,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      EventDescription: 'Нажатие кнопок фильтров: ВСЕ, ВО, СПО.',
      EventName: YmEventName.SpoVo,
      GoalsYandexMetrica: YmItems.S_Profile_SPOVO_Filter,
    },
  ],
  [
    // Заход ученика на страницу диагностического теста
    YmItems.S_Lessons_Diagnostics_Open,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      EventDescription: 'Заход ученика на страницу диагностического теста',
      GoalsYandexMetrica: YmItems.S_Lessons_Diagnostics_Open,
      EventName: YmEventName.DiagnosticOpen,
    },
  ],
  [
    // Выбор на странице с "Многими ответами"
    YmItems.S_Lessons_Diagnostics_ManyAnswers_Select,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      EventDescription: 'Выбор на странице с "Многими ответами"',
      GoalsYandexMetrica: YmItems.S_Lessons_Diagnostics_ManyAnswers_Select,
      EventName: YmEventName.DiagnosticManyAnswersSelect,
    },
  ],
  [
    // Снять отметку выбранности на странице с "Многими ответами"
    YmItems.S_Lessons_Diagnostics_ManyAnswers_Unselect,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      EventDescription: 'Снять отметку выбранности  на странице с "Многими ответами"',
      GoalsYandexMetrica: YmItems.S_Lessons_Diagnostics_ManyAnswers_Unselect,
      EventName: YmEventName.DiagnosticManyAnswersUnselect,
    },
  ],
  [
    // Отправка открытого ответа
    YmItems.S_Lessons_Diagnostics_OpenAnswer,
    {
      kind: UserActionKind.CompleteTask,
      result: UserActionResult.Success,
      EventDescription: 'Отправка открытого ответа',
      GoalsYandexMetrica: YmItems.S_Lessons_Diagnostics_OpenAnswer,
      EventName: YmEventName.DiagnosticOpenAnswer,
    },
  ],
  [
    // Выбор ответа на вопрос через позицию в скролл баре
    YmItems.S_Lessons_Diagnostics_SkrollBarAnswer,
    {
      kind: UserActionKind.CompleteTask,
      result: UserActionResult.Success,
      EventDescription: 'Выбор ответа на вопрос через позицию в скролл баре',
      GoalsYandexMetrica: YmItems.S_Lessons_Diagnostics_SkrollBarAnswer,
      EventName: YmEventName.DiagnosticScrollBarAnswer,
    },
  ],
  [
    // Нажатие "ДАЛЕЕ" на странице диагностического текста
    YmItems.S_Lessons_Diagnostics_NextButton,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      EventDescription: 'Нажатие кнопки "Далее" в диагностическом тесте',
      GoalsYandexMetrica: YmItems.S_Lessons_Diagnostics_NextButton,
      EventName: YmEventName.DiagnosticNextButton,
    },
  ],
  [
    // Нажатие "НАЗАД" на странице диагностического текста
    YmItems.S_Lessons_Diagnostics_BackButton,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      EventDescription: 'Нажатие кнопки "Назад" в диагностическом тесте',
      GoalsYandexMetrica: YmItems.S_Lessons_Diagnostics_BackButton,
      EventName: YmEventName.DiagnosticBackButton,
    },
  ],
  [
    // Нажатие кнопки "Крестик" в диагностическом тесте и выход из теста
    YmItems.S_Lessons_Diagnostics_CrossButton,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      EventDescription: 'Нажатие кнопки "Крестик" в диагностическом тесте и выход из теста',
      GoalsYandexMetrica: YmItems.S_Lessons_Diagnostics_CrossButton,
      EventName: YmEventName.DiagnosticCrossButton,
    },
  ],
  [
    // Завершение Диагностического теста
    YmItems.S_Lessons_Diagnostics_Done,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      EventDescription: 'Завершение Диагностического теста',
      GoalsYandexMetrica: YmItems.S_Lessons_Diagnostics_Done,
      EventName: YmEventName.DiagnosticDone,
    },
  ],
  [
    // Переход на страницы профессий со страницы результатов входного теста КГ
    YmItems.S_Lessons_DiagnosticsKG_Result_ProfessionButton,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      EventDescription: 'Переход на страницы профессий со страницы результатов входного теста',
      GoalsYandexMetrica: YmItems.S_Lessons_DiagnosticsKG_Result_ProfessionButton,
      EventName: YmEventName.DiagnosticProfessionsRedirect,
    },
  ],
  [
    // Перенос мини карточки профессии в слот
    YmItems.S_Lessons_Diagnostics_DragMiniCard,
    {
      kind: UserActionKind.Unknown,
      result: UserActionResult.Success,
      EventDescription: 'Перенос мини карточки профессии в слот',
      GoalsYandexMetrica: YmItems.S_Lessons_Diagnostics_DragMiniCard,
      EventName: YmEventName.TryingProfession,
    },
  ],
  [
    // Открытие карточки профессии во время "Примерки"
    YmItems.S_Lessons_Diagnostics_EnteringProfessionCard,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      EventDescription: 'Открытие карточки профессии во время "Примерки"',
      GoalsYandexMetrica: YmItems.S_Lessons_Diagnostics_EnteringProfessionCard,
      EventName: YmEventName.TryingProfession,
    },
  ],
  [
    // Открытие чат-бота
    YmItems.S_ChatBot_Open,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      EventDescription: 'Заход ученика на страницу с чат ботом',
      GoalsYandexMetrica: YmItems.S_ChatBot_Open,
      EventName: YmEventName.ChatBotOpen,
    },
  ],
  [
    // Завершение чат-бота
    YmItems.S_ChatBot_Complete,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      EventDescription: 'Завершение общения чат ботом и закрытие диалога с чат ботом',
      GoalsYandexMetrica: YmItems.S_ChatBot_Complete,
      EventName: YmEventName.ChatBot_Complete,
    },
  ],
  [
    // Нажатие кнопки на странице открытого чат бота
    YmItems.S_ChatBot_PressButton,
    {
      kind: UserActionKind.OpenResource,
      result: UserActionResult.Success,
      EventDescription: 'Нажатие кнопки на странице открытого чат бота',
      GoalsYandexMetrica: YmItems.S_ChatBot_PressButton,
      EventName: YmEventName.ChatBot_PressButton,
    },
  ],
]);

export const YmParamsByYmItems: Map<YmItems, IData> = new Map<YmItems, IData>([
  [
    // Переход в карточку из списка профессий раздела профессий
    YmItems.S_Professions_ProfessionsCard,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.ViewProfessionPage,
      event_action: YmItems.S_Professions_ProfessionsCard,
      event_label: YmItems.None,
    },
  ],
  [
    // Переход в карточку из списка избранного учеником:
    YmItems.S_Favorites_ProfessionsCard,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.ViewProfessionPage,
      event_action: YmItems.S_Favorites_ProfessionsCard,
      event_label: YmItems.None,
    },
  ],
  [
    // Переход на страницу профессии
    YmItems.S_ProfessionsPage,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.ViewProfessionPage,
      event_action: YmItems.S_ProfessionsPage,
      event_label: YmItems.None,
    },
  ],
  [
    // Нажатие кнопки понравилось в Тиндере профессий:
    YmItems.S_lessons_Exercise_Like,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.LikingProfessionTinder,
      event_action: YmItems.S_lessons_Exercise_Like,
    },
  ],
  [
    // Нажатие кнопки не понравилось в Тиндере профессий:
    YmItems.S_lessons_Exercise_NotLike,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.LikingProfessionTinder,
      event_action: YmItems.S_lessons_Exercise_NotLike,
    },
  ],
  [
    // Нажатие кнопки вернуться в Тиндере профессий:
    YmItems.S_lessons_Exercise_Back,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.BackButtonProfessionTinder,
      event_action: YmItems.S_lessons_Exercise_Back,
    },
  ],
  [
    // Отдельно завершения задания как цели:
    YmItems.S_lessons_CompleteExercise,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.CompleteExercise,
      event_action: YmItems.S_lessons_CompleteExercise,
    },
  ],
  [
    // Нажатие Лайк при выполнении задания
    YmItems.S_lessons_Likes,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.LikingProfession,
      event_action: YmItems.S_lessons_Likes,
    },
  ],
  [
    // Нажатие Лайк в раздели профессии в списке профессий
    YmItems.S_Professions_Likes,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.LikingProfession,
      event_action: YmItems.S_Professions_Likes,
    },
  ],
  [
    // Нажатие Лайк в карточке профессий
    YmItems.S_ProfessionsCard_Likes,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.LikingProfession,
      event_action: YmItems.S_ProfessionsCard_Likes,
    },
  ],
  [
    // Нажатие Лайк в разделе избранное в профиле ученика
    YmItems.S_Favorites_Likes,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.LikingProfession,
      event_action: YmItems.S_Favorites_Likes,
    },
  ],
  [
    // Переход по категориям  отобранных профессий в подразделе “Выбор” раздела “Результат”
    YmItems.T_Results_Industries,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.TransitionToCategories,
      event_action: YmItems.T_Results_Industries,
    },
  ],
  [
    // Раскрытие списков выборов учеников в подразделе “Выборы” раздела “Результаты”.
    YmItems.T_Results_AllSelections,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.SelectionsExpansion,
      event_action: YmItems.T_Results_AllSelections,
    },
  ],
  [
    // Кнопка перехода к профессиям, отфильтрованным по интересам ученика
    YmItems.S_Interests_ViewProfessions,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.TransitionToProfessionsFiltered,
      event_action: YmItems.S_Interests_ViewProfessions,
      event_label: 'Interests',
    },
  ],
  [
    // Кнопка перехода к профессиям, отфильтрованным по способностям ученика
    YmItems.S_Aptitudes_ViewProfessions,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.TransitionToProfessionsFiltered,
      event_action: YmItems.S_Aptitudes_ViewProfessions,
      event_label: 'Aptitudes',
    },
  ],
  [
    // Переход на вкладку "Избранное" в профиле ученика
    YmItems.S_Profile_Favorites,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.TransitionToProfileItem,
      event_action: YmItems.S_Profile_Favorites,
      event_label: 'Favorites',
    },
  ],
  [
    // Переход на вкладку "Интересы" в профиле ученика
    YmItems.S_Profile_Interests,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.TransitionToProfileItem,
      event_action: YmItems.S_Profile_Interests,
      event_label: 'Interests',
    },
  ],
  [
    // Переход на вкладку "Способности" в профиле ученика
    YmItems.S_Profile_Aptitude,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.TransitionToProfileItem,
      event_action: YmItems.S_Profile_Aptitude,
      event_label: 'Aptitude',
    },
  ],
  [
    // Переход в раздел 'Задания' ученика
    YmItems.S_lessons,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.TransitionToMenuItem,
      event_action: YmItems.S_lessons,
      event_label: 'lessons',
    },
  ],
  [
    // Переход в раздел 'Профиль' ученика
    YmItems.S_profile,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.TransitionToMenuItem,
      event_action: YmItems.S_profile,
      event_label: 'profile',
    },
  ],
  [
    // Переход в раздел 'Профессии' ученика
    YmItems.S_Professions,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.TransitionToMenuItem,
      event_action: YmItems.S_Professions,
      event_label: 'professions',
    },
  ],
  [
    // Ученик. Переход на страницу регистрации.
    YmItems.Pu_Reg_Hellow_OpenPage,
    {
      event_type: YmEventType.Pageview,
      eventName: YmEventName.StudentsRegistrationPageTransition,
      event_action: YmItems.Pu_Reg_Hellow_OpenPage,
      event_label: YmItems.None,
    },
  ],
  [
    // Ученик. Нажатие кнопки "Войти" в окне "Приветствия нового ученика"
    YmItems.Pu_Reg_Hellow_ClickEnterButton,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.StudentsRegistrationEnterButtonClick,
      event_action: YmItems.Pu_Reg_Hellow_ClickEnterButton,
      event_label: YmItems.None,
    },
  ],
  [
    // Ученик. Нажатие кнопки "Продолжить" в окне  "Придумай пароль"
    YmItems.Pu_Reg_CreatePas_ClickEnterButton,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.StudentsRegistrationContinueButtonClick,
      event_action: YmItems.Pu_Reg_CreatePas_ClickEnterButton,
      event_label: YmItems.None,
    },
  ],
  [
    // Ученик. Нажатие кнопки "Начнем!" в окне  "Начала введения"
    YmItems.Pu_Reg_Introduction_ClickEnterButton,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.StudentsRegistrationStartButtonClick,
      event_action: YmItems.Pu_Reg_Introduction_ClickEnterButton,
      event_label: YmItems.None,
    },
  ],
  [
    // Ученик. Нажатие кнопки "Данные не верны" в окне "Приветствия нового ученика"
    YmItems.Pu_Reg_Hellow_DataNotCorrect,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.StudentsRegistrationIncorrectDataButtonClick,
      event_action: YmItems.Pu_Reg_Hellow_DataNotCorrect,
      event_label: YmItems.None,
    },
  ],
  [
    // Переход на страницу 'Выборы' через меню навигации
    YmItems.T_Results_Selections,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.TransitionToSelections,
      event_action: YmItems.T_Results_Selections,
    },
  ],
  [
    // Переход на страницу 'Журнал' через меню навигации
    YmItems.T_Results_Register,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.TransitionToRegister,
      event_action: YmItems.T_Results_Register,
    },
  ],
  [
    //Переход на страницу 'Диагноста' через меню навигации
    YmItems.T_Results_Diagnostics,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.TransitionToDiagnostics,
      event_action: YmItems.T_Results_Diagnostics,
    },
  ],
  [
    // Переход в карточку профессии из раздела “Профессии”
    YmItems.T_Professions_ProfessionCard,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.TransitionToProfessionCard,
      event_action: YmItems.T_Professions_ProfessionCard,
    },
  ],
  [
    // Выдача заданий связанных с прошедшим занятием ученикам
    YmItems.T_Lessons_AccessToLesson,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.AssigningTasks,
      event_action: YmItems.T_Lessons_AccessToLesson,
    },
  ],
  [
    // Открытие конспектов урока
    YmItems.T_Lessons_Open,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.OpeningLesson,
      event_action: YmItems.T_Lessons_Open,
    },
  ],
  [
    // Скачивание материалов урока
    YmItems.T_Lessons_Download,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.DownloadingLesson,
      event_action: YmItems.T_Lessons_Download,
    },
  ],

  [
    // Копия приглашения для родителей на регистрацию для их детей в пустой класс.
    YmItems.T_Class_InvitationInEmptyClass,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.CopyingInvitation,
      event_action: YmItems.T_Class_InvitationInEmptyClass,
      event_label: YmItems.None,
    },
  ],
  [
    // Копировать приглашение родителям для учеников
    YmItems.T_Class_invitationInNotEmptyClass,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.CopyingInvitation,
      event_action: YmItems.T_Class_invitationInNotEmptyClass,
      event_label: YmItems.None,
    },
  ],
  [
    // Нажатие кнопки “Редактировать” профиле
    YmItems.T_Profile_EditButton,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.ProfileEditing,
      event_action: YmItems.T_Profile_EditButton,
      event_label: 'null',
    },
  ],
  [
    // Нажатие кнопки “Сохранить” профиле
    YmItems.T_Profile_SaveChanges,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.SaveProfileChanges,
      event_action: YmItems.T_Profile_SaveChanges,
      event_label: 'null',
    },
  ],
  [
    // Переход в раздел 'Занятия'
    YmItems.T_Lessons,
    {
      event_type: YmEventType.Event,
      event_action: YmItems.T_Lessons,
      event_label: 'null',
      eventName: YmEventName.TransitionToLessons,
    },
  ],
  [
    // Переход в раздел 'Ученики'
    YmItems.T_Class,
    {
      event_type: YmEventType.Event,
      event_action: YmItems.T_Class,
      event_label: 'null',
      eventName: YmEventName.TransitionToStudents,
    },
  ],
  [
    // Переход в раздел 'Результаты'
    YmItems.T_Results,
    {
      event_type: YmEventType.Event,
      event_action: YmItems.T_Results,
      event_label: 'null',
      eventName: YmEventName.TransitionToResults,
    },
  ],
  [
    // Переход в раздел 'Профессии'
    YmItems.T_Professions,
    {
      event_type: YmEventType.Event,
      event_action: YmItems.T_Professions,
      event_label: 'null',
      eventName: YmEventName.TransitionToProfessions,
    },
  ],
  [
    // Переходы между классами
    YmItems.T_SelectClass,
    {
      event_type: YmEventType.Event,
      event_action: YmItems.T_SelectClass,
      eventName: YmEventName.ClassTransition,
    },
  ],
  [
    // Учитель. Регистрация. Нажатие кнопки "Войти" в окне "Приветствия нового учителя"
    YmItems.T_Reg_Hello_ClickEnterButton,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.TeacherRegistrationEnterButtonClick,
      event_action: YmItems.T_Reg_Hello_ClickEnterButton,
      event_label: 'null',
    },
  ],
  [
    // Учитель. Регистрация. Нажатие кнопки "Подтвердить" в окне "Подтверждения номера телефона".
    YmItems.T_Reg_Confirm_ClickConfirmButton,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.TeacherRegistrationConfirmButtonClick,
      event_action: YmItems.T_Reg_Confirm_ClickConfirmButton,
      event_label: 'null',
    },
  ],
  [
    // Учитель. Регистрация. Успешная проверка валидации пароля в окне "Придумай пароль".
    YmItems.T_Reg_CreatePas_PasswordValidation,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.TeacherRegistrationPasswordValidation,
      event_action: YmItems.T_Reg_CreatePas_PasswordValidation,
      event_label: 'null',
    },
  ],
  [
    // Учитель. Нажатие кнопки. "Это не я или данные не верны".
    YmItems.T_Reg_Hellow_NotMeButton,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.TeacherRegistrationNotMeButtonClick,
      event_action: YmItems.T_Reg_Hellow_NotMeButton,
      event_label: 'null',
    },
  ],
  [
    // Учитель. Регистрация. Нажатие кнопки "Видимый пароль".
    YmItems.T_Reg_CreatePas_PasswordVisibility,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.TeacherRegistrationPasswordVisibilityButtonClick,
      event_action: YmItems.T_Reg_CreatePas_PasswordVisibility,
      event_label: 'null',
    },
  ],
  [
    // Учитель. Регистрация. Нажатие кнопки "Указать другой номер"
    YmItems.T_Reg_Confirm_AnotherNumber,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.TeacherRegistrationAnotherNumberButtonClick,
      event_action: YmItems.T_Reg_Confirm_AnotherNumber,
      event_label: 'null',
    },
  ],

  [
    // Родитель. Регистрация. Переход на страницу регистрации.
    YmItems.Pa_Reg_Hello_OpenPage,
    {
      event_type: YmEventType.Pageview,
      event_action: YmItems.Pa_Reg_Hello_OpenPage,
      eventName: YmEventName.ParentRegistrationPageTransition,
      event_label: 'null',
    },
  ],
  [
    // Родитель. Нажатие кнопки "Продолжить" в окне "Приветствия нового родителя".
    YmItems.Pa_Reg_Hello_ClickEnterButton,
    {
      event_type: YmEventType.Event,
      event_action: YmItems.Pa_Reg_Hello_ClickEnterButton,
      eventName: YmEventName.ParentRegistrationHelloContinueButtonClick,
      event_label: 'null',
    },
  ],
  [
    // Родитель. Регистрация. Нажатие кнопки "Продолжить" в окне "Введите данные родителя".
    YmItems.Pa_Reg_ParentDetails_ClickEnterButton,
    {
      event_type: YmEventType.Event,
      event_action: YmItems.Pa_Reg_ParentDetails_ClickEnterButton,
      eventName: YmEventName.ParentRegistrationParentDetailsContinueButtonClick,
      event_label: 'null',
    },
  ],
  [
    // Родитель. Регистрация. Нажатие кнопки "Продолжить" в окне "Введите данные подростка".
    YmItems.Pa_Reg_PupilData_ClickEnterButton,
    {
      event_type: YmEventType.Event,
      event_action: YmItems.Pa_Reg_PupilData_ClickEnterButton,
      eventName: YmEventName.ParentRegistrationEnterYourTeensDetails,
      event_label: 'null',
    },
  ],
  [
    // Родитель. Регистрация. Нажатие кнопки "Продолжить" в окне "Проверьте данные"
    YmItems.Pa_Reg_CheckData_ClickEnterButton,
    {
      event_type: YmEventType.Event,
      event_action: YmItems.Pa_Reg_CheckData_ClickEnterButton,
      eventName: YmEventName.ParentRegistrationCheckTheData,
      event_label: 'null',
    },
  ],

  [
    // Родитель. Регистрация. Нажатие галочки "Получать уведомления".
    YmItems.Pa_Reg_ParentData_NewsletterConsent,
    {
      event_type: YmEventType.Event,
      event_action: YmItems.Pa_Reg_ParentData_NewsletterConsent,
      eventName: YmEventName.ParentRegistrationNewsletterConsentCheckboxClick,
      event_label: 'null',
    },
  ],
  [
    // Родитель. Регистрация. Нажатие кнопки "Данные не верны" Страница приветствия.
    YmItems.Pa_Reg_Hello_DataIsNotCorrect,
    {
      event_type: YmEventType.Event,
      event_action: YmItems.Pa_Reg_Hello_DataIsNotCorrect,
      eventName: YmEventName.ParentRegistrationHello,
      event_label: 'null',
    },
  ],
  [
    // Родитель. Регистрация. Нажатие кнопки "Редактировать" данные.
    YmItems.Pa_Reg_DataCheck_Edit,
    {
      event_type: YmEventType.Event,
      event_action: YmItems.Pa_Reg_DataCheck_Edit,
      eventName: YmEventName.ParentRegistrationEditButtonClick,
      event_label: 'null',
    },
  ],
  [
    // Всплытие оповещения о наличии функционала СПО ВО
    YmItems.S_Profile_SPOVO_Open,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.SpoVo,
      event_action: YmItems.S_Profile_SPOVO_Open,
      event_label: 'null',
    },
  ],
  [
    // Клик на кнопку “Пойдем посмотрим! СПО ВО
    YmItems.S_Profile_SPOVO_Lets_See,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.SpoVo,
      event_action: YmItems.S_Profile_SPOVO_Lets_See,
      event_label: 'null',
    },
  ],
  [
    // Клик на кнопку “Посмотреть”, во всплывающем окне. СПО ВО
    YmItems.S_Profile_SPOVO_To_See,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.SpoVo,
      event_action: YmItems.S_Profile_SPOVO_To_See,
      event_label: 'null',
    },
  ],
  [
    // Клик на пункт рекомендованной программы обучения. СПО ВО
    YmItems.S_Profile_SPOVO_Item,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.SpoVo,
      event_action: YmItems.S_Profile_SPOVO_Item,
    },
  ],
  [
    // Нажатие кнопок фильтров: ВСЕ, ВО, СПО.
    YmItems.S_Profile_SPOVO_Filter,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.SpoVo,
      event_action: YmItems.S_Profile_SPOVO_Filter,
    },
  ],
  [
    // Заход ученика на страницу диагностического теста
    YmItems.S_Lessons_Diagnostics_Open,
    {
      event_type: YmEventType.Pageview,
      eventName: YmEventName.DiagnosticOpen,
      event_action: YmItems.S_Lessons_Diagnostics_Open,
    },
  ],
  [
    // Выбор на странице с "Многими ответами"
    YmItems.S_Lessons_Diagnostics_ManyAnswers_Select,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.DiagnosticManyAnswersSelect,
      event_action: YmItems.S_Lessons_Diagnostics_ManyAnswers_Select,
    },
  ],
  [
    // Снять отметку выбранности на странице с "Многими ответами"
    YmItems.S_Lessons_Diagnostics_ManyAnswers_Unselect,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.DiagnosticManyAnswersUnselect,
      event_action: YmItems.S_Lessons_Diagnostics_ManyAnswers_Unselect,
    },
  ],
  [
    // Отправка открытого ответа
    YmItems.S_Lessons_Diagnostics_OpenAnswer,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.DiagnosticOpenAnswer,
      event_action: YmItems.S_Lessons_Diagnostics_OpenAnswer,
    },
  ],
  [
    // Выбор ответа на вопрос через позицию в скролл баре
    YmItems.S_Lessons_Diagnostics_SkrollBarAnswer,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.DiagnosticScrollBarAnswer,
      event_action: YmItems.S_Lessons_Diagnostics_SkrollBarAnswer,
    },
  ],
  [
    // Нажатие "ДАЛЕЕ" на странице диагностического текста
    YmItems.S_Lessons_Diagnostics_NextButton,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.DiagnosticNextButton,
      event_action: YmItems.S_Lessons_Diagnostics_NextButton,
    },
  ],
  [
    // Нажатие "НАЗАД" на странице диагностического текста
    YmItems.S_Lessons_Diagnostics_BackButton,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.DiagnosticBackButton,
      event_action: YmItems.S_Lessons_Diagnostics_BackButton,
    },
  ],
  [
    // Нажатие кнопки "Крестик" в диагностическом тесте и выход из теста
    YmItems.S_Lessons_Diagnostics_CrossButton,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.DiagnosticCrossButton,
      event_action: YmItems.S_Lessons_Diagnostics_CrossButton,
    },
  ],
  [
    // Завершение Диагностического теста
    YmItems.S_Lessons_Diagnostics_Done,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.DiagnosticDone,
      event_action: YmItems.S_Lessons_Diagnostics_Done,
    },
  ],
  [
    // Переход на страницы профессий со страницы результатов входного теста КГ
    YmItems.S_Lessons_DiagnosticsKG_Result_ProfessionButton,
    {
      event_type: YmEventType.Pageview,
      eventName: YmEventName.DiagnosticProfessionsRedirect,
      event_action: YmItems.S_Lessons_DiagnosticsKG_Result_ProfessionButton,
    },
  ],
  [
    // Перенос мини карточки профессии в слот
    YmItems.S_Lessons_Diagnostics_DragMiniCard,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.TryingProfession,
      event_action: YmItems.S_Lessons_Diagnostics_DragMiniCard,
    },
  ],
  [
    // Открытие карточки профессии во время "Примерки"
    YmItems.S_Lessons_Diagnostics_EnteringProfessionCard,
    {
      event_type: YmEventType.Pageview,
      eventName: YmEventName.TryingProfession,
      event_action: YmItems.S_Lessons_Diagnostics_EnteringProfessionCard,
    },
  ],
  [
    // Открытие чат-бота
    YmItems.S_ChatBot_Open,
    {
      event_type: YmEventType.Pageview,
      eventName: YmEventName.ChatBotOpen,
      event_action: YmItems.S_ChatBot_Open,
    },
  ],
  [
    // Завершение чат-бота
    YmItems.S_ChatBot_Complete,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.ChatBot_Complete,
      event_action: YmItems.S_ChatBot_Complete,
    },
  ],

  [
    // Нажатие кнопки на странице открытого чат бота
    YmItems.S_ChatBot_PressButton,
    {
      event_type: YmEventType.Event,
      eventName: YmEventName.ChatBot_PressButton,
      event_action: YmItems.S_ChatBot_PressButton,
    },
  ],
]);
