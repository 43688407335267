import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'prf-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss'],
})
export class CoursesComponent implements OnInit {
  public tabLevel: number = 1;

  isCoursesPage: boolean = true;

  userRole: string;
  adminLevel: string = null;

  constructor(private meta: Meta, private router: Router) {
    this.userRole = localStorage.getItem('userRole');
    this.adminLevel = localStorage.getItem('adminLevel');
    this.meta.updateTag({ name: 'og:title', content: 'Страница курсов' });
  }

  ngOnInit() {
    let path = this.router.routerState.snapshot.url;
    if (path.includes('event')) {
      this.isCoursesPage = false;
    }
    this.router.url.includes('all-courses')
      ? (this.tabLevel = 1)
      : this.router.url.includes('mine-courses')
      ? (this.tabLevel = 2)
      : this.router.url.includes('search-index-courses')
      ? (this.tabLevel = 3)
      : null;
  }
}
