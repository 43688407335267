import { inject, Injectable } from '@angular/core';
import { HttpService } from '@profilum-api-services/http/http.service';
import { ICalculateSessionResultsResponse } from '@profilum-api-services/api-calculator/api-calculator.interface';
import { Observable } from 'rxjs';
import { ApiCalculatorUrls } from '@profilum-api-services/api-calculator/api-calculator.collections';

@Injectable({
  providedIn: 'root',
})
export class ApiCalculatorService {
  private readonly httpService: HttpService = inject(HttpService);

  public calculateSessionResults(sessionId: string): Observable<ICalculateSessionResultsResponse> {
    return this.httpService.post<ICalculateSessionResultsResponse>(ApiCalculatorUrls.CalculateSessionResults, null, { sessionId });
  }
}
