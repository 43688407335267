import { inject, Injectable } from '@angular/core';
import { HttpService } from '../http';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { IUniversity } from './api-universities.interface';
import { ApiUniversitiesUrls } from './api-universities.collections';

@Injectable({
  providedIn: 'root',
})
export class ApiUniversitiesService {
  private readonly httpService: HttpService = inject(HttpService);

  public getAllUniversities(): Observable<any> {
    return this.httpService.get(ApiUniversitiesUrls.GetUniversities).pipe(map((response: any) => response.universities));
  }

  public getUniversityById(id): Observable<IUniversity> {
    return this.httpService.get(ApiUniversitiesUrls.GetUniversity, { id }).pipe(map((response: any) => response.university));
  }

  public deleteUniversity(id): Observable<any> {
    return this.httpService.delete(ApiUniversitiesUrls.DeleteUniversity, { id }).pipe(take(1));
  }

  public addUniversity(university) {
    return this.httpService.post(ApiUniversitiesUrls.CreateUniversity, { university }).pipe(take(1));
  }

  public updateUniversity(university) {
    return this.httpService.put(ApiUniversitiesUrls.UpdateUniversity, { university }).pipe(take(1));
  }
}
