import { Component, Input } from '@angular/core';

@Component({
  selector: 'prf-user-profile-shared',
  templateUrl: './user-profile-shared.component.html',
  styleUrls: ['./user-profile-shared.component.scss'],
})
export class UserProfileSharedComponent {
  @Input() school: any;
  @Input() user: any;
  @Input() userClass: any;
  parents: any;

  constructor() {}
}
