import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginB2cComponent } from './login-b2c/login-b2c.component';
import { CodeRegistrationB2cComponent } from './registration-b2c/code-registration-b2c/code-registration-b2c.component';
import { OpenRegistrationB2cComponent } from './registration-b2c/open-registration-b2c/open-registration-b2c.component';
import { RegistrationB2cComponent } from './registration-b2c/registration-b2c.component';
import { RegistrationPupilB2cComponent } from './registration-b2c/registration-pupil-b2c/registration-pupil-b2c.component';
import { TokenauthComponent } from './tokenauth/tokenauth.component';

const routes: Routes = [
  { path: 'login', component: LoginB2cComponent },

  {
    path: 'registration',
    component: RegistrationB2cComponent,
  },
  {
    path: 'registration-parent',
    component: OpenRegistrationB2cComponent,
  },
  {
    path: 'code-registration',
    component: CodeRegistrationB2cComponent,
  },
  {
    path: 'registration-pupil',
    component: RegistrationPupilB2cComponent,
  },
  { path: 'openregistration', component: OpenRegistrationB2cComponent },
  {
    path: 'registration-other',
    component: CodeRegistrationB2cComponent,
    pathMatch: 'full',
  },
  { path: 'tokenauth', component: TokenauthComponent },
  { path: 'vorobievi-gori/login', component: LoginB2cComponent },
  { path: 'vorobievi-gori', redirectTo: 'vorobievi-gori/registration', pathMatch: 'full' },
  { path: 'vorobievi-gori/registration', component: OpenRegistrationB2cComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class LandingB2cRoutingModule {}
