import { Action, Selector, State, StateContext } from '@ngxs/store';
import { inject, Injectable } from '@angular/core';
import {
  DeleteAll,
  FetchCurrentSlide,
  FetchNextSlide,
  FetchPreviousSlide,
  SetCurrentSlide,
  SetCurrentSlideFailed,
  SetNextSlide,
  SetNextSlideFailed,
  SetPreviousSlide,
  SetPreviousSlideFailed,
  UpdateSlide,
} from './slides.actions';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { SlidesStateModel } from './slides.model';
import {
  IGetCurrentSlideResponse,
  IMoveToNextSlideResponse,
  IMoveToPreviousSlideResponse,
} from '@profilum-api-services/api-slides/api-slides.interface';
import { ApiSlidesService } from '@profilum-api-services/api-slides/api-slides.service';

@State<SlidesStateModel>({
  name: 'slides',
  defaults: {
    slides: [],
    currentSlide: null,
    error: null,
  },
})
@Injectable()
export class SlidesState {
  private apiSlidesService: ApiSlidesService = inject(ApiSlidesService);

  @Selector()
  public static getSlides(state: SlidesStateModel): IMoveToNextSlideResponse[] {
    return state.slides;
  }

  @Selector([SlidesState.getError])
  public static getCurrentSlide(state: SlidesStateModel, error: any): IMoveToNextSlideResponse {
    return error ? null : state.currentSlide;
  }

  @Selector()
  public static getError(state: SlidesStateModel): any {
    return state.error;
  }

  @Action([FetchNextSlide])
  public fetchNextSlideData(
    { setState, dispatch, getState }: StateContext<SlidesStateModel>,
    { payload: { sessionId, isAnonymous } }: FetchNextSlide,
  ): Observable<IMoveToNextSlideResponse> {
    const state: SlidesStateModel = getState();
    const slide: IMoveToNextSlideResponse = this.getNextOrPreviousSlide(state, false);

    if (slide) {
      setState({
        ...state,
        currentSlide: slide,
        error: null,
      });

      return of(slide);
    }

    const service: Observable<IMoveToNextSlideResponse> = isAnonymous
      ? this.apiSlidesService.moveToNextSlideAnonymous(sessionId)
      : this.apiSlidesService.moveToNextSlide(sessionId);

    return service.pipe(
      tap((slideData: IMoveToNextSlideResponse) => {
        dispatch(new SetNextSlide({ slide: slideData }));
      }),
      catchError((error: any) => {
        dispatch(new SetNextSlideFailed({ error }));

        throw new Error(error);
      }),
    );
  }

  @Action([SetNextSlide])
  public setNextSlide({ getState, setState }: StateContext<SlidesStateModel>, { payload: { slide } }: SetNextSlide): void {
    const state: SlidesStateModel = getState();
    const currentSlide: IGetCurrentSlideResponse = { ...slide };

    if (currentSlide.slide) {
      currentSlide.slide.userAnswers = currentSlide.slide.userAnswers ?? [];
    }

    // state.slides.push(currentSlide);
    state.slides = [currentSlide];

    setState({
      ...state,
      currentSlide,
      error: null,
    });
  }

  @Action([SetNextSlideFailed])
  public setNextSlideFailed({ getState, setState }: StateContext<SlidesStateModel>, { payload: { error } }: SetNextSlideFailed): void {
    const state: SlidesStateModel = getState();

    setState({
      ...state,
      error,
    });
  }

  @Action([FetchPreviousSlide])
  public fetchPreviousSlideData(
    { dispatch, getState, setState }: StateContext<SlidesStateModel>,
    { payload: { sessionId } }: FetchPreviousSlide,
  ): Observable<IMoveToPreviousSlideResponse> {
    const state: SlidesStateModel = getState();
    const slide: IMoveToNextSlideResponse = this.getNextOrPreviousSlide(state, true);

    if (slide) {
      setState({
        ...state,
        currentSlide: slide,
        error: null,
      });

      return of(slide);
    }

    return this.apiSlidesService.moveToPreviousSlide(sessionId).pipe(
      tap((slideData: IMoveToPreviousSlideResponse) => {
        dispatch(new SetPreviousSlide({ slide: slideData }));
      }),
      catchError((error: any) => {
        dispatch(new SetPreviousSlideFailed({ error }));

        throw new Error(error);
      }),
    );
  }

  @Action([SetPreviousSlide])
  public setPreviousSlide({ getState, setState }: StateContext<SlidesStateModel>, { payload: { slide } }: SetPreviousSlide): void {
    const state: SlidesStateModel = getState();
    const currentSlide: IGetCurrentSlideResponse = { ...slide };

    if (currentSlide.slide) {
      currentSlide.slide.userAnswers = currentSlide.slide.userAnswers ?? [];
    }

    // state.slides.unshift(currentSlide);
    state.slides = [currentSlide];

    setState({
      ...state,
      currentSlide,
      error: null,
    });
  }

  @Action([SetPreviousSlideFailed])
  public setPreviousSlideFailed(
    { getState, setState }: StateContext<SlidesStateModel>,
    { payload: { error } }: SetPreviousSlideFailed,
  ): void {
    const state: SlidesStateModel = getState();

    setState({
      ...state,
      error,
    });
  }

  @Action([FetchCurrentSlide])
  public fetchCurrentSlideData(
    { dispatch, getState, setState }: StateContext<SlidesStateModel>,
    { payload: { sessionId, isAnonymous} }: FetchCurrentSlide,
  ): Observable<IGetCurrentSlideResponse> {
    const state: SlidesStateModel = getState();

    if (state.currentSlide) {
      setState({
        ...state,
        currentSlide: state.currentSlide,
        error: null,
      });

      return of(state.currentSlide);
    }

    return this.apiSlidesService.getCurrentSlide(sessionId).pipe(
      tap((slideData: IGetCurrentSlideResponse) => {
        if (slideData?.slide) {
          dispatch(new SetCurrentSlide({ slide: slideData }));
          return;
        }

        dispatch(new FetchNextSlide({sessionId, isAnonymous}));
      }),
      catchError((error: any) => {
        dispatch(new SetCurrentSlideFailed({ error }));

        throw new Error(error);
      }),
    );
  }

  @Action([SetCurrentSlide])
  public setCurrentSlide({ getState, setState }: StateContext<SlidesStateModel>, { payload: { slide } }: SetCurrentSlide): void {
    const state: SlidesStateModel = getState();
    const currentSlide: IGetCurrentSlideResponse = { ...slide };

    if (currentSlide.slide) {
      currentSlide.slide.userAnswers = currentSlide.slide.userAnswers ?? [];
    }

    setState({
      ...state,
      currentSlide,
      slides: [currentSlide],
      error: null,
    });
  }

  @Action([SetCurrentSlideFailed])
  public setCurrentSlideFailed(
    { getState, setState }: StateContext<SlidesStateModel>,
    { payload: { error } }: SetCurrentSlideFailed,
  ): void {
    const state: SlidesStateModel = getState();

    setState({
      ...state,
      error,
    });
  }

  @Action([DeleteAll])
  public deleteAll({ getState, setState }: StateContext<SlidesStateModel>): void {
    const state: SlidesStateModel = getState();

    setState({
      ...state,
      slides: [],
      currentSlide: null,
      error: null,
    });
  }

  @Action([UpdateSlide])
  public updateSlideData({ getState, setState }: StateContext<SlidesStateModel>, { payload: { slideId, userAnswers } }: UpdateSlide): void {
    if (!userAnswers) {
      return;
    }

    const state: SlidesStateModel = getState();
    const slide: IMoveToNextSlideResponse = state.slides.find((slide: IMoveToNextSlideResponse) => slide.slide.id === slideId);

    slide.slide.userAnswers = [...userAnswers];

    setState({
      ...state,
      error: null,
    });
  }

  private getNextOrPreviousSlide({ slides, currentSlide }: SlidesStateModel, isPrevious: boolean): IMoveToNextSlideResponse | null {
    if (currentSlide) {
      const currentSlideIndex: number = slides.findIndex((slide: IMoveToNextSlideResponse) => slide.slide.id === currentSlide.slide.id);
      const index: number = isPrevious ? currentSlideIndex - 1 : currentSlideIndex + 1;
      const slide: IMoveToNextSlideResponse = slides[index];

      return slide ?? null;
    }

    return null;
  }
}
