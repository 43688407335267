import { inject, Injectable } from '@angular/core';
import { HttpService } from '../http';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ApiLocationsUrls } from './api-locations.collections';
import { ICity, IGetListOfAllRegionsResponse, IMunicipality, IRegion } from './api-locations.interface';
import { IMediaFile } from '../../interfaces';

@Injectable({
  providedIn: 'root',
})
export class ApiLocationsService {
  private readonly httpService: HttpService = inject(HttpService);

  public getMunicipality(municipalityId: string): Observable<any> {
    return this.httpService.get(ApiLocationsUrls.Municipality, { municipalityId }).pipe(map((response: any) => response.municipality));
  }

  public getMunicipalitiesByRegion(regionId: string): Observable<any> {
    return this.httpService.get(ApiLocationsUrls.GetMunicipalityByRegion, { regionId }).pipe(map((r: any) => r.municipalities));
  }

  public updateMunicipality(municipality: IMunicipality): Observable<any> {
    return this.httpService.put(ApiLocationsUrls.Municipality, municipality).pipe(take(1));
  }

  public deleteMunicipality(municipalityId: string): Observable<any> {
    return this.httpService.delete(ApiLocationsUrls.Municipality, { municipalityId }).pipe(take(1));
  }

  public createMunicipality(municipalityObj: any): Observable<any> {
    return this.httpService.post(ApiLocationsUrls.Municipality, municipalityObj).pipe(take(1));
  }

  //TODO метод getListOfAllRegions нужно убрать и оставить только getAllRegions
  public getListOfAllRegions(excludeTestRegions: boolean = true): Observable<IGetListOfAllRegionsResponse> {
    if (!excludeTestRegions) {
      return this.httpService.get<IGetListOfAllRegionsResponse>(ApiLocationsUrls.GetListOfAllRegions).pipe(take(1));
    }

    return this.httpService.get<IGetListOfAllRegionsResponse>(ApiLocationsUrls.GetListOfAllRegions).pipe(
      map((response: IGetListOfAllRegionsResponse) => {
        response.regions = response.regions?.filter((region: IRegion) => region.isBase);

        return response;
      }),
      take(1),
    );
  }

  public getAllRegions(): Observable<IRegion[]> {
    return this.httpService.get(ApiLocationsUrls.GetListOfAllRegions).pipe(map((r: any) => r.regions));
  }

  public getRegion(regionId): Observable<any> {
    return this.httpService.get(ApiLocationsUrls.GetRegion, { regionId }).pipe(map((r: any) => r.region));
  }

  public updateRegion(region: IRegion): Observable<any> {
    return this.httpService.put(ApiLocationsUrls.Regions, { region }).pipe(take(1));
  }

  public deleteRegion(regionId: string): Observable<any> {
    return this.httpService.delete(ApiLocationsUrls.Regions, { regionId }).pipe(take(1));
  }

  public createRegion(regionObj): Observable<any> {
    return this.httpService.post(ApiLocationsUrls.Regions, regionObj).pipe(take(1));
  }

  public saveRegionFiles(regionId: string, files: IMediaFile[]): Observable<any> {
    const url: string = ApiLocationsUrls.RegionFiles.replace('{id}', regionId);
    return this.httpService.put(url, files);
  }

  public getCity(id: string): Observable<any> {
    return this.httpService.get(ApiLocationsUrls.GetCity, { id }).pipe(take(1));
  }

  public getAllCities(): Observable<ICity[]> {
    return this.httpService.get<ICity[]>(ApiLocationsUrls.GetAllCities).pipe(map((response: any) => response.cities));
  }

  public getRegionCities(regionId: string): Observable<ICity[]> {
    return this.httpService.get<ICity[]>(ApiLocationsUrls.GetCitiesByRegion, { regionId }).pipe(map((response: any) => response.cities));
  }

  // TODO нужно вынести citi.id в тело запроса из url'a
  public updateCity(city: ICity): Observable<any> {
    return this.httpService.put(ApiLocationsUrls.UpdateCity + city.id, city).pipe(take(1));
  }

  public deleteCity(cityId: string): Observable<any> {
    return this.httpService.delete(ApiLocationsUrls.Cities, { cityId }).pipe(take(1));
  }

  public createCity(city: ICity): Observable<any> {
    return this.httpService.post(ApiLocationsUrls.Cities, { city }).pipe(take(1));
  }

  public getAllCitiesByMunicipality(municipalityId: string): Observable<any> {
    return this.httpService.get(ApiLocationsUrls.GetCitiesByMunicipality, { municipalityId }).pipe(take(1));
  }
}
