<form novalidate #f="ngForm" (ngSubmit)="onSubmit(f)">
  <div class="form-control">
    <mat-form-field>
      <input
        type="password"
        matInput
        placeholder="{{ 'SHARED.OLD_PASSWORD' | translate }}"
        [(ngModel)]="_passwords.currentPassword"
        [formControl]="currentPassword"
      />
      <mat-error *ngIf="currentPassword.hasError('required')">
        {{ 'SHARED.FORM.PASSWORD' | translate }}
        <strong>{{ 'SHARED.REQUIRED_FIELD' | translate }}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <input
        type="password"
        matInput
        placeholder="{{ 'PASS_CHANGE.NEW_PASSWORD' | translate }}"
        [(ngModel)]="_passwords.newPassword"
        [formControl]="newPasswordFormControl"
      />
      <mat-error *ngIf="newPasswordFormControl.hasError('password') && !newPasswordFormControl.hasError('required')">
        {{ 'SHARED.PASSWORD_REQUIRED_SYMBOL' | translate }}
      </mat-error>
      <mat-error *ngIf="newPasswordFormControl.hasError('required')">
        {{ 'SHARED.FORM.PASSWORD' | translate }}
        <strong>{{ 'SHARED.REQUIRED_FIELD' | translate }}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <input
        type="password"
        matInput
        placeholder="{{ 'SHARED.CONFIRM_PASSWORD' | translate }}"
        [(ngModel)]="_passwords.newPassword2"
        [formControl]="newPasswordFormControl2"
      />
      <mat-error *ngIf="newPasswordFormControl2.hasError('password') && !newPasswordFormControl2.hasError('required')">
        {{ 'SHARED.PASSWORD_REQUIRED_SYMBOL' | translate }}
      </mat-error>
      <mat-error *ngIf="newPasswordFormControl2.hasError('required')">
        {{ 'SHARED.FORM.PASSWORD' | translate }}
        <strong>{{ 'SHARED.REQUIRED_FIELD' | translate }}</strong>
      </mat-error>
    </mat-form-field>
  </div>
  <button type="submit" mat-raised-button>{{ 'SHARED.CHANGE_PASSWORD' | translate }}</button>
</form>
