import { Injectable } from '@angular/core';

@Injectable()
export class OverlayBusyService {
  overlay;
  register(overlay) {
    this.overlay = overlay;
  }
  unregister() {
    this.overlay = undefined;
  }
  show(text?) {
    if (this.overlay) {
      this.overlay.show(text);
    }
  }
  hide() {
    if (this.overlay) {
      this.overlay.hide();
    }
  }
}
