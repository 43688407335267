import { Component, Input } from '@angular/core';

@Component({
  selector: 'prf-filters-courses',
  templateUrl: './filters-courses.component.html',
  styleUrls: ['./filters-courses.component.scss'],
})
export class FiltersCoursesComponent {
  @Input() territoryFilters: boolean = true;

  public userRole: string;
  constructor() {
    this.userRole = localStorage.getItem('userRole');
  }
}
