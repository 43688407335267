import { inject, Injectable } from '@angular/core';
import { HttpService } from '@profilum-api-services/http/http.service';
import { IDeleteTestSessionsRequest, IDeleteTestSessionsResponse } from './api-utils.interface';
import { ApiUtilsUrls } from './api-utils.collections';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiUtilsService {
  private readonly httpService: HttpService = inject(HttpService);

  public deleteTestSessions(data: IDeleteTestSessionsRequest): Observable<IDeleteTestSessionsResponse> {
    return this.httpService.delete<IDeleteTestSessionsResponse>(ApiUtilsUrls.DeleteTestSessions, null, data).pipe(take(1));
  }
}
