import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { InternshipsService } from '../internships.service';

@Component({
  selector: 'prf-internships-welcome-page',
  templateUrl: './internships-welcome-page.component.html',
  styleUrls: ['./internships-welcome-page.component.scss'],
})
export class InternshipsWelcomePageComponent implements OnInit {
  public internships: any[];
  public userRole: string;

  constructor(private meta: Meta, private internshipsService: InternshipsService, private router: Router) {
    this.meta.updateTag({ name: 'og:title', content: 'Страница стажировок' });
  }

  ngOnInit() {
    this.userRole = localStorage.getItem('userRole');
  }

  public navigateStartTest() {
    this.router.navigate(['/' + this.userRole + '/create-internship']);
  }
}
