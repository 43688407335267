import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { IUniversity } from 'app/shared/interfaces/iuniversity.interface';
import { OverlayBusyService } from 'app/shared/overlay-busy/overlay-busy.service';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { UniversitiesService } from '../../universities.service';

@Component({
  selector: 'prf-university-details',
  templateUrl: './university-details.component.html',
  styleUrls: ['./university-details.component.scss'],
})
export class UniversityDetailsComponent implements OnInit {
  userId: string;
  userRole: string = '';
  adminLevel: string = '';
  university: IUniversity;
  id: string;

  popUpConfirming: boolean = false;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private meta: Meta,
    private overlayService: OverlayBusyService,
    private universitiesService: UniversitiesService,
    private utilsService: UtilsService,
    public route: ActivatedRoute,
    private router: Router,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'Детали ВУЗа' });
  }

  ngOnInit() {
    this.overlayService.show();

    this.userId = localStorage.getItem('userId');
    this.userRole = localStorage.getItem('userRole');
    if (this.userRole.includes('admin')) {
      this.adminLevel = localStorage.getItem('adminLevel');
    }

    this.route.params
      .pipe(
        mergeMap(params => {
          window.scrollTo(0, 0);
          this.id = params['id'];
          return this.loadUniversity();
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(() => this.overlayService.hide());
  }

  loadUniversity(): Observable<any> {
    return this.universitiesService.getUniversityById(this.id).pipe(
      tap(university => {
        if (university) {
          window.scrollTo(0, 0);
          this.university = university;
        }
      }),
    );
  }

  deleteUniversity(id) {
    this.universitiesService
      .deleteUniversity(id)
      .pipe(
        catchError(err => {
          this.popUpConfirming = !this.popUpConfirming;
          this.utilsService.openSnackBar('👎 Ошибка на сервере, попробуйте позже', 'error');
          return throwError(err);
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(delResponse => {
        if (delResponse.status == 'Success') {
          this.utilsService.openSnackBar(`👌 ВУЗ удален`, 'success');
        }
        this.popUpConfirming = !this.popUpConfirming;
        return setTimeout(_ => {
          this.router.navigate(['/admin/universities']);
        }, 1000);
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
