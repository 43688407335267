<section class="section-wrapper">
  <h1>{{ 'SHARED.NEWS_LIST' | translate }}</h1>
  <div class="wrapper">
    <div class="table">
      <div class="row table-header">
        <div class="cell">№</div>
        <div class="cell">Изображение</div>
        <div class="cell">Заголовок</div>
        <div class="cell">Описание</div>
        <div class="cell">Тип</div>
      </div>
      <div class="row news-row" *ngFor="let newsItem of news; let count = index">
        <div class="cell" data-title="number">{{ count + 1 }}</div>
        <img mat-card-avatar src="{{ newsItem.imageUrl }}" />
        <div class="cell" data-title="title">{{ newsItem.title }}</div>
        <div class="cell" data-title="description">{{ newsItem.description }}</div>
        <div class="cell" data-title="eventType">{{ newsItem.eventType }}</div>
      </div>
    </div>
  </div>
</section>
