import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CitiesService } from '../cities.service';

@Component({
  selector: 'prf-delete-city',
  templateUrl: './delete-city.component.html',
  styleUrls: ['./delete-city.component.scss'],
})
export class DeleteCityComponent implements OnInit {
  @Input() cityId: string;
  @Output() cityDeleted = new EventEmitter<any>();

  public dialogOpen: boolean = false;
  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(private citiesService: CitiesService, private utilsService: UtilsService) {}

  ngOnInit() {}

  public showDeleteDialog(): void {
    this.dialogOpen = !this.dialogOpen;
  }

  delete() {
    this.citiesService
      .deleteCity(this.cityId)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(responce => {
        this.utilsService.openSnackBar('👌 Город удален', 'success');
        return setTimeout(_ => {
          this.cityDeleted.emit(this.cityId);
          this.showDeleteDialog();
        }, 1000);
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
