import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { AppSettingsService } from 'app/shared/services/appsettings.service';
import { PupilPanelService } from '../../../control-panel/pupil/pupil-panel.service';
import { ResultsService } from '../../../results/results.service';
import { EUserTags } from 'app/shared/enums/user-types.enum';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-profession-cards',
  templateUrl: './profession-cards.component.html',
  styleUrls: ['./profession-cards.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProfessionCardsComponent extends UnsubscribeComponent implements OnInit {
  public loaded: boolean = false;
  public userRole: string = '';
  public testSessionId: string = '';
  public sessionStatus: string = '';
  public tabName: string = 'catalog'; // [catalog, recommendations]
  public testResults: any;
  public userId: string = '';
  public selectLanguage: boolean = false;
  public isVGuser: boolean = false;

  constructor(
    private pupilPanelService: PupilPanelService,
    private resultsService: ResultsService,
    private activatedRoute: ActivatedRoute,
  ) {
    super();
    this.userRole = localStorage.getItem('userRole');
    this.testSessionId = localStorage.getItem('testSessionId');
    this.sessionStatus = localStorage.getItem('sessionStatus');
    this.isVGuser = localStorage.getItem('tag') === EUserTags[EUserTags.VorobieviGori].toString();
    // если выполняется совпадение regionId из appsettings с выбранным регионом пользователя и для данного региона разрешена смена языка (isAccessible)
    this.selectLanguage =
      AppSettingsService.settings.regionLanguages &&
      AppSettingsService.settings.regionLanguages.isAccessible &&
      AppSettingsService.settings.regionId == localStorage.getItem('regionId');

    this.activatedRoute.fragment.subscribe(param => {
      if (param === 'recommendations') {
        this.tabName = 'recommendations';
      }
    });
  }

  public ngOnInit(): void {
    this.loadData()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => (this.loaded = true));
  }

  private loadData(): Observable<any> {
    if (this.sessionStatus === 'Success' && this.userRole === 'pupil') {
      const sessionIds = [this.testSessionId];

      const getResultsObject = {
        regionId: localStorage.getItem('regionId'),
        municipalityId: localStorage.getItem('municipalityId'),
        sessionIds: sessionIds,
        role: localStorage.getItem('userRole'),
      };

      let currentObservable$: Observable<any> = of(null);
      if (this.selectLanguage) {
        currentObservable$ = this.pupilPanelService.getUserSession(this.testSessionId).pipe(
          tap(session => {
            Object.assign(getResultsObject, { language: session.language });
          }),
        );
      }
      return currentObservable$.pipe(
        switchMap(() => {
          return this.resultsService.getResultPages(getResultsObject).pipe(
            tap(results => {
              if (results.results.length > 0) {
                this.testResults = results.results;
              }
            }),
          );
        }),
      );
    } else {
      return of(null);
    }
  }
}
