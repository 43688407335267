import { Component, OnInit } from '@angular/core';
import { timer as observableTimer } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'prf-page-toast',
  templateUrl: './page-toast.component.html',
  styleUrls: ['./page-toast.component.scss'],
})
export class PageToastComponent implements OnInit {
  show: boolean = true;
  count: number = 100;
  countDown;

  constructor() {}

  ngOnInit() {
    this.countDown = observableTimer(1000, 20)
      .pipe(take(1))
      .subscribe(x => {
        this.count = this.count - 0.2;
        if (this.count <= 0) {
          this.show = false;
        }
      });
  }
}
