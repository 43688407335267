import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'prf-base-slider',
  templateUrl: './base-slider.component.html',
  styleUrls: ['./base-slider.component.scss'],
})
export class BaseSliderComponent implements OnInit {
  @Input() collection: any[];
  @Input() translation: any;
  @Output() itemClicked = new EventEmitter<any>();
  private limit: number = 0;
  private start: number = 0;
  private end: number = 0;
  private step: number = 1;
  public frame: any[] = [];
  private defaultLimit: number = 4;

  constructor() {}

  ngOnInit() {
    this.limit = this.collection ? this.collection.length : 0;
    this.end = this.limit > this.defaultLimit ? 4 : this.limit;
    this.frame = this.updateFrame();
  }

  // Event handlers

  public processClick(data) {
    this.itemClicked.emit(data);
  }

  public canScrollLeft() {
    return this.start - this.step >= 0;
  }

  public canScrollRight() {
    return this.end + this.step <= this.limit;
  }

  // Protected section
  protected scrollLeft() {
    if (this.canScrollLeft()) {
      this.start -= this.step;
      this.end -= this.step;
      this.frame = this.updateFrame();
    }
  }

  protected scrollRight() {
    if (this.canScrollRight()) {
      this.start += this.step;
      this.end += this.step;
      this.frame = this.updateFrame();
    }
  }

  protected getBackLabel() {
    return this.translation.PROFESSIONS.SLIDE_BACK;
  }

  protected getForwardLabel() {
    return this.translation.PROFESSIONS.SLIDE_FORWARD;
  }

  protected updateFrame() {
    if (this.collection) {
      return this.collection.slice(this.start, this.end);
    } else {
      return [];
    }
  }
}
