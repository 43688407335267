import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'prf-universities',
  templateUrl: './universities.component.html',
  styleUrls: ['./universities.component.scss'],
})
export class UniversitiesComponent implements OnInit {
  public tabLevel: number = 1;

  adminLevel: string = null;

  constructor(private meta: Meta, private router: Router) {
    this.adminLevel = localStorage.getItem('adminLevel');
    this.meta.updateTag({ name: 'og:title', content: 'Страница ВУЗов' });
  }

  ngOnInit() {}
}
