<i
  matBadge="{{ _notificationsLength }}"
  matBadgeHidden="{{ _hidden }}"
  class="fas fa-bell"
  (click)="updateNotification()"
  [matMenuTriggerFor]="notification"
>
</i>
<mat-menu class="notification" #notification="matMenu">
  <div mat-menu-item class="notification-title">{{ 'SHARED.NOTIFICATIONS' | translate }}</div>
  <div *ngFor="let _notification of _notifications$ | async">
    <button class="notification-description" (mouseenter)="mouseEnter(_notification.id)" mat-menu-item>{{ _notification.type }} 🍕</button>
  </div>
</mat-menu>
