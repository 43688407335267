import { Pipe, PipeTransform } from '@angular/core';

export class DataClass {
  name: string = '';
  courses: any;
}

@Pipe({
  name: 'filterCourses',
})
export class FilterCoursesPipe implements PipeTransform {
  transform(items: DataClass[], filter: string): any[] {
    if (!filter || filter == '' || filter.length < 2) {
      return items;
    }

    return items.filter(item => item.name.toLowerCase().indexOf(filter.toLowerCase()) > -1);
  }
}
