import { Component, Input, OnInit } from '@angular/core';
import { PageHeaderService } from '../page-header.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'prf-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  @Input('matBadgeHidden') _hidden: boolean = true;
  _notifications$: any;
  _notificationsLength: number = 0;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private pageHeaderService: PageHeaderService) {}

  ngOnInit() {
    this._notifications$ = this.pageHeaderService
      .getNotifications()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(notifications => {
        this._notificationsLength = notifications.length;
        if (this._notificationsLength > 0) {
          this._hidden = false;
        }
      });
  }

  updateNotification() {
    this._notificationsLength = 0;
    this._hidden = true;
  }

  mouseEnter(notification: string) {
    let markNotification = {
      messageId: notification,
      isRead: true,
    };
    this.pageHeaderService.markNotification(markNotification);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }
}
