import {
  ISpiderChartConfig,
  SpiderChartFiguresEnum,
  SpiderChartPositioningTypeEnum,
  SpiderChartThemeEnum,
} from './spider-chart-custom.model';

export const SPIDER_CHART_CONFIG: ISpiderChartConfig = {
  defaultSize: 440,
  layout: {
    type: SpiderChartFiguresEnum.HEXAGON,
    peaks: 6,
    repeatCounts: 5,
    repeatFigureStep: 0.2,
    heightModifier: 2,
    widthModifier: 2.33,
    strokeWidth: '0.5px',
    strokeWidthRepeatedHex: '0.3px',
    stroke: 'gray',
    fill: 'none',
    fillOpacity: 0,
  },
  line: {
    stroke: 'rgb(53, 53, 53)',
    strokeOpacity: 0.36,
    strokeWidth: '0.7px',
  },
  polygon: {
    strokeWidth: '2px',
    fillOpacity: 0.8,
  },
  legendValue: {
    offset: {
      [SpiderChartPositioningTypeEnum.DEFAULT]: 15,
      [SpiderChartPositioningTypeEnum.PUPIL_CAPABILITIES]: 0,
      [SpiderChartPositioningTypeEnum.CLASS_RESULTS]: 0,
    },
    transformString: {
      [SpiderChartPositioningTypeEnum.DEFAULT]: {
        0: 'transform: translate(-50%, -100%)',
        1: 'transform: translate(-100%, 0%)',
        2: 'transform: translate(-100%, -100%)',
        3: 'transform: translate(-50%, 0)',
        4: 'transform: translate(0, -100%)',
        5: 'transform: translate(0, 0)',
      },
      [SpiderChartPositioningTypeEnum.PUPIL_CAPABILITIES]: {
        0: 'transform: translate(-50%, -75%)',
        1: 'transform: translate(-85%, -55%)',
        2: 'transform: translate(-85%, -45%)',
        3: 'transform: translate(-50%, -25%)',
        4: 'transform: translate(-10%, -45%)',
        5: 'transform: translate(-10%, -55%)',
      },
      [SpiderChartPositioningTypeEnum.CLASS_RESULTS]: {
        0: 'transform: translate(-50%, -85%)',
        1: 'transform: translate(-60%, -25%)',
        2: 'transform: translate(-60%, -80%)',
        3: 'transform: translate(-50%, -15%)',
        4: 'transform: translate(-30%, -80%)',
        5: 'transform: translate(-30%, -25%)',
      },
    },
    onlyColoredDots: [SpiderChartThemeEnum.CLASS_RESULTS],
  },
  differentPoints: {
    enabled: true,
    differencePercent: 15,
    imgSrc: './profilum-assets/images/icons/diagr-info-icon.svg',
    width: 25,
    height: 25,
  },
  animation: {
    enabled: false,
  },
};
