import { Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'prf-profession-search',
  templateUrl: './profession-search.component.html',
  styleUrls: ['./profession-search.component.scss'],
})
export class ProfessionSearchComponent {
  public searchField: UntypedFormControl;
  public searches: any;
  public empty: boolean = false;
  @Output() searchesData = new EventEmitter();

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor() {}

  ngOnInit() {
    this.searchField = new UntypedFormControl();
    this.searchField.valueChanges.pipe(debounceTime(300), distinctUntilChanged(), takeUntil(this.ngUnsubscribe$)).subscribe(term => {
      this.searchesData.emit(term);
    });
  }

  clearSearch() {
    this.searchField.setValue('');
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
