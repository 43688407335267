import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'prf-profession-menu',
  templateUrl: './profession-menu.component.html',
  styleUrls: ['./profession-menu.component.scss'],
})
export class ProfessionMenuComponent {
  @Input()
  set favoriteProfessions(professions: Array<any>) {
    this._favoriteProfessions = professions;

    this.prepareLists();

    if (this._favoriteProfessions.length > 0) {
      this.onSelectProfession(this._favoriteProfessions[0]);
    }
  }

  @Output() selectProfession = new EventEmitter<any>();

  @ViewChild('professionsDropdown') professionsDropdown: ElementRef;

  public isOpen: boolean = false;
  public selectedProfession: any = {};
  public favoriteProfessionsMain: Array<any> = [];
  public favoriteProfessionsMore: Array<any> = [];

  private _favoriteProfessions: Array<any> = [];

  public onSelectProfession(profession: any) {
    this.selectedProfession = profession;
    this.selectProfession.emit(this.selectedProfession);
    this.isOpen = false;
  }

  private prepareLists() {
    let wordsLength: number = 0;
    let maxCount: number = 60;

    this._favoriteProfessions.forEach(profession => {
      wordsLength += profession.name.length;

      if (wordsLength < maxCount) {
        this.favoriteProfessionsMain.push(profession);
      } else {
        this.favoriteProfessionsMore.push(profession);
      }
    });
  }

  @HostListener('document:click', ['$event.target'])
  checkClick(target) {
    if (this.professionsDropdown && !this.professionsDropdown?.nativeElement.contains(target)) {
      this.isOpen = false;
    }
  }
}
