import { inject, Injectable } from '@angular/core';
import {
  IGetUserAnswersResponse,
  ISaveUserAnswersRequest,
  ISaveUserAnswersResponse,
} from './api-user-answers.interface';
import { Observable } from 'rxjs';
import { ApiUserAnswersUrls } from './api-user-answers.collections';
import { take } from 'rxjs/operators';
import { HttpService } from '@profilum-api-services/http/http.service';

@Injectable({
  providedIn: 'root',
})
export class ApiUserAnswersService {
  private readonly httpService: HttpService = inject(HttpService);

  public saveUserAnswers(userAnswer: ISaveUserAnswersRequest): Observable<ISaveUserAnswersResponse> {
    return this.httpService.post<ISaveUserAnswersResponse>(ApiUserAnswersUrls.SaveUserAnswers, userAnswer).pipe(take(1));
  }

  public getUserAnswers(sessionId: string, questionId: string): Observable<IGetUserAnswersResponse> {
    return this.httpService.get<IGetUserAnswersResponse>(ApiUserAnswersUrls.GetUserAnswers, { sessionId, questionId }).pipe(take(1));
  }
}
