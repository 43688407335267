<div class="filter-div cat" #formatFilter>
  <div class="professions-dropdown w-dropdown" [style.z-index]="formatOpen ? 1000 : 0">
    <div class="professions-dropdown-toggle w-dropdown-toggle" (click)="formatOpen = !formatOpen" [class.active]="filters.format">
      <div class="professions-dd-icon-2 w-icon-dropdown-toggle"></div>
      <div>{{ checkFilterHeader() }}</div>
    </div>
    <nav class="dd-prof-filter-list w-dropdown-list" [class.w--open]="formatOpen">
      <div class="dd-prof-filter-div _2">
        <div class="filter-list-row">
          <ng-container *ngIf="filters.format">
            <a class="clear-categories show" (click)="deselectFormat()">{{ 'SHARED.CLEAR_BUTTON' | translate }}</a>
          </ng-container>
        </div>
        <div class="scroll-block">
          <div class="prof-categories-links">
            <a
              class="prof-categories-link w-inline-block"
              *ngFor="let format of formats"
              [class.w--current]="filters.format == format.value"
            >
              <div class="category-text-link" (click)="setFormatsFilter(format)">
                {{ format.viewValue }}
              </div>
            </a>
          </div>
        </div>
      </div>
    </nav>
  </div>
</div>
