<div
  class="prf-image-wrapper"
  (click)="selectAnswer()"
  *ngIf="answerInfo"
  [class.select]="isSelectedCurrently()"
  [class.selected]="isSelectedBefore()"
>
  <div class="prf-image-view">
    <div class="prf-bubble-img-container">
      <img
        draggable="false"
        class="prf-bubble-img"
        [ngSrc]="src"
        [alt]="'PRF_TESTS.RECTANGLE_IMAGE_BOX_BVB.IMAGE_ALT_TEXT' | translate"
        fill
      />
    </div>
  </div>
  <div class="description" *ngIf="answerInfo.description">{{ answerInfo.description }}</div>
</div>
