<div class="slider profession__info-tab--film-list">
  <button *ngIf="canScrollLeft()" type="button" class="prev" (click)="scrollLeft()" title="{{ getBackLabel() | translate }}">&nbsp;</button>
  <ul>
    <li class="profession__info-tab--film-item" *ngFor="let movie of frame">
      <div class="profession__info-tab--film-pic" *ngIf="movie.imageUrl">
        <img src="{{ movie.imageUrl }}" width="133" height="190" />
      </div>
      <span class="profession__info-tab--film-name">{{ movie.name || movie }}</span>
      <div *ngIf="movie.info" class="profession__info-tab--film-text">{{ movie.info }}</div>
    </li>
  </ul>
  <button *ngIf="canScrollRight()" type="button" class="next" (click)="scrollRight()" title="{{ getForwardLabel() | translate }}">
    &nbsp;
  </button>
</div>
