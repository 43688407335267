import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MunicipalitiesService } from 'app/pages/control-panel/admin/municipalities/municipalities.service';
import { RegionsService } from 'app/pages/control-panel/admin/regions/regions.service';
import { IMunicipality } from 'app/shared/interfaces/imunicipality';
import { IRegion } from 'app/shared/interfaces/iregion';
import * as moment from 'moment';
import { IClass } from '../../../../../../shared/interfaces/iclass.interface';
import { ISchool } from '../../../../../../shared/interfaces/ischool.interface';
import { AdminPanelService } from '../../../../../control-panel/admin/admin-panel.service';
import { SchoolAdminPanelService } from '../../../../../control-panel/schooladmin/schooladmin-panel.service';
import { CoursesService } from '../../../courses.service';
import { Stands } from 'app/shared/enums/stands.enums';
import { Observable, Subject } from 'rxjs';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { SharedService } from 'app/shared/shared.service';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { IFilterClasses } from 'app/shared/interfaces/ifilterclasses.interface';

export const COURSES_TYPES = [
  { value: 'MasterClass', viewValue: 'Мастер-класс', color: 'purple' },
  { value: 'Excursion', viewValue: 'Экскурсия', color: '' },
  { value: 'ShortCourse', viewValue: 'Краткосрочный курс', color: 'purple' },
  { value: 'LongCourse', viewValue: 'Долгосрочный курс', color: 'orange' },
  { value: 'Action', viewValue: 'Акция', color: '' },
  { value: 'Festival', viewValue: 'Фестиваль', color: '' },
  { value: 'Meeting', viewValue: 'Встреча', color: 'yellow' },
  { value: 'Competition', viewValue: 'Конкурс', color: 'orange' },
  { value: 'Profproba', viewValue: 'Профпроба', color: 'blue' },
  { value: 'OpenDay', viewValue: 'День открытых дверей', color: 'green' },
];
export const COMPLEXITYS = [
  { value: 'Introductory', viewValue: 'Начальный' },
  { value: 'Base', viewValue: 'Базовый' },
  { value: 'InDepth', viewValue: 'Продвинутый' },
  { value: 'PreProfessional', viewValue: 'Предпрофессиональный' },
];
export const DELIVERY = [
  { value: 'Offline', viewValue: 'Очный' },
  { value: 'Online', viewValue: 'Онлайн' },
  { value: 'Mixed', viewValue: 'Смешанный' },
];
export const PROMOCODE_TYPES = [
  { value: true, viewValue: 'Скидка в процентах' },
  { value: false, viewValue: 'Скидка в рублях' },
];

@Component({
  selector: 'prf-add-course',
  templateUrl: './add-course.component.html',
  styleUrls: ['./add-course.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

//* Вызывается для 3 активных ролей: админ, админ ДО, школьный админ
//* И для 1 неактивной: работодатель (нет контроллера на беке)
export class AddCourseComponent implements OnInit {
  public form: UntypedFormGroup;
  public submitted: boolean;
  public class: IClass = {
    url: '',
    address: '',
    isPercent: null,
    promocode: '',
  };
  public school: ISchool;
  public showInfoPopup: boolean = false;
  public showAgeInfo: boolean = false;
  public filters: IFilterClasses;
  public timeMask = [/[0-9]/, /\d/, ':', /\d/, /\d/];
  public classesFormats = COURSES_TYPES;
  public promocodeTypes = PROMOCODE_TYPES;
  public selectedFormat: any;
  public selectedPromoType: any;
  public complexitys = COMPLEXITYS;
  public selectedComplexity: any;
  public delivery = DELIVERY;
  public selectedDelivery: any;
  public selectedFiles: File;
  public selectedThemes: any;
  public selectedTalents: any;
  public selectedMetro: any;
  public moment: any = moment;
  public userRole: string;
  public isRegular: boolean;
  public currentMinAge: number;
  public currentMaxAge: number;
  public img: string = '/assets/images/dashboard/add-achievement-icon.svg';
  public checkhrid: boolean;
  public promoTypeOpen: boolean = false;
  //объекты территориальной привязки
  public allRegions$: Observable<IRegion[]>;
  public selectedRegion: IRegion = {
    id: '',
    hrid: '',
    name: '',
  };
  public defaultRegion: string = '00000000-0000-0000-0000-000000000000';
  public municipalitiesByRegion$: Observable<IMunicipality[]>;
  public selectedMunicipality: IMunicipality = {
    id: '',
    hrid: '',
    name: '',
    regionId: '',
  };
  public defaultMunicipality: string = '00000000-0000-0000-0000-000000000000';

  private ngUnsubscribe$: Subject<any> = new Subject();
  //@ViewChild('promoType') promoType: ElementRef;

  constructor(
    private meta: Meta,
    public router: Router,
    public route: ActivatedRoute,
    private coursesService: CoursesService,
    private regionsService: RegionsService,
    private municipalitiesService: MunicipalitiesService,
    private adminPanelService: AdminPanelService,
    private utilsService: UtilsService,
    private schoolAdminPanelService: SchoolAdminPanelService,
    private sharedService: SharedService,
    private fb: UntypedFormBuilder,
    private translateService: TranslateService,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'Добавление курса' });
    this.moment = moment;
    this.moment.locale('ru');
    this.class.id = this.utilsService.newGuid();
    this.userRole = localStorage.getItem('userRole');
  }

  ngOnInit() {
    this.initForm();

    if (this.userRole == 'schooladmin') {
      this.sharedService
        .getSchool()
        .pipe(take(1))
        .subscribe(response => (this.school = response));
    }
    this.getRegions();
  }

  initForm() {
    this.form = this.fb.group({
      name: new UntypedFormControl(this.class.name, [Validators.required]),
      institution: new UntypedFormControl(this.class.institution, [Validators.required]),
      shortDescription: new UntypedFormControl(this.class.shortDescription, [Validators.required]),
      description: new UntypedFormControl(this.class.description, [Validators.required]),
      hrid: new UntypedFormControl(this.class.hrid, [Validators.required]),
      region: new UntypedFormControl(this.selectedRegion, [Validators.required]),
      municipality: new UntypedFormControl(this.selectedMunicipality, []),
      unitPrice: new UntypedFormControl(this.class.unitPrice, [Validators.required]),
      unitDiscount: new UntypedFormControl(this.class.unitDiscount, []),
      isPercent: new UntypedFormControl(this.class.isPercent, []),
      promocode: new UntypedFormControl(this.class.promocode, []),
      startDate: new UntypedFormControl(this.class.startDate, []),
      startTime: new UntypedFormControl(this.class.startTime, []),
      minAgeAudience: new UntypedFormControl(this.class.minAgeAudience, [Validators.required]),
      maxAgeAudience: new UntypedFormControl(this.class.maxAgeAudience, []),
      address: new UntypedFormControl(this.class.address, []),
      url: new UntypedFormControl(this.class.url, []),
      mainImagePath: new UntypedFormControl(null, []),
    });
  }

  get f() {
    return this.form.controls;
  }

  dataThemes(data) {
    this.selectedThemes = data;
  }

  dataMetro(data) {
    this.selectedMetro = data;
  }

  dataTalents(data) {
    this.selectedTalents = data;
  }

  setFormat(obj) {
    if (obj) {
      this.selectedFormat = obj;
    }
  }

  setPromoType(obj) {
    if (obj) {
      this.selectedPromoType = obj;
      this.form.get('promocode').setValidators([Validators.required]);
      if (this.selectedPromoType.value) {
        this.form.get('unitDiscount').setValidators([Validators.max(100), Validators.min(0), Validators.required]);
      } else {
        this.form.get('unitDiscount').setValidators([Validators.required]);
      }
    }
  }

  checkFormatsHeader() {
    if (this.selectedFormat) {
      let town = this.classesFormats.filter(el => el.value === this.selectedFormat.value);
      return town[0].viewValue;
    } else {
      return 'Формат курса';
    }
  }

  checkPromocodeTypesHeader() {
    if (this.selectedPromoType) {
      let promoType = this.promocodeTypes.filter(el => el.value === this.selectedPromoType.value);
      return promoType[0].viewValue;
    } else {
      return 'Тип промокода';
    }
  }

  deselectPromoType() {
    this.selectedPromoType = null;
    this.form.get('unitDiscount').reset();
    this.form.get('promocode').reset();
    this.form.get('unitDiscount').setValidators(null);
    this.form.get('promocode').setValidators(null);
    this.form.get('promocode').updateValueAndValidity();
    this.form.get('unitDiscount').updateValueAndValidity();
  }

  setComplexity(obj) {
    if (obj) {
      this.selectedComplexity = obj;
    }
  }

  checkComplexityHeader() {
    if (this.selectedComplexity) {
      let town = this.complexitys.filter(el => el.value === this.selectedComplexity.value);
      return town[0].viewValue;
    } else {
      return 'Сложность курса';
    }
  }

  setDelivery(obj) {
    if (obj) {
      this.selectedDelivery = obj;
    }
  }

  checkDeliveryHeader() {
    if (this.selectedDelivery) {
      let town = this.delivery.filter(el => el.value === this.selectedDelivery.value);
      return town[0].viewValue;
    } else {
      return 'Формат курса';
    }
  }

  uploadLogoCourse(event) {
    if (event.target.files.item(0).type.match('image.*')) {
      this.selectedFiles = event.target.files;
      this.adminPanelService
        .uploadImage(this.selectedFiles, this.class.id)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(r => {
          this.f.mainImagePath.setValue(r);
        });
    } else {
      this.utilsService.openSnackBar('👎 Некорректный формат файла', 'error');
    }
  }

  parseDate(dateString) {
    if (dateString) {
      return new Date(dateString);
    } else {
      return null;
    }
  }

  getRegions() {
    this.allRegions$ = this.regionsService.getAllRegions().pipe(
      map(r => {
        // переименовываем дефолтный регион
        return r.map(r => {
          r.id === this.defaultRegion ? (r['name'] = 'Дефолтный регион') : null;
          return r;
        });
      }),
    );
  }

  selectRegion(region) {
    if (region) {
      if (region != this.selectedRegion) {
        this.clearMunicipality();
      }
      this.selectedRegion = region;
      this.municipalitiesByRegion$ = this.municipalitiesService.getMunicipalitiesByRegion(region.id);
    }
    return;
  }

  public clearMunicipality() {
    this.form.get('municipality').setValue(this.selectedMunicipality);
    return;
  }

  submit() {
    this.submitted = true;
    this.coursesService
      .checkhrid(this.form.value.hrid)
      .pipe(
        switchMap(r => {
          this.checkhrid = r;
          if (this.form.valid && this.checkhrid && this.selectedFormat) {
            let date = moment(this.form.value.startDate).format('YYYY-MM-DD');
            date = this.form.value.startTime ? moment(date + 'T' + this.form.value.startTime).format() : date;

            if (date == undefined || date == 'Invalid date') {
              date = null;
              this.isRegular = true;
            }
            if (this.form.value.metroStations == null) {
              this.form.value.metroStations = [];
            }
            if (this.form.value.maxAgeAudience == null) {
              this.form.value.maxAgeAudience = 120;
            }
            if (this.school) {
              // schooladmin
              this.class = {
                hrid: this.form.value.hrid,
                regionId: localStorage.getItem('regionId'),
                municipalityId: localStorage.getItem('municipalityId'),
                address: this.form.value.address,
                classesFormat: this.selectedFormat.value,
                description: this.form.value.description,
                institution: this.form.value.institution,
                maxAgeAudience: this.form.value.maxAgeAudience,
                minAgeAudience: this.form.value.minAgeAudience,
                name: this.form.value.name,
                shortDescription: this.form.value.shortDescription,
                startDate: date,
                unitPrice: this.form.value.unitPrice.toString(),
                unitDiscount: this.form.get('unitDiscount').value ? this.form.value.unitDiscount.toString() : null,
                isPercent: this.selectedPromoType != null ? this.selectedPromoType.value : null,
                promocode: this.form.get('promocode').value ? this.form.value.promocode.toString() : '',
                url: this.form.value.url,
                deliveryMode: this.selectedDelivery.value,
                mainImagePath: this.form.value.mainImagePath,
                talents: this.selectedTalents,
                courses: this.selectedThemes,
                metroStations: this.selectedMetro,
                isRegular: this.isRegular,
                institutionId: this.school.id,
                complexity: this.selectedComplexity.value,
                stand: Stands.Talent,
              };
            } else {
              // admin
              this.class = {
                hrid: this.form.value.hrid,
                regionId: this.form.value.region.id,
                municipalityId: this.form.value.municipality.id ? this.form.value.municipality.id : this.defaultMunicipality,
                address: this.form.value.address,
                classesFormat: this.selectedFormat.value,
                description: this.form.value.description,
                institution: this.form.value.institution,
                maxAgeAudience: this.form.value.maxAgeAudience,
                minAgeAudience: this.form.value.minAgeAudience,
                name: this.form.value.name,
                shortDescription: this.form.value.shortDescription,
                startDate: date,
                unitPrice: this.form.value.unitPrice.toString(),
                unitDiscount: this.form.get('unitDiscount').value ? this.form.value.unitDiscount.toString() : null,
                isPercent: this.selectedPromoType != null ? this.selectedPromoType.value : null,
                promocode: this.form.get('promocode').value ? this.form.value.promocode.toString() : '',
                url: this.form.value.url,
                deliveryMode: this.selectedDelivery.value,
                mainImagePath: this.form.value.mainImagePath,
                talents: this.selectedTalents,
                courses: this.selectedThemes,
                metroStations: this.selectedMetro,
                isRegular: this.isRegular,
                complexity: this.selectedComplexity.value,
                stand: Stands.Talent,
              };
            }
            let currentObservable$: Observable<any>;
            if (this.userRole === 'schooladmin') {
              currentObservable$ = this.schoolAdminPanelService.createClassDO(this.class);
            } else {
              //only admin
              currentObservable$ = this.coursesService.createClassesDO(this.class);
            }

            return currentObservable$.pipe(
              tap(r => {
                this.utilsService.openSnackBar('👌 Курс добавлен, вы будете перенаправлены на страницу данного мероприятия', 'success');
                setTimeout(_ => {
                  this.router.navigate(['/events/' + this.form.value.hrid]);
                }, 2000);
              }),
            );
          } else {
            return this.getTranslation('SHARED.ERROR_MSG').pipe(
              tap((translation: string) => {
                this.utilsService.openSnackBar(translation, 'error');
              }),
            );
          }
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  diffAges() {
    if (this.currentMinAge > this.currentMaxAge) {
      return (this.currentMinAge = this.currentMaxAge);
    }
  }

  getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
