<div class="dropdown" [ngClass]="{ active: selectedItem }">
  <div class="dropdown_toggle" (click)="toggleOpen()">
    <mat-icon class="dropdown_icon" [class.opened]="open" svgIcon="icon_dropdown"></mat-icon>
    <div class="dropdown_selected" *ngIf="selectedItem; else notSelected">
      {{ selectedItem.title }}
    </div>
    <ng-template #notSelected>
      <div class="dropdown_selected">Выбор</div>
    </ng-template>
  </div>

  <div class="dropdown__list" [ngClass]="{ show: isOpen }">
    <div class="dropdown__list-item" *ngFor="let item of itemsList" (click)="itemSelect(item)">
      {{ item.title }}
    </div>
  </div>
</div>
