import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AGE_DATA } from 'app/shared/dashboard/courses/courses-catalog/courses-filter/courses-filter.component';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';

import * as moment from 'moment';
import { catchError, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { OverlayBusyService } from '../../../../shared/overlay-busy/overlay-busy.service';
import {
  COMPLEXITYS,
  COURSES_TYPES,
} from '../../courses-page/all-courses/active-courses/add-course/add-course.component';

import { CourseService } from '../course.service';
import { CourseDetailsService } from './course-details.service';
import { MetroColors } from '../../courses-page/all-courses/active-courses/add-course/select-metro/metro-colors';
import { CoursesService } from '../../courses-page/courses.service';
import { SharedService } from 'app/shared/shared.service';
import { Observable, of, Subject, throwError } from 'rxjs';
import { forkJoin as observableForkJoin } from 'rxjs/internal/observable/forkJoin';
import { SchoolAdminPanelService } from 'app/pages/control-panel/schooladmin/schooladmin-panel.service';
import { FavoritesService } from 'app/pages/catalog/favorites/favorites.service';
import { FavoritesTypes } from 'app/shared/enums/favorites.enums';

@Component({
  selector: 'prf-course-details',
  templateUrl: './course-details.component.html',
  styleUrls: ['./course-details.component.scss'],
})
export class CourseDetailsComponent implements OnInit, OnDestroy {
  course: any;
  types: any = COURSES_TYPES;
  classesFormat: string;
  ageData: any = AGE_DATA;
  isFavorite: boolean = false;
  otherCourses: any = [];
  similarCourses: any = [];
  favoritesCourses: any;
  courseEnrolls: any;
  userId: string;

  hrid: string;
  title: string;
  mapHeader: string;
  userRole: string;
  adminLevel: string = '';
  color: string;
  isCourse: boolean;
  startDate: string;
  complexity: string = '';

  isEnrolled: boolean = false;
  dataFetched: boolean = false;
  popUpConfirming: boolean = false;
  addtoArchive: boolean = false;
  mapsLoaded: boolean = false;

  moment: any;
  metroColors: MetroColors = new MetroColors();

  fromString = '';
  beforeString = '';
  yearsString = '';
  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private meta: Meta,
    private courseDetailsService: CourseDetailsService,
    private courseService: CourseService,
    private route: ActivatedRoute,
    private coursesService: CoursesService,
    private utilsService: UtilsService,
    private overlayService: OverlayBusyService,
    private sharedService: SharedService,
    private favoritesService: FavoritesService,
    private translateService: TranslateService,
    private schoolAdminPanelService: SchoolAdminPanelService,
    private router: Router,
  ) {
    this.getTranslations(['SHARED.COURSE', 'SHARED.FROM', 'SHARED.BEFORE', 'COURCES.CATALOG.YEARS'])
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(translations => {
        this.meta.updateTag({ name: 'og:title', content: translations['SHARED.COURSE'] });
        this.fromString = this.beforeString;
        this.yearsString;
      });
    this.userRole = localStorage.getItem('userRole');
    if (this.userRole.includes('admin')) {
      this.adminLevel = localStorage.getItem('adminLevel');
    }
    this.userId = localStorage.getItem('userId');
    this.moment = moment;
    this.moment.locale('ru');
  }

  ngOnInit() {
    this.overlayService.show();
    this.setTitles();
    this.route.params
      .pipe(
        switchMap(params => {
          this.hrid = params['hrid'];
          window.scrollTo(0, 0);
          return this.loadCurrentCourse();
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(() => this.overlayService.hide());
  }

  loadCurrentCourse(): Observable<any> {
    return this.courseService.getClassByHRId(this.hrid).pipe(
      switchMap(data => {
        if (data.status == 'Not Found') {
          this.utilsService.openSnackBar(`Не удалось найти курс с hrid = ${this.hrid}`, 'error');
          this.router.navigate([this.backButtonUrl()]);
        }
        this.course = data.class;
        let type = this.types.find(el => el.value === this.course.classesFormat);
        this.classesFormat = type.viewValue;
        const complexity = COMPLEXITYS.find(c => c.value === this.course.complexity);
        this.complexity = complexity ? complexity.viewValue : '';
        this.color = type.color.length > 0 ? type.color : '';
        let startTime = this.moment(this.course.startDate).format('HH:mm');
        if (startTime == '00:00') {
          this.startDate = this.moment(this.course.startDate).format('D MMMM YYYY');
        } else {
          this.startDate = this.moment(this.course.startDate).format('D MMMM YYYY в HH:mm');
        }

        let classEnrollObservable$: Observable<any>;

        if (this.userRole === 'parent') {
          this.loadFavorites().pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
        }

        if (this.userRole === 'pupil') {
          classEnrollObservable$ = this.loadFavorites().pipe(
            switchMap(() =>
              this.courseDetailsService.getClassEnroll(this.userId, this.course.id).pipe(
                tap(classEnroll => {
                  this.isEnrolled = classEnroll && classEnroll.isDeleted === false;
                }),
              ),
            ),
          );
        } else {
          classEnrollObservable$ = of(null);
        }
        return classEnrollObservable$;
      }),
    );
  }

  loadOtherCourses(): Observable<any> {
    if (this.course.institutionId && this.course.institutionId !== '00000000-0000-0000-0000-000000000000') {
      return observableForkJoin([
        this.courseService.getOtherEventsOrCourses(this.course.institutionId, this.isCourse),
        this.courseService.getSimilarEventsOrCourses(this.course.courses, this.isCourse),
      ]).pipe(
        tap(([otherCourses, similarCourses]) => {
          this.otherCourses = otherCourses.filter(d => d.id !== this.course.id);
          this.similarCourses = similarCourses.filter(d => d.id !== this.course.id);
        }),
      );
    } else {
      return of(null);
    }
  }

  // здесь запрашиваем только курсы из favotites
  loadFavorites(): Observable<any> {
    return this.favoritesService.getUserFavorites(this.userId, FavoritesTypes.Class).pipe(
      tap(r => {
        this.favoritesCourses = r;
        this.favoritesService.setFavoriteCourses(this.favoritesCourses);
        if (this.favoritesCourses && this.course.id) {
          let productIds = this.favoritesCourses.filter(el => el.productId === this.course.id);
          this.isFavorite = productIds.length > 0;
        }
      }),
    );
  }

  archive(course) {
    course.isArchived = true;
    let observables: Observable<any>[] = [];
    // mongo
    if (this.userRole == 'schooladmin') {
      observables.push(this.schoolAdminPanelService.replaceClassDO(course));
    } else {
      // admin
      observables.push(this.coursesService.changeClassesDO(course));
    }
    observableForkJoin(observables)
      .pipe(
        switchMap(() =>
          this.getTranslations(['SHARED.COURSE_ADDED_TO_ARCHIVE']).pipe(
            tap(translations => {
              this.utilsService.openSnackBar(translations['SHARED.COURSE_ADDED_TO_ARCHIVE'], 'success');
              this.addtoArchive = !this.addtoArchive;
            }),
          ),
        ),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  addToFavorite() {
    this.favoritesService
      .addToFavorites(this.course.id, this.userId, FavoritesTypes.Class)
      .pipe(
        switchMap(() => this.loadFavorites()),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  removeFavorite() {
    let filteredFavorites = this.favoritesCourses.filter(el => el.productId === this.course.id);
    let courseFavoriteId = filteredFavorites.map(el => el.id)[0];
    if (courseFavoriteId) {
      this.favoritesService
        .deleteFavorite(courseFavoriteId)
        .pipe(
          switchMap(r => this.loadFavorites()),
          takeUntil(this.ngUnsubscribe$),
        )
        .subscribe();

      this.isFavorite = false;
    }
  }

  goToCourse() {
    if (!this.isEnrolled) {
      let currentObservable$: Observable<any> = of(null);
      if (this.userRole === 'parent') {
        currentObservable$ = this.courseDetailsService.addClassEnrollParent(this.course.id);
      }
      if (this.userRole === 'pupil') {
        currentObservable$ = this.courseDetailsService.addClassEnrollPupil(this.course.id);
      }
      currentObservable$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => (this.isEnrolled = true));
    }
  }

  cancelClassEnroll() {
    if (this.isEnrolled) {
      this.courseDetailsService
        .removeClassEnroll(this.course.id, this.userId)
        .pipe(take(1))
        .subscribe(() => (this.isEnrolled = false));
    }
  }

  getAges() {
    // let agesArray = [];
    // this.course.audience.forEach(age => agesArray.push(this.ageData.find(el => el.value === age).label));
    // let string = agesArray;
    // if (agesArray.length > 1) {
    //   let startSympols = agesArray[0].slice(0, agesArray[0].indexOf('-'));
    //   string = startSympols + agesArray[agesArray.length - 1].slice(agesArray[agesArray.length - 1].indexOf('-'))
    // }
    // return string
    if (this.course.maxAgeAudience != 100 && this.course.minAgeAudience != 0) {
      return this.fromString + ' ' + this.course.minAgeAudience + this.beforeString + this.course.maxAgeAudience + this.yearsString;
    } else if (this.course.maxAgeAudience == 100) {
      return this.course.minAgeAudience + '+ ' + this.yearsString;
    } else if (this.course.minAgeAudience == 0) {
      return this.fromString + ' ' + this.course.maxAgeAudience + ' ' + this.yearsString;
    }
  }

  calculateRealPrice() {
    return this.courseDetailsService.calculateRealPrice(this.course);
  }

  setTitles() {
    let path = this.router.routerState.snapshot.url;
    if (path.includes('/events/')) {
      this.getTranslations(['SHARED.EVENTS', 'SHARED.EVENT_ON_MAP'])
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(translations => {
          this.title = translations['SHARED.EVENTS'];
          this.mapHeader = translations['SHARED.EVENT_ON_MAP'];
          this.isCourse = false;
        });
    }
    if (path.includes('/courses/')) {
      this.getTranslations(['COURCES.CATALOG.COURCES', 'SHARED.EVENT_ON_MAP'])
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(translations => {
          this.title = translations['COURCES.CATALOG.COURCES'];
          this.mapHeader = translations['SHARED.COURSE_ON_MAP'];
          this.isCourse = true;
        });
    }
  }

  backButtonUrl() {
    switch (this.userRole) {
      case 'admin':
        return '/admin/courses';
      case 'schooladmin':
        return '/schooladmin/courses';
      default:
        if (this.isCourse) {
          return '/courses';
        } else {
          return '/events';
        }
    }
  }

  deleteCourse(courseID) {
    let observables: Observable<any>[] = [];
    // mongo
    if (this.userRole == 'schooladmin') {
      observables.push(this.schoolAdminPanelService.deleteClassDO(courseID));
    } else {
      // admin
      observables.push(this.coursesService.removeCurrentClass(courseID));
    }
    // elastic
    observables.push(this.courseService.removeCurrentClass(courseID));
    observableForkJoin(observables)
      .pipe(
        catchError(err => {
          this.popUpConfirming = !this.popUpConfirming;
          this.utilsService.openSnackBar('👎 Ошибка на сервере, попробуйте позже', 'error');
          return throwError(err);
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(([delMongo, delElastic]) => {
        if (delMongo.status == 'Success') {
          this.utilsService.openSnackBar(`👌 Курс удален`, 'success');
        }
        this.popUpConfirming = !this.popUpConfirming;
        return this.router.navigate([this.backButtonUrl()]);
      });
  }

  checkMenuRole() {
    switch (this.userRole) {
      case 'pupil':
      case 'parent':
        return true;
      default:
        return false;
    }
  }

  checkFavourite(): boolean {
    switch (this.userRole) {
      case 'pupil':
      case 'parent':
        return true;
      default:
        return false;
    }
  }

  checkCourseEdit(course) {
    let isEditable = false;
    isEditable =
      this.userRole == 'admin' ||
      (this.userRole == 'schooladmin' &&
        course.regionId === localStorage.getItem('regionId') &&
        course.municipalityId === localStorage.getItem('municipalityId') &&
        course.institutionId === localStorage.getItem('schoolId'));
    return isEditable;
  }

  getTranslations(keys: string[]): Observable<any> {
    return this.translateService.get(keys);
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
