import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'prf-page-404',
  templateUrl: './page-404.component.html',
  styleUrls: ['./page-404.component.scss'],
})
export class Page404Component implements OnInit, OnDestroy {
  constructor(private meta: Meta) {
    this.meta.updateTag({ name: 'og:title', content: '404' });
  }

  ngOnInit() {
    //анимация перестала работать
    //document.write(`<script type="text/javascript">${animate}</script>`);
  }

  ngOnDestroy() {}
}
