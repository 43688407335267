import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { WindowRef } from 'app/shared/services/windowRef.service';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { PageHeaderService } from './page-header.service';
import { Observable, of, Subject } from 'rxjs';
import { UserProfileService } from 'app/pages/catalog/user-profile-page/user-profile.service';
import { MenuService } from '../menus/menu/menu.service';
import { SharedService } from 'app/shared/shared.service';
import { FavoritesService } from '../../../pages/catalog/favorites/favorites.service';
import { EUserTags } from 'app/shared/enums/user-types.enum';
import { SchoolAdminPanelService } from 'app/pages/control-panel/schooladmin/schooladmin-panel.service';
import { IUserInfo, UserDataHandlerService } from '@profilum-library';

@Component({
  selector: 'prf-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PageHeaderComponent implements OnInit, OnDestroy {
  @ViewChild('headerMenu') headerMenu;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('notification') notification: ElementRef;
  @ViewChild('nav') nav: ElementRef;
  @Input() schoolTitle: string;
  @Input() isWelcome: boolean = false;
  @Input() backButtonName: string = '';
  @Input() backButtonUrl: string = '';
  @Input() showMobile: boolean = true;

  @Input() editButton: boolean = false;
  @Input() title: string = '';
  @Input() buttonToTest: boolean = false;
  @Output() adminLevel = new EventEmitter<any>();
  userInfo: IUserInfo;
  showProfile: boolean = false;
  showAddPupils: boolean;
  isAuthorized: any = false;
  active: boolean = false;
  window;
  avatar = {
    _avatarURL: null,
    _shortFIO: null,
    _veryShortFIO: null,
  };
  error: string;
  userRole: string;
  dataFetched: boolean = false;
  _userFavorites: any = [];
  previousUrl: string;
  regionId: string;
  favoriteCourses: any[] = [];
  favoriteProfessions: any[] = [];
  tag: string;

  isMOStend: boolean = false;

  private ngUnsubscribe$ = new Subject<any>();

  constructor(
    private windowRef: WindowRef,
    private router: Router,
    private pageHeaderService: PageHeaderService,
    private profileService: UserProfileService,
    private menuService: MenuService,
    private sharedService: SharedService,
    private favoritesService: FavoritesService,
    private schoolAdminPanelService: SchoolAdminPanelService,
    private translateService: TranslateService,
    private userDataHandlerService: UserDataHandlerService,
  ) {
    this.translateService
      .get('SHARED.GENERAL')
      .pipe(take(1))
      .subscribe(translation => {
        if (!this.title) {
          this.title = translation;
        }
      });

    this.isMOStend = location.origin.includes('mosreg');
    this.tag = localStorage.getItem('tag');
  }

  ngOnInit() {
    const userInfo = this.userDataHandlerService.getUserInfo();

    this.window = this.windowRef.getNativeWindow();
    this.userRole = userInfo.role;
    const userId = userInfo.userId;

    this.userDataHandlerService.getUserData().subscribe(userInfo => {
      this.userInfo = userInfo;
    });
    this.getUserFavorites(userId)
      .pipe(
        switchMap(() => {
          return this.sharedService.getUserInfoData().pipe(
            take(1),
            switchMap(() => {
              localStorage.setItem('userGender', this.userInfo.gender);
              //todo разобраться, зачем нужен дополнительный сеттинг профиля, вызывает дублирующие запросы..
              this.profileService.setUserProfile(this.userInfo);
              return this.profileService.getUserProfile().pipe(
                take(1),
                switchMap(() => {
                  if (this.userInfo.schoolId && this.userInfo.schoolId != '00000000-0000-0000-0000-000000000000') {
                    return this.pageHeaderService.getSchoolByID(this.userInfo.schoolId).pipe(
                      switchMap(school => {
                        if (school && school.cityId) {
                          return this.pageHeaderService.getCity(school.cityId).pipe(
                            switchMap(city => {
                              this.regionId = city.regionId;
                              city.regionId && city.regionId != 'null' ? localStorage.setItem('regionId', city.regionId) : null;
                              city.municipalityId && city.municipalityId != 'null'
                                ? localStorage.setItem('municipalityId', city.municipalityId)
                                : null;

                              return this.sharedService.getRegionTestInfo(this.regionId).pipe(
                                tap(data => {
                                  localStorage.setItem('isPilot', data.isPilot);
                                }),
                              );
                            }),
                          );
                        } else {
                          return of(null);
                        }
                      }),
                    );
                  } else {
                    return of(null);
                  }
                }),
              );
            }),
          );
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(() => (this.dataFetched = true));

    this.schoolAdminPanelService
      .getEditPupils()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(data => (this.showAddPupils = data.editPupils));

    this.favoritesService
      .getFavoriteCourses()
      .pipe(
        tap(courses => {
          this.favoriteCourses = courses || [];
        }),
      )
      .subscribe();
    this.favoritesService
      .getFavoriteProfessions()
      .pipe(
        tap(professions => {
          this.favoriteProfessions = professions || [];
        }),
      )
      .subscribe();

    this.pageHeaderService.avatarUpdated$
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        switchMap(r => this.updateUserInfo()),
      )
      .subscribe();
  }

  updateUserInfo(): Observable<any> {
    return this.sharedService.getUserInfoData().pipe(take(1));
  }

  get userFavorites() {
    return this.favoriteCourses.concat(this.favoriteProfessions);
  }

  public sendParentMessage() {
    this.pageHeaderService.sendParentMessage('true');
  }

  // todo check maybe not usable
  public isAllowed() {
    const url = this.router.routerState.snapshot.url;
    this.isAuthorized = localStorage.getItem('isAuthorized');
    if (this.isAuthorized === 'true') {
      if (
        localStorage.getItem('imagePath') != null &&
        localStorage.getItem('lastName') != null &&
        localStorage.getItem('firstName') != null
      ) {
        this.avatar = {
          _avatarURL: localStorage.getItem('imagePath'),
          _shortFIO: localStorage.getItem('lastName') + ' ' + localStorage.getItem('firstName').slice(0, 1),
          _veryShortFIO: localStorage.getItem('lastName').slice(0, 1) + localStorage.getItem('firstName').slice(0, 1),
        };
      } else {
        this.isAuthorized = false;
      }
    }
    // Не покажет header в courses
    return url.indexOf('courses') < 0;
  }

  showMobileMenu() {
    this.menuService.showMobileMenu(true);
  }

  isOpenProfile(): boolean {
    return this.userRole == 'pupil' || this.userRole == 'parent';
  }

  checkNoticeAllow(): boolean {
    return (
      this.userRole == 'pupil' ||
      (this.userRole == 'parent' &&
        this.tag != EUserTags[EUserTags.Default] &&
        this.tag != EUserTags[EUserTags.Prosv] &&
        this.tag != EUserTags[EUserTags.b2c])
    );
  }
  checkNotificationAllow(): boolean {
    return this.isMOStend;
  }

  isBackButtonWithUrlShow(): boolean {
    return this.backButtonName !== '' && this.backButtonUrl !== '';
  }

  showMyFavorite() {
    if (this.userFavorites && this.userFavorites.length > 0) {
      this.router.navigate(['/my-favorites']);
    }
  }

  openMobileMenu() {
    this.pageHeaderService.toggleMobileMenu(true);
  }

  @HostListener('document:click', ['$event'])
  checkClick(event) {
    if (this.notification && !this.notification?.nativeElement.contains(event.target)) {
      this.nav.nativeElement.classList.remove('w--open');
    }
  }

  private getUserFavorites(userId: string): Observable<any> {
    return this.favoritesService.getUserFavorites(userId).pipe(
      tap(userFavorites => {
        this._userFavorites = userFavorites;
      }),
      takeUntil(this.ngUnsubscribe$),
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
