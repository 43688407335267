import { inject, Injectable } from '@angular/core';
import { HttpService } from '../http';
import { map, take } from 'rxjs/operators';
import { ApiInternshipsUrls } from './api-internships.collections';
import { IFilterInternships, IInternship } from './api-internships.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiInternshipsService {
  private readonly httpService: HttpService = inject(HttpService);

  public getFilteredInternships(filters: IFilterInternships) {
    return this.httpService.post(ApiInternshipsUrls.Search, { filters }).pipe(map((response: any) => response.internships));
  }

  public getInternshipsByCompany(id: string): Observable<any> {
    return this.httpService.get(ApiInternshipsUrls.GetByCompany, { id });
  }

  public createInternshipsDO(internship: IInternship): Observable<any> {
    return this.httpService.post(ApiInternshipsUrls.Interships, { internship }).pipe(take(1));
  }

  public changeInternshipsDO(id: string, internship: IInternship): Observable<any> {
    return this.httpService.put(ApiInternshipsUrls.Interships, { id, internship }).pipe(take(1));
  }

  public removeCurrentInternship(id: string): Observable<any> {
    return this.httpService.delete(ApiInternshipsUrls.Interships, { id }).pipe(take(1));
  }

  public getInternshipByHrid(hrid): Observable<any> {
    return this.httpService.get(ApiInternshipsUrls.OneByHrid, { hrid }).pipe(map((response: any) => response.internship));
  }

  public checkHrid(hrid: string): Observable<any> {
    return this.httpService.post(ApiInternshipsUrls.CheckHrid, { hrid }).pipe(take(1));
  }

  public getInternshipsQuery(query: string) {
    return this.httpService.post(ApiInternshipsUrls.Query, { query }).pipe(map((response: any) => response.internships));
  }

  public getInternshipByID(id: string): Observable<any> {
    return this.httpService.get(ApiInternshipsUrls.OneById, { id }).pipe(take(1));
  }

  public getInternshipsCount(filters: IFilterInternships) {
    return this.httpService.post(ApiInternshipsUrls.Count, { filters }).pipe(map((response: any) => response.count));
  }

  public getAllInternships() {
    return this.httpService.get(ApiInternshipsUrls.IntershipsAll).pipe(map((response: any) => response.internships));
  }

  public getInternshipsCountStatistic() {
    return this.httpService.get(ApiInternshipsUrls.CountStatistic).pipe(map((response: any) => response.internshipsCountStatistic));
  }
}
