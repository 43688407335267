import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from 'app/core/services/http.service';
import { UserControlService } from 'app/shared/dashboard/backend-services/user-control.service';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { FavoritesTypes } from 'app/shared/enums/favorites.enums';
import { IFilterClasses } from 'app/shared/interfaces/ifilterclasses.interface';
import { IProfessionFilter } from 'app/shared/interfaces/iprofessionfilter.interface';
import { BehaviorSubject, Observable, of, throwError, TimeoutError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FavoritesService {
  private favoriteCourses$ = new BehaviorSubject(null);
  private favoriteProfessions$ = new BehaviorSubject(null);

  constructor(private http: HttpService, private userControlService: UserControlService, private utilsService: UtilsService) {}

  // обработка ошибок
  handleError(err: any): Observable<any> {
    if (err instanceof TimeoutError) {
      console.error(`Frontend returned timeout error: ${err['error']}`);
      return throwError(err['error']);
    }
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
      let errorText = err.error ? (err.error.comment ? err.error.comment : err.error) : null;
    }
    throw throwError(err);
  }

  getUserFavorites(userId: string, productTypeName?: number): Observable<any> {
    let query = '?' + `userId=${userId}`;
    productTypeName ? query.concat(`&productType=${productTypeName}`) : null;

    return this.http.get('/api/v1.0/profiles/favorites/getfavorites' + query).pipe(
      map(r => r.favorites),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getUserFavoritesCourses(favoriteIds): Observable<any> {
    let credentials: IFilterClasses = {};
    credentials['ids'] = favoriteIds;
    return this.http.post('/api/v1.0/catalog/classes/elasticsearch/search/filters', credentials).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getUserFavoritesProfessions(ids: string[]): Observable<any> {
    let url = '/api/v1.0/catalog/professions/elasticsearch/search/filters';
    let credentials: IProfessionFilter = {};

    credentials.isVisible = true;

    if (ids.length) {
      credentials.ids = [];
      ids.forEach(id => {
        credentials.ids.push(id);
      });
    } else {
      return of([]);
    }
    return this.http.post(url, credentials).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        this.utilsService.openSnackBar('👎 Ошибка на сервере, попробуйте позже', 'error');
        return this.handleError(err);
      }),
    );
  }

  addToFavorites(productId, userId, favoriteType: FavoritesTypes): Observable<any> {
    //check
    let params = {
      productId: productId,
      productType: favoriteType,
      userId: userId,
    };
    return this.http.post('/api/v1.0/profiles/favorites/addtofavorites', params).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  deleteFavorite(productId: string): Observable<any> {
    return this.http.delete('/api/v1.0/profiles/favorites/delete?favoriteId=' + productId).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  setFavoriteCourses(courses: any) {
    this.favoriteCourses$.next(courses);
  }
  getFavoriteCourses(): Observable<any> {
    return this.favoriteCourses$.asObservable();
  }

  setFavoriteProfessions(courses: any) {
    this.favoriteProfessions$.next(courses);
  }
  getFavoriteProfessions(): Observable<any> {
    return this.favoriteProfessions$.asObservable();
  }
}
