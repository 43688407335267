import { inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { HttpService, IHttpStatus } from '../http';
import { ApiAuthUrls } from './api-auth.collections';
import {
  IBvbRegisterData,
  ICheckActivationCode,
  IIsEmailFreeResponse,
  IJwtLoginData,
  IJwtRegisterData,
  IMosRuUserData,
  IProsvetUserData,
  ISchoolPortalUserData,
  IUserAction,
  IUserMurmanskInterface,
  IUserMurmanskLoginData,
} from './api-auth.interface';
import { ILastSession, ILoginResponseData } from '../b2g-saas';

@Injectable({
  providedIn: 'root',
})
export class ApiAuthService {
  private readonly httpService: HttpService = inject(HttpService);

  public logout(): Observable<any> {
    return this.httpService.get(ApiAuthUrls.Logout).pipe(take(1));
  }

  public login(userName: string, password: string, rememberMe: boolean = true): Observable<ILoginResponseData> {
    return this.httpService.post<ILoginResponseData>(ApiAuthUrls.Login, { userName, password, rememberMe }).pipe(take(1));
  }

  public loginMosruUser(userId: string, email: string): Observable<any> {
    return this.httpService.post(ApiAuthUrls.LoginMosruUser, { userId, email }).pipe(take(1));
  }

  public getMosruAuthUrl(): Observable<any> {
    return this.httpService.get(ApiAuthUrls.GetAuthMosruUrl).pipe(take(1));
  }

  public getAccess(userId: string): Observable<any> {
    return this.httpService.get(ApiAuthUrls.CheckAccess, { userId }).pipe(take(1));
  }

  public getLastSession(userId: string): Observable<ILastSession> {
    return this.httpService.get<ILastSession>(ApiAuthUrls.GetLastSession, { userId }).pipe(take(1));
  }

  public checkCookie(url: string = ''): Observable<any> {
    return this.httpService.get(url ? url : ApiAuthUrls.CheckCookie).pipe(take(1));
  }

  public checkPhoneAvailability(phone: string): Observable<any> {
    return this.httpService.get(ApiAuthUrls.CheckPhoneAvailability, { phone }).pipe(take(1));
  }

  public checkEmailAvailability(email: string): Observable<IIsEmailFreeResponse> {
    return this.httpService.post<IIsEmailFreeResponse>(ApiAuthUrls.CheckEmailAvailability, email).pipe(take(1));
  }

  public getMesAuthUrl(): string {
    const xmlHttp: XMLHttpRequest = new XMLHttpRequest();
    xmlHttp.open('GET', ApiAuthUrls.GetAuthMesUrl, false);
    xmlHttp.send();
    const firstIndex: number = xmlHttp.responseText.indexOf('https');
    const lastIndex: number = xmlHttp.responseText.indexOf('"><');
    return xmlHttp.responseText.slice(firstIndex, lastIndex);
  }

  public getMosruUserData(authCode: string): Observable<IMosRuUserData> {
    return this.httpService.post<IMosRuUserData>(ApiAuthUrls.GetMosruUserData, { authCode }).pipe(take(1));
  }

  public confirmEmail(userName: string, rawCode: string): Observable<any> {
    const code: string = rawCode.split(' ').join('+');
    return this.httpService.post(ApiAuthUrls.EmailVerification, { userName, code }).pipe(take(1));
  }

  public sendConfirmationLink(email: string): Observable<any> {
    return this.httpService.post(ApiAuthUrls.SendConfirmationLink, { email }).pipe(take(1));
  }

  public updatePasswordByEmail(email: string, password: string, token: string): Observable<any> {
    const passwordResetToken: string = token.split(' ').join('+');
    if (passwordResetToken) {
      return this.httpService.post(ApiAuthUrls.ResetPasswordByEmail, { email, password, passwordResetToken }).pipe(take(1));
    } else {
      return of(null);
    }
  }

  public updatePasswordByPhone(phoneNumber: string, password: string, token: string): Observable<any> {
    const passwordResetToken: string = token.split(' ').join('+');
    if (passwordResetToken) {
      return this.httpService.post(ApiAuthUrls.ResetPasswordByPhone, { phoneNumber, password, passwordResetToken }).pipe(take(1));
    } else {
      return of(null);
    }
  }

  public resetPasswordToRegCode(email: string): Observable<any> {
    return this.httpService.post(ApiAuthUrls.ResetPasswordCode, { email }).pipe(take(1));
  }

  public checkActivationCode(code: string): Observable<ICheckActivationCode> {
    return this.httpService.get<ICheckActivationCode>(ApiAuthUrls.CheckActivationCode, { code }).pipe(take(1));
  }

  public registrationSP(credentials): Observable<any> {
    return this.httpService.post(ApiAuthUrls.RegisterDnevnik, credentials).pipe(take(1));
  }

  public loginSP(userId: string, email: string): Observable<any> {
    return this.httpService.post(ApiAuthUrls.LoginDnevnik, { userId, email }).pipe(take(1));
  }

  public getUserDataSP(authCode: string): Observable<ISchoolPortalUserData> {
    return this.httpService.post<ISchoolPortalUserData>(ApiAuthUrls.GetUserDnevnik, { authCode }).pipe(take(1));
  }

  public getAuthUrlSP(): Observable<any> {
    return this.httpService.get(ApiAuthUrls.GetAuthUrlDnevnik).pipe(take(1));
  }

  public getUserDataMurmansk(code: string): Observable<IUserMurmanskInterface> {
    return this.httpService.get<IUserMurmanskInterface>(ApiAuthUrls.GetUserMurmansk, { code }).pipe(take(1));
  }

  public loginMurmansk(userId: string, email: string): Observable<IUserMurmanskLoginData> {
    return this.httpService.post<IUserMurmanskLoginData>(ApiAuthUrls.LoginMurmansk, { userId, email }).pipe(take(1));
  }

  public getAuthUrlMurmansk(): Observable<string> {
    return this.httpService.get(ApiAuthUrls.GetAuthUrlMurmansk).pipe(map((response: any) => response.url));
  }

  public getUserDataProsvet(authCode: string): Observable<IProsvetUserData> {
    return this.httpService.post<IProsvetUserData>(ApiAuthUrls.GetUserProsvet, { authCode }).pipe(take(1));
  }

  public loginProsvet(userId: string, email: string): Observable<any> {
    return this.httpService.post(ApiAuthUrls.LoginProsvet, { userId, email }).pipe(take(1));
  }

  public registrationProsvet(userData: any): Observable<any> {
    return this.httpService.post(ApiAuthUrls.RegisterProsvet, { userData }).pipe(take(1));
  }

  public getAuthUrlProsvet(): Observable<any> {
    return this.httpService.get(ApiAuthUrls.GetAuthUrlProsvet).pipe(take(1));
  }

  public logUserActions(action: IUserAction): Observable<IHttpStatus> {
    return this.httpService.post<IHttpStatus>(ApiAuthUrls.LogUserAction, action).pipe(take(1));
  }

  public getBvbLoginData(jwt: string): Observable<IBvbRegisterData> {
    return this.httpService.post<IBvbRegisterData>(ApiAuthUrls.RegisterBvb, { jwt }).pipe(take(1));
  }

  public checkUserSelectionBvb(userId: string): Observable<boolean> {
    return this.httpService.get<boolean>(ApiAuthUrls.CheckUserSelection, { userId }).pipe(take(1));
  }

  public getJwtLoginData(jwt: string): Observable<IJwtRegisterData> {
    return this.httpService.post<IJwtRegisterData>(ApiAuthUrls.RegisterJwt, { jwt }).pipe(take(1));
  }

  public loginJwtUser(userId: string, email: string): Observable<IJwtLoginData | null> {
    return this.httpService.post<IJwtLoginData>(ApiAuthUrls.LoginJwt, { userId, email }).pipe(take(1));
  }
}
