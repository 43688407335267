<div>
  <div class="testing-progress-bar-block">
    <div class="testing-progress-bar" [ngStyle]="{ width: ((slideNumber - 1) * 100) / (slidesCount - 1) + '%' }"></div>
  </div>
  <div class="testing-progress-bar-title">
    <a *ngIf="userRole" [routerLink]="['/' + userRole]" class="testing-logo-link w-inline-block">
      <img src="./assets/images/logo-color.svg" alt="Лого Профилума" />
    </a>
    <div class="header-testing-div">
      <div class="test-title">Тест на таланты</div>
    </div>
  </div>
  <span>
    <prf-section
      (allSlidesCount)="allSlidesCount($event)"
      (currentSlideNumber)="currentSlideNumber($event)"
      [isPreview]="isPreview"
      [screeningTestName]='screeningTestName'
      [enableYM]="enableYM"
      [storageKeyForCachingSessionId]="storageKeyForCachingSessionId"
    ></prf-section>
  </span>

  <div class="footer-section">
    <div class="footer-row">
      <div class="footer-text">© Профилум {{ yearNumber }}</div>
    </div>
  </div>
</div>
