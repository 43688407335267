import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'prf-page-footer-mosobl',
  templateUrl: './page-footer-mosobl.component.html',
  styleUrls: ['./page-footer-mosobl.component.scss'],
})
export class PageFooterMosoblComponent implements OnInit {
  public yearNumber: number = null;
  constructor() {}

  ngOnInit() {
    this.yearNumber = new Date().getFullYear();
  }
}
