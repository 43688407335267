import { Component } from '@angular/core';
import { BaseSliderComponent } from 'app/pages/catalog/profession-page/base-slider/base-slider.component';

@Component({
  selector: 'prf-movie-slider',
  templateUrl: './movie-slider.component.html',
  styleUrls: ['./movie-slider.component.scss'],
})
export class MovieSliderComponent extends BaseSliderComponent {
  constructor() {
    super();
  }
}
