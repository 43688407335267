import { ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { IDynamicComponentInputData } from './load-dynamic-components.interface';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';


@Injectable()
export abstract class LoadDynamicComponentsComponent extends UnsubscribeComponent {
  protected viewContainerRef: ViewContainerRef;

  public loadDynamicComponent<T>(component: T, dynamicComponentInputData?: IDynamicComponentInputData[], clearView: boolean = true): void {
    if (!this.viewContainerRef) {
      return;
    }

    if (clearView) {
      this.clear();
    }

    const componentRef: ComponentRef<T> = this.viewContainerRef.createComponent<typeof component>(component as any);

    dynamicComponentInputData?.forEach((dynamicComponentInputData: IDynamicComponentInputData) => {
      componentRef.instance[dynamicComponentInputData.field] = dynamicComponentInputData.data;
    });
  }

  public clear(): void {
    this.viewContainerRef?.clear();
  }
}
