import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { B2gSaasService, HttpService } from '../../api-services';
import { UserRoles } from '../../helpers';
import { IUserProfileInfo } from './auth-role.guard';
import { StorageKeys, WebStorageService } from '../../logic-services';
import { AppSettingsService, AuthHandlerService } from '../../utils-services';

@Injectable()
export class AuthGuard {
  private userInfo: IUserProfileInfo;

  constructor(
    private router: Router,
    private http: HttpService,
    private appSettings: AppSettingsService,
    public route: ActivatedRoute,
    private b2gSaasService: B2gSaasService,
    private authHandlerService: AuthHandlerService,
    private webStorageService: WebStorageService,
  ) {}

  async canActivate(): Promise<boolean> {
    this.userInfo = this.webStorageService.get(StorageKeys.UserProfileInfo);
    return await this.b2gSaasService
      .getUserInfo()
      .toPromise()
      .then(ui => {
        let locationPrefix: string = this.appSettings.currentLocation + '-'; // Префикс роута
        locationPrefix = locationPrefix.replace('base-', ''); // Если базовый лендинг, то убираем префикс

        if (this.userInfo?.role === UserRoles.admin) {
          const regionData: { id: string; url: string } = AppSettingsService.settings.regionIdsToRedirectOnRegionalDashboard?.find(
            (reg: { id: string; url: string }) => reg.id === ui.regionId,
          );

          if (regionData) {
            window.location.href = regionData.url;
            return false;
          }
        }

        this.authHandlerService.checkCookie();

        if (this.userInfo?.role == undefined) {
          this.authHandlerService.logout();
          return false;
        }
        // проверяем needToUpdate, если true, перенаправляем ученика на обновление профиля
        if (this.userInfo?.role === 'pupil' && ui.needToUpdate) {
          this.router.navigate(['/update-user-info']);
          return false;
        }

        this.router.events.subscribe(e => {
          if (e instanceof NavigationStart && e.url === '/parent' && localStorage.getItem('tag') === 'MosRu') {
            // для mosru пользователей домашняя страница - '/mosruabout'
            this.router.navigate(['/mosruabout']);
            return false;
          }
          if (e instanceof NavigationEnd) {
            switch (e.url) {
              case '/admin':
              case '/cityadmin':
              case '/adminDO':
              case '/employer':
              case '/schooladmin':
              case '/director':
              case '/teacher':
              case '/pupil':
              case '/tutor': {
                this.router.navigate(['/' + this.userInfo?.role]);
                return false;
              }
              case '/parent': {
                if (this.userInfo.tag === 'MosRu') {
                  // для mosru пользователей домашняя страница - '/mosruabout'
                  // срабатывает при переходе по внешней ссылке
                  this.router.navigate(['/mosruabout']);
                  return false;
                } else {
                  this.router.navigate(['/' + this.userInfo?.role]);
                  return false;
                }
              }
              default:
                break;
            }
          }
        });

        return true;
      })
      .catch(() => {
        this.router.navigate(['/']);
        return false;
      });
  }
}
