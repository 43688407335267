<div class="filter-div" #container>
  <div [class.w--open]="open" data-delay="0" class="professions-dropdown w-dropdown">
    <div
      (click)="open = !open"
      [class.w--open]="open"
      class="professions-dropdown-toggle w-dropdown-toggle"
      [ngClass]="{ active: selectedItem }"
    >
      <div class="professions-dd-icon-2 w-icon-dropdown-toggle"></div>
      <div>{{ sortingTitle }}</div>
    </div>

    <nav [class.w--open]="open" class="dd-prof-filter-list w-dropdown-list">
      <div class="dd-prof-filter-div">
        <div class="dd-prof-filter-clear_button">
          <a (click)="clearSorting()"> Очистить </a>
        </div>
        <a *ngFor="let order of itemsList" (click)="itemSelect(order)" class="dd-prof-filter-link w-dropdown-link">{{ order?.name }}</a>
      </div>
    </nav>
  </div>
</div>
