import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { WindowRef } from '../../../services/windowRef.service';
import { IPaymentForm } from './payment-form.interface';

@Component({
  selector: 'prf-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.scss'],
})
export class PaymentFormComponent implements OnInit, OnDestroy {
  @Input()
  public isClaim: boolean = false;
  @Input()
  public isAuthorized: boolean = false;
  @Input()
  public productObject: string;

  public product: IPaymentForm;

  public rulesAccepted: boolean = false;

  private handlers: Subscription[] = [];

  constructor(private meta: Meta, private activatedRoute: ActivatedRoute, private window: WindowRef) {
    this.meta.updateTag({ name: 'og:title', content: 'Оплата' });
  }

  ngOnInit() {
    if (this.productObject) {
      this.product = JSON.parse(this.productObject);
    }
    this.handlers.push(
      this.activatedRoute.queryParams.subscribe(params => {
        if (!this.isAuthorized) {
          this.isAuthorized = params.isAuthorized === 'true';
        }
        if (params.isClaim) {
          this.isClaim = params.isClaim === 'true';
        }
        if (!this.product && params.productObject) {
          this.product = JSON.parse(params.productObject || '') || {};
        }
        let win = this.window.getNativeWindow();
        if (win) {
          win.scrollTo(0, 0);
        }
      }),
    );
  }

  ngOnDestroy() {
    this.handlers.forEach(h => h.unsubscribe());
  }

  onChangedRulesSwitch() {
    this.rulesAccepted = !this.rulesAccepted;
  }
}
