import { IFavoriteProfessionInterface } from './ifavoriteprofession.interface';

export interface IExtendedPupil {
  favoriteProfessions: IFavoriteProfessionInterface[];
  lastSessionId: string;
  lastTestDate: string;
  lastSessionCompleted: boolean;
  lastParentSessionId: string;
  lastParentSessionCompleted: boolean;
  pupilId: number;
  firstName: string;
  lastName: string;
  middleName: string;
  userId: string;
  userHrid: number;
  isActivated: boolean;
  isTested: boolean;
  registrationCode: string;
  schoolClassId: string;
  imagePath: string;
  email: string;
  phoneNumber: string;
  gender: string;
  birthday: string;
}

export class ExtendedPupil implements IExtendedPupil {
  favoriteProfessions: IFavoriteProfessionInterface[];
  lastSessionId: string;
  lastTestDate: string;
  lastSessionCompleted: boolean;
  lastParentSessionId: string;
  lastParentSessionCompleted: boolean;
  pupilId: number;
  firstName: string;
  lastName: string;
  middleName: string;
  userId: string;
  userHrid: number;
  isActivated: boolean;
  isTested: boolean;
  registrationCode: string;
  schoolClassId: string;
  imagePath: string;
  email: string;
  phoneNumber: string;
  gender: string;
  birthday: string;

  constructor(user: any) {
    this.favoriteProfessions = [];
    this.lastSessionId = null;
    this.lastTestDate = null;
    this.lastSessionCompleted = false;
    this.lastParentSessionId = null;
    this.lastParentSessionCompleted = false;
    this.pupilId = user.pupilId;
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.middleName = user.middleName;
    this.userId = user.userId;
    this.userHrid = user.userHrid;
    this.isActivated = user.isActivated;
    this.isTested = user.isTested;
    this.registrationCode = user.registrationCode;
    this.schoolClassId = user.schoolClassId;
    this.imagePath = user.imagePath;
    this.email = user.email;
    this.phoneNumber = user.phoneNumber;
    this.gender = user.gender;
    this.birthday = user.birthday;
  }
}
