<div *ngIf="answerInfo" class="prf-range-container">
  <label class="prf-label" [for]="answerInfo.answerId">{{ answerInfo.text }}</label>
  <div class="prf-input-container">
    <div
      [class.red]="+ranger.value === rangeValues.DefinitelyNotMe"
      [class.red-orange]="+ranger.value === rangeValues.RatherNotMe"
      [class.orange]="+ranger.value === rangeValues.Average"
      [class.yellow]="+ranger.value === rangeValues.RatherMe"
      [class.green]="+ranger.value === rangeValues.DefinitelyMe"
      class="prf-range"
      #container
    >
      <input
        [id]="answerInfo.answerId"
        class="prf-input"
        type="range"
        min="0"
        max="4"
        step="1"
        #ranger
        (mousedown)="addClass(thumb); addClass(container)"
        (mouseup)="removeClass(thumb); removeClass(container)"
        (input)="changeValue(+ranger.value)"
        [(ngModel)]="currentValue"
      />
      <button #thumb type="button" class="prf-thumb">
        <span class="prf-text">{{ getText(+ranger.value) }}</span>
      </button>
      <div class="prf-text-mobile">{{ getText(+ranger.value) }}</div>
    </div>
    <button
      type="button"
      class="prf-button minus"
      [class.hidden]="+ranger.value === rangeValues.DefinitelyNotMe"
      (click)="decrease(ranger.value)"
    >
      -
    </button>
    <button
      type="button"
      class="prf-button plus"
      [class.hidden]="+ranger.value === rangeValues.DefinitelyMe"
      (click)="increase(ranger.value)"
    >
      +
    </button>
  </div>
</div>
