import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { IMunicipality } from 'app/shared/interfaces/imunicipality';
import { IRegion } from 'app/shared/interfaces/iregion';
import { OverlayBusyService } from 'app/shared/overlay-busy/overlay-busy.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IMunicipalityView } from '../../../../shared/interfaces/imunicipalityview.interface';
import { RegionsService } from '../regions/regions.service';
import { MunicipalitiesService } from './municipalities.service';

@Component({
  selector: 'prf-municipalities',
  templateUrl: './municipalities.component.html',
  styleUrls: ['./municipalities.component.scss'],
})
export class MunicipalitiesComponent implements OnInit {
  public regions: IRegion[];
  public selectedRegionName: string = '';
  public municipalitiesView: IMunicipalityView[];

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private meta: Meta,
    private municipalitiesService: MunicipalitiesService,
    private regionsService: RegionsService,
    private overlayService: OverlayBusyService,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'Муниципалитеты' });
  }

  ngOnInit() {
    this.getRegions();
  }

  private getRegions() {
    this.regionsService
      .getAllRegions()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(regions => {
        this.regions = regions.sort((r1, r2) => r1.name.localeCompare(r2.name));
      });
  }

  setRegionAsync(region: IRegion) {
    this.overlayService.show();
    this.municipalitiesService
      .getMunicipalitiesByRegion(region.id)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(municipalities => {
        const regionMunicipalities: IMunicipality[] = municipalities;
        this.setMunicipalities(regionMunicipalities, region);
        this.selectedRegionName = region.name;
        this.overlayService.hide();
      });
  }

  private setMunicipalities(municipalities: IMunicipality[], region: IRegion): void {
    let regionMunicipalities = [];
    municipalities.forEach(m => {
      const municipalityView: IMunicipalityView = {
        id: m.id,
        hrid: m.hrid,
        name: m.name,
        regionId: m.regionId,
        regionName: region.name,
      };
      regionMunicipalities.push(municipalityView);
    });
    this.municipalitiesView = regionMunicipalities.sort((m1, m2) => m1.name.localeCompare(m2.name));
  }

  public municipalityAdded(municipalityView: IMunicipalityView): void {
    if (municipalityView && municipalityView.regionName == this.selectedRegionName) {
      this.municipalitiesView.push(municipalityView);
    }
  }

  public municipalityDeleted(municipalityId: string): void {
    this.municipalitiesView = this.municipalitiesView.filter(i => i.id != municipalityId);
  }

  public municipalityUpdate(municipalityView: IMunicipalityView): void {
    if (this.isValidMunicipality(municipalityView)) {
      const changedMunicipality = this.municipalitiesView.find(i => i.id === municipalityView.id);
      const index = this.municipalitiesView.indexOf(changedMunicipality);
      if (index > -1) {
        this.municipalitiesView[index] = Object.assign(this.municipalitiesView[index], municipalityView);
      }
    }
  }

  private isValidMunicipality(municipalityView: IMunicipalityView): boolean {
    return municipalityView &&
      municipalityView.id &&
      municipalityView.hrid &&
      municipalityView.name &&
      municipalityView.regionId &&
      municipalityView.regionName
      ? true
      : false;
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
