export enum ClassesFormatTypes {
  /// Мастер-класс
  MasterClass = 1,
  /// Экскурсия
  Excursion = 2,
  /// Краткосрочный курс
  ShortCourse = 4,
  /// Долгосрочный курс
  LongCourse = 8,
  /// Фестиваль
  Festival = 16,
  /// Акция
  Action = 32,
  /// Встреча
  Meeting = 64,
  /// Конкурс
  Competition = 128,
  /// Профпроба
  Profproba = 256,
  /// День открытых дверей
  OpenDay = 512,
  /// Задача из каталога
  CatalogTask = 1024,
}
