import { throwError as observableThrowError } from 'rxjs';
import { APP_INITIALIZER, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { HttpService } from '../../api-services';
import { IAppSettings, LocationEnum } from './app-settings.interface';
import { isDevEnvironment } from '../../../../../profilum-configurations/tools/tools';
import { Services } from '../../../../../profilum-configurations/services/services';

@Injectable()
export class AppSettingsService {
  static settings: IAppSettings;
  fileUrl: string;

  private defaultTranslation: any;

  constructor(
    private httpService: HttpService,
    private translate: TranslateService,
  ) {}

  load() {
    console.log('load settings');
    this.fileUrl = this.getFileUrl();
    return new Promise(resolve => {
      this.httpService.getFile(this.fileUrl).subscribe(
        data => {
          AppSettingsService.settings = data.body;
          resolve(true);
        },
        (error: any) => {
          console.error(error);
          return observableThrowError(error.message || 'Server error');
        },
      );
    });
  }

  private getFileUrl(): string {
    const isLocalhost = location.hostname.includes('localhost');
    // данные условия надо менять при локальном тестировании регионов
    const isDev = isDevEnvironment(Services.B2G);

    const isMosObl = location.hostname.includes('mosreg');
    // TODO поменять условие
    const isKazakstan = location.hostname.includes('kz');
    //const isKazakstan = true;

    if (isMosObl) {
      return isDev || isLocalhost
        ? './assets/appsettings/appsettings.mosobl.dev.json'
        : './assets/appsettings/appsettings.mosobl.prod.json';
    } else if (isKazakstan) {
      return isDev ? './assets/appsettings/appsettings.kazakstan.dev.json' : './assets/appsettings/appsettings.kazakstan.prod.json';
    } else if (isLocalhost) {
      return './assets/appsettings/appsettings.default.dev.json';
    } else {
      return isDev ? './assets/appsettings/appsettings.default.dev.json' : './assets/appsettings/appsettings.default.prod.json';
    }
  }

  /**
   * Метод для получения текущего лендинга
   *
   */
  public get currentLocation(): LocationEnum {
    if (location.href.includes(LocationEnum.MOSOBL)) {
      return LocationEnum.MOSOBL;
    }
    if (location.href.includes(LocationEnum.KAZAZKHSTAN)) {
      return LocationEnum.KAZAZKHSTAN;
    }
    if (location.href.includes(LocationEnum.KRUZHKI)) {
      return LocationEnum.KRUZHKI;
    }
    if (location.href.includes(LocationEnum.YANAO)) {
      return LocationEnum.YANAO;
    }
    if (location.href.includes(LocationEnum.BASHKORTOSTAN)) {
      return LocationEnum.BASHKORTOSTAN;
    }
    if (location.href.includes(LocationEnum.MURMANSK)) {
      return LocationEnum.MURMANSK;
    }
    if (location.href.includes(LocationEnum.PSKOV)) {
      return LocationEnum.PSKOV;
    }
    if (location.href.includes(LocationEnum.EUROCHEM)) {
      return LocationEnum.EUROCHEM;
    }

    return LocationEnum.BASE;
  }

  public setDefaultTranslation(): void {
    this.translate
      .getTranslation(this.translate.defaultLang)
      .pipe(map(translations => (this.defaultTranslation = translations)))
      .subscribe();
  }

  public getByDefaultLocale(key: string): any {
    let result: any = this.defaultTranslation;
    if (!result) return;
    for (const p of key.split('.')) {
      if (result[p] == undefined) {
        return null;
      }
      result = result[p];
    }
    return result;
  }
}

export function AppsettingsFactory(appsettingsService: AppSettingsService): any {
  return () => appsettingsService.load();
}

export function init() {
  return {
    provide: APP_INITIALIZER,
    useFactory: AppsettingsFactory,
    deps: [AppSettingsService],
    multi: true,
  };
}

const AppsettingsModule = {
  init: init,
};

export { AppsettingsModule };
