import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Page500RoutingModule } from './page-500-routing.module';
import { Page500Component } from './page-500.component';

@NgModule({
  declarations: [Page500Component],
  imports: [CommonModule, Page500RoutingModule],
})
export class Page500Module {}
