<button mat-button (click)="openDialog()">{{ 'BUTTONS.DELETE' | translate }}</button>

<div class="editors-modal" *ngIf="dialogOpen" [ngClass]="dialogOpen ? 'active-modal' : ''">
  <div class="editors-modal-wrapper">
    <div class="editors-modal-body">
      <div class="editors-modal-dialog">
        <span class="editors-modal-dialog-close" (click)="dialogOpen = !dialogOpen">
          <i class="far fa-times-circle"></i>
        </span>
        <div class="editors-modal-dialog-body">
          <p>Вы уверены что хотите удалить центр?</p>
          <div class="group-btn">
            <button mat-raised-button (click)="delete()">Да</button>
            <button mat-raised-button (click)="dialogOpen = !dialogOpen">Нет</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
