import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IAnswerInfo } from '@profilum-api-services/api-slides/api-slides.interface';
import { TestBoxHelperComponent } from '@profilum-components/prf-tests/test-helper/test-box-helper.component';
import { RectangleImageViewComponent } from './rectangle-image-view/rectangle-image-view.component';

@Component({
  standalone: true,
  selector: 'prf-rectangle-image-box',
  templateUrl: './rectangle-image-box.component.html',
  styleUrls: ['./rectangle-image-box.component.scss'],
  imports: [CommonModule, RectangleImageViewComponent],
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RectangleImageBoxComponent extends TestBoxHelperComponent implements OnInit {
  public ngOnInit(): void {
    this.setupRequiredFields();
  }

  public trackByFn(index: number, answer: IAnswerInfo): string {
    return answer.answerId;
  }
}
