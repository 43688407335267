import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { IAnswerInfo } from '@profilum-api-services/api-slides/api-slides.interface';
import { TestViewHelperComponent } from '@profilum-components/prf-tests/test-helper/test-view-helper.component';
import { Helper } from '@profilum-helpers/common-helper/helper';
import { TranslateModule } from '@ngx-translate/core';


@Component({
  selector: 'prf-rectangle-image-view',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, TranslateModule],
  templateUrl: './rectangle-image-view.component.html',
  styleUrls: ['./rectangle-image-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RectangleImageViewComponent extends TestViewHelperComponent implements OnInit {
  @Output() public selectedAnswer: EventEmitter<IAnswerInfo> = new EventEmitter<IAnswerInfo>();
  public src: string;

  public ngOnInit(): void {
    this.init();
    this.src = Helper.getImageSrc(this.answerInfo);
    this.selectAnswerFromStorage();
  }
}
