export interface IResults {
  id: string;
  aggregateId: string;
  name: string;
  description: string;
  objectType: string;
  imageId: string;
  imageUrl: string;
  data: IResultsData;
  results: ITestResults[];
}

export interface IResult {
  id: string;
  aggregateId: string;
  name: string;
  description: string;
  objectType: string;
  imageId: string;
  imageUrl: string;
  data: any;
  objects: any;
  aggregates: string;
  results: {
    normalizedValue?: string;
    sessionId: string;
    value: number;
    transformedValue: number;
  }[];
  programms: any;
}

export interface IResultsData {
  fieldNames?: string[];
}

export interface ITestResults {
  sessionId: string;
  value: number;
  transformedValue: number;
  normalizedValue: number;
}

export interface ITestResultsAdditionalText {
  name: string;
  shortDescription: string;
  text: string;
}

export interface IGetDataForResultsPageResponse {
  results: IResult[];
  additionalText: ITestResultsAdditionalText;
  data: string;
  createdDate?: string;
}

export interface ITestResultsAnswerPercents {
  percents: number;
  id?: string;
  name?: string;
  description?: string;
  imageId?: string;
  imageUrl?: string;
  data?: string;
  count?: number;
  sessionIds?: string[];
  interestScale?: number;
}

export interface ITestResultsAnswerWeb {
  axis: string;
  description: string;
  value: number;
}

import { INoResponseData } from '../http';

export interface ISaveUserAnswersRequest {
  sessionId: string;
  userAnswers: IUserAnswer[];
  screeningTestId?: string;
  variantId?: string;
}

export interface ISaveUserAnswersResponse extends INoResponseData {}

export interface IGetUserAnswersResponse extends IUserAnswer {}

export interface IUserAnswer {
  questionId: string;
  answerId: string;
  questionSectionId: string;
  answerType: string;
  data: string;
  sessionId?: string;
  screeningTestId?: string;
  variantId?: string;
  createdDate?: string;
}
