<section class="error">
  <section class="error-container">
    <span><span>5</span></span>
    <span>0</span>
    <span><span>0</span></span>
  </section>
  <div class="error-description">
    <p>Упс, что то пошло не по плану, может попробуем <a routerLink="/">заново?</a></p>
  </div>
</section>
