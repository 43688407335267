import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { mergeMap, takeUntil, tap } from 'rxjs/operators';
import { ProfessionsService } from '../../dashboard/professions/professions.service';

export interface ISortedItem {
  key: string;
  name: string;
}

@Component({
  selector: 'prf-dropdown-sorting',
  templateUrl: './dropdown-sorting.component.html',
  styleUrls: ['./dropdown-sorting.component.scss'],
})
export class DropdownSortingComponent implements OnInit {
  @Input() set itemsList(val: ISortedItem[]) {
    this._itemsList = val;
  }
  get itemsList() {
    return this._itemsList;
  }
  _itemsList: ISortedItem[] = [];
  public open: boolean = false;
  public selectedItem: ISortedItem;
  public sortingTitle: string;

  private ngUnsubscribe$: Subject<any> = new Subject();
  @ViewChild('container') container: ElementRef;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.container?.nativeElement.contains(event.target)) {
      this.open = false;
    }
  }

  constructor(private eRef: ElementRef, private professionService: ProfessionsService) {}

  ngOnInit() {
    this.sortingTitle = this.itemsList[0].name;
    this.getSorting();
  }

  private getSorting() {
    this.professionService
      .getSortingProfession()
      .pipe(
        tap((sorting: ISortedItem) => {
          let selectedItem = this.itemsList.find(item => item == sorting);
          this.selectedItem = selectedItem ? selectedItem : null;
          this.sortingTitle = this.selectedItem ? this.selectedItem.name : this.itemsList[0].name;
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  itemSelect(item: ISortedItem) {
    this.selectedItem = item;
    this.professionService.setSortingProfession(item);
    this.open = false;
  }

  clearSorting() {
    this.professionService.setSortingProfession(null);
    this.open = false;
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
