import { Injectable } from '@angular/core';
import { HttpService } from 'app/core/services/http.service';
import { map } from 'rxjs/operators';

@Injectable()
export class ChangePasswordService {
  constructor(private http: HttpService) {}

  public updatePassword(login: string, password: string, token: string) {
    let replacedToken = token.split(' ').join('+');
    const model = {
      email: login,
      password: password,
      passwordResetToken: replacedToken,
    };
    return this.http.post('/api/v1.0/auth/account/resetpassword', model).pipe(map(resp => resp));
  }

  public getUserSession(userId: string) {
    return this.http.get('/b2c/v1.0/saas/getlastsession?userId=' + userId).pipe(map(resp => resp));
  }
}
