import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'prf-not-selected-profession',
  templateUrl: './not-selected-profession.component.html',
  styleUrls: ['./not-selected-profession.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotSelectedProfessionComponent {
  @Input() name: string = '';
}
