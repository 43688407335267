import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  CoursesFiltersService,
} from 'app/shared/dashboard/courses/courses-catalog/courses-filter/courses-filters.service';
import { takeUntil } from 'rxjs/operators';
import { CoursesListService } from '../../../../../courses-page-without-filters/courses-list.service';
import { Subject } from 'rxjs';
import { ITalentsFilter } from 'app/shared/interfaces/ifilterclasses.interface';

@Component({
  selector: 'prf-select-talents',
  templateUrl: './select-talents.component.html',
  styleUrls: ['./select-talents.component.scss'],
})
export class SelectTalentsComponent implements OnInit, OnDestroy {
  talents: any;
  selectedTalents: any = [];
  filters: ITalentsFilter;
  searchTalents: string;
  _existingTalents: any; //переменная нужна для временного хранения уже существующих тематик при редактировании мероприятия

  @ViewChild('ddTalents') ddTalents: ElementRef;
  @ViewChild('talentsDrop') dropdown: ElementRef;
  @Output() dataSelectTalents = new EventEmitter();

  private ngUnsubscribe$: Subject<any> = new Subject();

  @Input()
  set existingTalents(val: any) {
    this._existingTalents = val;
    this.filters = {};
    this.filters.TalentsName = val;
    this.filtersService.setTalentsFilter(this.filters);
  }

  constructor(private coursesListService: CoursesListService, private filtersService: CoursesFiltersService) {}

  ngOnInit() {
    this.filtersService
      .getTalentsFilters()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(data => {
        this.filters = data;
        if (this._existingTalents) {
          this.filters.TalentsName = this._existingTalents;
        }
        this.selectedTalents = data.TalentsName ? data.TalentsName : [];
        this.dataSelectTalents.emit(this.filters.TalentsName);
      });

    this.coursesListService
      .getTalents()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(data => {
        this.talents = data;
      });
  }

  selectTalent(name: string) {
    if (!this.filters.TalentsName) {
      this.filters.TalentsName = [name];
    } else {
      let index = this.filters.TalentsName.indexOf(name);
      index !== -1 ? this.filters.TalentsName.splice(index, 1) : this.filters.TalentsName.push(name);
    }
    this._existingTalents = this.filters.TalentsName;
    this.dataSelectTalents.emit(this.filters.TalentsName);
    this.filtersService.setTalentsFilter(this.filters);
  }

  deselectTalents() {
    this.filters.TalentsName = null;
    this._existingTalents = null;
    this.filtersService.setTalentsFilter(this.filters);
  }

  checkFilterHeader() {
    if (this.filters.TalentsName && this.filters.TalentsName.length > 0) {
      if (this.filters.TalentsName.length > 1 && this.filters.TalentsName.length < 5) {
        return this.filters.TalentsName.length + ' таланта';
      } else if (this.filters.TalentsName.length >= 5) {
        return this.filters.TalentsName.length + ' талантов';
      } else {
        return this.filters.TalentsName[0];
      }
    } else {
      return 'Таланты';
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }

  @HostListener('document:click', ['$event.target'])
  checkClick(target) {
    if (this.ddTalents && !this.ddTalents?.nativeElement.contains(target)) {
      this.dropdown.nativeElement.classList.remove('w--open');
    }
  }
}
