import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'prf-mine-courses',
  templateUrl: './mine-courses.component.html',
  styleUrls: ['./mine-courses.component.scss'],
})
export class MineCoursesComponent {
  public classesCount: string;
  public viewValue: boolean = false;

  constructor(private meta: Meta) {
    this.meta.updateTag({ name: 'og:title', content: 'Мои курсы' });
  }
}
