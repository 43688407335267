<section class="pieChart-container">
  <h1>{{ 'TEST_AND_RESULTS.SKILS.INTERESTS' | translate }}</h1>
  <canvas
    baseChart
    [datasets]="barChartData"
    [labels]="barChartLabels"
    [type]="barChartType"
    [legend]="barChartLegend"
    [options]="barChartOptions"
  >
  </canvas>
</section>
