import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { RequestCache } from '../services/request-cache.service';

@Injectable()
export class CachingInterceptor implements HttpInterceptor {
  // TODO: этот кеш не даёт гибкости. Избавиться от него, когда появится редакс.
  req_list = [
    'translations/ru.json',
    'professions/all',
    'getresultpages',
    'search/filters',
    'getschool',
    'classescountstatistic',
    'groups',
    'catalog/regions/all',
    'catalog/search/talentgroupcourse',
    'catalog/classes/elasticsearch/classescountstatistic',
    'getschoolclassstatistic',
    'getschoolclasses',
  ]; // Запросы, которые нужно кешировать
  userId: string = '';
  constructor(private cache: RequestCache) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let isCached = false;
    this.req_list.forEach(r => {
      if (req.url.indexOf(r) != -1) {
        return (isCached = true);
      }
    });

    let isSkipCachingUserInfo = localStorage.getItem('skipCaching') == 'true' && req.url.indexOf('getuserinfo') != -1;

    if (req.method === 'GET' && isSkipCachingUserInfo) {
      localStorage.setItem('skipCaching', 'false');
      return this.sendRequest(req, next, this.cache);
    } else if (req.method === 'GET' && isCached) {
      const cachedResponse = this.cache.get(req);
      return cachedResponse ? of(cachedResponse) : this.sendRequest(req, next, this.cache);
    } else if (req.method === 'POST' && isCached && req.url.indexOf('classescountstatistic') != -1) {
      const cachedResponse = this.cache.get(req);
      return cachedResponse ? of(cachedResponse) : this.sendRequest(req, next, this.cache);
    } else {
      return next.handle(req);
    }
  }

  sendRequest(req: HttpRequest<any>, next: HttpHandler, cache: RequestCache): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          cache.put(req, event);
        }
      }),
    );
  }
}
