export enum QuestionTypes {
  Single = 'Single',
  Multi = 'Multi',
}

export enum SectionTypes {
  Positive = 'Positive',
  Negative = 'Negative',
}

export enum RangeValues {
  DefinitelyNotMe = 0,
  RatherNotMe = 1,
  Average = 2,
  RatherMe = 3,
  DefinitelyMe = 4,
}

export const TEXTAREA_MAX_LENGTH: number = 512;
export const TEXTAREA_MIN_LENGTH: number = 2;

export enum ETestTypes {
  DEFAULT_STANDART,
  DEFAULT_360,
  PROFESSIONPRESTIGE,
  MOTIVATION_TEST,
  VALUES_TEST,
  PROMO_TEST,
  MINCIFRYDEMO_TEST,
  BVB_TEST,
  VK_TEST,
  CAMP_TEST,
  MINCIFRY_TEST,
  BVB_TEST_VISUALLY_IMPAIRED,
  WEB_TEST,
  INTERESTS_TEST,
  CAPABILITIES_TEST,
  CAREER_LITERACY_TEST,
  FRONTEND_DEV_TEST,
  BVB_TEST_PARENT,
  BVB_TEST_RAS,
  BVB_APPROBATION_LITE,
  MUF,
  BVB_TEST_OPEN_CONTOUR,
  MY_FUTURE,
  PREPARING_FOR_THE_FUTURE,
  SOUTH_AFRICA,
  PROTVORCHESTVO,
  MY_TALENTS,
  MY_HORIZONS
}
