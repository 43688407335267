<ng-container *ngIf="dataLoaded">
  <div class="filter-div cat" #regionFilter>
    <div class="professions-dropdown w-dropdown" [ngClass]="{ disabled: regionDisabled }" [style.z-index]="regionOpen ? 1001 : 0">
      <div
        class="professions-dropdown-toggle w-dropdown-toggle"
        (click)="regionOpen = !regionOpen"
        [class.active]="filters && filters.regionId"
      >
        <div class="professions-dd-icon-2 w-icon-dropdown-toggle"></div>
        <div>{{ checkRegionFilterHeader() }}</div>
      </div>
      <nav class="dd-prof-filter-list w-dropdown-list" [class.w--open]="regionOpen">
        <div class="dd-prof-filter-div _2">
          <div class="filter-list-row">
            <ng-container *ngIf="filters && filters.regionId">
              <a class="clear-categories show" (click)="resetRegion()">{{ 'SHARED.CLEAR_BUTTON' | translate }}</a>
            </ng-container>
          </div>
          <div class="scroll-block">
            <div class="prof-categories-links">
              <a
                class="prof-categories-link w-inline-block"
                *ngFor="let region of allRegions"
                [class.w--current]="selectedRegion.id.indexOf(region.id) !== -1"
              >
                <div class="category-text-link" (click)="setRegionFilter(region)">
                  {{ region.name }}
                </div>
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>

  <div class="filter-div cat" #municipalityFilter>
    <div
      class="professions-dropdown w-dropdown"
      [ngClass]="{ disabled: municipalityDisabled }"
      [style.z-index]="municipalityOpen ? 1001 : 0"
    >
      <div
        class="professions-dropdown-toggle w-dropdown-toggle"
        (click)="municipalityOpen = !municipalityOpen"
        [class.disabled]="selectedMunicipality.id === defaultGuid"
        [class.active]="filters && filters.municipalityId"
      >
        <div class="professions-dd-icon-2 w-icon-dropdown-toggle"></div>
        <div>{{ checkMunicipalityFilterHeader() }}</div>
      </div>
      <nav class="dd-prof-filter-list w-dropdown-list" [class.w--open]="municipalityOpen">
        <div class="dd-prof-filter-div _2">
          <div class="filter-list-row">
            <ng-container *ngIf="filters && filters.municipalityId">
              <a class="clear-categories show" (click)="resetMunicipality()">{{ 'SHARED.CLEAR_BUTTON' | translate }}</a>
            </ng-container>
          </div>
          <div class="scroll-block">
            <div class="prof-categories-links">
              <a
                class="prof-categories-link w-inline-block"
                *ngFor="let municipality of municipalitiesByRegion"
                [class.w--current]="selectedMunicipality.id.indexOf(municipality.id) !== -1"
              >
                <div class="category-text-link" (click)="setMunicipalityFilter(municipality)">
                  {{ municipality.name }}
                </div>
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</ng-container>
