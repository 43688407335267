import { IHttpStatus } from '../http';
import { FieldTypes, IField } from '../api-fields';
import { ICompany } from '../api-admins';

export type ProfessionsSlidePageState = 'like' | 'dislike' | null;

export interface IGetProfessionsFormElasticRequest {
  minSalary?: number;
  maxSalary?: number;
  ids?: string[];
  hrids?: string[];
  fields?: string[];
  productGroups?: string[];
  courses?: string[];
  profilClasses?: string[];
  demandRating?: number;
  education?: IEducation;
  regionId?: string;
  municipalityId?: string;
  forTotalRegion?: boolean;
  isVisible?: boolean;
  from?: number;
  size?: number;
  averageSalarySort?: number;
  nameSortingValues?: number;
}

export interface IGetProfessionsFormElasticResponse {
  id: string;
  _id: number;
  createdDate: string;
  publishedDate: string;
  isPublished: boolean;
  hrid: string;
  name: string;
  shortDescription: string;
  mediumDescription: string;
  longDescription: string;
  mainImageId: string;
  mainImagePath: string;
  demandRating: number;
  minSalary: number;
  maxSalary: number;
  averageSalary: number;
  education: IEducation[];
  additionalTexts: IAdditionalText;
  resposibilities: string[];
  knowledge: string[];
  skills: string[];
  abilities: string[];
  articles: IArticle[];
  movies: string[];
  persons: IPerson[];
  facts: string[];
  videos: IVideo[];
  companies: ICompany[];
  images: IImage[];
  fields: IField[];
  fieldBlock: string;
  productGroups: string[];
  specials: ISpecial[];
  specialities: ISpeciality[];
  additionalEducations: string[];
  courses: ICourse[];
  profilClasses: string[];
  genitive: string;
  dative: string;
  metaDescription: string;
  metaKeywords: string;
  title: string;
  regionId: string;
  municipalityId: string;
  isVisible: boolean;
  isFavourite?: boolean;
  state?: ProfessionsSlidePageState;
}

export interface IGetRandomProfessionsByFieldsRequest {
  fields: string[];
  professionsCount: number;
  regionId?: string;
}

export interface IGetRandomProfessionsByFieldsResponse extends IHttpStatus {
  professions: IGetProfessionsFormElasticResponse[];
}

export interface IAdditionalText {
  educationDescription: string;
  actualCompaniesText: string;
}

export interface IArticle {
  name: string;
  url: string;
}

export interface IPerson {
  name: string;
  description: string;
  imagePath: string;
}

export interface IVideo {
  name: string;
  url: string;
}

export interface ICompanyProfession {
  name: string;
  description: string;
  url: string;
  imageId: string;
}

export interface IImage {
  id: string;
  path: string;
}

export interface ISpecial {
  specialityId: string;
  name: string;
  education: number;
}

export interface ISpeciality {
  bigGroup?: string;
  code?: string;
  educationFieldKind?: {id: number, name: string}
  educationGrade?: number;
  educationLevel?: number;
  fields?: IField[];
  id?: string;
  name?: string;
}

export interface ISpecialityData {
  id: string;
  createdDate?: string;
  name: string;
  govCode: string;
  description: string;
}

export interface ICourse {
  id: string;
  name: string;
}

export interface IEducation {
  level: number;
  isPreferred: boolean;
  perentage: number;
}

export interface IProfessionFilter {
  from?: number;
  size?: number;
  averageSalarySort?: number;
  minSalary?: number;
  maxSalary?: number;
  ids?: string[];
  hrids?: string[];
  fields?: string[];
  productGroups?: string[];
  courses?: string[];
  profilClasses?: string[];
  demandRating?: number;
  education?: {
    level: number;
    isPreferred: boolean;
    perentage: number;
  };
  regionId?: string;
  municipalityId?: string;
  forTotalRegion?: boolean;
  isVisible?: boolean;
  fieldType?: FieldTypes;
}

export interface IProfession {
  id?: any;
  _id: number;
  hrid?: string;
  name: string;
  shortDescription: string;
  mediumDescription?: string;
  longDescription?: string;
  mainImageId?: string;
  mainImagePath: string;
  demandRating?: any;
  minSalary?: number;
  maxSalary?: number;
  averageSalary?: number;
  salaryString?: string; // этого поля нет в модели на backend
  education?: IEducation[];
  resposibilities?: string[];
  knowledge?: string[];
  skills?: string[];
  abilities?: string[];
  articles?: IArticle[];
  movies?: string[];
  persons?: IPerson[];
  facts?: string[];
  videos?: IVideo[];
  companies?: ICompany[];
  imageIds?: string[];
  fieldNames?: string[];
  fieldIds?: string[];
  productGroups?: string[];
  specialities?: ISpeciality[];
  additionalEducations?: string[];
  courses?: ICourse[];
  coursesIds?: string[];
  profilClasses?: string[];
  genitive?: string;
  dative?: string;
  additionalTexts?: IAdditionalText;
  title?: string;
  metaDescription?: string;
  metaKeywords?: string;
  isGoal?: boolean;
  regionId: string;
  municipalityId: string;
  fields?: IField[];
  fieldBlock?: string;
  recommended?: boolean;
  images?: IImage[];
  isVisible?: boolean;
  chosen?: boolean;
}

export interface IProfessionByHrid {
  status: 'string';
  comment: 'string';
  senderService: 'string';
  profession: IProfession;
}

export enum ProfessionTryingOnStage {
  FilteringByInterests = 1,
  FilteringByCapabilities = 2,
  IndustrySequestering = 3,
  Ranking = 4,
  Finish = 5,
}

export enum ProfessionEducationLevel {
  Collage = 2,
  University = 3,
}
