import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';

@Injectable()
export class HttpService {
  private headers = new HttpHeaders({ 'Content-Type': 'application/json;charset=utf-8' });
  private apiUrl = '/api';

  constructor(private http: HttpClient, private router: Router, private utilsService: UtilsService) {}

  get(path, valParams?): Observable<any> {
    let params = {
      params: valParams || null,
    };
    return this.http.get(this.apiUrl + path, params).pipe(
      catchError(error => {
        return throwError(error);
      }),
    );
  }

  getLocalFile(path, valParams?): Observable<any> {
    let params = {
      params: valParams || null,
    };
    return this.http.get(path, params).pipe(
      catchError(error => {
        return throwError(error);
      }),
    );
  }

  post(path, data?): Observable<any> {
    const headers = { headers: this.headers };
    return this.http.post(this.apiUrl + path, JSON.stringify(data || {}), headers).pipe(
      catchError(error => {
        return throwError(error);
      }),
    );
  }

  postFile(path, data?) {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json-patch+json',
    });
    return this.http
      .post(this.apiUrl + path, JSON.stringify(data || {}), {
        headers: header,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        catchError(error => {
          return throwError(error);
        }),
      );
  }

  getFile(path, data?): Observable<any> {
    let header = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json-patch+json',
    });
    return this.http
      .get(this.apiUrl + path, {
        headers: header,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        catchError(error => {
          return throwError(error);
        }),
      );
  }

  postImage(path, data?) {
    let headers = new HttpHeaders({ Accept: 'application/json' });
    const options = { headers: headers };
    return this.http.post(this.apiUrl + path, data, options).pipe(
      catchError(error => {
        return throwError(error);
      }),
    );
  }

  put(path, data?): Observable<any> {
    const headers = { headers: this.headers };
    return this.http.put(this.apiUrl + path, JSON.stringify(data || {}), headers).pipe(
      catchError(error => {
        return throwError(error);
      }),
    );
  }

  delete(path, valParams?: any): Observable<any> {
    let params = {
      params: valParams || null,
    };
    const headers = { headers: this.headers };
    return this.http.delete(this.apiUrl + path, params).pipe(
      catchError(error => {
        return throwError(error);
      }),
    );
  }

  deleteWithObject(path, body?: any): Observable<any> {
    this.headers.set('content-type', 'application/json-patch+json');
    this.headers.set('accept', 'text/plain');
    const headers = { headers: this.headers, body: body || null };
    return this.http.delete(this.apiUrl + path, headers).pipe(
      catchError(error => {
        return throwError(error);
      }),
    );
  }

  postText(path, string): Observable<any> {
    return this.http.post(this.apiUrl + path, string || '').pipe(
      catchError(error => {
        return throwError(error);
      }),
    );
  }

  sendMsgToSlack(str) {
    let xmlhttp = new XMLHttpRequest();
    let webhook_url = 'https://hooks.slack.com/services/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';
    let myJSONStr = `payload= {"username": "FRONT", "icon_emoji": ":information_desk_person:",
    "channel": "#pflr_front_errors", "attachments": [{"text": "${str}"}]}`;
    xmlhttp.open('POST', webhook_url, false);
    xmlhttp.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xmlhttp.send(myJSONStr);
  }
}
