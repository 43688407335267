import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TestBoxHelperComponent } from '@profilum-components/prf-tests/test-helper/test-box-helper.component';
import { GroupedRectangleViewComponent } from './grouped-rectangle-view/grouped-rectangle-view.component';


@Component({
  selector: 'prf-grouped-rectangle-view-box',
  standalone: true,
  imports: [CommonModule, GroupedRectangleViewComponent],
  templateUrl: './grouped-rectangle-view-box.component.html',
  styleUrls: ['./grouped-rectangle-view-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupedRectangleViewBoxComponent extends TestBoxHelperComponent {}
