import { Component } from '@angular/core';
import { BaseSliderComponent } from 'app/pages/catalog/profession-page/base-slider/base-slider.component';

@Component({
  selector: 'prf-books-slider',
  templateUrl: './books-slider.component.html',
  styleUrls: ['./books-slider.component.scss'],
})
export class BooksSliderComponent extends BaseSliderComponent {
  public booksCountLimit = 0;

  constructor() {
    super();
  }
}
