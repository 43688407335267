<div class="form-block w-form">
  <form #f="ngForm" [formGroup]="searchField">
    <div class="search-block">
      <input
        type="search"
        #search
        [formControl]="searchField"
        class="search-categories w-input"
        maxlength="256"
        placeholder="{{ 'SHARED.SEARCH' | translate }}"
        name="search"
        required
      />
      <a (click)="toClearSearch()" class="search-clear show w-inline-block"></a>
    </div>
  </form>
</div>
