import { Injectable } from '@angular/core';
import { HttpService } from 'app/core/services/http.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class MenuService {
  public showMobileMenu$ = new BehaviorSubject<boolean>(false);
  public isMobileScreen$ = new BehaviorSubject<boolean>(false);
  public selectedPupil$ = new BehaviorSubject<any>(null);

  private showResults = new BehaviorSubject({ showResults: false });

  constructor(private http: HttpService) {}

  showMobileMenu(show: boolean) {
    this.showMobileMenu$.next(show);
  }
  getMobileMenu() {
    return this.showMobileMenu$.asObservable();
  }

  setMobileScreen(isMobile: boolean) {
    this.isMobileScreen$.next(isMobile);
  }

  openResults() {
    this.showResults.next({ showResults: true });
  }

  getResultState(): Observable<any> {
    return this.showResults.asObservable();
  }

  checkTestAccess(userId, screeningTestId) {
    return this.http.get('/');
  }
}
