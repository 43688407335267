<div class="tab-menu-professions">
  <ng-container *ngFor="let profession of favoriteProfessionsMain">
    <a (click)="onSelectProfession(profession)" [class.w--current]="profession === selectedProfession" class="tabs-professions-link">{{
      profession.name
    }}</a>
  </ng-container>

  <div *ngIf="favoriteProfessionsMore.length > 0" #professionsDropdown class="menu-professions-dropdown w-dropdown">
    <div (click)="isOpen = !isOpen" class="professions-dd-toggle w-dropdown-toggle">
      <div class="professions-dd-icon w-icon-dropdown-toggle"></div>
      <div>{{ 'SHARED.YET' | translate }}</div>
    </div>

    <nav [class.w--open]="isOpen" class="dropdown-list w-dropdown-list">
      <div class="professions-dd-bg">
        <ng-container *ngFor="let profession of favoriteProfessionsMore">
          <a (click)="onSelectProfession(profession)" class="professions-dd-link w-dropdown-link">{{ profession.name }}</a>
        </ng-container>
      </div>
    </nav>
  </div>
</div>
