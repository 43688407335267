import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AdminAccessLevel } from 'app/shared/enums/admins.enums';
import { HttpService } from '../../../../../core/services/http.service';
import { ITalentsFilter } from 'app/shared/interfaces/ifilterclasses.interface';

export interface IFilterInternships {
  TalentsName?: string[];
  classesFormat?: string[];
  CourseGroups?: string[];
  Internships?: string[];
  Audience?: string;
  PublishedDate?: any;
  Courses?: string[];
  institutionIds?: string[];
  regionId?: string;
  municipalityId?: string;
  from?: number;
  size?: number;
  isArchived?: boolean;
}

@Injectable()
export class InternshipsFiltersService {
  private internshipsFilters = new BehaviorSubject<IFilterInternships>({});
  private talentsFilters = new BehaviorSubject<ITalentsFilter>({});
  public schoolID: string = '';

  constructor(private http: HttpService) {}

  getInternshipsFilters(): Observable<IFilterInternships> {
    return this.internshipsFilters.asObservable();
  }
  setInternshipsFilter(filters: any, schoolID?: any) {
    if (schoolID) {
      this.schoolID = schoolID;
    }
    this.internshipsFilters.next(filters);
  }

  getTalentsFilters(): Observable<ITalentsFilter> {
    return this.talentsFilters.asObservable();
  }
  setTalentsFilter(filters: any) {
    this.talentsFilters.next(filters);
  }

  public getFilteredInternships(filters: IFilterInternships, fromTo) {
    filters.from = fromTo.currentPage;
    filters.size = fromTo.pageSize;

    let url = '/api/v1.0/internships/internships/search/filters';

    let request = {
      filters: filters,
    };

    return this.http.post(url, request).pipe(map(resp => resp.internships));
  }

  public setRegionMunicipalityFilters(filters: IFilterInternships) {
    let regionId = localStorage.getItem('regionId');
    let municipalityId = localStorage.getItem('municipalityId');
    let userRole = localStorage.getItem('userRole');
    if (userRole === 'admin') {
      let adminLevel = localStorage.getItem('adminLevel');
      switch (adminLevel) {
        case AdminAccessLevel.MUNICIPALITY:
          {
            filters.regionId = regionId;
            filters.municipalityId = municipalityId;
          }
          break;
        case AdminAccessLevel.REGION:
          {
            filters.regionId = regionId;
          }
          break;
      }
    } else {
      filters.regionId = regionId;
      filters.municipalityId = municipalityId;
    }
    return filters;
  }

  public getFilteredInternshipsArchived(filters: IFilterInternships, fromTo) {
    filters.from = fromTo.currentPage;
    filters.size = fromTo.pageSize;
    filters.isArchived = true;

    let url = '/api/v1.0/internships/internships/search/filters';

    let request = {
      filters: filters,
    };

    return this.http.post(url, request).pipe(map(resp => resp.internships));
  }

  public getTypesCourses() {
    return this.http.get('/api/v1.0/catalog/courses/all').pipe(map(resp => resp.courses));
  }

  getTitleByNumber(n, titles: string[]) {
    return titles[n % 10 === 1 && n % 100 !== 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2];
  }

  public getTalentGroupCourses() {
    return this.http.get('/api/v1.0/catalog/search/talentgroupcourse').pipe(map(response => response.result));
  }

  public getInternshipsCountStatistic() {
    return this.http
      .get('/api/v1.0/catalog/internships/internshipscountstatistic')
      .pipe(map(response => response.internshipsCountStatistic));
  }

  public getCourseGroups() {
    return this.http.get('/api/v1.0/catalog/fielddos/all').pipe(
      map(response => {
        const data = response.fieldDOs;
        data.sort(sortList);
        return data;
      }),
    );
  }

  public getTalents() {
    return this.http.get('/api/v1.0/catalog/search/talents').pipe(
      map(response => {
        const data = response.result;
        data.sort(sortList);
        return uniqSorted(data);
      }),
    );
  }
}

function sortList(left, right) {
  if (left.name > right.name) {
    return 1;
  }
  if (left.name < right.name) {
    return -1;
  }
  return 0;
}

function uniqSorted(list) {
  const newList = [list[0]];

  list.forEach(({ name, line }) => {
    if (newList[newList.length - 1].name !== name) {
      newList.push({ name, line });
    }
  });

  return newList;
}
