import { IAnswerInfo } from '../api-services';
import { ETestTypes } from '../collections';
import { IStorageHelper } from '../helpers';

export interface ISectionInfo {
  sectionId: string;
  title: string;
  description: string;
  sectionType: string;
  elements: IAnswerInfo[];
  isRequired?: boolean;
  questionType?: string;
  results?: any;
  subtitle?: string;
  maxAnswers?: number;
  minAnswers?: number;
}

export interface IScreenSections {
  id: number;
  name: string;
  fields: IAnswerInfo[];
  isOpened: boolean;
}

export interface IPipeSlice {
  start: number;
  end: number;
}

export interface IBubbleBoxLine {
  elements: number;
  line: IPipeSlice[];
}

export interface ISlideImage {
  src: string;
  alt: string;
}

export interface IPlayerOptions {
  openQuestionRows: number;
}

export interface IUserAnswerLocalCache {
  questionId: IAnswerInfo[];
}

export interface IPreparedAnswer {
  questionId: string;
  answerId: string;
  questionSectionId: string;
  answerType: string;
  data: string;
}

export interface IAnketaTestInfo {
  screeningTestId: string;
  testType?: ETestTypes;
  variantId?: string;
  testRoute?: string;
  urlToNavigateIfSessionFailed?: string;
  isAnonymous?: boolean;
  themeLinkHref?: string;
  isPreview?: boolean;
  storageHelper?: IStorageHelper;
}

export interface ISessionData {
  name?: string;
  surname?: string;
  patronymic?: string;
  region?: string;
  regionId?: string;
  schoolClass?: string;
  gender?: string;
  email?: string;
  phone?: string;
  school?: string;
  auditory?: string;
}
