<div class="event-page-section create">
  <h1 class="event-h1">{{ 'SHARED.ADDING' | translate }}</h1>
  <div class="create-event-form w-form">
    <form [formGroup]="form">
      <h2 class="event-h2">Основная информация</h2>

      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="name">Название*</label>
        </div>
        <div class="create-form-column">
          <input
            type="text"
            class="create-event-field w-input"
            maxlength="256"
            name="name"
            data-name="name"
            [class.error-field]="submitted && f.name.errors"
            placeholder="Название"
            id="name"
            formControlName="name"
          />
          <ng-container *ngIf="submitted && f.name.errors">
            <mat-error *ngIf="f.name.errors.required">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
          </ng-container>
        </div>
      </div>

      <div class="create-form-row" [style.margin-bottom]="'100px'">
        <div class="create-form-column _1">
          <label class="create-form-label" for="description">Описание*</label>
        </div>
        <div class="create-form-column">
          <quill-editor
            [class.error-field]="submitted && f.description.errors"
            id="description"
            name="description"
            placeholder="Описание"
            formControlName="description"
          >
          </quill-editor>
          <ng-container *ngIf="submitted && f.description.errors">
            <mat-error *ngIf="f.description.errors.required">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
          </ng-container>
        </div>
      </div>

      <div class="create-form-row">
        <div class="create-form-column _1">
          <label class="create-form-label" for="govCode">GovCode*</label>
          <div class="info-div add-event">
            <div class="info-icon" (mouseover)="showInfoPopup = true" (mouseout)="showInfoPopup = false"></div>
            <div class="info-popup" *ngIf="showInfoPopup">
              <p>Для указания govCode доступны только цифры</p>
              <img src="/assets/images/icons/info-triangle.png" width="12" class="info-triangle" />
            </div>
          </div>
        </div>
        <div class="create-form-column">
          <input
            type="text"
            class="create-event-field w-input"
            maxlength="256"
            name="govCode"
            data-name="govCode"
            [class.error-field]="submitted && f.govCode.errors"
            placeholder="govCode"
            id="govCode"
            formControlName="govCode"
          />
          <ng-container *ngIf="submitted && f.govCode.errors">
            <mat-error *ngIf="f.govCode.errors.required">
              {{ 'SHARED.FORM.FILL_INPUT' | translate }}
            </mat-error>
          </ng-container>
        </div>
      </div>

      <input (click)="submit()" type="submit" value="Добавить специальность" class="button green-btn w-button" />
    </form>
    <div class="w-form-done">
      <div>Thank you! Your submission has been received!</div>
    </div>
    <div class="w-form-fail">
      <div>Oops! Something went wrong while submitting the form.</div>
    </div>
  </div>
</div>
