import { ModalType } from '../collections';

export type CallbackFunction = () => void;

export interface IModalParameters {
  title: string;
  content: string;
  type?: ModalType;
  isCloseButton?: boolean;
  icon?: boolean;
  button1?: string;
  button2?: string;
  onButtonClick?: CallbackFunction;
  onSecondButtonClick?: CallbackFunction;
}
