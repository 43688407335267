import { Injectable, OnDestroy } from '@angular/core';
import { Helper } from '@profilum-helpers/common-helper/helper';
import { Observable, ReplaySubject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

@Injectable()
export abstract class UnsubscribeComponent implements OnDestroy {
  protected unsubscribe: ReplaySubject<any> = new ReplaySubject<any>(1);
  private _timers: (ReturnType<typeof window.setTimeout> | number)[] = [];

  public set timer(timer: ReturnType<typeof window.setTimeout> | number) {
    this._timers.push(timer);
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
    this._timers.forEach((timer: ReturnType<typeof window.setTimeout>) => window.clearTimeout(timer));
    this._timers = [];
  }

  public unsubscribeOperator = <T>(source: Observable<T>): Observable<T> => {
    return source.pipe(
      catchError((error: any) => {
        console.error(error);

        throw new Error(Helper.isString(error) ? new Error(error) : error?.message ?? error);
      }),
      takeUntil(this.unsubscribe),
    );
  };
}
