import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { StorageKeys, WebStorageService } from '../../logic-services';
import {
  ApiCoursesMaterialsService,
  ApiUsersService,
  IGetCourseByClassResponse,
  IGetSchoolClassResponse,
} from '../../api-services';

@Injectable()
export class CanLoadPupil {
  constructor(
    private apiUsersService: ApiUsersService,
    private apiCoursesMaterialsService: ApiCoursesMaterialsService,
    private webStorageService: WebStorageService,
  ) {}

  public canLoad(): Promise<boolean> {
    return this.setAssignedCourseExists();
  }

  private setAssignedCourseExists(): Promise<boolean> {
    console.log('can load');
    const schoolClassId: string = window.localStorage.getItem('schoolClassId');
    console.log('schoolClassId ', schoolClassId);
    const schoolClassId$: Observable<any> = schoolClassId
      ? of(schoolClassId)
      : this.apiUsersService.getSchoolClassPupils().pipe(
          map((schoolClass: IGetSchoolClassResponse) => {
            console.log('schoolClassId ', schoolClassId);
            this.webStorageService.set(StorageKeys.TeacherId, schoolClass[0]?.teacherId ?? null);

            return schoolClass[0]?.id ?? null;
          }),
        );

    return schoolClassId$
      .pipe(
        switchMap((classId: string) => {
          this.webStorageService.set(StorageKeys.SchoolClassId, classId);

          return this.apiCoursesMaterialsService.getCourseByClass(classId);
        }),
        map((response: IGetCourseByClassResponse) => {
          this.webStorageService.set(StorageKeys.AssignedCourseExists, (!!response.courseIds.length).toString());
          return true;
        }),
        catchError(() => {
          this.webStorageService.set(StorageKeys.AssignedCourseExists, false);

          return of(true);
        }),
      )
      .toPromise();
  }
}
