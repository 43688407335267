<ng-template [ngIf]="sectionInfo()">
  <div class="slide-number">{{ slideNum }} {{ 'COMMON.OF' | translate }} {{ slidesCount }}</div>
  <div class="text-container">
    <h1 *ngIf="sectionInfo().title as title" class="title">{{ title }}</h1>
    <p *ngIf="sectionInfo().description as description" class="descr">{{ description }}</p>
  </div>
  <div *ngIf="slideImage() as slideImageData" class="image-container">
    <img [src]="slideImageData.src" [alt]="slideImageData.alt" />
  </div>
</ng-template>
<ng-template prfAnchor></ng-template>
<div class="content-section testing-content">
  <div class="container container-745">
    <prf-navigation
      [buttonEvent]="navigation"
      [isFirstStep]="!canGoBack()"
      [disableBack]="isDisabledGoBack()"
      [disableForward]="isDisabledGoForward()"
      [isFinish]="isFinish()"
    >
    </prf-navigation>
  </div>
</div>
