import { Component, Input, OnInit } from '@angular/core';
import { REG_EXP } from '../../../../shared/global-constants/reg-exp';

export enum ESubscribeMode {
  Initial,
  Active,
  Done,
}

@Component({
  selector: 'prf-job-subscription',
  templateUrl: './job-subscription.component.html',
  styleUrls: ['./job-subscription.component.scss'],
})
export class JobSubscriptionComponent implements OnInit {
  @Input() jobTitle;

  public state: ESubscribeMode;
  public userMail: string;
  private emailPattern: RegExp = REG_EXP.emailRegExp;
  public invalidEmail: boolean = false;

  constructor() {
    this.state = ESubscribeMode.Initial;
  }

  ngOnInit() {}

  public isDurtyState() {
    return this.state > ESubscribeMode.Initial;
  }

  public isCompleted() {
    return this.state == ESubscribeMode.Done;
  }

  public focusHandler() {
    if (this.state == ESubscribeMode.Initial) {
      this.state = ESubscribeMode.Active;
    }
  }

  public focusOutHandler() {
    if (this.state == ESubscribeMode.Active && !this.userMail) {
      this.state = ESubscribeMode.Initial;
    }
  }

  public sendEmail() {
    this.invalidEmail = !this.emailPattern.test(this.userMail);
    if (!this.invalidEmail) {
      this.state = ESubscribeMode.Done;
    }
    if (!this.userMail) {
      this.invalidEmail = false;
    }
  }
}
