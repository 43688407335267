import { inject, Injectable } from '@angular/core';
import { HttpService } from '../http';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ApiResultsUrls } from './api-results.collections';
import {
  IGetDataForResultsPageResponse,
  IGetUserAnswersResponse,
  ISaveUserAnswersRequest,
  ISaveUserAnswersResponse,
} from './api-results.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiResultsService {
  private readonly httpService: HttpService = inject(HttpService);

  public setUserAnswers(userAnswer: ISaveUserAnswersRequest): Observable<ISaveUserAnswersResponse> {
    return this.httpService.post<ISaveUserAnswersResponse>(ApiResultsUrls.UserAnswers, userAnswer).pipe(take(1));
  }

  public getUserAnswers(sessionId: string, questionId: string): Observable<IGetUserAnswersResponse> {
    return this.httpService.get<IGetUserAnswersResponse>(ApiResultsUrls.UserAnswers, { sessionId, questionId }).pipe(take(1));
  }

  public calculateResults(sessionId: string): Observable<any> {
    return this.httpService.post(ApiResultsUrls.UserAnswers, { sessionId }).pipe(take(1));
  }

  public getResultsPage(sessionIds: string[]): Observable<IGetDataForResultsPageResponse> {
    return this.httpService.get<IGetDataForResultsPageResponse>(ApiResultsUrls.GetResultsPage, { sessionIds }).pipe(take(1));
  }

  public getSchoolReport(schoolId: string): Observable<any> {
    return this.httpService.get(ApiResultsUrls.GetSchoolReport, { schoolId }).pipe(take(1));
  }

  public getMunicipalityReport(municipalityId: string): Observable<any> {
    return this.httpService.get(ApiResultsUrls.GetMunicipalityReport, { municipalityId }).pipe(take(1));
  }
}
