import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subject } from 'rxjs';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ConfirmEmailService } from './confirm-email.service';

@Component({
  selector: 'prf-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss'],
})
export class ConfirmEmailComponent implements OnInit, OnDestroy {
  message: string = '';
  showSendLink: boolean = false;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private meta: Meta,
    private activatedRoute: ActivatedRoute,
    private confirmService: ConfirmEmailService,
    private translateService: TranslateService,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'Подтверждение электронной почты' });
    this.getTranslation('SHARED.WAIT')
      .pipe(take(1))
      .subscribe(translation => (this.message = translation));
  }

  ngOnInit() {
    this.activatedRoute.queryParams
      .pipe(
        switchMap(params => {
          let currentObs$: Observable<any>;
          if (params.userEmail !== undefined && params.code !== undefined) {
            currentObs$ = this.confirmEmail(params.userEmail, params.code);
          } else {
            this.message = 'Неверная ссылка';
            this.showSendLink = true;
            currentObs$ = of(null);
          }
          return currentObs$;
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  confirmEmail(email: string, code: string): Observable<any> {
    return this.confirmService.confirmEmail(email, code).pipe(
      tap(res => {
        if (res.status == 'Success') {
          this.message = 'Вы успешно подтвердили свою почту!';
        } else {
          this.message = 'Почта не подтверждена. Неверная ссылка.';
          this.showSendLink = true;
        }
      }),
    );
  }

  updateMessage(data: boolean) {
    this.showSendLink = !data; // t
    this.message = data ? 'Вы успешно подтвердили свою почту!' : 'Почта не подтверждена. Попробуйте еще раз.';
  }

  getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
