import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

type TGGender = 'M' | 'F';

@Component({
  selector: 'prf-gender-toggle',
  templateUrl: './gender-toggle.component.html',
  styleUrls: ['./gender-toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GenderToggleComponent),
      multi: true,
    },
  ],
})
export class GenderToggleComponent implements ControlValueAccessor {
  @Input() genderLocked: boolean;

  private _currentGender: TGGender;
  private _genders: TGGender[] = ['M', 'F'];

  _gendersView = [
    { key: 'M', name: 'BUTTONS.GENDER.M' },
    { key: 'F', name: 'BUTTONS.GENDER.F' },
  ];
  propagateChange = (gender: TGGender) => {};
  propagateTouch = (gender: TGGender) => {};

  writeValue(gender: TGGender): void {
    this.currentGender = gender;
  }

  get currentGender() {
    return this._currentGender;
  }

  set currentGender(gender: TGGender) {
    this._currentGender = gender;
    this.propagateChange(gender);
    this.propagateTouch(gender);
  }

  constructor(private translateService: TranslateService) {}

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  switchGender(gender: TGGender) {
    this.currentGender = gender;
  }

  toggleNext() {
    this.currentGender = this._genders[(this._genders.indexOf(this.currentGender) + 1) % 2];
  }
}
