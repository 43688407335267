import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Helper, IStorageHelper, StorageHelper } from '../../helpers';
import { StorageKeys, Storages } from '../web-storage';

@Injectable({
  providedIn: 'root',
})
export class RamStorageService {
  private readonly _ramStorage: Map<string, ReplaySubject<any>>;
  private readonly _webStorage: IStorageHelper;

  constructor() {
    this._ramStorage = new Map<string, ReplaySubject<any>>();
    this._webStorage = new StorageHelper(Storages.Local);
  }

  public get<T>(key: StorageKeys, webStorage: Storages | null = null): Observable<T | null> {
    return this.getSubject(key, webStorage).pipe(map((value: T | null) => value));
  }

  public set<T>(key: StorageKeys, value: T, webStorage: Storages | null = null): void {
    if (!Helper.isDefined(value)) {
      return;
    }

    try {
      const replaySubjectFromStorage: ReplaySubject<T | null> = this.getSubject(key);
      const copiedValue: T = structuredClone(value);

      replaySubjectFromStorage.next(copiedValue);

      if (webStorage !== null) {
        this._webStorage.storage = webStorage;
        this._webStorage.set(key, copiedValue);
      }
    } catch (e) {
      console.error(e);
    }
  }

  public clear(key: StorageKeys, webStorage: Storages | null = null): void {
    this._ramStorage.delete(key);

    if (webStorage !== null) {
      this._webStorage.storage = webStorage;
      this._webStorage.clear(key);
    }
  }

  public clearAll(clearWebStorages: boolean = false): void {
    if (clearWebStorages) {
      this._ramStorage.forEach((value: ReplaySubject<string>, key: string) => {
        this.clear(key as StorageKeys, Storages.Local);
        this.clear(key as StorageKeys, Storages.Session);
        this.clear(key as StorageKeys, Storages.Cookie);
      });
    }

    this._ramStorage.clear();
  }

  private getSubject<T>(key: StorageKeys, webStorage: Storages | null = null): ReplaySubject<T | null> {
    if (this._ramStorage.has(key)) {
      return this._ramStorage.get(key) as ReplaySubject<T | null>;
    }

    const replaySubject: ReplaySubject<T> = new ReplaySubject<T>(1);

    this._ramStorage.set(key, replaySubject);

    try {
      if (webStorage !== null) {
        this._webStorage.storage = webStorage;

        const dataFromWebStorage: T = this._webStorage.get(key);

        if (dataFromWebStorage !== null) {
          replaySubject.next(dataFromWebStorage);
        }
      } else {
        replaySubject.next(null);
      }
    } catch (e) {
      replaySubject.next(null);
      console.error(e);
    }

    return this._ramStorage.get(key) as ReplaySubject<T | null>;
  }
}
