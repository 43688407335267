import { inject, Injectable } from '@angular/core';
import { HttpService, IHttpStatus } from '../http';
import { Observable } from 'rxjs';
import {
  IGetProfessionsFormElasticResponse,
  IGetRandomProfessionsByFieldsRequest,
  IGetRandomProfessionsByFieldsResponse,
  IProfession,
  IProfessionFilter,
  ISpecialityData,
} from './api-professions.interface';
import { ApiProfessionsUrls } from './api-professions.collections';
import { map, take } from 'rxjs/operators';
import { EmptyGuid } from '../../helpers';

@Injectable({
  providedIn: 'root',
})
export class ApiProfessionsService {
  private readonly httpService: HttpService = inject(HttpService);

  public getElasticProfessionsByFilters(filters: IProfessionFilter | any): Observable<any> {
    return this.httpService.post(ApiProfessionsUrls.SearchProfessionsElastic, filters).pipe(take(1));
  }

  public getProfessionByHrid(hrid: string): Observable<IGetProfessionsFormElasticResponse> {
    return this.httpService.get<IGetProfessionsFormElasticResponse>(ApiProfessionsUrls.GetProfessionByHrid, { hrid }).pipe(take(1));
  }

  public getProfessionByHridMongo(hrid: string): Observable<any> {
    return this.httpService.get(ApiProfessionsUrls.GetProfessionByHridMongo, { hrid }).pipe(map((r: any) => r.profession));
  }

  public getRandomProfessionsByFields(data: IGetRandomProfessionsByFieldsRequest): Observable<IGetRandomProfessionsByFieldsResponse> {
    data.regionId = data.regionId ?? EmptyGuid;

    return this.httpService
      .post<IGetRandomProfessionsByFieldsResponse>(ApiProfessionsUrls.GetRandomProfessionsByFields, data)
      .pipe(take(1));
  }

  public createProfessions(profession: IProfession): Observable<any> {
    return this.httpService.post(ApiProfessionsUrls.CreateProfession, { profession }).pipe(take(1));
  }

  public getProfessionsByFilters(filters: IProfessionFilter): Observable<any> {
    return this.httpService.post(ApiProfessionsUrls.SearchProfessions, filters).pipe(map((response: any) => response.professions));
  }

  public getProfessionCount(filters): Observable<any> {
    return this.httpService.post(ApiProfessionsUrls.ProfessionsCount, filters).pipe(map((r: any) => r.count));
  }

  public getElasticProfessionCount(filters) {
    return this.httpService.post(ApiProfessionsUrls.ProfessionsCountElastic, filters).pipe(take(1));
  }

  public getProfessionsQuery(query: any, regionId?: string): Observable<any> {
    return this.httpService.post(ApiProfessionsUrls.ProfessionsSearchQueryMongo, { query, regionId }).pipe(map((r: any) => r.professions));
  }

  public getElasticProfessionsQuery(query: any, regionId?: string): Observable<any> {
    return this.httpService.post(ApiProfessionsUrls.ProfessionsSearchQuery, { query, regionId }).pipe(take(1));
  }

  public getProfessionsByIds(ids: string[]): Observable<any> {
    return this.httpService.post(ApiProfessionsUrls.ProfessionsSearchQueryMongo, { ids }).pipe(map((r: any) => r.professions));
  }

  public changeProfessions(value: IProfession): Observable<any> {
    return this.httpService.put(ApiProfessionsUrls.ProfessionsUpdate, { id: value.id, profession: value }).pipe(take(1));
  }

  public removeProfession(professionId): Observable<any> {
    return this.httpService.delete(ApiProfessionsUrls.ProfessionsDelete, { professionId }).pipe(take(1));
  }

  public updateProfessions(): Observable<any> {
    return this.httpService.post(ApiProfessionsUrls.ProfessionsReloadIndex).pipe(take(1));
  }

  public getSpecialities(): Observable<any> {
    return this.httpService.get(ApiProfessionsUrls.GetAllSpecialities).pipe(map((response: any) => response.specialities));
  }

  public getSpecialtyById(id): Observable<ISpecialityData> {
    return this.httpService.get(ApiProfessionsUrls.GetSpecialitiesById, { id }).pipe(map((res: any) => res.speciality));
  }

  public deleteSpecialty(specialityId: string): Observable<any> {
    return this.httpService.delete(ApiProfessionsUrls.Specialities, { specialityId }).pipe(take(1));
  }

  public addSpecialty(speciality): Observable<any> {
    return this.httpService.post(ApiProfessionsUrls.Specialities, { speciality }).pipe(take(1));
  }

  public updateSpecialty(speciality): Observable<any> {
    return this.httpService.put(ApiProfessionsUrls.Specialities, { speciality }).pipe(take(1));
  }

  public sendProfessionsBvb(userId: string): Observable<IHttpStatus> {
    return this.httpService.post<IHttpStatus>(ApiProfessionsUrls.SendProfessionsBvb, { userId }).pipe(take(1));
  }
}
