export enum ETestTypes {
  DEFAULT_STANDART,
  DEFAULT_360,
  PROFESSIONPRESTIGE,
  MOTIVATION_TEST,
  VALUES_TEST,
  PROMO_TEST,
  MINCIFRYDEMO_TEST,
  BVB_TEST,
  VK_TEST,
  CAMP_TEST,
  MINCIFRY_TEST,
  BVB_TEST_VISUALLY_IMPAIRED,
  WEB_TEST,
  INTERESTS_TEST,
  CAPABILITIES_TEST,
  CAREER_LITERACY_TEST,
  FRONTEND_DEV_TEST,
  BVB_TEST_PARENT,
  BVB_TEST_RAS,
  BVB_APPROBATION_LITE,
  BVB_TEST_MUF,
  BVB_TEST_OPEN_CONTOUR,
  BVB_TEST_MY_FUTURE,
  PREPARING_FOR_THE_FUTURE,
}
