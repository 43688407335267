export const enum ApiAuthUrls {
  //school portal
  GetUserDnevnik = '/saas/v1.0/dnevnik/getuserdata',
  LoginDnevnik = '/saas/v1.0/dnevnik/login',
  RegisterDnevnik = '/saas/v1.0/dnevnik/register',
  GetAuthUrlDnevnik = '/saas/v1.0/dnevnik/getauthurl',

  // murmansk
  LoginMurmansk = '/saas/v1.0/murmansk/login',
  GetUserMurmansk = '/saas/v1.0/murmansk/getusermurmansk',
  GetAuthUrlMurmansk = '/saas/v1.0/murmansk/getauthurl',

  // prosveshenie
  LoginProsvet = '/saas/v1.0/prosveshcheniye/login',
  GetUserProsvet = '/saas/v1.0/prosveshcheniye/getuserdata',
  RegisterProsvet = '/saas/v1.0/prosveshcheniye/register',
  GetAuthUrlProsvet = '/saas/v1.0/prosveshcheniye/getauthurl',

  Logout = '/saas/v1.0/saas/logout',
  Login = '/saas/v1.0/saas/login',
  CheckAccess = '/saas/v1.0/saas/checkaccess',
  GetLastSession = '/saas/v1.0/saas/getlastsession',
  GetAuthMosruUrl = '/saas/v1.0/mosru/getauthurl',
  GetAuthMesUrl = '/api/saas/v1.0/identity/mes-login?redirectUri=/saas/v1.0/saas/mes-sync',
  LoginMosruUser = '/saas/v1.0/mosru/loginmosruuser',
  CheckCookie = '/saas/v1.0/saas/checkcookie',
  GetMosruUserData = '/saas/v1.0/mosru/getmosruuserdata',
  EmailVerification = '/api/v1.0/auth/account/emailverification',
  SendConfirmationLink = '/saas/v1.0/saas/sendconfirmationlink',
  ResetPasswordByEmail = '/api/v1.0/auth/account/resetpassword',
  ResetPasswordByPhone = '/api/v1.0/auth/account/resetpasswordbyphone',
  CheckActivationCode = '/api/v1.0/auth/account/checkactivationcode',
  ResetPasswordCode = '/api/v1.0/auth/account/resetpasswordtocode',
  CheckPhoneAvailability = '/saas/v1.0/saas/is-phone-free',
  CheckEmailAvailability = '/saas/v1.0/saas/isemailfree',

  LogUserAction = '/saas/v1.0/user-actions',

  // bvb
  RegisterBvb = '/saas/v1.0/bvb/register',
  CheckUserSelection = '/api/v1.0/bvb/bvb/checkuserselection',

  //jwt
  LoginJwt = '/saas/v1.0/provider/login',
  RegisterJwt = '/saas/v1.0/provider/register',
}

/**
 * Коллекция возможных значений для параметра kind в системе журналирования.
 *
 * Пополняется только после рисширения коллекциии на бэке.
 */
export const enum UserActionKind {
  Unknown = 'Unknown',
  SignIn = 'SignIn',
  ChangePassword = 'ChangePassword',
  RecoverPassword = 'RecoverPassword',
  Register = 'Register',
  CompleteTest = 'CompleteTest',
  CompleteTestQuestion = 'CompleteTestQuestion',
  CompleteTask = 'CompleteTask',
  NotificationView = 'NotificationView',
  OpenResource = 'OpenResource',
  GoToExternalResource = 'GoToExternalResource',
  SetLike = 'SetLike',
  UnsetLike = 'UnsetLike',
  RateCard = 'RateCard',
}

/**
 * Коллекция возможных значений для параметра result в системе журналирования.
 *
 * Пополняется только после рисширения коллекциии на бэке.
 */
export const enum UserActionResult {
  Unknown = 'Unknown',
  Success = 'Success',
  TooManyRequests = 'TooManyRequests',
  ValidationError = 'ValidationError',
  Unauthenticated = 'Unauthenticated',
  Forbidden = 'Forbidden',
  Error = 'Error',
}
