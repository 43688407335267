import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from 'app/core/services/http.service';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Stands } from 'app/shared/enums/stands.enums';
import { IClass } from '../../../shared/interfaces/iclass.interface';
import { IFilterClasses } from 'app/shared/interfaces/ifilterclasses.interface';

// методы для админов (монго)
@Injectable()
export class CoursesService {
  public schoolId: string = '';

  public defaultRegionId: string = '00000000-0000-0000-0000-000000000000';

  constructor(private http: HttpService) {
    this.schoolId = localStorage.getItem('schoolId');
  }

  // обработка ошибок
  handleError(err: any): Observable<any> {
    if (err instanceof TimeoutError) {
      console.error(`Frontend returned timeout error: ${err['error']}`);
      return throwError(err['error']);
    }
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
      let errorText = err.error ? (err.error.comment ? err.error.comment : err.error) : null;
    }
    throw throwError(err);
  }

  reloadClasses(): Observable<any> {
    return this.http.get('/api/v1.0/catalog/classes/reloadclasseselasticindex').pipe(
      map(res => res),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  // elastic search-indexes methods

  public getClassesElasticCount(filters: IFilterClasses): Observable<any> {
    let url = '/api/v1.0/catalog/classes/elasticsearch/count/filters';
    filters.stand = Stands.Talent;
    return this.http.post(url, filters).pipe(map(resp => resp));
  }

  public getElasticFilteredClasses(filters: IFilterClasses, fromTo?) {
    let url = '/api/v1.0/catalog/classes/elasticsearch/search/filters';

    if (fromTo) {
      filters.from = fromTo.currentPage;
      filters.size = fromTo.pageSize;
    }
    filters.stand = Stands.Talent;

    return this.http.post(url, filters).pipe(map(resp => resp));
  }

  // mongo methods
  getCourseByHrid(hrid: string): Observable<any> {
    return this.http.get(`/api/v1.0/catalog/classes/mongo/onebyhrid?hrid=${hrid}`).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  public getClassesQuery(query, filters: IFilterClasses) {
    let url = '/api/v1.0/catalog/classes/mongo/search/query?query=' + query;
    if (filters.regionId) {
      url += '&regionId=' + filters.regionId;
    }
    if (filters.municipalityId) {
      url += '&municipalityId=' + filters.municipalityId;
    }
    return this.http.get(url).pipe(map(res => res.classes));
  }

  createClassesDO(classesDto: IClass): Observable<any> {
    const credentials = {
      class: classesDto,
    };
    return this.http.post('/api/v1.0/catalog/classes/mongo/create', credentials).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  changeClassesDO(classes: IClass): Observable<any> {
    const credentials = {
      class: classes,
    };
    return this.http.put('/api/v1.0/catalog/classes/mongo/update', credentials).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  removeCurrentClass(classID: any): Observable<any> {
    return this.http.delete('/api/v1.0/catalog/classes/mongo/delete?classesId=' + classID).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  checkhrid(hrid): Observable<any> {
    return this.http.get('/api/v1.0/catalog/classes/mongo/checkhrid?hrid=' + hrid).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  public getClassesCount(filters: IFilterClasses): Observable<any> {
    filters.stand = Stands.Talent;
    return this.http.post('/api/v1.0/catalog/classes/mongo/count/filters', filters).pipe(map(r => r));
  }

  public getFilteredClasses(filters: IFilterClasses, fromTo) {
    const credentials = {
      filters: filters,
    };
    credentials.filters.from = fromTo.currentPage;
    credentials.filters.size = fromTo.pageSize;
    credentials.filters.stand = Stands.Talent;
    return this.http.post('/api/v1.0/catalog/classes/mongo/search/filters', credentials).pipe(map(resp => resp.classes));
  }
}
