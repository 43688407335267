// Компонент для запроса отправки ссылки на почту, для изменения пароля
import { Component, HostListener, OnInit } from '@angular/core';

import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { REG_EXP } from '../../../../shared/global-constants/reg-exp';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { RestorePasswordService } from './restore-password.service';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'prf-restore-password',
  templateUrl: './restore-password.component.html',
  styleUrls: ['./restore-password.component.scss'],
})
export class RestorePasswordComponent implements OnInit {
  public form: UntypedFormGroup;
  public email: string = null;
  public phone: string = null;
  public isEmailRestore: boolean = true;

  public firstStep: boolean = true;
  public secondStep: boolean = false;
  public errorResetToken: boolean = false;
  public userNotFound: boolean = false;

  private emailPattern: RegExp = REG_EXP.emailRegExp;
  private phonePattern: RegExp = REG_EXP.phoneRegExp;

  public isMobile: boolean = false;

  public phoneMask = ['+', '7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];

  constructor(
    private fb: UntypedFormBuilder,
    private meta: Meta,
    private resetPasswordService: RestorePasswordService,
    private router: Router,
    private utilsService: UtilsService,
    private translateService: TranslateService,
  ) {
    this.isMobile = window.innerWidth <= 450;
    this.getTranslation('SHARED.PASSWORD_RECOVERY')
      .pipe(take(1))
      .subscribe(translation =>
        this.meta.updateTag({
          name: 'og:title',
          content: translation,
        }),
      );
  }

  ngOnInit() {
    this.form = this.fb.group({
      email: new UntypedFormControl(this.email, [Validators.pattern(this.emailPattern)]),
      phone: new UntypedFormControl(this.phone, [Validators.pattern(this.phonePattern)]),
    });
  }

  public restorePassword() {
    if (this.isEmailRestore) {
      this.resetPasswordService
        .sendResetTokenEmail(this.form.value.email)
        .pipe(take(1))
        .subscribe(
          token => {
            if (token.status === 'Success') {
              this.firstStep = false;
              this.secondStep = true;
            } else {
              if (token.comment === 'User with currrent email not found') {
                this.userNotFound = true;
              } else return (this.errorResetToken = true);
            }
          },
          err => {
            this.utilsService.openSnackBar('👎 Ошибка на сервере, попробуйте позже', 'error');
          },
        );
    } else {
      this.resetPasswordService
        .sendResetTokenPhone(this.form.value.phone.replace(/\D/g, ''))
        .pipe(take(1))
        .subscribe(
          token => {
            if (token.status === 'Success') {
              this.firstStep = false;
              this.secondStep = true;
            } else {
              if (token.comment.includes("doesn't exist")) {
                this.userNotFound = true;
              } else return (this.errorResetToken = true);
            }
          },
          err => {
            this.utilsService.openSnackBar('👎 Ошибка на сервере, попробуйте позже', 'error');
          },
        );
    }
  }

  clearForm() {
    this.form.get('email').setValue(null);
    this.form.get('phone').setValue(null);
    this.userNotFound = false;
    this.errorResetToken = false;
  }

  onMain() {
    this.router.navigate(['/login']);
  }
  public back() {
    if (this.firstStep) {
      this.router.navigate(['/login']);
    }
    if (this.secondStep) {
      this.form.get('email').setValue(null);
      this.form.get('phone').setValue(null);
      this.firstStep = true;
      this.secondStep = false;
    }
  }

  getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }

  public isSecondStepAllowed() {
    return this.secondStep;
  }

  public isAccessAllowed() {
    if (this.isEmailRestore) {
      return this.emailPattern.test(this.form.value.email);
    } else {
      return this.phonePattern.test(this.form.value.phone);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target?.innerWidth;
    this.isMobile = event.target.innerWidth <= 450;
  }
}
