<ng-template [ngIf]="sectionInfo">
  <div class="prf-bubble-box">
    <prf-bubble-view-with-img
      *ngFor="let answer of sectionInfo.elements; let i = index; trackBy: trackByFn"
      [answerInfo]="answer"
      [sectionInfo]="sectionInfo"
      (selectedAnswer)="updateSelectedAnswer($event)"
      [updateSelections]="updateSelectionsSubject"
      class="prf-bubble-box-item"
      [class.prf-last-item]="isLastItem(i)"
      [class.prf-last-left-two-item]="isLastLeftOfTwoItems(i)"
      [class.prf-last-rigth-two-item]="isLastRightOfTwoItems(i)"
    ></prf-bubble-view-with-img>
  </div>
</ng-template>
