export const enum ApiProfessionsUrls {
  GetProfessionByHrid = '/api/v1.0/catalog/professions/onebyhrid',
  GetProfessionByHridMongo = '/api/v1.0/catalog/professions/mongo/onebyhrid',
  GetRandomProfessionsByFields = '/api/v1.0/catalog/professions/mongo/getrandombyfieldidsprofessions',
  CreateProfession = '/api/v1.0/catalog/professions/mongo/create',
  SearchProfessions = '/api/v1.0/catalog/professions/mongo/search/filters',
  SearchProfessionsElastic = '/api/v1.0/catalog/professions/elasticsearch/search/filters',
  ProfessionsCount = '/api/v1.0/catalog/professions/mongo/count/filters',
  ProfessionsCountElastic = '/api/v1.0/catalog/professions/elasticsearch/count/filters',
  ProfessionsSearchQueryMongo = '/api/v1.0/catalog/professions/mongo/search/query',
  ProfessionsSearchQuery = '/api/v1.0/catalog/professions/search/query',
  ProfessionsDelete = '/api/v1.0/catalog/professions/mongo/delete',
  ProfessionsUpdate = '/api/v1.0/catalog/professions/mongo/update',
  ProfessionsReloadIndex = '/api/v1.0/catalog/professions/reloadprofessionselasticindex',
  GetAllSpecialities = '/api/v1.0/catalog/specialities/all',
  Specialities = '/api/v1.0/catalog/specialities',
  GetSpecialitiesById = '/api/v1.0/catalog/specialities/onebyid',
  SendProfessionsBvb = '/api/v1.0/bvb/bvb/sendprofessionsbvb ',
}
