import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { getScreenSections, Sections } from './grouped-rectangle-view.collections';
import { take, takeUntil } from 'rxjs/operators';
import { IAnswerInfo } from '@profilum-api-services/api-slides/api-slides.interface';
import { TestBoxHelperComponent } from '@profilum-components/prf-tests/test-helper/test-box-helper.component';
import { IScreenSections } from '@profilum-interfaces/prf-tests.interface';
import { RectangleViewComponent } from '../../rectangle-box/rectangle-view/rectangle-view.component';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'prf-grouped-rectangle-view',
  standalone: true,
  imports: [CommonModule, RectangleViewComponent],
  templateUrl: './grouped-rectangle-view.component.html',
  styleUrls: ['./grouped-rectangle-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupedRectangleViewComponent extends TestBoxHelperComponent implements OnInit {
  public screenSections: IScreenSections[];

  public static getTranslations(keys: string[]): Observable<Record<string, string>> {
    return <Observable<Record<string, string>>>inject(TranslateService).get(keys);
  }

  constructor() {
    super();
    GroupedRectangleViewComponent.getTranslations([
      Sections.TECHNICAL_AND_TECHNOLOGY,
      Sections.SPORT_AND_MILITARY,
      Sections.CREATIVE,
      Sections.SOCIAL,
      Sections.SCIENCE,
      Sections.COUTURE,
      Sections.FINANCE,
      Sections.IT,
      Sections.LINGUISTIC,
      Sections.PHYSIC_AND_MATH,
    ])
      .pipe(take(1), takeUntil(this.unsubscribe))
      .subscribe((translations: Record<string, string>) => {
        this.screenSections = getScreenSections(translations);
      });
  }

  public ngOnInit(): void {
    this.sectionInfo.elements?.forEach((answerInfo: IAnswerInfo) => {
      const screenSection: IScreenSections = this.screenSections[answerInfo.orderNumber - 1];

      if (screenSection) {
        screenSection.fields.push(answerInfo);
      }
    });

    this.setupRequiredFields();
  }

  public trackBySectionId(index: number, item: IScreenSections): number {
    return item.id;
  }
  public trackByAnswerId(index: number, item: IAnswerInfo): string {
    return item.answerId;
  }

  public toggleSection(screenSection: IScreenSections): void {
    screenSection.isOpened = !screenSection.isOpened;
  }
}
