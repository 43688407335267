import { inject, Injectable } from '@angular/core';
import { HttpService } from '../http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiSearchUrls } from './api-search.collections';

@Injectable({
  providedIn: 'root',
})
export class ApiSearchService {
  private readonly httpService: HttpService = inject(HttpService);

  public searchTalents(): Observable<any> {
    return this.httpService.get(ApiSearchUrls.SearchTalents).pipe(map((response: any) => response.result));
  }

  public searchCourses(): Observable<any> {
    return this.httpService.get(ApiSearchUrls.SearchCourses).pipe(map((response: any) => response.result));
  }

  public searchProfileClasses(): Observable<any> {
    return this.httpService.get(ApiSearchUrls.SearchProfileClasses).pipe(map((response: any) => response.result));
  }

  public getTalentGroupCourse() {
    return this.httpService.get(ApiSearchUrls.TalentGroupCourse).pipe(map((response: any) => response.result));
  }
}
