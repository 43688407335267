import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { REG_EXP } from '../../../shared/global-constants/reg-exp';

@Component({
  selector: 'prf-registration-b2c',
  templateUrl: './registration-b2c.component.html',
  styleUrls: ['./registration-b2c.component.scss'],
})
export class RegistrationB2cComponent implements OnInit {
  public isMaskedPassword: boolean = true;
  public password: string = null;
  public email: string = null;
  public enterByCode: boolean = false;
  private emailPattern: RegExp = REG_EXP.emailRegExp;
  public registrationFailed: boolean = false;
  public passFailed: boolean = false;
  public loginFailed: boolean = false;

  constructor(private meta: Meta, private translateService: TranslateService) {
    this.getTranslation('SHARED.REGISTRATION')
      .pipe(take(1))
      .subscribe(translation => this.meta.updateTag({ name: 'og:title', content: translation }));
  }

  ngOnInit() {}

  public toggleMask() {
    this.isMaskedPassword = !this.isMaskedPassword;
  }

  public isAccessAllowed() {
    return this.password && this.isValid(this.email);
  }

  public isValid(email) {
    return this.emailPattern.test(email);
  }

  public enterCode() {
    this.enterByCode = !this.enterByCode;
  }

  getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }
}
