<div *ngIf="reportLoadDataFlag">
  <div class="toggle-reports">
    <button
      [ngClass]="{ 'btn-success__active': !reportsMiddle }"
      (click)="toggleChartControl('reportsMiddle')"
      class="toggle-reports__middle btn-grey"
    >
      {{ 'SHARED.AVR_TEST_RESULT' | translate }}
    </button>
    <button
      [ngClass]="{ 'btn-success__active': reportsSlash }"
      (click)="toggleChartControl('reportSlash')"
      class="toggle-reports__interest btn-grey"
    >
      {{ 'SHARED.CUTAWAY_RESULTS' | translate }}
    </button>
  </div>

  <div class="charts-container">
    <prf-class-results
      *ngIf="!reportsMiddle"
      [schoolClassId]="schoolClassId"
      [skillsData]="skillsDataGroupChart"
      [talentsData]="talentsDataGroupChart"
      [interestsData]="interestsDataGroupChart"
    >
    </prf-class-results>
    <div class="charts-container-items" *ngIf="reportsSlash">
      <prf-skills [data]="skillsData"></prf-skills>
      <prf-talents [data]="talentsData"></prf-talents>
      <prf-interests [data]="interestsData"></prf-interests>
    </div>
  </div>
</div>

<div class="not-load-data" *ngIf="!reportLoadDataFlag && !loadAggregatesFlag">
  <h1>{{ 'SHARED.DATA_LOADING' | translate }}анных</h1>
  <div class="bouncing-loader">
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>

<div class="not-load-data" *ngIf="loadAggregatesFlag">
  <h1>{{ 'SHARED.PUPILS_NOT_TESTING' | translate }}</h1>
</div>
