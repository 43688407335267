import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';

import { ROUTE_ANIMATIONS_ELEMENTS, routeAnimations } from '../../core';
import { AppSettingsService } from '../../shared/services/appsettings.service';

export const PAGE_SIZE: number = 1;

@Component({
  selector: 'prf-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  animations: [routeAnimations],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingComponent implements OnInit {
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
  public activeLocation = 'base';

  logo: boolean = false;

  constructor(private meta: Meta, private appSettings: AppSettingsService, private router: Router) {
    this.meta.addTag({ name: 'og:title', content: 'Профилум' });
    this.activeLocation = this.appSettings.currentLocation;
  }

  ngOnInit() {}
}
