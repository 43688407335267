import { ChangeDetectorRef, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { IAnswerInfo } from '@profilum-api-services/api-slides/api-slides.interface';
import { QuestionTypes } from '@profilum-collections/prf-tests.collections';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { ISectionInfo } from '@profilum-interfaces/prf-tests.interface';
import { RamStorageService } from '@profilum-logic-services/ram-storage/ram-storage.service';
import { StorageKeys } from '@profilum-logic-services/web-storage/web-storage.collections';
import { ReplaySubject, takeUntil } from 'rxjs';


@Component({
  template: ``,
  standalone: true,
})
export class TestBoxHelperComponent extends UnsubscribeComponent {
  @Input() public minSelections: number;
  @Input() public maxSelections: number;
  @Input() public sectionInfo: ISectionInfo;
  @Input() public isRequired: boolean = false;
  @Output() public canGoForward: EventEmitter<boolean> = new EventEmitter<boolean>();
  public updateSelectionsSubject: ReplaySubject<string> = new ReplaySubject<string>(1);
  protected readonly changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);
  protected readonly ramStorageService: RamStorageService = inject(RamStorageService);
  protected selectedCount$: ReplaySubject<number> = new ReplaySubject<number>(1);
  protected superNegativeAnswer: IAnswerInfo;

  public updateSelectedAnswer(event: IAnswerInfo): void {
    this.selectLimitedCountOfAnswers(event);

    if (this.sectionInfo.questionType === QuestionTypes.Single) {
      this.sectionInfo.elements?.forEach((element: IAnswerInfo) => {
        if (event.answerId !== element.answerId && element.sectionId === this.sectionInfo.sectionId) {
          element.sectionId = null;
          element.sectionType = null;
          this.updateSelectionsSubject.next(element.answerId);
        }
      });
    } else {
      this.setupAnswers(event);
    }

    this.selectedCount$.next(this.getSelectedCount());
  }

  private getSelectedCount(): number {
    const selectedItems: IAnswerInfo[] = this.sectionInfo.elements?.filter((element: IAnswerInfo) => {
      return element.sectionId === this.sectionInfo.sectionId;
    });

    return selectedItems.length ?? 0;
  }

  protected selectLimitedCountOfAnswers(event: IAnswerInfo): void {
    if (!(this.maxSelections > 0)) {
      return;
    }

    if (this.maxSelections < this.minSelections) {
      this.maxSelections = null;
    }

    if (this.maxSelections >= this.minSelections) {
      const selectedCount: number = this.getSelectedCount();

      if (selectedCount > this.maxSelections) {
        event.sectionId = null;
        event.sectionType = null;
        this.updateSelectionsSubject.next(event.answerId);
      }
    }
  }

  protected setupRequiredFields(): void {
    if (this.isRequired) {
      this.ramStorageService.set(StorageKeys.CanGoForward, false);

      this.selectedCount$.pipe(takeUntil(this.unsubscribe)).subscribe((selectedCount: number) => {
        this.ramStorageService.set(
          StorageKeys.CanGoForward,
          selectedCount > 0 && (this.minSelections > 0 ? selectedCount >= this.minSelections : true),
        );
      });
    } else {
      this.ramStorageService.set(StorageKeys.CanGoForward, true);
    }
  }

  protected setupAnswers(event: IAnswerInfo): void {
    if (!this.superNegativeAnswer) {
      return;
    }

    switch (true) {
      case event.isMainAnswer:
        return this.superNegativeAnswerSelected();
      default:
        return this.superNegativeAnswerUnselected();
    }
  }

  protected superNegativeAnswerSelected(): void {
    this.sectionInfo.elements.forEach((element: IAnswerInfo) => {
      if (!element.isMainAnswer) {
        element.sectionId = null;
        element.sectionType = null;
        this.updateSelectionsSubject.next(element.answerId);
      }
    });
  }

  protected superNegativeAnswerUnselected(): void {
    if (this.superNegativeAnswer.sectionId) {
      this.superNegativeAnswer.sectionId = null;
      this.superNegativeAnswer.sectionType = null;
      this.updateSelectionsSubject.next(this.superNegativeAnswer.answerId);
    }
  }
}
