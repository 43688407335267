export enum ApiAdminsUrls {
  GetAllSchoolsStatistic = '/saas/v1.0/admins/getallschoolsstatisticbyfilters',
  GetAllInstitutionsStatistic = '/saas/v1.0/admins/getallinstitutionsstatistic',
  UpdateAllSchoolsStatistic = '/saas/v1.0/admins/updateallschoolsstatistic',
  UpdateAllInstitutionsStatistic = '/saas/v1.0/admins/updateallinstitutionsstatistic',
  ChangeUserInfo = '/saas/v1.0/admins/changeuserinfo',
  DeleteUser = '/saas/v1.0/admins/deleteuser',
  FindUsers = '/saas/v1.0/admins/findusers',
  AddAdmin = '/saas/v1.0/admins/addadmin',
  GetRegionAdmins = '/saas/v1.0/admins/getregionadmins',
  GetSchoolAdmins = '/saas/v1.0/admins/getschooladmins',
  GetAdminDos = '/saas/v1.0/admins/getadmindos',
  GetDirectors = '/saas/v1.0/admins/getdirectors',
  GetEmployerUsers = '/saas/v1.0/admins/getemployerusers',
  GetTutors = '/saas/v1.0/admins/gettutors',
  GetCatalogReportData = '/saas/v1.0/admins/getcatalogreportdata',
  UpdateAllSchoolClassExtended = '/saas/v1.0/admins/updateallschoolclassesextendedpupils',
  UpdateExtendedPupils = '/saas/v1.0/admins/updateextendedpupils',
  CalculateAllSchoolReport = '/saas/v1.0/admins/calculateallpartialschoolireport',
  CalculateSchoolReport = '/saas/v1.0/admins/calculatepartialschoolireport',
  CalculateRegionReport = '/saas/v1.0/admins/calculatepartialregionireport',
  CalculateMunicipalityReport = '/saas/v1.0/admins/calculatepartialmunicipalityireport',
  GetInstitution = '/saas/v1.0/adminsdo/getinstitution',
  GetAllCompanies = '/api/v1.0/companies/companies/all',
  GetCompanyById = '/api/v1.0/companies/companies/onebyid',
  GetCompanies = '/api/v1.0/companies/companies',
  CreateAdminDOClass = '/saas/v1.0/adminsdo/createclass',
  ReplaceAdminDOClass = '/saas/v1.0/adminsdo/replaceclass',
  RemoveAdminDOClass = '/saas/v1.0/adminsdo/deleteclass?classId=',
  GetParamsFromShortLink = '/saas/v1.0/utils/getparamsfromshortlink',
  GetUserTrajectories = '/saas/v1.0/schooladmins/getusertrajectories',
  SchoolAdminsGetSchool = '/saas/v1.0/schooladmins/getschool',
  SchoolAdminsAddSchoolClass = '/saas/v1.0/schooladmins/addschoolclass',
  SchoolAdminsDeleteSchoolClass = '/saas/v1.0/schooladmins/deleteschoolclass',
  SchoolAdminsUpdateSchoolClass = '/saas/v1.0/schooladmins/updateschoolclass',
  UpdateRegistrationCode = '/saas/v1.0/schooladmins/updateregistrationcode',
  RemoveFromSchoolClass = '/saas/v1.0/schooladmins/removefromschoolclass',
  DeleteTeacher = '/saas/v1.0/schooladmins/deleteteacher',
  DeleteRegistrationCode = '/saas/v1.0/schooladmins/deleteregistrationcode',
  ResetPassword = '/saas/v1.0/schooladmins/resetpassword',
  CreateClass = '/saas/v1.0/schooladmins/createclass',
  ReplaceClass = '/saas/v1.0/schooladmins/replaceclass',
  DeleteClass = '/saas/v1.0/schooladmins/deleteclass',
  GetRegionCourseByRegionId = '/api/v1.0/access/regioncourses/getregioncoursesbyregionid',
  GetTeachers = '/saas/v1.0/schooladmins/getteachers',
  GetTeacher = '/saas/v1.0/schooladmins/getteacher',
  GetCompletedTestsCount = '/saas/v1.0/schooladmins/getcompletedtestscountbyclass',
  GetSchoolClassesWithCounts = '/saas/v1.0/schooladmins/getschoolclasseswithcounts',
  GetSchoolStatistic = '/saas/v1.0/schooladmins/getschoolstatistic',
  UnloadInactivePupil = '/saas/v1.0/schooladmins/unloadinactivepupilspdf',
  TransferPupil = '/saas/v1.0/schooladmins/transferpupil',
  BindTeacherToClass = '/saas/v1.0/schooladmins/bindteachertoclass',
  GetRegionCourse = '/api/v1.0/access/regioncourses/getregioncourse',
}
