export enum ApiInternshipsUrls {
  Search = '/api/v1.0/internships/internships/search/filters',
  GetByCompany = '/api/v1.0/internships/internships/getbycompany',
  Interships = '/api/v1.0/internships/internships',
  IntershipsAll = '/api/v1.0/internships/internships/all',
  OneByHrid = '/api/v1.0/internships/internships/onebyhrid',
  CheckHrid = '/api/v1.0/internships/internships/checkhrid',
  OneById = '/api/v1.0/internships/internships/onebyid',
  Query = '/api/v1.0/internships/internships/search/query',
  Count = '/api/v1.0/internships/internships/count/filters',
  CountStatistic = '/api/v1.0/catalog/internships/internshipscountstatistic',
}
