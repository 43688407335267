import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from 'app/core/services/http.service';
import { IUniversity } from 'app/shared/interfaces/iuniversity.interface';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UniversitiesService {
  constructor(private http: HttpService) {}

  // обработка ошибок
  handleError(err: any): Observable<any> {
    if (err instanceof TimeoutError) {
      console.error(`Frontend returned timeout error: ${err['error']}`);
      return throwError(err['error']);
    }
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
      let errorText = err.error ? (err.error.comment ? err.error.comment : err.error) : null;
    }
    throw throwError(err);
  }

  getAllUniversities(): Observable<any> {
    return this.http.get('/api/v1.0/universities/universities/getuniversities').pipe(
      map(res => res.universities),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getUniversityById(id): Observable<IUniversity> {
    return this.http.get('/api/v1.0/universities/universities/getuniversity?id=' + id).pipe(
      map(res => res.university),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  deleteUniversity(id): Observable<any> {
    let requestBody = {
      id: id,
    };

    return this.http.deleteWithObject('/api/v1.0/universities/universities/deleteuniversity', requestBody).pipe(
      map(res => res),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  addUniversity(university) {
    let requestBody = {
      university: university,
    };
    return this.http.post('/api/v1.0/universities/universities/createuniversity', requestBody).pipe(
      map(res => res),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  updateUniversity(university) {
    let requestBody = {
      university: university,
    };
    return this.http.put('/api/v1.0/universities/universities/updateuniversity', requestBody).pipe(
      map(res => res),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getSpecialities(): Observable<any> {
    return this.http.get('/api/v1.0/catalog/specialities/all').pipe(
      map(res => res.specialities),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }
}
