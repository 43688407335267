export class InstitutionClass {
  id: string;
  createdDate?: string;
  publishedDate?: string;
  hrid: string;
  name: string;
  description: string;
  address: string;
  existenceTime?: number;
  additionalInformation?: string;
  mainImageId?: string;
  mainImagePath?: string;
  audience?: [string];
  url?: string;
  email: string;
  groupId?: string;
  groupName?: string;
  category: string;
  billingInformation?: {
    inn: string;
    ogrn: string;
    kpp: string;
  };
  scheduleId?: string;
  galleryIds?: string[];
  galleryPaths?: string[];
  regionId: string;
  municipalityId: string;
}
