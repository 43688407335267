import { IHttpStatus } from '../http';

export interface ITestInfo {
  regionId: string;
  screeningTestId: string;
  pupilVariantId: string;
  parentVariantId: string;
  isPilot: boolean;
  interestsScreeningTestId: string;
  aptitudeScreeningTestId: string;
}

export interface IGetTestSlidesCountResponse extends IHttpStatus {
  count: number;
}

export interface IGetAllScreeningTestsResponse {
  screeningTestId: string;
  name: string;
}

export interface IGetTestsByScreenTestIdAndVariantIdResponse {
  comment: string;
  senderService: string;
  slidesCount: number;
  status: string;
  time: number;
}

export interface IGetInformationAboutScreeningTestResponse {
  id: string;
  isPublished: boolean;
  hrid: string;
  name: string;
  description: string;
  registerThroughTest: boolean;
  variants: ITestVariant[];
}

export interface ITestVariant {
  id: string;
  name: string;
  language: string;
  condition: string;
  isDefault: boolean;
  slides: ITestSlide[];
  questions: ITestQuestions[];
  time: string;
}

export interface ITestSlide {
  id: string;
  screeningTestId: string;
  orderNumber: number;
  isRandomization: boolean;
  slideType: string;
  slideView: ITestSlideView;
  questionId: string;
  questionType: string;
  externalUrl: string;
  slidePassingTime: number;
  userAnswers: ITestUserAnswers[];
  minAnswers: number;
  maxAnswers: number;
}

export interface ITestSlideView {
  viewType: string;
  bubbleView: ITestBubbleView;
  cardsView: ITestCardsView;
  scaleView: ITestScaleView;
  rectangleView: ITestRectangleView;
}

export interface ITestBubbleView {
  bubbles: ITestBubbles[];
  sections: ITestSections[];
  imageUrl: string;
}

export interface ITestBubbles {
  answerId: string;
  answerType: string;
  backgroundImageUrl: string;
  text: string;
  orderNumber: number;
  isMainAnswer: boolean;
  description: string;
}

export interface ITestSections {
  sectionId: string;
  name: string;
  description: string;
  sectionType: string;
  isRequired: boolean;
  minAnswers: number;
  maxAnswers: number;
}

export interface ITestCardsView {
  name: string;
  description: string;
  cards: ITestCards[];
  positiveSectionId: string;
  negativeSectionId: string;
  neutralSectionId: string;
}

export interface ITestCards {
  answerId: string;
  imageUrl: string;
  text: string;
}

export interface ITestScaleView {
  title: string;
  description: string;
  rulers: ITestRulers[];
  imageUrl: string;
  isRequired: boolean;
}

export interface ITestRulers {
  questionSectionId: string;
  text: string;
  marks: ITestMarks[];
}

export interface ITestMarks {
  orderNumber: number;
  answerId: string;
  text: string;
}

export interface ITestRectangleView {
  rectangles: ITestRectangles[];
  sections: ITestSections[];
  imageUrl: string;
}

export interface ITestRectangles {
  answerId: string;
  answerType: string;
  backgroundImageUrl: string;
  text: string;
  orderNumber: number;
  isMainAnswer: boolean;
  description: string;
}

export interface ITestSections {
  sectionId: string;
  name: string;
  description: string;
  sectionType: string;
  isRequired: boolean;
  minAnswers: number;
  maxAnswers: number;
}

export interface ITestUserAnswers {
  questionSectionId: string;
  answerId: string;
  answerType: string;
  answerText: string;
}

export interface ITestQuestions {
  id: string;
  name: string;
  description: string;
  questionType: string;
  questionSections: ITestQuestionSections[];
  answers: ITestAnswers[];
  imageUrl: string;
  isRandomization: boolean;
}

export interface ITestQuestionSections {
  id: string;
  name: string;
  description: string;
  sectionType: string;
  isRequired: boolean;
  minAnswers: number;
  maxAnswers: number;
}

export interface ITestAnswers {
  id: string;
  fullText: string;
  orderNumber: number;
  isDefault: boolean;
  answerType: string;
  description: string;
}

export interface IAnswerInfoMark {
  answerId: string;
  orderNumber: number;
  text: string;
}

export interface IAnswerInfo {
  answerId?: string;
  questionId?: string;
  questionSectionId?: string;
  sectionId?: string;
  text?: string;
  orderNumber?: number;
  backgroundImageUrl?: string;
  sectionType?: string;
  answerType?: string;
  marks?: IAnswerInfoMark[];
  selectedAnswerId?: string;
  middleRangeElement?: IAnswerInfoMark;
  isEven?: boolean;
  isMainAnswer?: boolean;
  description?: string;
}

export interface IMoveToNextSlideResponse {
  slide: ISlide;
  passedFirst: boolean;
  passedLast: boolean;
}

export interface IMoveToNextSlideAnonymousResponse extends IMoveToNextSlideResponse {}

export interface IMoveToPreviousSlideResponse extends IMoveToNextSlideResponse {}

export interface IGetCurrentSlideResponse extends IMoveToNextSlideResponse {}

export interface ISlide {
  id: string;
  screeningTestId: string;
  orderNumber: number;
  slideType: string;
  slideView: ISlideView;
  questionId: string;
  questionType: string;
  externalUrl: string;
  slidePassingTime: number;
  userAnswers: IAnswerInfo[];
}

export interface ISlideView {
  viewType: string;
  bubbleView: ISlideBubbleView | null;
  cardsView: ISlideCardsView | null;
  rectangleView: ISlideRectangleView | null;
  scaleView: ISlideScaleView | null;
}

export interface ISlideBubbleView {
  bubbles: ISlideBubble[];
  sections: ISlideSection[];
  imageUrl: string;
}

export interface ISlideBubble {
  answerId: string;
  answerType: string;
  backgroundImageUrl: string;
  text: string;
  orderNumber: number;
}

export interface ISlideCardsView {
  name: string;
  description: string;
  cards: ISlideCard[];
  positiveSectionId: string;
  negativeSectionId: string;
  neutralSectionId: string;
}

export interface ISlideCard {
  answerId: string;
  imageUrl: string;
  text: string;
}

export interface ISlideScaleView {
  title: string;
  description: string;
  rulers: IAnswerInfo[];
  imageUrl: string;
  isRequired: boolean;
}

export interface ISlideScaleRuler {
  questionSectionId: string;
  text: string;
  marks: ISlideScaleMark[];
}

export interface ISlideScaleMark {
  orderNumber: number;
  answerId: string;
  text: string;
}

export interface ISlideRectangleView {
  rectangles: ISlideRectangle[];
  sections: ISlideSection[];
  imageUrl: string;
}

export interface ISlideRectangle {
  answerId: string;
  answerType: string;
  backgroundImageUrl: string;
  text: string;
  orderNumber: number;
}

export interface ISlideSection {
  sectionId: string;
  name: string;
  description: string;
  sectionType: string;
  isRequired: boolean;
  minAnswers?: number;
  maxAnswers?: number;
}
