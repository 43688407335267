import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'prf-archive-courses',
  templateUrl: './archive-courses.component.html',
  styleUrls: ['./archive-courses.component.scss'],
})
export class ArchiveCoursesComponent {
  public viewValue: boolean = false;

  constructor(private meta: Meta) {
    this.meta.updateTag({ name: 'og:title', content: 'Архивные курсы' });
  }
}
