import { inject, Injectable } from '@angular/core';
import { HttpService } from '../http';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ApiUtilsUrls } from './api-utils.collections';
import { IDeleteTestSessionsRequest, IDeleteTestSessionsResponse } from './api-utils.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiUtilsService {
  private readonly httpService: HttpService = inject(HttpService);

  public getParamsFromShortLink(shortLink: string): Observable<any> {
    return this.httpService.get(ApiUtilsUrls.GetParamsFromShortLink, { shortLink }).pipe(take(1));
  }

  public uploadImage(image: File | FileList, id: string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append(image[0].name, image[0]);
    return this.httpService.postImage(ApiUtilsUrls.Upload + id, formData).pipe(take(1));
  }

  public deleteTestSessions(data: IDeleteTestSessionsRequest): Observable<IDeleteTestSessionsResponse> {
    return this.httpService.delete<IDeleteTestSessionsResponse>(ApiUtilsUrls.DeleteTestSessions, null, data).pipe(take(1));
  }

  public deleteFileFromTrajectory(id: string): Observable<any> {
    return this.httpService.delete(ApiUtilsUrls.DeleteFile, { id }).pipe(take(1));
  }

  public getFilesToTrajectory(id: string): Observable<any> {
    return this.httpService.get(ApiUtilsUrls.GetFilesForObject, { id }).pipe(take(1));
  }
}
