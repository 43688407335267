import { ChangeDetectionStrategy, Component, inject, OnDestroy } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, RouterLink } from '@angular/router';
import Theme from '../themes/theme/theme';
import { Store } from '@ngxs/store';
import { DeleteAll } from '@profilum-components/prf-tests/slides-store/slides.actions';
import { Helper } from '@profilum-helpers/common-helper/helper';
import { IAnketaTestInfo, IPlayerOptions } from '@profilum-interfaces/prf-tests.interface';
import { RamStorageService } from '@profilum-logic-services/ram-storage/ram-storage.service';
import { StorageKeys } from '@profilum-logic-services/web-storage/web-storage.collections';
import { WebStorageService } from '@profilum-logic-services/web-storage/web-storage.service';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { SectionComponent } from '@profilum-components/prf-tests/sections/section/section.component';

@Component({
  selector: 'prf-player-v2',
  templateUrl: './player-v2.component.html',
  styleUrls: ['./player-v2.component.scss'],
  standalone: true,
  imports: [CommonModule, SectionComponent, NgOptimizedImage, RouterLink],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlayerV2Component implements OnDestroy {
  public slidesCount: number = 1;
  public slideNumber: number = 1;
  protected ramStorageService: RamStorageService = inject(RamStorageService);
  protected meta: Meta = inject(Meta);
  protected webStorageService: WebStorageService = inject(WebStorageService);
  protected activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  protected themes: Theme[] | undefined;
  public userRole: string = this.webStorageService.get(StorageKeys.UserRole);
  public yearNumber: number = new Date().getFullYear();
  public options: IPlayerOptions;
  public themeLinkHref: string;
  public isPreview: boolean = (this.activatedRoute.snapshot.data as IAnketaTestInfo)?.isPreview ?? false;
  private sessionId: string;
  private store: Store = inject(Store);
  protected screeningTestName: string = '';
  protected enableYM: boolean = false;
  protected storageKeyForCachingSessionId: StorageKeys | undefined;

  constructor() {
    this.meta.updateTag({ name: 'og:title', content: 'Страница теста' });
    this.getAnketaTestInfo();
  }

  public ngOnDestroy(): void {
    this.themes?.forEach((theme: Theme) => theme.clearTheme());
    this.store.dispatch(new DeleteAll());
  }

  public allSlidesCount(event: number): void {
    this.slidesCount = event;
  }

  public currentSlideNumber(event: number): void {
    this.slideNumber = event;

    if (this.slideNumber === 2) {
      this.webStorageService.set(StorageKeys.TestSessionId, this.sessionId);
      this.webStorageService.set(StorageKeys.SessionStatus, 'Uncompleted test');
    }
  }

  private getAnketaTestInfo(): void {
    const anketaTestInfo: IAnketaTestInfo = this.activatedRoute.snapshot.data as IAnketaTestInfo;

    if (!Helper.isObject(anketaTestInfo, true)) {
      return;
    }

    this.themeLinkHref = anketaTestInfo.themeLinkHref;
    this.screeningTestName = anketaTestInfo.screeningTestName ?? '';
    anketaTestInfo.enableYM && (this.enableYM = anketaTestInfo.enableYM);
    anketaTestInfo.storageKeyForCachingSessionId && (this.storageKeyForCachingSessionId = anketaTestInfo.storageKeyForCachingSessionId);
  }
}
