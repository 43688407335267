import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from 'app/core/services/http.service';
import { IFilterInternships } from 'app/pages/catalog/internship-page/internship-catalog/internship-filter/internships-filter.service';
import { IInternship } from 'app/shared/interfaces/iinternships.interface';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class InternshipsService {
  constructor(private http: HttpService) {}

  // обработка ошибок
  handleError(err: any): Observable<any> {
    if (err instanceof TimeoutError) {
      console.error(`Frontend returned timeout error: ${err['error']}`);
      return throwError(err['error']);
    }
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
      let errorText = err.error ? (err.error.comment ? err.error.comment : err.error) : null;
    }
    throw throwError(err);
  }

  createInternshipsDO(internshipsDto: IInternship): Observable<any> {
    var request = {
      internship: internshipsDto,
    };

    return this.http.post('/api/v1.0/internships/internships', request).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  changeInternshipsDO(internshipId: string, internship: IInternship): Observable<any> {
    var request = {
      id: internshipId,
      internship: internship,
    };
    return this.http.put('/api/v1.0/internships/internships', request).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  removeCurrentInternship(internshipId: any): Observable<any> {
    var request = { id: internshipId };
    return this.http.delete('/api/v1.0/internships/internships', request).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  checkHrid(hrid: string): Observable<any> {
    var request = { Hrid: hrid };
    return this.http.post('/api/v1.0/internships/internships/checkhrid', request).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  public getInternshipsQuery(query: string) {
    var request = { query: query };
    return this.http.post('/api/v1.0/internships/internships/search/query', request).pipe(map(res => res.internships));
  }

  getInternshipByHrid(hrid: string): Observable<any> {
    return this.http.get(`/api/v1.0/internships/internships/onebyhrid?hrid=${hrid}`).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  getInternshipByID(internshipId): Observable<any> {
    return this.http.get('/api/v1.0/internships/internships/onebyid?id=' + internshipId).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  public getInternshipsCount(filters: IFilterInternships) {
    let url = '/api/v1.0/internships/internships/count/filters';
    var request = { filters: filters };
    return this.http.post(url, request).pipe(map(resp => resp.count));
  }

  public getAllInternships() {
    return this.http.get('/api/v1.0/internships/internships/all').pipe(map(response => response.internships));
  }

  getTitleByNumber(n, titles: string[]) {
    return titles[n % 10 === 1 && n % 100 !== 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2];
  }

  public getTalentGroupCourse() {
    return this.http.get('/api/v1.0/catalog/search/talentgroupcourse').pipe(map(response => response.result));
  }

  //TODO: добавить логику фильтрации, когда на беке появится этот метод (пока отсутствует)
  public getInternshipsCountStatistic() {
    return this.http
      .get('/api/v1.0/catalog/internships/internshipscountstatistic')
      .pipe(map(response => response.internshipsCountStatistic));
  }

  public getCourseGroups() {
    return this.http.get('/api/v1.0/catalog/fielddos/all').pipe(
      map(response => {
        const data = response.fieldDOs;
        data.sort(sortList);
        return data;
      }),
    );
  }

  public getTalents() {
    return this.http.get('/api/v1.0/catalog/search/talents').pipe(
      map(response => {
        const data = response.result;
        data.sort(sortList);
        return uniqSorted(data);
      }),
    );
  }

  getInternshipsByCompany(companyId: string): Observable<any> {
    return this.http.get('/api/v1.0/internships/internships/getbycompany?id=' + companyId);
  }
}

function sortList(left, right) {
  if (left.name > right.name) {
    return 1;
  }
  if (left.name < right.name) {
    return -1;
  }
  return 0;
}

function uniqSorted(list) {
  const newList = [list[0]];

  list.forEach(({ name, line }) => {
    if (newList[newList.length - 1].name !== name) {
      newList.push({ name, line });
    }
  });

  return newList;
}
