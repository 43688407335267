import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'prf-content-loader',
  templateUrl: './content-loader.component.html',
  styleUrls: ['./content-loader.component.scss'],
})
export class ContentLoaderComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
