import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface PromotestResultsCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class CanDeactivateGuard {
  constructor() {}

  canDeactivate(component: PromotestResultsCanDeactivate): Observable<boolean> | boolean {
    return component.canDeactivate ? component.canDeactivate() : true;
  }
}
