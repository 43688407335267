import { inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Params } from '@angular/router';
import { IHttpStatus, IObject } from './http.interface';
import { httpHeaders } from './http.collections';
import { Helper } from '@profilum-helpers/common-helper/helper';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private readonly httpClient: HttpClient = inject(HttpClient);

  public get<T>(path: string, queryParams: Params | null = null): Observable<T> {
    return this.httpClient.get<T>(this.getUrl(path, queryParams)).pipe(catchError(error => throwError(error)));
  }

  public post<T>(path: string, body: IObject | null = null, queryParams: Params | null = null): Observable<T> {
    return this.httpClient
      .post<T>(this.getUrl(path, queryParams), JSON.stringify(body ?? {}), this.getHeaders())
      .pipe(catchError(error => throwError(error)));
  }

  public put<T>(path: string, body: IObject | null = null, queryParams: Params | null = null): Observable<T> {
    return this.httpClient.put<T>(this.getUrl(path, queryParams), JSON.stringify(body ?? {}), this.getHeaders()).pipe(
      catchError((error: any) => {
        throw new Error(error);
      }),
    );
  }

  public delete<T>(path: string, queryParams: IObject | undefined | null = undefined, body: IObject | null = null): Observable<T> {
    return this.httpClient.request<T>('delete', this.getUrl(path, queryParams), { body }).pipe(catchError(error => throwError(error)));
  }

  public static parseHttpStatus(error: any): IHttpStatus | null {
    if (!Helper.isString(error?.message)) {
      return null;
    }

    try {
      const httpStatus: IHttpStatus = JSON.parse(error.message);

      return httpStatus?.status ? httpStatus : null;
    } catch (error) {
      return null;
    }
  }

  private getHeaders(): { headers: HttpHeaders } {
    return { headers: httpHeaders };
  }

  private getUrl(path: string, queryParams: Params | null = null): string {
    const queryParamsStr: string = this.createQueryParams(queryParams);
    const url: string = `/api${path}`;

    return queryParamsStr ? `${url}?${queryParamsStr}` : url;
  }

  private createQueryParams(queryParams: Params | null): string {
    if (!Helper.isObject(queryParams, true)) {
      return '';
    }

    const urlSearchParams: URLSearchParams = new URLSearchParams({});

    for (const prop in queryParams) {
      if (queryParams.hasOwnProperty(prop)) {
        try {
          urlSearchParams.append(prop, queryParams[prop]);
        } catch (e) {
          console.error(e);
        }
      }
    }

    return urlSearchParams.toString();
  }
}
