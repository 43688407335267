import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs';
import { IAnswerInfo } from '@profilum-api-services/api-slides/api-slides.interface';
import { TEXTAREA_MAX_LENGTH, TEXTAREA_MIN_LENGTH } from '@profilum-collections/prf-tests.collections';
import { TestBoxHelperComponent } from '@profilum-components/prf-tests/test-helper/test-box-helper.component';
import { StorageKeys } from '@profilum-logic-services/web-storage/web-storage.collections';
import { TranslateModule } from '@ngx-translate/core';


@Component({
  selector: 'prf-open-question',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslateModule],
  templateUrl: './open-question.component.html',
  styleUrls: ['./open-question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpenQuestionComponent extends TestBoxHelperComponent implements OnInit {
  @Input() public rows: number = 1;
  public form: FormGroup;
  protected minLength: number = TEXTAREA_MIN_LENGTH;
  protected maxLength: number = TEXTAREA_MAX_LENGTH;
  private formBuilder: FormBuilder = inject(FormBuilder);
  private answerPattern: string = '^(?!(.)\\1+$)[^\\w]*.*$';

  public ngOnInit(): void {
    this.form = new FormGroup({
      formData: this.formBuilder.array<FormGroup>(this.createQuestionsArray()),
    });
    this.setupRequiredFields();
  }

  public changeAnswer(event: Event): void {
    const answerInfo: IAnswerInfo = this.sectionInfo.elements[0];
    const target: HTMLTextAreaElement = event.target as HTMLTextAreaElement;

    answerInfo.sectionId = this.sectionInfo.sectionId;
    answerInfo.sectionType = this.sectionInfo.sectionType;
    answerInfo.text = target.value;
  }

  public trackByFn(index: number, answer: IAnswerInfo): string {
    return answer.answerId;
  }

  public isGoForwardAllowed(): boolean {
    return this.form.valid;
  }

  private createQuestionsArray(): FormGroup[] {
    return Array<FormGroup>(this.sectionInfo.elements.length).fill(this.createQuestionControl());
  }

  private createQuestionControl(): FormGroup {
    return this.formBuilder.group({
      question: ['', [Validators.required, Validators.pattern(this.answerPattern)]],
    });
  }

  protected setupRequiredFields(): void {
    if (this.isRequired) {
      this.ramStorageService.set(StorageKeys.CanGoForward, false);

      this.form.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
        this.ramStorageService.set(StorageKeys.CanGoForward, this.isGoForwardAllowed());
      });
    } else {
      this.ramStorageService.set(StorageKeys.CanGoForward, true);
    }
  }
}
