import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SpiderChartThemeEnum } from '../spider-chart-custom.model';
import { SPIDER_CHART_CONFIG } from '../spider-chart-custom.config';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'svg[prf-spider-chart-polygon]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './spider-chart-polygon.component.html',
  styleUrls: ['./spider-chart-polygon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpiderChartPolygonComponent {
  protected readonly SPIDER_CHART_CONFIG = SPIDER_CHART_CONFIG;

  @Input({ required: true }) public theme: SpiderChartThemeEnum;
  @Input({ required: true }) public polygonPoints: string;

  @ViewChild('polygonRef') public polygonRef: ElementRef;
}
