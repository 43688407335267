<a class="btn-prf-outline" (click)="showAddDialog()">{{ 'SHARED.ADD_CITY' | translate }}</a>

<div class="editors-modal" *ngIf="dialogOpen" [ngClass]="dialogOpen ? 'active-modal' : ''">
  <div class="editors-modal-wrapper">
    <div class="editors-modal-body">
      <div class="editors-modal-dialog">
        <div class="editors-modal-dialog-body">
          <p (click)="dialogOpen = !dialogOpen">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 35 35">
              <g fill-rule="evenodd" stroke-linecap="square">
                <path d="M9.192 9.192l18.385 18.385M27.577 9.192L9.192 27.577"></path>
              </g>
            </svg>
          </p>
          <form novalidate #f="ngForm" (ngSubmit)="onSubmit(f); f.reset()">
            <mat-form-field class="full-width">
              <input matInput id="name" [(ngModel)]="city.name" name="name" #classname="ngModel" placeholder="Название" required />
            </mat-form-field>

            <mat-form-field class="full-width">
              <input matInput id="hrid" [(ngModel)]="city.hrid" name="hrid" #classhrid="ngModel" placeholder="hrid" required />
            </mat-form-field>

            <mat-form-field class="full-width">
              <mat-select placeholder="{{ 'SHARED.REGION' | translate }}" [(ngModel)]="selectedRegion" name="region" required>
                <mat-option *ngFor="let region of regions" [value]="region" (click)="setRegion(region)">
                  {{ region.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="full-width">
              <mat-select placeholder="Муниципалитет" [(ngModel)]="selectedMunicipality" name="municipality" required>
                <mat-option *ngFor="let municipality of municipalitiesByRegion" [value]="municipality">
                  {{ municipality.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <button type="submit" mat-raised-button>{{ 'SHARED.ADD_CITY' | translate }}</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
