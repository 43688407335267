<ng-container [ngSwitch]="activeLocation">
  <div *ngSwitchCase="'mo'">
    <prf-landing-mosobl></prf-landing-mosobl>
  </div>
  <div *ngSwitchCase="'kz'">
    <prf-landing-kz></prf-landing-kz>
  </div>
  <div *ngSwitchDefault>
    <prf-landing-base></prf-landing-base>
  </div>
</ng-container>
