import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Meta, MetaDefinition } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminPanelService } from 'app/pages/control-panel/admin/admin-panel.service';
import { PrEventsEnums } from 'app/shared/enums/pr-events.enums';
import { REG_EXP } from 'app/shared/global-constants/reg-exp';
import { ICity } from 'app/shared/interfaces/icity';
import { FilterByNamePipe } from 'app/shared/pipes/filter-by-name.pipe';
import { environment } from 'environments/environment';
import { Observable, Subject, of } from 'rxjs';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AppSettingsService } from '../../../../shared/services/appsettings.service';
import { RegistrationProsvetService } from '../registration-prosvet.service';
import { RegionsService } from '../../../../pages/control-panel/admin/regions/regions.service';
import { ChildrenAddService } from '../../../../pages/control-panel/parent/children-add/children-add.service';
import { FilterSchoolsPipe } from '../../../../shared/pipes/filter-schools.pipe';
import { TranslateService } from '@ngx-translate/core';
import { UserInfoClass } from 'app/shared/classes/userInfo.class';
import { SharedService } from 'app/shared/shared.service';
import { IProsvetUserData } from 'app/shared/interfaces/iprosvet.userdata.interface';
import { SchoolClassesClass } from 'app/shared/classes/school-classes.class';
import * as moment from 'moment';
import { Gender } from 'app/shared/enums/gender.enums';

@Component({
  selector: 'prf-open-registration-prosvet',
  templateUrl: './open-registration-prosvet.component.html',
  styleUrls: ['./open-registration-prosvet.component.scss'],
})
export class OpenRegistrationProsvetComponent implements OnInit {
  @Input() prosvetUserData: IProsvetUserData;
  public personalTerms: boolean = false;
  public advertisingTerms: boolean = false;
  public duplicateUserName: boolean = false;
  public mask = ['+', '7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];
  public isKz: boolean = false;
  public prosvetUserRegistration: boolean = false;

  tag: string;
  public form: UntypedFormGroup;
  public submitted: boolean;
  public readonly dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  public readonly emailRegExp: RegExp = REG_EXP.emailRegExp;
  public readonly dateRegExp: RegExp = REG_EXP.dateRegExp;
  public readonly testOneLetter: RegExp = REG_EXP.testOneLetter;
  public readonly testOneDigit: RegExp = REG_EXP.testOneDigit;
  public readonly testSixCharter: RegExp = REG_EXP.testSixCharter;
  public readonly testWhiteSpace: RegExp = REG_EXP.testWhiteSpace;
  public readonly testRusLetters: RegExp = REG_EXP.testRusLetters;
  public readonly phoneRegExp: RegExp = REG_EXP.phoneRegExp;

  public isCharactersError: boolean = true;
  public isLetterError: boolean = true;
  public isNumberError: boolean = true;
  public isRusLettersError: boolean = false;
  public isWhiteSpaceError: boolean = false;
  public isNotValidPassword: boolean = false;
  public focusOutPasswordErrors: boolean = false;

  public addition: boolean = false;
  public regions = [];
  public cities: ICity[] = [];
  public classes: SchoolClassesClass[] = [];
  public classesFetched: boolean = false;
  public schools: any[] = [];
  private _schoolView: any[] = [];
  public numbersClass: any = [];
  public lettersClass: any = [];

  public registrationFailed: boolean = false;
  public passFailed: boolean = false;
  public loginFailed: boolean = false;

  public moment: any = moment;
  public buttonWaiting: boolean = false;
  public buttonActivated: boolean = false;
  public buttonActivate: boolean = false;
  public isMaskedPassword: boolean = true;
  public codeIsUsed: boolean = false;
  public checkEmail: boolean = true;
  public checkBirthday: boolean = false;
  public isClasses: boolean = false;

  public isNotEnoughYears: boolean = false; // признак для определения разрешенного возраста
  private minAge: number = 14; // минимальный разрешенный возраст для регистрации ученика
  public SCHOOL_LETTERS: { value: string }[] = [];
  public defaultRegion: string = null;
  private ngUnsubscribe$: Subject<any> = new Subject<any>();

  formChild: UntypedFormGroup;
  public childRegOpened: boolean = false;

  public isMobile: boolean = false;

  isProsvetIdUser: boolean = false;

  parentUserId: string = null;
  // mark for users form Vorobievi Gori
  public isVGuser: boolean = false;
  public urlToken: string = '';

  public parentValidated: boolean = false;
  public regionEnabled: boolean = true;
  public cityEnabled: boolean = true;

  public submittedChild: boolean = false;
  public genderChoice: string = Gender.Male;
  public invalidDate: boolean = false;
  newDate: Date = new Date();
  personalChildTerms: boolean = false;

  public selectedCity: ICity;
  public schoolsFiltered: any[] = [];
  public schoolName: string;
  public searchCity: string;
  public searchSchool: string;
  public schoolID: string = '';
  public selectedSchoolClass: any;
  public schoolClass: string;

  private role = 'parent';

  constructor(
    private registrationService: RegistrationProsvetService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private regionsService: RegionsService,
    private childrenAddService: ChildrenAddService,
    private filterSchoolsPipe: FilterSchoolsPipe,
    private filterByNamePipe: FilterByNamePipe,
    private translateService: TranslateService,
    private adminPanelService: AdminPanelService,
    private appSettingsService: AppSettingsService,
    private sharedService: SharedService,
    private meta: Meta,
    private activatedRoute: ActivatedRoute,
  ) {
    const componentName: MetaDefinition = {
      name: 'profliner:title',
      content: 'Open Registration',
    };
    this.meta.addTag(componentName);
  }

  ngOnInit() {
    this.SCHOOL_LETTERS = this.appSettingsService.getByDefaultLocale('SCHOOL_LETTERS');
    this.initFormGroup();
    this.getData().pipe(
      tap(_ => {
        if (this.prosvetUserData != null) {
          // SchoolPortal registration
          this.prosvetUserRegistration = true;
          this.setUserDataValues();
        } else {
          // open registration
          this.prosvetUserRegistration = false;
          this.setEmptyValues();
          if (!this.tag) {
            this.activatedRoute.queryParams.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(params => {
              this.tag = params.tag;
            });
            // Check if we have this tag type in system
            if (this.tag != PrEventsEnums.Emailing.toString()) {
              this.tag = null;
            }
          }
        }

        this.defaultRegion = this.prosvetUserData.regionId;

        // Check Tag in URL
        if (!this.tag) {
          this.activatedRoute.queryParams.subscribe(params => {
            this.tag = params.tag;
          });
          // Check if we have this tag type in system
          if (this.tag != PrEventsEnums.Emailing.toString()) {
            this.tag = null;
          }
        }
      }),
    );
  }

  getData(): Observable<any> {
    return this.getRegions().pipe(switchMap(_ => this.getCities().pipe(switchMap(_ => this.getSchools()))));
  }

  private setEmptyValues() {
    this.form = this.fb.group({
      lastName: new UntypedFormControl(null, [Validators.required]),
      firstName: new UntypedFormControl(null, [Validators.required]),
      middleName: new UntypedFormControl(null, [Validators.required]),
      phone: new UntypedFormControl(null, [Validators.maxLength(18), Validators.pattern(this.phoneRegExp)]),
      city: new UntypedFormControl(null, [Validators.required]),
      email: new UntypedFormControl(null, [Validators.required, Validators.pattern(this.emailRegExp)]),
      password: new UntypedFormControl(null, [
        Validators.required,
        // Validators.pattern(this.passwordRegExp)
      ]),
      role: new UntypedFormControl(this.role, [Validators.required]),
    });
  }

  getRegionCities(): Observable<any> {
    return this.adminPanelService.getRegionCities(this.defaultRegion).pipe(tap(r => (this.cities = r)));
  }

  public registerBase() {
    this.submitted = true;
    if (this.form.valid) {
      const credentials = {
        email: this.f.email.value,
        lastName: this.f.lastName.value,
        firstName: this.f.firstName.value,
        middleName: this.f.middleName.value,
        password: this.f.password.value,
        phoneNumber: this.f.phone.value?.replace(/\D/g, ''),
        regionId: this.defaultRegion,
        municipalityId: this.f.city.value.data.municipalityId,
        city: this.f.city.value,
        role: this.f.role.value,
        tag: this.tag,
        isConsentToMailing: this.advertisingTerms,
      };
      this.registrationFailed = false;
      this.passFailed = false;
      this.duplicateUserName = false;
      this.registrationService
        .openregistration(credentials)
        .pipe(
          switchMap((response: any) => {
            if (response) {
              if (response.userId == undefined || response.userId == null) {
                this.duplicateUserName = response.status = !!'Registration failed'; // почта занята
                this.registrationFailed = true; // ошибка регистрации
                this.failWaiting();
                return of(null);
              } else {
                // Сразу попадаем в ЛК
                return this.registrationService.loginProsvetUser(response.userId, response.email).pipe(
                  take(1),
                  tap((loginResult: any) => {
                    if (loginResult) {
                      if (loginResult.succeeded === false) {
                        this.loginFailed = true;
                        this.passFailed = true;
                      } else {
                        localStorage.setItem('userRole', loginResult.role);
                        localStorage.setItem('userId', loginResult.userId);
                        localStorage.setItem('tag', loginResult.tag);
                        localStorage.setItem('isAuthorized', 'true');

                        switch (loginResult.role) {
                          case 'parent': {
                            return this.router.navigate(['/parent']);
                          }
                        }
                      }
                    }
                  }),
                );
              }
            } else return of(null);
          }),
          takeUntil(this.ngUnsubscribe$),
        )
        .subscribe(_ => this.removeWaiting());
    } else {
      this.failWaiting();
    }
  }

  public registerProsvet() {
    // регистрация родителя через Просвещение
    this.submitted = true;
    if (this.personalTerms && (this.form.valid || this.form.disabled)) {
      const prosvetCredentials = {
        externalAppUserId: this.prosvetUserData.externalAppUserId,
        email: this.f.email.value,
        lastName: this.f.lastName.value,
        firstName: this.f.firstName.value,
        middleName: this.f.middleName.value,
        phoneNumber: this.f.phone.value?.replace(/\D/g, ''),
        role: this.f.role.value,
        regionId: this.defaultRegion,
        municipalityId: this.f.city.value.municipalityId,
        city: this.f.city.value.name,
        isConsentToMailing: this.advertisingTerms,
      };
      this.registrationFailed = false;
      this.passFailed = false;
      this.duplicateUserName = false;
      this.registrationService
        .registrationProsvetUser(prosvetCredentials)
        .pipe(
          switchMap((response: any) => {
            if (!response || response.userId == undefined || response.userId == null) {
              this.duplicateUserName = response.status = !!'Registration failed'; // почта занята
              this.registrationFailed = true; // ошибка регистрации
              this.failWaiting();
              return of(null);
            } else {
              // Сразу попадаем в ЛК
              return this.registrationService.loginProsvetUser(response.userId, prosvetCredentials.email).pipe(
                take(1),
                switchMap((loginResult: any) => {
                  if (!loginResult || loginResult.succeeded === false) {
                    this.loginFailed = true;
                    this.passFailed = true;
                    this.removeWaiting();
                    return of(null);
                  } else {
                    localStorage.setItem('userRole', loginResult.role);
                    localStorage.setItem('userId', loginResult.userId);
                    localStorage.setItem('tag', loginResult.tag);
                    localStorage.setItem('isAuthorized', 'true');

                    return this.sharedService.getUserInfoData().pipe(
                      tap((ui: UserInfoClass) => {
                        if (ui) {
                          this.setUserInfoInLS(ui);
                        }

                        // Сразу попадаем в ЛК
                        switch (loginResult.role) {
                          case 'parent': {
                            return this.router.navigate(['/parent']);
                          }
                        }
                        this.removeWaiting();
                      }),
                    );
                  }
                }),
              );
            }
          }),
          takeUntil(this.ngUnsubscribe$),
        )
        .subscribe();
    } else {
      this.failWaiting();
    }
  }

  public setUserDataValues() {
    this.form = this.fb.group({
      lastName: new UntypedFormControl(
        {
          value: this.prosvetUserData.lastName,
          disabled: this.prosvetUserData.lastName ? true : false,
        },
        [Validators.required],
      ),
      firstName: new UntypedFormControl(
        {
          value: this.prosvetUserData.firstName,
          disabled: this.prosvetUserData.firstName ? true : false,
        },
        [Validators.required],
      ),
      middleName: new UntypedFormControl(
        {
          value: this.prosvetUserData.middleName,
          disabled: this.prosvetUserData.middleName ? true : false,
        },
        [Validators.required],
      ),
      email: new UntypedFormControl({ value: this.prosvetUserData.email, disabled: this.prosvetUserData.email ? true : false }, [
        Validators.required,
        Validators.pattern(this.emailRegExp),
      ]),
      phone: new UntypedFormControl(null, [Validators.maxLength(18), Validators.pattern(this.phoneRegExp)]),
      role: new UntypedFormControl('parent', [Validators.required]),
      city: new UntypedFormControl({ value: null, disabled: false }, [Validators.required]),
    });
  }

  public setLanguage() {
    let language = localStorage.getItem('selectedLanguage');
    if (language && ['ru', 'kz'].indexOf(language) > -1) {
      this.isKz = language === 'kz' ? true : false;
      this.translateService.use(language);
    }
    return;
  }

  initFormGroup() {
    this.form = this.fb.group({
      lastName: new UntypedFormControl(null, [Validators.required]),
      firstName: new UntypedFormControl(null, [Validators.required]),
      middleName: new UntypedFormControl(null, [Validators.required]),
      phone: new UntypedFormControl(null, [Validators.maxLength(18), Validators.pattern(this.phoneRegExp)]),
      region: new UntypedFormControl(null, [Validators.required]),
      city: new UntypedFormControl(null, [Validators.required]),
      email: new UntypedFormControl(null, [Validators.required, Validators.pattern(this.emailRegExp)]),
      password: new UntypedFormControl(null, [Validators.required]),
      role: new UntypedFormControl(this.role, [Validators.required]),
    });
  }

  getRegions(): Observable<any> {
    return this.regionsService.getAllRegions().pipe(
      tap(regions => {
        this.regions = regions.filter(region => region.hrid != 'defaultRegion');
      }),
    );
  }

  getCities(): Observable<any> {
    return this.adminPanelService.getAllCities().pipe(tap(cities => (this.cities = cities)));
  }

  getSchools(): Observable<any> {
    return this.childrenAddService.getCatalogSchools().pipe(
      tap((schools: any[]) => {
        this.schools = [...schools].map(school => ({
          city: school.city,
          value: school.number,
          viewValue: school.number,
          id: school.id,
        }));
      }),
    );
  }

  get isClassSelected() {
    return true;
  }

  public submit() {
    this.submitted = true;
    if (this.form.valid) {
      const credentials = {
        email: this.f.email.value,
        lastName: this.f.lastName.value,
        firstName: this.f.firstName.value,
        middleName: this.f.middleName.value,
        password: this.f.password.value,
        phoneNumber: this.f.phone.value?.replace(/\D/g, ''),
        regionId: this.f.city.value.regionId,
        municipalityId: this.f.city.value.municipalityId,
        city: this.f.city.value.data.name,
        role: this.f.role.value,
        tag: this.tag,
        isConsentToMailing: this.advertisingTerms,
      };
      this.registrationFailed = false;
      this.passFailed = false;
      this.duplicateUserName = false;
      this.registrationService
        .openregistration(credentials)
        .pipe(
          switchMap((response: any) => {
            if (!response || response.userId == undefined || response.userId == null) {
              this.duplicateUserName = response.status = !!'Registration failed'; // почта занята
              this.registrationFailed = true; // ошибка регистрации
              this.failWaiting();
              return of(null);
            } else {
              // Сразу попадаем в ЛК
              return this.registrationService.loginProsvetUser(response.userId, response.email).pipe(
                switchMap((loginResult: any) => {
                  if (!loginResult || loginResult.succeeded === false) {
                    this.loginFailed = true;
                    this.passFailed = true;
                    this.removeWaiting();
                    return of(null);
                  } else {
                    localStorage.setItem('userRole', loginResult.role);
                    localStorage.setItem('userId', loginResult.userId);
                    localStorage.setItem('tag', loginResult.tag);
                    localStorage.setItem('isAuthorized', 'true');

                    return this.sharedService.getUserInfoData().pipe(
                      tap((ui: UserInfoClass) => {
                        if (ui) {
                          this.setUserInfoInLS(ui);
                        }

                        switch (loginResult.role) {
                          case 'parent': {
                            return this.router.navigate(['/parent']);
                          }
                        }

                        this.removeWaiting();
                      }),
                    );
                  }
                }),
              );
            }
          }),
          takeUntil(this.ngUnsubscribe$),
        )
        .subscribe();
    } else {
      this.failWaiting();
    }
  }

  setUserInfoInLS(ui: UserInfoClass) {
    if (!ui) {
      throw 'User info is not defined';
    }

    localStorage.setItem('imagePath', ui.imagePath);
    localStorage.setItem('firstName', ui.firstName);
    localStorage.setItem('lastName', ui.lastName);
    localStorage.setItem('schoolId', ui.schoolId);
    localStorage.setItem('userGender', ui.gender);
    localStorage.setItem('companyId', ui.companyId);
    localStorage.setItem('position', ui.position);
    localStorage.setItem('regionId', ui.regionId);
    localStorage.setItem('municipalityId', ui.municipalityId);
    localStorage.setItem('city', ui.city);
    ui.parents[0]?.userId ? localStorage.setItem('parentUserId', ui.parents[0]?.userId) : null;
  }

  animateLogin() {
    this.buttonActivate = true;
    this.buttonWaiting = true;
    if (this.prosvetUserRegistration) {
      this.registerProsvet();
    } else {
      this.registerBase();
    }
  }

  get isAccessAllowed() {
    if (this.prosvetUserRegistration) {
      return this.form.valid && this.personalTerms;
    } else {
      return this.form.valid && this.isValidPassword(this.f.password.value);
    }
  }

  getSchoolClassBySchool(id): Observable<any> {
    return this.registrationService.getSchoolClassesBySchool(id).pipe(
      tap((classes: SchoolClassesClass[]) => {
        this.classes = classes;
        this.classes && this.classes.length ? (this.isClasses = true) : null;
        this.classesFetched = true;
      }),
    );
  }

  get f() {
    return this.form.controls;
  }

  private setDefaultErrorFlagValues(): void {
    this.isWhiteSpaceError = false;
    this.isCharactersError = true;
    this.isLetterError = true;
    this.isNumberError = true;
    this.isRusLettersError = false;
  }
  private updateErrorFlagValues(password: string): void {
    this.isWhiteSpaceError = !this.testWhiteSpace.test(password);
    this.isCharactersError = !this.testSixCharter.test(password);
    this.isLetterError = !this.testOneLetter.test(password);
    this.isNumberError = !this.testOneDigit.test(password);
    this.isRusLettersError = this.testRusLetters.test(password);
  }
  private isAnyPasswordError(): boolean {
    return this.isCharactersError || this.isLetterError || this.isNumberError || this.isRusLettersError || this.isWhiteSpaceError;
  }

  public toggleMask() {
    this.isMaskedPassword = !this.isMaskedPassword;
  }

  public getDocsRoute(): string {
    return AppSettingsService.settings.docsPath.docsPathDefault;
  }
  public removeWaiting() {
    this.buttonWaiting = false;
    this.buttonActivated = true;
  }
  public failWaiting() {
    this.buttonWaiting = false;
    this.buttonActivate = false;
  }
  public isValidPassword(password: string): boolean {
    if (password && password.length > 0) {
      this.updateErrorFlagValues(password);
      if (this.isAnyPasswordError()) {
        this.isNotValidPassword = true; // isWhiteSpaceError был без этой записи
        return false;
      } else {
        this.isNotValidPassword = false;
        return true;
      }
    } else {
      this.setDefaultErrorFlagValues();
      return false;
    }
  }
  public focusOutErrorChecking() {
    this.focusOutPasswordErrors = this.isAnyPasswordError();
  }

  public checkFormatEmail(event): void {
    if (event) {
      this.checkEmail = this.emailRegExp.test(this.f.email.value);
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
