import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from 'app/core/services/http.service';
import { SchoolClassesClass } from 'app/shared/classes/school-classes.class';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IAddUserInterface } from '../../../shared/interfaces/iregistration.interface';

@Injectable()
export class RegistrationProsvetService {
  constructor(private http: HttpService) {}

  // обработка ошибок
  handleError(err: any): Observable<any> {
    if (err instanceof TimeoutError) {
      console.error(`Frontend returned timeout error: ${err['error']}`);
      return throwError(err['error']);
    }
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
      let errorText = err.error ? (err.error.comment ? err.error.comment : err.error) : null;
    }
    throw throwError(err);
  }

  registration(login: string, pass: string, advertisingTerms?: boolean): Observable<any> {
    let credentials = {
      email: login,
      password: pass,
      isConsentToMailing: advertisingTerms,
    };
    return this.http.post('/b2c/v1.0/saas/register', credentials).pipe(
      map(response => {
        const ALREADY_EXIST = 'already exists';
        let include = response.status.includes(ALREADY_EXIST);
        if (include) {
          return 'ALREADY_EXIST';
        } else {
          return response;
        }
      }),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  public registrationProsvetUser(userData) {
    let credentials = {
      userData: userData,
    };
    return this.http.post('/b2c/v1.0/prosveshcheniye/register', credentials).pipe(map(r => r));
  }

  public openregistration(credentials: any) {
    return this.http.post('/b2c/v1.0/saas/registeropen', credentials).pipe(map(r => r));
  }

  public user(user: IAddUserInterface) {
    return this.http.post('/b2c/v1.0/saas/adduser', user).pipe(map(r => r));
  }

  getSchoolClassesBySchool(id): Observable<any> {
    return this.http.get('/b2c/v1.0/saas/getschoolclassesbyschool?schoolId=' + id).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  public login(login: string, pass: string, rememberMe?: boolean) {
    let credentials = {
      userName: login,
      password: pass,
      rememberMe: rememberMe === true,
    };
    return this.http.post('/b2c/v1.0/saas/login', credentials).pipe(map(r => r));
  }

  loginProsvetUser(id, email) {
    let credentials = {
      userId: id,
      email: email,
    };
    return this.http.post('/b2c/v1.0/prosveshcheniye/login', credentials).pipe(map(r => r));
  }

  public softSchoolClassChange(userId: string, schoolId: string, classNumber: number, classLetter: string): Observable<any> {
    let credentials = {
      UserId: userId,
      SchoolId: schoolId,
      ClassNumber: classNumber,
      ClassLetter: classLetter,
    };
    return this.http.post('/b2c/v1.0/pupils/softschoolclasschange', credentials).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }
}
