import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, throwError } from 'rxjs';
import * as moment from 'moment';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { catchError, takeUntil } from 'rxjs/operators';
import { ISpeciality } from 'app/shared/interfaces/ispeciality.interface';
import { SpecialtiesService } from '../../specialties.service';

@Component({
  selector: 'prf-specialty-card',
  templateUrl: './specialty-card.component.html',
  styleUrls: ['./specialty-card.component.scss'],
})
export class SpecialtyCardComponent implements OnInit {
  @Input() specialty: ISpeciality;

  userId: string;
  userRole: string;
  popUpConfirming: boolean = false;
  moment: any;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(private specialtiesService: SpecialtiesService, private utilsService: UtilsService, private router: Router) {
    this.moment = moment;
    this.moment.locale('ru');
  }

  ngOnInit() {
    this.userId = localStorage.getItem('userId');
    this.userRole = localStorage.getItem('userRole');
  }

  deleteSpecialty(id) {
    this.specialtiesService
      .deleteSpecialty(id)
      .pipe(
        catchError(err => {
          this.popUpConfirming = !this.popUpConfirming;
          this.utilsService.openSnackBar('👎 Ошибка на сервере, попробуйте позже', 'error');
          return throwError(err);
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(delResponse => {
        if (delResponse.status == 'Success') {
          this.utilsService.openSnackBar(`👌 Специальность удалена`, 'success');
        }
        this.popUpConfirming = !this.popUpConfirming;
        return setTimeout(_ => {
          this.router.navigate(['/admin/specialties']);
        }, 1000);
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
