import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'prf-filters-internships',
  templateUrl: './filters-internships.component.html',
  styleUrls: ['./filters-internships.component.scss'],
})
export class FiltersInternshipsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
