<form [formGroup]="form" novalidate>
  <h1 class="title">{{ 'PLAYER.ANKETA.TELL_ABOUT_CHILD.HEADER' | translate }}</h1>
  <p class="descr">{{ 'PLAYER.ANKETA.TELL_ABOUT_CHILD.DESCRIPTION' | translate }}</p>

  <div class="content-slide content-slide--s0">
    <div class="content-slide-col">
      <label>{{ 'PLAYER.ANKETA.TELL_ABOUT_CHILD.NAME' | translate }}</label>
      <input type="text" name="userName" formControlName="childName" class="input__slide0" />
    </div>
    <div class="content-slide-col content-slide-col-2">
      <label>{{ 'PLAYER.ANKETA.TELL_ABOUT_CHILD.GENDER' | translate }}</label>
      <label class="switch">
        <input class="switch-input" type="checkbox" (change)="changeGender()" formControlName="childIsFemale" />
        <span class="switch-label"></span>
        <span *ngIf="isMaleGender" class="switch-handle">{{ 'BUTTONS.GENDER.M' | translate }}</span>
        <span *ngIf="!isMaleGender" class="switch-handle">{{ 'BUTTONS.GENDER.F' | translate }}</span>
      </label>
    </div>
    <div class="content-slide-col">
      <label>{{ 'PLAYER.ANKETA.TELL_ABOUT_CHILD.AGE' | translate }}</label>
      <input type="hidden" formControlName="childAge" name="food" class="tell-select" [(ngModel)]="childAge" />
      <!--<ng2-dropdown (onItemClicked)="onAgeChanged($event)">-->
      <!--<ng2-dropdown-button>-->
      <!--{{childAge}}-->
      <!--</ng2-dropdown-button>-->
      <!--<ng2-dropdown-menu [appendToBody]="false">-->
      <!--<ng2-menu-item *ngFor="let age of ageRange" [value]="age">-->
      <!--{{ age }}-->
      <!--</ng2-menu-item>-->
      <!--</ng2-dropdown-menu>-->
      <!--</ng2-dropdown>-->
    </div>
  </div>
</form>
