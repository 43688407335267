<div class="tab-pane-professions">
  <div class="tab-container">
    <div>
      <h2 class="professions-heading">{{ profession.name }}</h2>
      <prf-steps-trajectory [profession]="profession" [showMoreAbout]="true"></prf-steps-trajectory>
    </div>

    <h2>{{ 'SHARED.MY_TASKS' | translate }}</h2>
    <prf-pupil-tasks [profession]="profession"></prf-pupil-tasks>

    <!--Времменно убрал-->
    <div *ngIf="false && profession.facts && profession.facts[0]" class="quote-block">
      <div>
        <h4>Кстати, ты знаешь что..</h4>
        <p>{{ profession.facts[0] }}</p>
      </div>
    </div>
  </div>
</div>
