import { IHttpStatus } from '../http';

export interface IActiveLesson {
  lessonIndex: number;
  chapterIndex: number;
  lesson: ILessonMaterial;
}

export interface IPassedLessons {
  id: string;
  orderNumber: number;
  isPassed: boolean;
}

export interface ILessons {
  minNumber: number;
  maxNumber: number;
  lessons: ILessonMaterial[];
}

export interface IGetAllCourseMaterialsResponse extends IHttpStatus {
  courseMaterials: ICourseMaterial[];
}

export interface IGetOneCourseMaterialResponse extends IHttpStatus {
  courseMaterial: ICourseMaterial | ICourseMaterialExt;
}

export interface IDeleteCourseMaterialResponse extends IHttpStatus {}

export interface ICreateCourseMaterialRequest {
  courseMaterial: ICourseMaterial;
}

export interface ICreateCourseMaterialResponse extends IGetOneCourseMaterialResponse {}

export interface IUpdateCourseMaterialRequest extends ICreateCourseMaterialRequest {}

export interface IUpdateCourseMaterialResponse extends IHttpStatus {}

export interface ICreateLessonRequest {
  courseMaterialId: string;
  lessonMaterial: ILessonMaterial;
}

export interface ICreateLessonResponse extends IGetOneCourseMaterialResponse {}

export interface IUpdateLessonRequest extends ICreateLessonRequest {}

export interface IUpdateLessonResponse extends IHttpStatus {}

export interface IDeleteLessonRequest {
  courseMaterialId: string;
  lessonMaterialId: string;
}

export interface IDeleteLessonResponse extends IHttpStatus {}

export interface ICopyCourseMaterialWithLessonsRequest {
  BaseCourseMaterialId?: string;
  Name?: string;
  CorrelationId?: string;
  CorrelationUserId?: string;
}

export interface ICopyCourseMaterialWithLessonsResponse extends IHttpStatus {}

export interface ICourseMaterial {
  id: string;
  name: string;
  description: string;
  lessonsMaterials: ILessonMaterial[];
}

export interface ICourseMaterialExt {
  name: string;
  description: string;
  lessonsMaterials?: ILessonMaterial[];
  passedLessons?: IPassedLessons[];
  parsedLessons?: [string, ILessons][];
  id?: string;
  activeLesson?: IActiveLesson;
  nextLessonDate?: string;
  files?: IUsefulFile[];
}

export interface IUsefulFile {
  name: string;
  type: string;
}

export interface ILessonMaterial {
  id?: string;
  name: string;
  description: string;
  blockName: string;
  number: number;
  conspectUrl?: string;
  presentationUrl?: string;
  printMaterialsUrl?: string;
  yandexDiskMaterialsUrl?: string;
  hasLinkedTaskForPupil?: boolean;
  recommendedDate?: string;
  passed?: boolean;
}

export interface ILesson {
  hrid: string;
  name: string;
  courseMaterialId: string;
  lessonMaterialId: string;
  tasks?: any;
  id?: string;
  orderNumber?: 0;
  text?: string;
  imageUrl?: string;
  videoUrl?: string;
  isActive?: boolean;
  variantId?: string;
}

export interface IGetCourseByClassResponse {
  status: string;
  comment: string;
  senderService: string;
  courseIds: string[];
}

export interface ILessonActivity {
  userId?: string;
  courseId: string;
  lessonId: string;
  schoolClassId: string;
  isPassed: boolean;
  id?: string;
  taskId?: string;
  createDate?: string;
}

export interface ProfessionsTasksActivities {
  status: string;
  comment: string;
  senderService: string;
  chooseProfessionsFromFieldTaskActivity: {
    courseId: string;
    lessonId: string;
    taskId: string;
    userId: string;
    processedProfessions: {
      additionalProp1: boolean;
      additionalProp2: boolean;
      additionalProp3: boolean;
    };
  };
}

export interface IAssigningCourseModel {
  courseActivity: {
    schoolClassId: string;
    courseId: string;
    userId?: string;
    isFinish?: boolean;
  };
}
