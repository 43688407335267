<div class="slider profession__people-list">
  <button *ngIf="canScrollLeft()" type="button" class="prev" (click)="scrollLeft()" title="{{ getBackLabel() | translate }}">&nbsp;</button>
  <ul>
    <li class="profession__people-item" *ngFor="let person of frame">
      <div (click)="openDialog(person)">
        <div class="profession__people-pic">
          <img src="{{ prepareImgLink(person.imagePath) | lowercase }}" width="134" height="134" />
        </div>
        <div class="profession__people-name">{{ person.name }}</div>
        <div class="profession__people-text">{{ person.description }}</div>
      </div>
    </li>
  </ul>
  <button *ngIf="canScrollRight()" type="button" (click)="scrollRight()" class="next" title="{{ getForwardLabel() | translate }}">
    &nbsp;
  </button>
</div>

<div class="profession__people-list__overlay" [ngClass]="{ overlay_on: overlayOn }" (click)="closeDialog()">
  <div class="dialog-close" *ngIf="overlayOn" (click)="closeDialog()"></div>
  <div *ngIf="dialogItem" class="profession__people-list__overlay__content">
    <div class="profession__people-list__overlay-col1">
      <img src="{{ prepareImgLink(dialogItem.imagePath) | lowercase }}" class="profession profession_avatar" />
    </div>
    <div class="profession__people-list__overlay-col2">
      <div class="profession__people-name overlayed">{{ dialogItem.name }}</div>
      <div class="profession__people-text scrollable">
        <div class="profession__scroller">
          {{ getFullDescription(dialogItem.name) }}
        </div>
      </div>
    </div>
  </div>
</div>
