import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { of, Subject } from 'rxjs';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ISeamlessUserAccessData } from 'app/shared/interfaces/iseamlessuseraccessdata.interface';
import { SeamlessentryService } from './seamlessentry.service';
import { SharedService } from 'app/shared/shared.service';
import { UserInfoClass } from 'app/shared/classes/userInfo.class';
import { EUserTags } from 'app/shared/enums/user-types.enum';

@Component({
  selector: 'prf-seamlessentry',
  templateUrl: './seamlessentry.component.html',
  styleUrls: ['./seamlessentry.component.scss'],
})
export class SeamlessentryComponent implements OnInit {
  userRegistrationData: ISeamlessUserAccessData;

  public duplicateUserName: boolean = false;

  private ngUnsubscribe$: Subject<any> = new Subject();
  constructor(
    private activatedRoute: ActivatedRoute,
    private seamlessentryService: SeamlessentryService,
    private sharedService: SharedService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(params => {
      // todo здесь будет считывание данных из токена
      // var decoded = jwt_decode(token);
      if (params && params.externalApp) {
        switch (params.externalApp) {
          case 'mosru':
            this.userRegistrationData = {
              firstName: 'testFirstName',
              lastName: 'testLastName',
              middleName: 'testMiddleName',
              city: 'Москва',
              role: 'parent',
              phoneNumber: '70000000000',
              email: 'userId1@mos.ru',
              password: 'MosRu_userId1',
              tag: EUserTags.MosRu,
              ExternalAppUserId: params.userId || null,
              isConsentToMailing: true,
            };
            this.seamlessentryService
              .openregistration(this.userRegistrationData)
              .pipe(
                switchMap(response => {
                  if (response) {
                    if (response.userId || (!response.userId && response.status === 'User with current email already exists')) {
                      //todo изменить обработчик
                      console.error('Registration failed');
                      this.duplicateUserName = response.status = !!'Registration failed'; // почта занята
                      this.duplicateUserName ? console.error('User name is duplicated') : null;
                      // Сразу попадаем в ЛК
                      //todo вjзможно, нужно будет заменить на SP Login
                      return this.seamlessentryService.login(response.userName, this.userRegistrationData.password).pipe(
                        take(1),
                        switchMap((loginResult: any) => {
                          if (!loginResult || loginResult.succeeded === false) {
                            //todo изменить
                            console.error('Login failed');
                            return of(null);
                          } else {
                            localStorage.setItem('userRole', loginResult.role);
                            localStorage.setItem('userId', loginResult.userId);
                            localStorage.setItem('tag', loginResult.tag);
                            localStorage.setItem('isAuthorized', 'true');

                            return this.sharedService.getUserInfoData().pipe(
                              tap((ui: UserInfoClass) => {
                                if (ui) {
                                  this.setUserInfoInLS(ui);
                                }

                                // Сразу попадаем в ЛК
                                switch (loginResult.role) {
                                  case 'parent': {
                                    return this.router.navigate(['/parent']);
                                  }
                                }
                              }),
                            );
                          }
                        }),
                      );
                    } else {
                      //todo изменить обработчик
                      console.error('Registration failed');
                      this.duplicateUserName = response.status = !!'Registration failed'; // почта занята
                      this.duplicateUserName ? console.error('User name is duplicated') : null;

                      return of(null);
                    }
                  } else {
                    return of(null);
                  }
                }),
                takeUntil(this.ngUnsubscribe$),
              )
              .subscribe();
            break;
        }
      }
    });
  }

  setUserInfoInLS(ui: UserInfoClass) {
    if (!ui) {
      throw 'User info is not defined';
    }

    localStorage.setItem('imagePath', ui.imagePath);
    localStorage.setItem('firstName', ui.firstName);
    localStorage.setItem('lastName', ui.lastName);
    localStorage.setItem('schoolId', ui.schoolId);
    localStorage.setItem('userGender', ui.gender);
    localStorage.setItem('companyId', ui.companyId);
    localStorage.setItem('position', ui.position);
    localStorage.setItem('regionId', ui.regionId);
    localStorage.setItem('municipalityId', ui.municipalityId);
    ui.parents[0]?.userId ? localStorage.setItem('parentUserId', ui.parents[0]?.userId) : null;
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
