import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { SharedService } from 'app/shared/shared.service';
import { ISwitcherItem } from 'app/shared/dashboard/page-switcher/page-switcher.component';
import { FavoritesTypes } from 'app/shared/enums/favorites.enums';
import { FavoritesService } from './favorites.service';
import { ClassesFormatTypes } from 'app/shared/enums/courses-types.enum';

@Component({
  selector: 'prf-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FavoritesComponent implements OnInit, OnDestroy {
  public loaded: boolean = false;
  public userId: string = '';
  public userFavorites: any = [];
  public coursesFavorites: any[] = [];
  public favoriteEvents: any[] = [];
  public professionsFavorites: any[] = [];

  public coursesFormats = [ClassesFormatTypes[ClassesFormatTypes.ShortCourse], ClassesFormatTypes[ClassesFormatTypes.LongCourse]];

  public favoritesMenu: ISwitcherItem[] = [
    { name: 'Профессии', value: 'professions' },
    // {name: 'Курсы', value: 'courses'},
    // {name: 'Мероприятия', value: 'events'},
  ];
  public selectedTab: string = this.favoritesMenu[0].value;

  isLoading: boolean = false;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(private meta: Meta, private favoritesService: FavoritesService, private sharedService: SharedService) {
    this.meta.updateTag({ name: 'og:title', content: 'Избранное' });
    this.userId = localStorage.getItem('userId');
  }

  ngOnInit() {
    this.getFavoritesAll()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(r => (this.loaded = true));
  }

  public getFavoritesAll(): Observable<any> {
    if (!this.isLoading) {
      this.isLoading = true;
      return this.favoritesService.getUserFavorites(this.userId).pipe(
        takeUntil(this.ngUnsubscribe$),
        switchMap(userFavorites => {
          let favouriteCourses = userFavorites.filter(el => el.productType === FavoritesTypes.Class);
          this.favoritesService.setFavoriteCourses(favouriteCourses);

          let favoriteProfessions = userFavorites.filter(el => el.productType === FavoritesTypes.Profession);
          this.favoritesService.setFavoriteProfessions(favoriteProfessions);

          let coursesIds = userFavorites.filter(el => el.productType === FavoritesTypes.Class).map(el => el.productId) || [];
          let professionsIds = userFavorites.filter(el => el.productType === FavoritesTypes.Profession).map(el => el.productId) || [];

          this.coursesFavorites = [];
          let coursesFavorites$: Observable<any> = of([]);
          if (coursesIds.length) {
            coursesFavorites$ = this.favoritesService.getUserFavoritesCourses(coursesIds);
          }
          return coursesFavorites$.pipe(
            switchMap(courses => {
              this.coursesFavorites = courses.filter(course => this.coursesFormats.includes(course.classesFormat));
              this.favoriteEvents = courses.filter(course => !this.coursesFormats.includes(course.classesFormat));
              let professionsFavorites$: Observable<any> = of([]);
              if (professionsIds.length) {
                professionsFavorites$ = this.favoritesService.getUserFavoritesProfessions(professionsIds);
              }
              return professionsFavorites$.pipe(
                tap(professions => {
                  this.professionsFavorites = professions;
                  this.isLoading = false;
                }),
              );
            }),
          );
        }),
      );
    }
  }

  selectTab(event) {
    this.selectedTab = event?.value;
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
