<prf-add-city [regions]="regions" (cityAdded)="cityAdded($event)">{{ 'SHARED.ADD_CITY' | translate }}</prf-add-city>
<div>
  <h2>Список городов</h2>
  <table *ngIf="cities">
    <tr>
      <td>
        <b>Всего городов: {{ cities.length }}</b>
      </td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td><b>Название</b></td>
      <td>Hrid</td>
      <td>{{ 'SHARED.REGION' | translate }}</td>
      <td>Муниципалитет</td>
      <td></td>
    </tr>
    <tr *ngFor="let city of cities">
      <td>{{ city.name }}</td>
      <td>{{ city.hrid }}</td>
      <td>{{ city.region }}</td>
      <td>{{ city.municipality }}</td>
      <td>
        <prf-edit-city [currentCity]="city" [regions]="regions" (cityUpdated)="cityUpdated($event)"></prf-edit-city>
        <prf-delete-city [cityId]="city.id" (cityDeleted)="cityDeleted($event)"></prf-delete-city>
      </td>
    </tr>
  </table>
</div>
