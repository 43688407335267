<div *ngIf="loaded" class="news-block">
  <div class="news-main-column">
    <div *ngFor="let news of newsListByCol[0]" class="news-column">
      <div class="news-card">
        <a routerLink="/news/{{ news.id }}" class="news-card-image-link w-inline-block"></a>
        <div class="news-info-div">
          <h3 class="news-card-heading">
            <a routerLink="/news/{{ news.id }}" class="news-card-heading-link">
              {{ news.title }}
            </a>
          </h3>
          <p class="news-card-text">{{ news.description }}</p>
          <div class="news-card-row">
            <div class="news-card-date">27 февраля в 10:31</div>
            <div class="news-card-author">{{ 'SHARED.DANCE_SCHOOL_TODES' | translate }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="news-main-column">
    <div *ngFor="let news of newsListByCol[1]" class="news-column">
      <div class="news-card">
        <a routerLink="/news/{{ news.id }}" class="news-card-image-link w-inline-block"></a>
        <div class="news-info-div">
          <h3 class="news-card-heading">
            <a routerLink="/news/{{ news.id }}" class="news-card-heading-link">
              {{ news.title }}
            </a>
          </h3>
          <p class="news-card-text">{{ news.description }}</p>
          <div class="news-card-row">
            <div class="news-card-date">27 февраля в 10:31</div>
            <div class="news-card-author">{{ 'SHARED.DANCE_SCHOOL_TODES' | translate }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
