import { Component, Input } from '@angular/core';

@Component({
  selector: 'prf-parent-pupil-profile',
  templateUrl: './parent-pupil-profile.component.html',
  styleUrls: ['./parent-pupil-profile.component.scss'],
})
export class ParentPupilProfileComponent {
  @Input() child: any = {};

  public showInfoPopup: boolean = false;
}
