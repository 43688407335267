import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DefaultAvatarService {
  public avatarUrl(role: string, gender: string): string {
    if (role && gender) {
      return '/profilum-assets/images/avatars/' + role + '-' + gender + '.png';
    } else {
      return '/profilum-assets/images/avatars/default.png';
    }
  }
}
