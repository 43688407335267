import { inject, Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { Observable } from 'rxjs';
import {
  IGetAllScreeningTestsResponse,
  IGetInformationAboutScreeningTestResponse,
  IGetTestsByScreenTestIdAndVariantIdResponse,
  IGetTestSlidesCountResponse,
} from './api-tests.interface';
import { ApiTestsUrls } from './api-tests.collections';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiTestsService {
  private readonly httpService: HttpService = inject(HttpService);

  public getTestSlidesCount(screenTestingId: string): Observable<IGetTestSlidesCountResponse> {
    return this.httpService.get<IGetTestSlidesCountResponse>(ApiTestsUrls.GetTestSlidesCount, { screenTestingId }).pipe(take(1));
  }

  public getTestSlidesCountBySessionId(sessionId: string): Observable<number> {
    return this.httpService.get<number>(ApiTestsUrls.GetTestSlidesCountBySessionId, { sessionId }).pipe(take(1));
  }

  public getTestsByScreenTestIdAndVariantId(
    screeningTestId: string,
    variantId: string,
  ): Observable<IGetTestsByScreenTestIdAndVariantIdResponse> {
    return this.httpService
      .get<IGetTestsByScreenTestIdAndVariantIdResponse>(ApiTestsUrls.GetTestsByScreenTestIdAndVariantId, { screeningTestId, variantId })
      .pipe(take(1));
  }

  public getAllScreeningTests(): Observable<IGetAllScreeningTestsResponse[]> {
    return this.httpService.get<IGetAllScreeningTestsResponse[]>(ApiTestsUrls.GetAllScreeningTests).pipe(take(1));
  }

  public getInformationAboutScreeningTest(screeningTestId: string): Observable<IGetInformationAboutScreeningTestResponse> {
    return this.httpService
      .get<IGetInformationAboutScreeningTestResponse>(ApiTestsUrls.GetInformationAboutScreeningTest, { screeningTestId })
      .pipe(take(1));
  }
}
