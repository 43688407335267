<div class="filter-div cat" #groupFilter>
  <div class="professions-dropdown w-dropdown" [style.z-index]="groupOpen ? 1001 : 0">
    <div
      class="professions-dropdown-toggle w-dropdown-toggle"
      (click)="groupOpen = !groupOpen"
      [class.active]="filters.courses && filters.courses.length > 0"
    >
      <div class="professions-dd-icon-2 w-icon-dropdown-toggle"></div>
      <div>{{ checkFilterHeader() }}</div>
    </div>
    <nav class="dd-prof-filter-list w-dropdown-list" [class.w--open]="groupOpen">
      <div class="dd-prof-filter-div _2">
        <div class="filter-list-row">
          <ng-container *ngIf="selectedCourses.length > 0; else notSelected">
            <div class="filter-list-heading">{{ selectedCourses.length }} {{ 'SHARED.SELECTED' | translate }}</div>
            <a class="clear-categories show" (click)="deselectCourses()">{{ 'SHARED.CLEAR_BUTTON' | translate }}</a>
          </ng-container>
        </div>

        <div class="form-block w-form">
          <form id="email-form" name="email-form" data-name="Email Form">
            <div class="search-block">
              <input
                type="text"
                class="search-categories w-input"
                maxlength="256"
                placeholder="{{ 'SHARED.SEARCH' | translate }}"
                name="Search-2"
                id="Search-2"
                autocomplete="off"
                [(ngModel)]="searchCourse"
              />
              <a class="search-clear w-inline-block" (click)="searchCourse = ''" [class.show]="searchCourse && searchCourse.length > 0"></a>
            </div>
          </form>
        </div>

        <div class="scroll-block">
          <div class="prof-categories-links">
            <ng-container *ngIf="searchCourse.length > 1; then searchGroup; else defaultFilter"></ng-container>
          </div>
        </div>
      </div>
    </nav>
  </div>
</div>

<ng-template #notSelected>
  <div class="filter-list-heading">{{ 'SHARED.ALL_DIRECTIONS' | translate }}</div>
  <a class="clear-categories">{{ 'SHARED.CLEAR_BUTTON' | translate }}</a>
</ng-template>

<ng-template #searchGroup>
  <ng-container *ngFor="let group of groups | filterCourses : searchCourse">
    <div class="event-category-link-dd w-dropdown">
      <div class="event-categories-link dd-link w-dropdown-toggle" [class.dark]="selectedGroup.indexOf(group.name) !== -1" #toggle>
        <div class="event-category-dd-icon w-icon-dropdown-toggle" (click)="closeOther(coursesDrop, icon)" #icon></div>
        <div class="category-text-link" (click)="setGroupFilter(group)">{{ group.name }}</div>
      </div>
      <nav class="event-dd-second w-dropdown-list" #coursesDrop>
        <a
          class="event-categories-link w-inline-block"
          *ngFor="let course of group.courses"
          [class.disabled-filter]="course.item.count == 0 || course.item.count == undefined"
          [class.w--current]="selectedCourses.indexOf(course.item.name) !== -1"
          (click)="setCourseFilter(course)"
        >
          <div class="category-text-link">{{ course.item.name }}</div>
          <div>{{ course.item.count }}</div>
        </a>
      </nav>
    </div>
  </ng-container>

  <ng-container *ngFor="let group of groups">
    <a
      class="event-categories-link w-inline-block"
      *ngFor="let course of group.courses | filterCourses : searchCourse"
      [class.disabled-filter]="course.item.count == 0 || course.item.count == undefined"
      [class.w--current]="selectedCourses.indexOf(course.item.name) !== -1"
      (click)="setCourseFilter(course)"
    >
      <div class="category-text-link">{{ course.item.name }}</div>
      <div>{{ course.item.count }}</div>
    </a>
  </ng-container>
</ng-template>

<ng-template #defaultFilter>
  <ng-container *ngFor="let group of groups">
    <div class="event-category-link-dd w-dropdown">
      <div class="event-categories-link dd-link w-dropdown-toggle" [class.dark]="selectedGroup.indexOf(group.name) !== -1" #toggle>
        <div class="event-category-dd-icon w-icon-dropdown-toggle" (click)="closeOther(coursesDrop, icon)" #icon></div>
        <div class="category-text-link" (click)="setGroupFilter(group)">{{ group.name }}</div>
      </div>
      <nav class="event-dd-second w-dropdown-list" #coursesDrop>
        <a
          class="event-categories-link w-inline-block"
          *ngFor="let course of group.courses"
          [class.disabled-filter]="course.item.count == 0 || course.item.count == undefined"
          [class.w--current]="selectedCourses.indexOf(course.item.name) !== -1"
          (click)="setCourseFilter(course)"
        >
          <div class="category-text-link">{{ course.item.name }}</div>
          <div>{{ course.item.count }}</div>
        </a>
      </nav>
    </div>
  </ng-container>
</ng-template>
