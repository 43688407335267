import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'prf-select-test-type',
  templateUrl: './select-test-type.component.html',
  styleUrls: ['./select-test-type.component.scss'],
})
export class SelectTestTypeComponent {
  @Input() form: UntypedFormGroup;
}
