import { InternshipFormat } from '../enums/internship-format.enum';

export class Internship {
  id: string;
  createdDate: string;
  updatedDate: string;
  hrid: string;
  name: string;
  mainImagePath: string;
  departamentName: string;
  companyId: string;
  startDate: any;
  closeDate: any;
  deadline: any;
  candidates: string;
  duties: string;
  description: string;
  specialities: string[];
  courses: string[];
  talents: string[];
  totalPlaces: number;
  format: InternshipFormat;
  address: string;
  link: string;
  signUpList: string;
  blockerExercises: string[];
  isActive: boolean;
}
