import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'prf-internship-content-loader',
  templateUrl: './internship-content-loader.component.html',
  styleUrls: ['./internship-content-loader.component.scss'],
})
export class InternshipContentLoaderComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
