import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'prf-page-header-modern',
  templateUrl: './page-header-modern.component.html',
  styleUrls: ['./page-header-modern.component.scss'],
})
export class PageHeaderModernComponent implements OnInit {
  private window;

  constructor(private router: Router, private translator: TranslateService, private location: Location) {}

  ngOnInit() {}

  openPage() {
    const url = document.location.origin;
    if (this.window) {
      let path = this.router.routerState.snapshot.url;
      if (path.indexOf('courses') >= 0) {
        this.window.open(url);
      }
    }
  }

  public getHeaderClass() {
    return 'header__logo ' + this.translator.currentLang;
  }

  public isEn = () => this.translator.currentLang == 'en';

  public getLink(url) {
    return document.location.origin + this.location.prepareExternalUrl(url);
  }
}
