<ng-template [ngIf]="sectionInfo">
  <div class="prf-rectangle-box">
    <prf-rectangle-view
      *ngFor="let answer of sectionInfo.elements; trackBy: trackByFn"
      [answerInfo]="answer"
      [sectionInfo]="sectionInfo"
      (selectedAnswer)="updateSelectedAnswer($event)"
      [updateSelections]="updateSelectionsSubject"
      class="prf-rectangle-box-item"
    ></prf-rectangle-view>
  </div>
</ng-template>
