import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  CoursesFiltersService,
} from 'app/shared/dashboard/courses/courses-catalog/courses-filter/courses-filters.service';
import { IFilterClasses } from 'app/shared/interfaces/ifilterclasses.interface';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, mergeMap, takeUntil } from 'rxjs/operators';

// минимальная длина строки поиска для осуществления запроса
export const MinSearchLength = 3;

//поиск в каталоге админов
@Component({
  selector: 'prf-search-courses',
  templateUrl: './search-courses.component.html',
  styleUrls: ['./search-courses.component.scss'],
})
export class SearchCoursesComponent implements OnInit {
  public searchField: UntypedFormControl;
  private filters: IFilterClasses;

  private ngUnsubscribe$: Subject<any> = new Subject();
  constructor(private filtersService: CoursesFiltersService) {}

  ngOnInit() {
    this.searchField = new UntypedFormControl();
    this.filtersService
      .getCoursesFilters()
      .pipe(
        mergeMap(filters => {
          this.filters = filters;

          return this.searchField.valueChanges.pipe(
            debounceTime(400),
            distinctUntilChanged(),
            map(term => {
              if (term && term.length != 0 && term.length >= MinSearchLength) {
                this.filters.query = term;
                this.filtersService.setCoursesFilter(this.filters);
              } else if (!term || (term && term.length === 0)) {
                this.filters.query = term;
                this.filtersService.setCoursesFilter(this.filters);
              }
            }),
          );
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }

  toClearSearch() {
    this.searchField.setValue(null);
    this.filters.query = null;
    this.filtersService.setCoursesFilter(this.filters);
  }
}
