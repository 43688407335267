<section class="login-section">
  <div class="login-container">
    <a routerLink="/" class="login-logo w-inline-block">
      <img src="/assets/images/kz-landing/kz-logo.svg" alt="EDUNAVIGATOR" />
    </a>

    <article class="tabs login w-tabs">
      <div class="tabs-content w-tab-content">
        <div class="w-tab-pane w--tab-active">
          <div class="tab-pane login">
            <header>
              <h1>{{ 'PASSWORD.RESTORE' | translate }}</h1>
            </header>

            <div class="user-data-form-block w-form">
              <form [formGroup]="form" autocomplete="false">
                <section class="login-form-row" *ngIf="firstStep">
                  <label for="email" class="login-label">{{ 'PASSWORD.PLEACE_INDICATE_YOUR_EMAIL' | translate }}</label>
                  <div class="login-field-column">
                    <input
                      type="email"
                      autocomplete="false"
                      class="login-field w-input"
                      maxlength="70"
                      name="email"
                      data-name="email"
                      id="email"
                      formControlName="email"
                    />
                    <mat-error *ngIf="errorResetToken">
                      {{ 'PASSWORD.PLEACE_INDICATE_YOUR_EMAIL' | translate }}
                    </mat-error>
                  </div>
                </section>
                <section class="recovery-second" *ngIf="secondStep">
                  <p class="recovety-text">
                    {{ 'PASSWORD.USER_BY_EMAIL_NOT_FOUND' | translate : { email: form.value.email } }}
                  </p>
                  <small class="annotation">{{ 'PASSWORD.SEND_MSG_ON_EMAIL' | translate : { email: form.value.email } }}</small>
                </section>
                <div class="navigation">
                  <a *ngIf="!secondStep" class="nav-recovery-button back w-button recovery-active" (click)="back()">
                    {{ 'BUTTONS.PREVIOUS' | translate }}
                  </a>
                  <a
                    *ngIf="!secondStep"
                    class="nav-recovery-button next w-button"
                    [ngClass]="{ 'recovery-active': isAccessAllowed() }"
                    (click)="next()"
                  >
                    {{ 'BUTTONS.NEXT' | translate }}
                  </a>
                  <a
                    *ngIf="secondStep"
                    class="nav-recovery-button next w-button"
                    [ngClass]="{ 'recovery-active': isAccessAllowed() }"
                    [routerLink]="['/']"
                  >
                    {{ 'SHARED.ON_MAIN' | translate }}
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </article>
  </div>
</section>
