import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uppercaseFirst',
})
export class FirstUpperCasePipe implements PipeTransform {
  transform(text: string) {
    return text[0].toUpperCase() + text.substr(1, text.length - 1);
  }
}
