import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'prf-profession-content-loader',
  templateUrl: './profession-content-loader.component.html',
  styleUrls: ['./profession-content-loader.component.scss'],
})
export class ProfessionContentLoaderComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
