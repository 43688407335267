import { inject, Injectable } from '@angular/core';
import { HttpService } from '../http';
import { take } from 'rxjs/operators';
import { ApiEmailSenderCollectionsUrls } from './api-email-sender.collections';
import { Observable } from 'rxjs';
import {
  IPostSendMufEmailResponse,
  IPostSendMyFutureEmailResponse,
  IPostSendWelcomeEmailResponse,
} from './api-email-sender.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiEmailSenderService {
  private readonly httpService: HttpService = inject(HttpService);

  public sendMufEmail(sessionId: string, name: string): Observable<IPostSendMufEmailResponse> {
    return this.httpService
      .post<IPostSendMufEmailResponse>(ApiEmailSenderCollectionsUrls.PostSendMufEmail, { name, sessionId })
      .pipe(take(1));
  }

  public sendMyFutureEmail(sessionId: string, name: string): Observable<IPostSendMyFutureEmailResponse> {
    return this.httpService
      .post<IPostSendMyFutureEmailResponse>(ApiEmailSenderCollectionsUrls.PostSendMyFutureEmail, { name, sessionId })
      .pipe(take(1));
  }

  public sendWelcomeEmail(sessionId: string, name: string): Observable<IPostSendWelcomeEmailResponse> {
    return this.httpService
      .post<IPostSendWelcomeEmailResponse>(ApiEmailSenderCollectionsUrls.PostSendWelcomeEmail, { name, sessionId })
      .pipe(take(1));
  }
}
