import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DashboardProfessionInfo } from '../interfaces/analyticsFilters/analyticfilters.interface';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { MatIconRegistry } from '@angular/material/icon';

interface IProfession extends DashboardProfessionInfo {
  name: string;
}

@Component({
  selector: 'prf-top-list',
  templateUrl: './top-list.component.html',
  styleUrls: ['./top-list.component.scss'],
})
export class TopListComponent implements OnInit {
  demanded: { spo: IProfession[]; vpo: IProfession[] } = { spo: [], vpo: [] };
  @Input() set Denamed(value: { spo: DashboardProfessionInfo[]; vpo: DashboardProfessionInfo[] }) {
    this.prepareItems(value.spo)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(r => (this.demanded.spo = r));
    this.prepareItems(value.vpo)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(r => (this.demanded.vpo = r));
  }
  @Input() topListTooltip: string;
  popular: IProfession[] = [];
  @Input() set Popular(value: DashboardProfessionInfo) {
    this.prepareItems(value)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(r => (this.popular = r));
  }
  not_popular: IProfession[] = [];
  @Input() set NotPopular(value: DashboardProfessionInfo) {
    this.prepareItems(value)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(r => {
        this.not_popular = r;
        this.not_popular = this.not_popular.length < 15 ? [] : this.not_popular;
      });
  }

  @Input() noTitle: boolean = false;

  isSpo: boolean = false;

  private ngUnsubscribe$: Subject<any> = new Subject();

  prepareItems(object): Observable<any> {
    return this.getTranslations([
      'CHARTS.TOP_PROFESSIONS.SPECIALISTS.SENIOR',
      'CHARTS.TOP_PROFESSIONS.SPECIALISTS.MIDDLE',
      'CHARTS.TOP_PROFESSIONS.SPECIALISTS.JUNIOR',
    ]).pipe(
      map(translations => {
        return Object.keys(object).map(key => {
          return {
            name: key,
            count: object[key].count,
            minSalary: object[key].minSalary,
            averageSalary: object[key].averageSalary,
            maxSalary: object[key].maxSalary,
            tooltip: `
        <h4>${key}</h4>
          <span>${translations['CHARTS.TOP_PROFESSIONS.SPECIALISTS.SENIOR']}</span>
          <span><b>${Math.round(object[key].maxSalary)} р</b></span>
          <span>${translations['CHARTS.TOP_PROFESSIONS.SPECIALISTS.MIDDLE']}</span>
          <span><b>${Math.round(object[key].averageSalary)} р</b></span>
          <span>${translations['CHARTS.TOP_PROFESSIONS.SPECIALISTS.JUNIOR']}</span>
          <span><b>${Math.round(object[key].minSalary)} р</b></span>`,
          };
        });
      }),
    );
  }

  getStringFromHtml(template) {
    const html = template;
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  }

  constructor(matIconRegistry: MatIconRegistry, sanitizer: DomSanitizer, public translateService: TranslateService) {
    matIconRegistry.addSvgIcon('info', sanitizer.bypassSecurityTrustResourceUrl('/assets/images/icons/info.svg'));
  }

  getTranslations(keys: string[]): Observable<any> {
    return this.translateService.get(keys);
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
