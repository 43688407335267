import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';

import { IMunicipality } from 'app/shared/interfaces/imunicipality';
import { IRegion } from 'app/shared/interfaces/iregion';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IMunicipalityView } from '../../../../../shared/interfaces/imunicipalityview.interface';
import { MunicipalitiesService } from '../municipalities.service';

@Component({
  selector: 'prf-edit-municipality',
  templateUrl: './edit-municipality.component.html',
  styleUrls: ['./edit-municipality.component.scss'],
})
export class EditMunicipalityComponent implements OnInit {
  @Input() municipalityView: IMunicipalityView;
  @Input() regions: IRegion[];

  @Output() municipalityChanged = new EventEmitter<any>();

  public municipalityRegion: IRegion;

  public dialogOpen: boolean = false;
  public loaded: boolean = false;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private municipalitiesService: MunicipalitiesService, private utilsService: UtilsService) {}

  ngOnInit() {
    this.municipalityRegion = this.regions.find(r => r.id == this.municipalityView.regionId);
  }

  public showEditDialog(): void {
    this.dialogOpen = !this.dialogOpen;
    this.loaded = true;
  }

  onSubmit(valid: boolean) {
    if (valid && this.isValidMunicipality()) {
      const muncipalityData: IMunicipality = {
        id: this.municipalityView.id,
        hrid: this.municipalityView.hrid,
        name: this.municipalityView.name,
        regionId: this.municipalityRegion.id,
      };
      const selectedRegion = this.municipalityRegion;
      this.municipalitiesService
        .updateMunicipality(muncipalityData)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          response => {
            this.utilsService.openSnackBar('👌 Муниципалитет обновлен', 'success');
            return setTimeout(_ => {
              this.showEditDialog();
              this.municipalityChanged.emit({
                id: muncipalityData.id,
                hrid: muncipalityData.hrid,
                name: muncipalityData.name,
                regionId: selectedRegion.id,
                regionName: selectedRegion.name,
              });
            }, 300);
          },
          err => {
            return this.utilsService.openSnackBar('👎 Произошла ошибка, попробуйте позже', 'error');
          },
        );
    }
  }

  private isValidMunicipality(): boolean {
    return this.municipalityView && this.municipalityView.hrid && this.municipalityView.name && this.municipalityRegion ? true : false;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }
}
