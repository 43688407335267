import { Component, Input } from '@angular/core';

@Component({
  selector: 'prf-select-user-status',
  templateUrl: './select-user-status.component.html',
  styleUrls: ['./select-user-status.component.scss'],
})
export class SelectUserStatusComponent {
  @Input() form;
  @Input() isFemale;
}
