<div class="slider profession__info-tab--book-list" *ngIf="booksCountLimit > 0">
  <button *ngIf="canScrollLeft()" type="button" class="prev" (click)="scrollLeft()" title="{{ getBackLabel() | translate }}">&nbsp;</button>
  <ul>
    <li class="profession__info-tab--book-item" *ngFor="let book of frame">
      <div class="profession__info-tab--book-pic">
        <img src="https://st.kp.yandex.net/images/film_big/49063.jpg" width="133" height="190" />
      </div>
      <span class="profession__info-tab--book-name">{{ book.name }}</span>
      <div class="profession__info-tab--book-text">{{ 'SHARED.MIKLE_ERARD' | translate }}</div>
      <div class="profession__info-tab--book-price">{{ book.price }} {{ 'COMMON.MONEY_SYMBOL' | translateByDefault }}</div>
    </li>
  </ul>
  <button *ngIf="canScrollRight()" type="button" class="next" (click)="scrollRight()" title="{{ getForwardLabel() | translate }}">
    &nbsp;
  </button>
</div>
