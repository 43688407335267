import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { ISpeciality } from 'app/shared/interfaces/ispeciality.interface';
import { OverlayBusyService } from 'app/shared/overlay-busy/overlay-busy.service';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { UniversitiesService } from '../universities.service';

@Component({
  selector: 'prf-add-university',
  templateUrl: './add-university.component.html',
  styleUrls: ['./add-university.component.scss'],
})
export class AddUniversityComponent implements OnInit {
  specialities: ISpeciality[];

  public form: UntypedFormGroup;
  public submitted: boolean = false;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private meta: Meta,
    private fb: UntypedFormBuilder,
    public route: ActivatedRoute,
    private translateService: TranslateService,
    private universitiesService: UniversitiesService,
    private utilsService: UtilsService,
    private overlayService: OverlayBusyService,
    private router: Router,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'Добавление ВУЗа' });
  }

  ngOnInit() {
    this.form = this.fb.group({
      name: new UntypedFormControl(null, Validators.required),
      universitySpecialities: new UntypedFormControl(),
    });

    this.universitiesService
      .getSpecialities()
      .pipe(
        tap(specialities => {
          this.specialities = specialities;
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  get f() {
    return this.form.controls;
  }

  submit() {
    this.submitted = true;
    if (this.form.valid) {
      let universityObject = {
        name: this.form.get('name').value,
        universitySpecialities: this.form.get('universitySpecialities').value,
      };

      this.universitiesService
        .addUniversity(universityObject)
        .pipe(
          tap(r => {
            this.utilsService.openSnackBar('👌 ВУЗ добавлен, вы будете перенаправлены на страницу данного ВУЗа', 'success');
            return setTimeout(_ => {
              this.router.navigate(['/admin/universities/' + r.id]);
            }, 2000);
          }),
        )
        .subscribe();
    } else {
      this.utilsService.openSnackBar('👎 Произошла ошибка, проверьте правильность данных', 'error');
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
