import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { IAllInstitutionsStatistic } from 'app/shared/interfaces/iallinstitutionsstatistic';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InstitutionClass } from '../../../../shared/classes/institution.class';
import { OverlayBusyService } from '../../../../shared/overlay-busy/overlay-busy.service';
import { AdminPanelService } from '../admin-panel.service';

@Component({
  selector: 'prf-centers',
  templateUrl: './centers.component.html',
  styleUrls: ['./centers.component.scss'],
})
export class CentersComponent implements OnInit, OnDestroy {
  allInstitutionsStatistic: IAllInstitutionsStatistic[] = [];

  // счетчики для подсчета общего количества данных на фронте.
  regPupilsCounter: number = 0;
  testedPupilsCounter: number = 0;
  changedInstitution: IAllInstitutionsStatistic;
  changedInstitutionInfo: InstitutionClass;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(private meta: Meta, private adminPanelService: AdminPanelService, private overlayService: OverlayBusyService) {
    this.meta.updateTag({ name: 'og:title', content: 'Центры' });
  }

  ngOnInit() {
    this.overlayService.show();

    //TODO необходим рефакторинг! базы не совпадают, объекты добавляются только в каталог
    this.adminPanelService
      .getAllInstitutionsStatistic()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(statistic => {
        this.allInstitutionsStatistic = statistic;
        this.allInstitutionsStatistic.forEach(school => {
          this.regPupilsCounter += school.registeredPupilsCount;
          this.testedPupilsCounter += school.completedTestsCount;
        });
        this.overlayService.hide();
      });
  }

  updataStatistics() {
    this.overlayService.show();
    this.adminPanelService
      .updateAllInstitutionsStatistic()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(r => {
        return this.overlayService.hide();
      });
  }

  institutionUpdate(instID) {
    this.changedInstitution = this.allInstitutionsStatistic.find(x => x.institutionId == instID);
    let index = this.allInstitutionsStatistic.indexOf(this.changedInstitution);
    this.adminPanelService
      .getInstitution(instID)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(data => {
        this.changedInstitutionInfo = data;
        this.allInstitutionsStatistic[index].institutionName = this.changedInstitutionInfo.name;
      });
  }
  // getInstitutionInfo(instID) {
  //   return await this.adminPanelService.getInstitution(instID);
  // }

  removeInstitution(institution) {
    return (this.allInstitutionsStatistic = this.allInstitutionsStatistic.filter(x => x.institutionId != institution.institutionId));
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
