<div class="filter-div cat" #formatFilter>
  <div class="professions-dropdown w-dropdown" [style.z-index]="formatOpen ? 1001 : 0">
    <div
      class="professions-dropdown-toggle w-dropdown-toggle"
      (click)="formatOpen = !formatOpen"
      [class.active]="filters.format && filters.format.length > 0"
    >
      <div class="professions-dd-icon-2 w-icon-dropdown-toggle"></div>
      <div>{{ checkFilterHeader() }}</div>
    </div>
    <nav class="dd-prof-filter-list w-dropdown-list" [class.w--open]="formatOpen">
      <div class="dd-prof-filter-div _2">
        <div class="filter-list-row">
          <ng-container *ngIf="filters.format && filters.format.length > 0">
            <a class="clear-categories show" (click)="deselectFormat()">{{ 'SHARED.CLEAR_BUTTON' | translate }}</a>
          </ng-container>
        </div>
        <div class="scroll-block">
          <div class="prof-categories-links">
            <a
              class="prof-categories-link w-inline-block"
              *ngFor="let format of formats"
              [class.w--current]="selectedFormats.indexOf(format.value) !== -1"
              (click)="setFormatsFilter(format)"
            >
              <div class="category-text-link">{{ format.viewValue }}</div>
            </a>
          </div>
        </div>
      </div>
    </nav>
  </div>
</div>

<ng-template #notSelected>
  <div class="filter-list-heading">{{ 'SHARED.ALL_FORMATS' | translate }}</div>
  <a class="clear-categories">{{ 'SHARED.CLEAR_BUTTON' | translate }}</a>
</ng-template>
