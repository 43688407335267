<svg
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  [attr.width]="SPIDER_CHART_CONFIG.defaultSize"
  [attr.height]="SPIDER_CHART_CONFIG.defaultSize"
  [attr.viewbox]="'0 ' + '0 ' + SPIDER_CHART_CONFIG.defaultSize + ' ' + SPIDER_CHART_CONFIG.defaultSize"
>
  <path
    *ngFor="let index of Array.from(Array(SPIDER_CHART_CONFIG.layout.repeatCounts).keys())"
    [attr.d]="pathData.d"
    [attr.fill]="SPIDER_CHART_CONFIG.layout.fill"
    [attr.stroke]="SPIDER_CHART_CONFIG.layout.stroke"
    [attr.stroke-width]="index === 0 ? SPIDER_CHART_CONFIG.layout.strokeWidth : SPIDER_CHART_CONFIG.layout.strokeWidthRepeatedHex"
    [style.scale]="index === 0 ? 1 : 1 - index * SPIDER_CHART_CONFIG.layout.repeatFigureStep"
    [style]="'transform-origin: center; transform: ' + pathData.translateString"
  ></path>
  <line
    *ngFor="let index of Array.from(Array(SPIDER_CHART_CONFIG.layout.peaks / 2).keys())"
    [class]="'svg-line-' + index"
    [attr.stroke]="SPIDER_CHART_CONFIG.line.stroke"
    [attr.stroke-opacity]="SPIDER_CHART_CONFIG.line.strokeOpacity"
    [attr.stroke-width]="SPIDER_CHART_CONFIG.line.strokeWidth"
    [attr.x1]="SPIDER_CHART_CONFIG.defaultSize / 2"
    [attr.x2]="SPIDER_CHART_CONFIG.defaultSize / 2"
    [attr.y1]="SPIDER_CHART_CONFIG.defaultSize / 2 - layoutConfig.height / 2"
    [attr.y2]="SPIDER_CHART_CONFIG.defaultSize / 2 + layoutConfig.height / 2"
    style="transform-origin: center"
  ></line>
  <ng-content></ng-content>
</svg>
