import { IScreenSections } from '../../../../../interfaces/prf-tests.interface';

export const enum Sections {
  TECHNICAL_AND_TECHNOLOGY = 'THEMATICS.TECHNICAL_AND_TECHNOLOGY',
  SPORT_AND_MILITARY = 'THEMATICS.SPORT_AND_MILITARY',
  CREATIVE = 'THEMATICS.CREATIVE',
  SOCIAL = 'THEMATICS.SOCIAL',
  SCIENCE = 'THEMATICS.SCIENCE',
  COUTURE = 'THEMATICS.COUTURE',
  FINANCE = 'THEMATICS.FINANCE',
  IT = 'THEMATICS.IT',
  LINGUISTIC = 'THEMATICS.LINGUISTIC',
  PHYSIC_AND_MATH = 'THEMATICS.PHYSIC_AND_MATH',
}

export const getScreenSections = (translations: Record<string, string>): IScreenSections[] => {
  return [
    {
      id: 1,
      name: translations[Sections.TECHNICAL_AND_TECHNOLOGY],
      fields: [],
      isOpened: false,
    },
    {
      id: 2,
      name: translations[Sections.SPORT_AND_MILITARY],
      fields: [],
      isOpened: false,
    },
    {
      id: 3,
      name: translations[Sections.CREATIVE],
      fields: [],
      isOpened: false,
    },
    { id: 4, name: translations[Sections.SOCIAL], fields: [], isOpened: false },
    {
      id: 5,
      name: translations[Sections.SCIENCE],
      fields: [],
      isOpened: false,
    },
    {
      id: 6,
      name: translations[Sections.COUTURE],
      fields: [],
      isOpened: false,
    },
    {
      id: 7,
      name: translations[Sections.FINANCE],
      fields: [],
      isOpened: false,
    },
    { id: 8, name: translations[Sections.IT], fields: [], isOpened: false },
    {
      id: 9,
      name: translations[Sections.LINGUISTIC],
      fields: [],
      isOpened: false,
    },
    {
      id: 10,
      name: translations[Sections.PHYSIC_AND_MATH],
      fields: [],
      isOpened: false,
    },
  ];
};
