import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UsersRestrictionService {
  private restrictedRegions: string[] = [
    '18ab5c91-7d94-4033-84f0-7e3ff02bb1d5',
    'cf8208ee-e114-44ea-80db-bf146c1202bf',
    '2beb4790-b106-4de9-bf31-7a87d2dbab5b',
  ];

  public checkUserAccess(regionId: string): boolean {
    return this.restrictedRegions.includes(regionId);
  }
}
