import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[prfNoAutocomplete]',
})
/**
 * Меняем autocomplete="off" на несуществующий, т.к. хром игнорирует "off".
 * Так же подменяем имя input'a, чтобы не показывать браузерные подсказки.
 */
export class NoAutocompleteDirective implements OnInit {
  private _chrome = navigator.userAgent.indexOf('Chrome') > -1;

  constructor(private _el: ElementRef) {}

  ngOnInit() {
    if (this._chrome) {
      if (this._el.nativeElement.getAttribute('autocomplete') === 'off') {
        setTimeout(() => {
          this._el.nativeElement.setAttribute('autocomplete', 'offoff');
        });
      }
      let name = this._el.nativeElement.getAttribute('name');
      if (name) {
        setTimeout(() => {
          this._el.nativeElement.setAttribute('name', '_' + name);
        });
      }
    }
  }
}
