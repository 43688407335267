import { Component, Input } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'prf-select-user-status-int',
  templateUrl: './select-user-status-int.component.html',
  styleUrls: ['./select-user-status-int.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: SelectUserStatusIntComponent, multi: true },
    { provide: NG_VALIDATORS, useExisting: SelectUserStatusIntComponent, multi: true },
  ],
})
export class SelectUserStatusIntComponent implements ControlValueAccessor {
  @Input() isFemale;
  private selected: number;

  constructor(private translate: TranslateService) {}

  private onChangeCallback = (_: any) => {};
  private onTouchedCallback = () => {};
  private isEn = () => this.translate.currentLang == 'en';

  public select(value) {
    this.selected = value;
    this.onChangeCallback(value);
  }

  public writeValue(value) {
    this.selected = value || 0;
  }

  public registerOnChange(fn) {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn) {
    this.onTouchedCallback = fn;
  }

  public validate(control: AbstractControl): { [validator: string]: string } {
    const value = control.value;

    if (value >= 1 && value <= 3) {
      return null;
    }
    return { selectUserStatus: '' };
  }
}
