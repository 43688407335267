import { inject, Injectable } from '@angular/core';
import { HttpService } from '../http';
import { forkJoin, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ApiSchoolsUrls } from './api-schools.collections';
import {
  IClass,
  IFilterClasses,
  IGetCourseByHridResponse,
  IReloadClassesResponse,
  ISchool,
} from './api-schools.interface';
import { EmptyGuid } from '../../helpers';
import { UserDataHandlerService } from '../../utils-services';

@Injectable({
  providedIn: 'root',
})
export class ApiSchoolsService {
  private readonly httpService: HttpService = inject(HttpService);

  constructor(private userDataHandlerService: UserDataHandlerService) {}

  public getSchoolById(schoolId: string): Observable<ISchool> {
    return this.httpService.get<ISchool>(ApiSchoolsUrls.GetSchoolById, { schoolId }).pipe(map((response: any) => response.school));
  }

  public updateSchool(schoolClass: ISchool): Observable<any> {
    return this.httpService.put(ApiSchoolsUrls.SchoolsUpdate, { schoolId: schoolClass.id, schoolClass }).pipe(map((r: any) => r.school));
  }

  public deleteSchool(id: string): Observable<any> {
    return this.httpService.delete(ApiSchoolsUrls.Schools, { id }).pipe(take(1));
  }

  public getSchools(): Observable<any> {
    return this.httpService.get(ApiSchoolsUrls.Schools).pipe(map((response: any) => response.schools));
  }

  public setSchool(dataSchool: any): Observable<any> {
    return this.httpService.post(ApiSchoolsUrls.Schools, { dataSchool }).pipe(take(1));
  }

  public getClassById(id: string): Observable<any> {
    return this.httpService.get(ApiSchoolsUrls.OneById, { id }).pipe(take(1));
  }

  public getElasticFilteredClasses(filters: IFilterClasses): Observable<any> {
    return this.httpService.post(ApiSchoolsUrls.GetFilteredClassesElastic, filters).pipe(take(1));
  }

  // TODO странная реализация обьединения двух разных запросов - нужно выяснить зачем и привести к одному запросу
  public getClassesByCourses(courses: Array<string>): Observable<any> {
    const userData = this.userDataHandlerService.getUserInfo();
    const regionId = userData.regionId;
    const municipalityId = userData.municipalityId;
    return forkJoin([
      this.httpService.post(ApiSchoolsUrls.GetFilteredClassesElastic, { regionId, municipalityId, courses }),
      this.httpService.post(ApiSchoolsUrls.GetFilteredClassesElastic, { regionId: EmptyGuid, municipalityId: EmptyGuid, courses }),
    ]).pipe(
      map(([res, resDefault]) => {
        // @ts-ignore
        return resDefault.concat(res);
      }),
    );
  }

  public getClassesCountStatistic(filters: IFilterClasses, isMongo: boolean = false): Observable<any> {
    return this.httpService
      .post(isMongo ? ApiSchoolsUrls.ClassesCountStatistics : ApiSchoolsUrls.ClassesCountStatisticsElastic, { filters })
      .pipe(map((response: any) => response.classesCountStatistic));
  }

  public reloadClasses(): Observable<IReloadClassesResponse> {
    return this.httpService.get<IGetCourseByHridResponse>(ApiSchoolsUrls.ReloadClasses).pipe(take(1));
  }

  public getClassesCountElastic(filters: IFilterClasses): Observable<any> {
    return this.httpService.post(ApiSchoolsUrls.GetClassesCountElastic, filters).pipe(take(1));
  }

  public getCourseByHrid(hrid: string): Observable<IGetCourseByHridResponse> {
    return this.httpService.get<IGetCourseByHridResponse>(ApiSchoolsUrls.GetCourseByHrid, { hrid }).pipe(take(1));
  }

  public createClassesDO(classData: IClass): Observable<any> {
    return this.httpService.post(ApiSchoolsUrls.CreateClassesDO, { class: classData }).pipe(take(1));
  }

  public changeClassesDO(classData: IClass): Observable<any> {
    return this.httpService.post(ApiSchoolsUrls.ChangeClassesDO, { class: classData }).pipe(take(1));
  }

  public removeCurrentClass(classesId: string): Observable<any> {
    return this.httpService.delete(ApiSchoolsUrls.RemoveCurrentClass, { classesId }).pipe(take(1));
  }

  public checkHrid(hrid: string): Observable<any> {
    return this.httpService.get(ApiSchoolsUrls.CheckHrid, { hrid }).pipe(take(1));
  }

  public getClassesCount(filters: IFilterClasses): Observable<any> {
    return this.httpService.post(ApiSchoolsUrls.GetClassesCount, { filters }).pipe(take(1));
  }

  //TODO некорректный ответ Back-end'a
  public getFilteredClasses(filters: IFilterClasses): Observable<any> {
    return this.httpService.post(ApiSchoolsUrls.GetFilteredClasses, { filters }).pipe(take(1));
  }
}
