import { Injectable } from '@angular/core';
import { HttpService } from 'app/core/services/http.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class RestorePasswordService {
  constructor(private http: HttpService) {}

  sendResetTokenEmail(email: string): Observable<any> {
    const model = {
      email: email,
    };

    return this.http.post('/b2c/v1.0/saas/sendresettoken', model).pipe(map(r => r));
  }
  sendResetTokenPhone(phone: string): Observable<any> {
    return this.http.post('/api/v1.0/auth/account/resetpasswordtocode', phone).pipe(map(r => r));
  }
}
