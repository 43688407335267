import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GoalsService } from 'app/shared/dashboard/goals/goals.service';
import { IProfession } from 'app/shared/interfaces/iprofession.interface';
import { Observable } from 'rxjs';
import { forkJoin as observableForkJoin } from 'rxjs/internal/observable/forkJoin';
import { switchMap, take, tap } from 'rxjs/operators';

const SOLO_TEXT = {
  story: ['задание', 'задания', 'заданий'],
  title: 'Самостоятельное изучение',
  selection: 'Находи материалы на тему профессий, изучай, исследуй и делись своими знаниями!',
};
const SHORT_TEXT = {
  story: ['курс', 'курса', 'курсов'],
  title: 'Курсы',
  selection: 'Подборки курсов, которые подходят для освоения профессии. Выбирай и записывайся!',
};
const MASTER_TEXT = {
  story: ['мероприятие', 'мероприятия', 'мероприятий'],
  title: 'Мастер-классы и профпробы',
  selection: 'Программы, в которых ты можешь попробовать себя в выбранной профессии.',
};
const EXCURSION_TEXT = {
  story: ['мероприятие', 'мероприятия', 'мероприятий'],
  title: 'Экскурсии, встречи и дни открытых дверей',
  selection: 'Мероприятия, которые помогут узнать больше о профессии и людях, которые в ней работают',
};
const ACTION_TEXT = {
  story: ['мероприятие', 'мероприятия', 'мероприятий'],
  title: 'Другое',
  selection: 'Акции, фестивали и конкурсы, на которых можно встретиться с интересными людьми и получить новые навыки.',
};

@Component({
  selector: 'prf-pupil-tasks',
  templateUrl: './pupil-tasks.component.html',
  styleUrls: ['./pupil-tasks.component.scss'],
})
export class PupilTasksComponent implements OnInit {
  @Input()
  set profession(val: IProfession) {
    this.loaded = false;
    this._profession = val;
    this.getTasks(val);
  }

  @Output() checkActive = new EventEmitter<any>();

  get profession(): IProfession {
    return this._profession;
  }

  public readonly soloText = SOLO_TEXT;
  public readonly shortText = SHORT_TEXT;
  public readonly masterText = MASTER_TEXT;
  public readonly excursionText = EXCURSION_TEXT;
  public readonly actionText = ACTION_TEXT;

  public loaded: boolean = false;
  public showStatic: boolean = false;
  public tasksLevel: number = 1;
  public myTasks: Array<any> = [];
  public userId: string = '';
  private _profession: any;

  public userTasks: any[] = [];
  public userTrajectories: any[] = [];
  public userStaticTaskBeginner: Array<any> = [];
  public userStaticTaskIntermediate: Array<any> = [];
  public first_block: Array<any> = [];
  public second_block: Array<any> = [];
  public third_block: Array<any> = [];
  public fourth_block: Array<any> = [];

  constructor(private goalsService: GoalsService) {
    this.userId = localStorage.getItem('userId');
  }

  ngOnInit() {}

  public changeTaskLevel(taskLevel) {
    if (this.tasksLevel != taskLevel) {
      this.tasksLevel = taskLevel;
      this.filterTasks(this.tasksLevel, this.myTasks);
    }
  }

  private getTasks(profession) {
    this.myTasks = [];
    this.goalsService
      .getClassesByCourses(profession.courses)
      .pipe(
        switchMap(userTasks => {
          this.userTasks = userTasks;
          return this.goalsService.getUserTrajectories(this._profession.id).pipe(
            switchMap(userTrajectories => {
              this.userTrajectories = userTrajectories;
              this.checkActive.emit(this.userTrajectories.length);
              this.userTasks = this.removeCompletedTasks(this.userTasks, this.userTrajectories);

              this.myTasks = this.userTasks.map(r => {
                r.findCourses = this._profession.courses;
                return r;
              });

              this.filterTasks(this.tasksLevel, this.myTasks);
              return this.getUserStaticTask();
            }),
          );
        }),
      )
      .subscribe(_ => {
        this.loaded = true;
      });
  }

  private filterTasks(taskLevel, myTasks) {
    let filter: any[] = [];
    this.showStatic = false;
    if (taskLevel === 1) {
      filter[0] = this.setFilter(['Introductory', 'Base', 'ShortCourse', 'LongCourse']);
      filter[1] = this.setFilter(['Introductory', 'Base', 'MasterClass', 'Profproba']);
      filter[2] = this.setFilter(['Introductory', 'Base', 'Excursion', 'Meeting']);
      filter[3] = this.setFilter(['Introductory', 'Base', 'Action', 'Festival']);
    } else if (taskLevel === 2) {
      filter[0] = this.setFilter(['InDepth', 'PreProfessional', 'ShortCourse', 'LongCourse']);
      filter[1] = this.setFilter(['InDepth', 'PreProfessional', 'MasterClass', 'Profproba']);
      filter[2] = this.setFilter(['InDepth', 'PreProfessional', 'Excursion', 'Meeting']);
      filter[3] = this.setFilter(['InDepth', 'PreProfessional', 'Action', 'Festival']);
    }
    this.first_block = [];
    this.first_block = myTasks.filter(item => {
      return this.filterFunction(item, filter[0]);
    });

    this.second_block = [];
    this.second_block = myTasks.filter(item => {
      return this.filterFunction(item, filter[1]);
    });

    this.third_block = [];
    this.third_block = myTasks.filter(item => {
      return this.filterFunction(item, filter[2]);
    });

    this.fourth_block = [];
    this.fourth_block = myTasks.filter(item => {
      return this.filterFunction(item, filter[3]);
    });
    if (this.first_block.length == 0 || this.second_block.length == 0 || this.third_block.length == 0 || this.fourth_block.length == 0) {
      this.showStatic = true;
    }
  }

  private removeCompletedTasks(userTasks, userTrajectories) {
    // remove already used task
    userTrajectories.forEach(ut => {
      userTasks = userTasks.filter(task => {
        return task.id !== ut.productId;
      });
    });
    return userTasks;
  }
  private setFilter(params: string[]): any {
    return {
      complexity: [params[0], params[1]],
      classesFormat: [params[2], params[3]],
    };
  }

  private filterFunction(item: any, filter: any): boolean {
    return (
      (item.complexity == filter.complexity[0] || item.complexity == filter.complexity[1]) &&
      (item.classesFormat == filter.classesFormat[0] || item.classesFormat == filter.classesFormat[1])
    );
  }

  public updMenuAccess(e) {
    if (e) {
      this.goalsService
        .getUserTrajectories(this._profession.id)
        .pipe(take(1))
        .subscribe(userTrajectories => {
          this.checkActive.emit(userTrajectories.length);
        });
    }
  }

  private getUserStaticTask(): Observable<any> {
    return observableForkJoin([
      this.goalsService.getStaticTaskByComplexity('Beginner'),
      this.goalsService.getStaticTaskByComplexity('Intermediate'),
    ]).pipe(
      take(1),
      tap(([userStaticTaskBeginner, userStaticTaskIntermediate]) => {
        this.userStaticTaskBeginner = this.removeCompletedTasks(userStaticTaskBeginner, this.userTrajectories);
        this.userStaticTaskIntermediate = this.removeCompletedTasks(userStaticTaskIntermediate, this.userTrajectories);
      }),
    );
  }
}
