import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'prf-archive-internships',
  templateUrl: './archive-internships.component.html',
  styleUrls: ['./archive-internships.component.scss'],
})
export class ArchiveInternshipsComponent implements OnInit, OnDestroy {
  public viewValue: boolean = false;
  public searches: any = [];

  constructor(private meta: Meta) {
    this.meta.updateTag({ name: 'og:title', content: 'Архивные стажировки' });
  }

  ngOnInit() {}

  dataSearch(data) {
    this.searches = data;
  }

  ngOnDestroy() {}
}
