import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class PortfolioAddModalService {
  public show$ = new BehaviorSubject<any>(false);
  public added$ = new BehaviorSubject<any>(null);
  public portfolioId: string = '';

  public show(file?: any) {
    this.show$.next(file ? file : true);
  }

  public hide() {
    this.show$.next(false);
  }
}
