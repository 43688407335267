import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RoundNumberPipe } from '@profilum-components/spider-chart-custom/round-number.pipe';
import { SpiderChartCustomService } from '@profilum-components/spider-chart-custom/spider-chart-custom.service';

import { ISpiderChartUnit, SpiderChartThemeEnum } from '../spider-chart-custom.model';
import { SPIDER_CHART_CONFIG } from '@profilum-components/spider-chart-custom/spider-chart-custom.config';

@Component({
  selector: 'prf-spider-chart-value-block',
  standalone: true,
  imports: [CommonModule, RoundNumberPipe],
  templateUrl: './spider-chart-value-block.component.html',
  styleUrls: ['./spider-chart-value-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpiderChartValueBlockComponent {
  @Input({ required: true }) public spiderChartUnitData: ISpiderChartUnit;
  @Input({ required: true }) public index: number;
  @Input({ required: true }) public stylePosition: string;
  @Input() public theme: SpiderChartThemeEnum = SpiderChartThemeEnum.GREEN;
  @Input() public additionalTheme: SpiderChartThemeEnum = SpiderChartThemeEnum.BLUE;
  @Input() public additionalChartUnitValue: number;

  constructor(private spiderChartCustomService: SpiderChartCustomService) {}

  public onTextClick(name: string): void {
    this.spiderChartCustomService.spiderChartDescription.next(name);
  }

  protected readonly SPIDER_CHART_CONFIG = SPIDER_CHART_CONFIG;
}
