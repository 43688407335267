import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { IResult } from '../../../../pages/results/results.interface';

@Component({
  selector: 'prf-class-results',
  templateUrl: './class-results.component.html',
  styleUrls: ['./class-results.component.scss'],
})
export class ClassResultsComponent implements OnInit {
  @Input() schoolClassId: any;
  @Input() skillsData: any;
  @Input() interestsData: any;
  @Input() talentsData: any;
  subscription: Subscription;
  public interests: IResult[] = [];
  public talents: IResult[] = [];
  public dataLoaded: boolean = true;
  sessionId: string = null;

  constructor() {}

  ngOnInit() {}
}
