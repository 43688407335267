import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { GoalRecommendationService } from './goal-recommendation.service';

@Component({
  selector: 'prf-goal-recommendation',
  templateUrl: './goal-recommendation.component.html',
})
export class GoalRecommendationComponent implements OnInit {
  @Input() data;
  public recommendations: any;
  constructor(private goalRecommendation: GoalRecommendationService, private router: Router) {}

  ngOnInit() {
    this.goalRecommendation
      .getRecommendations('Profession')
      .pipe(take(1))
      .subscribe(recommendations => (this.recommendations = recommendations));
  }

  openGoal(name) {
    this.router.navigate([`/${name}`]);
  }
}
