<!-- SEARCH -->
<prf-search-courses></prf-search-courses>

<div class="events-filters"></div>

<!--VIEW SETTINGS-->
<div class="courses-row-1">
  <div class="courses-number"></div>
  <div class="courses-column-1">
    <div class="gender-choice-row">
      <a (click)="viewValue = false" [class.active]="viewValue === false" #grid class="gender-button w-inline-block">
        <img *ngIf="!viewValue" src="/assets/images/courses/list-view-1-white.svg" />
        <img *ngIf="viewValue" src="/assets/images/courses/list-view-1-gray.svg" />
      </a>
      <a (click)="viewValue = true" [class.active]="viewValue === true" #list class="gender-button right w-inline-block">
        <img *ngIf="viewValue" src="/assets/images/courses/list-view-2-white.svg" />
        <img *ngIf="!viewValue" src="/assets/images/courses/list-view-2-gray.svg" />
      </a>
    </div>
  </div>
</div>
<!-- EVENTS ARCHIVE -->
<prf-events-courses-archive [viewValue]="viewValue"></prf-events-courses-archive>
