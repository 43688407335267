//@ts-nocheck

import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { register } from 'swiper/element/bundle';
import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';

register();
Swiper.use([Pagination]);

@Directive({
  selector: '[prfSwiperElement]',
  standalone: true,
})
export class SwiperDirective implements AfterViewInit {
  private readonly swiperElement: HTMLElement;
  private currentIndex: number;
  @Input() public config?: SwiperOptions;

  @Input() public set index(val: number) {
    if (val) {
      this.currentIndex = val;

      this.swiperElement.swiper.slideTo(this.currentIndex);
    }
  }
  @Output() public indexChange: EventEmitter<number> = new EventEmitter<number>();

  constructor(private element: ElementRef<HTMLElement>) {
    this.swiperElement = element.nativeElement;
  }

  public ngAfterViewInit(): void {
    Object.assign(this.element.nativeElement, this.config);
    this.element.nativeElement.initialize();
    this.element.nativeElement.swiper.on('activeIndexChange', (event: Swiper) => {
      this.indexChange.emit(event.activeIndex);
      this.currentIndex = event.activeIndex;
    });
  }
}
