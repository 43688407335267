<div *ngIf="sectionInfo" class="prf-bubble-box-container">
  <div class="prf-bubble-box" *ngIf="linesCounter?.length">
    <div class="prf-line" *ngFor="let line of linesCounter; let i = index; trackBy: trackByLineFn">
      <prf-bubble-view
        *ngFor="let answer of sectionInfo.elements | slice : linesCounter[i].start : linesCounter[i].end; trackBy: trackByFn"
        [answerInfo]="answer"
        [sectionInfo]="sectionInfo"
        (selectedAnswer)="updateSelectedAnswer($event)"
        [updateSelections]="updateSelectionsSubject"
        class="prf-bubble-box-item"
      ></prf-bubble-view>
    </div>
  </div>
</div>
