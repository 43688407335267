export interface ICard {
  isFilled: boolean;
  title: string;
  text: string;
}

export interface ICardBlock {
  title?: string;
  subtitle?: string;
  cards: ICard[];
}

export interface ICardWithLabel {
  title: string;
  isPurple: boolean;
  isLabel: boolean;
  labelTitle?: string;
  labelDescription?: string;
  labelSubtitle?: string;
}

export interface ITextWithLabel extends ICardWithLabel {
  description: string;
}

export interface IListWithLabel extends ICardWithLabel  {
  description: string[];
}

export interface ITitleText {
  title: string;
  text: string;
}

export interface IList {
  title: string;
  text: string;
}

export interface IListBlock {
  title?: string;
  list: IList[];
}

