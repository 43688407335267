export enum FileTypesEnum {
  Unknown = 'Unknown',
  Text = 'Text',
  Document = 'Document',
  Presentation = 'Presentation',
  Video = 'Video',
}
export const FileType2LabelMapping: Record<FileTypesEnum, string> = {
  [FileTypesEnum.Unknown]: 'Неизвестно',
  [FileTypesEnum.Text]: 'Текстовый файл (txt)',
  [FileTypesEnum.Document]: 'Документ (pdf)',
  [FileTypesEnum.Presentation]: 'Презентация (ppt)',
  [FileTypesEnum.Video]: 'Видео',
};
