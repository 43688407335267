export enum ApiUsersUrls {
  // teachers

  GetTeacherName = '/saas/v1.0/saas/getteachernamebyschoolclassid',
  GetSchoolClassesTeachers = '/saas/v1.0/teachers/getschoolclasses',
  GetSchoolClassTeachers = '/saas/v1.0/teachers/getschoolclass',
  GetExtendedPupilsTeachers = '/saas/v1.0/teachers/getextendedpupils',
  GetPupilsByClassTeachers = '/saas/v1.0/teachers/getpupilsbyclass',
  GetPupilsByTeacher = '/saas/v1.0/teachers/getpupilsbyteacher',
  GetSchoolTeachers = '/saas/v1.0/teachers/getschool',
  RemoveFromSchoolClass = '/saas/v1.0/teachers/removefromschoolclass',
  DeleteRegistrationCode = '/saas/v1.0/teachers/deleteregistrationcode',
  UnloadInactivePupil = '/saas/v1.0/teachers/unloadinactivepupilspdf',
  GetCompletesTestsCount = '/saas/v1.0/teachers/getcompletedtestscountbyclass',
  UpdateRegistrationCodeTeachers = '/saas/v1.0/teachers/updateregistrationcode',
  GetUserWithRecommendation = '/saas/v1.0/teachers/getuseridswithrecommendation',
  AddUserRecommendationTeachers = '/saas/v1.0/teachers/adduserrecommendation',
  GetPupilsByTutor = '/saas/v1.0/tutors/getpupilsbytutor',

  // directors

  GetSchoolDirectors = '/saas/v1.0/directors/getschool',
  GetSchoolClassDirectors = '/saas/v1.0/directors/getschoolclass',
  CreateClass = '/saas/v1.0/directors/createclass',
  ReplaceClass = '/saas/v1.0/directors/replaceclass',
  DeleteClass = '/saas/v1.0/directors/deleteclass',
  GetSchoolClassesDirectors = '/saas/v1.0/directors/getschoolclasses',
  GetSchoolClassesWithCounts = '/saas/v1.0/directors/getschoolclasseswithcounts',
  GetSchoolStatistic = '/saas/v1.0/directors/getschoolstatistic',
  GetTeacher = '/saas/v1.0/directors/getteacher',
  GetTeachers = '/saas/v1.0/directors/getteachers',
  GetExtendedPupilsDirectors = '/saas/v1.0/directors/getextendedpupils',
  GetPupilsByClassDirectors = '/saas/v1.0/directors/getpupilsbyclass',
  GetPupilsByFilter = '/saas/v1.0/directors/getpupilsbyresultsfilters',
  SendResultsToParents = '/saas/v1.0/directors/sendresultstoparents',
  GetCompletedTestsCountByClass = '/saas/v1.0/directors/getcompletedtestscountbyclass',
  GetPupilsDistributionExcel = '/saas/v1.0/directors/getpupilsdistributionsexcel',

  // parents

  CreateParentSession = '/saas/v1.0/parents/createsession',
  AddUserRecommendationParents = '/saas/v1.0/parents/adduserrecommendation',
  GetUserIdsWithRecommendation = '/saas/v1.0/parents/getuseridswithrecommendation',
  GetUserInfoByCode = '/saas/v1.0/parents/getuserinfobycode',
  UpdateRegistrationCodeParents = '/saas/v1.0/parents/updateregistrationcode',

  // pupils

  CreateSessionPupils = '/saas/v1.0/pupils/createsession',
  GetSchoolClassPupils = '/saas/v1.0/pupils/getschoolclass',
  SoftSchoolClassChangePupils = '/saas/v1.0/pupils/softschoolclasschange',
  AddClassEnrollPupils = '/saas/v1.0/pupils/addclassenroll',
  UpdateClassEnrollPupils = '/saas/v1.0/pupils/updateclassenroll',
  GetClassEnrollPupils = '/saas/v1.0/pupils/getclassenroll',
  GetRecommendationProgramPupils = '/saas/v1.0/pupils/getrecommendationprogramsspovpo',
  SendApplyInternshipPupils = '/saas/v1.0/pupils/sendapplyinternship',
  GetCurrentStepPupils = '/saas/v1.0/pupils/getcurrentstep',
  AddUserTrajectoryPupils = '/saas/v1.0/pupils/addusertrajectory',

  // TODO все методы с постфиксами - должны быть обьеденены с передачей роли, дабы избежать дублирования и выполняться в зависимости от роли пользователя
}
