import { Injectable } from '@angular/core';
import { SharedService } from 'app/shared/shared.service';
import { UserInfoClass } from 'app/shared/classes/userInfo.class';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserControlService {
  userRole: string = '';

  constructor() {}

  //get user control domain in api
  get userControlDomain(): string {
    this.userRole = localStorage.getItem('userRole');
    switch (this.userRole) {
      case 'admin':
        return 'admins';
      case 'schooladmin':
        return 'schooladmins';
      case 'parent':
        return 'parents';
      case 'pupil':
        return 'pupils';
      default:
        break;
    }
  }
}
