import { Component, Input } from '@angular/core';
import { Gender } from '../../enums/gender.enums';

@Component({
  selector: 'prf-not-passed-test',
  templateUrl: './not-passed-test.component.html',
  styleUrls: ['./not-passed-test.component.scss'],
})
export class NotPassedTestComponent {
  @Input() user: any;

  public isMale(gender: string) {
    return gender === Gender.Male;
  }
}
