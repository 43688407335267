import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PageFooterKzComponent } from './page-footer-kz/page-footer-kz.component';
import { PageFooterMosoblComponent } from './page-footer-mosobl/page-footer-mosobl.component';
import { PageFooterComponent } from './page-footer.component';

@NgModule({
  imports: [CommonModule, RouterModule, TranslateModule],
  declarations: [PageFooterComponent, PageFooterKzComponent, PageFooterMosoblComponent],
  exports: [PageFooterComponent],
})
export class PageFooterModule {}
