export enum Stands {
  Profilum,
  Talent,
}

export interface ISchool {
  id?: string; // ID School in ElasticSearch
  number?: string;
  city?: string;
  cityId?: string;
  district?: string;
  address?: string;
  type?: string;
  imageUrl?: string;
  urlMskObr?: string;
  url?: string;
  other?: string;
  externalObjectId?: string;
  externalServiceName?: string;
  isDeleted?: boolean;
  createdDate?: string;
  viewValue?: number;
  value?: number;
}

export interface ISchoolClass {
  id?: any; // ID SchoolClass in Profiles Service, SchoolClass table
  teacherId?: string;
  letter: string;
  number: string;
  schoolId?: string;
  className?: string;
  pupilsCount?: number;
  completedTestsCount?: number;
  totalPupilsCount?: number;
  parentRegistrationLink?: string;
  shortLink?: string;
  isTestedPupils?: number;
}

export interface IClass {
  id?: string; // Идентификатор конкретной программы
  createdDate?: string; // Дата создания
  publishedDate?: string; // Дата публикации
  hrid?: string; // Внешний идентификатор (human-readable id)
  name?: string; // Название
  description?: string; // Описание
  shortDescription?: string; // Красткое описание
  audience?: string[]; // Возрастная аудитория конкретной программы
  complexity?: string; // Сложность программы
  unitPrice?: number;
  unitDiscount?: number; //Размер скидки
  isPercent: boolean; //тип промокода, true - скидка в процентах, false - в рублях
  promocode: string; // промокод на скидку
  classesFormat?: string; // Формат программы
  deliveryMode?: string; // Способ проведения
  scheduleId?: string; // Идентификатор расписания занятий по программе
  url?: string; // Ссылка на описание конкретной программы
  mainImageId?: string; // Идентификатор главного фотографии к программе
  mainImagePath?: string; // Путь главного фотографии к программе
  galleryIds?: string[]; // Галерея картинок программы
  galleryPaths?: string[];
  orderNumber?: number;
  metroStations?: IMetroStationInterface[];
  isInstitutionApproved?: boolean;
  courseIds?: string[];
  courses?: string[];
  courseGroupIds?: string[];
  courseGroups?: string[];
  talents?: string[];
  talentIds?: string[];
  productGroups?: string[];
  address?: string;
  institutionId?: string;
  institution?: string;
  isArchived?: boolean;
  startTime?: string;
  startDate?: any; // дата начала мероприятия
  isRegular?: boolean; // регулярное мероприятие или нет
  minAgeAudience?: number; // минимальный возраст
  maxAgeAudience?: number; // максимальный возраст
  regionId?: string;
  municipalityId?: string;
  stand?: Stands;
}

export interface IMetroStationInterface {
  name: string;
  line: string;
}

export interface IFilterClasses {
  minPrice?: number;
  maxPrice?: number;
  metroStationNames?: string[];
  metroStations?: IMetroStationInterface[];
  classesFormat?: number[];
  courseGroups?: string[];
  ids?: string[];
  courses?: string[];
  talents?: string[];
  audience?: string;
  minAgeAudience?: number;
  maxAgeAudience?: number;
  publishedDate?: any;
  institutionId?: string;
  regionId?: string;
  municipalityId?: string;
  format?: string;
  from?: number;
  size?: number;
  stand?: any;
  isArchived?: boolean;
  query?: string;
}

export interface IReloadClassesResponse {
  status: string;
  comment: string;
}

export interface IGetCourseByHridResponse {
  class: IClass;
  status: string;
  comment: string;
  senderService: string;
}
