import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from 'app/core/services/http.service';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class LoginService {
  constructor(private http: HttpService) {}

  // обработка ошибок
  handleError(err: any): Observable<any> {
    if (err instanceof TimeoutError) {
      console.error(`Frontend returned timeout error: ${err['error']}`);
      return throwError(err['error']);
    }
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
      let errorText = err.error ? (err.error.comment ? err.error.comment : err.error) : null;
    }
    throw throwError(err);
  }

  public login(
    login: string,
    pass: string,
    rememberMe?: boolean,
  ): Observable<{
    role: string;
    userId: string;
    succeeded: boolean;
    tag: string;
    isUnRegistered: boolean;
  }> {
    localStorage.clear();
    let credentials = {
      userName: login,
      password: pass,
      rememberMe: true,
    };
    return this.http.post('/b2c/v1.0/saas/login', credentials).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  updateUserName(userName: string, code: string): Observable<{ status: string; comment: string; senderService: string }> {
    let data = {
      newUserName: userName,
      smsCode: code,
    };
    return this.http.post('/b2c/v1.0/saas/changeusername', data).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  checkPhone(phone) {
    return this.http.post('/b2c/v1.0/saas/isphonefree', phone).pipe(map(r => r));
  }

  public sendPhone(phoneString: string) {
    let credentials = {
      phoneNumber: phoneString,
    };
    return this.http.post('/b2c/v1.0/saas/sendsmscode', credentials).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  public tokenLogin(loginToken: string): Observable<any> {
    localStorage.clear();
    return this.http.post('/b2c/v1.0/saas/token_login', loginToken).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  public getLastSession(userId: string): Observable<any> {
    return this.http.get('/b2c/v1.0/saas/getlastsession?userId=' + userId).pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }
}
