import { IObject } from '../../api-services';

export interface IYandexMetricsConfig {
  enabled: boolean;
  ymId: number | null;
}

export interface IYmParams {
  order_price?: number;
  currency?: string;
  client_type?: string;
  visit_type?: string;
  count_professions?: string;
  User_id?: string;
  dynamic_values?: (string | number)[];
  user_id?: string;
  event_time?: string;
  event_page?: string;
  action_id?: string;
  event_type?: string;
  eventName?: string;
  event_action?: string;
  event_label?: string;
}

export interface IYmOptions {
  callback?: () => void;
  ctx?: IObject;
  title?: string;
}

export interface IYmExtLinkOptions extends IYmOptions {
  params?: IYmParams;
}

export interface IYmFileOptions extends IYmOptions {
  referer?: string;
  params?: IYmParams;
}

export interface IYmFirstPartyParams {
  email?: string;
  phone_number?: string;
  first_name?: string;
  last_name?: string;
  home_address?: string;
  street?: string;
  city?: string;
  region?: string;
  postal_code?: number;
  country?: string;
  yandex_cid?: number;
}

export interface IYmHitOptions extends IYmOptions {
  referer?: string;
  params?: IYmParams;
}

export interface IYmNotBounce {
  callback?: () => void;
  ctx?: IObject;
}

export interface IYmUTMParams {
  utm_medium: 'qr' | 'qrcode';
  utm_source?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_term?: string;
}

export type ymFileExtension =
  | '3GP'
  | '7Z'
  | 'AAC'
  | 'AVI'
  | 'APK'
  | 'CSV'
  | 'DJVU'
  | 'DOCX'
  | 'EXE'
  | 'FLAC'
  | 'FLV'
  | 'GZ'
  | 'KEY'
  | 'MIDI'
  | 'MKA'
  | 'MKV'
  | 'MP3'
  | 'MP4'
  | 'MPEG'
  | 'MOV'
  | 'MSI'
  | 'OGG'
  | 'PDF'
  | 'PKG'
  | 'PPS'
  | 'PPT'
  | 'PPTX'
  | 'RAR'
  | 'RTF'
  | 'TXT'
  | 'WAV'
  | 'WMA'
  | 'WMV'
  | 'WMF'
  | 'XLSX'
  | 'ZIP';
