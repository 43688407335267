<div
  *ngIf="answerInfo"
  class="prf-rectangle-view prf-rectangle-view-theme"
  [class.select]="isSelectedCurrently()"
  [class.selected]="isSelectedBefore()"
  [class.positive]="isPositive()"
  [class.negative]="!isPositive()"
  (click)="selectAnswer()"
>
  <p>{{ answerInfo.text }}</p>
</div>
