<div class="test-result-block">
  <ng-container *ngIf="showText">
    <h2 class="test-result-h1">Интересы</h2>
    <div>
      <p class="test-result-text">
        {{ 'TEST_AND_RESULTS.PARENT_INTEREST_TERM' | translate }}
      </p>
    </div>
  </ng-container>

  <div *ngIf="!hasChildResult || !hasParentResult" class="test-result-parameters">
    <div *ngFor="let skillData of data" class="test-result-parameters-row">
      <div class="test-result-parameters-text">{{ skillData.title }}</div>
      <div class="test-result-parameters-column">
        <div class="test-result-parameters-bar" [class.parent]="isParent">
          <div *ngIf="hasChildResult" [style.width]="skillData.childEstimate + '%'" class="test-result-parameters-line w-clearfix">
            <div class="test-result-parameters-oval"></div>
          </div>
          <div *ngIf="hasParentResult" [style.width]="skillData.parentEstimate + '%'" class="test-result-parameters-line w-clearfix">
            <div class="test-result-parameters-oval"></div>
          </div>
        </div>
        <div class="test-result-parameters-num" [class.parent]="isParent">
          {{ isParent ? skillData.parentEstimate : skillData.childEstimate }}
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="hasChildResult && hasParentResult" class="test-result-parameters">
    <div *ngFor="let skillData of data" class="test-result-parameters-row">
      <div [class.problem]="skillData.problem" class="test-result-parameters-text">
        {{ skillData.title }}
      </div>

      <div class="test-result-parameters-column">
        <div class="test-result-parameters-bar">
          <div [style.width]="skillData.childEstimate + '%'" class="test-result-parameters-line w-clearfix">
            <div class="test-result-parameters-oval"></div>
          </div>
          <div [style.margin-left]="skillData.parentEstimate + '%'" class="test-result-parameters-oval parent"></div>
        </div>
        <div class="test-result-parameters-num">{{ skillData.childEstimate }}</div>
        <div class="test-result-parameters-num parent">{{ skillData.parentEstimate }}</div>
      </div>
    </div>
  </div>

  <div *ngIf="hasChildResult && hasParentResult" class="diagram-row _2">
    <div class="diagram-column">
      <div class="diagram-color-oval"></div>
      <div>Результаты ребенка</div>
    </div>
    <div class="diagram-column">
      <div class="diagram-color-oval blue"></div>
      <div>Результаты родителя</div>
    </div>
  </div>
</div>
