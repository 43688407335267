import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'prf-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AdminPanelComponent implements OnInit, OnDestroy {
  @Output() updateValue = new EventEmitter<any>();
  schoolId: string;
  length: number;
  public title: string = '';
  adminLevel: string;

  constructor(private meta: Meta, private router: Router, private translateService: TranslateService) {
    this.meta.updateTag({ name: 'og:title', content: 'Личный кабинет администратора' });

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.checkTitle(event.url);
      }
    });

    this.getTranslation('SHARED.GENERAL')
      .pipe(take(1))
      .subscribe(translation => {
        this.title = translation;
      });
  }

  ngOnInit() {
    this.adminLevel = localStorage.getItem('adminLevel');
  }

  checkTitle(url) {
    if (url == '/admin/courses') {
      this.title = 'Курсы';
    } else if (url == '/admin/create-course') {
      this.title = 'Курсы';
    } else if (url == '/admin/events') {
      this.title = 'Мероприятия';
    } else if (url == '/admin/create-events') {
      this.title = 'Мероприятия';
    } else if (url == '/admin/schools') {
      this.title = 'Школы';
    } else if (url == '/admin/professions') {
      this.getTranslation('HEADER.PROF')
        .pipe(take(1))
        .subscribe(translation => (this.title = translation));
    } else if (url == '/admin/centers') {
      this.title = 'Центры ДО';
    } else if (url == '/admin/users') {
      this.title = 'Пользователи';
    } else if (url == '/admin/create-course') {
      this.title = 'Добавление курса';
    } else if (url == '/admin/internships') {
      this.title = 'Стажировки';
    } else if (url == '/admin/universities') {
      this.title = 'ВУЗы';
    } else if (url == '/admin/specialties') {
      this.title = 'Специальности';
    } else if (url == '/admin/regions') {
      this.title = 'Регионы';
    } else if (url == '/admin/municipalities') {
      this.title = 'Муниципалитеты';
    } else if (url == '/admin/cities') {
      this.title = 'Города';
    } else if (url == '/admin/regions') {
      this.title = 'Регионы';
    } else if (url.includes('/admin/system')) {
      this.title = 'Система';
    }
  }

  getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }

  ngOnDestroy() {}
}
