import { inject, Injectable } from '@angular/core';
import { HttpService } from '../http';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { B2gTinderUrls } from './tinder.collection';
import { IGetProfessionsRequest, IGetProfessionsResponse } from './tinder.interface';

@Injectable({
  providedIn: 'root',
})
export class B2gTinderService {
  private readonly httpService: HttpService = inject(HttpService);

  public getProfessions(params: IGetProfessionsRequest): Observable<IGetProfessionsResponse> {
    return this.httpService.get<any>(B2gTinderUrls.Professions, params).pipe(take(1));
  }

  public fixProfessions(tinderType: any): Observable<any> {
    return this.httpService.post<any>(B2gTinderUrls.Fix, tinderType).pipe(take(1));
  }
}
