import { Injectable } from '@angular/core';
import { HttpService } from '../http';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ApiNotificationsUrls } from './api-notifications.collections';
import { INotify, IUpdateNotificationStatusResponse } from './api-notifications.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiNotificationsService {
  constructor(private httpService: HttpService) {}

  public getNotifications(): Observable<INotify[]> {
    return this.httpService.get<INotify[]>(ApiNotificationsUrls.Notifications).pipe(take(1));
  }

  public setNotificationViewed(notificationId: string): Observable<IUpdateNotificationStatusResponse> {
    return this.httpService
      .put<IUpdateNotificationStatusResponse>(ApiNotificationsUrls.Notifications, null, { notificationId })
      .pipe(take(1));
  }
}
