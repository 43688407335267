import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpService } from '../../api-services';
import { StorageKeys, WebStorageService } from '../../logic-services';

@Injectable()
export class MosruGuard  {
  constructor(private router: Router, private http: HttpService, private webStorageService: WebStorageService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const tag = localStorage.getItem('tag');
    return await this.checkCookie()
      .toPromise()
      .then((response: any) => {
        if (response.status !== 'Success' || tag !== 'MosRu') {
          localStorage.clear();
          this.webStorageService.set(StorageKeys.LastRoute, state.url);
          this.router.navigate(['/mosrulogin']);
          return false;
        } else {
          localStorage.removeItem('lastRoute');
          return true;
        }
      });
  }

  public checkCookie() {
    return this.http.get('/saas/v1.0/saas/checkcookie').pipe(
      map(r => r),
      catchError((err: HttpErrorResponse) => {
        if (err.status >= 500 && err.status < 600) {
          localStorage.clear();
          this.router.navigate(['/login']);
        }
        return throwError(err);
      }),
    );
  }
}
