import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IUserInfo } from '../b2g-saas';
import { StorageKeys, WebStorageService } from '../../logic-services';
import { ApiPlayerService } from '../api-player';
import { UserDataHandlerService } from '../../utils-services';
import { diagnosticTestIds } from './testIds.enums';

@Injectable({
  providedIn: 'root',
})
export class ResultsTestService {
  private _resultsTestIds: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _regionTestInfo: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private userInfo: IUserInfo;

  private regionTestInfo;

  constructor(
    private apiPlayerService: ApiPlayerService,
    private userDataHandlerService: UserDataHandlerService,
    private webStorageService: WebStorageService,
  ) {}

  public getResultsTestId(): BehaviorSubject<any> {
    if (!this._resultsTestIds?.value) {
      this._resultsTestIds.next(this.webStorageService.get(StorageKeys.ResultsTestIds));
    }

    return this._resultsTestIds;
  }

  public getRegionTestInfo(): BehaviorSubject<any> {
    if (!this._regionTestInfo?.value) {
      this._regionTestInfo.next(this.webStorageService.get(StorageKeys.RegionTestInfo));
    }

    return this._regionTestInfo;
  }

  public setRegionTestInfo(regionId?: string): void {
    const region = regionId ? regionId : this.userDataHandlerService.getUserInfo().regionId;

    this.apiPlayerService.regionTestInfo(region).subscribe(el => {
      this.regionTestInfo = el;
      this.setResultsTestId();
      this.webStorageService.set(StorageKeys.RegionTestInfo, el);
      this._regionTestInfo.next(el);
    });
  }

  public setResultsTestId(): void {
    this.userInfo = this.userDataHandlerService.getUserInfo();

    this.apiPlayerService.getSessionsList(this.userInfo.userId).subscribe(res => {
      this.setTestIds(res);
    });
  }

  private setTestIds(sessionsList): void {
    if (!this.regionTestInfo) {
      this.regionTestInfo = this.getRegionTestInfo().value;
    }

    const resultsTestIds: any = {};
    sessionsList.forEach(el => {
      switch (el.screeningTestId) {
        case this.regionTestInfo.interestsScreeningTestId: {
          if (el.completed === true) {
            resultsTestIds.complitedInterestTestId = el.sessionId;
          }
          resultsTestIds.lastSessionIdInterests = el;
          break;
        }
        case this.regionTestInfo.aptitudeScreeningTestId: {
          if (el.completed === true) {
            resultsTestIds.complitedCapabilitiesTestId = el.sessionId;
          }
          resultsTestIds.lastSessionIdCapabilities = el;
          break;
        }
        case diagnosticTestIds.careerLiteracyScreeningTestId:
        case diagnosticTestIds.careerLiteracyScreeningTestId2: {
          resultsTestIds.lastSessionIdCareerLiteracy = el;
          break;
        }
        case diagnosticTestIds.diagnosticByCareerTypeTestId: {
          resultsTestIds.lastSessionIdDiagnosticByCareerType = el;
          break;
        }
        case diagnosticTestIds.outputDiagnosticBashkortostan: {
          resultsTestIds.lastSessionIdDiagnosticBashkortostan = el;
          break;
        }
        case diagnosticTestIds.outputDiagnosticMurmansk: {
          resultsTestIds.lastSessionIdDiagnosticMurmansk = el;
          break;
        }
        case diagnosticTestIds.outputDiagnosticYanao6_7: {
          resultsTestIds.lastSessionIdDiagnosticYanao6_7 = el;
          break;
        }
        case diagnosticTestIds.outputDiagnosticYanao8_11: {
          resultsTestIds.lastSessionIdDiagnosticYanao8_11 = el;
          break;
        }
        default:
          break;
      }
    });

    this.webStorageService.set(StorageKeys.ResultsTestIds, resultsTestIds);
    this._resultsTestIds.next(resultsTestIds);
  }
}
