import { UserRoles } from '../../helpers/common-helper/common.collections';

export enum YmItems {
  // teacher
  T_Profile_SaveChanges = 'T_Profile_SaveChanges',
  T_Profile_EditButton = 'T_Profile_EditButton',
  T_Class_StudentAccess = 'T_Class_StudentAccess',
  T_Professions_Courses = 'T_Professions_Сourses',
  T_Professions_SendRecommendation = 'T_Professions_SendRecommendation',
  T_Professions_Recommendation = 'T_Professions_Recommendation',
  T_Courses_SendRecommendation = 'T_Сourses_SendRecommendation',
  T_Courses_Recommendation = 'T_Сourses_Recommendation',
  T_Courses_Course = 'T_Сourses_Сourse',
  T_Professions = 'T_Professions',
  T_Results = 'T_Results',
  T_Class = 'T_Class',
  T_Lessons = 'T_Lessons',
  T_Professions_ProfessionCard = 'T_Professions_ProfessionСard',
  T_Class_InvitationInEmptyClass = 'T_Class_InvitationInEmptyClass',
  T_Class_invitationInNotEmptyClass = 'T_Class_invitationInNotEmptyClass',
  T_Results_AllSelections = 'T_Results_AllSelections',
  T_Results_Industries = 'T_Results_Industries',
  T_Results_SelectClass = 'T_Results_SelectClass',
  T_Class_SelectClass = 'T_Class_SelectClass',
  T_Lessons_SelectClass = 'T_Lessons_SelectClass',
  T_SelectClass = 'T_SelectClass',
  T_Class_DownloadAccess = 'T_Class_DownloadAccess',
  T_Lessons_AccessToLesson = 'T_Lessons_AccessToLesson',
  T_Lessons_invitationInEmptyClass = 'T_Lessons_invitationInEmptyClass',
  T_Lessons_Download = 'T_Lessons_Download',
  T_Lessons_Open = 'T_Lessons_Open',
  T_Results_Selections = 'T_Results_Selections',
  T_Results_Register = 'T_Results_Register',
  T_Results_Diagnostics = 'T_Results_Diagnostics',
  RegistrationTeacher_ConfirmButton = 'RegistrationTeacher_ConfirmButton',
  RegistrationTeacher_Success = 'RegistrationTeacher_Success',
  T_Reg_Hello_ClickEnterButton = 'T_Reg_Hellow_ClickEnterButton',
  T_Reg_Confirm_ClickConfirmButton = 'T_Reg_Confirm_ClickConfirmButton',
  T_Reg_CreatePas_PasswordValidation = 'T_Reg_CreatePas_PasswordValidation',
  T_Reg_Hellow_NotMeButton = 'T_Reg_Hellow_NotMeButton',
  T_Reg_Confirm_AnotherNumber = 'T_Reg_Confirm_AnotherNumber',
  T_Reg_CreatePas_PasswordMismatch = 'T_Reg_CreatePas_PasswordMismatch',
  T_Reg_CreatePas_PasswordVisibility = 'T_Reg_CreatePas_PasswordVisibility',

  // parent
  RegistrationParent_ConfirmButton = 'RegistrationParent_ConfirmButton',
  RegistrationParent_Success = 'RegistrationParent_Success',
  Pa_Reg_Hello_OpenPage = 'Pa_Reg_Hellow_OpenPage',
  Pa_Reg_Hello_ClickEnterButton = 'Pa_Reg_Hellow_ClickEnterButton',
  Pa_Reg_ParentDetails_ClickEnterButton = 'Pa_Reg_ParentDetails_ClickEnterButton',
  Pa_Reg_PupilData_ClickEnterButton = 'Pa_Reg_PupilData_ClickEnterButton',
  Pa_Reg_CheckData_ClickEnterButton = 'Pa_Reg_CheckData_ClickEnterButton',
  Pa_Reg_Hello_DataIsNotCorrect = 'Pa_Reg_Hellow_DataIsNotCorrect',
  Pa_Reg_DataCheck_Edit = 'Pa_Reg_DataCheck_Edit',
  Pa_Reg_ParentData_NewsletterConsent = 'Pa_Reg_ParentData_NewsletterConsent',

  // pupil
  S_Favorites_NotLikes = 'S_Favorites_NotLikes',
  S_ProfessionsCard_NotLikes = 'S_ProfessionsCard_NotLikes',
  S_Professions_NotLikes = 'S_Professions_NotLikes',
  S_lessons_NotLikes = 'S_lessons_NotLikes',
  S_AllLikes_NotLike = 'S_AllLikes_NotLike',
  S_Favorites_Likes = 'S_Favorites_Likes',
  S_ProfessionsCard_Likes = 'S_ProfessionsCard_Likes',
  S_Professions_Likes = 'S_Professions_Likes',
  S_lessons_Likes = 'S_lessons_Likes',
  S_AllLikes = 'S_AllLikes',
  S_lessons_CompleteExercise = 'S_lessons_CompleteExercise',
  S_lessons_Exercise = 'S_lessons_Exercise',
  S_ProfessionsCard_Courses = 'S_ProfessionsCard_Сourses',
  S_ProfessionsCard_Materials = 'S_ProfessionsCard_Materials',
  S_Favorites_ProfessionsCard = 'S_Favorites_ProfessionsCard',
  S_Professions_ProfessionsCard = 'S_Professions_ProfessionsCard',
  S_AllProfessionsCard = 'S_AllProfessionsCard',
  RegistrationPupil_ConfirmButton = 'RegistrationPupil_ConfirmButton',
  RegistrationPupil_Success = 'RegistrationPupil_Success',
  S_lessons = 'S_lessons',
  S_profile = 'S_profile',
  S_Professions = 'S_Professions',
  S_Profile_Favorites = 'S_Profile_Favorites',
  S_Profile_Interests = 'S_Profile_Interests',
  S_Profile_Aptitude = 'S_Profile_Aptitude',
  S_Interests_ViewProfessions = 'S_Interests_ViewProfessions',
  S_Aptitudes_ViewProfessions = 'S_Aptitudes_ViewProfessions',
  S_Profile_SPOVO_To_See = 'S_Profile_SPOVO_To_See',
  S_Profile_SPOVO_Any_Item = 'S_Profile_SPOVO_Any_Item',
  S_Profile_SPOVO_Item = 'S_Profile_SPOVO_Item',
  S_Profile_SPOVO_Filter = 'S_Profile_SPOVO_Filter',
  S_Profile_SPOVO_Scroll = 'S_Profile_SPOVO_Scroll',
  S_Profile_SPOVO_Open = 'S_Profile_SPOVO_Open',
  S_Profile_SPOVO_Lets_See = "S_Profile_SPOVO_Let's_See",
  Pu_Reg_Hello_OpenPage = 'Pu_Reg_Hellow_OpenPage',
  Pu_Reg_Hello_ClickEnterButton = 'Pu_Reg_Hellow_ClickEnterButton',
  Pu_Reg_CreatePas_ClickEnterButton = 'Pu_Reg_CreatePas_ClickEnterButton',
  Pu_Reg_Introduction_ClickEnterButton = 'Pu_Reg_Introduction_ClickEnterButton',
  Pu_Reg_Hellow_DataNotCorrect = 'Pu_Reg_Hellow_DataNotCorrect',
  S_ProfessionsPage = 'S_ProfessionsPage',
  S_lessons_Exercise_Like = 'S_lessons_Exercise_Like',
  S_lessons_Exercise_NotLike = 'S_lessons_Exercise_NotLike',
  S_lessons_Exercise_Back = 'S_lessons_Exercise_Back',
  Pu_Reg_Hellow_OpenPage = 'Pu_Reg_Hellow_OpenPage',
  Pu_Reg_Hellow_ClickEnterButton = 'Pu_Reg_Hellow_ClickEnterButton',
  S_Lessons_Diagnostics_Open = 'S_Lessons_Diagnostics_Open',
  S_Lessons_Diagnostics_ManyAnswers_Select = 'S_Lessons_Diagnostics_ManyAnswers_Select',
  S_Lessons_Diagnostics_ManyAnswers_Unselect = 'S_Lessons_Diagnostics_ManyAnswers_Unselect',
  S_Lessons_Diagnostics_OpenAnswer = 'S_Lessons_Diagnostics_OpenAnswer',
  S_Lessons_Diagnostics_SkrollBarAnswer = 'S_Lessons_Diagnostics_SkrollBarAnswer',
  S_Lessons_Diagnostics_NextButton = 'S_Lessons_Diagnostics_NextButton',
  S_Lessons_Diagnostics_BackButton = 'S_Lessons_Diagnostics_BackButton',
  S_Lessons_Diagnostics_CrossButton = 'S_Lessons_Diagnostics_CrossButton',
  S_Lessons_Diagnostics_Done = 'S_Lessons_Diagnostics_Done',
  S_Lessons_DiagnosticsKG_Result_ProfessionButton = 'S_Lessons_DiagnosticsKG_Result_ProfessionButton',

  // for all users
  Recovery_Password_Next = 'Recovery_Password_Next',
  None = 'None',

  // not exist goals
  PUPIL_TEST_FINISHED = 'PUPIL_TEST_FINISHED',
  TEACHER_PRES_OPEN = 'TEACHER_PRES_OPEN',
  ADD_TO_CART = 'ADD_TO_CART',
  PARENT_REG = 'PARENT_REG',
  PARTNER_EMAIL_REGISTRATION = 'PARTNER_EMAIL_REGISTRATION',
  PUPIL_REG = 'PUPIL_REG',
  PUPIL_CODE_REG = 'PUPIL_CODE_REG',
  ADD_PUPIL_BY_REG = 'ADD_PUPIL_BY_REG',
  ADD_PUPIL_BY_CODE = 'ADD_PUPIL_BY_CODE',

  // userParams method
  UserPupilParams = 'userPupilParams',
  UserTeacherParams = 'userTeacherParams',

  // params method
  PupilParam = 'pupilParam',
  TeacherParam = 'teacherParam',

  // МУФ
  MufStartTestButton = 'User_MUF_WelcomePage_ClickEnterButton',
  MufCompleteTestButton = 'User_MUF_LastTestSlidePage_ClickCompleteButton',


  // Про творчество (депкульт)
  ProtvorchestvoStartTestButton = 'User_MUF_WelcomePage_ClickEnterButton',
  ProtvorchestvoCompleteTestButton = 'User_MUF_LastTestSlidePage_ClickCompleteButton',
}

const YmItemsForTeacherRole: Set<YmItems> = new Set<YmItems>([
  YmItems.T_Profile_SaveChanges,
  YmItems.T_Profile_EditButton,
  YmItems.T_Class_StudentAccess,
  YmItems.T_Courses_SendRecommendation,
  YmItems.T_Courses_Recommendation,
  YmItems.T_Courses_Course,
  YmItems.T_Professions_Courses,
  YmItems.T_Professions_SendRecommendation,
  YmItems.T_Professions_Recommendation,
  YmItems.TeacherParam,
  YmItems.UserTeacherParams,
  YmItems.RegistrationTeacher_Success,
  YmItems.RegistrationTeacher_ConfirmButton,
  YmItems.T_Professions,
  YmItems.T_Results,
  YmItems.T_Class,
  YmItems.T_Lessons,
  YmItems.T_Professions_ProfessionCard,
  YmItems.T_Class_InvitationInEmptyClass,
  YmItems.T_Class_invitationInNotEmptyClass,
  YmItems.T_Results_AllSelections,
  YmItems.T_Results_Industries,
  YmItems.T_Results_SelectClass,
  YmItems.T_Class_SelectClass,
  YmItems.T_Lessons_SelectClass,
  YmItems.T_SelectClass,
  YmItems.T_Class_DownloadAccess,
  YmItems.T_Lessons_AccessToLesson,
  YmItems.T_Lessons_invitationInEmptyClass,
  YmItems.T_Lessons_Download,
  YmItems.T_Lessons_Open,
  YmItems.T_Results_Selections,
  YmItems.T_Results_Register,
  YmItems.T_Results_Diagnostics,
  YmItems.T_Reg_Hello_ClickEnterButton,
  YmItems.T_Reg_Confirm_ClickConfirmButton,
  YmItems.T_Reg_CreatePas_PasswordValidation,
  YmItems.T_Reg_Hellow_NotMeButton,
  YmItems.T_Reg_Confirm_AnotherNumber,
  YmItems.T_Reg_CreatePas_PasswordMismatch,
  YmItems.T_Reg_CreatePas_PasswordVisibility,
]);
const YmItemsForParentRole: Set<YmItems> = new Set<YmItems>([
  YmItems.RegistrationParent_ConfirmButton,
  YmItems.RegistrationParent_Success,
  YmItems.Pa_Reg_Hello_OpenPage,
  YmItems.Pa_Reg_Hello_ClickEnterButton,
  YmItems.Pa_Reg_ParentDetails_ClickEnterButton,
  YmItems.Pa_Reg_PupilData_ClickEnterButton,
  YmItems.Pa_Reg_CheckData_ClickEnterButton,
  YmItems.Pa_Reg_Hello_DataIsNotCorrect,
  YmItems.Pa_Reg_DataCheck_Edit,
  YmItems.Pa_Reg_ParentData_NewsletterConsent,
]);

const YmItemsForPupilRole: Set<YmItems> = new Set<YmItems>([
  YmItems.S_Favorites_NotLikes,
  YmItems.S_ProfessionsCard_NotLikes,
  YmItems.S_Professions_NotLikes,
  YmItems.S_lessons_NotLikes,
  YmItems.S_AllLikes_NotLike,
  YmItems.S_Favorites_Likes,
  YmItems.S_ProfessionsCard_Likes,
  YmItems.S_Professions_Likes,
  YmItems.S_lessons_Likes,
  YmItems.S_AllLikes,
  YmItems.S_lessons_CompleteExercise,
  YmItems.S_lessons_Exercise,
  YmItems.S_ProfessionsCard_Courses,
  YmItems.S_ProfessionsCard_Materials,
  YmItems.S_Favorites_ProfessionsCard,
  YmItems.S_Professions_ProfessionsCard,
  YmItems.S_AllProfessionsCard,
  YmItems.S_Interests_ViewProfessions,
  YmItems.S_Aptitudes_ViewProfessions,
  YmItems.S_Profile_Favorites,
  YmItems.S_Profile_Interests,
  YmItems.S_Profile_Aptitude,
  YmItems.S_lessons,
  YmItems.S_profile,
  YmItems.S_Professions,
  YmItems.S_Profile_SPOVO_To_See,
  YmItems.S_Profile_SPOVO_Any_Item,
  YmItems.S_Profile_SPOVO_Item,
  YmItems.S_Profile_SPOVO_Scroll,
  YmItems.S_Profile_SPOVO_Open,
  YmItems.S_Profile_SPOVO_Lets_See,
  YmItems.S_Profile_SPOVO_Filter,
  YmItems.PupilParam,
  YmItems.UserPupilParams,
  YmItems.RegistrationPupil_ConfirmButton,
  YmItems.RegistrationPupil_Success,
  YmItems.Pu_Reg_Hello_OpenPage,
  YmItems.Pu_Reg_Hello_ClickEnterButton,
  YmItems.Pu_Reg_CreatePas_ClickEnterButton,
  YmItems.Pu_Reg_Introduction_ClickEnterButton,
  YmItems.Pu_Reg_Hellow_DataNotCorrect,
  YmItems.S_ProfessionsPage,
  YmItems.S_lessons_Exercise_Like,
  YmItems.S_lessons_Exercise_NotLike,
  YmItems.S_lessons_Exercise_Back,
  YmItems.Pu_Reg_Hellow_OpenPage,
  YmItems.Pu_Reg_Hellow_ClickEnterButton,
  YmItems.S_Lessons_Diagnostics_Open,
  YmItems.S_Lessons_Diagnostics_ManyAnswers_Select,
  YmItems.S_Lessons_Diagnostics_ManyAnswers_Unselect,
  YmItems.S_Lessons_Diagnostics_OpenAnswer,
  YmItems.S_Lessons_Diagnostics_SkrollBarAnswer,
  YmItems.S_Lessons_Diagnostics_NextButton,
  YmItems.S_Lessons_Diagnostics_BackButton,
  YmItems.S_Lessons_Diagnostics_CrossButton,
  YmItems.S_Lessons_Diagnostics_Done,
  YmItems.S_Lessons_DiagnosticsKG_Result_ProfessionButton,
]);

const YmItemsNoRole: Set<YmItems> = new Set<YmItems>([
  YmItems.Recovery_Password_Next,
  YmItems.MufStartTestButton,
  YmItems.MufCompleteTestButton,

  YmItems.PUPIL_TEST_FINISHED,
  YmItems.TEACHER_PRES_OPEN,
  YmItems.ADD_TO_CART,
  YmItems.PARENT_REG,
  YmItems.PARTNER_EMAIL_REGISTRATION,
  YmItems.PUPIL_REG,
  YmItems.PUPIL_CODE_REG,
  YmItems.ADD_PUPIL_BY_REG,
  YmItems.ADD_PUPIL_BY_CODE,
]);

export const YmItemsByRole: Map<UserRoles, Set<YmItems>> = new Map<UserRoles, Set<YmItems>>([
  [UserRoles.teacher, YmItemsForTeacherRole],
  [UserRoles.pupil, YmItemsForPupilRole],
  [UserRoles.parent, YmItemsForParentRole],
  ['none' as UserRoles, YmItemsNoRole],
]);

export enum YmEventType {
  Event = 'event',
  Pageview = 'pageview',
}

export enum YmEventName {
  ViewProfessionPage = 'view_profession_page',
  LikingProfessionTinder = 'liking_profession_tinder',
  BackButtonProfessionTinder = 'back_button_profession_tinder',
  CompleteExercise = 'complete_exercise',
  LikingProfession = 'liking_profession',
  TransitionToCategories = 'transition_to_categories',
  SelectionsExpansion = 'selections_expansion',
  TransitionToProfessionsFiltered = 'transition_to_professions_filtered',
  TransitionToProfileItem = 'transition_to_profile_item',
  TransitionToMenuItem = 'transition_to_menu_item',
  StudentsRegistrationPageTransition = 'students_registration_page_transition',
  StudentsRegistrationEnterButtonClick = 'students_registration_enter_button_click',
  StudentsRegistrationContinueButtonClick = 'students_registration_continue_button_click',
  StudentsRegistrationStartButtonClick = 'students_registration_start_button_click',
  StudentsRegistrationIncorrectDataButtonClick = 'students_registration_incorrect_data_button_click',
  TransitionToSelections = 'transition_to_selections',
  TransitionToRegister = 'transition_to_register',
  TransitionToDiagnostics = 'transition_to_diagnostics',
  TransitionToProfessionCard = 'transition_to_profession_card',
  AssigningTasks = 'assigning_tasks',
  OpeningLesson = 'opening_lesson',
  DownloadingLesson = 'downloading_lesson',
  CopyingInvitation = 'copying_invitation',
  ProfileEditing = 'profile_editing',
  SaveProfileChanges = 'profile_save_changes',
  TransitionToLessons = 'transition_to_lessons',
  TransitionToStudents = 'transition_to_students',
  TransitionToResults = 'transition_to_results',
  TransitionToProfessions = 'transition_to_professions',
  ClassTransition = 'class_transition',
  TeacherRegistrationEnterButtonClick = 'teacher_registration_enter_button_click',
  TeacherRegistrationConfirmButtonClick = 'teacher_registration_confirm_button_click',
  TeacherRegistrationPasswordValidation = 'teacher_registration_password_validation',
  TeacherRegistrationNotMeButtonClick = 'teacher_registration_not_me_button_click',
  TeacherRegistrationPasswordVisibilityButtonClick = 'teacher_registration_password_visibility_button_click',
  TeacherRegistrationAnotherNumberButtonClick = 'teacher_registration_another_number_button_click',
  ParentRegistrationPageTransition = 'parent_registration_page_transition',
  ParentRegistrationHelloContinueButtonClick = 'parent_registration_hellow_continue_button_click',
  ParentRegistrationParentDetailsContinueButtonClick = 'parent_registration_parent_details_continue_button_click',
  ParentRegistrationEnterYourTeensDetails = 'parent_registration_enter_your_teens_details',
  ParentRegistrationCheckTheData = 'parent_registration_сheck_the_data',
  ParentRegistrationNewsletterConsentCheckboxClick = 'parent_registration_newsletter_consent_checkbox_click',
  ParentRegistrationHello = 'parent_registration_hellow',
  ParentRegistrationEditButtonClick = 'parent_registration_edit_button_click',
  SpoVo = 'SPO_VO',
  DiagnosticOpen = 'diagnostic_open',
  DiagnosticManyAnswersSelect = 'diagnostic_many_answers_select',
  DiagnosticManyAnswersUnselect = 'diagnostic_many_answers_unselect',
  DiagnosticOpenAnswer = 'diagnostic_open_answer',
  DiagnosticScrollBarAnswer = 'diagnostic_scroll_bar_answer',
  DiagnosticNextButton = 'diagnostic_next_button',
  DiagnosticBackButton = 'diagnostic_back_button',
  DiagnosticCrossButton = 'diagnostic_cross_button',
  DiagnosticDone = 'diagnostic_done',
  DiagnosticProfessionsRedirect = 'diagnostic_professions_redirect',
}
