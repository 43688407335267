import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ClassesFormatTypes } from 'app/shared/enums/courses-types.enum';
import { IFilterClasses } from 'app/shared/interfaces/ifilterclasses.interface';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CoursesFiltersService } from '../courses-filters.service';

export const COURSES_TYPES = [
  { value: 'MasterClass', viewValue: 'Мастер-класс', color: 'purple' },
  { value: 'Excursion', viewValue: 'Экскурсия', color: '' },
  { value: 'ShortCourse', viewValue: 'Краткосрочный курс', color: 'purple' },
  { value: 'LongCourse', viewValue: 'Долгосрочный курс', color: 'orange' },
  { value: 'Action', viewValue: 'Акция', color: '' },
  { value: 'Festival', viewValue: 'Фестиваль', color: '' },
  { value: 'Meeting', viewValue: 'Встреча', color: 'yellow' },
  { value: 'Competition', viewValue: 'Конкурс', color: 'orange' },
  { value: 'Profproba', viewValue: 'Профпроба', color: 'blue' },
  { value: 'OpenDay', viewValue: 'День открытых дверей', color: 'green' },
];

@Component({
  selector: 'prf-courses-types-filter',
  templateUrl: './courses-types-filter.component.html',
  styleUrls: ['./courses-types-filter.component.scss'],
})
export class CoursesTypesFilterComponent implements OnInit, OnDestroy {
  filters: IFilterClasses;
  selectedType: any;
  schoolId: string = '';
  isOpen: boolean = false;
  @ViewChild('typesFilter') typesFilter: ElementRef;
  @ViewChild('typesDrop') typesDrop: ElementRef;

  classesFormatTypes = ClassesFormatTypes;
  classesFormatTypesView: any[] = COURSES_TYPES;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(private filtersService: CoursesFiltersService, private router: Router) {}

  ngOnInit() {
    this.schoolId = localStorage.getItem('schoolId');
    let path = this.router.routerState.snapshot.url;
    if (path === '/events') {
      this.classesFormatTypesView = [
        { value: 'MasterClass', viewValue: 'Мастер-класс', color: 'purple' },
        { value: 'Excursion', viewValue: 'Экскурсия', color: '' },
        { value: 'Action', viewValue: 'Акция', color: '' },
        { value: 'Festival', viewValue: 'Фестиваль', color: '' },
        { value: 'Meeting', viewValue: 'Встреча', color: 'yellow' },
        { value: 'Competition', viewValue: 'Конкурс', color: 'orange' },
        { value: 'Profproba', viewValue: 'Профпроба', color: 'blue' },
        { value: 'OpenDay', viewValue: 'День открытых дверей', color: 'green' },
      ];
    }
    if (path === '/courses') {
      this.classesFormatTypesView = [
        { value: 'ShortCourse', viewValue: 'Краткосрочный курс', color: 'purple' },
        { value: 'LongCourse', viewValue: 'Долгосрочный курс', color: 'orange' },
      ];
    }
    this.filtersService
      .getCoursesFilters()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(filters => {
        this.filters = filters;
        if (filters.classesFormat && filters.classesFormat.length > 0) {
          this.selectedType = this.classesFormatTypesView.find(type => {
            let enumValue = ClassesFormatTypes[filters.classesFormat[0]];
            return type.value === enumValue;
          });
        } else {
          this.selectedType = null;
        }
      });
  }

  setTypeFilter(type: { value: string; viewValue: string; color: string }) {
    let enumIndex: number;
    enumIndex = ClassesFormatTypes[type.value];
    this.filters.classesFormat = enumIndex ? [enumIndex] : null;
    this.isOpen = !this.isOpen;
    this.filtersService.setCoursesFilter(this.filters, this.schoolId);
  }

  deselectTypes() {
    this.filters.classesFormat = [];
    this.filters.institutionId = null;
    this.filtersService.setCoursesFilter(this.filters);
  }

  checkFilterHeader() {
    if (this.selectedType) {
      return this.selectedType.viewValue;
    } else {
      return 'Тип';
    }
    // for multiple selection filter
    // if (this.filters.classesFormat && this.filters.classesFormat.length > 0) {
    //   if (this.filters.classesFormat.length > 1) {
    //     let typeTitles = ['тип', 'типа', 'типов'];
    //     return (
    //       this.filters.classesFormat.length +
    //       ' ' +
    //       this.filtersService.getTitleByNumber(this.filters.classesFormat.length, typeTitles)
    //     );
    //   } else {
    //     let enumIndex = this.filters.classesFormat[0];
    //     let type = this.types.filter(el => el.value === ClassesFormatTypes[enumIndex].toString());
    //     return type[0].viewValue;
    //   }
    // } else {
    //   return 'Тип';
    // }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }

  @HostListener('document:click', ['$event.target'])
  checkClick(target) {
    if (this.typesFilter && !this.typesFilter?.nativeElement.contains(target)) {
      this.isOpen = false;
    }
  }
}
