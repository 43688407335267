import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { routeAnimations } from './core';
import { AppSettingsService } from './shared/services/appsettings.service';
import { YandexMetricsService } from '@profilum-library';

declare let yaCounter32259284: any;
declare let gtag: any;

@Component({
  selector: 'prf-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [routeAnimations],
})
export class AppComponent implements OnInit {
  title = 'Profliner';
  private metrikaInitAttemp = 0;
  private maxMetrikaInitAttemp = 10;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private appSettingsService: AppSettingsService,
    private yandexMetricsService: YandexMetricsService,
  ) {}

  public ngOnInit(): void {
    this.yandexMetricsService.init({ enabled: environment.analytics, ymId: AppSettingsService.settings.yandexMetricCounterNumber });
    this.setLocale().subscribe(() => this.initApp());
  }

  setLocale(): Observable<any> {
    let locale;
    if (environment.eng) {
      locale = 'en';
    } else {
      locale = 'ru';
    }
    if (location.href.includes('kz')) {
      locale = 'kz';
    }
    this.translate.setDefaultLang(locale);
    this.appSettingsService.setDefaultTranslation();
    return this.translate.use(locale);
  }

  initApp() {
    let prevPath = window.location.href;
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      const newPath = window.location.href;
      if (environment.analytics) {
        this.hitNavigateEvent(prevPath, newPath);
      }
      prevPath = newPath;
    });
  }

  private hitNavigateEvent(prevPath, newPath) {
    if (this.metrikaInitAttemp > this.maxMetrikaInitAttemp) {
      return;
    }
    if ((window as any).yaCounter32259284 && (window as any).gtag) {
      this.metrikaInitAttemp = 0;
      yaCounter32259284.hit(newPath, {
        referer: prevPath,
      });
      gtag('event', 'page_view');
    } else {
      this.metrikaInitAttemp++;
      setTimeout(() => {
        this.hitNavigateEvent(prevPath, newPath);
      }, 500);
    }
  }
}
