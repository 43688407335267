<div [style]="stylePosition" class="values-wrapper {{ 'values-wrapper-' + index }} {{ theme }}">
  @if (!SPIDER_CHART_CONFIG.legendValue.onlyColoredDots.includes(theme)) {
    <ng-container *ngTemplateOutlet="defaultTemplate" />
  } @else {
    <ng-container *ngTemplateOutlet="onlyColoredDotsTemplate" />
  }
</div>

<ng-template #defaultTemplate>
  <div class="result-values-wrapper">
    <span *ngIf="additionalChartUnitValue && additionalTheme" class="result-value" [ngClass]="additionalTheme">{{
      additionalChartUnitValue * 100 | roundNumber
    }}</span>
    <span class="result-value" [ngClass]="theme">{{ spiderChartUnitData.value * 100 | roundNumber }}</span>
  </div>
  <div (click)="onTextClick(spiderChartUnitData.axis)" class="text-wrapper">
    <p class="text" [ngClass]="theme">{{ spiderChartUnitData.axis }}</p>
  </div>
</ng-template>

<ng-template #onlyColoredDotsTemplate>
  <div class="result-values-wrapper">
    <span class="result-value" [ngClass]="theme" [ngStyle]="{ 'background-color': spiderChartUnitData.color }"></span>
  </div>
  <div class="text-wrapper">
    <p class="text" [ngClass]="theme">{{ spiderChartUnitData.value * 100 | roundNumber }}</p>
  </div>
</ng-template>
