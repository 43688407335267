import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { IMunicipality } from 'app/shared/interfaces/imunicipality';
import { IRegion } from 'app/shared/interfaces/iregion';
import { of, Subject } from 'rxjs';
import { mergeMap, takeUntil, tap } from 'rxjs/operators';
import { AdminPanelService } from '../../admin-panel.service';
import { MunicipalitiesService } from '../../municipalities/municipalities.service';
import { RegionsService } from '../../regions/regions.service';
import { SystemService } from '../system.service';

interface IListedOption {
  name: string | number;
  data: any;
}

// список регионов с выбором языков
// Kazahstan regionId
const multipleLanguagesRegions: string[] = ['aeba8896-64c1-49c0-9a47-099b6b192a55'];

@Component({
  selector: 'prf-system-dashboard',
  templateUrl: './system-dashboard.component.html',
  styleUrls: ['./system-dashboard.component.scss'],
})
export class SystemDashboardComponent implements OnInit {
  regions: IRegion[] = [];
  listedRegions: IListedOption[] = [];
  generatedRegionReportId: string = '';

  municipalitiesByRegion: IMunicipality[] = [];
  listedMunicipalities: IListedOption[] = [];
  generatedMunicipalityReportId: string = '';

  public form: UntypedFormGroup;

  public dataFetched: boolean = false;

  private ngUnsubscribe$: Subject<any> = new Subject();
  constructor(
    private fb: UntypedFormBuilder,
    private meta: Meta,
    private adminService: AdminPanelService,
    private systemService: SystemService,
    private utilsService: UtilsService,
    private regionsService: RegionsService,
    private municipalitiesService: MunicipalitiesService,
  ) {
    this.meta.updateTag({ name: 'og:title', content: 'Отчеты' });
  }

  ngOnInit() {
    this.initForm();
    this.getData();

    this.form
      .get('municipalityReport')
      .get('region')
      .valueChanges.pipe(
        mergeMap(value => {
          if (value && value.data) {
            let selectedRegion = this.regions.find(region => region.hrid === value.data);
            this.form.get('municipalityReport').get('municipality').reset();

            return this.municipalitiesService.getMunicipalitiesByRegion(selectedRegion.id).pipe(
              tap(municipalities => {
                this.municipalitiesByRegion = municipalities;
                this.listedMunicipalities = [];
                municipalities.forEach(municipality =>
                  this.listedMunicipalities.push({ name: municipality.name, data: municipality.hrid }),
                );
              }),
            );
          } else {
            return of(null);
          }
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  initForm() {
    this.form = this.fb.group({
      regionReport: this.fb.group({
        region: new UntypedFormControl(null),
      }),
      municipalityReport: this.fb.group({
        region: new UntypedFormControl(null),
        municipality: new UntypedFormControl(null),
      }),
    });
  }

  getData() {
    this.dataFetched = false;
    this.regionsService
      .getAllRegions()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(regions => {
        this.regions = regions;
        this.regions.forEach(region =>
          this.listedRegions.push({
            name: region.name,
            data: region.hrid,
          }),
        );

        this.dataFetched = true;
      });
  }

  generateRegionReport() {
    let selectedRegion = this.regions.find(region => region.name === this.form.get('regionReport').get('region').value.name);
    if (selectedRegion) {
      this.form.get('regionReport').get('region').disable();
      this.systemService
        .calculatePartialRegioniReport(selectedRegion.id)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(
          res => {
            this.form.get('regionReport').get('region').enable();
            this.form.get('regionReport').get('region').setValue(null);
            this.generatedRegionReportId = res;
            this.utilsService.openSnackBar('Отчет сгенерирован', 'success');
          },
          err => {
            this.form.get('regionReport').get('region').enable();
            this.form.get('regionReport').get('region').setValue(null);
            this.generatedRegionReportId = '';
            this.utilsService.openSnackBar('Произошла ошибка', 'error');
          },
        );
    }
  }

  generateMunicipalityReport() {
    let selectedRegion = this.regions.find(region => region.name === this.form.get('municipalityReport').get('region').value.name);
    let selectedMunicipality = this.municipalitiesByRegion.find(
      municipality => municipality.name === this.form.get('municipalityReport').get('municipality').value.name,
    );
    if (selectedMunicipality) {
      this.form.get('municipalityReport').get('municipality').disable();
      this.systemService
        .calculatePartialMunicipalityiReport(selectedRegion.id, selectedMunicipality.id)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(
          res => {
            this.form.get('municipalityReport').get('municipality').enable();
            this.form.get('municipalityReport').get('region').setValue(null);
            this.form.get('municipalityReport').get('municipality').setValue(null);
            this.generatedMunicipalityReportId = res;
            this.utilsService.openSnackBar('Отчет сгенерирован', 'success');
          },
          err => {
            this.form.get('municipalityReport').get('municipality').enable();
            this.form.get('municipalityReport').get('region').setValue(null);
            this.form.get('municipalityReport').get('municipality').setValue(null);
            this.generatedMunicipalityReportId = '';
            this.utilsService.openSnackBar('Произошла ошибка', 'error');
          },
        );
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
