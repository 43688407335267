import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from '../../api-services';
import { AppSettingsService, AuthHandlerService } from '../../utils-services';

@Injectable()
export class MainGuard {
  constructor(
    private router: Router,
    private http: HttpService,
    public route: ActivatedRoute,
    private appSettings: AppSettingsService,
    private authHandlerService: AuthHandlerService,
  ) {}

  canActivate(): void {
    console.log('main guard');
    this.authHandlerService.checkCookie();
  }
}
