import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lowercaseFirst',
})
export class LowercaseFirstPipe implements PipeTransform {
  transform(text: string) {
    return text.charAt(0).toLowerCase() + text.slice(1);
  }
}
