import { Injectable } from '@angular/core';

import { SPIDER_CHART_CONFIG } from './spider-chart-custom.config';
import {
  ISpiderChartConfig,
  ISpiderChartUnit,
  SpiderChartFiguresEnum,
  SpiderChartPositioningTypeEnum,
} from './spider-chart-custom.model';
import { IPathData, ISpiderChartLayoutConfig } from './spider-chart-layout/spider-chart-layout.model';
import { Path, Vector } from './utils';

@Injectable({
  providedIn: 'root',
})
export class SpiderChartService {
  private SPIDER_CHART_CONFIG: ISpiderChartConfig = SPIDER_CHART_CONFIG;

  public getPolygonPoints(
    spiderChartUnits: ISpiderChartUnit[],
    spiderSVGHeight: number,
    spiderSVGWidth: number,
    figureType: SpiderChartFiguresEnum,
  ): string {
    if (!spiderChartUnits.length) return '';

    const halfDefaultSize: number = this.SPIDER_CHART_CONFIG.defaultSize / 2;
    const halfSVGHeight: number = spiderSVGHeight / 2;
    const halfSVGWidth: number = spiderSVGWidth / 2;
    const tan30Degrees: number = Math.sqrt(3) / 3;

    if (figureType === SpiderChartFiguresEnum.HEXAGON) {
      const result: string[] = spiderChartUnits.map((spiderChartUnit: ISpiderChartUnit, index: number): string => {
        switch (index) {
          case 0:
            return `${halfDefaultSize} ${halfDefaultSize - halfSVGHeight * spiderChartUnit.value},`;
          case 1:
            return `${halfDefaultSize - halfSVGWidth * spiderChartUnit.value} ${
              halfDefaultSize - tan30Degrees * (halfSVGWidth * spiderChartUnit.value)
            },`;
          case 2:
            return `${halfDefaultSize - halfSVGWidth * spiderChartUnit.value} ${
              halfDefaultSize + tan30Degrees * (halfSVGWidth * spiderChartUnit.value)
            },`;
          case 3:
            return `${halfDefaultSize} ${halfDefaultSize + halfSVGHeight * spiderChartUnit.value},`;
          case 4:
            return `${halfDefaultSize + halfSVGWidth * spiderChartUnit.value} ${
              halfDefaultSize + tan30Degrees * (halfSVGWidth * spiderChartUnit.value)
            },`;
          case 5:
            return `${halfDefaultSize + halfSVGWidth * spiderChartUnit.value} ${
              halfDefaultSize - tan30Degrees * (halfSVGWidth * spiderChartUnit.value)
            }`;
        }
      });

      return result.join('');
    }
  }

  public getDifferentBlocksPosition(
    data: ISpiderChartUnit[][],
    spiderSVGHeight: number,
    spiderSVGWidth: number,
    figureType: SpiderChartFiguresEnum,
  ): string[] {
    const result: string[] = [];
    const halfDefaultSize: number = this.SPIDER_CHART_CONFIG.defaultSize / 2;
    const halfSVGHeight: number = spiderSVGHeight / 2;
    const halfSVGWidth: number = spiderSVGWidth / 2;

    if (figureType === SpiderChartFiguresEnum.HEXAGON) {
      const positions = [
        { top: halfDefaultSize - halfSVGHeight, left: halfDefaultSize },
        { top: halfDefaultSize - halfSVGHeight / 2, left: halfDefaultSize - halfSVGWidth },
        { top: halfDefaultSize + halfSVGHeight / 2, left: halfDefaultSize - halfSVGWidth },
        { top: halfDefaultSize + halfSVGHeight, left: halfDefaultSize },
        { top: halfDefaultSize + halfSVGHeight / 2, left: halfDefaultSize + halfSVGWidth },
        { top: halfDefaultSize - halfSVGHeight / 2, left: halfDefaultSize + halfSVGWidth },
      ];

      data[0].forEach((spiderChartUnit: ISpiderChartUnit, index: number): string => {
        const isDifferentShow: boolean =
          Math.abs(spiderChartUnit.value - data[1][index]?.value) * 100 > this.SPIDER_CHART_CONFIG.differentPoints.differencePercent;

        if (!isDifferentShow) return;

        result.push(`top: ${positions[index].top}px; left: ${positions[index].left}px;`);
      });
    }

    return result;
  }

  public getResultsBlocksPosition(
    spiderChartUnits: ISpiderChartUnit[],
    spiderSVGHeight: number,
    spiderSVGWidth: number,
    figureType: SpiderChartFiguresEnum,
    positioningType: SpiderChartPositioningTypeEnum,
  ): string[] {
    const halfDefaultSize: number = this.SPIDER_CHART_CONFIG.defaultSize / 2;
    const halfSVGHeight: number = spiderSVGHeight / 2;
    const halfSVGWidth: number = spiderSVGWidth / 2;
    const offset: number = this.SPIDER_CHART_CONFIG.legendValue.offset[positioningType];

    if (figureType === SpiderChartFiguresEnum.HEXAGON) {
      return spiderChartUnits.map((_spiderChartUnit: ISpiderChartUnit, index: number): string => {
        const transformString = this.SPIDER_CHART_CONFIG.legendValue.transformString[positioningType][index];

        switch (index) {
          case 0:
            return `top: ${halfDefaultSize - halfSVGHeight - offset}px;
          left: ${halfDefaultSize}px; ${transformString};`;
          case 1:
            return `top: ${halfDefaultSize - spiderSVGHeight / 3}px;
          left: ${halfDefaultSize - halfSVGWidth - offset}px; ${transformString};`;
          case 2:
            return `top: ${halfDefaultSize + spiderSVGHeight / 3}px;
          left: ${halfDefaultSize - halfSVGWidth - offset}px; ${transformString};`;
          case 3:
            return `top: ${halfDefaultSize + halfSVGHeight + offset}px;
          left: ${halfDefaultSize}px; ${transformString};`;
          case 4:
            return `top: ${halfDefaultSize + spiderSVGHeight / 3}px;
          left: ${halfDefaultSize + halfSVGWidth + offset}px; ${transformString};`;
          case 5:
            return `top: ${halfDefaultSize - spiderSVGHeight / 3}px;
          left: ${halfDefaultSize + halfSVGWidth + offset}px; ${transformString};`;
        }
      });
    }
  }

  public getLayoutConfig({ width, height }: ISpiderChartLayoutConfig): IPathData {
    const a = new Vector(width / 2, 0);
    const b = new Vector(width, height / 4);
    const c = new Vector(width, (height * 3) / 4);
    const d = new Vector(width / 2, height);
    const e = new Vector(0, (height * 3) / 4);
    const f = new Vector(0, height / 4);

    return {
      d: new Path([new Path.M(a), new Path.L(b), new Path.L(c), new Path.L(d), new Path.L(e), new Path.L(f), new Path.Z()]).toString(),
      translateString: `translate(${this.SPIDER_CHART_CONFIG.defaultSize / 2 - width / 2}px, ${
        this.SPIDER_CHART_CONFIG.defaultSize / 2 - height / 2
      }px);`,
    };
  }
}
