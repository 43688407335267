import { ChangeDetectorRef, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { IAnswerInfo } from '@profilum-api-services/api-slides/api-slides.interface';
import { SectionTypes } from '@profilum-collections/prf-tests.collections';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { Helper } from '@profilum-helpers/common-helper/helper';
import { ISectionInfo } from '@profilum-interfaces/prf-tests.interface';
import { Observable } from 'rxjs';


@Component({
  template: ``,
  standalone: true,
})
export class TestViewHelperComponent extends UnsubscribeComponent {
  @Input() public answerInfo: IAnswerInfo;
  @Input() public sectionInfo: ISectionInfo;
  @Output() public selectedAnswer: EventEmitter<IAnswerInfo> = new EventEmitter<IAnswerInfo>();
  @Input() public updateSelections?: Observable<string>;
  protected readonly changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);
  protected showTooltip: boolean = false;
  private timerForTooltip: number;

  public static getMiddleElement(allElementsCount: number): number {
    return Math.ceil(allElementsCount / 2) - 1;
  }

  public isSelectedBefore(): boolean {
    return this.answerInfo.sectionId && this.answerInfo.sectionId !== this.sectionInfo.sectionId;
  }

  public isSelectedCurrently(): boolean {
    return this.answerInfo.sectionId && this.answerInfo.sectionId === this.sectionInfo.sectionId;
  }

  public isPositive(): boolean {
    if (this.isSelectedBefore()) {
      return this.answerInfo.sectionType === SectionTypes.Positive;
    } else {
      return this.sectionInfo.sectionType === SectionTypes.Positive;
    }
  }

  public selectAnswer(): void {
    if (this.isSelectedBefore()) {
      return;
    }

    if (this.answerInfo.isMainAnswer) {
      this.openTooltip();
    }

    if (!this.answerInfo.sectionId) {
      this.answerInfo.sectionId = this.sectionInfo.sectionId;
      this.answerInfo.sectionType = this.sectionInfo.sectionType;
    } else {
      this.answerInfo.sectionId = null;
      this.answerInfo.sectionType = null;
    }

    this.selectedAnswer.emit(this.answerInfo);
    this.changeDetectorRef.detectChanges();
  }

  protected init(): void {
    if (!Helper.isDefined(this.answerInfo) && !Helper.isDefined(this.sectionInfo)) {
      return;
    }

    this.updateSelections?.pipe(this.unsubscribeOperator).subscribe((answerId: string) => {
      if (answerId === this.answerInfo.answerId) {
        if (this.answerInfo.isMainAnswer) {
          this.openTooltip();
        }

        this.changeDetectorRef.detectChanges();
      }
    });
  }

  protected selectAnswerFromStorage(): void {
    if (!this.isSelectedBefore() && this.isSelectedCurrently()) {
      this.answerInfo.sectionId = this.sectionInfo.sectionId;
      this.answerInfo.sectionType = this.sectionInfo.sectionType;
      this.selectedAnswer.emit(this.answerInfo);
    }
  }

  public openTooltip(): void {
    window.clearTimeout(this.timerForTooltip);
    this.showTooltip = true;

    this.timer = this.timerForTooltip = window.setTimeout(() => {
      this.showTooltip = false;
      this.changeDetectorRef.detectChanges();
    }, 10000);
  }
}
