import { IProfession, ProfessionTryingOnStage } from '../api-professions';
import { IHttpStatus } from '../http/http.interface';

export interface IGetTryingOnQueryParams {
  professionTryingOnStage: ProfessionTryingOnStage;
}

export interface IGetProfessionsForFilteringResponse extends IHttpStatus {
  professionsForFiltering: IProfession[];
}

export interface IGetResultsResponse extends IHttpStatus {
  tryingOnResults: ITryingOnResult[];
}

export interface ISetTryingOnAnswersRequest {
  tryingOnAnswers: ITryingOnAnswer[];
}

export interface ISetTryingOnAnswersResponse extends IHttpStatus {}

export interface ITryingOnResult {
  id: string;
  userId: string;
  createdDate: string;
  profession: IProfession;
  order: number;
  professionTryingOnStage: typeof ProfessionTryingOnStage;
}

export interface ITryingOnAnswer {
  professionId: string;
  professionTryingOnStage: ProfessionTryingOnStage;
  order?: number;
}
