<div class="class-loader-container">
  <div class="class-loader">
    <content-loader [rtl] [height]="84" [width]="950" [speed]="2" [primaryColor]="'#f3f3f3'" [secondaryColor]="'#ecebeb'">
      <svg:rect width="305" height="8" rx="4" x="201" y="23" ry="3" />
      <svg:circle cx="135" cy="40" r="30" />
      <svg:rect width="115" height="7" rx="3.5" x="201" y="50" ry="3" />
      <svg:circle cx="810" cy="38" r="22" />
      <svg:circle cx="870" cy="38" r="22" />
      <svg:rect x="42" y="28" width="33" height="16" rx="3" />
    </content-loader>
  </div>
  <div class="class-loader">
    <content-loader [rtl] [height]="84" [width]="950" [speed]="2" [primaryColor]="'#f3f3f3'" [secondaryColor]="'#ecebeb'">
      <svg:rect width="305" height="8" rx="4" x="201" y="23" ry="3" />
      <svg:circle cx="135" cy="40" r="30" />
      <svg:rect width="115" height="7" rx="3.5" x="201" y="50" ry="3" />
      <svg:circle cx="810" cy="38" r="22" />
      <svg:circle cx="870" cy="38" r="22" />
      <svg:rect x="42" y="28" width="33" height="16" rx="3" />
    </content-loader>
  </div>
  <div class="class-loader">
    <content-loader [rtl] [height]="84" [width]="950" [speed]="2" [primaryColor]="'#f3f3f3'" [secondaryColor]="'#ecebeb'">
      <svg:rect width="305" height="8" rx="4" x="201" y="23" ry="3" />
      <svg:circle cx="135" cy="40" r="30" />
      <svg:rect width="115" height="7" rx="3.5" x="201" y="50" ry="3" />
      <svg:circle cx="810" cy="38" r="22" />
      <svg:circle cx="870" cy="38" r="22" />
      <svg:rect x="42" y="28" width="33" height="16" rx="3" />
    </content-loader>
  </div>
</div>
