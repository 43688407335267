import { Component, OnInit } from '@angular/core';
import { ContactPhoneString } from 'app/shared/global-constants/ui-constants';
import { AppSettingsService } from '../../services/appsettings.service';

@Component({
  selector: 'prf-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss'],
})
export class PageFooterComponent implements OnInit {
  public yearNumber: number = null;
  public activeLocation = 'base';

  nowDate = new Date();
  public contactPhone = ContactPhoneString;

  constructor(private appSettings: AppSettingsService) {}

  ngOnInit() {
    this.activeLocation = this.appSettings.currentLocation;
    this.yearNumber = new Date().getFullYear();
  }

  get getTelString() {
    return this.contactPhone?.replace(/\D/g, '');
  }
}
