import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import {
  IFilterInternships,
  InternshipsFiltersService,
} from 'app/pages/catalog/internship-page/internship-catalog/internship-filter/internships-filter.service';
import { InternshipsService } from '../../../../internships.service';

@Component({
  selector: 'prf-select-talents',
  templateUrl: './select-talents.component.html',
  styleUrls: ['./select-talents.component.scss'],
})
export class SelectTalentsComponent implements OnInit, OnDestroy {
  talents: any;
  selectedTalents: any = [];
  filters: IFilterInternships;
  subscription: any;
  searchTalents: string;
  @ViewChild('ddTalents') ddTalents: ElementRef;
  @ViewChild('talentsDrop') dropdown: ElementRef;
  @Output() dataSelectTalents = new EventEmitter();

  @Input()
  set existingTalents(val: any) {
    this.filters = {};
    this.filters.TalentsName = val;
    this.filtersService.setTalentsFilter(this.filters);
  }

  constructor(private filtersService: InternshipsFiltersService, private internshipsService: InternshipsService) {}

  ngOnInit() {
    this.subscription = this.filtersService.getTalentsFilters().subscribe(data => {
      this.filters = data;
      this.selectedTalents = data.TalentsName ? data.TalentsName : [];
      this.dataSelectTalents.emit(this.filters.TalentsName);
    });

    let subscribe = this.internshipsService.getTalents().subscribe(data => {
      this.talents = data;
      subscribe.unsubscribe();
    });
  }

  selectTalent(name: string) {
    if (!this.filters.TalentsName) {
      this.filters.TalentsName = [name];
    } else {
      let index = this.filters.TalentsName.indexOf(name);
      index !== -1 ? this.filters.TalentsName.splice(index, 1) : this.filters.TalentsName.push(name);
    }
    this.dataSelectTalents.emit(this.filters.TalentsName);
    this.filtersService.setTalentsFilter(this.filters);
  }

  deselectTalents() {
    this.filters.TalentsName = null;
    this.filtersService.setTalentsFilter(this.filters);
  }

  checkFilterHeader() {
    if (this.filters.TalentsName && this.filters.TalentsName.length > 0) {
      if (this.filters.TalentsName.length > 1 && this.filters.TalentsName.length < 5) {
        return this.filters.TalentsName.length + ' таланта';
      } else if (this.filters.TalentsName.length >= 5) {
        return this.filters.TalentsName.length + ' талантов';
      } else {
        return this.filters.TalentsName[0];
      }
    } else {
      return 'Таланты';
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @HostListener('document:click', ['$event.target'])
  checkClick(target) {
    if (this.ddTalents && !this.ddTalents?.nativeElement.contains(target)) {
      this.dropdown.nativeElement.classList.remove('w--open');
    }
  }
}
