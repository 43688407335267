import { Component, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { EventEmitter } from 'events';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { InternshipsService } from '../../../internships.service';

@Component({
  selector: 'prf-search-internships',
  templateUrl: './search-internships.component.html',
  styleUrls: ['./search-internships.component.scss'],
})
export class SearchInternshipsComponent implements OnInit {
  public searchField: UntypedFormControl;
  public searches: any;
  public empty: boolean = false;
  @Output() searchesData = new EventEmitter();

  constructor(private internshipsService: InternshipsService) {}

  ngOnInit() {
    this.searchField = new UntypedFormControl();
    this.searchField.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(term => {
      if (term.length != 0) {
        this.internshipsService.getInternshipsQuery(term).subscribe(search => {
          this.searches = [];
          search.forEach(item => {
            this.searches.push(item);
            this.searchesData.emit(this.searches);
            if (this.searches.length == 0) {
              this.empty = true;
            }
          });
        });
      } else {
        this.searches = [];
      }
    });
  }

  toClearSearch() {
    this.searchField.setValue('');
  }
}
