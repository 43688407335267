import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'prf-bubble-box-container',
  templateUrl: './bubble-box-container.component.html',
  styleUrls: ['./bubble-box-container.component.scss'],
})
export class BubbleBoxContainerComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
