import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SeamlessentryComponent } from './seamlessentry.component';

const routes: Routes = [{ path: 'seamlessentry', component: SeamlessentryComponent, pathMatch: 'full' }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SeamlessEntryRoutingModule {}
