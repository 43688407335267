import { ProductTypes } from './api-favorites.collections';

export interface unused {
  regionId: string;
}

export interface IFavoriteProfessionInterface {
  productId: string;
  productType: ProductTypes;
  userId: string;
  id?: string;
  createdDate?: Date;
  productName?: string;
}
