import { Component, Input, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { take } from 'rxjs/operators';
import { AdminPanelService } from '../../../pages/control-panel/admin/admin-panel.service';

@Component({
  selector: 'prf-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements OnInit {
  news: any = [];
  @Input() schools;

  constructor(private meta: Meta, private adminPanelService: AdminPanelService) {
    this.meta.updateTag({ name: 'og:title', content: 'Новости' });
  }

  ngOnInit() {
    this.adminPanelService
      .getNews('0', '10', '')
      .pipe(take(1))
      .subscribe(res => {
        this.news = [];
        res.forEach(item => {
          this.news.push(item);
        });
      });
  }
}
