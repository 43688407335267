export interface ISortedItem {
  name: string;
  key?: string;
  isActive: boolean;
  sortType: string;
  data: {
    key: string;
    sortType: string;
  };
}
