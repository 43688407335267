import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { take } from 'rxjs/operators';
import { CoursesService } from '../courses.service';

@Component({
  selector: 'prf-elastic-courses',
  templateUrl: './elastic-courses.component.html',
  styleUrls: ['./elastic-courses.component.scss'],
})
export class ElasticCoursesComponent implements OnInit {
  public ActiveArchive: number = 1;

  constructor(private meta: Meta, private coursesService: CoursesService, private utilsService: UtilsService) {
    this.meta.updateTag({ name: 'og:title', content: 'Поисковый индекс' });
  }

  ngOnInit() {}

  //синхронизировать монго и эластик
  reloadClasses() {
    this.coursesService
      .reloadClasses()
      .pipe(take(1))
      .subscribe(res => {
        if (res.status != 'Success') {
          this.utilsService.openSnackBar(`Произошла ошибка синхронизации: ${res.comment}`, 'error');
        } else {
          this.utilsService.openSnackBar('Базы синхронизированы успешно', 'success');
        }
      });
  }
}
