import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'prf-custom-check-box',
  templateUrl: './custom-check-box.component.html',
  styleUrls: ['./custom-check-box.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomCheckBoxComponent),
      multi: true,
    },
  ],
})
export class CustomCheckBoxComponent implements ControlValueAccessor {
  public _check: boolean;

  propagateChange = (check: boolean) => {};
  propagateTouch = (check: boolean) => {};

  writeValue(check: any): void {
    this.currentCheck = check;
  }

  get currentCheck() {
    return this._check;
  }

  set currentCheck(check: boolean) {
    this._check = check;
    this.propagateChange(check);
    this.propagateTouch(check);
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  toggleCheck(check) {
    this.currentCheck = check;
  }
}
