import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';
import { Page404RoutingModule } from './page-404-routing.module';
import { Page404Component } from './page-404.component';

@NgModule({
  declarations: [Page404Component],
  imports: [CommonModule, SharedModule, Page404RoutingModule],
})
export class Page404Module {}
