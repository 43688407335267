import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpiderChartCustomService {
  public show$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public url: string = '';

  public spiderChartDescription: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

}
