import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { IMunicipality } from 'app/shared/interfaces/imunicipality';
import { IRegion } from 'app/shared/interfaces/iregion';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ICity } from '../../../../../shared/interfaces/icity';
import { MunicipalitiesService } from '../../municipalities/municipalities.service';
import { RegionsService } from '../../regions/regions.service';
import { CitiesService } from '../cities.service';

@Component({
  selector: 'prf-add-city',
  templateUrl: './add-city.component.html',
  styleUrls: ['./add-city.component.scss'],
})
export class AddCityComponent implements OnInit {
  @Input() regions: IRegion[];
  @Output() cityAdded = new EventEmitter<ICity>();

  public municipalitiesByRegion: IMunicipality[];

  public selectedRegion: IRegion;
  public selectedMunicipality: IMunicipality = {
    id: '',
    hrid: '',
    name: '',
    regionId: '',
  };

  public city: ICity = {
    id: '',
    name: '',
    hrid: '',
    region: '',
    regionId: '',
    municipality: '',
    municipalityId: '',
  };

  public dialogOpen: boolean = false;

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private citiesService: CitiesService,
    private regionsService: RegionsService,
    private municipalitiesService: MunicipalitiesService,
    private utilsService: UtilsService,
  ) {}

  ngOnInit() {}

  onSubmit(valid: boolean) {
    if (valid && this.isValidData(this.city, this.selectedMunicipality, this.selectedRegion)) {
      const cityData: ICity = {
        id: null,
        hrid: this.city.hrid,
        name: this.city.name,
        region: this.selectedRegion.name,
        regionId: this.selectedRegion.id,
        municipality: this.selectedMunicipality.name,
        municipalityId: this.selectedMunicipality.id,
      };

      this.citiesService
        .createCity(cityData)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(
          cityId => {
            if (cityId) {
              this.utilsService.openSnackBar('👌 Город добавлен', 'success');
              return setTimeout(() => {
                cityData.id = cityId;
                this.cityAdded.emit(cityData);
                this.showAddDialog();
              }, 300);
            }
          },
          err => {
            this.utilsService.openSnackBar('👎 Произошла ошибка, попробуйте позже', 'error');
          },
        );
    } else {
      return;
    }
    this.clearCurrentRegion();
    this.clearCurrentMunicipality();
  }

  private isValidData(city: ICity, municipality: IMunicipality, region: IRegion): boolean {
    return city && city.hrid && city.name && municipality && municipality.id && municipality.name && region && region.id && region.name
      ? true
      : false;
  }

  setRegion(region: IRegion) {
    if (region) {
      this.clearCurrentMunicipality();
      this.municipalitiesService
        .getMunicipalitiesByRegion(region.id)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(municipalities => {
          this.municipalitiesByRegion = municipalities.sort((r1, r2) => r1.name.localeCompare(r2.name));
        });
    }
  }

  private clearCurrentRegion(): void {
    this.selectedRegion = {
      id: '',
      hrid: '',
      name: '',
    };
  }

  private clearCurrentMunicipality(): void {
    this.selectedMunicipality = {
      id: '',
      hrid: '',
      name: '',
      regionId: '',
    };
  }

  public showAddDialog(): void {
    this.dialogOpen = !this.dialogOpen;
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
