import { inject, Injectable } from '@angular/core';
import { HttpService } from '../http';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  IDeleteAllDataRequest,
  IDeleteAllDataResponse,
  IDeleteValueRequest,
  IDeleteValueResponse,
  IGetAllDataResponse,
  IGetValueResponse,
  IUpdateValueRequest,
  IUpdateValueResponse,
} from './api-user-storage.interface';
import { ApiUserStorageUrls } from './api-user-storage.collections';

@Injectable({
  providedIn: 'root',
})
export class ApiUserStorageService {
  private readonly httpService: HttpService = inject(HttpService);

  public getValue(userId: string, key: string): Observable<IGetValueResponse> {
    return this.httpService.get<IGetValueResponse>(ApiUserStorageUrls.GetValue, { userId, key }).pipe(take(1));
  }

  public getAllData(userId: string): Observable<IGetAllDataResponse> {
    return this.httpService.get<IGetAllDataResponse>(ApiUserStorageUrls.GetAllData, { userId }).pipe(take(1));
  }

  public updateValue(data: IUpdateValueRequest): Observable<IUpdateValueResponse> {
    return this.httpService.post<IUpdateValueResponse>(ApiUserStorageUrls.UpdateValue, data).pipe(take(1));
  }

  public deleteValue(data: IDeleteValueRequest): Observable<IDeleteValueResponse> {
    return this.httpService.delete<IDeleteValueResponse>(ApiUserStorageUrls.DeleteValue, null, data).pipe(take(1));
  }

  public deleteAllData(data: IDeleteAllDataRequest): Observable<IDeleteAllDataResponse> {
    return this.httpService.delete<IDeleteAllDataResponse>(ApiUserStorageUrls.DeleteAllData, null, data).pipe(take(1));
  }
}
