export enum BreakpointLabels {
  Desktop = 'break-desktop',
  Tablet = 'break-tablet',
  Mobile = 'break-mobile',
}

export const Breakpoints: Map<string, { min?: string; max?: string }> = new Map<string, { min?: string; max?: string }>([
  [BreakpointLabels.Mobile, { max: '768px' }],
  [BreakpointLabels.Tablet, { min: '769px', max: '1024px' }],
  [BreakpointLabels.Desktop, { min: '1025px' }],
]);
