import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AdminPanelService } from 'app/pages/control-panel/admin/admin-panel.service';
import { MunicipalitiesService } from 'app/pages/control-panel/admin/municipalities/municipalities.service';
import { RegionsService } from 'app/pages/control-panel/admin/regions/regions.service';
import {
  CoursesFiltersService,
} from 'app/shared/dashboard/courses/courses-catalog/courses-filter/courses-filters.service';
import { AdminAccessLevel } from 'app/shared/enums/admins.enums';
import { IFilterClasses } from 'app/shared/interfaces/ifilterclasses.interface';
import { IMunicipality } from 'app/shared/interfaces/imunicipality';
import { IRegion } from 'app/shared/interfaces/iregion';
import { Observable, of, Subject } from 'rxjs';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'prf-courses-filter-territory',
  templateUrl: './courses-filter-territory.component.html',
  styleUrls: ['./courses-filter-territory.component.scss'],
})
export class CoursesFilterTerritoryComponent implements OnInit {
  filters: IFilterClasses = {};
  // types = COURSES_TYPES_DATA;

  //объекты территориальной привязки
  public allRegions: IRegion[] = [];
  public selectedRegion: IRegion = {
    id: '',
    hrid: '',
    name: '',
  };
  public defaultRegion: string = '00000000-0000-0000-0000-000000000000';
  public municipalitiesByRegion: IMunicipality[] = [];
  public selectedMunicipality: IMunicipality = {
    id: '',
    hrid: '',
    name: '',
    regionId: '',
  };
  public defaultMunicipality: string = '00000000-0000-0000-0000-000000000000';

  userRegionId: string = '';
  userMunicipalityId: string = '';

  dataLoaded: boolean = false;
  adminLevel: any;
  regionDisabled: boolean = false;
  municipalityDisabled: boolean = false;

  regionFilterHeader: string = '';

  municipalityOpen: boolean = false;
  regionOpen: boolean = false;

  classes: any = [];
  selectType: string;
  @ViewChild('regionFilter') regionFilter: ElementRef;
  @ViewChild('municipalityFilter') municipalityFilter: ElementRef;

  public ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private filtersService: CoursesFiltersService,
    private regionsService: RegionsService,
    private municipalitiesService: MunicipalitiesService,
    private adminPanelService: AdminPanelService,
    private translateService: TranslateService,
  ) {
    this.getTranslation('SHARED.REGION')
      .pipe(take(1))
      .subscribe(translation => (this.regionFilterHeader = translation));
  }

  ngOnInit() {
    this.adminLevel = localStorage.getItem('adminLevel');
    this.loadTerritory();
  }

  setRegionFilter(region: any) {
    if (region != this.selectedRegion) {
      this.selectedRegion = region;
      this.filters.regionId = region.id;
      this.filters.municipalityId = null;
      this.deselectMunicipality();
      //записываем дефолтный муниципалитет при выборе дефолтного региона
      this.filters.regionId === this.defaultRegion ? (this.filters.municipalityId = this.defaultMunicipality) : null;
      this.filtersService.setCoursesFilter(this.filters);
    }
    this.regionOpen = false;
    this.municipalitiesService
      .getMunicipalitiesByRegion(this.selectedRegion.id)
      .pipe(
        tap(municipalities => {
          this.municipalitiesByRegion = municipalities;
          this.selectedMunicipality = {
            id: '',
            hrid: '',
            name: '',
            regionId: '',
          };
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe();
  }

  setMunicipalityFilter(municipality: any) {
    this.selectedMunicipality = municipality;
    this.filters.municipalityId = municipality.id;
    this.filtersService.setCoursesFilter(this.filters);
    this.municipalityOpen = false;
  }

  public deselectRegion() {
    this.selectedRegion = {
      id: '',
      hrid: '',
      name: '',
    };
    this.selectedMunicipality = { id: '', hrid: '', name: '', regionId: '' };
    this.municipalitiesByRegion = [];
    this.filters.regionId = null;
    this.filters.municipalityId = null;
    this.filtersService.setCoursesFilter(this.filters);
  }

  public deselectMunicipality() {
    this.selectedMunicipality = { id: '', hrid: '', name: '', regionId: '' };
    this.filters.municipalityId = null;
    this.municipalityDisabled = false;
    this.filtersService.setCoursesFilter(this.filters);
  }

  loadTerritory() {
    this.dataLoaded = false;
    this.userRegionId = localStorage.getItem('regionId');
    this.userMunicipalityId = localStorage.getItem('municipalityId');

    this.regionsService
      .getAllRegions()
      .pipe(
        switchMap(regions => {
          this.allRegions = regions;

          let currentObservable$: Observable<any> = of(null);

          switch (this.adminLevel) {
            case AdminAccessLevel.GLOBAL:
              {
                // переименовываем дефолтный регион
                this.allRegions = regions.map(r => {
                  r.id === this.defaultRegion ? (r['name'] = 'Дефолтный регион') : null;
                  return r;
                });
              }
              break;
            case AdminAccessLevel.REGION:
              {
                this.filters.regionId = this.userRegionId;
                this.selectedRegion = regions.find(r => r.id === this.userRegionId);
                this.regionDisabled = true;

                currentObservable$ = this.municipalitiesService.getMunicipalitiesByRegion(this.selectedRegion.id).pipe(
                  tap(municipalities => {
                    this.municipalitiesByRegion = municipalities;
                    this.selectedMunicipality = {
                      id: '',
                      hrid: '',
                      name: '',
                      regionId: '',
                    };
                  }),
                );
              }
              break;
            case AdminAccessLevel.MUNICIPALITY:
              {
                this.filters.regionId = this.userRegionId;
                this.selectedRegion = regions.find(r => r.id === this.userRegionId);
                this.regionDisabled = true;

                currentObservable$ = this.municipalitiesService.getMunicipalitiesByRegion(this.selectedRegion.id).pipe(
                  tap(municipalities => {
                    this.municipalitiesByRegion = municipalities;
                    this.filters.municipalityId = this.userMunicipalityId;
                    this.selectedMunicipality = municipalities.find(r => r.id === this.userMunicipalityId);
                    this.municipalityDisabled = true;
                  }),
                );
              }
              break;

            default:
              currentObservable$ = of(null);
          }
          return currentObservable$;
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(_ => (this.dataLoaded = true));
  }

  public checkRegionFilterHeader() {
    if (this.filters.regionId) {
      let regions = this.allRegions.filter(el => el.id === this.selectedRegion.id);
      return regions[0].name;
    } else {
      return this.regionFilterHeader;
    }
  }

  getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }

  public checkMunicipalityFilterHeader() {
    if (this.filters.municipalityId) {
      let municipalities = this.municipalitiesByRegion.filter(el => el.id === this.selectedMunicipality.id);
      return municipalities[0].name;
    } else {
      return 'Муниципалитет';
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }

  @HostListener('document:click', ['$event.target'])
  checkClick(target) {
    if (this.regionFilter && !this.regionFilter?.nativeElement.contains(target)) {
      this.regionOpen = false;
    }
    if (this.municipalityFilter && !this.municipalityFilter?.nativeElement.contains(target)) {
      this.municipalityOpen = false;
    }
  }
}
