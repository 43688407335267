<a class="btn-prf-outline" (click)="addCenter()">{{ 'SHARED.ADD_CENTER' | translate }}</a>

<div class="editors-modal" *ngIf="dialogOpen" [ngClass]="dialogOpen ? 'active-modal' : ''">
  <div class="editors-modal-wrapper">
    <div class="editors-modal-body">
      <div class="editors-modal-dialog">
        <div class="editors-modal-dialog-body">
          <p (click)="dialogOpen = !dialogOpen">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 35 35">
              <g fill-rule="evenodd" stroke-linecap="square">
                <path d="M9.192 9.192l18.385 18.385M27.577 9.192L9.192 27.577"></path>
              </g>
            </svg>
          </p>
          <form novalidate #f="ngForm" (ngSubmit)="onSubmit(f)">
            <mat-form-field class="full-width">
              <input matInput id="name" [(ngModel)]="institution.name" name="name" #classname="ngModel" placeholder="Название" required />
            </mat-form-field>

            <mat-form-field class="full-width">
              <input matInput id="hrid" [(ngModel)]="institution.hrid" name="hrid" #classhrid="ngModel" placeholder="hrid" required />
            </mat-form-field>

            <mat-form-field class="full-width">
              <input
                matInput
                id="description"
                [(ngModel)]="institution.description"
                name="description"
                #classdescription="ngModel"
                placeholder="Описание"
              />
            </mat-form-field>

            <mat-form-field class="full-width">
              <input
                matInput
                id="address"
                [(ngModel)]="institution.address"
                name="address"
                #classdescription="ngModel"
                placeholder="Адрес"
                required
              />
            </mat-form-field>

            <mat-form-field class="full-width">
              <input matInput id="email" [(ngModel)]="institution.email" name="email" #classemail="ngModel" placeholder="Email" />
            </mat-form-field>

            <mat-form-field class="full-width">
              <mat-select placeholder="Категория" #category [(ngModel)]="institution.category" name="category">
                <mat-option *ngFor="let category of institutionCategories" [value]="category.value">
                  {{ category.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="full-width">
              <mat-select
                name="region"
                [(ngModel)]="selectedRegion"
                placeholder="{{ 'SHARED.REGION' | translate }}"
                [disabled]="hasRegion"
                required
              >
                <mat-option *ngFor="let region of regions" [value]="region" (click)="selectRegion(region)">
                  {{ region.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="full-width" *ngIf="selectedRegion.id">
              <mat-select placeholder="Муниципалитет" [(ngModel)]="selectedMunicipality" [disabled]="hasMunicipality" name="municipality">
                <mat-option *ngFor="let municipality of municipalities" [value]="municipality" (click)="setMunicipality(municipality)">
                  {{ municipality.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="full-width">
              <input
                matInput
                id="additionalInformation"
                [(ngModel)]="institution.additionalInformation"
                name="additionalInformation"
                #classadditionalInformation="ngModel"
                placeholder="Дополнительная информация"
              />
            </mat-form-field>

            <mat-form-field class="full-width">
              <input matInput id="url" [(ngModel)]="institution.url" name="url" #classurl="ngModel" placeholder="Ссылка на сайт" />
            </mat-form-field>

            <button type="submit" mat-raised-button>{{ 'SHARED.ADD_CENTER' | translate }}</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
