import { Component, Input, OnInit } from '@angular/core';
import { INews } from '../../../interfaces/inews.interface';
import { AdminPanelService } from '../../../../pages/control-panel/admin/admin-panel.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'prf-add-news',
  templateUrl: './add-news.component.html',
  styleUrls: ['./add-news.component.scss'],
})
export class AddNewsComponent implements OnInit {
  error: boolean = false;
  @Input() schools;
  _selectedFiles: File;
  //   iconTypes: any = [
  //     {
  //       value: 'Hearth',
  //       viewValue: 'Сердце'
  //     },
  //     {
  //       value: 'Phone',
  //       viewValue: 'Телефон'
  //     },
  //     {
  //       value: 'CheckMark',
  //       viewValue: 'Галочка'
  //     },
  //     {
  //       value: 'Target',
  //       viewValue: 'Мишень'
  //     },
  //     {
  //       value: 'Percent',
  //       viewValue: 'Процент'
  //     }
  // ];
  news: INews = {
    imageURL: null,
    title: 'Заголовок',
    description: 'Описание',
    groupId: 'groupId',
    eventType: 'тип новости',
  };

  constructor(private adminPanelService: AdminPanelService) {}

  ngOnInit() {
    let globalNewsTarget = {
      id: null,
      value: 'Для всех',
      viewValue: 'Для всех',
    };
    this.schools.unshift(globalNewsTarget);
  }

  public changeSelected(data) {
    this.news.groupId = this.schools.find(s => s.value == data).id;
    if (this.news.groupId == null) {
      this.news.eventType = 'GlobalNews';
    } else {
      this.news.eventType = 'SchoolNews';
    }
  }

  public changeIconSelect(data) {
    this.news.buttons[0].iconType = data;
  }

  selectFile(event) {
    if (event.target.files.item(0).type.match('image.*')) {
      this._selectedFiles = event.target.files;
    } else {
      alert('invalid format!');
    }
  }

  upload() {
    this.adminPanelService
      .uploadImage(this._selectedFiles, this.news.title + 'news')
      .pipe(take(1))
      .subscribe(r => {
        this.news.imageURL = r;
      });
  }

  updateUrl() {
    this.news.imageURL = '/staticfiles/content/images/default.png';
  }

  onSubmit(valid: boolean) {
    if (!valid) {
    } else {
      // Add news
      this.adminPanelService.createNews(this.news);
    }
  }
}
