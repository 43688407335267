import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IAnswerInfo, IAnswerInfoMark } from '@profilum-api-services/api-slides/api-slides.interface';
import { RangeValues } from '@profilum-collections/prf-tests.collections';
import { TestViewHelperComponent } from '@profilum-components/prf-tests/test-helper/test-view-helper.component';
import { Helper } from '@profilum-helpers/common-helper/helper';

@Component({
  selector: 'prf-range-view',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './range-view.component.html',
  styleUrls: ['./range-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RangeViewComponent extends TestViewHelperComponent implements OnInit {
  @ViewChild('ranger') public rangeElem: ElementRef;
  @Output() public updateValue: EventEmitter<IAnswerInfo> = new EventEmitter<IAnswerInfo>();
  public rangeValues: typeof RangeValues = RangeValues;
  public currentValue: number;
  protected marksLength: number;

  public ngOnInit(): void {
    if (!Helper.isDefined(this.answerInfo) && !Helper.isDefined(this.sectionInfo)) {
      return;
    }

    this.marksLength = this.answerInfo.marks.length;

    const orderNumber: number = this.answerInfo.marks.findIndex((item: IAnswerInfoMark) => item.answerId === this.answerInfo.answerId);

    if (orderNumber !== -1) {
      this.currentValue = orderNumber;
    }
  }

  public getText(value: number): string {
    return this.answerInfo.marks[value]?.text;
  }

  public decrease(currentValue: string): void {
    const currentValueNumber: number = +currentValue;

    if (!currentValueNumber) {
      return;
    }

    const newValue: number = currentValueNumber - 1;
    this.rangeElem.nativeElement.value = newValue;
    this.changeValue(newValue);
  }

  public increase(currentValue: string): void {
    const currentValueNumber: number = +currentValue;

    if (this.marksLength - 1 === currentValueNumber) {
      return;
    }

    const newValue: number = currentValueNumber + 1;
    this.rangeElem.nativeElement.value = newValue;
    this.changeValue(newValue);
  }

  public changeValue(propValue: number): void {
    const answerId: string = this.answerInfo.marks[propValue].answerId;

    this.answerInfo.selectedAnswerId = answerId;
    this.updateValue.emit({
      middleRangeElement: this.answerInfo.middleRangeElement,
      questionSectionId: this.answerInfo.questionSectionId,
      answerId: answerId,
    });
  }

  public addClass(element: HTMLElement): void {
    element.classList.add('bg-track');
  }

  public removeClass(element: HTMLElement): void {
    element.classList.remove('bg-track');
  }
}
