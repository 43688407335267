export enum ApiPlayerUrls {
  Sessions = '/api/v1.0/player/sessions',
  GetSessionsList = '/api/v1.0/player/sessions/list',
  GetLastSessionId = '/api/v1.0/player/sessions/last',
  RegionTestInfo = '/api/v1.0/player/regiontestinfo',
  RegionTestInfoList = '/api/v1.0/player/regiontestinfo/list',
  GetInformationAboutScreeningTest = '/api/v1.0/player/tests',
  GetTestsList = '/api/v1.0/player/tests/list',
  GetTestSlidesCount = '/api/v1.0/player/tests/gettestslidescount',
  GetTestSlidesCountBySessionId = '/api/v1.0/player/tests/gettestslidescountbysessionid',
  GetTestsByScreenTestIdAndVariantId = '/api/v1.0/player/tests/gettestsbyscreentestidandvariantid',
  GetNextSlide = '/api/v1.0/player/slides/next',
  GetNextSlideAnonymous = '/api/v1.0/player/slides/anonymousnext',
  GetCurrentSlide = '/api/v1.0/player/slides/current',
  GetPreviousSlide = '/api/v1.0/player/slides/previous',
}
