import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';

import { AdminAccessLevel } from 'app/shared/enums/admins.enums';
import { IMunicipality } from 'app/shared/interfaces/imunicipality';
import { IRegion } from 'app/shared/interfaces/iregion';
import { Observable, Subject } from 'rxjs';
import { mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { InstitutionClass } from '../../../../../shared/classes/institution.class';
import { AdminPanelService } from '../../admin-panel.service';
import { MunicipalitiesService } from '../../municipalities/municipalities.service';
import { RegionsService } from '../../regions/regions.service';

@Component({
  selector: 'prf-add-center',
  templateUrl: './add-center.component.html',
  styleUrls: ['./add-center.component.scss'],
})
export class AddCenterComponent implements OnInit {
  institution: InstitutionClass = new InstitutionClass();
  dialogOpen: boolean = false;
  selectedOptions = [];
  selectedFiles: File;
  audiences = [
    { value: 'ZeroPlus', viewValue: '0+' },
    { value: 'FourSix', viewValue: '4-6' },
    { value: 'SevenTen', viewValue: '7-10' },
    { value: 'ElevenThirteen', viewValue: '11-13' },
    { value: 'FourteenSixteen', viewValue: '14-16' },
    { value: 'SixteenPlus', viewValue: '16+' },
  ];
  institutionCategories = [
    { value: 'Commercial', viewValue: 'Коммерческое' },
    { value: 'State', viewValue: 'Государственное' },
  ];
  defaultRegionId: string = '00000000-0000-0000-0000-000000000000';
  adminLevel: any;
  hasRegion: boolean = false;
  hasMunicipality: boolean = false;
  regions: IRegion[];
  selectedRegion: IRegion = {
    id: '',
    hrid: '',
    name: '',
  };

  municipalities: any[] = [
    {
      id: '00000000-0000-0000-0000-000000000000',
      name: 'Не выбран',
    },
  ];
  selectedMunicipality: IMunicipality = {
    id: '00000000-0000-0000-0000-000000000000',
    hrid: '',
    name: '',
    regionId: '',
  };

  private ngUnsubscribe$: Subject<any> = new Subject();

  constructor(
    private adminPanelService: AdminPanelService,
    private regionsService: RegionsService,
    private municipalitiesService: MunicipalitiesService,
    private utilsService: UtilsService,
    private translateService: TranslateService,
  ) {
    this.institution.galleryPaths = [];
  }

  ngOnInit() {
    this.adminLevel = localStorage.getItem('adminLevel');
    this.loadTerritory();
  }

  loadTerritory() {
    let userRegionId = localStorage.getItem('regionId');
    let userMunicipalityId = localStorage.getItem('municipalityId');
    let getRegionsObs$: Observable<any>;

    switch (this.adminLevel) {
      case AdminAccessLevel.GLOBAL:
        {
          getRegionsObs$ = this.regionsService.getAllRegions().pipe(
            tap(regions => {
              this.regions = regions;
            }),
          );
        }
        break;
      case AdminAccessLevel.REGION:
        {
          getRegionsObs$ = this.regionsService.getAllRegions().pipe(
            mergeMap(regions => {
              this.regions = regions;
              let region = this.regions.find(r => r.id == userRegionId);
              this.hasRegion = true;
              return this.setRegion(region);
            }),
          );
        }
        break;
      case AdminAccessLevel.MUNICIPALITY:
        {
          getRegionsObs$ = this.regionsService.getAllRegions().pipe(
            switchMap(regions => {
              this.regions = regions;
              let region = this.regions.find(r => r.id == userRegionId);
              this.hasRegion = true;

              return this.setRegion(region).pipe(
                switchMap(r =>
                  this.municipalitiesService.getMunicipalitiesByRegion(region.id).pipe(
                    tap(municipalities => {
                      let municipality = this.municipalities.find(r => r.id == userMunicipalityId);
                      this.setMunicipality(municipality);
                      this.hasMunicipality = true;
                    }),
                  ),
                ),
              );
            }),
          );
        }
        break;
    }

    getRegionsObs$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
  }

  selectRegion(region) {
    this.setRegion(region).pipe(takeUntil(this.ngUnsubscribe$)).subscribe();
  }

  setRegion(region): Observable<any> {
    this.clearSelectedMunicipality();
    this.selectedRegion = region;
    return this.getMunicipalities(region);
  }

  getMunicipalities(region): Observable<any> {
    return this.municipalitiesService.getMunicipalitiesByRegion(region.id).pipe(
      tap(municipalities => {
        let municipalitiesForCurrentRegion = municipalities.map(item => {
          return {
            id: item.id,
            name: item.name,
          };
        });
        this.municipalities = [this.municipalities[0], ...municipalitiesForCurrentRegion]; // дефолтный элемент и элементы по региону
      }),
    );
  }

  // async getMunicipalities(regionId) {
  //   await this.municipalitiesService.getMunicipalitiesByRegion(regionId).then(r => {
  //     this.municipalities = r.filter(r => r.id != this.defaultRegionId);
  //   });
  // }

  setMunicipality(municipality) {
    this.selectedMunicipality = municipality;
    return;
  }

  clearSelectedMunicipality() {
    this.municipalities = [
      {
        id: '00000000-0000-0000-0000-000000000000',
        name: 'Не выбран',
      },
    ];
    this.selectedMunicipality = {
      id: '00000000-0000-0000-0000-000000000000',
      hrid: '',
      name: '',
      regionId: '',
    };
    return;
  }

  clearTerritoryData() {
    this.selectedRegion = {
      id: '',
      hrid: '',
      name: '',
    };
    this.clearSelectedMunicipality();
    return;
  }

  onSubmit(form: NgForm) {
    if (form.status == 'VALID') {
      let InstitutionClassData: InstitutionClass = {
        id: undefined,
        hrid: this.institution.hrid,
        name: this.institution.name,
        description: this.institution.description,
        address: this.institution.address,
        email: this.institution.email,
        category: this.institution.category,
        additionalInformation: this.institution.additionalInformation,
        url: this.institution.url,
        regionId: this.selectedRegion.id,
        municipalityId: this.selectedMunicipality.id,
      };
      this.adminPanelService
        .createInstitutions(InstitutionClassData)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(response => {
          let institutionID = response;
          if (institutionID) {
            this.utilsService.openSnackBar('👌 Центр добавлен', 'success');
            form.reset();
            this.clearTerritoryData();
            return setTimeout(() => {
              this.addCenter();
            }, 300);
          } else {
            this.utilsService.openSnackBar('👎 Центр не добавлен, возможно, такой центр уже существует, проверьте hrid', 'error');
          }
        });
    } else {
      this.getTranslation('SHARED.ERROR_MSG')
        .pipe(take(1))
        .subscribe(translation => this.utilsService.openSnackBar(translation, 'error'));
    }
  }

  getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }

  addCenter() {
    this.dialogOpen = !this.dialogOpen;
  }

  removeSelectedRows() {
    this.selectedOptions.forEach(item => {
      let indexGalleryPaths: number = this.institution.galleryPaths.findIndex(d => d === item);
      if (indexGalleryPaths > -1) {
        this.institution.galleryPaths.splice(indexGalleryPaths, 1);
      }
    });
    this.selectedOptions = [];
  }

  selectFile(event) {
    if (event.target.files.item(0).type.match('image.*')) {
      this.selectedFiles = event.target.files;
    } else {
      alert('invalid format!');
    }
  }

  // async uploadMainImagePath() {
  //   this.institution.mainImagePath = await this.adminPanelService.uploadImage(
  //     this.selectedFiles,
  //     this.institution.id
  //   );
  // }

  // async uploadGalleryPaths() {
  //   let mainImagePaths = await this.adminPanelService.uploadImage(
  //     this.selectedFiles,
  //     this.institution.id
  //   );
  //   this.institution.galleryPaths.push(mainImagePaths);
  // }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
