<div class="profession__subscription">
  <div class="profession__subscription-title">{{ 'PROFESSIONS.WANT_MORE' | translate }}</div>
  <div class="profession__subscription-text">{{ 'PROFESSIONS.SIGNUP' | translate }} «{{ jobTitle }}»</div>
  <div
    [ngClass]="{
      'profession__subscription-inputs': true,
      'profession__subscription-inputs--active': isDurtyState() || userMail
    }"
  >
    <form>
      <input
        type="email"
        [disabled]="isCompleted()"
        [(ngModel)]="userMail"
        (focus)="focusHandler()"
        (focusout)="focusOutHandler()"
        class="profession__subscription-input"
        placeholder="{{ 'SETTINGS.EMAIL_LABEL' | translate }}"
        name="userNameField"
      />
      <div *ngIf="!isCompleted()" class="profession__subscription-button" (click)="sendEmail()">
        {{ 'PROFESSIONS.SUBSCRIBE' | translate }}
      </div>
      <div *ngIf="isCompleted()" class="profession__subscription-button profession__subscription-button--send-success"></div>
    </form>
  </div>
  <div
    [ngClass]="{
      'profession__subscription-bottom': true,
      'profession__subscription-bottom-error': invalidEmail
    }"
  >
    {{ 'PROFESSIONS.MAIL_RULES' | translate }}
  </div>
</div>
