import { IHttpStatus } from '../http';

export interface IMunicipality {
  id: string;
  hrid: string;
  name: string;
  regionId: string;
}

export interface IGetListOfAllRegionsResponse extends IHttpStatus {
  regions: IRegion[];
}

export interface IRegion {
  id: string;
  createdDate?: string;
  hrid: string;
  name: string;
  isBase?: boolean;
}

export interface ICity {
  id: string;
  hrid: string;
  name: string;
  region: string;
  regionId: string;
  municipality: string;
  municipalityId: string;
  createdDate?: string;
  publishedDate?: string;
}
