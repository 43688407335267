import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  CoursesFiltersService,
} from 'app/shared/dashboard/courses/courses-catalog/courses-filter/courses-filters.service';
import { IFilterClasses } from 'app/shared/interfaces/ifilterclasses.interface';
import { take } from 'rxjs/operators';
import { METRO_COLORS } from './metro-colors';

@Component({
  selector: 'prf-select-metro',
  templateUrl: './select-metro.component.html',
  styleUrls: ['./select-metro.component.scss'],
})
export class SelectMetroComponent implements OnInit {
  metro: any = METRO_COLORS;
  selectedMetroStation: any = [];
  selectedMetroStationNames: any = [];
  filters: IFilterClasses;
  searchMetro: string;
  @ViewChild('metroFilter') metroFilter: ElementRef;
  @ViewChild('metroDrop') dropdown: ElementRef;
  @Output() dataSelectMetro = new EventEmitter();
  @Input() selectedMetro;
  @Input()
  set existingMetroStations(val: any) {
    this.filters = {};
    this.filters.metroStations = val;
    this.filtersService.setCoursesFilter(this.filters);
  }

  constructor(private filtersService: CoursesFiltersService) {}

  ngOnInit() {
    this.filtersService
      .getCoursesFilters()
      .pipe(take(1))
      .subscribe(data => {
        this.filters = data;
        this.selectedMetroStation = data.metroStations ? data.metroStations : [];
        this.selectedMetroStationNames = this.selectedMetroStation.map(x => x.name);
      });
  }

  selectMetroStation(station: any) {
    if (!this.filters.metroStations) {
      this.filters.metroStations = [station];
    } else {
      this.selectedMetroStationNames = this.filters.metroStations.map(x => x.name);
      let index = this.selectedMetroStationNames.indexOf(station.name);
      index !== -1 ? this.filters.metroStations.splice(index, 1) : this.filters.metroStations.push(station);
    }
    this.selectedMetroStationNames = this.filters.metroStations.map(x => x.name);
    this.dataSelectMetro.emit(this.filters.metroStations);
    this.filtersService.setCoursesFilter(this.filters);
  }

  deselectMetroStations() {
    this.selectedMetroStation = [];
    this.selectedMetroStationNames = [];
    this.filters.metroStations = null;
    this.dataSelectMetro.emit(this.filters.metroStations);
    this.filtersService.setCoursesFilter(this.filters);
  }

  checkFilterHeader() {
    if (this.filters.metroStations && this.filters.metroStations.length > 0) {
      if (this.filters.metroStations.length > 1) {
        let metroTitles = ['станция', 'станции', 'станций'];
        return (
          this.filters.metroStations.length + ' ' + this.filtersService.getTitleByNumber(this.filters.metroStations.length, metroTitles)
        );
      } else {
        return this.filters.metroStations[0].name;
      }
    } else {
      return 'Метро';
    }
  }

  @HostListener('document:click', ['$event.target'])
  checkClick(target) {
    if (this.metroFilter && !this.metroFilter?.nativeElement.contains(target)) {
      this.dropdown.nativeElement.classList.remove('w--open');
    }
  }
}
