import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tagFilter',
})
export class TagFilterPipe implements PipeTransform {
  transform(items: string[], filter: string): any[] {
    if (!filter || filter == '' || filter.length < 3) {
      return items;
    }

    return items.filter(item => item.indexOf(filter) > -1);
  }
}
